/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Backgrid = require('backgrid');

require('./ButtonLinkCell.css');
var tmplButtonLinkCell = require('./ButtonLinkCell.hbs');

module.exports = Backgrid.ButtonCell = Backgrid.Cell.extend({
  className: 'link-button-cell td-grid-cell-btn',

  render: function() {
    var href = '#';
    if (this.href) {
      href = Backgrid.callByNeed(this.href, this, this.model);
    }

    var buttonClass = 'btn-primary';
    if (this.buttonClass) {
      buttonClass = Backgrid.callByNeed(this.buttonClass, this, this.model);
    }
    this.$el.addClass(this.column.get('name'));

    var isRenderable = true;
    if (this.renderable) {
      isRenderable = Backgrid.callByNeed(this.renderable, this, this.model);
    }

    if (isRenderable) {
      this.$el.empty().append(
        tmplButtonLinkCell({
          name: this.name,
          href: href,
          newTab: this.newTab,
          buttonClass: buttonClass
        })
      );
    }

    this.delegateEvents();
    return this;
  },

  onClickOfLink: function(e) {
    if (this.onClick && _.isFunction(this.onClick)) {
      e.preventDefault();
      this.onClick(this.model);
    }
  }
});
