/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var parsleyUtil = require('common/util/parsleyUtil');

var FileUploadCollection = require('psa-backbone/models/FileUploadCollection');

var FileUploadView = require('common/views/FileUploadView');
var makeUploadCollection = require('common/util/makeUploadCollection');

var tmplCheckCasherRenewalGeneralDetailsSectionView = require('./CheckCasherRenewalGeneralDetailsSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplCheckCasherRenewalGeneralDetailsSectionView,

  className: 'check-casher-renewal-general-details-section-view',

  ui: {
    generalDetailsForm: '.general-details-form',
    foodEstablishmentContainer: '.food-establishment-container',
    publicEatingContainer: '.public-eating-container',
    zoningLawsDocument: '.zoning-laws-document-container',
    municipalCountyOrdinanceDocument: '.municipal-county-ordinance-document-container',
    retailFoodRegistrationDocument: '.retail-food-document-container',
    missingFile: '.missing-file',
    publicEatingRadio: '[name="isPublicEating"]',
    legalProceedingsFileContainer: '.legal-proceedings-file-container',
    applicationDocumentsFileContainer: '.application-documents-container'
  },

  regions: {
    foodEstablishmentContainer: '@ui.foodEstablishmentContainer',
    publicEatingContainer: '@ui.publicEatingContainer',
    legalProceedingsFileContainer: '@ui.legalProceedingsFileContainer',
    applicationDocumentsFileContainer: '@ui.applicationDocumentsFileContainer',
    zoningLawsDocument: '@ui.zoningLawsDocument',
    municipalCountyOrdinanceDocument: '@ui.municipalCountyOrdinanceDocument',
    retailFoodRegistrationDocument: '@ui.retailFoodRegistrationDocument'
  },

  behaviors: {
    RadioGroupToggleBehavior: {
      radios: [
        {
          target: '#hasFoodEstablishmentArea',
          radioGroup: '[name="isFoodEstablishment"]',
          valueToShow: '1'
        },
        {
          target: '#isPublicEatingArea',
          radioGroup: '[name="isPublicEating"]',
          valueToShow: '1'
        },
        {
          target: '#hasLegalProceedingsExplainationArea',
          radioGroup: '[name="hasLegalProceedings"]',
          valueToShow: '1'
        }
      ]
    }
  },

  onBeforeShow: function() {
    this.foodEstablishmentDocument = new FileUploadView({
      collection: makeUploadCollection(this.model.get('renewalData').foodEstablishmentDocument),
      isRequired: true,
      isReadOnly: this.options.isReadOnly,
      metadata: {
        documentTypeId: 10023
      }
    });
    this.showChildView('foodEstablishmentContainer', this.foodEstablishmentDocument);

    this.publicEatingDocument = new FileUploadView({
      collection: makeUploadCollection(this.model.get('renewalData').publicEatingDocument),
      isRequired: true,
      isReadOnly: this.options.isReadOnly,
      metadata: {
        documentTypeId: 10023
      }
    });
    this.showChildView('publicEatingContainer', this.publicEatingDocument);

    var hasLegalProceedingsDocument = this.model.get('renewalData')
      .hasLegalProceedingsDocument;

    this.legalProceedingsFile = new FileUploadView({
      collection: makeUploadCollection(hasLegalProceedingsDocument),
      isReadOnly: this.options.isReadOnly,
      metadata: {
        documentTypeId: 10023
      }
    });
    this.showChildView('legalProceedingsFileContainer', this.legalProceedingsFile);

    this.zoningLawsDocument = new FileUploadView({
      collection: makeUploadCollection(this.model.get('renewalData').zoningLawsDocument),
      isRequired: true,
      isReadOnly: this.options.isReadOnly,
      metadata: {
        documentTypeId: 10023
      }
    });
    this.showChildView('zoningLawsDocument', this.zoningLawsDocument);

    this.municipalCountyOrdinanceDocument = new FileUploadView({
      collection: makeUploadCollection(this.model.get('renewalData').municipalCountyOrdinanceDocument),
      isRequired: true,
      isReadOnly: this.options.isReadOnly,
      metadata: {
        documentTypeId: 10023
      }
    });
    this.showChildView('municipalCountyOrdinanceDocument', this.municipalCountyOrdinanceDocument);

    this.applicationDocumentsFile = new FileUploadView({
      isRequired: true,
      allowMultipleFiles: true,
      collection: new FileUploadCollection(this.model.get('renewalData').applicationDocuments),
      isReadOnly: this.options.isReadOnly,
      metadata: {
        documentTypeId: 10023
      }
    });
    this.showChildView('applicationDocumentsFileContainer', this.applicationDocumentsFile);

    this.retailFoodRegistrationDocument = new FileUploadView({
      collection: makeUploadCollection(this.model.get('renewalData').retailFoodRegistrationDocument),
      isRequired: true,
      isReadOnly: this.options.isReadOnly,
      metadata: {
        documentTypeId: 10023
      }
    });
    this.showChildView('retailFoodRegistrationDocument', this.retailFoodRegistrationDocument);

    Syphon.deserialize(this, this.model.get('renewalData'));
  },

  validate: function(config) {
    if (config.type === 'save') {
      // Turn off required field validation for this form
      parsleyUtil.disableRequiredValidation(this.ui.generalDetailsForm);
    } else {
      // Turn on required field validation for this form
      parsleyUtil.enableRequiredValidation(this.ui.generalDetailsForm);
    }
    return $.when(this.ui.generalDetailsForm.parsley().whenValidate(), this.zoningLawsDocument.validate(), this.municipalCountyOrdinanceDocument.validate(), this.retailFoodRegistrationDocument.validate());
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    _.extend(formData, {
      foodEstablishmentDocumentId: this.foodEstablishmentDocument.getFirstFileId(),
      publicEatingDocumentId: this.publicEatingDocument.getFirstFileId(),
      hasLegalProceedingsDocumentId: this.legalProceedingsFile.getFirstFileId(),
      applicationDocuments: this.applicationDocumentsFile.collection.pluck('fileId'),
      zoningLawsDocumentId: this.zoningLawsDocument.getFirstFileId(),
      municipalCountyOrdinanceDocumentId: this.municipalCountyOrdinanceDocument.getFirstFileId(),
      retailFoodRegistrationDocumentId: this.retailFoodRegistrationDocument.getFirstFileId()
    });
    return formData;
  }
});
