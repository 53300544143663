var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "            <option value=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.type || (depth0 != null ? depth0.type : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"type","hash":{},"data":data}) : helper)))
    + "</option>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "        <option value=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.regarding || (depth0 != null ? depth0.regarding : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"regarding","hash":{},"data":data}) : helper)))
    + "</option>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "\r\n<div class=\"modal-body\">\r\n  <form class=\"license-payment-form\">\r\n    <div class=\"row\">\r\n      <div class=\"col-sm-6\">\r\n        <div class=\"form-group required\">\r\n          <label class=\"control-label\">Payment Date</label>\r\n          <div class=\"input-group\">\r\n            <input type=\"text\" name=\"paidDate\" class=\"form-control datepicker paid-date\" placeholder=\"Payment Date\"\r\n              data-parsley-required=\"true\" data-parsley-date=\"\"\r\n              data-parsley-trigger-after-failure=\"changeDate\" \r\n              data-parsley-errors-container=\"#paidDateErrorsContainer\" />\r\n            <div class=\"input-group-addon\">\r\n              <i class=\"fa fa-calendar\"></i>\r\n            </div>\r\n          </div>\r\n          <span id=\"paidDateErrorsContainer\"></span>\r\n        </div>\r\n      </div>\r\n      <div class=\"col-sm-6\">\r\n        <div class=\"form-group required\">\r\n          <label class=\"control-label\">Payment Type</label>\r\n          <select name=\"typeId\" class=\"form-control\" data-parsley-required=\"true\">\r\n            <option value=\"\">--</option>\r\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.paymentType : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "          </select>\r\n        </div>\r\n      </div>\r\n    </div>\r\n    <div class=\"form-group required\">\r\n      <label class=\"control-label\">Payment Regarding</label>\r\n      <select name=\"regardingId\" class=\"form-control\" data-parsley-required=\"true\">\r\n        <option value=\"\">--</option>\r\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.paymentRegarding : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "      </select>\r\n    </div>\r\n    <div class=\"form-group required\">\r\n      <label class=\"control-label\"> Payment Amount</label>\r\n      <div class=\"input-group amount-input-group\">\r\n        <div class=\"input-group-addon\">$</div>\r\n        <input type=\"number\" step=\"any\" class=\"form-control\" name=\"amount\" placeholder=\"Payment Amount\"\r\n          data-parsley-dollar-amount=\"true\" data-parsley-errors-container=\"#amountError\" data-parsley-required=\"true\" />\r\n      </div>\r\n      <div id=\"amountError\"></div>\r\n    </div>\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label\">Comments</label>\r\n      <textarea class=\"form-control\" name=\"comments\" rows=\"4\" placeholder=\"Comments\"></textarea>\r\n    </div>\r\n  </form>\r\n</div>\r\n<div class=\"modal-footer\">\r\n  <button class=\"btn btn-default\" data-dismiss=\"modal\">Cancel</button>\r\n  <button class=\"btn btn-primary submit ladda-button\" data-style=\"expand-right\" type=\"button\">\r\n    <span class=\"ladda-label\">Submit</span>\r\n  </button>\r\n</div>";
},"useData":true});