/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var moment = require('moment');

module.exports = function(formData) {
  var defer = $.Deferred();

  var applicationContacts = formData.contacts || [];
  var dbas = formData.dbasSection;
  var branchManager = formData.branchManager;
  var affirmation = formData.affirmation;

  $.soap({
    body: {
      SubmitCheckCasherApplication: {
        $xmlns: 'http://dobs.pa.gov/nd/application/bpm/1.0',
        ApplicationID: formData.applicationId,
        IsPublicEating: formData.generalDetails.isPublicEating,
        IsFoodEstablishment: formData.generalDetails.isFoodEstablishment,
        GrossIncome: formData.generalDetails.grossIncome,
        CheckCasherIncome: formData.generalDetails.checkCasherIncome,
        FoodEstablishmentDocumentID: formData.generalDetails.foodEstablishmentDocumentId,
        PublicEatingDocumentID: formData.generalDetails.publicEatingDocumentId,
        ZoningLawsDocumentID: formData.generalDetails.zoningLawsDocumentId,
        MunicipalCountyOrdinanceDocumentID: formData.generalDetails.municipalCountyOrdinanceDocumentId,
        RetailFoodRegistrationDocumentID: formData.generalDetails.retailFoodRegistrationDocumentId,
        HasLegalProceedings: formData.generalDetails.hasLegalProceedings,
        IsCertified: affirmation.isCertified ? '1' : '0',
        CertificationName: affirmation.certificationName,
        BranchManager: {
          FirstName: branchManager.firstName,
          MiddleName: branchManager.middleName,
          LastName: branchManager.lastName,
          Title: branchManager.title,
          Suffix: branchManager.suffix,
          SSN: branchManager.ssn,
          DOB: branchManager.dob ? moment(branchManager.dob, 'MM/DD/YYYY').format('YYYY-MM-DD') : '',
          HomePhoneNumber: branchManager.homePhoneNumber,
          CellPhoneNumber: branchManager.cellPhoneNumber,
          Email: branchManager.email,
          Address: {
            AddressLine1: branchManager.addressLine1,
            AddressLine2: branchManager.addressLine2,
            AddressLine3: branchManager.addressLine3,
            County: branchManager.county,
            City: branchManager.city,
            Country: branchManager.country,
            PostalCode: branchManager.postalCode,
            State: branchManager.state
          }
        },
        EntityDBAIDs: (function() {
          if (!dbas.entityDBAIds || !dbas.entityDBAIds.length) {
            return {};
          } else {
            return {
              ID: dbas.entityDBAIds
            };
          }
        })(),
        ApplicationContacts: (function() {
          if (!applicationContacts.length) {
            return {};
          }
          return {
            ApplicationContact: _.map(applicationContacts, function(applicationContact) {
              return {
                FirstName: applicationContact.firstName,
                LastName: applicationContact.lastName,
                HomePhoneNumber: applicationContact.homePhoneNumber,
                CellPhoneNumber: applicationContact.cellPhoneNumber,
                Email: applicationContact.email,
                LicenseContactTypeID: applicationContact.licenseContactTypeId,
                Title: applicationContact.title,
                Company: applicationContact.company,
                WorkPhoneNumber: applicationContact.workPhoneNumber,
                WorkPhoneExtension: applicationContact.workPhoneExtension,
                Fax: applicationContact.fax,
                Address: {
                  AddressLine1: applicationContact.addressLine1,
                  AddressLine2: applicationContact.addressLine2,
                  AddressLine3: applicationContact.addressLine3,
                  County: applicationContact.county,
                  City: applicationContact.city,
                  State: applicationContact.state,
                  PostalCode: applicationContact.postalCode,
                  Country: applicationContact.country
                }
              };
            })
          };
        })(),
        ApplicationDocuments: (function() {
          if (formData.generalDetails.applicationDocuments.length === 0) {
            return {};
          }
          return {
            DocumentID: formData.generalDetails.applicationDocuments
          };
        })(),
        DocumentsToMove: (function() {
          var documentsToMove = _.compact(
            [
              formData.generalDetails.foodEstablishmentDocumentId,
              formData.generalDetails.publicEatingDocumentId,
              formData.generalDetails.zoningLawsDocumentId,
              formData.generalDetails.municipalCountyOrdinanceDocumentId,
              formData.generalDetails.retailFoodRegistrationDocumentId
            ].concat(formData.generalDetails.applicationDocuments)
          );
          if (!documentsToMove.length) {
            return {};
          }
          return {
            DocumentID: _.map(documentsToMove, function(documentId) {
              return documentId;
            })
          };
        })()
      }
    }
  })
    .done(function() {
      defer.resolve();
    })
    .fail(function(err) {
      defer.reject(err);
    });
  return defer.promise();
};
