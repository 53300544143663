var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "              <option value=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.type || (depth0 != null ? depth0.type : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"type","hash":{},"data":data}) : helper)))
    + "</option>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=helpers.helperMissing;

  return "\r\n<div class=\"page-title clearfix\">\r\n  <h1 class=\"pull-left m-n p-n\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.task : depth0)) != null ? stack1.taskType : stack1)) != null ? stack1.description : stack1), depth0))
    + " for "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.organization : depth0)) != null ? stack1.DBAName : stack1), depth0))
    + "</h1>\r\n  <div class=\"page-title-buttons-container\">\r\n    <div class=\"cs-link-container\"></div>\r\n  </div>\r\n</div>\r\n<div class=\"header-bar\"></div>\r\n<div class=\"breadcrumb-container\"></div>\r\n<div class=\"task-status\"></div>\r\n<div class=\"form-horizontal\">\r\n  <form class=\"financial-statement-form\">\r\n    <div class=\"row\">\r\n      <div class=\"col-sm-9 col-sm-offset-3\">\r\n        <h2>Recommendation from Chief Accounting</h2>\r\n      </div>\r\n    </div>\r\n    <div class=\"row fiscal-year-end-row\">\r\n      <div class=\"col-sm-12\">\r\n        <div class=\"form-group\">\r\n          <label class=\"control-label col-sm-3\">Recommendation</label>\r\n          <div class=\"col-sm-9 col-md-6 form-control-static\">"
    + alias2((helpers["default"] || (depth0 && depth0["default"]) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.financialStatement : depth0)) != null ? stack1.reviewStatus : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\r\n        </div>\r\n      </div>\r\n    </div>\r\n    <div class=\"row\">\r\n      <div class=\"col-sm-12\">\r\n        <div class=\"form-group\">\r\n          <label class=\"control-label col-sm-3\">Comments</label>\r\n          <div class=\"col-sm-9 col-md-6 form-control-static\">"
    + alias2((helpers["default"] || (depth0 && depth0["default"]) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.financialStatement : depth0)) != null ? stack1.internalComments : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\r\n        </div>\r\n      </div>\r\n    </div>\r\n    <div class=\"row\">\r\n      <div class=\"col-sm-9 col-sm-offset-3\">\r\n        <h2>Documents</h2>\r\n      </div>\r\n    </div>\r\n    <div class=\"row\">\r\n      <div class=\"col-sm-12\">\r\n        <div class=\"form-group\">\r\n          <label class=\"control-label col-sm-3 \">Files</label>\r\n          <div class=\" col-sm-9 col-md-6\">\r\n            <div class=\"ext-file-upload-table-container\"></div>\r\n          </div>\r\n        </div>\r\n      </div>\r\n    </div>\r\n    <div class=\"row\">\r\n      <div class=\"col-sm-12\">\r\n        <div class=\"form-group\">\r\n          <label class=\"control-label col-sm-3 \">Attach Files</label>\r\n          <div class=\" col-sm-9 col-md-6\">\r\n            <div class=\"int-file-upload-table-container\"></div>\r\n          </div>\r\n        </div>\r\n      </div>\r\n    </div>\r\n    <div class=\"row\">\r\n      <div class=\"col-sm-9 col-sm-offset-3\">\r\n        <h2>Decision</h2>\r\n      </div>\r\n    </div>\r\n    <div class=\"row\">\r\n      <div class=\"col-sm-12\">\r\n        <div class=\"form-group required\">\r\n          <label class=\"control-label col-sm-3 \">Decision</label>\r\n          <div class=\"col-sm-9 col-md-3\">\r\n            <select name=\"reviewDecisionId\" class=\"form-control status\" data-parsley-required=\"true\">\r\n              <option value=\"\" selected>--</option>\r\n"
    + ((stack1 = helpers.each.call(alias3,(depth0 != null ? depth0.reviewDecisionTypes : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            </select>\r\n          </div>\r\n        </div>\r\n      </div>\r\n    </div>\r\n    <div class=\"row\">\r\n      <div class=\"col-sm-12\">\r\n        <div class=\"form-group required\">\r\n          <label class=\"control-label col-sm-3\">Internal Comments</label>\r\n          <div class=\"col-sm-9 col-md-6\">\r\n            <textarea type=\"text\" class=\"form-control \" name=\"internalComments\" placeholder=\"Comments\" rows=\"5\"\r\n              data-parsley-required=\"true\"></textarea>\r\n          </div>\r\n        </div>\r\n      </div>\r\n    </div>\r\n  </form>\r\n  <div class=\"form-group\">\r\n    <div class=\"col-sm-9 col-lg-7 col-sm-offset-3\">\r\n      <a href=\"#sec/dashboard\" class=\"btn btn-default\" role=\"button\">Cancel</a>\r\n      <button type=\"button\" class=\"btn btn-primary submit\">Submit</button>\r\n      <div class=\"task-actions\"></div>\r\n    </div>\r\n  </div>\r\n</div>";
},"useData":true});