/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var PageableCollection = require('psa-backbone/models/PageableCollection');

var PlaceholderView = require('common/views/PlaceholderView');

var SecComplianceSanctionsSectionView = require('../views/SecComplianceSanctionsSectionView');
var GetComplianceSanctionsByCompliance = require('../services/GetComplianceSanctionsByCompliance');

module.exports = {
  channel: 'sc',

  event: 'build:comp-sanction-view',

  builder: function(complianceId) {
    return new PlaceholderView({
      viewPromise: buildComplianceSanctionView(complianceId)
    });
  }
};

function buildComplianceSanctionView(complianceId) {
  var deferred = $.Deferred();
  GetComplianceSanctionsByCompliance(complianceId)
    .done(function(sanctions) {
      deferred.resolve(
        new SecComplianceSanctionsSectionView({
          collection: new PageableCollection(sanctions, { pageSize: 25 })
        })
      );
    })
    .fail(function(err) {
      deferred.reject(err);
    });
  return deferred.promise();
}
