/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var toggleForm = require('./toggleForm');

module.exports = function($form, selector) {
  var textAreas = $form.find(selector);

  _.each(textAreas, function(textArea) {
    var textAreaVal = textArea.value;
    var textAreaName = textArea.name;
    var printElementId = '#'.concat(textAreaName, 'PrintView');
    $(printElementId).text(textAreaVal);
  });
  toggleForm(textAreas, false);
};
