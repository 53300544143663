/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(fundId) {
  var defer = $.Deferred();

  $.soap({
    body: {
      GetTrustAuditsByTrustFund: {
        $xmlns: 'http://dobs.pa.gov/dp/fund/bpm/1.0',
        TrustFundID: fundId
      }
    },
    blueprintArrays: ['GetTrustAuditsByTrustFundResponse.DP_TrustAudit'],
    blueprint: {
      audits: [
        '$..DP_TrustAudit',
        {
          id: '$..ID',
          entityId: '$..EntityID',
          fundId: '$..TrustFundID',
          auditDate: '$..AuditDate',
          receivedDate: '$..ReceivedDate',
          opinion: '$..Opinion',
          opinionDetails: '$..OpinionDetails',
          auditFirm: '$..AuditFirm'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      _.each(response.audits, function(audit) {
        if (audit.auditDate) {
          audit.auditDate = moment(audit.auditDate, 'YYYY-MM-DD');
        }
        if (audit.receivedDate) {
          audit.receivedDate = moment(audit.receivedDate, 'YYYY-MM-DD');
        }
        audit.auditType = audit.auditType || '--';
        audit.opinion = audit.opinion || '--';
        audit.auditFirm = audit.auditFirm || '--';
      });

      defer.resolve(response.audits);
    })
    .fail(function(err) {
      defer.reject(err);
    });

  return defer.promise();
};
