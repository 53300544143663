/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');
var Syphon = require('backbone.syphon');
var Ladda = require('ladda');

var modelGet = require('common/util/modelGet');
var HeaderBarView = require('common/views/HeaderBarView');
var BreadcrumbView = require('common/views/BreadcrumbView');

var SubmitFilingLawyer = require('../services/SubmitFilingLawyer');

var tmplDepManageFilingLawyerView = require('../templates/DepManageFilingLawyerView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepManageFilingLawyerView,

  className: 'dep-manage-filing-lawyer-view container-fluid',

  ui: {
    headerBar: '.header-bar',
    breadcrumb: '.breadcrumb-container',
    manageLawyerForm: '.manage-lawyer-form',
    submit: '.submit'
  },

  regions: {
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb',
    manageLawyerForm: '@ui.manageLawyerForm'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  behaviors: {
    ToggleBehavoir: {
      toggles: [
        {
          target: '.new-lawyer',
          group: '[name="hasLawyer"]',
          valuesToShow: '1'
        }
      ]
    }
  },

  templateHelpers: function() {
    return {
      viewMode: this.options.viewMode,
      lawyerDisplay: modelGet(this.model, 'filingDetails.lawyer.displayName')
    };
  },

  onBeforeShow: function() {
    var entityId = this.model.get('entityId');
    var viewMode = this.options.viewMode;
    var filingId = this.model.get('filingId');
    var filingDetails = this.model.get('filingDetails') || {};
    var filingType = filingDetails.filingType;
    var entityName = filingDetails.entityName;
    var isEntityFiling = !!entityId;
    var isExternal = viewMode === 'ext';

    var headerBarColumns = [
      {
        name: 'filingType',
        label: 'Filing Type',
        minWidth: '160px',
        maxWidth: '350px',
        overflow: 'truncate'
      }
    ];

    if (isEntityFiling) {
      headerBarColumns.unshift(
        {
          name: 'entityName',
          label: 'Entity Name',
          minWidth: '160px',
          maxWidth: '300px'
        },
        {
          name: 'entityType',
          label: 'Entity Type',
          minWidth: '160px'
        }
      );
    }

    this.showChildView(
      'headerBar',
      new HeaderBarView({
        columns: new Backbone.Collection(headerBarColumns),
        model: new Backbone.Model(filingDetails)
      })
    );

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            text: 'Home',
            link: isExternal ? '#ext/dep/dashboard' : '#dep/dashboard'
          },
          {
            text: entityName,
            link: isExternal ? '#ext/dep/entity/' + entityId + '/dashboard' : '#dep/entity/' + entityId + '/dashboard',
            renderable: isEntityFiling
          },
          {
            link: '#dep/entity/' + entityId + '/filing/' + filingId + '/dashboard',
            text: filingType + ' Filing',
            renderable: !isExternal
          },
          {
            text: 'Manage Filer'
          }
        ]
      })
    );
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;

    var filingId = this.model.get('filingId');
    var entityId = this.model.get('entityId');
    var isExternal = this.options.viewMode === 'ext';
    var isEntityFiling = !!entityId;

    this.validate().done(function() {
      var spinner = Ladda.create(self.ui.submit[0]);
      spinner.start();
      var formData = self.getFormData();
      formData.filingId = filingId;

      SubmitFilingLawyer(formData)
        .done(function() {
          if (isExternal) {
            Radio.channel('navigate').trigger('show:ext:dp:entity-dashboard', entityId);
          } else {
            if (isEntityFiling) {
              Radio.channel('navigate').trigger('show:int:dp:filing', entityId, filingId);
            } else {
              Radio.channel('navigate').trigger('show:int:dp:dashboard');
            }
          }
        })
        .fail(function() {
          spinner.stop();
        });
    });
  },

  validate: function() {
    return this.ui.manageLawyerForm.parsley().whenValidate();
  },

  getFormData: function() {
    return Syphon.serialize(this);
  }
});
