/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(historyId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetIndividualHistoryByHistoryID: {
        $xmlns: 'http://dobs.pa.gov/nd/individual/bpm/1.0',
        HistoryID: historyId
      }
    },
    blueprint: {
      individualType: '$..IndividualType',
      entityOfficerId: '$..EntityOfficerID',
      entityId: '$..EntityID',
      entityName: '$..EntityName',
      licenseId: '$..LicenseID',
      licenseNumber: '$..LicenseNumber',
      licenseType: '$..LicenseType',
      individualId: '$..IndividualID',
      firstName: '$..FirstName',
      middleName: '$..MiddleName',
      lastName: '$..LastName',
      title: '$..Title',
      ssn: '$..SSN',
      dob: '$..DOB',
      homePhoneNumber: '$..HomePhoneNumber',
      cellPhoneNumber: '$..CellPhoneNumber',
      email: '$..Email',
      addressLine1: '$..AddressLine1',
      addressLine2: '$..AddressLine2',
      addressLine3: '$..AddressLine3',
      city: '$..City',
      state: '$..State',
      postalCode: '$..PostalCode',
      country: '$..Country',
      officerType: '$..EntityOfficerTypeName',
      entityOfficerTypeId: '$..EntityOfficerTypeID',
      entityOfficerTypeName: '$..EntityOfficerTypeName',
      fbiCheck: '$..FBICheck',
      fbiDateCompleted: '$..FBIDateCompleted',
      nmlsCheck: '$..NMLSCheck',
      nmlsDateCompleted: '$..NMLSDateCompleted',
      nonNmlsCheck: '$..NonNMLSCheck',
      nonNmlsDateCompleted: '$..NonNMLSDateCompleted',
      jnetReceived: '$..JNetReceived',
      jnetDateReceived: '$..JNetDateReceived',
      jnetComments: '$..JNetComments',
      flagged: '$..Flagged',
      suffix: '$..Suffix',
      otherCheck: '$..OtherCheck',
      otherDateReceived: '$..OtherDateReceived',
      jnetType: '$..JNETType',
      jnetTypeName: '$..JNETTypeName'
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      response.dob = response.dob ? moment(response.dob, 'YYYY-MM-DD').format('MM/DD/YYYY') : '';
      response.fbiDateCompleted = response.fbiDateCompleted
        ? moment(response.fbiDateCompleted, 'YYYY-MM-DD').format('MM/DD/YYYY')
        : '';
      response.nmlsDateCompleted = response.nmlsDateCompleted
        ? moment(response.nmlsDateCompleted, 'YYYY-MM-DD').format('MM/DD/YYYY')
        : '';
      response.nonNmlsDateCompleted = response.nonNmlsDateCompleted
        ? moment(response.nonNmlsDateCompleted, 'YYYY-MM-DD').format('MM/DD/YYYY')
        : '';
      response.jnetDateReceived = response.jnetDateReceived
        ? moment(response.jnetDateReceived, 'YYYY-MM-DD').format('MM/DD/YYYY')
        : '';
      response.otherDateReceived = response.otherDateReceived
        ? moment(response.otherDateReceived, 'YYYY-MM-DD').format('MM/DD/YYYY')
        : '';

      response.fbiCheck = response.fbiCheck === '1';
      response.nmlsCheck = response.nmlsCheck === '1';
      response.nonNmlsCheck = response.nonNmlsCheck === '1';
      response.jnetReceived = response.jnetReceived === '1';
      response.flagged = response.flagged === '1';
      response.otherCheck = response.otherCheck === '1';

      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject(err);
    });

  return deferred.promise();
};
