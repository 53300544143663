/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(requestData) {
  var defer = $.Deferred();
  $.soap({
    body: {
      GetComplianceDetailsForEntity: {
        $xmlns: 'http://dobs.pa.gov/nd/compliance/bpm/1.0',
        ComplianceID: requestData.complianceId,
        CompliancePaymentID: requestData.compliancePaymentId
      }
    },
    blueprintArrays: ['GetComplianceDetailsForEntityResponse.ComplianceViolations.ND_ComplianceViolation'],
    blueprint: {
      complianceId: '$..ComplianceID',
      compliancePaymentId: '$..CompliancePaymentID',
      entityName: '$..EntityName',
      caseNumber: '$..CaseNumber',
      tin: '$..TIN',
      licenseNumber: '$..LicenseNumber',
      licenseType: '$..LicenseType',
      unlicensedLicenseType: '$..OperatingAsLicenseType',
      fineAmount: '$..FineAmount',
      paymentPlanDescription: '$..PaymentPlanDescription',
      paymentDescription: '$..PaymentDescription',
      paymentDueDate: '$..PaymentDueDate',
      paymentAmount: '$..PaymentAmount',
      paymentIsDeleted: '$..PaymentIsDeleted',
      complianceViolations: [
        '$..ND_ComplianceViolation',
        {
          id: '$..ID',
          complianceViolationTypeId: '$..ComplianceViolationTypeID',
          code: '$..Code',
          name: '$..Name'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response, { cleanUpTextKeys: true });

      if (!response.fineAmount || _.isObject(response.fineAmount)) {
        response.fineAmount = '0.00';
      }

      if (!response.paymentPlanDescription || _.isObject(response.paymentPlanDescription)) {
        response.paymentPlanDescription = '--';
      }

      response.caseNumber = response.caseNumber || '--';
      response.licenseNumber = response.licenseNumber || '--';
      response.paymentIsDeleted = response.paymentIsDeleted === '1';

      defer.resolve(response);
    })
    .fail(function(err) {
      defer.reject();
    });

  return defer.promise();
};
