/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var _get = require('lodash.get');

module.exports = function(formData) {
  var filingId = formData.filingId;
  var pointOfContact = formData.depCreditUnionSEGRequestFilingPointOfContact;
  var lawyerExternalUserId = _get(formData, 'creditUnionSEGRequestSubmissionLawyer.lawyerExternalUserId');
  var groupLocations = formData.depCreditUnionSEGRequestFilingGroupLocations;
  var individualTypes = formData.depCreditUnionSEGRequestFilingGroupLocations.individualTypes;
  var groupLocationsList = formData.depCreditUnionSEGRequestFilingGroupLocations.locations;
  var groupInformation = formData.depCreditUnionSEGRequestFilingGroupInformation;
  var documentation = formData.depCreditUnionSEGRequestFilingDocumentation;
  var supportingDocuments = formData.depCreditUnionSEGRequestFilingDocumentation.otherSupportingDocs || [];
  var certification = formData.depCreditUnionSEGRequestFilingCertification || {};

  return $.soap({
    body: {
      SubmitCreditUnionSEGRequestFiling: {
        $xmlns: 'http://dobs.pa.gov/dp/filing/bpm/1.0',
        FilingID: filingId,
        CreditUnionSEGRequestFilingID: formData.creditUnionSEGRequestFilingId,
        GroupName: groupInformation.groupName,
        GroupBusinessDesc: groupInformation.groupBusinessDesc,
        GroupWebsites: groupInformation.groupWebsites,
        GroupEmployeesCount: groupLocations.groupEmployeesCount,
        GroupEmployeesComments: groupLocations.groupEmployeesComments,
        GroupNotFormingCU:  groupLocations.groupNotFormingCU,
        StateOfIncorporation: groupInformation.stateOfIncorporation,
        OtherTypeComments: groupInformation.otherTypeComments,
        StateGroupRegisteredNumber: groupInformation.stateGroupRegisteredNumber,
        IsHqAddressDiffer: groupInformation.isHqAddressDiffer,
        NumEmployessLocation: groupLocations.numEmployessLocation,
        NumMembersLocation:groupLocations.numMembersLocation,
        NumStudentsLocation:groupLocations.numStudentsLocation,
        NumAlumniLocation:groupLocations.numAlumniLocation,
        NumBoardLocation:groupLocations.numBoardLocation,
        NumOtherLocation:groupLocations.numOtherLocation,
        NumEmployessHQ:groupLocations.numEmployessHQ,
        IsAllMembersAtHQ: groupLocations.isAllMembersHQ,
        IsGroupNonProfit: groupInformation.isGroupNonProfit,
        EntityTypeID: groupInformation.entityTypeID,
        StateVerificationDocID:groupInformation.stateVerificationDocID,
        NonProfitByLawsDocID:groupInformation.nonProfitByLawsDocID,
        StateGroupAddressComments: groupInformation.stateGroupAddressComments,
        PDOBSRequestLetterDocID: documentation.pdobsRequestLetterDocId,
        PDOBSRequestLetterComments: documentation.pdobsRequestLetterComments,
        CreditUnionRequestLetterDocID: documentation.creditUnionRequestLetterDocId,
        CreditUnionRequestLetterComments: documentation.creditUnionRequestLetterComments,
        BylawsDocID: groupInformation.bylawsDocId,
        BylawsComments: groupInformation.bylawsComments,
        IsCertified: certification.isCertified ? 1 : 0,
        CertificationName: certification.certificationName,
        CertificationDocID: certification.certificationDocId,
        GroupAddress: {
          AddressLine1: groupInformation.address.addressLine1,
          AddressLine2: groupInformation.address.addressLine2,
          AddressLine3: groupInformation.address.addressLine3,
          County: groupInformation.address.county,
          City: groupInformation.address.city,
          State: groupInformation.address.state,
          PostalCode: groupInformation.address.postalCode,
          Country: groupInformation.address.country
        },
        StateGroupAddress: {
          AddressLine1: groupInformation.regAddress.addressLine1,
          AddressLine2: groupInformation.regAddress.addressLine2,
          AddressLine3: groupInformation.regAddress.addressLine3,
          County: groupInformation.regAddress.county,
          City: groupInformation.regAddress.city,
          State: groupInformation.regAddress.state,
          PostalCode: groupInformation.regAddress.postalCode,
          Country: groupInformation.regAddress.country
        },
        PointOfContact: {
          Name: pointOfContact.name,
          Title:pointOfContact.title,
          Phone: pointOfContact.phone,
          Email: pointOfContact.email,
          AddressLine1: pointOfContact.addressLine1,
          AddressLine2: pointOfContact.addressLine2,
          AddressLine3: pointOfContact.addressLine3,
          City: pointOfContact.city,
          State: pointOfContact.state,
          PostalCode: pointOfContact.postalCode,
          County: pointOfContact.county,
          Country: pointOfContact.country
        },
        LawyerExternalUserID: lawyerExternalUserId,
        IndividualTypes: (function() {
          if (!individualTypes || !individualTypes.length) {
            return {};
          }
          return {
            TypeID: individualTypes
          };
        })(),
        GroupLocations: (function() {
          if (!groupLocationsList || groupLocationsList.length === 0) {
            return {};
          }
          return {
            GroupLocation: _.map(groupLocationsList, function(location) {
              return {
                AddressLine1: location.addressLine1,
                AddressLine2: location.addressLine2,
                AddressLine3: location.addressLine3,
                County: location.county,
                City: location.city,
                State: location.state,
                PostalCode: location.postalCode,
                Country: location.country,
                DistanceToCU: location.distanceToCU,
                EmployeeCount: location.employeeCount
              };
            })
          };
        })(),
        SupportingDocuments: (function() {
          if (!supportingDocuments.length) {
            return {};
          }
          return {
            Document: _.map(supportingDocuments, function(supportingDocument) {
              return {
                DocumentID: supportingDocument.fileId
              };
            })
          };
        })()
      }
    }
  });
};
