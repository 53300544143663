/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Cookie = require('js-cookie');

var sha1 = require('psa-core/util/sha1');
var Session = require('psa-core/Session');

var ClearAuthenticationCookies = require('psa-authentication/ClearAuthenticationCookies');
var GetOTDSTicketByUsernamePassword = require('psa-authentication/GetOTDSTicketByUsernamePassword');

var GetSAMLAssertionByOTDSTicket = require('./GetSAMLAssertionByOTDSTicket');

module.exports = function(username, password, code) {
  var defer = $.Deferred();

  var preLoginInfo = Session.preLoginInfo;

  var samlCookieName = preLoginInfo.SamlArtifactCookieName;
  var checkCookieName = preLoginInfo.CheckName;
  var cookiesPath = preLoginInfo.SamlArtifactCookiePath || '/';

  ClearAuthenticationCookies()
    .done(function() {
      GetOTDSTicketByUsernamePassword(username, password, undefined, code)
        .done(function(ticketObj) {
          GetSAMLAssertionByOTDSTicket(ticketObj.ticket)
            .done(function(artifact) {
              Cookie.set(samlCookieName, artifact, { path: cookiesPath });

              var ct = sha1.hex_sha1(artifact);
              Cookie.set(checkCookieName, ct, { path: cookiesPath });

              Session.auth = {
                samlArtifact: artifact,
                checkValue: ct
              };

              defer.resolve();
            })
            .fail(function(errCode) {
              defer.reject(errCode);
            });
        })
        .fail(function(errCode) {
          defer.reject(errCode);
        });
    })
    .fail(function(errCode) {
      defer.reject(errCode);
    });

  return defer.promise();
};
