var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "  <div class=\"form-group\">\r\n    <div class=\"col-sm-9 col-lg-7 col-sm-offset-3\">\r\n      <button type=\"button\" class=\"btn btn-primary close-correspondence\">Mark as Closed</button>\r\n    </div>\r\n  </div>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=helpers.helperMissing;

  return "\r\n<div class=\"page-title clearfix\">\r\n  <h1 class=\"pull-left m-n p-n\">Complaint - "
    + alias1(container.lambda(((stack1 = (depth0 != null ? depth0.correspondence : depth0)) != null ? stack1.complaintCaseNumber : stack1), depth0))
    + "</h1>\r\n</div>\r\n<div class=\"header-bar\"></div>\r\n<div class=\"breadcrumb-container\"></div>\r\n<div class=\"form-horizontal\">\r\n  <form class=\"correspondence-form\">\r\n    <div class=\"row\">\r\n      <div class=\"col-sm-9 col-sm-offset-3\">\r\n        <h2>Internal Correspondence</h2>\r\n      </div>\r\n    </div>\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-3\">Message From Dobs</label>\r\n      <div class=\"col-sm-9 col-md-6\">\r\n        <p class=\"form-control-static\">"
    + alias1((helpers["default"] || (depth0 && depth0["default"]) || alias3).call(alias2,((stack1 = (depth0 != null ? depth0.correspondence : depth0)) != null ? stack1.requestComments : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</p>\r\n      </div>\r\n    </div>\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-3 \">Internal Documents</label>\r\n      <div class=\" col-sm-9 col-md-6\">\r\n        <div class=\"int-file-upload-table-container\"></div>\r\n      </div>\r\n    </div>\r\n    <div class=\"row\">\r\n      <div class=\"col-sm-9 col-sm-offset-3\">\r\n        <h2>External Response</h2>\r\n      </div>\r\n    </div>\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-3\">Reply Comments</label>\r\n      <div class=\"col-sm-9 col-md-6\">\r\n        <p class=\"form-control-static\">"
    + alias1((helpers["default"] || (depth0 && depth0["default"]) || alias3).call(alias2,((stack1 = (depth0 != null ? depth0.correspondence : depth0)) != null ? stack1.replyComments : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</p>\r\n      </div>\r\n    </div>\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-3 \">External Documents</label>\r\n      <div class=\" col-sm-9 col-md-6\">\r\n        <div class=\"ext-file-upload-table-container\"></div>\r\n      </div>\r\n    </div>\r\n    <div class=\"row\">\r\n      <div class=\"col-sm-9 col-sm-offset-3\">\r\n        <h2>Correspondence Details</h2>\r\n      </div>\r\n    </div>\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-3\">Status</label>\r\n      <div class=\"col-sm-9 col-md-6\">\r\n        <p class=\"form-control-static \">"
    + alias1((helpers["default"] || (depth0 && depth0["default"]) || alias3).call(alias2,((stack1 = (depth0 != null ? depth0.correspondence : depth0)) != null ? stack1.status : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</p>\r\n      </div>\r\n    </div>\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-3\">Correspondence Replied By</label>\r\n      <div class=\"col-sm-9 col-md-6\">\r\n        <p class=\"form-control-static \">"
    + alias1((helpers["default"] || (depth0 && depth0["default"]) || alias3).call(alias2,((stack1 = (depth0 != null ? depth0.correspondence : depth0)) != null ? stack1.repliedByName : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</p>\r\n      </div>\r\n    </div>\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-3\">Replied By Email</label>\r\n      <div class=\"col-sm-9 col-md-6\">\r\n        <p class=\"form-control-static \">"
    + alias1((helpers["default"] || (depth0 && depth0["default"]) || alias3).call(alias2,((stack1 = (depth0 != null ? depth0.correspondence : depth0)) != null ? stack1.repliedByEmail : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</p>\r\n      </div>\r\n    </div>\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-3\">Reply Date</label>\r\n      <div class=\"col-sm-9 col-md-6\">\r\n        <p class=\"form-control-static \">"
    + alias1((helpers["default"] || (depth0 && depth0["default"]) || alias3).call(alias2,((stack1 = (depth0 != null ? depth0.correspondence : depth0)) != null ? stack1.repliedOn : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</p>\r\n      </div>\r\n    </div>\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-3\">Correspondence Withdrawn By</label>\r\n      <div class=\"col-sm-9 col-md-6\">\r\n        <p class=\"form-control-static \">"
    + alias1((helpers["default"] || (depth0 && depth0["default"]) || alias3).call(alias2,((stack1 = (depth0 != null ? depth0.correspondence : depth0)) != null ? stack1.withdrawnByName : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</p>\r\n      </div>\r\n    </div>\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-3\">Withdrawn Date</label>\r\n      <div class=\"col-sm-9 col-md-6\">\r\n        <p class=\"form-control-static \">"
    + alias1((helpers["default"] || (depth0 && depth0["default"]) || alias3).call(alias2,((stack1 = (depth0 != null ? depth0.correspondence : depth0)) != null ? stack1.withdrawnOn : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</p>\r\n      </div>\r\n    </div>\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-3\">Withdrawn Comments</label>\r\n      <div class=\"col-sm-9 col-md-6\">\r\n        <p class=\"form-control-static\">"
    + alias1((helpers["default"] || (depth0 && depth0["default"]) || alias3).call(alias2,((stack1 = (depth0 != null ? depth0.correspondence : depth0)) != null ? stack1.withdrawnComments : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</p>\r\n      </div>\r\n    </div>\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-3\">Correspondence Closed By</label>\r\n      <div class=\"col-sm-9 col-md-6\">\r\n        <p class=\"form-control-static \">"
    + alias1((helpers["default"] || (depth0 && depth0["default"]) || alias3).call(alias2,((stack1 = (depth0 != null ? depth0.correspondence : depth0)) != null ? stack1.closedByName : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</p>\r\n      </div>\r\n    </div>\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-3\">Closed Date</label>\r\n      <div class=\"col-sm-9 col-md-6\">\r\n        <p class=\"form-control-static \">"
    + alias1((helpers["default"] || (depth0 && depth0["default"]) || alias3).call(alias2,((stack1 = (depth0 != null ? depth0.correspondence : depth0)) != null ? stack1.closedOn : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</p>\r\n      </div>\r\n    </div>\r\n  </form>\r\n"
    + ((stack1 = helpers["if"].call(alias2,(depth0 != null ? depth0.responseReceived : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>";
},"useData":true});