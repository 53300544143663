var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "\r\n<div class=\"page-title clearfix\">\r\n  <h1 class=\"pull-left m-n p-n\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.entityIdentifiers : depth0)) != null ? stack1.entityName : stack1), depth0))
    + "</h1>\r\n  <div class=\"page-title-buttons-container\">\r\n    <div class=\"cs-link-container\"></div>\r\n    <div class=\"filing-action-drop-down\"></div>\r\n  </div>\r\n</div>\r\n<div class=\"page-subtitle\">\r\n  <h2>Filing</h2>\r\n  <div class=\"page-subtitle-details\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.filing : depth0)) != null ? stack1.filingType : stack1), depth0))
    + "</div>\r\n</div>\r\n<div class=\"breadcrumb-container\"></div>\r\n<div class=\"filing-dashboard-container\"></div>";
},"useData":true});