var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "          <option value=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.locationAction || (depth0 != null ? depth0.locationAction : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"locationAction","hash":{},"data":data}) : helper)))
    + "</option>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "\r\n<div class=\"panel-body\">\r\n  <div class=\"row location-info\">\r\n    <div class=\"col-sm-8\">\r\n      <div class=\"row\">\r\n        <div class=\"col-sm-5\">\r\n          <div class=\"form-group\">\r\n            <label class=\"control-label\">Location Type</label>\r\n            <div class=\"form-control-static\">"
    + container.escapeExpression(((helper = (helper = helpers.locationType || (depth0 != null ? depth0.locationType : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"locationType","hash":{},"data":data}) : helper)))
    + "</div>\r\n          </div>\r\n        </div>\r\n        <div class=\"col-sm-7\">\r\n          <div class=\"form-group\">\r\n            <label class=\"control-label\">Location Address</label>\r\n            <div class=\"current-location-address\"></div>\r\n          </div>\r\n        </div>\r\n      </div>\r\n    </div>\r\n    <div class=\"col-sm-4\">\r\n      <div class=\"form-group\">\r\n        <label class=\"control-label\">Select Action</label>\r\n        <select name=\"locationActionTypeId\" class=\"form-control action-type-dropdown\">\r\n          <option value=\"\" selected>--</option>\r\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.actionTypes : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </select>\r\n      </div>\r\n    </div>\r\n  </div>\r\n  <div class=\"action-form\"></div>\r\n</div>";
},"useData":true});