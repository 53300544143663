/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(entityId) {
  var defer = $.Deferred();

  $.soap({
    body: {
      GetEntityTrackingsByEntity: {
        $xmlns: 'http://dobs.pa.gov/dp/tracking/bpm/1.0',
        EntityID: entityId
      }
    },
    blueprintArrays: ['GetEntityTrackingsByEntityResponse.DP_EntityTracking'],
    blueprint: {
      funds: [
        '$..DP_EntityTracking',
        {
          id: '$.ID',
          trackingTypeId: '$.TrackingTypeID',
          trackingType: '$.TrackingType',
          enteredDate: '$.EnteredDate',
          trackingSource: '$.TrackingSource',
          vendorName: '$.VendorName',
          vendorService: '$.VendorService',
          isActive: '$.IsActive',
          comments: '$..Comments'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      _.each(response.funds, function(fund) {
        fund.entityId = entityId;

        if (fund.enteredDate) {
          fund.enteredDate = moment(fund.enteredDate, 'YYYY-MM-DD');
        }
        fund.trackingSource = fund.trackingSource || '--';
        fund.vendorName = fund.vendorName || '--';
        var comments = fund.comments;
        if (comments) {
          fund.comments = comments.length > 100 ? comments.substring(0, 100) + '...' : comments;
        } else {
          fund.comments = '--';
        }
        var vendorService = fund.vendorService;
        if (vendorService) {
          fund.vendorService = vendorService.length > 100 ? vendorService.substring(0, 100) + '...' : vendorService;
        } else {
          fund.vendorService = '--';
        }
      });

      defer.resolve(response.funds);
    })
    .fail(function(err) {
      defer.reject(err);
    });

  return defer.promise();
};
