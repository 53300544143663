/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');
var Ladda = require('ladda');
var _ = require('underscore');

var modelGet = require('common/util/modelGet');
var BreadcrumbView = require('common/views/BreadcrumbView');
var HeaderBarView = require('common/views/HeaderBarView');

var SubmitOrganizationLicenseFinancials = require('../services/SubmitOrganizationLicenseFinancials');
var SecOrganizationLicenseFinancialsFormView = require('./SecOrganizationLicenseFinancialsFormView');

var tmplSecOrganizationLicenseFinancialsNewView = require('../templates/SecOrganizationLicenseFinancialsNewView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplSecOrganizationLicenseFinancialsNewView,

  className: 'sec-organization-license-financials-new-view container-fluid m-b-lg',

  ui: {
    financialsFormContainer: '.financials-form-container',
    breadcrumb: '.breadcrumb-container',
    headerBar: '.header-bar-container',
    submit: '.btn-submit'
  },

  regions: {
    financialsFormContainer: '@ui.financialsFormContainer',
    breadcrumb: '@ui.breadcrumb',
    headerBar: '@ui.headerBar'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeShow: function() {
    var identifiers = this.model.get('identifiers') || {};
    var licenseId = this.model.get('licenseId') || {};
    var organizationId = modelGet(this.model, 'organizationId');

    identifiers.title = 'License';
    var headerBarColumns = [
      {
        name: 'CRDNumber',
        label: 'CRD Number',
        minWidth: '160px',
        maxWidth: '200px'
      }
    ];

    this.showChildView(
      'headerBar',
      new HeaderBarView({
        columns: new Backbone.Collection(headerBarColumns),
        model: new Backbone.Model(identifiers)
      })
    );

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#sec/dashboard',
            text: 'Home'
          },
          {
            link: '#sec/organization/' + organizationId + '/dashboard',
            text: identifiers.applicantLegalName
          },
          {
            link: '#sec/organization/' + organizationId + '/license/' + licenseId + '/dashboard',
            text: identifiers.type
          },
          {
            text: 'New Financials'
          }
        ]
      })
    );

    this.financialsForm = new SecOrganizationLicenseFinancialsFormView({
      model: this.model
    });

    this.showChildView('financialsFormContainer', this.financialsForm);

    var licenseTypeId = modelGet(this.model, 'identifiers.typeId'),
      iaLicenseTypeIds = ['2', '3'];
    if (_.contains(iaLicenseTypeIds, licenseTypeId)) {
      this.$el.find('input[name="isFinancialReportReq"][value="1"]').prop('checked', true);
    }
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    this.validate().done(
      function() {
        var laddaContext = Ladda.create(this.ui.submit.get(0));
        laddaContext.start();
        var formData = this.getFormData();
        formData.isDeleted = 0;
        SubmitOrganizationLicenseFinancials(formData)
          .done(function(response) {
            Radio.channel('navigate').trigger(
              'show:int:sc:organization:license:financials',
              formData.organizationId,
              formData.licenseId,
              response.id
            );
          })
          .fail(function() {
            laddaContext.stop();
          });
      }.bind(this)
    );
  },

  validate: function() {
    return this.ui.financialsFormContainer.parsley().whenValidate();
  },

  getFormData: function() {
    return this.financialsForm.getFormData();
  }
});
