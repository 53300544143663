/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var momentUtil = require('common/util/momentUtil');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(subFundId) {
  var defer = $.Deferred();

  $.soap({
    body: {
      GetTrustSubFund: {
        $xmlns: 'http://dobs.pa.gov/dp/fund/bpm/1.0',
        ID: subFundId
      }
    },
    blueprint: {
      id: '$..ID.__text',
      fundId: '$..TrustFundID.__text',
      fundName: '$..TrustFundName.__text',
      entityId: '$..EntityID.__text',
      entityName: '$..EntityName.__text',
      name: '$..Name.__text',
      effectiveDate: '$..EffectiveDate.__text',
      approvalDate: '$..ApprovalDate.__text',
      lastAmendmentDate: '$..LastAmendmentDate.__text',
      lastAmendmentApprovalDate: '$..LastAmendmentApprovalDate.__text',
      lastAuditDate: '$..LastAuditDate.__text',
      lastAuditOpinion: '$..LastAuditOpinion.__text',
      terminatedDate: '$..TerminatedDate.__text',
      comments: '$..Comments.__text',
      effectiveAmendDate: '$..EffectiveAmendDate.__text',
      filingId: '$..FilingID.__text'
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      var formatCfg = _.extend({}, momentUtil.defaultInboundDateConfig, { default: undefined });

      response.effectiveDate = momentUtil.formatStr(response.effectiveDate, formatCfg);
      response.approvalDate = momentUtil.formatStr(response.approvalDate, formatCfg);
      response.lastAmendmentDate = momentUtil.formatStr(response.lastAmendmentDate, formatCfg);
      response.lastAmendmentApprovalDate = momentUtil.formatStr(response.lastAmendmentApprovalDate, formatCfg);
      response.lastAuditDate = momentUtil.formatStr(response.lastAuditDate, formatCfg);
      response.terminatedDate = momentUtil.formatStr(response.terminatedDate, formatCfg);
      response.effectiveAmendDate = momentUtil.formatStr(response.effectiveAmendDate, formatCfg);

      response.lastAuditOpinion = response.lastAuditOpinion || '--';

      defer.resolve(response);
    })
    .fail(function(err) {
      defer.reject(err);
    });

  return defer.promise();
};
