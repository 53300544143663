/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(joinCode) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      SubmitNewEntityJoinCode: {
        $xmlns: 'http://dobs.pa.gov/sc/organization/bpm/1.0',
        FirstName: joinCode.firstName,
        LastName: joinCode.lastName,
        Email: joinCode.email,
        EntityID: joinCode.entityId,
        EntityExternalUserAccessLevelID: joinCode.userAccessLevelId
      }
    },
    blueprint: {
      id: '$..ID',
      firstName: '$..FirstName',
      lastName: '$..LastName',
      email: '$..Email',
      joinCode: '$..JoinCode',
      accessLevel: '$..Name'
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
