/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');

var DepBankLocationActivitiesCollectionView = require('./DepBankLocationActivitiesCollectionView');

require('./DepBankLocationActivitiesSectionView.css');
var tmplDepBankLocationActivitiesSectionView = require('./DepBankLocationActivitiesSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepBankLocationActivitiesSectionView,

  className: 'dep-bank-location-activities-section-view',

  ui: {
    topBorderContainer: '.top-border',
    existingLocationsCollectionContainer: '.existing-locations-collection-container'
  },

  regions: {
    existingLocationsCollectionContainer: '@ui.existingLocationsCollectionContainer'
  },

  behaviors: {
    ReadOnlyFormBehavior: {}
  },

  onBeforeShow: function() {
    this.existingLocations = new DepBankLocationActivitiesCollectionView({
      collection: this.collection,
      model: this.model,
      isReadOnly: this.options.isReadOnly
    });
    this.showChildView('existingLocationsCollectionContainer', this.existingLocations);

    if (!this.collection.length || this.collection.length === '0') {
      this.ui.topBorderContainer.hide();
    }
  },

  validate: function(config) {
    return this.existingLocations.validate(config);
  },

  getFormData: function() {
    return this.existingLocations.getFormData();
  }
});
