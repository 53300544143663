/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Backbone = require('backbone');
var Marionette = require('backbone.marionette');

var HeaderBarView = require('common/views/HeaderBarView');
var BreadcrumbView = require('common/views/BreadcrumbView');
var BigTabsView = require('common/views/BigTabsView');
var HeaderBarAddressCellView = require('common/views/HeaderBarView/HeaderBarAddressCellView');

var tmplInternaFilingReviewView = require('../templates/InternalFilingReviewView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplInternaFilingReviewView,

  className: 'internal-filing-review-view container-fluid',

  ui: {
    headerBar: '.header-bar',
    breadcrumb: '.breadcrumb-container',
    taskSections: '.task-sections'
  },

  regions: {
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb',
    taskSections: '@ui.taskSections'
  },

  behaviors: {
    TaskBehavior: {}
  },

  templateHelpers: function() {
    var filingDetails = this.model.get('filingDetails') || {};
    var filingTypeId = filingDetails.filingTypeId;
    // Filing type is CU Audit, Trust Audit or Trust Call Report
    // Filing type is CU Audit, Trust Audit, Trust Call Report or CIF/CTF
    var isSubmission = _.includes(['111', '205', '305', '306', '307'], filingTypeId);

    return {
      isSubmission: isSubmission
    };
  },

  onBeforeShow: function() {
    var filingDetails = this.model.get('filingDetails') || {};
    var pointOfContact = filingDetails.pointOfContact || {};
    var ppobAddress = filingDetails.ppobAddress || {};
    var filingType = filingDetails.filingType;
    var filingTypeId = filingDetails.filingTypeId;
    // Filing type is CU Audit, Trust Audit, Trust Call Report or CIF/CTF
    var isSubmission = _.includes(['111', '205', '305', '306', '307'], filingTypeId);

    var headerBarData = {
      filingType: filingDetails.filingType,
      entityName: filingDetails.entityName,
      entityType: filingDetails.entityType,
      address: {
        addressLine1: ppobAddress.addressLine1,
        addressLine2: ppobAddress.addressLine2,
        city: ppobAddress.city,
        state: ppobAddress.state,
        postalCode: ppobAddress.postalCode,
        country: ppobAddress.country,
        county: ppobAddress.county
      },
      pointOfContact: pointOfContact.name || '--'
    };

    var headerBarColumns = [
      {
        name: 'filingType',
        label: isSubmission ? 'Type' : 'Filing Type',
        minWidth: '160px',
        maxWidth: '200px'
      },
      {
        name: 'entityName',
        label: 'Entity Name',
        minWidth: '160px',
        maxWidth: '300px'
      },
      {
        name: 'entityType',
        label: 'Entity Type',
        minWidth: '160px',
        maxWidth: '300px'
      },
      {
        name: 'pointOfContact',
        label: 'Point of Contact',
        width: '160px',
        overflow: 'truncate'
      },
      {
        name: 'address',
        label: 'Principal Place of Business Address',
        minWidth: '160px',
        headerCell: HeaderBarAddressCellView
      }
    ];

    this.showChildView(
      'headerBar',
      new HeaderBarView({
        columns: new Backbone.Collection(headerBarColumns),
        model: new Backbone.Model(headerBarData)
      })
    );

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#dep/dashboard',
            text: 'Home'
          },
          {
            text: isSubmission ? 'Review Submission for ' + filingType : 'Review Filing for ' + filingType
          }
        ]
      })
    );

    var taskSections = [
      {
        id: 'filing',
        title: isSubmission ? 'Submission' : 'Filing',
        subtitle: isSubmission ? 'Contents of submission' : 'Contents of filing',
        faIconClass: 'fa-envelope',
        view: this.options.filingFormView
      },
      {
        id: 'work',
        title: 'Work',
        subtitle: isSubmission ? 'Evaluation of submission' : 'Evaluation of filing',
        faIconClass: 'fa-pencil',
        view: this.options.workTabView
      }
    ];

    this.showChildView(
      'taskSections',
      new BigTabsView({
        collection: new Backbone.Collection(taskSections)
      })
    );
  }
});
