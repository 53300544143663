/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Backgrid = require('backgrid');

var PageableCollection = require('psa-backbone/models/PageableCollection');
var PageableDeepCollection = require('psa-backbone/models/PageableDeepCollection');

var PanelRowCollectionView = require('./PanelRowCollectionView');

var tmplPanelGridView = require('./PanelGridView.hbs');

module.exports = Marionette.LayoutView.extend({
  className: 'panel-grid-view',

  template: tmplPanelGridView,

  ui: {
    panelGrid: '.panel-grid-container',
    paginator: '.paginator-container'
  },

  regions: {
    panelGrid: '@ui.panelGrid',
    paginator: '@ui.paginator'
  },

  onBeforeShow: function() {
    this.showChildView('panelGrid', new PanelRowCollectionView(this.options));

    if (this.collection instanceof PageableCollection || this.collection instanceof PageableDeepCollection) {
      this.showChildView(
        'paginator',
        new Backgrid.Extension.Paginator({
          collection: this.collection
        })
      );
      this.ui.paginator.find('ul').addClass('pagination-sm');
    }
  }
});
