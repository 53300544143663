var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "\r\n<div class=\"row\">\r\n  <div class=\"col-offset-category-and-exam col-time-hrs\">\r\n    <div class=\"panel panel-default\">\r\n      <div class=\"panel-body\">\r\n        <div class=\"row\">\r\n          <div class=\"col-hr\">\r\n            <div class=\"form-group\">\r\n              <p id=\"startDayPlus1Total\">"
    + ((stack1 = (helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.timeTotals : depth0)) != null ? stack1.startDayPlus1Total : stack1),"0.0",{"name":"default","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " Hrs</p>\r\n            </div>\r\n          </div>\r\n          <div class=\"col-hr\">\r\n            <div class=\"form-group\">\r\n              <p id=\"startDayPlus2Total\">"
    + ((stack1 = (helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.timeTotals : depth0)) != null ? stack1.startDayPlus2Total : stack1),"0.0",{"name":"default","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " Hrs</p>\r\n            </div>\r\n          </div>\r\n          <div class=\"col-hr\">\r\n            <div class=\"form-group\">\r\n              <p id=\"startDayPlus3Total\">"
    + ((stack1 = (helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.timeTotals : depth0)) != null ? stack1.startDayPlus3Total : stack1),"0.0",{"name":"default","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " Hrs</p>\r\n            </div>\r\n          </div>\r\n          <div class=\"col-hr\">\r\n            <div class=\"form-group\">\r\n              <p id=\"startDayPlus4Total\">"
    + ((stack1 = (helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.timeTotals : depth0)) != null ? stack1.startDayPlus4Total : stack1),"0.0",{"name":"default","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " Hrs</p>\r\n            </div>\r\n          </div>\r\n          <div class=\"col-hr\">\r\n            <div class=\"form-group\">\r\n              <p id=\"startDayPlus5Total\">"
    + ((stack1 = (helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.timeTotals : depth0)) != null ? stack1.startDayPlus5Total : stack1),"0.0",{"name":"default","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " Hrs</p>\r\n            </div>\r\n          </div>\r\n          <div class=\"col-hr\">\r\n            <div class=\"form-group\">\r\n              <p id=\"startDayPlus8Total\">"
    + ((stack1 = (helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.timeTotals : depth0)) != null ? stack1.startDayPlus8Total : stack1),"0.0",{"name":"default","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " Hrs</p>\r\n            </div>\r\n          </div>\r\n          <div class=\"col-hr\">\r\n            <div class=\"form-group\">\r\n              <p id=\"startDayPlus9Total\">"
    + ((stack1 = (helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.timeTotals : depth0)) != null ? stack1.startDayPlus9Total : stack1),"0.0",{"name":"default","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " Hrs</p>\r\n            </div>\r\n          </div>\r\n          <div class=\"col-hr\">\r\n            <div class=\"form-group\">\r\n              <p id=\"startDayPlus10Total\">"
    + ((stack1 = (helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.timeTotals : depth0)) != null ? stack1.startDayPlus10Total : stack1),"0.0",{"name":"default","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " Hrs</p>\r\n            </div>\r\n          </div>\r\n          <div class=\"col-hr\">\r\n            <div class=\"form-group\">\r\n              <p id=\"startDayPlus11Total\">"
    + ((stack1 = (helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.timeTotals : depth0)) != null ? stack1.startDayPlus11Total : stack1),"0.0",{"name":"default","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " Hrs</p>\r\n            </div>\r\n          </div>\r\n          <div class=\"col-hr\">\r\n            <div class=\"form-group\" >\r\n              <p id=\"startDayPlus12Total\">"
    + ((stack1 = (helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.timeTotals : depth0)) != null ? stack1.startDayPlus12Total : stack1),"0.0",{"name":"default","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " Hrs</p>\r\n            </div>\r\n          </div>\r\n          <div class=\"dep-error-message error-format collapse\">\r\n            <div class=\"form-group\" >\r\n              <p>The total hours cannot be greater than 7.5hrs.</p>\r\n            </div>\r\n          </div>\r\n          <div class=\"nondep-error-message error-format collapse\">\r\n            <div class=\"form-group\">\r\n              <p>The total hours must add up to 7.5hrs.</p>\r\n            </div>\r\n          </div>\r\n          <div class=\"sec-error-message error-format collapse\">\r\n            <div class=\"form-group\" >\r\n              <p>The total hours cannot be greater than 24hrs.</p>\r\n            </div>\r\n          </div>\r\n        </div>\r\n      </div>\r\n    </div>\r\n  </div>";
},"useData":true});