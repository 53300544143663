/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var _get = require('lodash.get');
var Backbone = require('backbone');
var NProgress = require('nprogress');

var GetExamDetailsForInternal = require('../../../common/services/GetExamDetailsForInternal');
var GetExamDetailsForEntity = require('../../../common/services/GetExamDetailsForEntity');
var GetNonDepExamBillingTimesheet = require('../../../exam-dashboard/services/GetNonDepExamBillingTimesheet');
var formatBillableTimesheet = require('../../../exam-dashboard/util/formatBillableTimesheet');
var ExamMTReviewBillingTaskView = require('./ExamMTReviewBillingTaskView');

module.exports = {
  event: 'build:nd:rmanagement-tech-review-billing-details',

  channel: 'task',

  builder: function(task) {
    var defer = $.Deferred();

    var examId = _get(task, 'taskData.ND.examId');

    $.when(GetExamDetailsForInternal(examId), GetExamDetailsForEntity(examId), GetNonDepExamBillingTimesheet(examId))
      .done(function(interalExam, entityExam, examInvoiceItems) {
        NProgress.set(0.85);

        defer.resolve(
          new ExamMTReviewBillingTaskView({
            model: new Backbone.Model({
              task: task,
              taskId: task.taskId,
              exam: _.extend(interalExam, entityExam),
              examId: examId,
              examInvoiceItems: formatBillableTimesheet(examInvoiceItems, examInvoiceItems.examHourlyRate)
            })
          })
        );
      })
      .fail(function(err) {
        defer.reject(err);
      });

    return defer.promise();
  }
};
