/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

module.exports = function(data) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      SubmitSecondaryName: {
        $xmlns: 'http://dobs.pa.gov/cf/record/bpm/1.0',
        SecondaryNameID: data.secondaryNameId,
        RecordID: data.recordId,
        LastName: data.lastName,
        FirstName: data.firstName,
        MiddleInitial: data.middleInitial,
        Suffix: data.suffix,
        Title: data.title,
        TypeID: data.typeId,
        IsDeleted: data.isDeleted
      }
    },
    blueprint: {
      secondaryNameId: '$..NewSecondaryNameID'
    }
  })
    .done(function(response) {
      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
