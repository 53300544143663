/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');

var BreadcrumbView = require('common/views/BreadcrumbView');
var EntityHeaderBarView = require('../../common/views/EntityHeaderBarView');

var EntityNameChangeApplication = require('../forms/EntityNameChangeApplication');

var tmplEntityNameChangeView = require('../templates/EntityNameChangeView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplEntityNameChangeView,

  className: 'entity-name-change-view container-fluid',

  ui: {
    headerBarContainer: '.header-bar-container',
    breadCrumbContainer: '.breadcrumb-container',
    entityNameChangeFormContainer: '.entity-name-change-form-container'
  },

  regions: {
    headerBarContainer: '@ui.headerBarContainer',
    breadCrumbContainer: '@ui.breadCrumbContainer',
    entityNameChangeFormContainer: '@ui.entityNameChangeFormContainer'
  },

  templateHelpers: function() {
    return {
      viewMode: this.options.viewMode
    };
  },

  onBeforeShow: function() {
    var entityName = this.model.get('entityName');
    var entityId = this.model.get('entityId');

    this.showChildView(
      'headerBarContainer',
      new EntityHeaderBarView({
        model: this.model
      })
    );

    this.showChildView(
      'breadCrumbContainer',
      new BreadcrumbView({
        breadcrumbs: [
          {
            text: 'Home',
            link: '#ext/nondep/dashboard'
          },
          {
            text: entityName,
            link: '#ext/nondep/entity/' + entityId + '/dashboard'
          },
          {
            text: 'Edit Name'
          }
        ]
      })
    );

    this.showChildView(
      'entityNameChangeFormContainer',
      new EntityNameChangeApplication({
        model: this.model
      })
    );

    Radio.channel('nd:submit-application').on('submit', function() {
      Radio.channel('navigate').trigger('show:ext:nd:entity-dashboard', entityId);
    });
  }
});
