/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(entityId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetSubsidiariesByEntity: {
        $xmlns: 'http://dobs.pa.gov/dp/subsidiary/bpm/1.0',
        EntityID: entityId
      }
    },
    blueprintArrays: ['GetSubsidiariesByEntityResponse.DP_EntitySubsidiary'],
    blueprint: {
      subsidiaries: [
        '$..DP_EntitySubsidiary',
        {
          id: '$..ID',
          entityId: '$..EntityID',
          name: '$..Name',
          status: '$..Status',
          type: '$..Type',
          statusDate: '$..StatusDate',
          approvalDate: '$..ApprovalDate',
          comments: '$..Comments'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      _.each(response.subsidiaries, function(subsidiary) {
        if (!subsidiary.name) {
          subsidiary.name = '--';
        }
        if (!subsidiary.status) {
          subsidiary.status = '--';
        }
        if (!subsidiary.type) {
          subsidiary.type = '--';
        }
        if (subsidiary.statusDate) {
          subsidiary.statusDate = moment(subsidiary.statusDate, 'YYYY-MM-DD');
        }
        if (subsidiary.approvalDate) {
          subsidiary.approvalDate = moment(subsidiary.approvalDate, 'YYYY-MM-DD');
        }
        var comments = subsidiary.comments;
        if (comments) {
          subsidiary.comments = comments.length > 100 ? comments.substring(0, 100) + '...' : comments;
        } else {
          subsidiary.comments = '--';
        }
      });
      deferred.resolve(response.subsidiaries);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
