/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Ladda = require('ladda');

var GetUsersForRole = require('../../services/GetUsersForRole');
var SetTaskAssignment = require('../../services/SetTaskAssignment');
var modelGet = require('../../util/modelGet');
var SpinnerView = require('../SpinnerView');

var NewAssigneeSelectView = require('./NewAssigneeSelectView');

var tmplTaskAssignModalView = require('./TaskAssignModalView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplTaskAssignModalView,

  className: 'task-assign-modal-view',

  ui: {
    reassignTaskForm: '.assign-task-form',
    newAssigneeContainer: '.new-assignee-container',
    submit: '.submit'
  },

  regions: {
    newAssigneeContainer: '@ui.newAssigneeContainer'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeShow: function() {
    var self = this;
    this.showChildView(
      'newAssigneeContainer',
      new SpinnerView({
        spinner: {
          scale: '0.8'
        }
      })
    );
    var targetRole = this.model.get('targetRole');
    var assignee = modelGet(this.model, 'task.assignee');

    GetUsersForRole(targetRole).done(function(users) {
      self.showChildView(
        'newAssigneeContainer',
        new NewAssigneeSelectView({
          model: new Backbone.Model({
            users: users,
            targetRole: targetRole,
            assignee: assignee
          })
        })
      );
      self.ui.submit.prop('disabled', false);
    });
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;
    this.ui.reassignTaskForm
      .parsley()
      .whenValidate()
      .done(function() {
        var l = Ladda.create(self.ui.submit[0]);
        l.start();
        var formData = Syphon.serialize(self);
        formData.taskId = modelGet(self.model, 'task.taskId');
        SetTaskAssignment(formData)
          .done(function() {
            // Reload page after task has been successfully assigned.
            Backbone.history.loadUrl();
            self.triggerMethod('modal:close');
          })
          .fail(function() {
            self.triggerMethod('modal:close');
          });
      });
  }
});
