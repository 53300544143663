/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var NProgress = require('nprogress');

var NavUtils = require('psa-core/util/nav');

var Scaffold = require('common/layout/Scaffold');

var GetEntity = require('../services/GetEntity');

var ConSerEntityJoinIndividualView = require('../views/ConSerEntityJoinIndividualView');

module.exports = function(entityId) {
  NProgress.start();
  NProgress.set(0.6);

  GetEntity(entityId)
    .done(function(entity) {
      NavUtils.navigate('conser/entity/' + entityId + '/join-individual');
      Scaffold.content.show(
        new ConSerEntityJoinIndividualView({
          model: new Backbone.Model({ entityId: entityId, entity: entity })
        })
      );
      NProgress.done();
    })
    .fail(function() {
      NProgress.done();
    });
};
