/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');

var EmptySetView = require('common/views/EmptySetView');

var NonDepLicenseContactDetailView = require('./NonDepLicenseContactDetailView');

module.exports = Marionette.CollectionView.extend({
  className: 'non-dep-license-contact-detail-collection-view',

  childView: NonDepLicenseContactDetailView,

  emptyView: EmptySetView,

  emptyViewOptions: function() {
    var contactTypeName = this.options.contactTypeName;
    return {
      emptyText: 'No ' + contactTypeName + ' Contacts Found.'
    };
  },

  childViewOptions: function() {
    return {
      viewMode: this.options.viewMode
    };
  }
});
