/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

module.exports = function(entities) {
  return $.soap({
    body: {
      SubmitEntityAssetsBulkUpdate: {
        $xmlns: 'http://dobs.pa.gov/dp/entity/bpm/1.0',
        Entities: (function() {
          if (!entities || !entities.length) {
            return {};
          }
          return {
            Entity: _.map(entities, function(entity) {
              return {
                EntityID: entity.entityId,
                NewAssetsAmount: entity.newAssetsAmount,
                NewAssetsAsOfDate: entity.newAssetsAsOfDate
              };
            })
          };
        })()
      }
    }
  });
};
