/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');

var Session = require('psa-core/Session');
var UIUtil = require('psa-core/util/ui');

var userHasRole = require('common/util/userHasRole');
var SideNavSectionView = require('common/views/SideNavSectionView');
var ContentServerLinkView = require('common/views/ContentServerLinkView');
var BreadcrumbView = require('common/views/BreadcrumbView');
var DropdownView = require('common/views/DropdownView');
var ConfirmationModalView = require('common/views/ConfirmationModalView');

var SecExamInvInqSectionNextStepsView = require('../../common/views/SecExamInvInqSectionNextStepsView');
var SecExamHeaderBarView = require('./SecExamHeaderBarView');
var SecExamSectionGeneralView = require('./SecExamSectionGeneralView');
var SubmitExam = require('../services/SubmitExam');
var CancelExamWorkflowTasks = require('../services/CancelExamWorkflowTasks');

var tmplSecExamDashboardView = require('../templates/SecExamDashboardView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplSecExamDashboardView,

  className: 'sec-exam-dashboard-view container-fluid',

  ui: {
    headerBar: '.header-bar-container',
    breadcrumb: '.breadcrumb-container',
    actionDropdown: '.exam-action-drop-down',
    examDashboard: '.exam-dashboard-container',
    csLinkContainer: '.cs-link-container',
    save: '.save'
  },

  regions: {
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb',
    actionDropdown: '@ui.actionDropdown',
    examDashboard: '@ui.examDashboard',
    csLinkContainer: '@ui.csLinkContainer'
  },

  onBeforeShow: function() {
    var exam = this.model.get('exam') || {};
    var examIdentifiers = this.model.get('examIdentifiers') || {};
    var examId = this.model.get('examId');

    var examNumber = examIdentifiers.examNumber;
    var workflowStatusId = exam.workflowStatusId;
    var organizationId = examIdentifiers.organizationId;
    var organizationLicenseId = examIdentifiers.organizationLicenseId;
    var staffMembers = this.model.get('staffMembers');
    var isManager = userHasRole('SC - Manager');
    var isExaminer = userHasRole('SC - Examiner');
    var isExamChief = userHasRole('SC - Exam Chief');
    var isChiefAccounting = userHasRole('SC - Chief Accounting');
    var isFilingExaminer = userHasRole('SC - Filing Examiner');

    examIdentifiers.title = 'Exam';
    this.showChildView(
      'headerBar',
      new SecExamHeaderBarView({
        model: new Backbone.Model(examIdentifiers)
      })
    );

    this.showChildView(
      'csLinkContainer',
      new ContentServerLinkView({
        model: new Backbone.Model({
          buttonText: 'Open Exam Folder',
          contentServerId: exam.CSFolderId
        })
      })
    );

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#sec/dashboard',
            text: 'Home'
          },
          {
            link: '#sec/organization/' + organizationId + '/dashboard',
            text: examIdentifiers.applicantLegalName
          },
          {
            link: '#sec/organization/' + organizationId + '/license/' + organizationLicenseId + '/dashboard',
            text: examIdentifiers.licenseType
          },
          {
            text: 'Exam (' + examNumber + ')'
          }
        ]
      })
    );

    var dropDownOptions = [
      {
        text: 'Edit General Section',
        link: '#sec/exam/' + examId + '/edit'
      },
      {
        isDivider: true
      },
      {
        text: 'Add Staff Member',
        link: '#sec/exam/' + examId + '/new-staff-member'
      },
      {
        text: 'Add Referral',
        link: '#sec/exam/' + examId + '/new-referral'
      },
      {
        text: 'Add Compliance',
        link: '#sec/exam/' + examId + '/new-compliance'
      }
    ];

    var editNextSteps =
      userHasRole('SC - Exam Supervisor') || _.contains(_.pluck(staffMembers, 'staffMember'), Session.user.userName);

    if (editNextSteps) {
      dropDownOptions.push({
        text: 'Edit Next Steps Section',
        link: '#sec/exam/' + examId + '/edit-next-steps'
      });
    }

    if (isManager || isExaminer || isChiefAccounting || isFilingExaminer) {
      dropDownOptions = dropDownOptions.concat(
        {
          isDivider: true
        },
        {
          text: 'Initiate Correspondence',
          link: '#sec/exam/' + examId + '/initiate-correspondence'
        }
      );
    }

    if (isExamChief) {
      if (workflowStatusId === '3') {
        dropDownOptions = dropDownOptions.concat(
          {
            isDivider: true
          },
          {
            text: 'Delete Provide Response to RFI Task',
            onClick: this.onClickCancelExamWorkflowTasks.bind(this)
          }
        );
      } else if (workflowStatusId === '5') {
        dropDownOptions = dropDownOptions.concat(
          {
            isDivider: true
          },
          {
            text: 'Delete Provide Response to Subsequent Request',
            onClick: this.onClickCancelExamWorkflowTasks.bind(this)
          }
        );
      }
    }

    this.showChildView(
      'actionDropdown',
      new DropdownView({
        rightAlign: true,
        model: new Backbone.Model({
          name: 'Exam Actions',
          options: dropDownOptions
        })
      })
    );

    var sideNavTabs = [
      {
        id: 'general',
        title: 'General',
        view: new SecExamSectionGeneralView({ model: new Backbone.Model(exam) })
      },
      {
        id: 'relatedSubjects',
        title: 'Related Subject List',
        view: Radio.channel('sc').request('build:exam-inv-inq-section-related-subject-view', {
          objectId: examId,
          objectTypeId: 'EXAM'
        })
      },
      {
        id: 'staffMembers',
        title: 'Staff Assigned',
        view: Radio.channel('sc').request('build:exam-inv-inq-section-staff-member-view', {
          objectId: examId,
          objectTypeId: 'EXAM'
        })
      },
      {
        id: 'referrals',
        title: 'Referrals',
        view: Radio.channel('sc').request('build:exam-inv-inq-section-referral-view', {
          objectId: examId,
          objectTypeId: 'EXAM'
        })
      },
      {
        id: 'compliances',
        title: 'Compliance',
        view: Radio.channel('sc').request('build:exam-inv-inq-section-compliance-view', {
          objectId: examId,
          objectTypeId: 'EXAM'
        })
      },
      {
        id: 'nextSteps',
        title: 'Next Steps',
        view: new SecExamInvInqSectionNextStepsView({ model: new Backbone.Model(exam) })
      },
      {
        id: 'notes',
        title: 'Notes',
        view: Radio.channel('sc').request('build:notes-view', { objectId: examId, objectTypeId: 'EXAM' })
      },
      {
        id: 'auditLog',
        title: 'Audit Log',
        view: Radio.channel('sc').request('build:audit-log-view', { objectId: examId, objectTypeId: 'EXAM' })
      }
    ];

    this.dashboard = new SideNavSectionView({
      collection: new Backbone.Collection(sideNavTabs),
      useNavigationButtons: false
    });

    this.showChildView('examDashboard', this.dashboard);
  },

  onClickCancelExamWorkflowTasks: function() {
    var self = this;
    var taskTitle =
      self.model.get('exam').workflowStatusId === '3'
        ? 'Provide Response to RFI Task'
        : 'Provide Response to Subsequent Request';

    UIUtil.showModal({
      title: 'Confirm Deleting ' + taskTitle,
      view: new ConfirmationModalView({
        confirmText: 'Delete ' + taskTitle,
        model: new Backbone.Model({
          message:
            'Are you sure you want to delete this external task? The task will be deleted and the exam workflow will continue.',
          onConfirm: function() {
            var tasks = self.model.get('tasks');
            return CancelExamWorkflowTasks(tasks);
          },
          onConfirmDone: function() {
            var examId = self.model.get('examId');
            Radio.channel('navigate').trigger('show:int:sc:exam-dashboard', examId);
          }
        })
      })
    });
  },

  onClickDelete: function() {
    SubmitExam({ examId: this.model.get('examId'), isDeleted: '1' }).done(function() {
      Radio.channel('navigate').trigger('show:int:sc:dashboard');
    });
  }
});
