/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(data) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetLicenseAddressByObject: {
        $xmlns: 'http://dobs.pa.gov/sc/licenseaddress/bpm/1.0',
        ObjectTypeID: data.objectTypeId,
        ObjectID: data.objectId
      }
    },
    blueprintArrays: ['GetLicenseAddressByObjectResponse.SC_LicenseAddress'],
    blueprint: {
      addresses: [
        '$..SC_LicenseAddress',
        {
          id: '$..ID',
          objectTypeId: '$..ObjectTypeID',
          objectId: '$..ObjectID',
          addressId: '$..AddressID',
          address: {
            addressLine1: '$..AddressLine1',
            addressLine2: '$..AddressLine2',
            addressLine3: '$..AddressLine3',
            county: '$..County',
            city: '$..City',
            state: '$..State',
            postalCode: '$..PostalCode',
            country: '$..Country'
          },
          county: '$..County',
          country: '$..Country',
          typeId: '$..TypeID',
          isPrincipal: '$..IsPrincipal',
          type: '$..Type'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      _.each(response.addresses, function(address) {
        if (address.isPrincipal) {
          address.isPrincipalText = address.isPrincipal === '1' ? 'Yes' : 'No';
        } else {
          address.isPrincipalText = '--';
        }
        address.type = address.type || '--';
        address.country = address.country || '--';
      });
      deferred.resolve(response.addresses);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
