var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "  <div class=\"col-sm-12 col-md-6\">\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-3 col-md-4\">Total Offering Amount</label>\r\n      <div class=\"form-control-static col-sm-9 col-md-8\">\r\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isNoDollarAmount : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + "      </div>\r\n    </div>\r\n  </div>\r\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "        No $ Amount\r\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "        "
    + container.escapeExpression((helpers["default"] || (depth0 && depth0["default"]) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.totalOfferingAmountDisplay : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "\r\n";
},"6":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<div class=\"row\">\r\n  <div class=\"col-sm-12 col-md-6\">\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-3 col-md-4\">FYE On</label>\r\n      <div class=\"form-control-static col-sm-9 col-md-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.fyeOn : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\r\n    </div>\r\n  </div>\r\n  <div class=\"col-sm-12 col-md-6\">\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-3 col-md-4\">FYE Change</label>\r\n      <div class=\"form-control-static col-sm-9 col-md-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.fyeChange : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\r\n    </div>\r\n  </div>\r\n</div>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<div class=\"row\">\r\n  <div class=\"col-sm-12 col-md-6\">\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-3 col-md-4\">Status</label>\r\n      <div class=\"form-control-static col-sm-9 col-md-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.status : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\r\n    </div>\r\n  </div>\r\n</div>\r\n<div class=\"row\">\r\n  <div class=\"col-sm-12 col-md-6\">\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-3 col-md-4\">Investment Type</label>\r\n      <div class=\"form-control-static col-sm-9 col-md-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.recordType : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\r\n    </div>\r\n  </div>\r\n  <div class=\"col-sm-12 col-md-6\">\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-3 col-md-4\">File Number</label>\r\n      <div class=\"form-control-static col-sm-9 col-md-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.fileNumber : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\r\n    </div>\r\n  </div>\r\n</div>\r\n<div class=\"row\">\r\n  <div class=\"col-sm-12 col-md-6\">\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-3 col-md-4\">File Number Suffix</label>\r\n      <div class=\"form-control-static col-sm-9 col-md-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.fileNumberSuffix : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\r\n    </div>\r\n  </div>\r\n  <div class=\"col-sm-12 col-md-6\">\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-3 col-md-4\">Fund Name</label>\r\n      <div class=\"form-control-static col-sm-9 col-md-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.recordName : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\r\n    </div>\r\n  </div>\r\n</div>\r\n<div class=\"row\">\r\n  <div class=\"col-sm-12 col-md-6\">\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-3 col-md-4\">Total Offering Indefinite</label>\r\n      <div class=\"form-control-static col-sm-9 col-md-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.isIndefiniteOfferingDisplay : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\r\n    </div>\r\n  </div>\r\n</div>\r\n<div class=\"row\">\r\n"
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias2).call(alias1,(depth0 != null ? depth0.isIndefiniteOffering : depth0),"===","0",{"name":"ifCond","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  <div class=\"col-sm-12 col-md-6\">\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-3 col-md-4\">Date Filed</label>\r\n      <div class=\"form-control-static col-sm-9 col-md-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.filedDate : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\r\n    </div>\r\n  </div>\r\n</div>\r\n<div class=\"row\">\r\n  <div class=\"col-sm-12\">\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-3 col-md-2\">Securities Description</label>\r\n      <p class=\"form-control-static col-sm-9 col-md-9\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.securitiesDescription : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</p>\r\n    </div>\r\n  </div>\r\n</div>\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isMF : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "<div class=\"row\">\r\n  <div class=\"col-sm-12 col-md-6\">\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-3 col-md-4\">State of Formation</label>\r\n      <div class=\"form-control-static col-sm-9 col-md-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.stateOfFormation : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\r\n    </div>\r\n  </div>\r\n  <div class=\"col-sm-12 col-md-6\">\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-3 col-md-4\">SEC Effective Date</label>\r\n      <div class=\"form-control-static col-sm-9 col-md-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.securitiesEffectiveDate : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\r\n    </div>\r\n  </div>\r\n</div>\r\n<div class=\"row\">\r\n  <div class=\"col-sm-12 col-md-6\">\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-3 col-md-4\">Entity Type</label>\r\n      <div class=\"form-control-static col-sm-9 col-md-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.entityType : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\r\n    </div>\r\n  </div>\r\n  <div class=\"col-sm-12 col-md-6\">\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-3 col-md-4\">Examiner</label>\r\n      <div class=\"form-control-static col-sm-9 col-md-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.examiner : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\r\n    </div>\r\n  </div>\r\n</div>\r\n<div class=\"row\">\r\n  <div class=\"col-sm-12 col-md-6\">\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-3 col-md-4\">Date Sent to Record Center</label>\r\n      <div class=\"form-control-static col-sm-9 col-md-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.recordCenterSentDate : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\r\n    </div>\r\n  </div>\r\n  <div class=\"col-sm-12 col-md-6\">\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-3 col-md-4\">Date Destroyed</label>\r\n      <div class=\"form-control-static col-sm-9 col-md-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.dateDestroyed : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\r\n    </div>\r\n  </div>\r\n</div>\r\n";
},"useData":true});