/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var GridView = require('psa-marionette/views/GridView');

var modelGet = require('common/util/modelGet');
var DollarCell = require('common/backgrid/DollarCell');
var AddressDisplayView = require('common/views/AddressDisplayView');
var downloadDocument = require('common/util/downloadDocument');

var tmplNonDepExamGeneralInformationSectionView = require('../templates/NonDepExamGeneralInformationSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepExamGeneralInformationSectionView,

  className: 'non-dep-exam-general-information-section-view container-fluid',

  ui: {
    violationsTable: '.violations-table',
    address: '.address',
    examDocument: '.exam-document',
    closingLetter: '.closing-letter',
    setupLetter: '.setup-letter'
  },

  regions: {
    violationsTable: '@ui.violationsTable',
    address: '@ui.address'
  },

  events: {
    'click @ui.examDocument': 'onClickExamDocument',
    'click @ui.closingLetter': 'onClickClosingLetter',
    'click @ui.setupLetter': 'onClickSetupLetter'
  },

  onBeforeShow: function() {
    var address = {
      addressLine1: this.model.get('exam').addressLine1,
      addressLine2: this.model.get('exam').addressLine2,
      addressLine3: this.model.get('exam').addressLine3,
      city: this.model.get('exam').city,
      state: this.model.get('exam').state,
      postalCode: this.model.get('exam').postalCode
    };

    this.showChildView(
      'address',
      new AddressDisplayView({
        model: new Backbone.Model(address)
      })
    );

    this.showChildView(
      'violationsTable',
      new GridView({
        columns: [
          {
            name: 'violationCodeName',
            label: 'Violation Code',
            cell: 'string',
            headerCell: 'custom',
            width: 33,
            editable: false
          },
          {
            name: 'correctiveActionName',
            label: 'Corrective Action Code',
            cell: 'string',
            headerCell: 'custom',
            width: 33,
            editable: false
          },
          {
            name: 'refundAmount',
            label: '$ Amount',
            cell: DollarCell,
            headerCell: 'custom',
            width: 33,
            editable: false
          }
        ],
        collection: new Backbone.Collection(this.model.get('exam').examViolations),
        emptyText: 'No Violations.'
      })
    );
  },

  onClickExamDocument: function(e) {
    e.preventDefault();
    downloadDocument({
      fileId: modelGet(this.model, 'exam.examDocument.fileId'),
      fileName: modelGet(this.model, 'exam.examDocument.fileName')
    });
  },

  onClickClosingLetter: function(e) {
    e.preventDefault();
    downloadDocument({
      fileId: this.model.get('exam').closingLetterDocumentId,
      fileName: this.model.get('exam').closingLetterDocumentName
    });
  },

  onClickSetupLetter: function(e) {
    e.preventDefault();
    downloadDocument({
      fileId: this.model.get('exam').setupLetterDocumentId,
      fileName: this.model.get('exam').setupLetterDocumentName
    });
  }
});
