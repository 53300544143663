/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');

var GridView = require('psa-marionette/views/GridView');

var ParagraphCell = require('common/backgrid/ParagraphCell');
var MomentCell = require('common/backgrid/MomentCell');

module.exports = GridView.extend({
  className: 'audit-log-view',

  options: {
    columns: [
      {
        name: 'createdBy',
        label: 'User',
        cell: 'string',
        headerCell: 'custom',
        width: 20,
        editable: false
      },
      {
        name: 'message',
        label: 'Action',
        cell: ParagraphCell,
        headerCell: 'custom',
        width: 60,
        editable: false
      },
      {
        name: 'createdDate',
        label: 'Date',
        cell: MomentCell.MomentCell,
        formatter: MomentCell.MomentDateTimeFormatter.extend({
          emptyValue: '--'
        }),
        sortValue: MomentCell.MomentSortValue,
        headerCell: 'custom',
        width: 20,
        editable: false
      }
    ],
    emptyText: 'No Audit Log Entries',
    collection: this.collection || new Backbone.Collection()
  }
});
