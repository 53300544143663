/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Backbone = require('backbone');

var GetLicenseOfficeManagerChangeApplication = require('../services/GetLicenseOfficeManagerChangeApplication');
var GetOfficeManagerByLicense = require('../services/GetOfficeManagerByLicense');

var LicenseOfficeManagerChangeApplication = require('../forms/LicenseOfficeManagerChangeApplication');

module.exports = {
  event: 'build:amendment:license-office-manager-change',

  channel: 'nd:application',

  builder: function(config) {
    var defer = $.Deferred();

    GetLicenseOfficeManagerChangeApplication(config.applicationId)
      .done(function(appData) {
        GetOfficeManagerByLicense(appData.licenseId).done(function(oldIndividual) {
          defer
            .resolve(
              new LicenseOfficeManagerChangeApplication({
                isReadOnly: config.isReadOnly,
                viewMode: config.viewMode,
                mode: config.mode,
                model: new Backbone.Model(_.extend(appData, { oldIndividual: oldIndividual }))
              })
            )
            .fail(function(err) {
              defer.reject(err);
            });
        });
      })
      .fail(function(err) {
        defer.reject(err);
      });

    return defer.promise();
  }
};
