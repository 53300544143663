/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');

var TimeTrkSecEntryRowView = require('./TimeTrkSecEntryRowView');

module.exports = Marionette.CollectionView.extend({
  className: 'time-trk-sec-entry-row-collection-view',

  childView: TimeTrkSecEntryRowView,

  childEvents: {
    'remove:entry': 'onChildClickRemoveEntry'
  },

  childViewOptions: function(model, index) {
    return {
      childIndex: index,
      model: model,
      isReadOnly: this.options.isReadOnly,
      timesheet: this.model.get('timesheet'),
      categories: this.model.get('categories'),
      indLicenseTypes: this.model.get('indLicenseTypes'),
      orgLicenseTypes: this.model.get('orgLicenseTypes')
    };
  },

  onChildClickRemoveEntry: function(childView) {
    this.collection.remove(childView.model);
    this.triggerMethod('update:hour-totals'); // TimeTrkSecExaminerEntryView
  },

  getTimeEntries: function() {
    if (this.collection.toJSON().length) {
      var timeEntryArr = _.map(this.collection.toJSON(), function(row) {
        if (row.timeEntries) {
          return row.timeEntries;
        } else {
          return {
            startDayPlus1Total: 0.0,
            startDayPlus2Total: 0.0,
            startDayPlus3Total: 0.0,
            startDayPlus4Total: 0.0,
            startDayPlus5Total: 0.0,
            startDayPlus8Total: 0.0,
            startDayPlus9Total: 0.0,
            startDayPlus10Total: 0.0,
            startDayPlus11Total: 0.0,
            startDayPlus12Total: 0.0
          };
        }
      });
    }
    return timeEntryArr;
  },

  getFormData: function() {
    return this.children.map(function(child) {
      return child.getFormData();
    });
  }
});
