/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var moment = require('moment');
var _ = require('underscore');

var modelGet = require('common/util/modelGet');

var tmplSecOrganizationLicenseFinancialsFormView = require('../templates/SecOrganizationLicenseFinancialsFormView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplSecOrganizationLicenseFinancialsFormView,

  className: 'sec-organization-license-financials-form-view',

  ui: {
    financialYear: '.financial-year',
    fiscalYearEnd: '.fiscal-year-end',
    FSDueDate: '.fs-due-date',
    FSFiledDate: '.fs-filed-date',
    FSClearedDate: '.fs-cleared-date',
    custodyDate: '.custody-date',
    discretionDate: '.discretion-date',
    isFinancialReportReq: 'input[name="isFinancialReportReq"]',
    isDiscretion: 'input[name="isDiscretion"]',
    isCustody: 'input[name="isCustody"]',
    netWorthRequiredAmount: 'input[name="netWorthReqAmount"]',
    isAuditRequired: 'input[name=isAuditReq]',
    
  },

  events: {
    'change @ui.fiscalYearEnd': 'onChangeFiscalYearEnd',
    'change @ui.isFinancialReportReq': 'setNetWorthRequiredAmount',
    'change @ui.isDiscretion': 'setNetWorthRequiredAmount',
    'change @ui.isCustody': 'setNetWorthRequiredAmount',
    'change @ui.isAuditReq': 'setAuditRequired'
  },

  templateHelpers: function() {
    var chiefAccountingUsers = modelGet(this.model, 'chiefAccountingUsers', []);

    var fsAssignedToUsers = _.clone(chiefAccountingUsers);
    var fsAssignedTo = modelGet(this.model, 'financials.FSAssignedTo');
    var fsAssignedToName = modelGet(this.model, 'financials.FSAssignedToName');

    var fsClearedByUsers = _.clone(chiefAccountingUsers);
    var fsClearedBy = modelGet(this.model, 'financials.FSClearedBy');
    var fsClearedByName = modelGet(this.model, 'financials.FSClearedByName');

    var currentFSAssigneeHasRole = _.find(fsAssignedToUsers, function(cm) {
      return fsAssignedTo === cm.userId;
    });

    if (fsAssignedTo && !currentFSAssigneeHasRole) {
      var currentFsAssignee = {
        userId: fsAssignedTo,
        name: fsAssignedToName
      };
      fsAssignedToUsers.splice(_.sortedIndex(fsAssignedToUsers, currentFsAssignee, 'name'), 0, currentFsAssignee);
    }

    var currentFSClearedByHasRole = _.find(fsClearedByUsers, function(cm) {
      return fsClearedBy === cm.userId;
    });

    if (fsClearedBy && !currentFSClearedByHasRole) {
      var currentFSClearedBy = {
        userId: fsClearedBy,
        name: fsClearedByName
      };
      fsClearedByUsers.splice(_.sortedIndex(fsClearedByUsers, currentFSClearedBy, 'name'), 0, currentFSClearedBy);
    }

    return {
      fsAssignedToUsers: fsAssignedToUsers,
      fsClearedByUsers: fsClearedByUsers
    };
  },

  onBeforeShow: function() {
    Syphon.deserialize(this, this.model.get('financials'));

    var latestFinancial = this.model.get('latestFinancial');
    if (latestFinancial) {
      if (latestFinancial.financialYear) {
        latestFinancial.financialYear++;
      }
      Syphon.deserialize(this, latestFinancial);
      this.onChangeFiscalYearEnd();
    }

    this.ui.financialYear.datepicker({
      format: 'yyyy',
      viewMode: 'years',
      minViewMode: 'years'
    });

    this.ui.fiscalYearEnd.datepicker({
      format: 'mm/dd/yyyy',
      maxViewMode: 'months'
    });

    this.ui.FSDueDate.datepicker();
    this.ui.FSFiledDate.datepicker();
    this.ui.FSClearedDate.datepicker();
    this.ui.custodyDate.datepicker();
    this.ui.discretionDate.datepicker();
  },

  onChangeFiscalYearEnd: function() {
    var fiscalYearEnd = this.ui.fiscalYearEnd.val();
    
    var FSDueDate = '';
        
    if (fiscalYearEnd) {
      FSDueDate = moment(fiscalYearEnd).add(120, 'days').format('MM/DD/YYYY');
    }
    
    this.ui.FSDueDate.val(FSDueDate);
  },
  
  setNetWorthRequiredAmount: function() {
    var formData = Syphon.serialize(this);
    var isFinancialReportReq = formData.isFinancialReportReq;
    var isDiscretion = formData.isDiscretion;
    var isCustody = formData.isCustody;
    var netWorthRequiredAmount = formData.netWorthReqAmount;

    if (isFinancialReportReq === '0') {
      netWorthRequiredAmount = 0;
    } else if (isFinancialReportReq === '1' && isCustody === '1') {
      netWorthRequiredAmount = 35000;
    } else if (isFinancialReportReq === '1' && isCustody === '0' && isDiscretion === '1') {
      netWorthRequiredAmount = 10000;
    }

    this.ui.netWorthRequiredAmount.val(netWorthRequiredAmount);
    this.setAuditRequired();
  },

  setAuditRequired: function() {   
    if (this.ui.isCustody[0].checked) {
      this.ui.isAuditRequired.filter('[value="1"]').prop('checked', true);
    }
    if (this.ui.isCustody[1].checked) {
      this.ui.isAuditRequired.filter('[value="0"]').prop('checked', true);
    }
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    formData.organizationId = this.model.get('organizationId');
    formData.licenseId = this.model.get('licenseId');
    formData.financialsId = this.model.get('financialsId');
    return formData;
  }
});
