/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var parsleyUtil = require('common/util/parsleyUtil');

var CreditServicesLoanBrokerLenderCollectionView = require('./CreditServicesLoanBrokerLendersCollectionView');

var tmplCreditServicesLoanBrokerLendersSectionView = require('./CreditServicesLoanBrokerLendersSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplCreditServicesLoanBrokerLendersSectionView,

  className: 'credit-services-loan-broker-lenders-section-view',

  ui: {
    addLender: '.btn-add-lender',
    lenderCollectionContainer: '.broker-lenders-collection-container'
  },

  regions: {
    lenderCollectionContainer: '@ui.lenderCollectionContainer'
  },

  events: {
    'click @ui.addLender': 'onClickAddLender'
  },

  onBeforeShow: function() {
    this.brokers = new CreditServicesLoanBrokerLenderCollectionView({
      isReadOnly: this.options.isReadOnly,
      collection: new Backbone.Collection(this.model.get('lenders'))
    });

    this.showChildView('lenderCollectionContainer', this.brokers);
  },

  onClickAddLender: function(e) {
    e.preventDefault();
    this.brokers.collection.unshift({});
  },

  validate: function(config) {
    this.ui.lenderCollectionContainer.parsley().reset();

    if (config.type === 'save') {
      parsleyUtil.disableRequiredValidation(this.ui.lenderCollectionContainer);
    } else {
      parsleyUtil.enableRequiredValidation(this.ui.lenderCollectionContainer);
    }

    return this.ui.lenderCollectionContainer.parsley().whenValidate();
  },

  getFormData: function() {
    return this.brokers.getFormData();
  }
});
