var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "DOR";
},"3":function(container,depth0,helpers,partials,data) {
    return "MRBA";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return "\r\n<div class=\"page-title clearfix\">\r\n  <h1 class=\"pull-left m-n p-n\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.entityIdentifiers : depth0)) != null ? stack1.entityName : stack1), depth0))
    + "</h1>\r\n  <div class=\"mrba-action-drop-down pull-right\"></div>\r\n</div>\r\n<div class=\"page-subtitle\">\r\n  <h2>"
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.isCU : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "</h2>\r\n  <div class=\"page-subtitle-details\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.mrba : depth0)) != null ? stack1.category : stack1), depth0))
    + "</div>\r\n</div>\r\n<div class=\"breadcrumb-container\"></div>\r\n<div class=\"col-sm-12 col-md-offset-1 col-md-10 m-b-md\">\r\n  <div class=\"row\">\r\n    <h3 class=\"m-t-n col-sm-offset-2 col-sm-10\">Edit "
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.isCU : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "</h3>\r\n  </div>\r\n  <form class=\"mrba-form-container\"></form>\r\n  <div class=\"row\">\r\n    <div class=\"col-sm-offset-3 col-sm-9 col-md-offset-2 col-md-10\">\r\n      <a href=\"#dep/entity/"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.mrba : depth0)) != null ? stack1.entityId : stack1), depth0))
    + "/mrba/"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.mrba : depth0)) != null ? stack1.mrbaId : stack1), depth0))
    + "/dashboard\" class=\"btn btn-default\">Cancel</a>\r\n      <button class=\"btn btn-primary submit ladda-button\" data-style=\"expand-right\" type=\"button\">\r\n        <span class=\"ladda-label\">Submit</span>\r\n      </button>\r\n    </div>\r\n  </div>\r\n</div>";
},"useData":true});