/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

module.exports = function(data) {
  var defer = $.Deferred();

  $.soap({
    body: {
      UpdatePayment: {
        $xmlns: 'http://dobs.pa.gov/dp/payment/bpm/1.0',
        PaymentObject: data.paymentObjectType,
        PaymentObjectId: data.paymentObjectId,
        TaskId: data.taskId
      }
    },
    blueprint: {
      x_login: '$..x_login',
      x_fp_sequence: '$..x_fp_sequence',
      x_fp_timestamp: '$..x_fp_timestamp',
      x_fp_hash: '$..x_fp_hash',
      x_invoice_num: '$..x_invoice_num',
      transactionId: '$..TransactionID',
      taskId: '$..TaskID.__text'
    }
  })
    .done(function(response) {
      defer.resolve(response);
    })
    .fail(function(err) {
      defer.reject(err);
    });

  return defer.promise();
};
