/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');

var HeaderBarView = require('common/views/HeaderBarView');
var HeaderBarLinkCellView = require('common/views/HeaderBarView/HeaderBarLinkCellView');
var BreadcrumbView = require('common/views/BreadcrumbView');

var DraftLicensePaymentView = require('./DraftLicensePaymentView');

require('../styles/DraftLicenseApplicationView.css');
var tmplDraftLicenseApplicationView = require('../templates/DraftLicenseApplicationView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDraftLicenseApplicationView,

  className: 'draft-license-application-view container-fluid',

  ui: {
    headerBar: '.header-bar',
    breadcrumb: '.breadcrumb-container',
    licenseApplicationContainer: '.license-application-container',
    paymentContainer: '.payment-container',
    paymentTab: '.payment-tab'
  },

  regions: {
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb',
    licenseApplicationContainer: '@ui.licenseApplicationContainer',
    paymentContainer: '@ui.paymentContainer'
  },

  initialize: function(options) {
    this.licenseApplicationView = options.licenseApplicationView;
  },

  onBeforeShow: function() {
    var self = this;
    var applicationDetails = this.model.get('applicationDetails');
    var headerData = _.extend(applicationDetails, {
      location: applicationDetails.location.addressLine1
    });
    this.showChildView(
      'headerBar',
      new HeaderBarView({
        columns: new Backbone.Collection([
          {
            name: 'entityName',
            label: 'Entity Name',
            minWidth: '160px',
            maxWidth: '300px'
          },
          {
            name: 'tin',
            label: 'Federal ID Number',
            minWidth: '160px'
          },
          {
            name: 'location',
            label: 'Location',
            minWidth: '160px',
            maxWidth: '350px',
            overflow: 'truncate'
          },
          {
            name: 'webAddress',
            label: 'Web Address',
            width: '350px',
            overflow: 'truncate',
            headerCell: HeaderBarLinkCellView
          }
        ]),
        model: new Backbone.Model(headerData)
      })
    );

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#ext/nondep/dashboard',
            text: 'Home'
          },
          {
            link: '#ext/nondep/entity/' + applicationDetails.entityId + '/dashboard',
            text: applicationDetails.entityName
          },
          {
            text: 'Application for a ' + applicationDetails.applicationType
          }
        ]
      })
    );

    this.showChildView('licenseApplicationContainer', this.licenseApplicationView);

    this.showChildView(
      'paymentContainer',
      new DraftLicensePaymentView({
        model: this.model
      })
    );

    Radio.channel('nd:draft-license-application').on('proceed-to-payment', function() {
      self.ui.paymentTab.tab('show');
      self.ui.licenseApplicationContainer.removeClass('active');
      self.ui.paymentContainer.addClass('active');
    });
  },

  onDestroy: function() {
    Radio.channel('nd:draft-license-application').reset();
  }
});
