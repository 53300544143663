/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');
var Ladda = require('ladda');

var UIUtil = require('psa-core/util/ui');

var BreadcrumbView = require('common/views/BreadcrumbView');
var ConfirmationModalView = require('common/views/ConfirmationModalView');
var modelGet = require('common/util/modelGet');
var HeaderBarView = require('common/views/HeaderBarView');

var SubmitEntityExternalUserUpdate = require('../services/SubmitEntityExternalUserUpdate');

var tmplEntityManageUserView = require('../templates/SecEntityManageUserView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplEntityManageUserView,

  className: 'sec-entity-manage-user-view container-fluid',

  ui: {
    breadcrumb: '.breadcrumb-container',
    accessLevelSelect: "select[name='userAccessLevel']",
    saveButton: '.save-button',
    revokeButton: '.revoke-button',
    headerBar: '.header-bar-container'
  },

  regions: {
    breadcrumb: '@ui.breadcrumb',
    headerBar: '@ui.headerBar'
  },

  events: {
    'click @ui.saveButton': 'onClickSave',
    'click @ui.revokeButton': 'onClickRevoke'
  },

  templateHelpers: function() {
    return {
      viewMode: this.options.viewMode
    };
  },

  onBeforeShow: function() {
    var organizationId = this.model.get('organizationId');
    var organizationIdentifier = this.model.get('organizationIdentifier');

    var breadcrumbs = [
      {
        text: 'Home',
        link: '#sec/dashboard'
      },
      {
        text: modelGet(this.model, 'organizationIdentifier.applicantLegalName'),
        link: '#sec/organization/' + organizationId + '/dashboard'
      },
      {
        text: 'Manage User'
      }
    ];
    var headerBarColumns = [
      {
        name: 'CRDNumber',
        label: 'CRD Number',
        minWidth: '160px',
        maxWidth: '200px'
      }
    ];

    this.showChildView(
      'headerBar',
      new HeaderBarView({
        columns: new Backbone.Collection(headerBarColumns),
        model: new Backbone.Model(organizationIdentifier)
      })
    );

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: breadcrumbs
      })
    );
    var userAccessLevelId = modelGet(this.model, 'externalUser.userAccessLevelId');
    this.ui.accessLevelSelect.val(userAccessLevelId);
  },

  onClickSave: function(e) {
    e.preventDefault();

    var organizationId = this.model.get('organizationId');

    var args = {
      entityExternalUserId: this.model.get('userId'),
      entityExternalUserAccessLevelId: this.ui.accessLevelSelect.val()
    };

    var laddaContext = Ladda.create(this.ui.saveButton.get(0));
    laddaContext.start();
    SubmitEntityExternalUserUpdate(args)
      .done(function() {
        Radio.channel('navigate').trigger('show:int:sc:organization', organizationId);
      })
      .fail(function() {
        laddaContext.stop();
      });
  },

  onClickRevoke: function(e) {
    e.preventDefault();

    var firstName = modelGet(this.model, 'externalUser.firstName');
    var lastName = modelGet(this.model, 'externalUser.lastName');
    var fullName = firstName + ' ' + lastName;
    var organizationId = this.model.get('organizationId');
    var entityName = modelGet(this.model, 'organizationIdentifier.DBAName');

    UIUtil.showModal({
      title: 'Confirm Revoke ' + fullName + "'s Access",
      model: new Backbone.Model({
        message: 'Are you sure you want to revoke ' + fullName + "'s access to " + entityName + '?',
        onConfirm: function() {
          return SubmitEntityExternalUserUpdate({
            entityExternalUserId: this.model.get('userId'),
            isDeleted: '1'
          });
        }.bind(this),
        onConfirmDone: function() {
          Radio.channel('navigate').trigger('show:int:sc:organization', organizationId);
        }
      }),
      view: new ConfirmationModalView()
    });
  }
});
