/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var momentUtil = require('common/util/momentUtil');

module.exports = function(formData) {
  var supportingDocuments = formData.supportingDocuments || [];

  var formOutboundDateConfig = {
    sourceFormat: 'MM/DD/YYYY',
    targetFormat: 'YYYY-MM-DD',
    default: ''
  };

  formData.auditDated = momentUtil.formatStr(formData.auditDated, formOutboundDateConfig);

  return $.soap({
    body: {
      SubmitAuditSubmission: {
        $xmlns: 'http://dobs.pa.gov/dp/submission/bpm/1.0',
        EntityID: formData.entityId,
        SubmissionTypeID: formData.submissionTypeId,
        AuditDated: formData.auditDated,
        AuditTypeID: formData.auditTypeId,
        IsCPA: formData.isCPA,
        IsFund: formData.isFund,
        FundID: formData.fundId,
        SubFundID: formData.subFundId,
        AuditFileDocID: formData.auditFileDocId,
        AuditComments: formData.auditComments,
        PointOfContact: {
          Name: formData.name,
          Title: formData.title,
          Phone: formData.phone,
          Email: formData.email,
          AddressLine1: formData.addressLine1,
          AddressLine2: formData.addressLine2,
          AddressLine3: formData.addressLine3,
          City: formData.city,
          State: formData.state,
          PostalCode: formData.postalCode,
          County: formData.county,
          Country: formData.country
        },
        SupportingDocuments: (function() {
          if (!supportingDocuments.length) {
            return {};
          }
          return {
            Document: _.map(supportingDocuments, function(supportingDocument) {
              return {
                DocumentID: supportingDocument.fileId
              };
            })
          };
        })()
      }
    }
  });
};
