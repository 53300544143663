/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function() {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetAllExternalTasks: {
        $xmlns: 'http://dobs.pa.gov/dp/task/bpm/1.0'
      }
    },
    blueprintArrays: [
      'GetAllExternalTasksResponse.Tasks.Task',
      'GetAllExternalTasksResponse.Tasks.Task.BusinessAttributes.Attribute',
      'GetAllExternalTasksResponse.Tasks.Task.Targets.Target',
      'GetAllExternalTasksResponse.TaskTypes.CM_LU_TaskType',
      'GetAllExternalTasksResponse.Filings.DP_Filing',
      'GetAllExternalTasksResponse.Exams.DP_EntityExam'
    ],
    blueprint: {
      tasks: [
        '$..Task',
        {
          taskId: '$..TaskId',
          deliveryDate: '$..DeliveryDate',
          businessAttributes: [
            '$..Attribute',
            {
              name: '$.$name',
              value: '$..__text'
            }
          ],
          assignee: '$..Assignee.$displayName',
          targetType: '$..Targets.Target[0].$type',
          targetDn: '$..Targets.Target[0].__text'
        }
      ],
      taskMetaData: {
        taskTypes: [
          '$..CM_LU_TaskType',
          {
            id: '$.ID',
            name: '$.Name',
            description: '$.Description'
          }
        ],
        exams: [
          '$..DP_EntityExam',
          {
            id: '$..ID',
            entityName: '$..EntityName',
            examType: '$..ExamType',
            dueDate: '$..DueDate'
          }
        ],
        filings: [
          '$..DP_Filing',
          {
            id: '$..FilingID',
            entityName: '$..EntityName',
            filingType: '$..FilingType',
            decisionDueDate: '$..DecisionDueDate',
            submissionTypeName: '$..SubmissionTypeName',
            filingTypeId: '$..FilingTypeID'
          }
        ]
      }
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      _.each(response.tasks, function(task) {
        task.deliveryDate = moment.utc(task.deliveryDate, moment.ISO_8601).local();

        task.assigneeDetails = {
          assignee: task.assignee,
          targetType: task.targetType,
          targetDn: task.targetDn
        };

        _.each(task.businessAttributes, function(attr) {
          var attrMap = {
            DPEntityID: 'entityId',
            DPExamID: 'examId',
            TaskTypeName: 'taskTypeName',
            DPFilingID: 'filingId'
          };

          if (attrMap[attr.name]) {
            task[attrMap[attr.name]] = attr.value;
          }
        });

        delete task.businessAttributes;

        var type = _.find(response.taskMetaData.taskTypes, function(type) {
          return task.taskTypeName === type.name;
        });
        task.type = type.description;

        if (task.filingId) {
          var filing = _.find(response.taskMetaData.filings, function(filing) {
            return task.filingId === filing.id;
          });

          if (filing) {
            task.filingType = filing.filingType || '--';
            task.entityName = filing.entityName || '--';
            //add submission type to filing type if generic submission
            if (_.includes(['100', '200', '300'], filing.filingTypeId) && filing.submissionTypeName) {
              task.filingType = filing.filingType + ' - ' + filing.submissionTypeName;
            } else {
              task.filingType = filing.filingType;
            }
            if (filing.decisionDueDate) {
              task.dueDate = moment(filing.decisionDueDate, 'YYYY-MM-DD');
            }
          }
        }

        if (task.examId) {
          var exam = _.find(response.taskMetaData.exams, function(exam) {
            return task.examId === exam.id;
          });
          if (exam) {
            task.entityName = exam.entityName;
            if (exam.dueDate) {
              task.dueDate = moment(exam.dueDate, 'YYYY-MM-DD');
            }
          }
        }

        task.entityName = task.entityName || '--';
        task.filingType = task.filingType || '--';
      });
      deferred.resolve(response.tasks);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
