/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var NProgress = require('nprogress');

var NavUtils = require('psa-core/util/nav');

var Scaffold = require('common/layout/Scaffold');

var GetRecord = require('../../common/services/GetRecord');
var CorpFinInvPortfolioEditView = require('../views/CorpFinInvPortfolioEditView');
var GetPortfolio = require('../services/GetPortfolio');

module.exports = function(recordId, portfolioId) {
  NProgress.start();
  NProgress.set(0.6);
  $.when(GetRecord(recordId), GetPortfolio(portfolioId))
    .done(function(record, portfolio) {
      NavUtils.navigate('corpfin/investment/' + recordId + '/portfolio/' + portfolioId + '/edit');
      Scaffold.content.show(
        new CorpFinInvPortfolioEditView({
          viewMode: 'int',
          model: new Backbone.Model({
            recordId: recordId,
            record: record,
            portfolioId: portfolioId,
            portfolio: portfolio
          })
        })
      );
      NProgress.done();
    })
    .fail(function(err) {
      NProgress.done();
    });
};
