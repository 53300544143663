/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(bondId) {
  var defer = $.Deferred();

  $.soap({
    body: {
      GetLicenseBondByID: {
        $xmlns: 'http://dobs.pa.gov/nd/license/bpm/1.0',
        BondID: bondId
      }
    },
    blueprint: {
      Id: '$..ID',
      entityID: '$..EntityID',
      licenseId: '$..LicenseID',
      bondCompanyId: '$..BondCompanyID',
      bondName: '$..BondName',
      bondNumber: '$..BondNumber',
      bondAmount: '$..BondAmount',
      expiration: '$..Expiration',
      requiredTo: '$..RequiredTo',
      licenseRequirement: '$..LicenseRequirement',
      type: '$..Type',
      status: '$..Status',
      typeId: '$..TypeID',
      statusId: '$..StatusID',
      bondTypeOther: '$..BondTypeOther'
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      response.expiration = response.expiration
        ? moment(response.expiration, moment.ISO_8601).format('MM/DD/YYYY')
        : '';
      response.requiredTo = response.requiredTo
        ? moment(response.requiredTo, moment.ISO_8601).format('MM/DD/YYYY')
        : '';
      defer.resolve(response);
    })
    .fail(function(err) {
      defer.reject();
    });

  return defer.promise();
};
