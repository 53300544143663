/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(formData) {
  var defer = $.Deferred();

  $.soap({
    body: {
      SubmitExamInvInqRelatedSubject: {
        $xmlns: 'http://dobs.pa.gov/sc/examInvInq/bpm/1.0',
        ID: formData.id,
        ObjectTypeID: formData.objectTypeId,
        ObjectID: formData.objectId,
        SubjectObjectTypeID: formData.subjectObjectTypeId,
        SubjectObjectID: formData.subjectObjectId
      }
    },
    blueprint: {
      subjectObjectId: '$..RelatedSubjectID'
    }
  })
    .done(function(response) {
      cleanUpResponse(response, {
        cleanUpTextKeys: true
      });

      defer.resolve(response.subjectObjectId);
    })
    .fail(function(err) {
      defer.reject(err);
    });

  return defer.promise();
};
