var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=helpers.helperMissing, alias4="function";

  return "\r\n<div class=\"page-title clearfix\">\r\n  <h1 class=\"pull-left m-n p-n\">"
    + alias1(container.lambda(((stack1 = (depth0 != null ? depth0.entityIdentifiers : depth0)) != null ? stack1.entityName : stack1), depth0))
    + "</h1>\r\n</div>\r\n<div class=\"breadcrumb-container\"></div>\r\n<div class=\"col-sm-9 col-sm-offset-1 main-form\">\r\n  <div class=\"form-horizontal\">\r\n    <form class=\"entity-merge-form\">\r\n      <div class=\"entity-search-section\">\r\n        <div class=\"row m-b-lg\">\r\n          <h3 class=\"col-sm-offset-3 col-sm-9\">Mergers and Acquisition</h3>\r\n        </div>\r\n        <div class=\"form-group required\">\r\n          <label class=\"control-label col-sm-3\">Target Entity</label>\r\n          <div class=\"col-sm-6\">\r\n            <div class=\"input-group\">\r\n              <span class=\"input-group-btn\">\r\n                <button class=\"btn btn-primary btn-entity-search\">Search for Entity</button>\r\n              </span>\r\n              <input type=\"text\" class=\"selected-entity-search-entity-name form-control\"\r\n                placeholder=\"Search for an Entity\" name=\"acquiredCompanyEntityName\" readonly>\r\n              <span class=\"input-group-btn\">\r\n                <button class=\"btn btn-default clear-entity-search\">Clear</button>\r\n              </span>\r\n              <input type=\"number\" class=\"selected-entity-id\" name=\"entitySearchId\"\r\n                data-parsley-errors-container=\".entity-select-error-container\" \r\n                data-parsley-required=\"true\"\r\n                data-parsley-not-equal=\""
    + alias1(((helper = (helper = helpers.entityId || (depth0 != null ? depth0.entityId : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"entityId","hash":{},"data":data}) : helper)))
    + "\" \r\n                data-parsley-not-equal-message=\"Please choose a different Entity.\" hidden>\r\n            </div>\r\n            <div class=\"entity-select-error-container\"></div>\r\n          </div>\r\n        </div>\r\n        <div class=\"form-group\">\r\n          <label class=\"control-label col-sm-3\">Target Locations To Be Merged</label>\r\n          <div class=\"entity-location-grid-container col-sm-9\"></div>\r\n        </div>\r\n      </div>\r\n    </form>\r\n    <div class=\"row m-b-lg\">\r\n      <div class=\"col-sm-6 col-sm-offset-3 pull-left\">\r\n        <a href=\"#dep/entity/"
    + alias1(((helper = (helper = helpers.entityId || (depth0 != null ? depth0.entityId : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"entityId","hash":{},"data":data}) : helper)))
    + "/dashboard\" class=\"btn btn-default\">Cancel</a>\r\n        <button class=\"btn btn-primary btn-submit\">Submit</button>\r\n      </div>\r\n    </div>\r\n  </div>\r\n</div>";
},"useData":true});