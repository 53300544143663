/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(data) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetExamsForSelection: {
        $xmlns: 'http://dobs.pa.gov/nd/exam/bpm/1.0',
        LicenseTypeID: data.licenseTypeId,
        LicenseNumber: data.licenseNumber,
        EntityName: data.entityName,
        AssignedToUserID: data.assignedToUserId
      }
    },
    blueprintArrays: ['GetExamsForSelectionResponse.ND_Exam'],
    blueprint: {
      ndExams: [
        '$..ND_Exam',
        {
          objectId: '$..ID',
          examDate: '$..ExamDate',
          entityName: '$..EntityName',
          licenseType: '$..LicenseType',
          licenseNumber: '$..LicenseNumber',
          yearPeriod: '$..YearPeriod'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      _.each(response.ndExams, function(ndExam) {
        if (ndExam.examDate) {
          ndExam.examDate = moment(ndExam.examDate, 'YYYY-MM-DD');
        }
        var examDateDisplay = 'Not Started';
        if (ndExam.examDate && moment.isMoment(ndExam.examDate) && ndExam.examDate.isValid()) {
          examDateDisplay = ndExam.examDate.format('MM/DD/YYYY');
        }
        ndExam.objectDisplayName = ndExam.objectId + ' - ' + ndExam.entityName + ' - ' + examDateDisplay;
      });

      response.totalHits = response.ndExams.length;

      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject(err);
    });

  return deferred.promise();
};
