/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');

var GetCreditServicesLoanBrokerRenewalApplication = require('../services/GetCreditServicesLoanBrokerRenewalApplication');

var CreditServicesLoanBrokerRenewalApplication = require('../forms/CreditServicesLoanBrokerRenewalApplication');

module.exports = {
  event: 'build:license-renew:credit-services-loan-broker',

  channel: 'nd:application',

  builder: function(config) {
    var defer = $.Deferred();
    GetCreditServicesLoanBrokerRenewalApplication(config.applicationId)
      .done(function(renewalData) {
        defer.resolve(
          new CreditServicesLoanBrokerRenewalApplication({
            mode: config.mode || 'new', // new, resubmit, print or int-expand
            isReadOnly: config.isReadOnly,
            model: new Backbone.Model({
              renewalData: renewalData
            })
          })
        );
      })
      .fail(function(err) {
        defer.reject(err);
      });

    return defer.promise();
  }
};
