/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var NProgress = require('nprogress');

var NavUtils = require('psa-core/util/nav');

var Scaffold = require('common/layout/Scaffold');
var GetUsersForRole = require('common/services/GetUsersForRole');

var GetAllOrgLicenseLookups = require('../../common/services/GetAllOrgLicenseLookups');
var GetOrganizationLicenseDetails = require('../services/GetOrganizationLicenseDetails');
var GetOrganizationLicenseIdentifiers = require('../services/GetOrganizationLicenseIdentifiers');
var SecOrganizationLicenseMainEditView = require('../views/SecOrganizationLicenseMainEditView');

module.exports = function(organizationId, licenseId) {
  NProgress.start();
  NProgress.set(0.4);
  $.when(
    GetOrganizationLicenseDetails(licenseId),
    GetOrganizationLicenseIdentifiers(licenseId),
    GetAllOrgLicenseLookups(),
    GetUsersForRole('SC - Filing Examiner')
  )
    .done(function(license, licenseIdentifiers, orgLicenseLookups, filingExaminerUsers) {
      NProgress.set(0.75);
      NavUtils.navigate('#sec/organization/' + organizationId + '/license/' + licenseId + '/edit');
      Scaffold.content.show(
        new SecOrganizationLicenseMainEditView({
          viewMode: 'int',
          model: new Backbone.Model({
            license: license,
            licenseIdentifiers: licenseIdentifiers,
            filingExaminerUsers: filingExaminerUsers,
            orgLicenseTypes: orgLicenseLookups.orgLicenseType,
            orgLicenseStatus: orgLicenseLookups.orgLicenseStatus,
            orgLicenseCustodyType: orgLicenseLookups.orgLicenseCustodyType
          })
        })
      );
      NProgress.done();
    })
    .fail(function(err) {
      NProgress.done();
    });
};
