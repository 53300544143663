var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "Sub-";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "      <a class=\"btn btn-default\"\r\n        href=\"#dep/entity/"
    + alias4(((helper = (helper = helpers.entityId || (depth0 != null ? depth0.entityId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"entityId","hash":{},"data":data}) : helper)))
    + "/fund/"
    + alias4(((helper = (helper = helpers.fundId || (depth0 != null ? depth0.fundId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"fundId","hash":{},"data":data}) : helper)))
    + "/sub-fund/"
    + alias4(((helper = (helper = helpers.subFundId || (depth0 != null ? depth0.subFundId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"subFundId","hash":{},"data":data}) : helper)))
    + "/dashboard\">Cancel</a>\r\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "      <a class=\"btn btn-default\" href=\"#dep/entity/"
    + alias4(((helper = (helper = helpers.entityId || (depth0 != null ? depth0.entityId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"entityId","hash":{},"data":data}) : helper)))
    + "/fund/"
    + alias4(((helper = (helper = helpers.fundId || (depth0 != null ? depth0.fundId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"fundId","hash":{},"data":data}) : helper)))
    + "/dashboard\">Cancel</a>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return "\r\n<div class=\"page-title clearfix\">\r\n  <h1 class=\"m-n p-n\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.audit : depth0)) != null ? stack1.entityName : stack1), depth0))
    + "</h1>\r\n</div>\r\n<div class=\"page-subtitle\">\r\n  <h2>Collective Investment "
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.subFundId : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "Fund Audit</h2>\r\n  <div class=\"page-subtitle-details\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.audit : depth0)) != null ? stack1.auditType : stack1), depth0))
    + " - "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.audit : depth0)) != null ? stack1.auditDate : stack1), depth0))
    + "</div>\r\n</div>\r\n<div class=\"breadcrumb-container\"></div>\r\n<div class=\"col-sm-10 col-sm-offset-1\">\r\n  <div class=\"row\">\r\n    <div class=\"col-sm-12 col-sm-offset-3 col-md-offset-2 col-md-10\">\r\n      <h3 class=\"m-t-xs m-b-lg\">Edit Collective Investment "
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.subFundId : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "Fund Audit</h3>\r\n    </div>\r\n  </div>\r\n  <div class=\"edit-audit-form-container\"></div>\r\n  <div class=\"row\">\r\n    <div class=\"col-sm-12 col-sm-offset-3 col-md-offset-2 col-md-10\">\r\n"
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.subFundId : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data})) != null ? stack1 : "")
    + "      <button class=\"btn btn-primary btn-submit\">Submit</button>\r\n    </div>\r\n  </div>\r\n</div>";
},"useData":true});