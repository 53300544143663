/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');
var _ = require('underscore');

var UIUtil = require('psa-core/util/ui');
var PageableCollection = require('psa-backbone/models/PageableCollection');

var PanelGridView = require('common/views/PanelGridView');
var MomentPanelCellView = require('common/views/PanelGridView/MomentPanelCellView');
var ButtonsPanelCellView = require('common/views/PanelGridView/ButtonsPanelCellView');
var AddressDisplayPanelCellView = require('common/views/PanelGridView/AddressDisplayPanelCellView');
var ConfirmationModalView = require('common/views/ConfirmationModalView');
var DropdownView = require('common/views/DropdownView');

var EntityJoinCodeUseModalView = require('./EntityJoinCodeUseModalView');
var DepEntityConfirmCreateFilingModalView = require('../../entity-dashboard/views/DepEntityConfirmCreateFilingModalView');

var SubmitDeleteFiling = require('../services/SubmitDeleteFiling');

require('../styles/DepExtDashboardView.css');
var tmplDepExtDashboardView = require('../templates/DepExtDashboardView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepExtDashboardView,

  className: 'dep-ext-dashboard-view container-fluid',

  ui: {
    pendingTaskWrapper: '.pending-task-wrapper',
    myEntitiesWrapper: '.my-entities-wrapper',
    draftFilingsWrapper: '.draft-filings-wrapper',
    joinEntityButton: '.join-entity-button',
    newBankCharterButton: '.new-bank-charter-button',
    actionDropdown: '.action-dropdown'
  },

  regions: {
    pendingTasksWrapper: '@ui.pendingTaskWrapper',
    myEntitiesWrapper: '@ui.myEntitiesWrapper',
    draftFilingsWrapper: '@ui.draftFilingsWrapper',
    actionDropdown: '@ui.actionDropdown'
  },

  events: {
    'click @ui.joinEntityButton': 'onClickJoinEntity',
    'click @ui.newBankCharterButton': 'onClickNewBankCharter'
  },

  onBeforeShow: function() {
    this.showChildView(
      'actionDropdown',
      new DropdownView({
        rightAlign: true,
        model: new Backbone.Model({
          name: 'Actions',
          options: [
            {
              text: 'Join an Entity Using a Join Code',
              onClick: this.onClickJoinEntity.bind(this)
            },
            {
              text: 'Create New Bank Charter',
              onClick: this.confirmNewFiling.bind(this)
            }
          ]
        })
      })
    );

    var pendingTasksView = new PanelGridView({
      columns: [
        {
          name: 'type',
          label: 'Task Name',
          width: 25,
          overflow: 'break-normal'
        },
        {
          name: 'filingType',
          label: 'Filing Type',
          width: 25,
          overflow: 'truncate'
        },
        {
          name: 'entityName',
          label: 'Entity Name',
          width: 25,
          overflow: 'truncate'
        },
        {
          name: 'deliveryDate',
          label: 'Assigned On',
          panelCell: MomentPanelCellView,
          width: 15
        },
        {
          name: '',
          label: '',
          width: 10,
          panelCell: ButtonsPanelCellView.extend({
            buttons: [
              {
                buttonText: 'Open',
                buttonClass: 'btn-primary btn-md',
                href: function(model) {
                  return '#ext/task/' + model.get('taskId');
                }
              }
            ]
          })
        }
      ],
      collection: new PageableCollection(this.model.get('tasks'), { pageSize: 5 }),
      emptyText: 'No Pending Tasks'
    });

    var myEntitiesView = new PanelGridView({
      columns: [
        {
          name: 'entityName',
          label: 'Entity Name',
          width: 30
        },
        {
          name: 'address',
          label: 'PPOB Address',
          panelCell: AddressDisplayPanelCellView,
          width: 30
        },
        {
          name: 'entityStatus',
          label: 'Status',
          width: 20
        },
        {
          name: '',
          label: '',
          width: 20,
          panelCell: ButtonsPanelCellView.extend({
            buttons: [
              {
                buttonText: 'Open',
                buttonClass: 'btn-primary btn-md',
                href: function(model) {
                  return '#ext/dep/entity/' + model.get('id') + '/dashboard';
                }
              }
            ]
          })
        }
      ],
      collection: new PageableCollection(this.model.get('entities'), { pageSize: 5 }),
      emptyText: 'No Entities Created'
    });

    this.draftFilingsCollection = new PageableCollection(this.model.get('filings'), { pageSize: 5 });
    var draftFilingsView = new PanelGridView({
      columns: [
        {
          name: 'entityName',
          label: 'Entity Name',
          width: 30
        },
        {
          name: 'filingType',
          label: 'Filing Type',
          width: 30
        },
        {
          name: 'modifiedDate',
          label: 'Last Modified',
          panelCell: MomentPanelCellView,
          width: 20
        },
        {
          name: '',
          label: '',
          width: 20,
          panelCell: ButtonsPanelCellView.extend({
            buttons: [
              {
                buttonText: 'Discard',
                buttonClass: function(model) {
                  var baseClass = 'btn-danger btn-md',
                    nonEntityFilingTypeIds = ['101', '109', '304'];
                  if (
                    !_.contains(nonEntityFilingTypeIds, model.get('filingTypeId')) &&
                    model.get('entityAccessLevelId') < 20
                  ) {
                    baseClass += ' collapse';
                  }
                  return baseClass;
                },
                onClick: this.onClickDiscardDraftApp.bind(this)
              },
              {
                buttonText: 'Open',
                buttonClass: 'btn-primary btn-md',
                href: function(model) {
                  return '#ext/dep/filing/' + model.get('id') + '/draft';
                }
              }
            ]
          })
        }
      ],
      collection: this.draftFilingsCollection,
      emptyText: 'No Draft Filings Started'
    });

    this.showChildView('pendingTasksWrapper', pendingTasksView);
    this.showChildView('myEntitiesWrapper', myEntitiesView);
    this.showChildView('draftFilingsWrapper', draftFilingsView);
  },

  onClickJoinEntity: function(e) {
    e.preventDefault();

    UIUtil.showModal({
      title: 'Join an Entity',
      model: this.model,
      view: new EntityJoinCodeUseModalView()
    });
  },

  confirmNewFiling: function() {
    UIUtil.showModal({
      title: 'Confirm Creating Filing Draft',
      view: new DepEntityConfirmCreateFilingModalView({
        filingTypeId: '101'
      })
    }).done(function(response) {
      Radio.channel('navigate').trigger('show:ext:dp:draft-filing', response.filingId);
    });
  },

  onClickDiscardDraftApp: function(e, model) {
    e.preventDefault();
    var self = this;

    UIUtil.showModal({
      title: 'Confirm Discard Filing Draft',
      view: new ConfirmationModalView({
        confirmText: 'Discard Filing Draft',
        model: new Backbone.Model({
          message:
            'Are you sure you want to discard this ' +
            model.get('filingType') +
            ' Filing Draft for ' +
            model.get('entityName') +
            '?',
          onConfirm: function() {
            return SubmitDeleteFiling(model.id);
          },
          onConfirmDone: function() {
            self.draftFilingsCollection.remove(model);
          }
        })
      })
    });
  }
});
