/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(data) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetInvInqForSelection: {
        $xmlns: 'http://dobs.pa.gov/sc/investigationinquiry/bpm/1.0',
        IndLicenseTypeID: data.indLicenseTypeId,
        OrgLicenseTypeID: data.orgLicenseTypeId,
        FirstName: data.firstName,
        LastName: data.lastName,
        DBAName: data.dbaName,
        CaseNumber: data.caseNumber,
        AssignedToUsername: data.assignedToUsername,
        IsOrganization: data.isOrganization
      }
    },
    blueprintArrays: ['GetInvInqForSelectionResponse.SC_InvestigationInquiry'],
    blueprint: {
      scInvInqs: [
        '$..SC_InvestigationInquiry',
        {
          objectId: '$..ID',
          openedDate: '$..OpenedDate',
          number: '$..CaseNumber',
          isOrganization: '$..IsOrganization',
          firstName: '$..FirstName',
          lastName: '$..LastName',
          indLicenseType: '$..IndLicenseType',
          category: '$..Category',
          dbaName: '$..DBAName',
          orgLicenseType: '$..OrgLicenseType'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      _.each(response.scInvInqs, function(scInvInq) {
        scInvInq.licenseType = scInvInq.orgLicenseType || scInvInq.indLicenseType;

        if (scInvInq.openedDate) {
          scInvInq.openedDate = moment(scInvInq.openedDate, 'YYYY-MM-DD');
        }
        var openedDateDisplay = 'Not Started';
        if (scInvInq.openedDate && moment.isMoment(scInvInq.openedDate) && scInvInq.openedDate.isValid()) {
          openedDateDisplay = scInvInq.openedDate.format('MM/DD/YYYY');
        }

        scInvInq.name = scInvInq.dbaName || scInvInq.firstName + ' ' + scInvInq.lastName;
        scInvInq.objectDisplayName = 'INV-INQ #' + scInvInq.number + ' - ' + scInvInq.name + ' - ' + openedDateDisplay;
        scInvInq.objectType = scInvInq.category;
        scInvInq.objectTypeId = 'INV-INQ';
      });

      response.totalHits = response.scInvInqs.length;

      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
