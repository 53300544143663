/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');

var PlaceholderView = require('common/views/PlaceholderView');

var GetEntityIdentifiers = require('../../common/services/GetEntityIdentifiers');
var GetEntityUsers = require('../services/GetEntityUsers');

var DepUserManagementSectionView = require('../views/DepUserManagementSectionView');

module.exports = {
  channel: 'dp',

  event: 'build:user-management-section-view',

  builder: function(entityId, viewMode) {
    return new PlaceholderView({
      viewPromise: buildDirectorsSectionView(entityId, viewMode)
    });
  }
};

function buildDirectorsSectionView(entityId, viewMode) {
  var deferred = $.Deferred();
  $.when(GetEntityUsers(entityId), GetEntityIdentifiers(entityId))
    .done(function(entityUsers, entityIdentifiers) {
      deferred.resolve(
        new DepUserManagementSectionView({
          viewMode: viewMode,
          model: new Backbone.Model({
            entityId: entityId,
            entityIdentifiers: entityIdentifiers,
            authorizedUsers: entityUsers.authorizedUsers,
            invitedUsers: entityUsers.invitedUsers,
            userAccessLevels: entityUsers.userAccessLevels
          })
        })
      );
    })
    .fail(function(err) {
      deferred.reject(err);
    });
  return deferred.promise();
}
