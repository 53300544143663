var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "\r\n<div class=\"official-top-header-container\"></div>\r\n<nav class=\"navbar navbar-ext-default\">\r\n  <div class=\"container-fluid\">\r\n    <div class=\"navbar-header\">\r\n      <button type=\"button\" class=\"navbar-toggle collapsed\" data-toggle=\"collapse\"\r\n        data-target=\"#bs-example-navbar-collapse-1\" aria-expanded=\"false\">\r\n        <span class=\"sr-only\">Toggle navigation</span>\r\n        <span class=\"icon-bar\"></span>\r\n        <span class=\"icon-bar\"></span>\r\n        <span class=\"icon-bar\"></span>\r\n      </button>\r\n      <div class=\"logo-text\">\r\n        <a href=\"#ext/applications\">\r\n          <img class=\"logo\" src=\"img/DoB&SlogoKOKeystonet.png\" alt=\"logo\" />\r\n          <p class=\"navbar-text navbar-brand\">Self-Service</p>\r\n        </a>\r\n      </div>\r\n    </div>\r\n    <div class=\"collapse navbar-collapse\" id=\"int-main-menu\">\r\n      <ul class=\"nav navbar-nav navbar\">\r\n        <li><a href=\"#ext/applications\">Program Selection</a></li>\r\n      </ul>\r\n      <ul class=\"nav navbar-nav navbar-right\">\r\n        <li>\r\n          <p class=\"navbar-text welcome-text\">Welcome, "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.firstName : stack1), depth0))
    + " "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.lastName : stack1), depth0))
    + "</p>\r\n        </li>\r\n        <li><a href=\"#\" class=\"logout\">Logout</a></li>\r\n      </ul>\r\n    </div>\r\n  </div>\r\n</nav>";
},"useData":true});