var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "\r\n<div class=\"page-title clearfix\">\r\n  <h1 class=\"m-n p-n\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.entityIdentifiers : depth0)) != null ? stack1.entityName : stack1), depth0))
    + "</h1>\r\n  <div class=\"page-title-buttons-container\">\r\n    <div class=\"dropdown-container\"></div>\r\n  </div>\r\n</div>\r\n<div class=\"breadcrumb-container\"></div>\r\n<div class=\"col-sm-12 col-md-10 col-md-offset-1\">\r\n  <div class=\"row\">\r\n    <div class=\"col-sm-12 col-md-10 col-md-offset-2\">\r\n      <h3 class=\"m-t-xs m-b-lg\">New Collective Investment Fund</h3>\r\n    </div>\r\n  </div>\r\n  <div class=\"new-fund-form-container\"></div>\r\n  <div class=\"row\">\r\n    <div class=\"col-sm-12 col-md-10 col-md-offset-2\">\r\n      <a class=\"btn btn-default\" href=\"#dep/entity/"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.entityIdentifiers : depth0)) != null ? stack1.entityId : stack1), depth0))
    + "/dashboard\">Cancel</a>\r\n      <button class=\"btn btn-primary btn-submit\">Submit</button>\r\n    </div>\r\n  </div>\r\n</div>";
},"useData":true});