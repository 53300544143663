/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Backbone = require('backbone');

var EntityRecordsLocationChangeApplicationView = require('../forms/EntityRecordsLocationChangeApplication/EntityRecordsLocationChangeApplicationView');
var GetEntitySiteRecordKeepingApplication = require('../services/GetEntitySiteRecordKeepingApplication');
var GetEntitySiteRecordKeeping = require('../../entity-dashboard/services/GetEntitySiteRecordKeeping');

module.exports = {
  event: 'build:amendment:entity-site-record-keeping',

  channel: 'nd:application',

  builder: function(config) {
    var defer = $.Deferred();
    GetEntitySiteRecordKeepingApplication(config.applicationId).done(function(appData) {
      GetEntitySiteRecordKeeping(appData.entityId).done(function(oldBooksAndRecords) {
        defer.resolve(
          new EntityRecordsLocationChangeApplicationView({
            model: new Backbone.Model(_.extend(appData, { oldBooksAndRecords: oldBooksAndRecords.siteRecordKeepings })),
            isReadOnly: config.isReadOnly,
            mode: config.mode,
            viewMode: config.viewMode
          })
        );
      });
    });

    return defer.promise();
  }
};
