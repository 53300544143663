var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "          <option value=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</option>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "\r\n<h1 class=\"page-title\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.entityIdentifiers : depth0)) != null ? stack1.entityName : stack1), depth0))
    + "</h1>\r\n<div class=\"header-bar\"></div>\r\n<div class=\"breadcrumb-container\"></div>\r\n\r\n<div class=\"row\">\r\n  <div class=\"col-sm-7 col-sm-offset-2\">\r\n    <h2 class=\"m-t-n\">Apply for a License</h2>\r\n  </div>\r\n</div>\r\n<form class=\"form-horizontal apply-for-license-form\">\r\n  <fieldset>\r\n    <div class=\"form-group required\">\r\n      <label class=\"control-label col-sm-2\">License Type</label>\r\n      <div class=\"col-sm-7 col-md-5\">\r\n        <select name=\"licenseTypeId\" class=\"form-control license-type-select\" data-parsley-required=\"true\">\r\n          <option value=\"\">--</option>\r\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.licenseTypes : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </select>\r\n      </div>\r\n    </div>\r\n    <div class=\"form-group disabled parent-license-form-group\">\r\n      <label class=\"control-label col-sm-2\">Parent License</label>\r\n      <div class=\"col-sm-7 col-md-5\">\r\n        <div class=\"parent-license-select-container\"></div>\r\n        <p class=\"help-block parent-select-help-block\">Required when a branch license is selected. In order to apply for\r\n          a branch license, an\r\n          existing primary license or pending license application must be selected.</p>\r\n        <p class=\"help-block parent-is-application-warning collapse\">A pending license application has been selected for\r\n          the parent of this branch license\r\n          application. This application will not be processed until the parent license application has been approved.\r\n        </p>\r\n      </div>\r\n    </div>\r\n    <div class=\"form-group required\">\r\n      <label class=\"control-label col-sm-2\">Location</label>\r\n      <div class=\"col-sm-7 col-md-5\">\r\n        <select name=\"locationId\" class=\"form-control location-select\" data-placeholder=\"--\"\r\n          data-parsley-required=\"true\" data-parsley-validate-if-empty=\"true\"\r\n          data-parsley-trigger-after-failure=\"change.select2\">\r\n          <option></option>\r\n        </select>\r\n      </div>\r\n    </div>\r\n    <div class=\"form-group\">\r\n      <div class=\"col-sm-offset-2 col-sm-10\">\r\n        <a href=\"#ext/nondep/entity/"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.entityIdentifiers : depth0)) != null ? stack1.id : stack1), depth0))
    + "/dashboard\" class=\"btn btn-default btn-md\" role=\"button\">Cancel</a>\r\n        <button class=\"btn btn-primary btn-md submit\">Submit</button>\r\n      </div>\r\n    </div>\r\n  </fieldset>\r\n</form>";
},"useData":true});