/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var NProgress = require('nprogress');

var NavUtils = require('psa-core/util/nav');

var Scaffold = require('common/layout/Scaffold');

var GetLicenseIdentifiers = require('../services/GetLicenseIdentifiers');
var GetAllComplianceViolations = require('../../compliance-dashboard/services/GetAllComplianceViolations');
var GetAllPrincipleLicenseTypes = require('../../compliance-dashboard/services/GetAllPrincipleLicenseTypes');

var NonDepLicenseCreateComplianceCaseRequestView = require('../views/NonDepLicenseCreateComplianceCaseRequestView');

module.exports = function(entityId, licenseId) {
  NProgress.start();
  NProgress.set(0.6);

  $.when(GetLicenseIdentifiers(licenseId), GetAllPrincipleLicenseTypes(), GetAllComplianceViolations())
    .done(function(licenseIdentifiers, licenseTypes, violationCodes) {
      NavUtils.navigate('#nondep/entity/' + entityId + '/license/' + licenseId + '/new-compliance');
      Scaffold.content.show(
        new NonDepLicenseCreateComplianceCaseRequestView({
          model: new Backbone.Model({
            entityId: entityId,
            licenseId: licenseId,
            entityName: licenseIdentifiers.entityName,
            licenseIdentifiers: licenseIdentifiers,
            licenseTypes: licenseTypes,
            violationCodes: violationCodes
          })
        })
      );
      NProgress.done();
    })
    .fail(function() {
      NProgress.done();
    });
};
