var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "        <div class=\"form-group form-group-individual\">\r\n          <label>Individual</label>\r\n          <div class=\"form-control-static\">"
    + alias4(((helper = (helper = helpers.firstName || (depth0 != null ? depth0.firstName : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"firstName","hash":{},"data":data}) : helper)))
    + " "
    + alias4(((helper = (helper = helpers.lastName || (depth0 != null ? depth0.lastName : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"lastName","hash":{},"data":data}) : helper)))
    + "</div>\r\n        </div>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "\r\n<div class=\"panel-body\">\r\n  <div class=\"row\">\r\n    <div class=\"col-sm-3\">\r\n"
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.mode : depth0),"===","individual",{"name":"ifCond","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "      <div class=\"form-group\">\r\n        <label>Investigation Indicators</label>\r\n        <div>\r\n          <label class=\"checkbox-inline\">\r\n            <input type=\"checkbox\" name=\"hasCriminal\" class=\"indicator\"> Criminal\r\n          </label>\r\n          <label class=\"checkbox-inline\">\r\n            <input type=\"checkbox\" name=\"hasFinancial\" class=\"indicator\"> Financial\r\n          </label>\r\n        </div>\r\n        <div>\r\n          <label class=\"checkbox-inline\">\r\n            <input type=\"checkbox\" name=\"hasRegulatory\" class=\"indicator\"> Regulatory\r\n          </label>\r\n          <label class=\"checkbox-inline\">\r\n            <input type=\"checkbox\" name=\"hasCivil\" class=\"indicator\"> Civil\r\n          </label>\r\n        </div>\r\n      </div>\r\n    </div>\r\n    <div class=\"col-sm-9\">\r\n      <div class=\"file-upload-grid-container\"></div>\r\n    </div>\r\n  </div>\r\n</div>\r\n\r\n";
},"useData":true});