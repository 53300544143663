/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _get = require('lodash.get');
var Backbone = require('backbone');
var NProgress = require('nprogress');

var GetNotification = require('common/services/GetNotification');

var CorpFinEntityViewNotificationTaskView = require('./CorpFinEntityViewNotificationTaskView');

module.exports = {
  event: 'build:cf:entity-view-notification',

  channel: 'task',

  builder: function(task) {
    var defer = $.Deferred();
    var notificationId = _get(task, 'taskData.CM.notificationId');
    var filingId = _get(task, 'taskData.CF.cfFilingId');

    $.when(GetNotification(notificationId))
      .done(function(notification) {
        NProgress.set(0.85);

        defer.resolve(
          new CorpFinEntityViewNotificationTaskView({
            model: new Backbone.Model({
              task: task,
              taskId: task.taskId,
              filingId: filingId,
              notification: notification
            })
          })
        );
      })
      .fail(function(err) {
        defer.reject(err);
      });

    return defer.promise();
  }
};
