/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var parsleyUtil = require('common/util/parsleyUtil');

var UIUtil = require('psa-core/util/ui');
var DepSelectFundModalView = require('./DepSelectFundModalView');
var tmplDepCollectiveInvestmentFundTerminateSubFormView = require('./DepCollectiveInvestmentFundTerminateSubFormView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepCollectiveInvestmentFundTerminateSubFormView,

  className: 'dep-collective-investment-fund-amend-sub-form-view',

  behaviors: {
    ReadOnlyFormBehavior: {}
  },

  ui: {
    amendFundForm: '.amend-fund-form',
    selectFundButton: '.btn-fund-select',
    selectedFundName: '.selected-fund-name',
    selectedFundId: '.selected-fund-id',
    datePickerFields: '.date-picker'
  },

  regions: {
    datePickerFields: '@ui.datePickerFields'
  },

  events: {
    'click @ui.clearEntitySearch': 'onClearEntitySearch',
    'click @ui.selectFundButton': 'onClickFundSelect',
    'click @ui.submit': 'onClickSubmit'
  },

  childEvents: {
    'remove:subfund': 'onChildRemoveSubfund'
  },

  templateHelpers: function() {
    return {
      isReadOnly: this.options.isReadOnly
    };
  },

  onBeforeShow: function() {
    var filingContent = this.model.get('filingContent') || {};
    var fundId = filingContent.fundId;

    Syphon.deserialize(this, this.model.get('filingContent'));

    this.ui.datePickerFields.datepicker();

    if (fundId) {
      var fund = _.find(filingContent.trustFunds, function(fund) {
        return fund.id === fundId;
      });
      this.ui.selectedFundName.val(fund.name);
      this.ui.selectedFundId.val(fund.id);
    }
  },

  onClickFundSelect: function(e) {
    e.preventDefault();
    var self = this;

    UIUtil.showModal({
      title: 'Select a Fund',
      view: new DepSelectFundModalView({
        model: this.model
      })
    }).done(function(fund) {
      if (fund) {
        self.ui.selectedFundName.val(fund.name);
        self.ui.selectedFundId.val(fund.id);
      } else {
        self.ui.selectedFundName.val('');
        self.ui.selectedFundId.val('');
      }
    });
  },

  removeValidationErrors: function() {
    this.ui.amendFundForm.parsley().reset();
  },

  validate: function(config) {
    this.removeValidationErrors();

    if (config.type === 'save') {
      // Turn off required field validation for this form
      parsleyUtil.disableRequiredValidation(this.ui.amendFundForm);
    } else {
      var validationPromises = [this.ui.amendFundForm.parsley().whenValidate()];

      // Turn on required field validation for this form
      parsleyUtil.enableRequiredValidation(this.ui.amendFundForm);
    }
    return $.when.apply($, validationPromises);
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    return formData;
  }
});
