/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');

var UIUtil = require('psa-core/util/ui');

var modelGet = require('common/util/modelGet');

var ContentServerLinkView = require('common/views/ContentServerLinkView');
var SideNavSectionView = require('common/views/SideNavSectionView');
var BreadcrumbView = require('common/views/BreadcrumbView');
var DropdownView = require('common/views/DropdownView');
var ConfirmationModalView = require('common/views/ConfirmationModalView');

var SubmitSubsidiary = require('../services/SubmitSubsidiary');

var DepSubsidiaryDetailsSectionView = require('./DepSubsidiaryDetailsSectionView');

var tmplDepSubsidiaryDashboardView = require('../templates/DepSubsidiaryDashboardView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepSubsidiaryDashboardView,

  className: 'dep-subsidiary-dashboard-view container-fluid',

  ui: {
    subsidiaryWrapper: '.subsidiary-wrapper',
    breadcrumb: '.breadcrumb-container',
    csLinkContainer: '.cs-link-container',
    entityActionDropDown: '.dropdown-container'
  },

  regions: {
    subsidiaryWrapper: '@ui.subsidiaryWrapper',
    breadcrumb: '@ui.breadcrumb',
    csLinkContainer: '@ui.csLinkContainer',
    entityActionDropDown: '@ui.entityActionDropDown'
  },

  templateHelpers: function() {
    return {
      isCreditUnion: modelGet(this.model, 'entityIdentifiers.entityTypeId') === '11'
    };
  },

  onBeforeShow: function() {
    var subsidiary = this.model.get('subsidiary') || {};
    var subsidiaryId = this.model.get('subsidiaryId');
    var subsidiariesCSFolderId = this.model.get('subsidiariesCSFolderId');
    var entityName = this.model.get('entityIdentifiers').entityName;
    var isCreditUnion = modelGet(this.model, 'entityIdentifiers.entityTypeId') === '11';

    var entityId = this.model.get('entityId');
    var dropDownOptions = [
      {
        text: isCreditUnion ? 'Edit CUSO' : 'Edit Subsidiary',
        link: '#dep/entity/' + entityId + '/subsidiary/' + subsidiaryId + '/edit'
      },
      {
        text: isCreditUnion ? 'Delete CUSO' : 'Delete Subsidiary',
        onClick: this.onClickDelete.bind(this)
      }
    ];

    this.showChildView(
      'csLinkContainer',
      new ContentServerLinkView({
        model: new Backbone.Model({
          buttonText: isCreditUnion ? 'Open CUSO Folder' : 'Open Subsidiary Folder',
          contentServerId: subsidiariesCSFolderId
        })
      })
    );

    this.showChildView(
      'entityActionDropDown',
      new DropdownView({
        rightAlign: true,
        model: new Backbone.Model({
          name: isCreditUnion ? 'CUSO Actions' : 'Subsidiary Actions',
          options: dropDownOptions
        })
      })
    );

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#dep/dashboard',
            text: 'Home'
          },
          {
            link: '#dep/entity/' + entityId + '/dashboard',
            text: entityName
          },
          {
            text: subsidiary.name + ' - ' + subsidiary.status
          }
        ]
      })
    );

    this.subsidiary = new SideNavSectionView({
      useNavigationButtons: false,
      collection: new Backbone.Collection([
        {
          id: 'subsidiaryDetails',
          title: isCreditUnion ? 'CUSO Details' : 'Subsidiary Details',
          view: new DepSubsidiaryDetailsSectionView({
            model: new Backbone.Model(subsidiary),
            subsidiaryType: this.options.subsidiaryType
          })
        },
        {
          id: 'notes',
          title: 'Notes',
          view: Radio.channel('dp').request('build:notes-view', {
            objectId: subsidiaryId,
            objectTypeId: 'SUBSIDIARIES'
          })
        },
        {
          id: 'auditLog',
          title: 'Audit Log',
          view: Radio.channel('dp').request('build:audit-log-view', {
            objectId: subsidiaryId,
            objectTypeId: 'SUBSIDIARIES'
          })
        }
      ])
    });
    this.showChildView('subsidiaryWrapper', this.subsidiary);
  },

  onClickDelete: function() {
    var self = this;
    var isCreditUnion = modelGet(this.model, 'entityIdentifiers.entityTypeId') === '11';
    var message = isCreditUnion
      ? 'Are you sure you want to delete this CUSO?'
      : 'Are you sure you want to delete this subsidiary?';

    UIUtil.showModal({
      title: isCreditUnion ? 'Confirm Deleting CUSO' : 'Confirm Deleting Subsidiary',
      view: new ConfirmationModalView({
        confirmText: isCreditUnion ? 'Delete CUSO' : 'Delete Subsidiary',
        model: new Backbone.Model({
          message: message,
          onConfirm: function() {
            var subsidiaryId = self.model.get('subsidiaryId');
            return SubmitSubsidiary({ subsidiaryId: subsidiaryId, isDeleted: '1' });
          },
          onConfirmDone: function() {
            var entityId = self.model.get('entityId');
            Radio.channel('navigate').trigger('show:int:dp:entity', entityId);
          }
        })
      })
    });
  }
});
