/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

var SelectRowCell = require('psa-backgrid/SelectRowCell');

var SelectAllHeaderCell = SelectRowCell.SelectAllHeaderCell;

SelectRowCell = SelectRowCell.SelectRowCell.extend({
  render: function() {
    var editable = this.column.get('editable');
    if (_.isFunction(editable) && this.model) {
      editable = editable(this.model);
    }

    this.$el.empty().append('<input tabindex="-1" type="checkbox" />');
    this.delegateEvents();
    if (!_.isUndefined(this.column.get('width'))) {
      this.$el.attr('width', this.column.get('width') + '%');
      this.$el.css('text-align', 'center');
    }
    if (!_.isUndefined(editable) && editable === false) {
      this.$el.find('input[type=checkbox]').attr('disabled', 'true');
    }
    var columnName = this.column.get('name');
    this.checkbox().prop('checked', this.model.get(columnName));
    this.model.trigger('backgrid:selected', this.model, this.model.get(columnName));
    return this;
  }
});

module.exports = {
  SelectRowCell: SelectRowCell,
  SelectAllHeaderCell: SelectAllHeaderCell
};
