/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');
var UIUtil = require('psa-core/util/ui');

var SideNavSectionView = require('common/views/SideNavSectionView');
var BreadcrumbView = require('common/views/BreadcrumbView');
var DropdownView = require('common/views/DropdownView');
var ConfirmationModalView = require('common/views/ConfirmationModalView');
var ContentServerLinkView = require('common/views/ContentServerLinkView');

var DepFundDetailsSectionView = require('./DepFundDetailsSectionView');

var DeleteTrustSubFund = require('../services/DeleteTrustSubFund');

var tmplDepSubFundDashboardView = require('../templates/DepSubFundDashboardView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepSubFundDashboardView,

  className: 'dep-sub-fund-dashboard-view container-fluid',

  ui: {
    subFundWrapper: '.sub-fund-wrapper',
    subFundActionDropdown: '.dropdown-container',
    csLinkContainer: '.cs-link-container',
    breadcrumb: '.breadcrumb-container'
  },

  regions: {
    subFundWrapper: '@ui.subFundWrapper',
    subFundActionDropdown: '@ui.subFundActionDropdown',
    csLinkContainer: '@ui.csLinkContainer',
    breadcrumb: '@ui.breadcrumb'
  },

  templateHelpers: function() {
    var fund = this.model.get('fund') || {};
    var hasRelatedFiling = !!fund.filingId;
    return {
      hasRelatedFiling: hasRelatedFiling
    };
  },

  onBeforeShow: function() {
    var fund = this.model.get('fund') || {},
      entityId = fund.entityId,
      entityName = fund.entityName,
      fundId = fund.fundId,
      fundName = fund.fundName,
      subFundId = fund.id,
      subFundName = fund.name;

    var isTrust = this.model.get('entityCategoryId') === '3';

    var dropDownOptions = [
      {
        link: '#dep/entity/' + entityId + '/fund/' + fundId + '/sub-fund/' + subFundId + '/edit',
        text: 'Edit Sub-Fund'
      },
      {
        text: 'Delete Sub-Fund',
        onClick: this.onClickDelete.bind(this)
      },
      {
        isDivider: true
      },
      {
        link: '#dep/entity/' + entityId + '/fund/' + fundId + '/sub-fund/' + subFundId + '/new-audit',
        text: 'Add Sub-fund Audit'
      }
    ];

    this.showChildView(
      'subFundActionDropdown',
      new DropdownView({
        rightAlign: true,
        model: new Backbone.Model({
          name: 'Fund Actions',
          options: dropDownOptions
        })
      })
    );

    this.Fund = new SideNavSectionView({
      collection: new Backbone.Collection([
        {
          id: 'fundDetails',
          title: 'Fund Details',
          view: new DepFundDetailsSectionView({
            model: new Backbone.Model(this.model.get('fund'))
          })
        },
        {
          id: 'audits',
          title: 'Audits',
          view: Radio.channel('dp').request('build:sub-fund-audit-view', subFundId, isTrust)
        },
        {
          id: 'notes',
          title: 'Notes',
          view: Radio.channel('dp').request('build:notes-view', {
            objectId: subFundId,
            objectTypeId: 'CIF-SUBFUND'
          })
        },
        {
          id: 'auditLog',
          title: 'Audit Log',
          view: Radio.channel('dp').request('build:audit-log-view', {
            objectId: subFundId,
            objectTypeId: 'CIF-SUBFUND'
          })
        }
      ])
    });
    this.showChildView('subFundWrapper', this.Fund);

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#dep/dashboard',
            text: 'Home'
          },
          {
            link: '#dep/entity/' + entityId + '/dashboard',
            text: entityName
          },
          {
            link: '#dep/entity/' + entityId + '/fund/' + fundId + '/dashboard',
            text: fundName
          },
          {
            text: subFundName
          }
        ]
      })
    );

    this.showChildView(
      'csLinkContainer',
      new ContentServerLinkView({
        model: new Backbone.Model({
          buttonText: 'Open CIF/CTF Folder',
          contentServerId: this.model.get('csFolderId')
        })
      })
    );
  },

  onClickDelete: function() {
    var fund = this.model.get('fund');
    UIUtil.showModal({
      title: 'Confirm Deleting Sub-Fund',
      view: new ConfirmationModalView({
        confirmText: 'Delete Sub-Fund',
        model: new Backbone.Model({
          message: 'Are you sure you want to delete this collective investment sub-fund?',
          onConfirm: function() {
            return DeleteTrustSubFund(fund.id);
          },
          onConfirmDone: function() {
            Radio.channel('navigate').trigger('show:int:dp:fund', fund.entityId, fund.fundId);
          }
        })
      })
    });
  }
});
