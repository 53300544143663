var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "\r\n<div class=\"page-title clearfix\">\r\n  <h1 class=\"pull-left m-n p-n\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.individual : depth0)) != null ? stack1.individualType : stack1), depth0))
    + " - "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.individual : depth0)) != null ? stack1.firstName : stack1), depth0))
    + " "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.individual : depth0)) != null ? stack1.lastName : stack1), depth0))
    + "</h1>\r\n  <div class=\"labels-container\">\r\n    <div class=\"nmls-label-container\"></div>\r\n  </div>\r\n  <div class=\"page-title-buttons-container\">\r\n    <div class=\"cs-link-container\"></div>\r\n    <div class=\"dropdown-container\"></div>\r\n  </div>\r\n</div>\r\n<div class=\"breadcrumb-container\"></div>\r\n<div class=\"individual-wrapper\"></div>";
},"useData":true});