/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var NProgress = require('nprogress');

var NavUtils = require('psa-core/util/nav');
var Scaffold = require('common/layout/Scaffold');

var GetLicenseIdentifiers = require('../services/GetLicenseIdentifiers');

var NonDepLicenseDocGenView = require('../views/NonDepLicenseDocGenView');

module.exports = function(entityId, licenseId) {
  NProgress.start();
  NProgress.set(0.6);

  GetLicenseIdentifiers(licenseId)
    .done(function(licenseIdentifier) {
      NavUtils.navigate('nondep/entity/' + entityId + '/license/' + licenseId + '/doc-gen');

      Scaffold.content.show(
        new NonDepLicenseDocGenView({
          model: new Backbone.Model({ licenseIdentifier: licenseIdentifier, entityId: entityId })
        })
      );
      NProgress.done();
    })
    .fail(function(err) {
      NProgress.done();
    });
};
