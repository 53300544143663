/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');

var UIUtil = require('psa-core/util/ui');

var modelGet = require('common/util/modelGet');
var ContentServerLinkView = require('common/views/ContentServerLinkView');
var SideNavSectionView = require('common/views/SideNavSectionView');
var BreadcrumbView = require('common/views/BreadcrumbView');
var DropdownView = require('common/views/DropdownView');
var HeaderBarView = require('common/views/HeaderBarView');

var SecIndividualLicenseSectionView = require('./SecIndividualLicenseSectionView');

var tmplSecIntIndividualLicenseDashboardView = require('../templates/SecIntIndividualLicenseDashboardView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplSecIntIndividualLicenseDashboardView,

  className: 'sec-int-individual-license-dashboard-view container-fluid m-b-lg',

  ui: {
    individualLicenseWrapper: '.individual-license-wrapper',
    csLinkContainer: '.cs-link-container',
    actionDropdown: '.dropdown-container',
    breadcrumb: '.breadcrumb-container',
    headerBar: '.header-bar-container'
  },

  regions: {
    individualLicenseWrapper: '@ui.individualLicenseWrapper',
    csLinkContainer: '@ui.csLinkContainer',
    actionDropdown: '@ui.actionDropdown',
    breadcrumb: '@ui.breadcrumb',
    headerBar: '@ui.headerBar'
  },

  onBeforeShow: function() {
    var identifiers = this.model.get('identifiers') || {};
    var license = this.model.get('license') || {};
    var licenseId = this.model.get('licenseId') || {};
    var individualId = modelGet(this.model, 'individualId');
    var individual = modelGet(this.model, 'individual');
    var licenseType = identifiers.type;

    identifiers.title = 'License';
    var headerBarColumns = [
      {
        name: 'CRDNumber',
        label: 'CRD Number',
        minWidth: '160px',
        maxWidth: '200px'
      },
      {
        name: 'firstName',
        label: 'First Name',
        minWidth: '160px',
        maxWidth: '200px'
      },
      {
        name: 'middleInitialDisplay',
        label: 'Middle Initial',
        minWidth: '160px',
        maxWidth: '200px'
      },
      {
        name: 'lastName',
        label: 'Last Name',
        minWidth: '160px',
        maxWidth: '200px'
      }
    ];

    this.showChildView(
      'headerBar',
      new HeaderBarView({
        columns: new Backbone.Collection(headerBarColumns),
        model: new Backbone.Model(identifiers)
      })
    );

    this.showChildView(
      'csLinkContainer',
      new ContentServerLinkView({
        model: new Backbone.Model({
          buttonText: 'Open License Folder',
          contentServerId: license.CSFolderId || individual.CSFolderId
        })
      })
    );

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#sec/dashboard',
            text: 'Home'
          },
          {
            link: '#sec/individual/' + individualId + '/dashboard',
            text: identifiers.dbaName
          },
          {
            text: licenseType
          }
        ]
      })
    );

    var dropDownOptions = [
      {
        text: 'Edit License',
        link: '#sec/individual/' + individualId + '/license/' + licenseId + '/edit'
      },
      {
        isDivider: true
      },
      {
        text: 'Add License Information Detail',
        onClick: this.onClickAddLicenseInfo.bind(this)
      },
      {
        text: 'Add Payment - Non FINRA Member Details',
        onClick: this.onClickAddPayment.bind(this)
      },
      {
        text: 'Add Investigation/Inquiry',
        link: '#sec/individual/' + individualId + '/license/' + licenseId + '/new-investigation-inquiry'
      }
    ];

    var sideNavTabs = [
      {
        id: 'license',
        title: 'License',
        view: new SecIndividualLicenseSectionView({
          model: new Backbone.Model(this.model.get('license'))
        })
      },
      {
        id: 'licenseInformation',
        title: 'License Information List',
        view: Radio.channel('sc').request('build:license-info-view', {
          objectId: licenseId,
          objectTypeId: 'IND-LICENSE'
        })
      },
      {
        id: 'payments',
        title: 'Payments - Non FINRA Member List',
        view: Radio.channel('sc').request('build:license-nonfinra-payment-member-view', {
          objectId: licenseId,
          objectTypeId: 'IND-LICENSE'
        })
      },
      {
        id: 'invinq',
        title: 'Investigations/Inquiries',
        view: Radio.channel('sc').request('build:related-inv-inq-section-view', {
          objectTypeId: 'INDIVIDUAL',
          objectId: individualId,
          objectLicenseId: licenseId
        })
      },
      {
        id: 'notes',
        title: 'Notes',
        view: Radio.channel('sc').request('build:notes-view', {
          objectId: licenseId,
          objectTypeId: 'IND-LICENSE'
        })
      },
      {
        id: 'auditLog',
        title: 'Audit Log',
        view: Radio.channel('sc').request('build:audit-log-view', {
          objectId: licenseId,
          objectTypeId: 'IND-LICENSE'
        })
      }
    ];

    this.showChildView(
      'actionDropdown',
      new DropdownView({
        rightAlign: true,
        model: new Backbone.Model({
          name: 'License Actions',
          options: dropDownOptions
        })
      })
    );

    this.individual = new SideNavSectionView({
      collection: new Backbone.Collection(sideNavTabs)
    });

    this.showChildView('individualLicenseWrapper', this.individual);
  },

  onClickAddLicenseInfo: function(e, model) {
    var self = this;
    var modalViewPromise = Radio.channel('sc').request('build:license-info-modal-view', {
      objectId: self.model.get('licenseId'),
      objectTypeId: self.options.objectTypeId
    });
    UIUtil.showModal({
      title: 'Add License Information Details',
      view: modalViewPromise
    }).done(function(wasSuccessful) {
      if (wasSuccessful) {
        Backbone.history.loadUrl();
      }
    });
  },

  onClickAddPayment: function() {
    var self = this;
    var modalViewPromise = Radio.channel('sc').request('build:license-nonfinra-payment-member-modal-view', {
      objectId: self.model.get('licenseId'),
      objectTypeId: self.options.objectTypeId
    });
    UIUtil.showModal({
      title: 'Add Payments - Non FINRA Member Details',
      view: modalViewPromise
    }).done(function(wasSuccessful) {
      if (wasSuccessful) {
        Backbone.history.loadUrl();
      }
    });
  }
});
