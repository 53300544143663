/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var momentUtil = require('common/util/momentUtil');
var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(applicationId) {
  var defer = $.Deferred();

  $.soap({
    body: {
      GetCollectorRepossessorRenewalApplication: {
        $xmlns: 'http://dobs.pa.gov/nd/application/bpm/1.0',
        ApplicationID: applicationId
      }
    },
    blueprintArrays: [
      'GetCollectorRepossessorRenewalApplicationResponse.Employees.ND_CollectorRepossessorEmployee',
      'GetCollectorRepossessorRenewalApplicationResponse.CanceledEmployees.ND_AppLicenseCollectorRepossessorRenewCancelation',
      'GetCollectorRepossessorRenewalApplicationResponse.ApplicationDocuments.ND_ApplicationDocument'
    ],
    blueprint: {
      renewalData: {
        id: '$..ND_AppLicenseConsumerDiscountRenew.ID',
        applicationId: '$..ND_AppLicenseConsumerDiscountRenew.ApplicationID',
        hasCriminalOfficer: '$..HasCriminalOfficer',
        hasCriminalOfficerExplaination: '$..HasCriminalOfficerExplanation',
        hasCriminalOfficerDocument: {
          fileId: '$..HasCriminalOfficerDocumentID',
          fileName: '$..HasCriminalOfficerDocumentName'
        },
        hasOfficerWithAdministrativeAction: '$..HasOfficerWithAdministrativeAction',
        hasOfficerWithAdministrativeActionExplaination: '$..HasOfficerWithAdministrativeActionExplaination',
        hasOfficerWithAdministrativeActionDocument: {
          fileId: '$..HasOfficerWithAdministrativeActionDocumentID',
          fileName: '$..HasOfficerWithAdministrativeActionDocumentName'
        },
        hasLegalProceedings: '$..HasLegalProceedings',
        hasLegalProceedingsExplaination: '$..HasLegalProceedingsExplaination',
        hasLegalProceedingsDocument: {
          fileId: '$..HasLegalProceedingsDocumentID',
          fileName: '$..HasLegalProceedingsDocumentName'
        },
        bondCompanyId: '$..BondCompanyID',
        bondNumber: '$..BondNumber',
        bondDocument: {
          fileId: '$..BondDocumentID',
          fileName: '$..BondDocumentName'
        },
        hasCollectedMonies: '$..HasCollectedMonies',
        numberOfContractsReceived: '$..NumberOfContractsReceived',
        contractsDollarValue: '$..ContractsDollarValue',
        thirdPartyDollarValue: '$..ThirdPartyDollarValue',
        reportingZeroReason: '$..ReportingZeroReason',
        hasRepossessMotorVehicles: '$..HasRepossessMotorVehicles',
        totalNumberVehiclesRepossess: '$..TotalNumberVehiclesRepossess',
        noVehiclesRepossessExplanation: '$..NoVehiclesRepossessExplanation',
        certificationName: '$..CertificationName',
        isCertified: '$..IsCertified',
        applicationDocuments: [
          '$..ND_ApplicationDocument',
          {
            fileId: '$.DocumentID',
            fileName: '$..Name'
          }
        ]
      },
      employees: [
        '$..ND_CollectorRepossessorEmployee',
        {
          id: '$..ID',
          fullName: '$..FullName',
          hasCriminalHistory: '$..HasCriminalHistory',
          hasCriminalHistoryExplaination: '$..HasCriminalHistoryExplaination',
          licenseId: '$..LicenseID',
          address: {
            addressLine1: '$..AddressLine1',
            addressLine2: '$..AddressLine2',
            addressLine3: '$..AddressLine3',
            city: '$..City',
            state: '$..State',
            postalCode: '$..PostalCode',
            country: '$..Country'
          },
          proofOfEmploymentDocument: {
            fileId: '$..ProofOfEmploymentDocumentID',
            fileName: '$..ProofOfEmploymentDocument'
          },
          identificationCardDocument: {
            fileId: '$..IdentificationCardDocumentID',
            fileName: '$..IdentificationCardDocument'
          },
          licenseExpirationDate: '$..LicenseExpirationDate'
        }
      ],
      canceledEmployees: [
        '$..ND_AppLicenseCollectorRepossessorRenewCancelation',
        {
          id: '$..ID',
          applicationId: '$..ApplicationID',
          crEmployeeId: '$..CREmployeeID'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      var renewalData = response.renewalData;

      if (renewalData) {
        if (renewalData.isCertified) {
          renewalData.isCertified = renewalData.isCertified.__text
            ? renewalData.isCertified.__text === '1'
            : renewalData.isCertified === '1';
        }
        if (renewalData.certificationName) {
          renewalData.certificationName = renewalData.certificationName.__text
            ? renewalData.certificationName.__text
            : renewalData.certificationName;
        }
      }

      _.each(response.employees, function(cr) {
        cr.hasCriminalHistoryYesNo = cr.hasCriminalHistory === '1' ? 'Yes' : 'No';
        cr.licenseExpirationDate = momentUtil.formatStr(cr.licenseExpirationDate, momentUtil.defaultInboundDateConfig);
      });

      defer.resolve(response);
    })
    .fail(function(err) {
      defer.reject(err);
    });
  return defer.promise();
};
