var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "\r\n<div class=\"page-title clearfix\">\r\n  <h1 class=\"page-title m-n p-n\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.applicationDetails : depth0)) != null ? stack1.entityName : stack1), depth0))
    + "</h1>\r\n</div>\r\n<div class=\"header-bar\"></div>\r\n<div class=\"breadcrumb-container\"></div>\r\n<h2 class=\"m-t-n\">Generate Delinquency Letter</h2>\r\n<form class=\"form-horizontal doc-gen-form m-t-lg\">\r\n  <div class=\"form-group required\">\r\n    <label class=\"control-label col-sm-3\">External Review Comments</label>\r\n    <div class=\"col-sm-6\">\r\n      <textarea class=\"form-control\" name=\"externalReviewComments\" rows=\"3\" data-parsley-required=\"true\"\r\n        placeholder=\"External Review Comments\"></textarea>\r\n    </div>\r\n  </div>\r\n</form>\r\n<div class=\"row\">\r\n  <div class=\"col-sm-6 col-sm-offset-3\">\r\n     <a href=\"#nondep/application/"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.applicationDetails : depth0)) != null ? stack1.applicationId : stack1), depth0))
    + "/dashboard\" class=\"btn btn-default\" role=\"button\">Cancel</a>\r\n    <button class=\"btn btn-primary submit ladda-button\" data-style=\"expand-right\" type=\"button\"><span\r\n        class=\"ladda-label\">Generate Document</span></button>\r\n  </div>\r\n</div>\r\n<form class=\"form-horizontal\">\r\n  <div class=\"form-group m-t-sm\">\r\n    <label class=\"control-label collapse download-container col-sm-3\">Download Delinquency Letter:</label>\r\n    <div class=\"col-sm-6\">\r\n      <div class=\"document-container pull-left\"></div>\r\n    </div>\r\n  </div>\r\n</form>";
},"useData":true});