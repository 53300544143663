var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "      <option value=\""
    + alias2(alias1(depth0, depth0))
    + "\">"
    + alias2(alias1(depth0, depth0))
    + "</option>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "\r\n\r\n<div class=\"page-title clearfix\">\r\n  <h1 class=\"pull-left m-n p-n\">Time Tracking</h1>\r\n</div>\r\n<form class=\"form-inline m-t-md\">\r\n  <div class=\"form-group\">\r\n    <label class=\"control-label\">Year</label>\r\n    <select name=\"year\" class=\"form-control year-select m-l-xs\">\r\n      <option value=\"\">--</option>\r\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.years : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </select>\r\n  </div>\r\n  <div class=\"form-group m-l-md\">\r\n    <label class=\"control-label\">Period</label>\r\n  </div>\r\n  <div class=\"form-group\">\r\n    <div class=\"m-l-xs period-select\"></div>\r\n  </div>\r\n  <button class=\"btn btn-primary go-to-period m-l-md\" data-dismiss=\"modal\">Go</button>\r\n</form>\r\n<div class=\"time-tracking-form m-t-lg\"></div>";
},"useData":true});