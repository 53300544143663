/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(licenseTypeId) {
  var defer = $.Deferred();
  $.soap({
    body: {
      GetLicenseContactTypesByLicenseType: {
        $xmlns: 'http://dobs.pa.gov/nd/lookup/bpm/1.0',
        LicenseTypeID: licenseTypeId
      }
    },
    blueprintArrays: ['GetLicenseContactTypesByLicenseTypeResponse.ND_LU_LicenseContactType'],
    blueprint: {
      contactTypes: [
        '$..ND_LU_LicenseContactType',
        {
          id: '$.ID',
          name: '$.Name',
          isRequired: '$.IsRequired'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      response.contactTypes = _.filter(response.contactTypes, function(contactType) {
        return contactType.isRequired === '1';
      });
      defer.resolve(response.contactTypes);
    })
    .fail(function(err) {
      defer.reject();
    });

  return defer.promise();
};
