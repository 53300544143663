var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "      <p class=\"form-control-static\">"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.feedback : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + "</p>\r\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.feedback || (depth0 != null ? depth0.feedback : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"feedback","hash":{},"data":data}) : helper)));
},"4":function(container,depth0,helpers,partials,data) {
    return "N/A";
},"6":function(container,depth0,helpers,partials,data) {
    return "      <textarea class=\"form-control feedback-input\" name=\"feedback\" data-parsley-required=\"true\" rows=\"12\" placeholder=\"Provide feedback here\"></textarea>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "\r\n<div class=\"form-group required\">\r\n  <label class=\"control-label col-md-3\">"
    + alias4(((helper = (helper = helpers.feedbackLabel || (depth0 != null ? depth0.feedbackLabel : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"feedbackLabel","hash":{},"data":data}) : helper)))
    + "</label>\r\n  <div class=\"col-lg-7 col-md-9\">\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isReadOnly : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(6, data, 0),"data":data})) != null ? stack1 : "")
    + "  </div>\r\n</div>\r\n<div class=\"form-group\">\r\n  <label class=\"control-label col-md-3\">"
    + alias4(((helper = (helper = helpers.documentsLabel || (depth0 != null ? depth0.documentsLabel : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"documentsLabel","hash":{},"data":data}) : helper)))
    + " Documents</label>\r\n  <div class=\"col-lg-7 col-md-9\">\r\n    <div class=\"supporting-documents-container\"></div>\r\n  </div>\r\n</div>";
},"useData":true});