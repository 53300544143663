/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Radio = require('backbone.radio');
var Ladda = require('ladda');

var UIUtil = require('psa-core/util/ui');
var GridView = require('psa-marionette/views/GridView');

var modelGet = require('common/util/modelGet');
var BreadcrumbView = require('common/views/BreadcrumbView');
var ParagraphCell = require('common/backgrid/ParagraphCell');
var MultiButtonCell = require('common/backgrid/MultiButtonCell');

var DepExamOtherInformationViolationModalView = require('./DepExamOtherInformationViolationModalView');
var DepExamOtherInformationConcentrationModalView = require('./DepExamOtherInformationConcentrationModalView');

var SubmitEntityExamOtherInformation = require('../services/SubmitEntityExamOtherInformation');

var tmplDepExamOtherInfomrationEditView = require('../templates/DepExamOtherInformationEditView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepExamOtherInfomrationEditView,

  className: 'dep-exam-other-information-edit-view container-fluid m-b-lg',

  ui: {
    form: 'form',
    breadcrumb: '.breadcrumb-container',

    addViolation: '.btn-add-violation',
    addConcentration: '.btn-add-concentration',

    violationGrid: '.violation-information-grid-container',
    concentrationGrid: '.concentration-information-grid-container',

    datepicker: '.datepicker',

    submit: '.btn-submit'
  },

  regions: {
    breadcrumb: '@ui.breadcrumb',
    violationGrid: '@ui.violationGrid',
    concentrationGrid: '@ui.concentrationGrid'
  },

  events: {
    'click @ui.addViolation': 'onClickAddViolation',
    'click @ui.addConcentration': 'onClickAddConcentration',
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeShow: function() {
    var entityId = this.model.get('entityId');
    var entityName = this.model.get('entityName');
    var examId = modelGet(this.model, 'exam.id');
    var examType = modelGet(this.model, 'exam.examType');
    var leadAgency = modelGet(this.model, 'exam.leadAgency');

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#dep/dashboard',
            text: 'Home'
          },
          {
            link: '#dep/entity/' + entityId + '/dashboard',
            text: entityName
          },
          {
            link: '#dep/entity/' + entityId + '/exam/' + examId + '/dashboard',
            text: examType + ' - ' + leadAgency
          },
          {
            text: 'Edit Other Information'
          }
        ]
      })
    );

    Syphon.deserialize(this, this.model.get('exam'));

    this.violationCollection = new Backbone.Collection(modelGet(this.model, 'exam.examViolations', []));
    this.concentrationCollection = new Backbone.Collection(modelGet(this.model, 'exam.examConcentrations', []));

    this.showChildView(
      'violationGrid',
      new GridView({
        columns: [
          {
            name: 'legalCiteDisplay',
            label: 'Legal Cite',
            cell: 'string',
            headerCell: 'custom',
            width: 20,
            editable: false
          },
          {
            name: 'commentsDisplay',
            label: 'Comments',
            cell: ParagraphCell,
            headerCell: 'custom',
            width: 50,
            editable: false
          },
          {
            name: '',
            label: '',
            cell: MultiButtonCell.extend({
              buttons: [
                {
                  name: '',
                  labelClass: 'fa fa-edit',
                  buttonClass: 'btn-primary btn-sm edit-processing-response',
                  onClick: this.onClickEditViolation.bind(this)
                },
                {
                  name: '',
                  labelClass: 'fa fa-trash',
                  buttonClass: 'btn-danger btn-sm delete-processing-response',
                  onClick: this.onClickDeleteViolation.bind(this)
                }
              ]
            }),
            editable: false,
            sortable: false
          }
        ],
        collection: this.violationCollection,
        emptyText: 'No Exam Violations'
      })
    );

    this.showChildView(
      'concentrationGrid',
      new GridView({
        emptyText: 'No Concentrations',
        columns: [
          {
            name: 'typeDisplay',
            label: 'Type',
            cell: 'string',
            headerCell: 'custom',
            width: 20,
            editable: false,
            sortable: false
          },
          {
            name: 'subTypeDisplay',
            label: 'SubType',
            cell: 'string',
            headerCell: 'custom',
            width: 20,
            editable: false,
            sortable: false
          },
          {
            name: 'commentsDisplay',
            label: 'Comments',
            cell: ParagraphCell,
            headerCell: 'custom',
            width: 35,
            editable: false,
            sortable: false
          },
          {
            name: 'editDeleteMultiButtonCell',
            label: '',
            cell: MultiButtonCell.extend({
              buttons: [
                {
                  name: '',
                  labelClass: 'fa fa-edit',
                  buttonClass: 'btn-primary btn-sm edit-processing-response',
                  onClick: this.onClickEditConcentration.bind(this)
                },
                {
                  name: '',
                  labelClass: 'fa fa-trash',
                  buttonClass: 'btn-danger btn-sm delete-processing-response',
                  onClick: this.onClickDeleteConcentration.bind(this)
                }
              ]
            })
          }
        ],
        collection: this.concentrationCollection
      })
    );
  },

  validate: function() {
    return this.ui.form.parsley().whenValidate();
  },

  getFormData: function() {
    var formData = Syphon.serialize(this.ui.form);

    formData.examViolations = this.violationCollection.toJSON();

    formData.examConcentrations = this.concentrationCollection.toJSON();

    var currentExamConcentrations = modelGet(this.model, 'exam.examConcentrations', []),
      currentExamViolations = modelGet(this.model, 'exam.examViolations', []);

    formData.isExamViolationsUpdated = +(
      JSON.stringify(currentExamViolations) !== JSON.stringify(formData.examViolations)
    );

    formData.isExamConcentrationsUpdated = +(
      JSON.stringify(currentExamConcentrations) !== JSON.stringify(formData.examConcentrations)
    );

    return formData;
  },

  onClickAddViolation: function(e) {
    e.preventDefault();

    UIUtil.showModal({
      title: 'New Exam Violation',
      view: new DepExamOtherInformationViolationModalView({
        lookups: {
          examViolationLegalCites: modelGet(this.model, 'lookups.examViolationLegalCites'),
          examViolationDescriptions: modelGet(this.model, 'lookups.examViolationDescriptions')
        },
        model: new Backbone.Model({})
      })
    }).done(
      function(violation) {
        violation.entityId = modelGet(this.model, 'exam.entityId');
        this.violationCollection.add(violation);
      }.bind(this)
    );
  },

  onClickAddConcentration: function(e) {
    e.preventDefault();

    UIUtil.showModal({
      title: 'New Exam Concentration',
      view: new DepExamOtherInformationConcentrationModalView({
        lookups: {
          examConcentrationTypes: modelGet(this.model, 'lookups.examConcentrationTypes'),
          examConcentrationSubTypes: modelGet(this.model, 'lookups.examConcentrationSubTypes')
        },
        model: this.model
      })
    }).done(
      function(concentration) {
        concentration.entityId = modelGet(this.model, 'exam.entityId');
        this.concentrationCollection.add(concentration);
      }.bind(this)
    );
  },

  onClickEditViolation: function(e, violation) {
    e.preventDefault();

    UIUtil.showModal({
      title: 'New Exam Violation',
      view: new DepExamOtherInformationViolationModalView({
        lookups: {
          examViolationLegalCites: modelGet(this.model, 'lookups.examViolationLegalCites'),
          examViolationDescriptions: modelGet(this.model, 'lookups.examViolationDescriptions')
        },
        model: violation
      })
    }).done(function(editedViolation) {
      violation.set(editedViolation);
    });
  },

  onClickEditConcentration: function(e, concentration) {
    e.preventDefault();

    UIUtil.showModal({
      title: 'New Exam Concentration',
      view: new DepExamOtherInformationConcentrationModalView({
        lookups: {
          examConcentrationTypes: modelGet(this.model, 'lookups.examConcentrationTypes'),
          examConcentrationSubTypes: modelGet(this.model, 'lookups.examConcentrationSubTypes')
        },
        model: concentration
      })
    }).done(function(editedConcentration) {
      concentration.set(editedConcentration);
    });
  },

  onClickDeleteViolation: function(e, violation) {
    e.preventDefault();
    this.violationCollection.remove(violation);
  },

  onClickDeleteConcentration: function(e, concentration) {
    e.preventDefault();
    this.concentrationCollection.remove(concentration);
  },

  onClickSubmit: function(e) {
    e.preventDefault();

    var l = Ladda.create(this.ui.submit[0]);
    l.start();
    this.validate()
      .done(
        function() {
          var formData = this.getFormData(),
            entityId = this.model.get('entityId');

          formData.id = modelGet(this.model, 'exam.id');

          SubmitEntityExamOtherInformation(formData)
            .done(function(examId) {
              Radio.channel('navigate').trigger('show:int:dp:exam', entityId, examId);
            })
            .fail(function(err) {
              l.stop();
            });
        }.bind(this)
      )
      .fail(function() {
        l.stop();
      });
  }
});
