/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var NProgress = require('nprogress');

var NavUtils = require('psa-core/util/nav');
var Session = require('psa-core/Session');

var Scaffold = require('common/layout/Scaffold');

var GetAllIndLicenseLookups = require('../../common/services/GetAllIndLicenseLookups');
var SecIndividualSearchView = require('../views/SecIndividualSearchView');

module.exports = function() {
  NProgress.start();
  NProgress.set(0.6);
  var searchLimit = Session.config.CM_ELASTICSEARCH_MAX_RESULTS || 1000;
  GetAllIndLicenseLookups()
    .done(function(indLicenseLookups) {
      Scaffold.content.show(
        new SecIndividualSearchView({
          model: new Backbone.Model({
            searchLimit: searchLimit,
            indLicenseStatuses: indLicenseLookups.indLicenseStatuses,
            indLicenseTypes: indLicenseLookups.indLicenseTypes
          })
        })
      );
      NavUtils.navigate('sec/individual-search');
      NProgress.done();
    })
    .fail(function() {
      NProgress.done();
    });
};
