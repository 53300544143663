/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(formData) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      SubmitInvInqRelatedCase: {
        $xmlns: 'http://dobs.pa.gov/sc/investigationinquiry/bpm/1.0',
        RelatedCaseID: formData.relatedCaseId,
        InvInqID: formData.invInqId,
        ObjectTypeID: formData.objectTypeId,
        ObjectID: formData.objectId,
        RelatedTypeID: formData.relatedTypeId,
        OtherCaseInfo: formData.otherCaseInfo,
        IsDeleted: formData.isDeleted
      }
    },
    blueprint: {
      id: '$..ID',
      invInqId: '$..InvInqID',
      relatedTypeId: '$..RelatedTypeID',
      relatedType: '$..RelatedType',
      objectTypeId: '$..ObjectTypeID',
      objectId: '$..ObjectID',
      category: '$..Category',
      status: '$..Status',
      caseNumber: '$..CaseNumber',
      openedDate: '$..OpenedDate',
      closedDate: '$..ClosedDate',
      upgradedDate: '$..UpgradedDate',
      otherCaseInfo: '$..OtherCaseInfo',
      isDeleted: '$..IsDeleted'
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      if (response.openedDate) {
        response.openedDate = moment(response.openedDate, 'YYYY-MM-DD');
      }
      if (response.closedDate) {
        response.closedDate = moment(response.closedDate, 'YYYY-MM-DD');
      }
      if (response.upgradedDate) {
        response.upgradedDate = moment(response.upgradedDate, 'YYYY-MM-DD');
      }
      if (response.relatedTypeId === '1') {
        response.objectType = 'exam';
      } else {
        response.objectType = 'investigation-inquiry';
      }

      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
