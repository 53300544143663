/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');
var NProgress = require('nprogress');
var Syphon = require('backbone.syphon');

var GetInternalUserDetails = require('common/services/GetInternalUserDetails');
var Session = require('psa-core/Session');

var HandleOTDSRESTAuthentication = require('../../services/HandleOTDSRESTAuthentication');
var GetConfigurations = require('../../services/GetConfigurations');
var GetDepartments = require('../../services/GetDepartments');

require('../styles/LoginView.css');
var tmplLoginView = require('../templates/LoginView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplLoginView,

  className: 'login-view',

  ui: {
    loginBtn: '.btn-login',
    username: '.username',
    password: '.password',
    loginError: '.login-error-message',
    generalError: '.general-error-message'
  },

  events: {
    'click @ui.loginBtn': 'handleLogin'
  },

  handleLogin: function(e) {
    e.preventDefault();
    var self = this;

    var formData = Syphon.serialize(this);
    var username = formData.username;
    var password = formData.password;

    this.ui.loginError.hide();
    this.ui.generalError.hide();
    this.ui.loginBtn.prop('disabled', true);
    this.ui.username.prop('disabled', true);
    this.ui.password.prop('disabled', true);

    NProgress.start();
    HandleOTDSRESTAuthentication(username, password)
      .done(function() {
        NProgress.set(0.6);

        $.when(GetInternalUserDetails(), GetConfigurations(), GetDepartments())
          .done(function(profile, config, departments) {
            Session.user = profile;
            Session.config = config;
            Session.departments = departments;
            Radio.channel('global').trigger('login:finish');
            NProgress.done();
          })
          .fail(function(err) {
            self.flashLoginErrorMessage(true);
          });
      })
      .fail(function(err) {
        NProgress.done();
        self.flashLoginErrorMessage(false);
      });
  },

  flashLoginErrorMessage: function(isGeneral) {
    this.ui.loginBtn.prop('disabled', false);
    this.ui.username.prop('disabled', false);
    this.ui.password.prop('disabled', false);
    if (isGeneral) {
      this.ui.generalError.slideDown(300);
    } else {
      this.ui.loginError.slideDown(300);
    }
  }
});
