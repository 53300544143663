/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Ladda = require('ladda');
var _ = require('underscore');

var SideNavSectionView = require('common/views/SideNavSectionView');
var openInNewTab = require('common/util/openInNewTab');
var toggleForm = require('common/util/toggleForm');

// var DepFilingInstructionsSectionView = require('../../views/DepFilingInstructionsSectionView');
var DepFilingPointOfContactSectionView = require('../../views/DepFilingPointOfContactSectionView');
// var DepFilingLawyerSectionView = require('../../views/DepFilingLawyerSectionView');
// var DepCollectiveInvestmentFundCertificationSectionView = require('./DepCollectiveInvestmentFundCertificationSectionView');
var DepCollectiveInvestmentFundFundSectionView = require('./DepCollectiveInvestmentFundFundSectionView');
// var DepFilingSupportingDocumentsSectionView = require('../../views/DepFilingSupportingDocumentsSectionView');

var SubmitCollectiveInvestmentFundFiling = require('../../services/SubmitCollectiveInvestmentFundFiling');

require('../../styles/DepFilingPrintView.css');
var tmplDepCollectiveInvestmentFundFilingView = require('./DepCollectiveInvestmentFundFilingView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepCollectiveInvestmentFundFilingView,

  className: 'dep-collective-investment-fund-filing-view m-b-md',

  behaviors: {
    ReadOnlyFormBehavior: {}
  },

  ui: {
    filingSections: '.filing-sections',
    nextButton: '.next-button',
    previousButton: '.previous-button'
  },

  events: {
    'click @ui.previousButton': 'onClickPrevious',
    'click @ui.nextButton': 'onClickNext'
  },

  regions: {
    filingSections: '@ui.filingSections'
  },

  onBeforeShow: function() {
    var filingDetails = this.model.get('filingDetails') || {};
    var filingContent = this.model.get('filingContent') || {};
    var filingId = this.model.get('filingId');
    var fundActions = this.model.get('fundActions');
    var subFundActions = this.model.get('subFundActions');
    // var lawyers = this.model.get('lawyers');
    // var entityCategoryId = filingDetails.entityCategoryId;

    var collectiveInvestmentFundFilingId = filingContent.id;
    // var instructionsDocument = filingDetails.instructionsDocument;
    // var additionalInstructionDocuments = filingDetails.instructionsDocuments;
    // var instructions = filingDetails.instructions;
    var pointOfContact = filingDetails.pointOfContact;
    var mode = this.options.mode;
    var isNewExternal = mode === 'ext-new';
    var isForPrinting = mode === 'print';
    var isExpandInternal = mode === 'int-expand';

    this.form = new SideNavSectionView({
      collection: new Backbone.Collection([
        {
          id: 'pointOfContact',
          title: 'Point of Contact',
          view: new DepFilingPointOfContactSectionView({
            mode: this.options.mode,
            isReadOnly: this.options.isReadOnly,
            isSubmission: true,
            hasAddress: false,
            model: new Backbone.Model(pointOfContact)
          })
        },
        // {
        //   id: 'collectiveInvestmentFundSubmissionLawyer',
        //   title: 'Filer',
        //   view: new DepFilingLawyerSectionView({
        //     mode: this.options.mode,
        //     isReadOnly: this.options.isReadOnly,
        //     model: new Backbone.Model({
        //       lawyers: lawyers,
        //       currentLawyer: filingDetails.lawyer
        //     })
        //   })
        // },
        // {
        //   id: 'instructions',
        //   title: 'I - General Information and Instructions',
        //   view: new DepFilingInstructionsSectionView({
        //     mode: this.options.mode,
        //     isReadOnly: this.options.isReadOnly,
        //     model: new Backbone.Model({
        //       instructionsDocument: instructionsDocument,
        //       additionalInstructionDocuments: additionalInstructionDocuments,
        //       instructions: instructions
        //     })
        //   })
        // },
        {
          id: 'funds',
          title: 'Fund Information',
          view: new DepCollectiveInvestmentFundFundSectionView({
            mode: this.options.mode,
            isReadOnly: this.options.isReadOnly,
            model: new Backbone.Model({
              filingContent: filingContent,
              fundActions: fundActions,
              subFundActions: subFundActions,
              filingId: filingId,
              collectiveInvestmentFundFilingId: collectiveInvestmentFundFilingId,
              filingDetails: filingDetails
            })
          })
        }
        // {
        //   id: 'supportingDocuments',
        //   title: 'Other Supporting Documents',
        //   view: new DepFilingSupportingDocumentsSectionView({
        //     mode: this.options.mode,
        //     isReadOnly: this.options.isReadOnly,
        //     model: new Backbone.Model({
        //       filingDetails: filingDetails,
        //       filingContent: filingContent,
        //       supportingDocDescriptionField: filingContent.supportingDocDescriptionField,
        //       collectiveInvestmentFundFilingId: collectiveInvestmentFundFilingId
        //     })
        //   })
        // }
        // {
        //   id: 'collectiveInvestmentFundCertification',
        //   title: 'Certification',
        //   view: new DepCollectiveInvestmentFundCertificationSectionView({
        //     mode: this.options.mode,
        //     isReadOnly: this.options.isReadOnly,
        //     model: new Backbone.Model({
        //       filingId: filingId,
        //       collectiveInvestmentFundFilingId: collectiveInvestmentFundFilingId,
        //       filingDetails: filingDetails
        //     })
        //   })
        // }
      ]),
      useNavigationButtons: false,
      expanded: isForPrinting || isExpandInternal,
      contentWidth: 'medium',
      useNavigationSavePrevButtons: !this.options.isReadOnly,
      buttons: [
        {
          buttonText: 'Save',
          buttonClass: 'btn-default ladda-button',
          buttonIconClass: 'fa-save',
          labelClass: 'ladda-label',
          buttonAttrs: {
            'data-style': 'expand-right'
          },
          onClick: this.onClickSave.bind(this)
        },
        {
          buttonText: 'Save & Print',
          buttonClass: 'btn-default ladda-button',
          buttonIconClass: 'fa-print',
          labelClass: 'ladda-label',
          buttonAttrs: {
            'data-style': 'expand-right'
          },
          onClick: this.onClickPrint.bind(this)
        }
      ]
    });

    // if (entityCategoryId === '3') {
    //   this.form.collection.remove(this.form.collection.get('collectiveInvestmentFundSubmissionLawyer'));
    // }

    if (this.options.isReadOnly) {
      this.form.collection.remove(this.form.collection.get('instructions'));
      this.form.collection.remove(this.form.collection.get('collectiveInvestmentFundCertification'));
    }

    // If this isn't the new external form, or if the form is in print mode, remove the buttons
    if (!isNewExternal || isForPrinting) {
      this.form.options.buttons = [];
    }

    this.showChildView('filingSections', this.form);
  },

  onAttach: function() {
    if (this.options.mode === 'print') {
      this.switchToPrint();
    }
  },

  switchToPrint: function() {
    var textAreas = $('.input-view');
    var self = this;
    _.each(textAreas, function(textArea) {
      var textAreaVal = textArea.value;
      var textAreaName = textArea.name;
      var printElementId = '#'.concat(textAreaName, 'PrintView');
      $(printElementId).text(textAreaVal);
    });

    var subFunds = self.model.get('filingContent').subFundFiling;
    _.each(subFunds, function(subFund) {
      var subFundElementId = '#summaryOfChanges'.concat(subFund.id);
      var subFundTextArea = $(subFundElementId);
      var subFundTextAreaVal = subFundTextArea.val();
      var printElementId = subFundElementId.concat('PrintView');
      $(printElementId).text(subFundTextAreaVal);
      toggleForm(subFundTextArea, false);
    });
    toggleForm(textAreas, false);
  },

  onClickNext: function(e) {
    var l = Ladda.create(e.currentTarget);
    l.start();

    this.saveForm()
      .done(function() {
        l.stop();
      })
      .fail(function() {
        l.stop();
      });
    window.scrollTo(0, 0);
  },

  onClickPrevious: function(e) {
    var l = Ladda.create(e.currentTarget);
    l.start();

    this.saveForm()
      .done(function() {
        l.stop();
      })
      .fail(function() {
        l.stop();
      });
    window.scrollTo(0, 0);
  },

  onClickSave: function(e) {
    var l = Ladda.create(e.currentTarget);
    l.start();

    this.saveForm()
      .done(function() {
        l.stop();
      })
      .fail(function() {
        l.stop();
      });
  },

  onClickPrint: function(e) {
    var filingId = this.model.get('filingId');
    var l = Ladda.create(e.currentTarget);
    l.start();

    this.saveForm()
      .done(function() {
        openInNewTab('#ext/dep/filing/' + filingId + '/print');
        l.stop();
      })
      .fail(function() {
        l.stop();
      });
  },

  saveForm: function() {
    var deferred = $.Deferred();
    var self = this;
    var filingId = this.model.get('filingId');
    var filingContent = this.model.get('filingContent');

    this.form
      .validateAll({ type: 'save' })
      .done(function() {
        var formData = self.form.getFormData();
        formData.filingId = filingId;
        formData.collectiveInvestmentFundFilingId = filingContent.id;
        SubmitCollectiveInvestmentFundFiling(formData)
          .done(function() {
            deferred.resolve();
          })
          .fail(function() {
            deferred.reject();
          });
      })
      .fail(function() {
        deferred.reject();
      });
    return deferred.promise();
  }
});
