/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');
var Ladda = require('ladda');

var modelGet = require('common/util/modelGet');
var BreadcrumbView = require('common/views/BreadcrumbView');
var SecInvInqHeaderBarView = require('./SecInvInqHeaderBarView');

var SubmitNewCorrespondence = require('../../common/services/SubmitNewCorrespondence');

var SecInitiateCorrespondenceFormView = require('../../common/views/SecInitiateCorrespondenceFormView');

var tmplSecInvInqInitiateCorrespondenceView = require('../templates/SecInvInqInitiateCorrespondenceView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplSecInvInqInitiateCorrespondenceView,

  className: 'sec-inv-inq-initiate-correspondence-view container-fluid',

  ui: {
    breadcrumb: '.breadcrumb-container',
    headerBar: '.header-bar-container',
    correspondenceFormContainer: '.correspondence-form-container',
    submit: '.submit'
  },

  regions: {
    breadcrumb: '@ui.breadcrumb',
    headerBar: '@ui.headerBar',
    correspondenceFormContainer: '@ui.correspondenceFormContainer'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeShow: function() {
    var identifiers = this.model.get('identifiers') || {};
    var invInqId = this.model.get('invInqId');

    var category = identifiers.category;
    var caseNumber = identifiers.caseNumber;
    var objectId = identifiers.objectId;
    var objectLicenseId = identifiers.objectLicenseId;

    var breadcrumbs = [
      {
        link: '#sec/dashboard',
        text: 'Home'
      }
    ];

    if (identifiers.isOrganization === '1') {
      breadcrumbs.push({
        link: '#sec/organization/' + objectId + '/dashboard',
        text: identifiers.applicantLegalName
      });
      if (objectLicenseId) {
        breadcrumbs.push({
          link: '#sec/organization/' + objectId + '/license/' + objectLicenseId + '/dashboard',
          text: identifiers.licenseType
        });
      }
    } else {
      breadcrumbs.push({
        link: '#sec/individual/' + objectId + '/dashboard',
        text: identifiers.dbaName
      });
      if (objectLicenseId) {
        breadcrumbs.push({
          link: '#sec/individual/' + objectId + '/license/' + objectLicenseId + '/dashboard',
          text: identifiers.licenseType
        });
      }
    }

    breadcrumbs = breadcrumbs.concat([
      {
        link: '#sec/investigation-inquiry/' + invInqId + '/dashboard',
        text: category + ' (' + caseNumber + ')'
      },
      {
        text: 'Initiate Correspondence'
      }
    ]);

    this.showChildView(
      'headerBar',
      new SecInvInqHeaderBarView({
        model: new Backbone.Model(identifiers),
        isLicenseInvInq: !!objectLicenseId
      })
    );

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: breadcrumbs
      })
    );

    this.correspondenceForm = new SecInitiateCorrespondenceFormView({
      model: this.model
    });

    this.showChildView('correspondenceFormContainer', this.correspondenceForm);
  },

  onClickSubmit: function(event) {
    event.preventDefault();
    var self = this;
    var organizationId = modelGet(this.model, 'identifiers.objectId');
    this.correspondenceForm.validate().done(function() {
      var l = Ladda.create(self.ui.submit[0]);
      l.start();
      var formData = self.correspondenceForm.getFormData();
      formData.organizationId = organizationId;

      SubmitNewCorrespondence(formData)
        .done(function() {
          Radio.channel('navigate').trigger('show:int:sc:investigation-inquiry-dashboard', self.model.get('invInqId'));
        })
        .fail(function() {
          l.stop();
        });
    });
  }
});
