/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');

var GridView = require('psa-marionette/views/GridView');
var PageableCollection = require('psa-backbone/models/PageableCollection');

var ButtonLinkCell = require('common/backgrid/ButtonLinkCell');
var MomentCell = require('common/backgrid/MomentCell');
var LinkCell = require('common/backgrid/LinkCell');

var GridSpinnerView = require('common/views/GridSpinnerView');

var SecInternalSidebarNavView = require('./SecInternalSidebarNavView');

var GetOpenExamsByExaminer = require('../services/GetOpenExamsByExaminer');
var GetOpenInvInqsByExaminer = require('../services/GetOpenInvInqsByExaminer');
var GetNextStepsByAssignee = require('../services/GetNextStepsByAssignee');

require('../styles/SecExaminerDashboardView.css');
var tmplSecExaminerDashboardView = require('../templates/SecExaminerDashboardView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplSecExaminerDashboardView,

  className: 'sec-examiner-dashboard-view container-fluid',

  ui: {
    sideBar: '.side-bar-container',
    myExams: '.my-exams',
    myInvInqs: '.my-inv-inqs',
    myNextSteps: '.my-next-steps'
  },

  regions: {
    sideBar: '@ui.sideBar',
    myExams: '@ui.myExams',
    myInvInqs: '@ui.myInvInqs',
    myNextSteps: '@ui.myNextSteps'
  },

  onBeforeShow: function() {
    var self = this;
    this.showChildView('sideBar', new SecInternalSidebarNavView());

    var myExamsColumns = [
      {
        name: 'applicantLegalName',
        label: 'Applicant/Legal Name',
        cell: LinkCell.extend({
          emptyText: '--',
          href: function(model) {
            var organizationId = model.get('organizationId');
            var url = organizationId ? '#sec/organization/' + organizationId + '/dashboard' : '';
            return url;
          }
        }),
        headerCell: 'custom',
        width: 15,
        editable: false
      },
      {
        name: 'crdNumber',
        label: 'CRD Number',
        cell: 'string',
        headerCell: 'custom',
        width: 15,
        editable: false
      },
      {
        name: 'licenseType',
        label: 'License Type',
        cell: 'string',
        headerCell: 'custom',
        width: 15,
        editable: false
      },
      {
        name: 'examNumber',
        label: 'Exam Number',
        cell: 'string',
        headerCell: 'custom',
        width: 30,
        editable: false
      },
      {
        name: '',
        label: '',
        cell: ButtonLinkCell.extend({
          name: 'Open',
          buttonClass: 'btn-primary btn-sm',
          href: function(model) {
            return '#sec/exam/' + model.get('id') + '/dashboard';
          }
        }),
        headerCell: 'custom',
        width: 10,
        editable: false,
        sortable: false
      }
    ];

    this.myExams = new GridSpinnerView({
      columns: myExamsColumns,
      spinner: {
        scale: 0.75,
        length: 10
      },
      pageable: true
    });
    this.showChildView('myExams', this.myExams);

    GetOpenExamsByExaminer()
      .done(function(exams) {
        self.myExams = new GridView({
          columns: myExamsColumns,
          collection: new PageableCollection(exams, { pageSize: 5 }),
          emptyText: 'No Exams.'
        });
        self.showChildView('myExams', self.myExams);
      })
      .fail(function() {
        self.myExams = new GridView({
          columns: myExamsColumns,
          collection: new PageableCollection([], { pageSize: 5 }),
          emptyText: 'No Exams.'
        });
        self.showChildView('myExams', self.myExams);
      });

    var myInvInqsColumns = [
      {
        name: 'applicantLegalName',
        label: 'Name',
        cell: LinkCell.extend({
          emptyText: '--',
          href: function(model) {
            var url = '';
            var objectId = model.get('objectId');
            var objectTypeId = model.get('objectTypeId');

            if (objectTypeId === 'ORGANIZATION') {
              url = '#sec/organization/' + objectId + '/dashboard';
            } else if (objectTypeId === 'INDIVIDUAL') {
              url = '#sec/individual/' + objectId + '/dashboard';
            }

            return url;
          }
        }),
        headerCell: 'custom',
        width: 15,
        editable: false
      },
      {
        name: 'crdNumber',
        label: 'CRD Number',
        cell: 'string',
        headerCell: 'custom',
        width: 15,
        editable: false
      },
      {
        name: 'licenseType',
        label: 'License Type',
        cell: 'string',
        headerCell: 'custom',
        width: 15,
        editable: false
      },
      {
        name: 'caseNumber',
        label: 'Case Number',
        cell: 'string',
        headerCell: 'custom',
        width: 15,
        editable: false
      },
      {
        name: 'category',
        label: 'Category',
        cell: 'string',
        headerCell: 'custom',
        width: 15,
        editable: false
      },
      {
        name: '',
        label: '',
        cell: ButtonLinkCell.extend({
          name: 'Open',
          buttonClass: 'btn-primary btn-sm',
          href: function(model) {
            return '#sec/investigation-inquiry/' + model.get('id') + '/dashboard';
          }
        }),
        headerCell: 'custom',
        width: 10,
        editable: false,
        sortable: false
      }
    ];

    this.myInvInqs = new GridSpinnerView({
      columns: myInvInqsColumns,
      spinner: {
        scale: 0.75,
        length: 10
      },
      pageable: true
    });
    this.showChildView('myInvInqs', this.myInvInqs);

    GetOpenInvInqsByExaminer()
      .done(function(invInqs) {
        self.myInvInqs = new GridView({
          columns: myInvInqsColumns,
          collection: new PageableCollection(invInqs, { pageSize: 5 }),
          emptyText: 'No Investigations/Inquiries.'
        });
        self.showChildView('myInvInqs', self.myInvInqs);
      })
      .fail(function() {
        self.myInvInqs = new GridView({
          columns: myInvInqsColumns,
          collection: new PageableCollection([], { pageSize: 5 }),
          emptyText: 'No Investigations/Inquiries.'
        });
        self.showChildView('myInvInqs', self.myInvInqs);
      });

    var myNextStepsColumns = [
      {
        name: 'nextStepDescription',
        label: 'Task Name',
        cell: 'string',
        headerCell: 'custom',
        width: 15,
        editable: false
      },
      {
        name: 'nextStepType',
        label: 'Inq / Inv / E',
        cell: 'string',
        headerCell: 'custom',
        width: 15,
        editable: false
      },
      {
        name: 'modifiedDate',
        label: 'Last Modified',
        cell: MomentCell.MomentCell,
        formatter: MomentCell.MomentDateFormatter.extend({
          emptyValue: '--'
        }),
        sortValue: MomentCell.MomentSortValue,
        headerCell: 'custom',
        width: 15,
        editable: false
      },
      {
        name: 'nextStepProjectedDate',
        label: 'Target Date',
        cell: MomentCell.MomentCell,
        formatter: MomentCell.MomentDateFormatter.extend({
          emptyValue: '--'
        }),
        sortValue: MomentCell.MomentSortValue,
        headerCell: 'custom',
        width: 30,
        editable: false
      },
      {
        name: '',
        label: '',
        cell: ButtonLinkCell.extend({
          name: 'Open',
          buttonClass: 'btn-primary btn-sm',
          href: function(model) {
            var nextStepType = model.get('nextStepType'),
              objectId = model.get('id');
            if (nextStepType === 'EXAM') {
              return '#sec/exam/' + objectId + '/dashboard';
            } else {
              return '#sec/investigation-inquiry/' + objectId + '/dashboard';
            }
          }
        }),
        headerCell: 'custom',
        width: 10,
        editable: false,
        sortable: false
      }
    ];

    this.myNextSteps = new GridSpinnerView({
      columns: myNextStepsColumns,
      spinner: {
        scale: 0.75,
        length: 10
      },
      pageable: true
    });
    this.showChildView('myNextSteps', this.myNextSteps);

    GetNextStepsByAssignee()
      .done(function(nextSteps) {
        self.myNextSteps = new GridView({
          columns: myNextStepsColumns,
          collection: new PageableCollection(nextSteps, { pageSize: 5 }),
          emptyText: 'No Next Steps.'
        });
        self.showChildView('myNextSteps', self.myNextSteps);
      })
      .fail(function() {
        self.myNextSteps = new GridView({
          columns: myNextStepsColumns,
          collection: new PageableCollection([], { pageSize: 5 }),
          emptyText: 'No Next Steps.'
        });
        self.showChildView('myNextSteps', self.myNextSteps);
      });
  }
});
