/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');

var tmplNonDepIndividualAuditLogSectionView = require('../templates/NonDepIndividualAuditLogSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepIndividualAuditLogSectionView,

  className: 'non-dep-audit-log-section-view',

  ui: {
    auditLogContainer: '.audit-log-container'
  },

  regions: {
    auditLogContainer: '@ui.auditLogContainer'
  },

  onBeforeShow: function() {
    var auditLogView = Radio.channel('nd').request('build:audit-log-view', {
      objectId: this.model.get('individualId'),
      objectTypeId: 'INDIVIDUAL'
    });

    this.showChildView('auditLogContainer', auditLogView);
  }
});
