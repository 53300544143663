/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(licenseId) {
  var defer = $.Deferred();

  $.soap({
    body: {
      GetComplianceByLicense: {
        $xmlns: 'http://dobs.pa.gov/nd/compliance/bpm/1.0',
        LicenseID: licenseId
      }
    },
    blueprintArrays: ['GetComplianceByLicenseResponse.ComplianceCases.ND_Compliance'],
    blueprint: {
      complianceCases: [
        '$..ND_Compliance',
        {
          id: '$.ID',
          administrator: '$.Administrator',
          complianceStatusId: '$.ComplianceStatusID',
          complianceStatus: '$.ComplianceStatus',
          licenseId: '$.LicenseID',
          entityId: '$.EntityID',
          caseNumber: '$.CaseNumber',
          submissionDate: '$.SubmissionDate'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      _.each(response.complianceCases, function(complianceCase) {
        complianceCase.administrator = complianceCase.administrator || '--';
        complianceCase.caseNumber = complianceCase.caseNumber || '--';
      });

      defer.resolve(response.complianceCases);
    })
    .fail(function(err) {
      defer.reject(err);
    });

  return defer.promise();
};
