/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

module.exports = function(data) {
  return $.soap({
    body: {
      SubmitMTApplicationReviewTask: {
        $xmlns: 'http://dobs.pa.gov/nd/task/bpm/1.0',
        TaskID: data.taskId,
        ApplicationID: data.applicationId,
        ReviewDecisionID: data.reviewDecisionId,
        DecisionReason: data.decisionReason,
        InvestigationDocuments: (function() {
          if (!data.investigationDocuments || !data.investigationDocuments.length) {
            return;
          }
          return {
            InvestigationDocument: _.map(data.investigationDocuments, function(investigationDocument) {
              return {
                ApplicationInvestigationID: investigationDocument.applicationInvestigationId,
                IndividualID: investigationDocument.individualId,
                DocumentID: investigationDocument.documentId,
                DocumentTypeID: investigationDocument.documentTypeId
              };
            })
          };
        })()
      }
    }
  });
};
