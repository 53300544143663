/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');

var Ladda = require('ladda');

var SubmitRevokeEntityJoinCode = require('../services/SubmitRevokeEntityJoinCode');
var tmplRevokeEntityJoinCodeModalView = require('../templates/DepRevokeEntityJoinCodeModalView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplRevokeEntityJoinCodeModalView,

  className: 'dep-revoke-entity-join-code-modal-view',

  ui: {
    submitButton: '.btn-submit',
    joinCodeForm: '.join-code-form'
  },

  events: {
    'click @ui.submitButton': 'onClickSubmit'
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;
    this.ui.joinCodeForm
      .parsley()
      .whenValidate()
      .done(function() {
        var laddaContext = Ladda.create(self.ui.submitButton.get(0));
        laddaContext.start();
        SubmitRevokeEntityJoinCode({
          id: self.model.get('id'),
          entityId: self.model.get('entityId')
        })
        .done(function() {
          laddaContext.stop();
          self.triggerMethod('modal:close');
        });
      });
  }
});
