/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Radio = require('backbone.radio');
var Ladda = require('ladda');

var UIUtil = require('psa-core/util/ui');

var moment = require('moment');

var Session = require('psa-core/Session');

var HeaderBarView = require('common/views/HeaderBarView');
var HeaderBarAddressCellView = require('common/views/HeaderBarView/HeaderBarAddressCellView');
var BreadcrumbView = require('common/views/BreadcrumbView');
var ConfirmationModalView = require('common/views/ConfirmationModalView');
var ContentServerEditorLinkView = require('common/views/ContentServerEditorLinkView');
var toggleForm = require('common/util/toggleForm');

var SubmitFinalizeCloseOutTask = require('../../services/SubmitFinalizeCloseOutTask');

var tmplDepFinalizeCloseOutTaskView = require('./DepFinalizeCloseOutTaskView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepFinalizeCloseOutTaskView,

  className: 'dep-finalize-close-out-task-view container-fluid',

  ui: {
    headerBar: '.header-bar',
    breadcrumb: '.breadcrumb-container',
    finalizeCloseOutForm: '.finalize-close-out-form',
    checklistLinkContainer: '.checklist-link-container',
    analysisMemoLinkContainer: '.analysis-memo-link-container',
    decisionLetterLinkContainer: '.decision-letter-link-container',
    checklistLinkSection: '.checklist-link-section',
    analysisMemoLinkSection: '.analysis-memo-link-section',
    decisionLetterLinkSection: '.decision-letter-link-section',
    documentsSection: '.documents-section',
    isFollowUpRequiredFormGroup: '.is-follow-up-required-form-group',
    isFollowUpRequired: '.is-follow-up-required',
    filingWorkflowDecision: '.filing-workflow-decision-form-group',
    filingWorkflowDecisionId: '.filing-workflow-decision-id',
    isNotifyEntityCheckbox: 'input[name="isNotifyEntity"]',
    commentsRequiredFormGroup: '.comments-required-form-group',
    commentsOptionalFormGroup: '.comments-optional-form-group',
    comments: '.comments',
    notifyEnityRequiredFormGroup: '.notify-enity-required-form-group',
    submit: '.submit'
  },

  regions: {
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb',
    checklistLinkContainer: '@ui.checklistLinkContainer',
    analysisMemoLinkContainer: '@ui.analysisMemoLinkContainer',
    decisionLetterLinkContainer: '@ui.decisionLetterLinkContainer'
  },

  events: {
    'change @ui.filingWorkflowDecisionId': 'onChangeFilingWorkFlowDecisionId',
    'click @ui.submit': 'onClickSubmit'
  },

  behaviors: {
    TaskBehavior: {}
  },

  onBeforeShow: function() {
    var filingDetails = this.model.get('filingDetails') || {};
    var pointOfContact = filingDetails.pointOfContact || {};
    var ppobAddress = filingDetails.ppobAddress || {};
    var filingType = filingDetails.filingType;

    var headerBarData = {
      filingType: filingDetails.filingType,
      entityName: filingDetails.entityName,
      entityType: filingDetails.entityType,
      address: {
        addressLine1: ppobAddress.addressLine1,
        addressLine2: ppobAddress.addressLine2,
        city: ppobAddress.city,
        state: ppobAddress.state,
        postalCode: ppobAddress.postalCode,
        country: ppobAddress.country,
        county: ppobAddress.county
      },
      pointOfContact: pointOfContact.name || '--'
    };

    var headerBarColumns = [
      {
        name: 'filingType',
        label: 'Filing Type',
        minWidth: '160px',
        maxWidth: '200px'
      },
      {
        name: 'entityName',
        label: 'Entity Name',
        minWidth: '160px',
        maxWidth: '300px'
      },
      {
        name: 'entityType',
        label: 'Entity Type',
        minWidth: '160px',
        maxWidth: '300px'
      },
      {
        name: 'pointOfContact',
        label: 'Point of Contact',
        width: '160px',
        overflow: 'truncate'
      },
      {
        name: 'address',
        label: 'Principal Place of Business Address',
        minWidth: '160px',
        headerCell: HeaderBarAddressCellView
      }
    ];
    var reviewComments = filingDetails.reviewComments;
    if (reviewComments) {
      reviewComments = '\n\n' + reviewComments;
    } else {
      reviewComments = '';
    }

    this.ui.comments.val(reviewComments);

    this.showChildView(
      'headerBar',
      new HeaderBarView({
        columns: new Backbone.Collection(headerBarColumns),
        model: new Backbone.Model(headerBarData)
      })
    );

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#dep/dashboard',
            text: 'Home'
          },
          {
            text: 'Finalize Close-Out for ' + filingType
          }
        ]
      })
    );

    this.showChildView(
      'checklistLinkContainer',
      new ContentServerEditorLinkView({
        model: new Backbone.Model({
          linkText: 'Checklist',
          contentServerId: filingDetails.checklistCSId
        })
      })
    );

    this.showChildView(
      'analysisMemoLinkContainer',
      new ContentServerEditorLinkView({
        model: new Backbone.Model({
          linkText: 'Analysis Memo',
          contentServerId: filingDetails.analysisMemoCSId
        })
      })
    );

    this.showChildView(
      'decisionLetterLinkContainer',
      new ContentServerEditorLinkView({
        model: new Backbone.Model({
          linkText: 'Decision Letter',
          contentServerId: filingDetails.decisionLetterCSId
        })
      })
    );

    var showChecklist = filingDetails.checklistCSId;
    var showAnalysisMemo = filingDetails.analysisMemoCSId;
    var showDecisionLetter = filingDetails.decisionLetterCSId;

    if (!showChecklist) {
      this.ui.checklistLinkSection.hide();
    }
    if (!showAnalysisMemo) {
      this.ui.analysisMemoLinkSection.hide();
    }
    if (!showDecisionLetter) {
      this.ui.decisionLetterLinkSection.hide();
    }
    if (!showChecklist && !showAnalysisMemo && !showDecisionLetter) {
      this.ui.documentsSection.hide();
    }

    var isApproved = filingDetails.reviewRecommendationId === '1';
    toggleForm(this.ui.isFollowUpRequired, isApproved);

    this.onChangeFilingWorkFlowDecisionId();
  },

  onChangeFilingWorkFlowDecisionId: function() {
    var filingWorkflowDecision = this.ui.filingWorkflowDecisionId.val();
    this.toggleReviewForm(filingWorkflowDecision);
  },

  toggleReviewForm: function(filingWorkflowDecisionId) {
    var defaultdecision = filingWorkflowDecisionId === '';
    var submission = filingWorkflowDecisionId === '1';

    // When form is toggled, an input field for comments
    // with correct requirements is shown.
    // Store and repopulate value to prevent dispruption to user.
    var reviewComments = this.ui.comments.val();
    var commentsNotRequired = defaultdecision || submission;
    if (commentsNotRequired) {
      this.ui.notifyEnityRequiredFormGroup.show();
      this.ui.notifyEnityRequiredFormGroup.toggleClass('required', true);
      this.ui.isNotifyEntityCheckbox.attr('data-parsley-required', true);
      this.ui.isFollowUpRequired.toggleClass('required', true);
      this.ui.isFollowUpRequired.attr('data-parsley-required', true);
      this.ui.isFollowUpRequiredFormGroup.toggleClass('required', true);
      this.ui.isNotifyEntityCheckbox.filter('[value="0"]').prop('checked', false);
      this.ui.isNotifyEntityCheckbox.filter('[value="1"]').prop('checked', false);
      this.ui.commentsRequiredFormGroup.toggleClass('required', false);
    } else {
      this.ui.notifyEnityRequiredFormGroup.toggleClass('required', false);
      this.ui.isNotifyEntityCheckbox.attr('data-parsley-required', false);
      this.ui.notifyEnityRequiredFormGroup.hide();
      this.ui.isNotifyEntityCheckbox.filter('[value="0"]').prop('checked', true);
      this.ui.isFollowUpRequired.toggleClass('required', false);
      this.ui.isFollowUpRequired.attr('data-parsley-required', false);
      this.ui.isFollowUpRequiredFormGroup.toggleClass('required', false);
      this.ui.commentsRequiredFormGroup.toggleClass('required', true);
    }
    this.ui.comments.val(reviewComments);
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;
    this.validate().done(function() {
      var message = 'Confirm decision letter has been updated.';
      var isNotifyEntity = self.ui.isNotifyEntityCheckbox.filter(':checked').val() === '1';

      if (isNotifyEntity) {
        UIUtil.showModal({
          title: 'Confirm Decision Letter Updated',
          view: new ConfirmationModalView({
            confirmText: 'Confirm & Submit',
            model: new Backbone.Model({
              message: message,
              onConfirm: function() {
                return SubmitFinalizeCloseOutTask(self.getFormData());
              },
              onConfirmDone: function() {
                Radio.channel('navigate').trigger('show:int:dp:dashboard');
              }
            })
          })
        });
      } else {
        var l = Ladda.create(self.ui.submit[0]);
        l.start();
        SubmitFinalizeCloseOutTask(self.getFormData())
          .done(function() {
            Radio.channel('navigate').trigger('show:int:dp:dashboard');
          })
          .fail(function() {
            l.stop();
          });
      }
    });
  },

  validate: function() {
    return this.ui.finalizeCloseOutForm.parsley().whenValidate();
  },

  getFormData: function() {
    var timestamp = moment().format('MM/DD/YYYY h:mm A');
    var userName = Session.user.description;
    var formData = Syphon.serialize(this);

    _.extend(formData, {
      taskId: this.model.get('taskId'),
      filingId: this.model.get('filingId')
    });

    formData.reviewComments = '[' + userName + ' - ' + timestamp + '] \n' + formData.reviewcomments;
    var filingWorkflowDecision = this.ui.filingWorkflowDecisionId.val();
    if (filingWorkflowDecision === '2') {
      formData.isReturnForReview = '1';
    } else if (filingWorkflowDecision === '1') {
      formData.isReturnForReview = '0';
    }

    return formData;
  }
});
