/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var moment = require('moment');

var formatAsDollars = require('common/util/formatAsDollars');
var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(entityId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetEntity: {
        $xmlns: 'http://dobs.pa.gov/dp/entity/bpm/1.0',
        EntityID: entityId
      }
    },
    blueprintArrays: [
      'GetEntityResponse.Entity.DBAs.DP_EntityDBA',
      'GetEntityResponse.Entity.EnforcementActions.DP_EntityEnforcementAction',
      'GetEntityResponse.Entity.MainContact.EmailAddresses.DP_EntityContactEmailAddress',
      'GetEntityResponse.Entity.Tracking.DP_EntityTracking'
    ],
    blueprint: {
      entity: {
        entityId: '$..EntityID',
        entityName: '$..EntityName',
        CSFolderId: '$..CSFolderID',
        callReportsCSFolderId: '$..CallReportsCSFolderID',
        entityTypeId: '$..EntityTypeID',
        entityCategoryId: '$..CategoryID',
        entityType: '$..EntityType',
        statusId: '$..StatusID',
        status: '$..Status',
        statusDate: '$..StatusDate',
        charterTypeId: '$..CharterTypeID',
        charteredOnDate: '$..CharteredOnDate',
        entityCharterType: '$..EntityCharterType',
        ownershipStructureId: '$..OwnershipStructureID',
        ownershipStructure: '$..OwnershipStructure',
        FOMTypeId: '$..FOMTypeID',
        FOMType: '$..FOMType',
        corporateStructureTypeId: '$..CorporateStructureTypeID',
        corporateStructureType: '$..CorporateStructureType',
        rssdidNumber: '$..RSSDIDNumber',
        certificationNumber: '$..CertificationNumber',
        certificateOfAuthorizationToEngageInFiduciaryActivity:
          '$..CertificateOfAuthorizationToEngageInFiduciaryActivity',
        letterOfReciprocity: '$..LetterOfReciprocity',
        certifiedStatementOfMinimumCapital: '$..CertifiedStatementOfMinimumCapital',
        proofOfMinimumCapital: '$..ProofOfMinimumCapital',
        certificateOfAuthorityToDoBusinessInPennsylvania: '$..CertificateOfAuthorityToDoBusinessInPennsylvania',
        mainPhoneNumber: '$..MainPhoneNumber',
        hasLegalHold: '$..HasLegalHold',
        idCharterNumber: '$..IDCharterNumber',
        isComplex: '$..IsComplex',
        isLowIncome: '$..IsLowIncome',
        isMBL: '$..IsMemberBusinessLoans',
        isFHLBMember: '$..IsFHLBMember',
        isCollectiveInvestmentFund: '$..IsCollectiveInvestmentFund',
        totalAssetsAsOfDate: '$..TotalAssetsAsOfDate',
        totalAssetsAmount: '$..TotalAssetsAmount',
        totalAssetsUnderManagementAsOfDate: '$..TotalAssetsUnderManagementAsOfDate',
        totalAssetsUnderManagementAmount: '$..TotalAssetsUnderManagementAmount',
        totalTrustCompanyAssetsAsOfDate: '$..TotalTrustCompanyAssetsAsOfDate',
        totalTrustCompanyAssetsAmount: '$..TotalTrustCompanyAssetsAmount',
        totalCapitalAsOfDate: '$..TotalCapitalAsOfDate',
        totalCapitalAmount: '$..TotalCapitalAmount',
        federalRegulatorId: '$..FederalRegulatorID',
        federalRegulator: '$..FederalRegulator',
        regulatorDivisionId: '$..RegulatorDivisionID',
        regulatorDivision: '$..RegulatorDivision',
        fieldSupervisor: '$..FieldSupervisor',
        fieldSupervisorId: '$..FieldSupervisorID',
        fieldSupervisorName: '$..EntityFieldSupervisorName',
        caseManagerUserId: '$..CaseManager',
        caseManagerFirstName: '$..CaseManagerFirstName',
        caseManagerLastName: '$..CaseManagerLastName',
        stateRegulator: '$..StateRegulator',
        holdingCompanyEntityId: '$..HoldingCompanyEntityID',
        holdingCompanyEntityName: '$..HoldingCompanyEntityName',
        subsidaryBankEntityId: '$..SubsidaryBankEntityID',
        subsidaryBankEntityName: '$..SubsidaryBankEntityName',
        redFlagsCount: '$..RedFlagsCount',
        mrbCount: '$..MRBCount',
        openMRBACount: '$..OpenMRBACount',
        nextExamConductedById: '$..NextExamConductedByID',
        nextExamConductedBy: '$..NextExamConductedBy',
        nextExamYear: '$..NextExamYear',
        nextExamTypeId: '$..NextExamTypeID',
        nextExamType: '$..NextExamType',
        nextExamStartDate: '$..NextExamStartDate',
        ppobPhone: '$..PPOBPhone',
        ppobFax: '$..PPOBFax',
        ppob: {
          id: '$..PPOBAddressID',
          addressLine1: '$..PPOBAddressLine1',
          addressLine2: '$..PPOBAddressLine2',
          addressLine3: '$..PPOBAddressLine3',
          city: '$..PPOBCity',
          country: '$..PPOBCountry',
          county: '$..PPOBCounty',
          postalCode: '$..PPOBPostalCode',
          state: '$..PPOBState'
        },
        isMailingSame: '$..IsMailingSame',
        mailingAddress: {
          id: '$..MailingAddressID',
          addressLine1: '$..MailingAddressLine1',
          addressLine2: '$..MailingAddressLine2',
          addressLine3: '$..MailingAddressLine3',
          city: '$..MailingAddressCity',
          country: '$..MailingAddressCountry',
          county: '$..MailingAddressCounty',
          postalCode: '$..MailingAddressPostalCode',
          state: '$..MailingAddressState'
        },
        isBillingSame: '$..IsBillingSame',
        billingAddress: {
          id: '$..BillingAddressID',
          addressLine1: '$..BillingAddressLine1',
          addressLine2: '$..BillingAddressLine2',
          addressLine3: '$..BillingAddressLine3',
          city: '$..BillingAddressCity',
          country: '$..BillingAddressCountry',
          county: '$..BillingAddressCounty',
          postalCode: '$..BillingAddressPostalCode',
          state: '$..BillingAddressState'
        },
        latestCallReport: {
          totalTrustCompanyAssets: '$..DP_EntityCallReport.TotalTrustCompanyAssets',
          totalAssetsUnderManagement: '$..DP_EntityCallReport.TotalAssetsUnderManagement',
          totalCapital: '$..DP_EntityCallReport.TotalCapital',
          reportDate: '$..DP_EntityCallReport.ReportDate'
        },
        website: '$..Website',
        frsrssd: '$..FRSRSSD',
        occCharter: '$..OCCCharter',
        fdicUniNum: '$..FDICUNINUM',
        FFCharteringState: '$..FFCharteringState',
        FFPrimaryRegulator: '$..FFPrimaryRegulator',
        FFCapitalAmount: '$..FFCapitalAmount',
        FFCapitalAsOfDate: '$..FFCapitalAsOfDate',
        FFCapitalComments: '$..FFCapitalComments',
        FFDeptActionId: '$..FFDeptActionID',
        FFDeptAction: '$..FFDeptAction',
        FFDeptActionDate: '$..FFDeptActionDate',
        FFTerminationReason: '$..FFTerminationReason',
        FFTerminationDate: '$..FFTerminationDate',
        createdDate: '$..CreatedDate',
        createdBy: '$..CreatedBy',
        modifiedDate: '$..ModifiedDate',
        modifiedBy: '$..ModifiedBy',
        entityEmail: '$..EntityEmail',
        supervisoryCommittee: '$..SupervisoryCommittee',
        mainContactFirstName: '$..FirstName',
        mainContactMiddleInitial: '$..MiddleInitial',
        mainContactLastName: '$..LastName',
        mainContactTitle: '$..Title',
        dbas: [
          '$..DP_EntityDBA',
          {
            id: '$..ID',
            entityId: '$..EntityID',
            dba: '$..DBA',
            createdDate: '$..CreatedDate',
            createdBy: '$..CreatedBy'
          }
        ],
        tracking: [
          '$..DP_EntityTracking',
          {
            id: '$..ID',
            trackingTypeID: '$..TrackingTypeID',
            isDirect: '$..IsDirect',
            isIndirect: '$..IsIndirect',
            isServiceMRBS: '$..IsServiceMRBS',
            ssMRBInvestments: '$..IsMRBInvestments'
          }
        ],
        enforcements: [
          '$..DP_EntityEnforcementAction',
          {
            id: '$..ID',
            entityId: '$..EntityID',
            actionId: '$..ActionID',
            areaId: '$..AreaID',
            typeId: '$..TypeID',
            agencyId: '$..AgencyID',
            examAsOfDate: '$..ExamAsOfDate',
            effectiveDate: '$..EffectiveDate',
            statusId: '$..StatusID',
            isDeleted: '$..IsDeleted',
            createdDate: '$..CreatedDate',
            createdBy: '$..CreatedBy',
            modifiedDate: '$..ModifiedDate',
            modifiedBy: '$..ModifiedBy',
            action: '$..Action',
            area: '$..Area',
            agency: '$..Agency',
            status: '$..Status',
            type: '$..Type',
            comments: '$..Comments'
          }
        ],
        examInProgressCount: '$..ExamInProgress'
      }
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      var decimals = 0;

      var entity = response.entity;
      if (entity.entityTypeId === '3' || entity.entityTypeId === '12' || entity.entityTypeId === '23') {
        entity.isTSP = true;
      }

      entity.hasLegalHold = entity.hasLegalHold === '1';

      entity.certificateOfAuthorizationToEngageInFiduciaryActivity =
        entity.certificateOfAuthorizationToEngageInFiduciaryActivity === '1';

      entity.letterOfReciprocity = entity.letterOfReciprocity === '1';

      entity.certifiedStatementOfMinimumCapital = entity.certifiedStatementOfMinimumCapital === '1';

      entity.proofOfMinimumCapital = entity.proofOfMinimumCapital === '1';

      entity.certificateOfAuthorityToDoBusinessInPennsylvania =
        entity.certificateOfAuthorityToDoBusinessInPennsylvania === '1';

      if (entity.charteredOnDate) {
        entity.charteredOnDate = moment(entity.charteredOnDate, 'YYYY-MM-DD').format('MM/DD/YYYY');
      }

      if (entity.FFCapitalAsOfDate) {
        entity.FFCapitalAsOfDate = moment(entity.FFCapitalAsOfDate, 'YYYY-MM-DD').format('MM/DD/YYYY');
      }

      if (entity.FFDeptActionDate) {
        entity.FFDeptActionDate = moment(entity.FFDeptActionDate, 'YYYY-MM-DD').format('MM/DD/YYYY');
      }

      if (entity.FFTerminationDate) {
        entity.FFTerminationDate = moment(entity.FFTerminationDate, 'YYYY-MM-DD').format('MM/DD/YYYY');
      }

      if (entity.nextExamStartDate) {
        entity.nextExamStartDate = moment(entity.nextExamStartDate, 'YYYY-MM-DD').format('MM/DD/YYYY');
      }

      if (entity.latestCallReport.reportDate) {
        entity.latestCallReport.reportDate = moment(entity.latestCallReport.reportDate, 'YYYY-MM-DD').format(
          'MM/DD/YYYY'
        );
      }

      // Status-Date format
      if (entity.statusDate) {
        entity.statusDate = moment(entity.statusDate, 'YYYY-MM-DD').format('MM/DD/YYYY');
      }

      // As-of-Date format
      if (entity.totalAssetsAsOfDate) {
        entity.totalAssetsAsOfDate = moment(entity.totalAssetsAsOfDate, 'YYYY-MM-DD').format('MM/DD/YYYY');
      }
      if (entity.totalAssetsUnderManagementAsOfDate) {
        entity.totalAssetsUnderManagementAsOfDate = moment(
          entity.totalAssetsUnderManagementAsOfDate,
          'YYYY-MM-DD'
        ).format('MM/DD/YYYY');
      }
      if (entity.totalCapitalAsOfDate) {
        entity.totalCapitalAsOfDate = moment(entity.totalCapitalAsOfDate, 'YYYY-MM-DD').format('MM/DD/YYYY');
      }

      // Total Assets Amount format
      if (entity.totalAssetsAmount) {
        entity.totalAssetsAmountDisplay = formatAsDollars(parseFloat(entity.totalAssetsAmount), decimals);
        entity.totalAssetsAmount = parseInt(entity.totalAssetsAmount);
      }

      // Total Trust Company Assets Amount
      if (entity.latestCallReport.totalTrustCompanyAssets) {
        entity.latestCallReport.totalTrustCompanyAssetsDisplay = formatAsDollars(
          parseFloat(entity.latestCallReport.totalTrustCompanyAssets),
          decimals
        );
        entity.totalTrustCompanyAssets = parseInt(entity.totalTrustCompanyAssets);
      }

      // Total Assets Under Management Amount
      if (entity.totalAssetsUnderManagementAmount) {
        entity.totalAssetsUnderManagementAmountDisplay = formatAsDollars(
          parseFloat(entity.totalAssetsUnderManagementAmount),
          decimals
        );
        entity.totalAssetsUnderManagementAmount = parseInt(entity.totalAssetsUnderManagementAmount);
      }

      // Total Capital Amount
      if (entity.totalCapitalAmount) {
        entity.totalCapitalAmountDisplay = formatAsDollars(parseFloat(entity.totalCapitalAmount), decimals);
        entity.totalCapitalAmount = parseInt(entity.totalCapitalAmount);
      }

      if (entity.latestCallReport.totalAssetsUnderManagementAsOfDate) {
        entity.latestCallReport.totalAssetsUnderManagementAsOfDate = moment(
          entity.latestCallReport.totalAssetsUnderManagementAsOfDate,
          'YYYY-MM-DD'
        ).format('MM/DD/YYYY');
      }

      if (entity.latestCallReport.totalTrustCompanyAssetsAsOfDate) {
        entity.latestCallReport.totalTrustCompanyAssetsAsOfDate = moment(
          entity.latestCallReport.totalTrustCompanyAssetsAsOfDate,
          'YYYY-MM-DD'
        ).format('MM/DD/YYYY');
      }

      if (entity.FFCapitalAmount) {
        entity.FFCapitalAmountDisplay = formatAsDollars(parseFloat(entity.FFCapitalAmount), decimals);
        entity.FFCapitalAmount = parseInt(entity.FFCapitalAmount);
      }

      if (entity.isComplex === '0') {
        entity.isComplexDisplay = 'No';
      } else if (entity.isComplex === '1') {
        entity.isComplexDisplay = 'Yes';
      }

      if (entity.isLowIncome === '0') {
        entity.isLowIncomeDisplay = 'No';
      } else if (entity.isLowIncome === '1') {
        entity.isLowIncomeDisplay = 'Yes';
      }

      if (entity.isMBL === '0') {
        entity.isMBLDisplay = 'No';
      } else if (entity.isMBL === '1') {
        entity.isMBLDisplay = 'Yes';
      }

      if (entity.isFHLBMember === '0') {
        entity.isFHLBMemberDisplay = 'No';
      } else if (entity.isFHLBMember === '1') {
        entity.isFHLBMemberDisplay = 'Yes';
      }

      if (entity.isCollectiveInvestmentFund === '0') {
        entity.isCollectiveInvestmentFundDisplay = 'No';
      } else if (entity.isCollectiveInvestmentFund === '1') {
        entity.isCollectiveInvestmentFundDisplay = 'Yes';
      }

      if (entity.supervisoryCommittee === '0') {
        entity.supervisoryCommitteeDisplay = 'No';
      } else if (entity.supervisoryCommittee === '1') {
        entity.supervisoryCommitteeDisplay = 'Yes';
      }

      entity.tracking = _.sortBy(entity.tracking, 'id');

      _.each(entity.tracking, function(tracking) {
        if (tracking.trackingTypeID === '12' || tracking.trackingTypeID === '21' || tracking.trackingTypeID === '2') {
          if (
            tracking.isDirect === '1' ||
            tracking.isIndirect === '1' ||
            tracking.isServiceMRBS === '1' ||
            tracking.ssMRBInvestments === '1'
          ) {
            entity.hasOpenMRBADisplay = 'Yes';
          } else {
            entity.hasOpenMRBADisplay = 'No';
          }
        }
      });

      entity.hasRedFlagsDisplay = entity.redFlagsCount ? 'Yes' : 'No';

      _.each(entity.enforcements, function(enforcement) {
        enforcement.type = enforcement.type || '--';

        if (enforcement.effectiveDate) {
          enforcement.effectiveDate = moment(enforcement.effectiveDate, 'YYYY-MM-DD');
        }
      });

      entity.ssEnforcements = _.filter(entity.enforcements, function(enforcement) {
        return _.contains(['1', '11'], enforcement.areaId);
      });

      //grabbing entity type trust enforcements, NOT filtering on the areas like the others
      entity.trustEnforcements = _.filter(entity.enforcements, function(enforcement) {
        return _.contains(['21', '22'], enforcement.areaId);
      });

      if (entity.enforcements.length > 0) {
        entity.tspEnforcementAction = 'Yes';
      } else {
        entity.tspEnforcementAction = 'No';
      }

      entity.complianceEnforcements = _.where(entity.enforcements, { areaId: '2' });

      entity.craEnforcements = _.where(entity.enforcements, { areaId: '3' });

      entity.itEnforcements = _.where(entity.enforcements, { areaId: '12' });

      if (entity.mainContactFirstName && entity.mainContactLastName) {
        entity.mainContactFullName =
          entity.mainContactFirstName +
          ' ' +
          (entity.mainContactMiddleInitial ? entity.mainContactMiddleInitial + ' ' : '') +
          entity.mainContactLastName;
      }
      deferred.resolve(entity);
    })
    .fail(function(err) {
      deferred.reject();
    });
  return deferred.promise();
};

