/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(userId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetEntityExternalUser: {
        $xmlns: 'http://dobs.pa.gov/nd/entity/bpm/1.0',
        ExternalUserID: userId
      }
    },
    blueprint: {
      firstName: '$..FirstName',
      lastName: '$..LastName',
      userName: '$..UserName',
      email: '$..Email',
      userAccessLevelId: '$..AccessLevelID',
      entity: {
        id: '$..EntityID',
        entityName: '$..EntityName',
        nmlsId: '$..NMLSID'
      },
      userAccessLevels: [
        '$..ND_LU_EntityExternalUserAccessLevel',
        {
          id: '$..ID',
          name: '$..Name',
          description: '$..Description'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      response.entity.isNMLSEntity = !!response.entity.nmlsId;

      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
