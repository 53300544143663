/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');

var userHasRole = require('common/util/userHasRole');

var AppCardCollectionView = require('../../views/AppCardCollectionView');

require('../styles/InternalApplicationSelectionView.css');
var tmplInternalApplicationSelectionView = require('../templates/InternalApplicationSelectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplInternalApplicationSelectionView,

  className: 'internal-application-selection-view container-fluid',

  ui: {
    appCardContainer: '.app-card-container'
  },

  regions: {
    appCardContainer: '@ui.appCardContainer'
  },

  onBeforeShow: function() {
    var isNonDepositoryUser = userHasRole('ND - User');
    var isDepositoryUser = userHasRole('DP - User');
    var isSecuritiesUser = userHasRole('SC - User');
    var isConsumerServicesUser = userHasRole('CS - User');
    var isCorpFinance = userHasRole('CF - User');
    var appCards = [];

    if (isNonDepositoryUser) {
      appCards.push({
        image: 'img/checkbook.jpg',
        name: 'Non-Depository',
        description:
          'Licensing, examination, and compliance management of licensees and registrants in Mortgage, Automobile ' +
          'Finance, Consumer Loans, Credit Services, Debt Management/Settlement, Check Cashers, Pawnbrokers, Money ' +
          'Transmitters, etc. industries.',
        link: '#nondep/dashboard'
      });
    }

    if (isDepositoryUser) {
      // https://pixabay.com/photos/atm-withdraw-cash-map-ec-card-1524870/
      appCards.push({
        image: 'img/atm.jpg',
        name: 'Depository',
        description: 'Banks, Credit Unions, and Trust Companies.',
        link: '#dep/dashboard'
      });
    }

    if (isSecuritiesUser) {
      // https://pixabay.com/photos/stock-exchange-trading-floor-738671/
      appCards.push({
        image: 'img/stock-exchange.jpg',
        name: 'Securities',
        description:
          'Licensing, management, and compliance enforcement of broker-dealers, agents, investment advisers and representatives.',
        link: '#sec/dashboard'
      });
    }

    if (isCorpFinance) {
      // https://pixabay.com/photos/stock-trading-monitor-business-1863880/
      appCards.push({
        image: 'img/stock-graph.jpg',
        name: 'Securities - Corporation Finance',
        description: 'Submit Security Offerings and Filing Fees.',
        link: '#corpfin/dashboard'
      });
    }

    if (isConsumerServicesUser) {
      // https://www.pexels.com/photo/action-america-architecture-avenue-378570/
      appCards.push({
        image: 'img/avenue.jpg',
        name: 'Consumer Services',
        description: 'Manage compliants submitted by the public about financial instituions.',
        link: '#conser/dashboard'
      });
    }

    this.showChildView(
      'appCardContainer',
      new AppCardCollectionView({
        collection: new Backbone.Collection(appCards)
      })
    );
  }
});
