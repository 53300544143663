/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var _ = require('underscore');

var UIUtil = require('psa-core/util/ui');

var modelGet = require('common/util/modelGet');

var SecOrganizationSearchModalView = require('../../common/views/SecOrganizationSearchModalView');

require('../styles/SecIndividualLicenseFormView.css');
var tmplSecIndividualLicenseFormView = require('../templates/SecIndividualLicenseFormView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplSecIndividualLicenseFormView,

  className: 'sec-individual-license-form-view',

  ui: {
    individualLicenseForm: '.individual-license-form',
    statusDate: '.status-date',
    organizationSearchSection: '.organization-search-section',
    organizationSearchButton: '.btn-organization-search',
    clearOrganizationSearch: '.clear-organization-search',
    selectedOrganizationSearchOrganizationName: '.selected-organization-search-organization-name',
    selectedOrganizationId: '.selected-organization-id',
    crdNumber: '.crd-number',
    dbaName: '.dba-name',
    licenseTypeDropdown: 'select[name="licenseTypeId"]',
    organizationSearchFormGroup: '.organization-searchform-group',
    submit: '.btn-submit'
  },

  events: {
    'click @ui.clearOrganizationSearch': 'onClearOrganizationSearch',
    'click @ui.organizationSearchButton': 'onClickOrganizationSearch',
    'change @ui.licenseTypeDropdown': 'onChangeLicenseType'
  },

  templateHelpers: function() {
    var filingExaminerUsers = modelGet(this.model, 'filingExaminerUsers', []);
    var filingExaminerUserId = modelGet(this.model, 'license.filingExaminerUserId');
    var filingExaminerUserName = modelGet(this.model, 'license.filingExaminerUserName');

    /*
     * Current filing examiner could no longer have examiner role, add current
     * filing examiner with internal user data if username does not exist in
     * examiners array
     */
    var currentFilingExaminerHasRole = _.find(filingExaminerUsers, function(cm) {
      return filingExaminerUserId === cm.userId;
    });

    if (filingExaminerUserId && !currentFilingExaminerHasRole) {
      var currentFilingExaminer = {
        userId: filingExaminerUserId,
        name: filingExaminerUserName
      };
      filingExaminerUsers.splice(
        _.sortedIndex(filingExaminerUsers, currentFilingExaminer, 'name'),
        0,
        currentFilingExaminer
      );
    }

    return {
      filingExaminerUsers: filingExaminerUsers
    };
  },

  onBeforeShow: function() {
    var organizationId = modelGet(this.model, 'license.organizationId');
    var applicantLegalName = modelGet(this.model, 'license.applicantLegalName');

    Syphon.deserialize(this, this.model.get('license'));
    this.ui.statusDate.datepicker();
    this.ui.selectedOrganizationSearchOrganizationName.val(applicantLegalName);
    this.ui.selectedOrganizationId.val(organizationId);
  },

  onClickOrganizationSearch: function(e) {
    e.preventDefault();
    var self = this;

    UIUtil.showModal({
      title: 'Search for an Organization',
      dialogClasses: 'modal-dialog-organization-search-modal-view modal-lg',
      view: new SecOrganizationSearchModalView({
        model: this.model
      })
    }).done(function(organization) {
      if (organization) {
        self.ui.selectedOrganizationSearchOrganizationName.val(organization.applicantLegalName);
        self.ui.selectedOrganizationId.val(organization.organizationId);
        self.ui.crdNumber.text(organization.crdNumber);
        self.ui.dbaName.text(organization.dbaName);
      } else {
        self.ui.selectedOrganizationSearchOrganizationName.val('');
        self.ui.selectedOrganizationId.val('');
        self.ui.crdNumber.text('--');
      }
    });
  },

  onClearOrganizationSearch: function(e) {
    e.preventDefault();
    this.ui.selectedOrganizationSearchOrganizationName.val('');
    this.ui.selectedOrganizationId.val('');
    this.ui.crdNumber.text('--');
  },

  onChangeLicenseType: function() {
    var formData = Syphon.serialize(this);
    
    if (formData.licenseTypeId === '1') {
      this.ui.organizationSearchFormGroup.toggleClass('required', false);
      this.ui.selectedOrganizationId.attr('data-parsley-required', false);
    }
    else {    
      this.ui.organizationSearchFormGroup.toggleClass('required', true);
      this.ui.selectedOrganizationId.attr('data-parsley-required', true);
    }
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    formData.id = this.model.get('licenseId');
    formData.individualId = this.model.get('individualId');
    return formData;
  }
});
