/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(formData) {
  var deferred = $.Deferred();
  $.soap({
    body: {
      GenerateAdHocDocument: {
        $xmlns: 'http://dobs.pa.gov/nd/documentgen/bpm/1.0',
        LicenseID: formData.licenseId,
        ApplicationID: formData.applicationId,
        DocumentTemplateName: formData.documentTemplateName,
        ExternalReviewComments: formData.externalReviewComments,
        TentSaleDate: formData.tentSaleDate,
        Location: formData.location,
        AdvisoryLetterDate: formData.advisoryLetterDate,
        ApplicationType: formData.applicationType
      }
    },
    blueprint: {
      fileId: '$..FileID',
      fileName: '$..FileName'
    }
  })
    .done(function(file) {
      cleanUpResponse(file);
      deferred.resolve(file);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
