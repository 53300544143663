/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');

var CorpFinCorrespondentSectionView = require('../../common/views/CorpFinCorrespondentSectionView');
var CorpFinInvInvestmentDetailSectionView = require('./CorpFinInvInvestmentDetailSectionView');
var CorpFinInvIssuerSectionView = require('./CorpFinInvIssuerSectionView');

var tmplCorpFinInvInvestmentSectionView = require('../templates/CorpFinInvInvestmentSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplCorpFinInvInvestmentSectionView,

  className: 'corp-fin-inv-investment-section-view form-horizontal form-static m-b-lg',

  ui: {
    investmentDetail: '.investment-detail',
    issuer: '.issuer',
    correspondent: '.correspondent'
  },

  regions: {
    investmentDetail: '@ui.investmentDetail',
    issuer: '@ui.issuer',
    correspondent: '@ui.correspondent'
  },

  onBeforeShow: function() {
    this.showChildView('investmentDetail', new CorpFinInvInvestmentDetailSectionView({ model: this.model }));

    this.showChildView('issuer', new CorpFinInvIssuerSectionView({ model: this.model }));

    this.showChildView('correspondent', new CorpFinCorrespondentSectionView({ model: this.model }));
  }
});
