var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "        <span class=\"ladda-label\">Finalize Application and Proceed to Cart</span>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "        <span class=\"ladda-label\">Resubmit Application</span>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "\r\n<form class=\"affirmation-form\">\r\n  <div class=\"form-group required\">\r\n    <label class=\"control-label font-weight-normal\" id=\"CheckCasherAffirmationCheckBoxLabel\">\r\n      <input type=\"checkbox\" name=\"isCertified\" value=\"1\" id=\"CheckCasherAffirmationCheckBox\"\r\n        data-parsley-required=\"true\" />\r\n      I understand by submitting this Retail Grocery Store Check Casher Application: \r\n      <br>\r\n      I am agreeing to be bound by the\r\n      following declaration: \"I declare that all of my answers on this License Application are complete, true and\r\n      correct. I make this declaration subject to the penalties of 18 PA.C.S. § 4904 relating to unsworn falsification\r\n      to authorities.\"\r\n    </label>\r\n    <p>I attest that I have read and understand the following (click each for link):</p>\r\n    <ol>\r\n      <li>\r\n        <a href=\"https://www.dobs.pa.gov/Documents/Statutes/Check%20Casher%20Licensing%20Act.pdf\" target=\"_blank\">Check\r\n          Casher Act</a>\r\n      </li>\r\n      <li>\r\n        <a href=\"https://www.pacode.com/secure/data/010/chapter81/chap81toc.html\" target=\"_blank\">Title 10, Pennsylvania\r\n          Code, Chapter\r\n          81</a>\r\n      </li>\r\n    </ol>\r\n  </div>\r\n  <div class=\"form-group required\">\r\n    <label><strong>Enter Name Below</strong></label>\r\n    <input type=\"text\" class=\"form-control\" name=\"certificationName\" data-parsley-required=\"true\" data-parsley-maxlength=\"100\" />\r\n  </div>\r\n\r\n  <div class=\"row\">\r\n    <div class=\"col-sm-12\">\r\n      <button class=\"btn btn-primary submit ladda-button\" data-style=\"expand-right\" type=\"button\">\r\n"
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.mode : depth0),"===","new",{"name":"ifCond","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "      </button>\r\n    </div>\r\n  </div>\r\n</form>";
},"useData":true});