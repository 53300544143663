/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');

var parsleyUtil = require('common/util/parsleyUtil');

var NonDepEntityFormBooksAndRecordsCollectionView = require('./NonDepEntityFormBooksAndRecordsCollectionView');

var tmplNonDepEntityFormSectionBooksAndRecordsView = require('../templates/NonDepEntityFormSectionBooksAndRecordsView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepEntityFormSectionBooksAndRecordsView,

  className: 'nondep-entity-form-section-books-and-records-view',

  ui: {
    addBooksRecords: '.add-books-records',
    missingBooksAndRecords: '.missing-books-and-records',
    booksRecordsCollectionContainer: '.books-records-collection-container'
  },

  regions: {
    booksRecordsCollectionContainer: '@ui.booksRecordsCollectionContainer'
  },

  events: {
    'click @ui.addBooksRecords': 'onClickAddBooksRecords'
  },

  onBeforeShow: function() {
    this.needsOneBookAndRecord = this.model.get('isForeignEntity') === '1';
    this.booksAndRecords = new NonDepEntityFormBooksAndRecordsCollectionView({
      collection: new Backbone.Collection(this.model.get('alternateSitekeepings')),
      model: new Backbone.Model({ deletedBooksAndRecords: this.model.get('deletedBooksAndRecords') }),
      isAmendment: this.options.isAmendment,
      isReadOnly: this.options.isReadOnly,
      viewMode: this.options.viewMode,
      mode: this.options.mode
    });
    this.showChildView('booksRecordsCollectionContainer', this.booksAndRecords);
  },

  onClickAddBooksRecords: function(e) {
    e.preventDefault();
    this.booksAndRecords.collection.add({});
  },

  toggleNeedsOneBookAndRecord: function(needsOneBookAndRecord) {
    this.needsOneBookAndRecord = needsOneBookAndRecord;
  },

  validate: function(config) {
    this.ui.booksRecordsCollectionContainer.parsley().reset();
    this.ui.missingBooksAndRecords.hide();

    var validationPromises = [this.ui.booksRecordsCollectionContainer.parsley().whenValidate()];

    if (config.type === 'save') {
      // Turn off required field validation for this form
      parsleyUtil.disableRequiredValidation(this.ui.booksRecordsCollectionContainer);
    } else {
      // Turn on required field validation for this form
      parsleyUtil.enableRequiredValidation(this.ui.booksRecordsCollectionContainer);

      if (this.needsOneBookAndRecord && !config.isStandAlone) {
        validationPromises.push(this.validateHasOneBookAndRecord());
      }
    }
    return $.when.apply(this, validationPromises);
  },

  validateHasOneBookAndRecord: function() {
    var defer = $.Deferred();
    if (this.booksAndRecords.collection.length > 0) {
      this.ui.missingBooksAndRecords.hide();
      defer.resolve();
    } else {
      this.ui.missingBooksAndRecords.show();
      defer.reject();
    }
    return defer.promise();
  },

  getFormData: function() {
    return this.booksAndRecords.getFormData();
  }
});
