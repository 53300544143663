/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');

var PageableCollection = require('psa-backbone/models/PageableCollection');

var PlaceholderView = require('common/views/PlaceholderView');

var GetIndividualLicenseByIndividual = require('../services/GetIndividualLicenseByIndividual');
var SecIndividualSectionLicenseView = require('../views/SecIndividualSectionLicenseView');

module.exports = {
  channel: 'sc',

  event: 'build:individual-section-license-view',

  builder: function(individualId) {
    return new PlaceholderView({
      viewPromise: buildLicenseView(individualId)
    });
  }
};

function buildLicenseView(individualId) {
  var deferred = $.Deferred();

  GetIndividualLicenseByIndividual(individualId)
    .done(function(licenses) {
      deferred.resolve(
        new SecIndividualSectionLicenseView({
          model: new Backbone.Model({
            individualId: individualId
          }),
          collection: new PageableCollection(licenses, { pageSize: 25 })
        })
      );
    })
    .fail(function(err) {
      deferred.reject(err);
    });

  return deferred.promise();
}
