/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Ladda = require('ladda');
var Radio = require('backbone.radio');

var parsleyUtil = require('common/util/parsleyUtil');

var SubmitNewEntity = require('../services/SubmitNewEntity');

require('../styles/NonDepEntityFormSectionAffirmationView.css');
var tmplNonDepEntityFormSectionAffirmationView = require('../templates/NonDepEntityFormSectionAffirmationView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepEntityFormSectionAffirmationView,

  className: 'nondep-entity-form-section-affirmation-view',

  ui: {
    submitNewEntity: '.submit-new-entity',
    validationErrors: '.validation-errors',
    entityAffirmationForm: '.entity-affirmation-form'
  },

  events: {
    'click @ui.submitNewEntity': 'onClickSubmit'
  },

  templateHelpers: function() {
    return {
      isExtEdit: this.options.viewMode === 'extEdit'
    };
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;
    var l = Ladda.create(this.ui.submitNewEntity[0]);

    this.sideNavView
      .validateAll({ type: 'submit' })
      .done(function() {
        l.start();

        var rawFormData = self.sideNavView.getFormData();
        var formData = {
          isDraft: false,
          entityId: self.model.get('id'),
          identification: rawFormData.entityFormIdentification,
          entityOfficers: rawFormData.entityFormEntityOfficers,
          locations: rawFormData.entityFormLocations,
          booksAndRecords: rawFormData.entityFormBooksAndRecords,
          attachments: rawFormData.entityFormAttachments
        };

        if (self.options.viewMode === 'extEdit') {
          formData.isExtEdit = '1';
        }
        SubmitNewEntity(formData)
          .done(function(newEntityId) {
            Radio.channel('navigate').trigger('show:ext:nd:entity-dashboard', newEntityId);
          })
          .fail(function() {
            l.stop();
          });
      })
      .fail(function() {
        l.stop();
        self.ui.validationErrors.show();
      });
  },

  validate: function(config) {
    this.ui.validationErrors.hide();
    this.ui.entityAffirmationForm.parsley().reset();
    if (config.type === 'save') {
      // Turn off required field validation for this form
      parsleyUtil.disableRequiredValidation(this.ui.entityAffirmationForm);
    } else {
      // Turn on required field validation for this form
      parsleyUtil.enableRequiredValidation(this.ui.entityAffirmationForm);
    }
    return this.ui.entityAffirmationForm.parsley().whenValidate();
  }
});
