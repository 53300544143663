/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(notificationId) {
  var defer = $.Deferred();

  $.soap({
    body: {
      GetNotification: {
        $xmlns: 'http://dobs.pa.gov/cm/task/bpm/1.0',
        NotificationID: notificationId
      }
    },
    blueprintArrays: ['GetNotificationResponse.Documents.CM_NotificationDocument'],
    blueprint: {
      message: '$..Message',
      documents: [
        '$..CM_NotificationDocument',
        {
          id: '$.ID',
          documentId: '$.DocumentID',
          documentTypeId: '$.DocumentTypeID',
          documentType: '$.DocumentType',
          documentName: '$.DocumentName',
          createdDate: '$.CreatedDate',
          createdBy: '$.CreatedBy'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      _.each(response.documents, function(documents) {
        documents.createdDate = moment(documents.createdDate, moment.ISO_8601).format('MM/DD/YYYY hh:mm a');
        documents.file = {
          fileId: documents.documentId,
          fileName: documents.documentName
        };
      });
      defer.resolve(response);
    })
    .fail(function(err) {
      defer.reject(err);
    });
  return defer.promise();
};
