/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

module.exports = function(data) {
  return $.soap({
    body: {
      SubmitSEGLocation: {
        $xmlns: 'http://dobs.pa.gov/dp/seg/bpm/1.0',
        SEGLocationID: data.segLocationId,
        SegID: data.segId,
        Address: {
          AddressLine1: data.addressLine1,
          AddressLine2: data.addressLine2,
          AddressLine3: data.addressLine3,
          City: data.city,
          State: data.state,
          PostalCode: data.postalCode,
          Country: data.country,
          County: data.county
        },
        IsDeleted: data.isDeleted
      }
    }
  });
};
