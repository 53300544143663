/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var NProgress = require('nprogress');

var NavUtils = require('psa-core/util/nav');

var Scaffold = require('common/layout/Scaffold');

var GetEntityExternalUser = require('../services/GetEntityExternalUser');
var GetEntityIdentifiers = require('../../common/services/GetEntityIdentifiers');

var DepEntityManageUserView = require('../views/DepEntityManageUserView');

module.exports = function(args) {
  NProgress.start();
  NProgress.set(0.6);

  var entityId = args.model.get('entityId');
  var userId = args.model.get('userId');

  $.when(GetEntityExternalUser(userId), GetEntityIdentifiers(entityId))
    .done(function(externalUser, entityIdentifier) {
      if (args.viewMode === 'int') {
        NavUtils.navigate('dep/entity/' + entityId + '/user/' + userId);
      } else {
        NavUtils.navigate('ext/dep/entity/' + entityId + '/user/' + userId);
      }
      args.model.set(externalUser);
      args.model.set(entityIdentifier);
      Scaffold.content.show(new DepEntityManageUserView(args));
      NProgress.done();
    })
    .fail(function(err) {
      NProgress.done();
    });
};
