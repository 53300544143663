var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "        <option value=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</option>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {});

  return "\r\n<h1 class=\"page-title\">"
    + alias1(container.lambda(((stack1 = (depth0 != null ? depth0.entityIdentifiers : depth0)) != null ? stack1.entityName : stack1), depth0))
    + "</h1>\r\n<div class=\"header-bar\"></div>\r\n<div class=\"breadcrumb-container\"></div>\r\n\r\n<h2 class=\"m-t-n\">Create New License</h2>\r\n\r\n<form class=\"form-horizontal apply-for-license-form col-sm-9 col-sm-offset-1\">\r\n  <div class=\"form-group required\">\r\n    <label class=\"control-label col-sm-3\">License Type</label>\r\n    <div class=\"col-sm-9\">\r\n      <select name=\"licenseTypeId\" class=\"form-control license-type-select\" data-parsley-required=\"true\">\r\n        <option value=\"\">--</option>\r\n"
    + ((stack1 = helpers.each.call(alias2,(depth0 != null ? depth0.licenseTypes : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "      </select>\r\n    </div>\r\n  </div>\r\n  <div class=\"form-group disabled parent-license-form-group\">\r\n    <label class=\"control-label col-sm-3\">Parent License</label>\r\n    <div class=\"col-sm-9\">\r\n      <div class=\"parent-license-select-container\"></div>\r\n      <p class=\"input-label\">Only required when a branch license is selected. In order to apply for a branch license, an\r\n        existing primary license must be selected.</p>\r\n    </div>\r\n  </div>\r\n  <div class=\"form-group required\">\r\n    <label class=\"control-label col-sm-3\">Location</label>\r\n    <div class=\"col-sm-9\">\r\n      <select name=\"locationId\" class=\"form-control location-select\" data-placeholder=\"--\" data-parsley-required=\"true\"\r\n        data-parsley-validate-if-empty=\"true\" data-parsley-trigger-after-failure=\"change.select2\">\r\n        <option></option>\r\n      </select>\r\n    </div>\r\n  </div>\r\n  <div class=\"form-group required\">\r\n    <label class=\"control-label col-sm-3\">License Status</label>\r\n    <div class=\"col-sm-9\">\r\n      <select name=\"licenseStatusId\" class=\"form-control license-status-select\" data-parsley-required=\"true\">\r\n        <option value=\"\">--</option>\r\n"
    + ((stack1 = helpers.each.call(alias2,((stack1 = (depth0 != null ? depth0.licenseStatus : depth0)) != null ? stack1.status : stack1),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "      </select>\r\n    </div>\r\n  </div>\r\n  <div class=\"form-group required\">\r\n    <label class=\"control-label col-sm-3\">Effective Start Date</label>\r\n    <div class=\"col-md-3 col-sm-9\">\r\n      <div class=\"input-group\">\r\n        <input type=\"text\" name=\"effectiveStartDate\" class=\"form-control datepicker effective-start-date\"\r\n          placeholder=\"Effective Start Date\" data-parsley-required=\"true\" data-parsley-date=\"\"\r\n          data-parsley-trigger-after-failure=\"changeDate\" \r\n          data-parsley-errors-container=\"#effectiveStartDateErrorsContainer\"/>\r\n        <div class=\"input-group-addon\">\r\n          <i class=\"fa fa-calendar\"></i>\r\n        </div>\r\n      </div>\r\n      <div id=\"effectiveStartDateErrorsContainer\"></div>\r\n    </div>\r\n  </div>\r\n  <div class=\"form-group required\">\r\n    <label class=\"control-label col-sm-3\">Effective End Date</label>\r\n    <div class=\"col-md-3 col-sm-9\">\r\n      <div class=\"input-group\">\r\n        <input type=\"text\" name=\"effectiveEndDate\" class=\"form-control datepicker effective-end-date\"\r\n          placeholder=\"Effective End Date\" data-parsley-required=\"true\" data-parsley-date=\"\"\r\n          data-parsley-trigger-after-failure=\"changeDate\" \r\n          data-parsley-errors-container=\"#effectiveEndDateErrorsContainer\" />\r\n        <div class=\"input-group-addon\">\r\n          <i class=\"fa fa-calendar\"></i>\r\n        </div>\r\n      </div>\r\n      <div id=\"effectiveEndDateErrorsContainer\"></div>\r\n    </div>\r\n  </div>\r\n  <div class=\"form-group\">\r\n    <div class=\"col-sm-offset-3 col-sm-9\">\r\n      <a href=\"#nondep/entity/"
    + alias1(((helper = (helper = helpers.entityId || (depth0 != null ? depth0.entityId : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias2,{"name":"entityId","hash":{},"data":data}) : helper)))
    + "/dashboard\" class=\"btn btn-default btn-md\" role=\"button\">Cancel</a>\r\n      <button class=\"btn btn-primary btn-md submit\">Submit</button>\r\n    </div>\r\n  </div>\r\n</form>";
},"useData":true});