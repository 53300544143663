/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(entityId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetMrbasByEntity: {
        $xmlns: 'http://dobs.pa.gov/dp/mrba/bpm/1.0',
        EntityID: entityId
      }
    },
    blueprintArrays: ['GetMrbasByEntityResponse.DP_EntityMRBA'],
    blueprint: {
      mrbas: [
        '$..DP_EntityMRBA',
        {
          mrbaId: '$..ID',
          entityId: '$..EntityID',
          type: '$..Type',
          number: '$..Number',
          examAsOfDate: '$..ExamAsOfDate',
          category: '$..Category',
          agency: '$..Agency',
          dueDate: '$..DueDate',
          status: '$..Status',
          history: '$..History',
          isDeleted: '$..IsDeleted',
          comments: '$..Comments'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      _.each(response.mrbas, function(mrba) {
        if (mrba.examAsOfDate) {
          mrba.examAsOfDate = moment(mrba.examAsOfDate, 'YYYY-MM-DD');
        }
        if (mrba.dueDate) {
          mrba.dueDate = moment(mrba.dueDate, 'YYYY-MM-DD');
        }

        _.each(['type', 'number', 'lastName', 'category', 'agency', 'status', 'history'], function(attr) {
          if (!mrba[attr]) {
            mrba[attr] = '--';
          }
        });
        var comments = mrba.comments;
        if (comments) {
          mrba.comments = comments.length > 100 ? comments.substring(0, 100) + '...' : comments;
        } else {
          mrba.comments = '--';
        }
      });

      deferred.resolve(response.mrbas);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
