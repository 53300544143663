/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');

var UIUtil = require('psa-core/util/ui');

var FileUploadTableView = require('common/views/FileUploadTableView');

var AttachmentsModalView = require('./AttachmentModalView');

require('../styles/AttachmentsView.css');
var tmplAttachmentsView = require('../templates/AttachmentsView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplAttachmentsView,

  className: 'attachments-view',

  ui: {
    addAttachments: '.add-attachments',
    attachmentsCollectionContainer: '.attachments-collection-container'
  },

  events: {
    'click @ui.addAttachments': 'onClickAddAttachments'
  },

  regions: {
    attachmentsCollectionContainer: '@ui.attachmentsCollectionContainer'
  },

  onBeforeShow: function() {
    if (!this.collection) {
      this.collection = new Backbone.Collection();
    }
    this.fileUploadTable = new FileUploadTableView({
      isReadOnly: true,
      collection: this.collection,
      emptyText: 'No Attachments Added'
    });
    this.showChildView('attachmentsCollectionContainer', this.fileUploadTable);
  },

  onClickAddAttachments: function() {
    var self = this;
    UIUtil.showModal({
      title: 'Add Attachments',
      dialogClasses: 'modal-dialog-attachment-modal-view',
      view: new AttachmentsModalView({
        model: this.model
      })
    }).done(function(newAttachments) {
      self.fileUploadTable.collection.add(newAttachments, { at: 0 });
    });
  }
});
