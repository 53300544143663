var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "\r\n<div class=\"modal-body\">\r\n  <div class=\"row\">\r\n    <div class=\"col-md-3\">\r\n      <img src=\"img/question-mark.png\"/>\r\n    </div>\r\n    <div class=\"col-md-9\">\r\n      <p>"
    + alias4(((helper = (helper = helpers.message || (depth0 != null ? depth0.message : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"message","hash":{},"data":data}) : helper)))
    + "</p>\r\n    </div>\r\n  </div>\r\n</div>\r\n<div class=\"modal-footer\">\r\n  <button class=\"btn-no btn "
    + alias4(((helper = (helper = helpers.rejectButtonClass || (depth0 != null ? depth0.rejectButtonClass : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"rejectButtonClass","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.rejectText || (depth0 != null ? depth0.rejectText : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"rejectText","hash":{},"data":data}) : helper)))
    + "</button>\r\n  <button class=\"btn-yes btn ladda-button "
    + alias4(((helper = (helper = helpers.confirmButtonClass || (depth0 != null ? depth0.confirmButtonClass : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"confirmButtonClass","hash":{},"data":data}) : helper)))
    + "\" data-style=\"expand-right\">\r\n    <span class=\"ladda-label\">"
    + alias4(((helper = (helper = helpers.confirmText || (depth0 != null ? depth0.confirmText : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"confirmText","hash":{},"data":data}) : helper)))
    + "</span>\r\n  </button>\r\n</div>";
},"useData":true});