/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');

var ExamQuestionView = require('./ExamQuestionView');

require('../styles/ExamQuestionsCollectionView.css');

module.exports = Marionette.CollectionView.extend({
  className: 'exam-questions-collection-view',

  childView: ExamQuestionView,

  childViewOptions: function() {
    return {
      examQuestionParameters: this.options.examQuestionParameters,
      documentTypes: this.options.documentTypes,
      mode: this.options.mode
    };
  },

  validate: function(config) {
    // Run the .validate() method on every child view, which should return true
    // if valid
    var validationPromises = this.children.reduce(function(memo, child) {
      var validateView = child.validate.bind(child);
      var validateViewPromise = validateView(config);
      if (validateViewPromise) {
        memo.push(validateViewPromise);
      }
      return memo;
    }, []);
    return $.when.apply($, validationPromises);
  },

  getFormData: function() {
    return this.children.reduce(function(memo, child) {
      if (child.getFormData && _.isFunction(child.getFormData)) {
        var formData = child.getFormData();
        if (formData) {
          memo.push(formData);
        }
      }
      return memo;
    }, []);
  },

  getRetainedDocuments: function() {
    return this.children.reduce(function(memo, child) {
      if (child.getRetainedDocuments && _.isFunction(child.getRetainedDocuments)) {
        var retainedDocuments = child.getRetainedDocuments() || [];
        if (retainedDocuments.length) {
          return memo.concat(retainedDocuments);
        }
      }
      return memo;
    }, []);
  }
});
