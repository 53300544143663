var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0["case"] : depth0)) != null ? stack1.paymentPlanDescription : stack1), depth0));
},"3":function(container,depth0,helpers,partials,data) {
    return "--";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0["case"] : depth0)) != null ? stack1.restitutionOrdered : stack1), depth0));
},"7":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0["case"] : depth0)) != null ? stack1.restitutionPaid : stack1), depth0));
},"9":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0["case"] : depth0)) != null ? stack1.anticipatedFineNotIssued : stack1), depth0));
},"11":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0["case"] : depth0)) != null ? stack1.uncollectedDollarAmount : stack1), depth0));
},"13":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0["case"] : depth0)) != null ? stack1.referredToOag : stack1), depth0));
},"15":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0["case"] : depth0)) != null ? stack1.underAppealAmount : stack1), depth0));
},"17":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0["case"] : depth0)) != null ? stack1.waivedDollarAmount : stack1), depth0));
},"19":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0["case"] : depth0)) != null ? stack1.nonConsentLineNumbers : stack1), depth0));
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "\r\n<div class=\"row\">\r\n  <div class=\"col-sm-12 form-horizontal form-static\">\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-3\">Payment Plan Description</label>\r\n      <p class=\"form-control-static col-sm-6\">"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0["case"] : depth0)) != null ? stack1.paymentPlanDescription : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "</p>\r\n    </div>\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-3\">Restitution Ordered</label>\r\n      <div class=\"form-control-static col-sm-9\">\r\n        "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0["case"] : depth0)) != null ? stack1.restitutionOrdered : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\r\n    </div>\r\n <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-3\">Restitution Paid</label>\r\n      <div class=\"form-control-static col-sm-9\">\r\n        "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0["case"] : depth0)) != null ? stack1.restitutionPaid : stack1),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\r\n    </div>\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-3\">Anticipated Fine Not Yet Issued</label>\r\n      <div class=\"form-control-static col-sm-9\">\r\n        "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0["case"] : depth0)) != null ? stack1.anticipatedFineNotIssued : stack1),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\r\n    </div>\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-3\">Uncollectible Dollar Amount</label>\r\n      <div class=\"form-control-static col-sm-9\">\r\n        "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0["case"] : depth0)) != null ? stack1.uncollectedDollarAmount : stack1),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\r\n    </div>\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-3\">Referred to OAG</label>\r\n      <div class=\"form-control-static col-sm-9\">"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0["case"] : depth0)) != null ? stack1.referredToOag : stack1),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\r\n    </div>\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-3\">Under Appeal Dollar Amount</label>\r\n      <div class=\"form-control-static col-sm-9\">\r\n        "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0["case"] : depth0)) != null ? stack1.underAppealAmount : stack1),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "\r\n      </div>\r\n    </div>\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-3\">Waived Dollar Amount</label>\r\n      <div class=\"form-control-static col-sm-9\">\r\n        "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0["case"] : depth0)) != null ? stack1.waivedDollarAmount : stack1),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\r\n    </div>\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-3\">Line Numbers of Non-Consent Orders Replaced by CAO</label>\r\n      <div class=\"form-control-static col-sm-9\">\r\n        "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0["case"] : depth0)) != null ? stack1.nonConsentLineNumbers : stack1),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\r\n    </div>\r\n  </div>\r\n</div>";
},"useData":true});