/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Ladda = require('ladda');
var Radio = require('backbone.radio');

var BreadcrumbView = require('common/views/BreadcrumbView');
var modelGet = require('common/util/modelGet');

var SubmitEntityContact = require('../services/SubmitEntityContact');
var DepContactFormView = require('./DepContactFormView');

var tmplDepContactEditView = require('../templates/DepContactEditView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepContactEditView,

  className: 'dep-edit-contact-view container-fluid',

  ui: {
    breadcrumb: '.breadcrumb-container',
    contactContainer: '.contact-form-container',
    contactTypeSelect: '.contact-type-select',
    submit: '.submit'
  },

  regions: {
    breadcrumb: '@ui.breadcrumb',
    contactContainer: '@ui.contactContainer'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeShow: function() {
    var contact = this.model.get('contact') || {};
    this.contactId = contact.contactId;
    this.entityId = contact.entityId;
    var entityName = modelGet(this.model, 'entityIdentifiers.entityName');

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#dep/dashboard',
            text: 'Home'
          },
          {
            link: '#dep/entity/' + this.entityId + '/dashboard',
            text: entityName
          },
          {
            link: '#dep/entity/' + this.entityId + '/contact/' + this.contactId + '/dashboard',
            text: contact.firstName + ' ' + contact.lastName + ' - ' + contact.entityContactTypesList
          },
          {
            text: 'Edit Contact'
          }
        ]
      })
    );

    this.contactForm = new DepContactFormView({
      model: this.model
    });
    this.showChildView('contactContainer', this.contactForm);
  },

  onClickSubmit: function(event) {
    event.preventDefault();
    var self = this;
    this.validate().done(function() {
      var l = Ladda.create(self.ui.submit[0]);
      l.start();
      var formData = self.getFormData();
      SubmitEntityContact(formData)
        .done(function(contactId) {
          Radio.channel('navigate').trigger('show:int:dp:contact', self.entityId, contactId);
        })
        .fail(function() {
          l.stop();
        });
    });
  },

  getFormData: function() {
    var formData = this.contactForm.getFormData();

    formData.contactId = this.contactId;
    formData.entityId = this.entityId;
    return formData;
  },

  validate: function() {
    return this.ui.contactContainer.parsley().whenValidate();
  }
});
