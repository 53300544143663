/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(examPeriodId) {
  var defer = $.Deferred();

  $.soap({
    body: {
      GetExamByExamPeriod: {
        $xmlns: 'http://dobs.pa.gov/nd/exam/bpm/1.0',
        ExamPeriodID: examPeriodId
      }
    },
    blueprintArrays: ['GetExamByExamPeriodResponse.ND_Exam'],
    blueprint: {
      exam: [
        '$..ND_Exam',
        {
          examId: '$.ExamID',
          entityId: '$.EntityID',
          entityName: '$.EntityName',
          examDate: '$.ExamDate',
          statusId: '$.StatusID',
          status: '$.Status',
          primaryFirstName: '$.PrimaryFirstName',
          primaryLastName: '$.PrimaryLastName',
          region: '$.Region',
          licenseNumber: '$.LicenseNumber',
          licenseType: '$.LicenseType',
          licenseTypeId: '$.LicenseTypeID',
          licenseId: '$.LicenseID',
          examiners: '$.Examiners',
          primaryExaminerUserId: '$.PrimaryExaminerUserId',
          examCoverageStartDate: '$.ExamCoverageStartDate',
          examCoverageEndDate: '$.ExamCoverageEndDate',
          examStartDate: '$.ExamStartDate',
          examEndDate: '$.ExamEndDate'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      _.each(response.exam, function(exam) {
        if (exam.examDate) {
          exam.examDate = moment(exam.examDate, moment.ISO_8601);
        }
        if (exam.examCoverageStartDate) {
          exam.examCoverageStartDate = moment(exam.examCoverageStartDate, moment.ISO_8601);
        }
        if (exam.examCoverageEndDate) {
          exam.examCoverageEndDate = moment(exam.examCoverageEndDate, moment.ISO_8601);
        }
        if (exam.examStartDate) {
          exam.examStartDate = moment(exam.examStartDate, moment.ISO_8601);
        }
        if (exam.examEndDate) {
          exam.examEndDate = moment(exam.examEndDate, moment.ISO_8601);
        }
        if (exam.examiners) {
          exam.examiners = exam.primaryExaminerUserId + ', ' + exam.examiners;
        } else {
          exam.examiners = exam.primaryExaminerUserId;
        }
      });

      defer.resolve(response.exam);
    })
    .fail(function(err) {
      defer.reject(err);
    });

  return defer.promise();
};
