/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var toggleForm = require('common/util/toggleForm');

var tmplDepExamTSPNewParticipatingAgencyModalView = require('../templates/DepExamTSPNewParticipatingAgencyModalView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepExamTSPNewParticipatingAgencyModalView,

  className: 'new-tsp-part-agency',

  ui: {
    form: 'form',
    datePickerFields: '.date-picker',
    otherRegion: '.other-region',
    regionSelect: '.region-select',
    nameSelect: '.type-select',
    submit: '.submit'
  },

  events: {
    'click @ui.submit': 'onClickSubmit',
    'change @ui.regionSelect': 'onChangeRegionSelect'
  },

  templateHelpers: function() {
    return {
      examRegions: this.options.examRegions,
      examNames: this.options.examNames
    };
  },

  onBeforeShow: function() {
    this.ui.datePickerFields.datepicker();
    Syphon.deserialize(this, this.model.toJSON());
    this.onChangeRegionSelect();
  },

  onChangeRegionSelect: function() {
    var regionValue = this.ui.regionSelect.val();
    var isRegionOther = regionValue === '16';

    toggleForm(this.ui.otherRegion, isRegionOther);
  },

  getFormData: function() {
    var formData = Syphon.serialize(this.ui.form);

    formData.nameDisplay = this.ui.nameSelect.find(':selected').text();
    formData.regionDisplay = this.ui.regionSelect.find(':selected').text();
    formData.examId = this.options.examId;

    return formData;
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;
    this.ui.form
      .parsley()
      .whenValidate()
      .done(
        function() {
          var formData = this.getFormData();

          self.triggerMethod('modal:close', formData);
        }.bind(this)
      );
  }
});
