/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');

var modelGet = require('common/util/modelGet');

var tmplDepExamTurnaroundSectionView = require('../templates/DepExamTurnaroundSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepExamTurnaroundSectionView,

  className: 'dep-exam-turnaround-section-view',

  ui: {
    emptyTurnaround: '.empty-turnaround-container',
    ssTurnaround: '.ss-turnaround-container',
    trustTurnaround: '.trust-turnaround-container'
  },

  templateHelpers: function() {
    var isBank = this.model.get('entityCategoryId') === '1';
    return {
      isBank: isBank
    };
  },

  onBeforeShow: function() {
    var examAreaIds = _.map(modelGet(this.model, 'examAreas', []), function(area) {
      return area.id;
    });

    var ssAreaIds = ['1', '11'],
      trustAreaIds = ['2', '21'],
      hasSSTurnAround = _.intersection(ssAreaIds, examAreaIds).length > 0,
      hasTrustTurnAround = _.intersection(trustAreaIds, examAreaIds).length > 0;
    if (hasSSTurnAround || hasTrustTurnAround) {
      if (hasSSTurnAround) {
        this.ui.ssTurnaround.show();
      }
      if (hasTrustTurnAround) {
        this.ui.trustTurnaround.show();
      }
    } else {
      this.ui.emptyTurnaround.show();
    }
  }
});
