var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "\r\n<div class=\"page-title clearfix\">\r\n  <h1 class=\"m-n p-n\">"
    + alias4(((helper = (helper = helpers.entityName || (depth0 != null ? depth0.entityName : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"entityName","hash":{},"data":data}) : helper)))
    + "</h1>\r\n  <div class=\"page-title-buttons-container\">\r\n    <div class=\"dropdown-container\"></div>\r\n  </div>\r\n</div>\r\n<div class=\"breadcrumb-container\"></div>\r\n<div class=\"col-sm-12 col-md-10 col-md-offset-1\">\r\n  <div class=\"row\">\r\n    <div class=\"col-sm-9 col-sm-offset-3 col-md-10 col-md-offset-2\">\r\n      <h3 class=\"m-t-xs m-b-lg\">New Key Third-Party Vendor</h3>\r\n    </div>\r\n  </div>\r\n  <div class=\"new-key-third-party-vendor-container\"></div>\r\n  <div class=\"row m-b-lg\">\r\n    <div class=\"col-sm-9 col-sm-offset-3 col-md-10 col-md-offset-2\">\r\n      <a class=\"btn btn-default\" href=\"#dep/entity/"
    + alias4(((helper = (helper = helpers.entityId || (depth0 != null ? depth0.entityId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"entityId","hash":{},"data":data}) : helper)))
    + "/dashboard\">Cancel</a>\r\n      <button class=\"btn btn-primary btn-submit\">Submit</button>\r\n    </div>\r\n  </div>\r\n</div>";
},"useData":true});