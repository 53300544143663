/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Handlebars = require('handlebars');
var Marionette = require('backbone.marionette');

var NavUtils = require('psa-core/util/nav');

require('./SidebarNavView.css');

module.exports = Marionette.LayoutView.extend({
  template: Handlebars.compile(''),

  className: 'side-bar-nav-view',

  ui: {
    menuItem: '.menu-item'
  },

  onBeforeShow: function() {
    var currentRoute = NavUtils.getCurrentRoute();
    this.$el
      .find('.menu-item[href="#' + currentRoute + '"]')
      .closest('li')
      .addClass('active');
  }
});
