/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Radio = require('backbone.radio');
var moment = require('moment');

var UIUtil = require('psa-core/util/ui');
var GridView = require('psa-marionette/views/GridView');
var PageableCollection = require('psa-backbone/models/PageableCollection');

var MultiButtonCell = require('common/backgrid/MultiButtonCell');
var TruncateStringCell = require('common/backgrid/TruncateStringCell');
var MomentCell = require('common/backgrid/MomentCell');

var ConSerInternalSidebarNavView = require('./ConSerInternalSidebarNavView');
var ConSerComplaintWithdrawCorrespondenceModalView = require('../../complaint-dashboard/views/ConSerComplaintWithdrawCorrespondenceModalView');

require('../styles/ConSerCorrespondenceSearchView.css');
var tmplConSerCorrespondenceSearchView = require('../templates/ConSerCorrespondenceSearchView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplConSerCorrespondenceSearchView,

  className: 'con-ser-correspondence-search-view container-fluid',

  ui: {
    sideBar: '.side-bar-container',
    searchForm: '.search-form',

    searchResultsContainer: '.search-results-container',
    search: '.search',
    clearFilters: '.clear-filters',
    formInputs: ':input',
    initiatedOnDateRange: '.initiated-on-date-range'
  },

  regions: {
    sideBar: '@ui.sideBar',
    searchResultsContainer: '@ui.searchResultsContainer'
  },

  events: {
    'click @ui.search': 'onClickSearch',
    'click @ui.clearFilters': 'onClickClearFilters',
    'keydown @ui.formInputs': 'onKeydownOfInputs'
  },

  onBeforeShow: function() {
    this.showChildView('sideBar', new ConSerInternalSidebarNavView());

    this.collection = new PageableCollection(this.model.get('correspondences'), { pageSize: 25 });
    this.shadowCollection = new PageableCollection(this.model.get('correspondences'), { pageSize: 25 });

    this.ui.initiatedOnDateRange.datepicker();

    this.searchResults = new GridView({
      columns: [
        {
          name: 'entityName',
          label: 'Entity Name',
          cell: TruncateStringCell,
          headerCell: 'custom',
          width: 15,
          editable: false
        },
        {
          name: 'requestedByName',
          label: 'Initiated By',
          cell: 'string',
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'caseManagerName',
          label: 'Case Manager',
          cell: 'string',
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'complainantName',
          label: 'Complainant',
          cell: 'string',
          headerCell: 'custom',
          width: 11,
          editable: false
        },
        {
          name: 'status',
          label: 'Status',
          cell: 'string',
          headerCell: 'custom',
          width: 11,
          editable: false
        },
        {
          name: 'requestedOn',
          label: 'Date Opened',
          cell: MomentCell.MomentCell,
          formatter: MomentCell.MomentDateFormatter.extend({
            emptyValue: '--'
          }),
          sortValue: MomentCell.MomentSortValue,
          headerCell: 'custom',
          width: 11,
          editable: false
        },
        {
          name: '',
          label: '',
          cell: MultiButtonCell.extend({
            buttons: [
              {
                name: '',
                labelClass: 'fa fa-eraser',
                buttonClass: 'btn-danger btn-sm ladda-button',
                renderable: function(dataModel) {
                  var statusId = dataModel.get('statusId');
                  return statusId === '1';
                },
                onClick: this.onClickWithdrawCorrespondence.bind(this)
              },
              {
                name: '',
                labelClass: 'fa fa-external-link',
                buttonClass: 'btn-primary btn-sm',
                newTab: true,
                href: function(model) {
                  return (
                    '#conser/complaint/' + model.get('complaintId') + '/correspondence/' + model.get('correspondenceId')
                  );
                }
              }
            ]
          }),
          headerCell: 'custom',
          width: 5,
          sortable: false,
          editable: false
        }
      ],
      collection: this.shadowCollection,
      emptyText: 'No Correspondences Found'
    });

    this.showChildView('searchResultsContainer', this.searchResults);

    this.$('.backgrid.table').addClass('table-bordered');
  },

  onKeydownOfInputs: function(e) {
    // Searches on press of enter
    if (e.keyCode === 13) {
      this.onClickSearch(e);
    }
  },

  onClickSearch: function(e) {
    e.preventDefault();
    var self = this;
    this.ui.searchForm
      .parsley()
      .whenValidate()
      .done(function() {
        var filter = Syphon.serialize(self, {
          inputReaders: Syphon.SearchInputReaderSet
        });
        filter = _.pick(filter, _.identity);
        self.shadowCollection.fullCollection.reset(self.collection.fullCollection.models);
        var models;

        _.each(filter, function(filterVal, filterType, filter) {
          models = self.shadowCollection.fullCollection.filter(function(model) {
            return self.filter(model, filterType, filterVal);
          });
          self.shadowCollection.fullCollection.reset(models);
        });
      });
  },

  filter: function(shadow, filterType, filterVal) {
    if (filterType === 'correspondenceInitiatedBy' || filterType === 'caseManager' || filterType === 'statusId') {
      return shadow.attributes[filterType] === filterVal;
    } else if (filterType === 'initiatedOnStartDate') {
      if (shadow.attributes.requestedOn) {
        return moment(shadow.attributes.requestedOn).isSameOrAfter(moment(filterVal, 'MM-DD-YYYY'), 'day');
      }
    } else if (filterType === 'initiatedOnEndDate') {
      if (shadow.attributes.requestedOn) {
        return moment(shadow.attributes.requestedOn).isSameOrBefore(moment(filterVal, 'MM-DD-YYYY'), 'day');
      }
    } else {
      var temp = isNaN(shadow.attributes[filterType])
        ? shadow.attributes[filterType].toLowerCase()
        : shadow.attributes[filterType];
      filterVal = isNaN(filterVal) ? filterVal.toLowerCase() : filterVal;
      return typeof temp === 'string' && temp.indexOf(filterVal) > -1;
    }
  },

  onClickClearFilters: function(e) {
    e.preventDefault();
    this.ui.formInputs.val('');
  },

  onClickWithdrawCorrespondence: function(e, model) {
    e.preventDefault();
    UIUtil.showModal({
      title: 'Withdraw Correspondence',
      view: new ConSerComplaintWithdrawCorrespondenceModalView({ model: model })
    }).done(function() {
      Radio.channel('navigate').trigger('show:int:cs:correspondence-search');
    });
  }
});
