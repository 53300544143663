/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

module.exports = function(formData) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      SubmitRicsCompliance: {
        $xmlns: 'http://dobs.pa.gov/nd/rics/bpm/1.0',
        EntityID: formData.entityId,
        RicsComplianceID: formData.ricsComplianceId,
        TIN: formData.tin,
        CaseNumber: formData.caseNumber,
        ClearanceDecisionCode: formData.clearanceDecisionCode,
        ClearanceDecisionDate: formData.clearanceDecisionDate,
        CaseStatusCode: formData.caseStatusCode,
        CaseStatusReasonCode: formData.caseStatusReasonCode,
        ErrorDescription: formData.errorDescription
      }
    }
  })
    .done(function() {
      deferred.resolve();
    })
    .fail(function(err) {
      deferred.reject(err);
    });

  return deferred.promise();
};
