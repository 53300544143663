/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Ladda = require('ladda');
var Radio = require('backbone.radio');
var Syphon = require('backbone.syphon');

var GridView = require('psa-marionette/views/GridView');
var UIUtil = require('psa-core/util/ui');

var HeaderBarView = require('common/views/HeaderBarView');
var BreadcrumbView = require('common/views/BreadcrumbView');
var BooleanCell = require('common/backgrid/BooleanCell');
var modelGet = require('common/util/modelGet');
var ConfirmationModalView = require('common/views/ConfirmationModalView');

var SecOrganizationFormDBACollectionView = require('./SecOrganizationFormDBACollectionView');
var SubmitOrganization = require('../services/SubmitOrganization');
var GetOrganizationCRDNumberExists = require('../services/GetOrganizationCRDNumberExists');

var tmplSecOrganizationDetailsEditView = require('../templates/SecOrganizationDetailsEditView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplSecOrganizationDetailsEditView,

  className: 'sec-organization-edit-view container-fluid',

  ui: {
    headerBar: '.header-bar-container',
    breadcrumb: '.breadcrumb-container',
    submit: '.btn-submit',
    reviewDate: '.review-date',
    currentDBACollectionContainer: '.current-dba-collection-container',
    dbaCollectionContainer: '.dba-collection-container',
    addDBA: '.add-dba',
    organizationDetailsForm: '.organization-details-form'
  },

  regions: {
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb',
    currentDBACollectionContainer: '@ui.currentDBACollectionContainer',
    dbaCollectionContainer: '@ui.dbaCollectionContainer'
  },

  events: {
    'click @ui.addDBA': 'onClickAddDBA',
    'click @ui.submit': 'onClickSubmit'
  },

  childEvents: {
    'remove:dba': 'onChildRemoveDBA'
  },

  templateHelpers: function() {
    var registrationUsers = modelGet(this.model, 'registrationUsers', []);
    var registrationUser = modelGet(this.model, 'organization.lastLicensingReviewBy');
    var registrationUserName = modelGet(this.model, 'organization.lastLicensingReviewByName');

    /*
     * Current user could no longer have sc user role, add current user
     * with internal user data if username does not exist in users array
     */
    var currentUserHasRole = _.find(registrationUsers, function(cm) {
      return registrationUser === cm.userId;
    });

    if (registrationUser && !currentUserHasRole) {
      var currentUser = {
        userId: registrationUser,
        name: registrationUserName
      };
      registrationUsers.splice(_.sortedIndex(registrationUsers, currentUser, 'name'), 0, currentUser);
    }

    return {
      registrationUsers: registrationUsers
    };
  },

  onBeforeShow: function() {
    var organization = this.model.get('organization') || {};
    var organizationId = organization.id;
    var DBAs = organization.dbas;

    var headerBarColumns = [
      {
        name: 'CRDNumber',
        label: 'CRD Number',
        minWidth: '160px',
        maxWidth: '200px'
      }
    ];

    organization.title = 'Organization';
    this.showChildView(
      'headerBar',
      new HeaderBarView({
        columns: new Backbone.Collection(headerBarColumns),
        model: new Backbone.Model(organization)
      })
    );

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#sec/dashboard',
            text: 'Home'
          },
          {
            link: '#sec/organization/' + organizationId + '/dashboard',
            text: organization.applicantLegalName
          },
          {
            text: 'Edit Organization'
          }
        ]
      })
    );

    this.currentDBAs = new GridView({
      emptyText: 'Organization has no current DBAs',
      collection: new Backbone.Collection(DBAs),
      columns: [
        {
          name: 'dba',
          label: 'DBA Name',
          cell: 'string',
          headerCell: 'custom',
          width: 75,
          editable: false
        },
        {
          name: 'shouldDelete',
          label: 'Delete?',
          cell: BooleanCell,
          headerCell: 'custom',
          width: 25,
          editable: true,
          sortable: false
        }
      ]
    });
    this.dbas = new SecOrganizationFormDBACollectionView({
      collection: new Backbone.Collection([])
    });
    this.showChildView('currentDBACollectionContainer', this.currentDBAs);
    this.showChildView('dbaCollectionContainer', this.dbas);

    Syphon.deserialize(this, this.model.get('organization'));
    this.ui.reviewDate.datepicker();
  },

  onClickAddDBA: function(e) {
    e.preventDefault();
    this.dbas.collection.add({});
  },

  onChildRemoveDBA: function(childView) {
    this.dbas.collection.remove(childView);
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;
    var formData = this.getFormData();

    // This form uses uppercase CRD for the input name, but the duplicate CRD
    // check service needs lowercase crd, hence this correction.
    formData.crdNumber = formData.CRDNumber;

    this.validate().done(function() {
      var spinner = Ladda.create(self.ui.submit.get(0));
      spinner.start();
      GetOrganizationCRDNumberExists(formData)
        .done(function(organizationCRDNumberExists) {
          if (organizationCRDNumberExists) {
            spinner.stop();
            self.showDuplicateCRDConfirmationModal();
          } else {
            SubmitOrganization(formData)
              .done(function() {
                Radio.channel('navigate').trigger('show:int:sc:organization', formData.organizationId);
              })
              .fail(function() {
                spinner.stop();
              });
          }
        })
        .fail(function() {
          spinner.stop();
        });
    });
  },

  validate: function() {
    return this.ui.organizationDetailsForm.parsley().whenValidate();
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    var dbas = this.dbas.getFormData();
    formData.organizationId = modelGet(this.model, 'organization.id');
    formData.deleteDBAs = _.reduce(
      this.currentDBAs.collection.toJSON(),
      function(memo, dba) {
        if (dba.shouldDelete) {
          memo.push(dba.id);
        }
        return memo;
      },
      []
    );
    formData.newDBAs = dbas;
    return formData;
  },

  showDuplicateCRDConfirmationModal: function() {
    var formData = this.getFormData();
    var crdNumber = formData.CRDNumber;
    return UIUtil.showModal({
      title: 'Duplicate CRD Number ' + crdNumber,
      view: new ConfirmationModalView({
        confirmButtonClass: 'collapse',
        rejectText: 'Close',
        model: new Backbone.Model({
          message:
            'Another organization already exists with CRD Number ' +
            crdNumber +
            '. Please ensure that the organization you are attempting to give a new CRD Number to does not already exist before proceeding.'
        })
      })
    });
  }
});
