/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');
var Ladda = require('ladda');

var BreadcrumbView = require('common/views/BreadcrumbView');
var EntityHeaderBarView = require('../../common/views/EntityHeaderBarView');

var NonDepEntityFormLocationView = require('../../entity-form/views/NonDepEntityFormLocationView');
var SubmitNewEntityLocation = require('../services/SubmitNewEntityLocation');

require('../styles/NonDepEntityNewLocationView.css');
var tmplNonDepEntityNewLocationView = require('../templates/NonDepEntityNewLocationView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepEntityNewLocationView,

  className: 'nondep-new-location-view container-fluid',

  ui: {
    headerBar: '.header-bar-container',
    breadcrumb: '.breadcrumb-container',
    newLocationContainer: '.new-location-container',
    submitButton: '.submit-button'
  },

  regions: {
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb',
    newLocationContainer: '@ui.newLocationContainer'
  },

  events: {
    'click @ui.submitButton': 'onClickSubmit'
  },

  templateHelpers: function() {
    return {
      viewMode: this.options.viewMode
    };
  },

  onBeforeShow: function() {
    var viewMode = this.options.viewMode;
    var entityId = this.model.get('entityId');
    var entityName = this.model.get('entityName');

    var breadcrumbs = [
      {
        text: 'Home'
      },
      {
        text: entityName
      },
      {
        text: 'Add New Location'
      }
    ];
    if (viewMode === 'int') {
      breadcrumbs[0].link = '#nondep/dashboard';
      breadcrumbs[1].link = '#nondep/entity/' + entityId + '/dashboard';
    } else {
      breadcrumbs[0].link = '#ext/nondep/dashboard';
      breadcrumbs[1].link = '#ext/nondep/entity/' + entityId + '/dashboard';
    }

    this.showChildView(
      'headerBar',
      new EntityHeaderBarView({
        model: this.model
      })
    );

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: breadcrumbs
      })
    );

    this.entityFormLocationView = new NonDepEntityFormLocationView({
      model: new Backbone.Model()
    });

    this.showChildView('newLocationContainer', this.entityFormLocationView);
  },

  onClickSubmit: function(e) {
    e.preventDefault();

    var self = this;

    this.ui.newLocationContainer
      .parsley()
      .whenValidate()
      .done(function() {
        var laddaContext = Ladda.create(self.ui.submitButton.get(0));
        laddaContext.start();

        var formData = self.entityFormLocationView.getFormData();

        formData.entityId = self.model.get('entityId');
        SubmitNewEntityLocation(formData)
          .done(function() {
            if (self.options.viewMode === 'int') {
              Radio.channel('navigate').trigger('show:int:nd:entity-dashboard', self.model.get('entityId'));
            } else {
              Radio.channel('navigate').trigger('show:ext:nd:entity-dashboard', self.model.get('entityId'));
            }
          })
          .fail(function() {
            laddaContext.stop();
          });
      });
  }
});
