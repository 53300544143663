/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Radio = require('backbone.radio');
var moment = require('moment');
var Ladda = require('ladda');

var PaymentScheduleGridView = require('../../../common/views/PaymentScheduleGridView');
var SubmitAdminCreateEnforcementCaseLogTask = require('../../services/SubmitAdminCreateEnforcementCaseLogTask');
var ComplianceJumpToDropdownView = require('../../views/ComplianceJumpToDropdownView');

require('./ComplianceAdminCreateEnforcementCaseLogView.css');
var tmplComplianceAdminCreateEnforcementCaseLogView = require('./ComplianceAdminCreateEnforcementCaseLogView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplComplianceAdminCreateEnforcementCaseLogView,

  className: 'non-dep-compliance-admin-create-enforcement-case-log-view container-fluid',

  ui: {
    headerBar: '.header-bar-container',
    breadcrumb: '.breadcrumb-container',
    jumpTo: '.jump-to-container',
    summary: '.summary-container',
    enforcementCaseLogForm: '.enforcement-case-log-form',
    addPayment: '.btn-add-payment',
    paymentSchedule: '.payment-schedule-container',
    paymentScheduleError: '.payment-schedule-error-container',
    dateOfFirstPayment: '.date-of-first-payment',
    dateOfLastPayment: '.date-of-last-payment',
    isFineRequired: '[name="isFineRequired"]',
    submit: '.btn-submit'
  },

  regions: {
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb',
    jumpTo: '@ui.jumpTo',
    summary: '@ui.summary',
    paymentSchedule: '@ui.paymentSchedule'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  behaviors: {
    TaskBehavior: {},
    ToggleBehavoir: {
      toggles: [
        {
          target: '.payment-details',
          group: '[name="isFineRequired"]',
          valuesToShow: '1'
        }
      ]
    }
  },

  onBeforeShow: function() {
    var compliance = this.model.get('complianceDetails') || {};

    this.showChildView('headerBar', this.options.complianceHeaderBar);

    this.showChildView(
      'jumpTo',
      new ComplianceJumpToDropdownView({
        model: new Backbone.Model({
          compliance: compliance
        })
      })
    );

    this.showChildView('breadcrumb', this.options.complianceBreadcrumbs);

    this.showChildView('summary', this.options.complianceSummary);

    this.paymentGrid = new PaymentScheduleGridView({
      emptyText: 'No Payment Scheduled',
      collection: new Backbone.Collection(this.model.get('scheduledPayments') || []),
      isReadOnly: false,
      mode: 'task'
    });
    this.showChildView('paymentSchedule', this.paymentGrid);

    this.ui.dateOfFirstPayment.datepicker();
    this.ui.dateOfLastPayment.datepicker();
  },

  validate: function() {
    var validationArr = [this.ui.enforcementCaseLogForm.parsley().whenValidate()];
    var isFineRequired = this.ui.isFineRequired.val() === '1';
    if (isFineRequired) {
      validationArr.push(this.paymentScheduledValidation());
    }
    return $.when.apply($, validationArr);
  },

  paymentScheduledValidation: function() {
    var defer = $.Deferred();
    var numPaymentsScheduled = this.paymentGrid.collection.length;

    if (numPaymentsScheduled === 0) {
      this.ui.paymentScheduleError.show();
      defer.reject();
    } else {
      defer.resolve();
      this.ui.paymentScheduleError.hide();
    }

    return defer.promise();
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);

    formData.firstPaymentDate = formData.firstPaymentDate
      ? moment(formData.firstPaymentDate, 'MM-DD-YYYY').format('YYYY-MM-DD')
      : null;
    formData.lastPaymentDate = formData.lastPaymentDate
      ? moment(formData.lastPaymentDate, 'MM-DD-YYYY').format('YYYY-MM-DD')
      : null;

    formData.taskId = this.model.get('task').taskId;
    formData.complianceId = this.model.get('complianceDetails').complianceId;

    formData.paymentSchedule = _.map(this.paymentGrid.collection.toJSON(), function(payment) {
      return payment;
    });

    return formData;
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    this.validate().done(
      function() {
        var laddaContext = Ladda.create(this.ui.submit.get(0));
        laddaContext.start();
        SubmitAdminCreateEnforcementCaseLogTask(this.getFormData()).done(function() {
          Radio.channel('navigate').trigger('show:int:nd:dashboard');
        });
      }.bind(this)
    );
  }
});
