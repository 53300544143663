/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var moment = require('moment');

module.exports = function(formData) {
  var examDate = moment(formData.examDate, 'MM/DD/YYYY').format('YYYY-MM-DD');
  var loanLogDueDate = null;
  if (formData.loanLogDueDate) {
    loanLogDueDate = moment(formData.loanLogDueDate, 'MM/DD/YYYY').format('YYYY-MM-DD');
  }
  var examMaterialsDueDate = null;
  if (formData.examMaterialsDueDate) {
    examMaterialsDueDate = moment(formData.examMaterialsDueDate, 'MM/DD/YYYY').format('YYYY-MM-DD');
  }

  return $.soap({
    body: {
      SubmitUpdateExamDate: {
        $xmlns: 'http://dobs.pa.gov/nd/exam/bpm/1.0',
        ExamDate: examDate,
        LoanLogDueDate: loanLogDueDate,
        ExamMaterialsDueDate: examMaterialsDueDate,
        ID: formData.id
      }
    }
  });
};
