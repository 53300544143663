/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Coulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remai
 * the property of Counterpoint Coulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Coulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Coulting Incorporated.
 */

module.exports = function(formData) {
  var defer = $.Deferred();

  $.soap({
    body: {
      SubmitTimesheetStatus: {
        $xmlns: 'http://dobs.pa.gov/tt/timesheet/bpm/1.0',
        TimesheetID: formData.timesheetId,
        StatusID: formData.statusId
      }
    }
  })
    .done(function() {
      defer.resolve();
    })
    .fail(function(err) {
      defer.reject(err);
    });

  return defer.promise();
};
