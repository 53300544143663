/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(filingId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetFilingInternalDetails: {
        $xmlns: 'http://dobs.pa.gov/dp/filing/bpm/1.0',
        FilingID: filingId
      }
    },
    blueprint: {
      filingId: '$..ID',
      checklistDocId: '$..ChecklistDocID',
      checklistCSId: '$..ChecklistCSID',
      analysisMemoDocId: '$..AnalysisMemoDocID',
      analysisMemoCSId: '$..AnalysisMemoCSID',
      decisionLetterDocId: '$..DecisionLetterDocID',
      decisionLetterCSId: '$..DecisionLetterCSID',
      isPendingItems: '$..IsAcceptancePendingItems',
      legalReviewer: '$..LegalReviewer',
      legalSentDate: '$..LegalSentDate',
      tempReviewer: '$..TempReviewer',
      tempReviewerSentDate: '$..TempReviewerSentDate',
      currentReviewerRoleId: '$..CurrentReviewerRoleID',
      currentReviewerRoleTypeId: '$..CurrentReviewerRoleTypeID',
      currentReviewerRoleType: '$..CurrentReviewerRoleType',
      isCurrentReviewerLegal: '$..IsCurrentReviewerLegal',
      isCurrentReviewerTemp: '$..IsCurrentReviewerTemp',
      reviewRecommendationId: '$..ReviewRecommendationID',
      reviewRecommendation: '$..ReviewRecommendation',
      reviewDecisionId: '$..ReviewDecisionID',
      reviewComments: '$..ReviewDecisionComments'
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject(err);
    });

  return deferred.promise();
};
