/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');

var tmplDepTrackingDetailsSectionView = require('../templates/DepTrackingDetailsSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepTrackingDetailsSectionView,

  className: 'dep-tracking-details-section-view container-fluid',

  ui: {
    ceclSection: '.cecl-container',
    mrbSection: '.mrb-container',
    subDebtSection: '.sub-debt-container',
    redFlagSection: '.red-flag-container',
    keyThirdPartyVendorSection: '.key-third-party-vendor-container',
    bankRedFlagSection: '.bank-red-flag-container',
    creditUnionRedFlagSection: '.credit-union-red-flag-container'
  },

  onBeforeShow: function() {
    var tracking = this.model.get('entityTracking') || {};
    var entityCategoryId = this.model.get('entityCategoryId');

    // Show sections by tracking type
    var ceclTypeIds = ['1', '11'];
    if (_.contains(ceclTypeIds, tracking.trackingTypeId)) {
      this.ui.ceclSection.show();
    }

    var mrbTypeIds = ['2', '12', '21'];
    if (_.contains(mrbTypeIds, tracking.trackingTypeId)) {
      this.ui.mrbSection.show();
    }

    var subDebtTypeIds = ['3'];
    if (_.contains(subDebtTypeIds, tracking.trackingTypeId)) {
      this.ui.subDebtSection.show();
    }

    var redFlagTypeIds = ['4', '13', '23'];
    if (_.contains(redFlagTypeIds, tracking.trackingTypeId)) {
      this.ui.redFlagSection.show();
      if (entityCategoryId === '1') {
        this.ui.bankRedFlagSection.show();
      } else if (entityCategoryId === '2' || entityCategoryId === '3') {
        this.ui.creditUnionRedFlagSection.show();
      }
    }

    var keyThirdPartyVendorTypeIds = ['5', '14', '22'];
    if (_.contains(keyThirdPartyVendorTypeIds, tracking.trackingTypeId)) {
      this.ui.keyThirdPartyVendorSection.show();
    }
  }
});
