/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Radio = require('backbone.radio');
var Ladda = require('ladda');
var _ = require('underscore');

var momentUtil = require('common/util/momentUtil');
var modelGet = require('common/util/modelGet');

var BreadcrumbView = require('common/views/BreadcrumbView');
var SecExamHeaderBarView = require('./SecExamHeaderBarView');

var SubmitExamNextSteps = require('../../common/services/SubmitExamNextSteps');

var tmplSecExamNextStepEditView = require('../templates/SecExamNextStepEditView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplSecExamNextStepEditView,

  className: 'sec-exam-next-step-edit-view container-fluid',

  ui: {
    headerBar: '.header-bar-container',
    breadcrumb: '.breadcrumb-container',
    examNextStepsForm: '.exam-next-steps-form',
    datePickerField: '.date-picker',
    submit: '.submit'
  },

  regions: {
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  behaviors: {
    ToggleBehavoir: {
      toggles: [
        {
          target: '.next-step-form',
          group: '[name="hasNextStep"]',
          valuesToShow: ['1']
        }
      ]
    }
  },

  templateHelpers: function() {
    var users = modelGet(this.model, 'users', []);
    var user = modelGet(this.model, 'exam.nextStepAssignedTo');
    var userFirstName = modelGet(this.model, 'exam.nextStepAssignedToFirstName');
    var userLastName = modelGet(this.model, 'exam.nextStepAssignedToLastName');

    /*
     * Current users could no longer have user role, add current user for
     * each area with internal user data if username does not exist in
     * users array
     */
    var currentUserHasRole = _.find(users, function(cm) {
      return user === cm.userId;
    });

    if (user && !currentUserHasRole) {
      var currentuser = {
        userId: user,
        name: !userFirstName && !userLastName ? '' : userFirstName + ' ' + userLastName
      };
      users.splice(_.sortedIndex(users, currentuser, 'name'), 0, currentuser);
    }

    return {
      users: users
    };
  },

  onBeforeShow: function() {
    var examIdentifiers = this.model.get('examIdentifiers') || {};
    var exam = this.model.get('exam') || {};
    var examId = this.model.get('examId');

    this.showChildView('headerBar', new SecExamHeaderBarView({ model: new Backbone.Model(examIdentifiers) }));

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#sec/dashboard',
            text: 'Home'
          },
          {
            link: '#sec/organization/' + examIdentifiers.organizationId + '/dashboard',
            text: examIdentifiers.applicantLegalName
          },
          {
            link:
              '#sec/organization/' +
              examIdentifiers.organizationId +
              '/license/' +
              examIdentifiers.organizationLicenseId +
              '/dashboard',
            text: examIdentifiers.licenseType
          },
          {
            link: '#sec/exam/' + examId + '/dashboard',
            text: 'Exam - ' + examIdentifiers.examNumber
          },
          {
            text: 'Edit Exam'
          }
        ]
      })
    );

    Syphon.deserialize(this, exam);

    this.ui.datePickerField.datepicker({});
  },

  onClickSubmit: function(event) {
    event.preventDefault();
    var self = this;
    this.validate().done(function() {
      var l = Ladda.create(self.ui.submit[0]);
      l.start();
      var formData = self.getFormData();
      SubmitExamNextSteps(formData)
        .done(function() {
          Radio.channel('navigate').trigger('show:int:sc:exam-dashboard', self.model.get('examId'));
        })
        .fail(function() {
          l.stop();
        });
    });
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);

    formData.examId = this.model.get('examId');

    formData.nextStepProjectedDate = momentUtil.formatStr(
      formData.nextStepProjectedDate,
      momentUtil.defaultOutboundDateConfig
    );

    return formData;
  },

  validate: function() {
    return this.ui.examNextStepsForm.parsley().whenValidate();
  }
});
