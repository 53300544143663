/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var _get = require('lodash.get');

module.exports = function(formData) {
  return $.soap({
    body: {
      SubmitEntityExamInfoTask: {
        $xmlns: 'http://dobs.pa.gov/dp/task/bpm/1.0',
        ExamID: formData.examId,
        TaskID: formData.taskId,
        Documents: (function() {
          if (!_get(formData, 'documents.length')) {
            return {};
          }
          return _.map(formData.documents, function(document) {
            return {
              DocumentID: document.fileId
            };
          });
        })()
      }
    }
  });
};
