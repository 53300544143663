/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');

var GridView = require('psa-marionette/views/GridView');
var PageableCollection = require('psa-backbone/models/PageableCollection');

var MomentCell = require('common/backgrid/MomentCell');

var tmplNonDepEntityNameHistoryView = require('../templates/NonDepEntityNameHistoryView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepEntityNameHistoryView,

  className: 'non-dep-entity-name-history-view container-fluid',

  ui: {
    entityNameHistory: '.entity-name-history-container'
  },

  regions: {
    entityNameHistory: '@ui.entityNameHistory'
  },

  onBeforeShow: function() {
    this.entityNameHistory = new GridView({
      columns: [
        {
          name: 'effectiveDate',
          label: 'Effective Date',
          cell: MomentCell.MomentCell,
          formatter: MomentCell.MomentDateFormatter.extend({
            emptyValue: '--'
          }),
          sortValue: MomentCell.MomentSortValue,
          headerCell: 'custom',
          width: 33,
          editable: false
        },
        {
          name: 'createdBy',
          label: 'Changed By',
          cell: 'string',
          headerCell: 'custom',
          width: 33,
          editable: false
        },
        {
          name: 'entityName',
          label: 'Name',
          cell: 'string',
          headerCell: 'custom',
          width: 34,
          editable: false
        },
      ],
      emptyText: 'No Name History',
      collection: new PageableCollection(this.model.get('nameHistory'), { pageSize: 25 })
    });

    this.showChildView('entityNameHistory', this.entityNameHistory);
  }
});
