/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var GridView = require('psa-marionette/views/GridView');
var PageableCollection = require('psa-backbone/models/PageableCollection');

var modelGet = require('common/util/modelGet');

var tmplSecOrganizationDetailsSectionView = require('../templates/SecOrganizationDetailsSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplSecOrganizationDetailsSectionView,

  className: 'sec-organization-details-section-view',

  ui: {
    dba: '.doing-business-as'
  },

  regions: {
    dba: '@ui.dba'
  },

  onBeforeShow: function() {
    var dbas = modelGet(this.model, 'organization.dbas');

    Syphon.deserialize(this, this.model.toJSON());

    this.showChildView(
      'dba',
      new GridView({
        columns: [
          {
            name: 'dba',
            label: 'Organization DBA Name',
            cell: 'string',
            headerCell: 'custom',
            width: 100,
            editable: false
          }
        ],
        collection: new PageableCollection(dbas, { pageSize: 10 }),
        emptyText: 'Organization has no D/B/A registrations.'
      })
    );
  }
});
