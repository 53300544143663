/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');

var PlaceholderView = require('common/views/PlaceholderView');

var GetIndividualByIdAndIndividualType = require('../services/GetIndividualByIdAndIndividualType');

var ConSerIndividualDisplayView = require('../views/ConSerIndividualDisplayView');

module.exports = {
  channel: 'cs',

  event: 'build:individual-display-view',

  builder: function(individualId, individualTypeId) {
    return new PlaceholderView({
      viewPromise: buildIndividualDisplayView(individualId, individualTypeId)
    });
  }
};

function buildIndividualDisplayView(individualId, individualTypeId) {
  var deferred = $.Deferred();
  GetIndividualByIdAndIndividualType(individualId, individualTypeId)
    .done(function(individual) {
      deferred.resolve(
        new ConSerIndividualDisplayView({
          model: new Backbone.Model(individual)
        })
      );
    })
    .fail(function(err) {
      deferred.reject(err);
    });

  return deferred.promise();
}
