/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');
var Ladda = require('ladda');
var Radio = require('backbone.radio');
var moment = require('moment');
var Syphon = require('backbone.syphon');

var Session = require('psa-core/Session');

var modelGet = require('common/util/modelGet');
var BreadcrumbView = require('common/views/BreadcrumbView');
var AddressDisplayView = require('common/views/AddressDisplayView');
var FileUploadView = require('common/views/FileUploadView');
var ContentServerLinkView = require('common/views/ContentServerLinkView');

var ExamHeaderBarView = require('../../../common/views/ExamHeaderBarView');
var SubmitExaminerSetUpExamTask = require('../../services/SubmitExaminerSetUpExamTask');

var tmplExaminerSetUpExamTaskView = require('./ExaminerSetUpExamTaskView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplExaminerSetUpExamTaskView,

  className: 'examiner-set-up-exam-task-view container-fluid',

  ui: {
    headerBar: '.header-bar',
    csLinkContainer: '.cs-link-container',
    breadcrumb: '.breadcrumb-container',
    isSetUpConsolidated: '.is-set-up-consolidated',
    consolidatedLetterContainer: '.consolidated-letter-container',
    addConsolidatedLetter: '.add-consolidated-letter',
    noFileAlert: '#noFileAlert',
    setUpExamForm: '.set-up-exam-form',
    submit: '.submit',
    examDate: '.exam-date',
    isOffsite: '.is-offsite',
    loanLogsDueDate: '.loan-logs-due-date',
    examMaterialsDueDate: '.exam-materials-due-date',
    locationAddress: '.location-address',
    contactPhone: '.contact-phone',
    contactEmail: '.contact-email',
    examContactId: '#examContactId',
    coverageStartDate: '.coverage-start-date',
    coverageEndDate: '.coverage-end-date'
  },

  regions: {
    headerBar: '@ui.headerBar',
    csLinkContainer: '@ui.csLinkContainer',
    breadcrumb: '@ui.breadcrumb',
    locationAddress: '@ui.locationAddress',
    consolidatedLetterContainer: '@ui.consolidatedLetterContainer'
  },

  events: {
    'click @ui.submit': 'onClickSubmit',
    'change @ui.examContactId': 'onChangeExamContactId',
    'change @ui.examDate': 'onChangeExamDate',
    'change @ui.isOffsite': 'onChangeIsOffsite'
  },

  behaviors: {
    TaskBehavior: {},
    RadioGroupToggleBehavior: {
      radios: [
        {
          target: '.add-consolidated-letter',
          radioGroup: 'input[name="isSetUpLetterConsolidated"]',
          valueToShow: '1'
        }
      ]
    }
  },

  templateHelpers: function() {
    var exam = this.model.get('exam') || {};
    var hasEntityExamTask = exam.hasExamPolicyProcedures || exam.hasExamLoanLogs;
    return {
      hasEntityExamTask: hasEntityExamTask
    };
  },

  onBeforeShow: function() {
    var exam = this.model.get('exam') || {};

    this.showChildView(
      'headerBar',
      new ExamHeaderBarView({
        model: new Backbone.Model(_.extend(this.model.get('exam'), { title: 'Exam' }))
      })
    );

    this.showChildView(
      'csLinkContainer',
      new ContentServerLinkView({
        model: new Backbone.Model({
          buttonText: 'Open Exam Folder',
          contentServerId: exam.CSFolderId
        })
      })
    );

    this.consolidatedLetterContainer = new FileUploadView({
      isRequired: true,
      metadata: {
        documentTypeId: 10008
      }
    });
    this.showChildView('consolidatedLetterContainer', this.consolidatedLetterContainer);

    var examLeadTimeDays = exam.examLeadTimeDays;
    var date = examLeadTimeDays ? moment().add(examLeadTimeDays, 'days') : moment();
    date = date.format('MM/DD/YYYY');
    this.ui.examDate.datepicker('setDate', date);

    this.ui.coverageStartDate.datepicker();
    this.ui.coverageEndDate.datepicker();

    this.setEntityExamTaskDueDates();

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#nondep/dashboard',
            text: 'Home'
          },
          {
            text: modelGet(this.model, 'task.taskType.description')
          }
        ]
      })
    );
  },

  onChangeExamContactId: function(e) {
    e.preventDefault();
    if (this.ui.examContactId.val()) {
      var currentContact = _.findWhere(this.model.get('examContacts'), { id: this.ui.examContactId.val() });
      if (currentContact.cellPhoneNumber) {
        this.ui.contactPhone.text(currentContact.cellPhoneNumber);
      }
      if (currentContact.email) {
        this.ui.contactEmail.text(currentContact.email);
      }
      this.showChildView(
        'locationAddress',
        new AddressDisplayView({
          model: new Backbone.Model(currentContact.address)
        })
      );
    } else {
      this.ui.contactPhone.text('--');
      this.ui.contactEmail.text('--');
      this.ui.locationAddress.text('--');
    }
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;
    this.validate().done(function() {
      var l = Ladda.create(self.ui.submit[0]);
      l.start();
      var formData = Syphon.serialize(self);

      var data = {
        taskId: self.model.get('task').taskId,
        examId: self.model.get('exam').examId,
        formData: formData
      };

      if (formData.isSetUpLetterConsolidated === '1') {
        _.extend(data, {
          consolidatedLetterId: self.consolidatedLetterContainer.getFirstFileId()
        });
      }

      SubmitExaminerSetUpExamTask(data)
        .done(function() {
          Radio.channel('navigate').trigger('show:int:nd:dashboard');
        })
        .fail(function() {
          l.stop();
        });
    });
  },

  onChangeExamDate: function() {
    this.setEntityExamTaskDueDates();
  },

  onChangeIsOffsite: function() {
    this.setEntityExamTaskDueDates();
  },

  validate: function(config) {
    this.ui.setUpExamForm.parsley().reset();

    if (this.ui.isSetUpConsolidated.filter(':checked').val() === '1') {
      return $.when(this.ui.setUpExamForm.parsley().whenValidate(), this.consolidatedLetterContainer.validate());
    } else {
      return this.ui.setUpExamForm.parsley().whenValidate();
    }
  },

  setEntityExamTaskDueDates: function() {
    var exam = this.model.get('exam') || {};
    var licenseTypeId = exam.licenseTypeId;
    var isMoneyTransmitterLicense = licenseTypeId === '16';

    // The default value of the loan logs due date is defined by a Session
    // constant, which as of Oct 2020 is set to be 10 days after the exam set
    // up date, which is interpreted as today.
    if (exam.hasExamLoanLogs) {
      var loanLogsDueDateOffset = Session.config.ND_EXAM_LOAN_LOGS_PERIOD;
      var defaultLoanLogsDueDate = moment()
        .add(loanLogsDueDateOffset, 'days')
        .format('MM/DD/YYYY');
      this.ui.loanLogsDueDate.datepicker('setDate', defaultLoanLogsDueDate);
    }

    // The default value of the exam materials due date is defined by a Session
    // constant, which as of Oct 2020 is set to be as 15 days before the exam
    // date. For money transmitter licenses, the value is also defined by a
    // Session constant, and as of Oct 2020, is set to be 30 days before the
    // exam date.
    if (exam.hasExamPolicyProcedures) {
      var examMaterialsDueDateOffset = Session.config.ND_EXAM_PREP_MATERIALS_GENERIC_PERIOD;
      if (isMoneyTransmitterLicense) {
        examMaterialsDueDateOffset = Session.config.ND_EXAM_PREP_MATERIALS_MT_PERIOD;
      }

      var rawExamDate = this.ui.examDate.val();
      var rawOnsiteOffsite = this.ui.isOffsite.val();
      var examDate = moment(rawExamDate, 'MM/DD/YYYY');
      if (rawOnsiteOffsite && rawExamDate && examDate.isValid()) {
        var isOnsite = rawOnsiteOffsite === '0';
        var defaultExamMaterialsDueDate;
        if (isOnsite) {
          defaultExamMaterialsDueDate = examDate.clone().format('MM/DD/YYYY');
        } else {
          defaultExamMaterialsDueDate = examDate
            .clone()
            .subtract(examMaterialsDueDateOffset, 'days')
            .format('MM/DD/YYYY');
        }
        this.ui.examMaterialsDueDate.datepicker('setDate', defaultExamMaterialsDueDate);
      } else {
        this.ui.examMaterialsDueDate.datepicker({});
      }
    }
  }
});
