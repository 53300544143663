/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');

var UIUtil = require('psa-core/util/ui');

var ConfirmationModalView = require('common/views/ConfirmationModalView');
var SideNavSectionView = require('common/views/SideNavSectionView');
var BreadcrumbView = require('common/views/BreadcrumbView');
var DropdownView = require('common/views/DropdownView');
var ContentServerLinkView = require('common/views/ContentServerLinkView');

var DepFundDetailsSectionView = require('./DepFundDetailsSectionView');
var DeleteTrustFund = require('../services/DeleteTrustFund');

var tmplDepFundDashboardView = require('../templates/DepFundDashboardView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepFundDashboardView,

  className: 'dep-fund-dashboard-view container-fluid',

  ui: {
    fundWrapper: '.fund-wrapper',
    fundActionDropdown: '.dropdown-container',
    csLinkContainer: '.cs-link-container',
    breadcrumb: '.breadcrumb-container'
  },

  regions: {
    fundWrapper: '@ui.fundWrapper',
    fundActionDropdown: '@ui.fundActionDropdown',
    csLinkContainer: '@ui.csLinkContainer',
    breadcrumb: '@ui.breadcrumb'
  },

  templateHelpers: function() {
    var fund = this.model.get('fund') || {};
    var hasRelatedFiling = !!fund.filingId;
    return {
      hasRelatedFiling: hasRelatedFiling
    };
  },

  onBeforeShow: function() {
    var fund = this.model.get('fund') || {};
    var entityId = fund.entityId;
    var entityName = fund.entityName;
    var fundId = fund.fundId;
    var fundName = fund.name;
    var isTrust = fund.isTrust;

    var dropDownOptions = [
      {
        text: 'Edit Fund',
        link: '#dep/entity/' + entityId + '/fund/' + fundId + '/edit'
      },
      {
        text: 'Delete Fund',
        onClick: this.onClickDelete.bind(this)
      },
      {
        isDivider: true
      },
      {
        text: 'Add Sub-Fund',
        link: '#dep/entity/' + entityId + '/fund/' + fundId + '/new-sub-fund'
      },
      {
        text: 'Add Fund Audit',
        link: '#dep/entity/' + entityId + '/fund/' + fundId + '/new-audit'
      }
    ];

    this.showChildView(
      'fundActionDropdown',
      new DropdownView({
        rightAlign: true,
        model: new Backbone.Model({
          name: 'Fund Actions',
          options: dropDownOptions
        })
      })
    );

    this.fund = new SideNavSectionView({
      collection: new Backbone.Collection([
        {
          id: 'fundDetails',
          title: 'Fund Details',
          view: new DepFundDetailsSectionView({
            model: new Backbone.Model(this.model.get('fund'))
          })
        },
        {
          id: 'subFunds',
          title: 'Sub-Funds',
          view: Radio.channel('dp').request('build:fund-sub-fund-view', fundId, isTrust)
        },
        {
          id: 'audits',
          title: 'Audits',
          view: Radio.channel('dp').request('build:fund-audit-view', fundId)
        },
        {
          id: 'notes',
          title: 'Notes',
          view: Radio.channel('dp').request('build:notes-view', {
            objectId: fundId,
            objectTypeId: 'CIF-FUND'
          })
        },
        {
          id: 'auditLog',
          title: 'Audit Log',
          view: Radio.channel('dp').request('build:audit-log-view', {
            objectId: fundId,
            objectTypeId: 'CIF-FUND'
          })
        }
      ])
    });
    this.showChildView('fundWrapper', this.fund);

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#dep/dashboard',
            text: 'Home'
          },
          {
            link: '#dep/entity/' + entityId + '/dashboard',
            text: entityName
          },
          {
            text: fundName
          }
        ]
      })
    );

    this.showChildView(
      'csLinkContainer',
      new ContentServerLinkView({
        model: new Backbone.Model({
          buttonText: 'Open CIF/CTF Folder',
          contentServerId: this.model.get('csFolderId')
        })
      })
    );
  },

  onClickDelete: function() {
    var fund = this.model.get('fund');
    UIUtil.showModal({
      title: 'Confirm Deleting Fund',
      view: new ConfirmationModalView({
        confirmText: 'Delete Fund',
        model: new Backbone.Model({
          message: 'Are you sure you want to delete this collective investment fund?',
          onConfirm: function() {
            return DeleteTrustFund(fund.fundId);
          },
          onConfirmDone: function() {
            Radio.channel('navigate').trigger('show:int:dp:entity', fund.entityId);
          }
        })
      })
    });
  }
});
