/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Backbone = require('backbone');
var Marionette = require('backbone.marionette');

var GridView = require('psa-marionette/views/GridView');
var PageableCollection = require('psa-backbone/models/PageableCollection');
var UIUtil = require('psa-core/util/ui');

var MultiButtonCell = require('common/backgrid/MultiButtonCell');
var ConfirmationModalView = require('common/views/ConfirmationModalView');
var MomentCell = require('common/backgrid/MomentCell');
// var ParagraphCell = require('common/backgrid/ParagraphCell/ParagraphCell.js');

var SubmitLicenseBond = require('../services/SubmitLicenseBond');

var tmplNonDepLicenseBondsView = require('../templates/NonDepLicenseBondsView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepLicenseBondsView,

  className: 'non-dep-license-bonds-view',

  ui: {
    licenseBondsGridContainer: '.license-bonds-grid-container'
  },

  regions: {
    licenseBondsGridContainer: '@ui.licenseBondsGridContainer'
  },

  onBeforeShow: function() {
    // Fill in data with display values.
    var bonds = _.map(this.collection.toJSON(), function(bond) {
      bond.licenseRequirement = bond.licenseRequirement === '1' ? 'Yes' : 'No';
      bond.bondName = bond.bondName || bond.bondTypeOther || '--';
      bond.bondNumber = bond.bondNumber || '--';
      bond.bondAmount = bond.bondAmount || '--';
      bond.type = bond.type || '--';
      bond.status = bond.status || '--';
      return bond;
    });

    this.bondsGrid = new GridView({
      columns: [
        {
          name: 'bondName',
          label: 'Bond Company',
          cell: 'string',
          headerCell: 'custom',
          width: 25,
          editable: false
        },
        {
          name: 'bondNumber',
          label: 'Bond #',
          cell: 'string',
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'type',
          label: 'Type',
          cell: 'string',
          headerCell: 'custom',
          width: 15,
          editable: false
        },
        {
          name: 'expiration',
          label: 'Expiration Date',
          cell: MomentCell.MomentCell,
          formatter: MomentCell.MomentDateFormatter.extend({
            emptyValue: '--'
          }),
          sortValue: MomentCell.MomentSortValue,
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'requiredTo',
          label: 'Required To',
          cell: MomentCell.MomentCell,
          formatter: MomentCell.MomentDateFormatter.extend({
            emptyValue: '--'
          }),
          sortValue: MomentCell.MomentSortValue,
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'status',
          label: 'Status',
          cell: 'string',
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'licenseRequirement',
          label: 'License Requirements',
          cell: 'string',
          headerCell: 'custom',
          width: 10,
          editable: false
        }
      ],
      collection: new PageableCollection(bonds, { pageSize: 10 }),
      emptyText: 'No License Bonds Provided'
    });

    if (this.options.viewMode === 'int') {
      // Add edit/delete buttons in grid for internal users
      this.bondsGrid.options.columns.push({
        name: 'editDeleteCell',
        label: '',
        width: 10,
        cell: MultiButtonCell.extend({
          buttons: [
            {
              name: '',
              labelClass: 'fa fa-edit',
              buttonClass: 'btn-primary btn-sm edit-inv-history',
              href: function(model) {
                return (
                  '#nondep/entity/' +
                  model.get('entityId') +
                  '/license/' +
                  model.get('licenseID') +
                  '/edit-bond/' +
                  model.get('bondId')
                );
              }
            },
            {
              name: '',
              labelClass: 'fa fa-trash',
              buttonClass: 'btn-danger btn-sm',
              onClick: this.onCLickRemove.bind(this)
            }
          ]
        }),
        sortable: false,
        editable: false
      });
    }

    this.showChildView('licenseBondsGridContainer', this.bondsGrid);
  },

  onCLickRemove: function(e, bondModel) {
    e.preventDefault();
    var self = this;

    var bondId = bondModel.get('bondId');
    var bondName = bondModel.get('bondName');
    var appData = {
      bondId: bondId,
      bond: {
        isDeleted: '1'
      }
    };

    UIUtil.showModal({
      title: 'Confirm Deletion of Bond',
      view: new ConfirmationModalView({
        model: new Backbone.Model({
          message: 'Are you sure you want to remove this bond: ' + bondName + '?',
          onConfirm: function() {
            return SubmitLicenseBond(appData);
          },
          onConfirmDone: function() {
            self.bondsGrid.collection.remove(bondModel);
          }
        })
      })
    });
  }
});
