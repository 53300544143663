/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Cookie = require('js-cookie');

var Session = require('psa-core/Session');

var PayeezyView = require('common/views/PayeezyView');
var Config = require('../../../config/Config');

var UpdatePayment = require('../services/UpdatePayment');

module.exports = {
  channel: 'dp',

  event: 'build:payeezy-view',

  builder: function(data) {
    var deferred = $.Deferred();

    UpdatePayment(data)
      .done(function(result) {
        var payeezyModel = new Backbone.Model({
          x_url: Config.getPayeezyUrl,
          x_amount: data.paymentAmount,
          x_currency_code: 'USD',
          x_login: result.x_login,
          x_fp_sequence: result.x_fp_sequence,
          x_fp_timestamp: result.x_fp_timestamp,
          x_fp_hash: result.x_fp_hash,
          x_invoice_num: result.x_invoice_num,
          merchant_cookie_1: Session.preLoginInfo.SamlArtifactCookieName,
          merchant_cookie_2: Cookie.get(Session.preLoginInfo.SamlArtifactCookieName),
          merchant_cookie_3: Session.preLoginInfo.CheckName,
          merchant_cookie_4: Cookie.get(Session.preLoginInfo.CheckName),
          merchant_cookie_5: Config.getDoBSSessionToken(),
          merchant_cookie_6: Cookie.get(Config.getDoBSSessionToken()),
          merchant_cookie_7: result.taskId || '',
          merchant_cookie_8: result.transactionId,
          paymentObjectType: data.paymentObjectType.toLowerCase(),
          items: data.items
        });

        deferred.resolve(
          new PayeezyView({
            model: payeezyModel
          })
        );
      })
      .fail(function(err) {
        deferred.reject(err);
      });

    return deferred.promise();
  }
};
