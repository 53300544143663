/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var _remove = require('lodash.remove');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(data) {
  var defer = $.Deferred();

  $.soap({
    body: {
      GetExamQuestionResponses: {
        $xmlns: 'http://dobs.pa.gov/nd/exam/bpm/1.0',
        ExamID: data.examId,
        ExamSetupCode: data.examSetupCode
      }
    },
    blueprintArrays: [
      'GetExamQuestionResponsesResponse.ExamQuestionResponses.ND_ExamQuestionResponse',
      'GetExamQuestionResponsesResponse.ExamQuestionResponseDocuments.ND_ExamQuestionResponseDocument'
    ],
    blueprint: {
      examQuestionResponses: [
        '$..ND_ExamQuestionResponse',
        {
          id: '$.ID',
          examId: '$.ExamID',
          examSetupQuestionId: '$.ExamSetupQuestionID',
          isNotApplicable: '$.IsNotApplicable',
          notApplicableReason: '$.NotApplicableReason',
          responseValue: '$.ResponseValue'
        }
      ],
      examQuestionResponseDocuments: [
        '$..ND_ExamQuestionResponseDocument',
        {
          id: '$.ID',
          examId: '$.ExamID',
          examQuestionResponseId: '$.ExamQuestionResponseID',
          examSetupQuestionId: '$.ExamSetupQuestionID',
          file: {
            fileId: '$.DocumentID',
            fileName: '$.DocumentName'
          },
          isRetained: '$.IsRetained',
          documentType: '$.DocumentType',
          documentTypeId: '$.DocumentTypeID'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      _.each(response.examQuestionResponseDocuments, function(document) {
        document.isRetained = document.isRetained === '1';
      });
      _.each(response.examQuestionResponses, function(examQuestionResponse) {
        examQuestionResponse.isNotApplicable = examQuestionResponse.isNotApplicable === '1';
        examQuestionResponse.documents = _remove(response.examQuestionResponseDocuments, function(document) {
          return document.examQuestionResponseId === examQuestionResponse.id;
        });
      });
      defer.resolve(response.examQuestionResponses);
    })
    .fail(function(err) {
      defer.reject();
    });

  return defer.promise();
};
