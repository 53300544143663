/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var tmplDepFundFormView = require('../templates/DepFundFormView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepFundFormView,

  className: 'dep-fund-form-view',

  ui: {
    fundForm: 'form',
    effectiveDate: '.effective-date',
    approvalDate: '.approval-date',
    lastAmendmentDate: '.last-amendment-date',
    lastAmendmentApprovalDate: '.last-amendment-approval-date',
    terminatedDate: '.terminated-date',
    effectiveAmendDate: '.effective-amend-date'
  },

  onBeforeShow: function() {
    Syphon.deserialize(this, this.model.toJSON());
    this.ui.effectiveDate.datepicker();
    this.ui.approvalDate.datepicker();
    this.ui.lastAmendmentDate.datepicker();
    this.ui.lastAmendmentApprovalDate.datepicker();
    this.ui.terminatedDate.datepicker();
    this.ui.effectiveAmendDate.datepicker();
  },

  validate: function() {
    return this.ui.fundForm.parsley().whenValidate();
  },

  getFormData: function() {
    return Syphon.serialize(this);
  }
});
