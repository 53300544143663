var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "\r\n<nav class=\"navbar navbar-default\">\r\n  <div class=\"container-fluid\">\r\n    <div class=\"navbar-header\">\r\n      <button type=\"button\" class=\"navbar-toggle collapsed\" data-toggle=\"collapse\" data-target=\"#bs-example-navbar-collapse-1\" aria-expanded=\"false\">\r\n        <span class=\"sr-only\">Toggle navigation</span>\r\n        <span class=\"icon-bar\"></span>\r\n        <span class=\"icon-bar\"></span>\r\n        <span class=\"icon-bar\"></span>\r\n      </button>\r\n      <a href=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.brand : depth0)) != null ? stack1.link : stack1), depth0))
    + "\" class=\"logo-text brand-link\">\r\n        <img class=\"logo\" src=\"img/logo.png\" alt=\"logo\" />\r\n        <p class=\"navbar-text navbar-brand\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.brand : depth0)) != null ? stack1.text : stack1), depth0))
    + "</p>\r\n      </a>\r\n    </div>\r\n    <div class=\"collapse navbar-collapse\" id=\"int-main-menu\">\r\n      <ul class=\"nav navbar-nav navbar\">\r\n        <li><a href=\"#int/applications\">Program Selection</a></li>\r\n        <li class=\"time-tracking-link-item\">\r\n          <a href=\"#\">\r\n            Time Tracking\r\n          </a>\r\n        </li>\r\n      </ul>\r\n      <ul class=\"nav navbar-nav navbar-right\">\r\n        <li><p class=\"navbar-text welcome-text\">Welcome, "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.description : stack1), depth0))
    + "</p></li>\r\n        <li><a href=\"#\" class=\"logout\">Logout</a></li>\r\n      </ul>\r\n    </div>\r\n  </div>\r\n</nav>\r\n";
},"useData":true});