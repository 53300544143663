/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var bodybuilder = require('bodybuilder');
var Ladda = require('ladda');

var GridView = require('psa-marionette/views/GridView');
var PageableCollection = require('psa-backbone/models/PageableCollection');

var AddressCell = require('common/backgrid/AddressCell');
var ListCell = require('common/backgrid/ListCell');
var ButtonLinkCell = require('common/backgrid/ButtonLinkCell');
var ElasticSearchSummaryView = require('common/views/ElasticSearchSummaryView');
var ConSerInternalSidebarNavView = require('./ConSerInternalSidebarNavView');

var SearchNonDepIndividuals = require('../../../non-depository/common/services/SearchNonDepIndividuals');
var SearchDepPeople = require('../../../depository/common/services/SearchDepPeople');
var SearchSecIndividuals = require('../../../securities/common/services/SearchSecIndividuals');
var SearchConSerIndividuals = require('../../common/services/SearchConSerIndividuals');

require('../styles/ConSerIndividualSearchView.css');
var tmplConSerIndividualSearchView = require('../templates/ConSerIndividualSearchView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplConSerIndividualSearchView,

  className: 'con-ser-individual-search-view container-fluid',

  ui: {
    sideBar: '.side-bar-container',
    department: '[name="departmentId"]',
    searchResultsContainer: '.search-results-container',
    search: '.search',
    clearFilters: '.clear-filters',
    formInputs: ':input[name!="departmentId"]',
    searchSummary: '.search-summary',
    dateOfBirth: '.date-of-birth'
  },

  regions: {
    sideBar: '@ui.sideBar',
    searchResultsContainer: '@ui.searchResultsContainer',
    searchSummary: '@ui.searchSummary'
  },

  events: {
    'change @ui.department': 'onChangeDepartment',
    'click @ui.search': 'onClickSearch',
    'click @ui.clearFilters': 'onClickClearFilters',
    'keydown @ui.formInputs': 'onKeydownOfInputs'
  },

  onBeforeShow: function() {
    this.showChildView('sideBar', new ConSerInternalSidebarNavView());

    this.ui.dateOfBirth.datepicker({
      endDate: '0d',
      startView: 'years'
    });

    this.searchSummaryView = new ElasticSearchSummaryView({
      model: new Backbone.Model({
        searchLimit: this.model.get('searchLimit')
      })
    });
    this.showChildView('searchSummary', this.searchSummaryView);

    this.searchResultsGrid = new GridView({
      columns: [
        {
          name: 'lastName',
          label: 'Last Name',
          cell: 'string',
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'firstName',
          label: 'First Name',
          cell: 'string',
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'title',
          label: 'Title',
          cell: 'string',
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'phoneNumber',
          label: 'Phone',
          cell: 'string',
          width: 10,
          editable: false
        },
        {
          name: 'emails',
          label: 'Email',
          cell: ListCell,
          headerCell: 'custom',
          width: 15,
          editable: false
        },
        {
          name: 'address',
          label: 'Address',
          cell: AddressCell,
          width: 15,
          editable: false,
          sortable: false
        },
        {
          name: 'licenses',
          label: 'Associated Licenses',
          cell: ListCell,
          headerCell: 'custom',
          width: 20,
          editable: false
        },
        {
          name: 'openButton',
          label: '',
          cell: ButtonLinkCell.extend({
            name: 'Open',
            newTab: true,
            buttonClass: 'btn-primary btn-sm',
            href: function(model) {
              var individualId = model.get('individualId');
              return '#conser/individual/' + individualId + '/dashboard';
            }
          }),
          headerCell: 'custom',
          width: 10,
          editable: false,
          sortable: false
        }
      ],
      collection: new PageableCollection([], { pageSize: 25 }),
      emptyText: 'No Individuals Found'
    });

    this.showChildView('searchResultsContainer', this.searchResultsGrid);

    this.$('.backgrid.table').addClass('table-bordered');
  },

  onShow: function() {
    this.lastNameColModel = this.searchResultsGrid.viewGrid.columns.findWhere({ name: 'lastName' });
    this.firstNameColModel = this.searchResultsGrid.viewGrid.columns.findWhere({ name: 'firstName' });
    this.openButtonColModel = this.searchResultsGrid.viewGrid.columns.findWhere({ name: 'openButton' });
    this.associatedLicensesColModel = this.searchResultsGrid.viewGrid.columns.findWhere({ name: 'licenses' });
    this.titleColModel = this.searchResultsGrid.viewGrid.columns.findWhere({ name: 'title' });
    this.phoneNumberColModel = this.searchResultsGrid.viewGrid.columns.findWhere({ name: 'phoneNumber' });
    this.emailColModel = this.searchResultsGrid.viewGrid.columns.findWhere({ name: 'emails' });
    this.addressColModel = this.searchResultsGrid.viewGrid.columns.findWhere({ name: 'address' });
    this.onChangeDepartment();
  },

  onKeydownOfInputs: function(e) {
    // Searches on press of enter
    if (e.keyCode === 13) {
      this.onClickSearch(e);
    }
  },

  onChangeDepartment: function() {
    var departmentId = this.ui.department.val();
    if (departmentId === '1000') {
      // Non-Depository
      this.showDefaultSearchResultsView();
      this.searchResultsGrid.viewGrid.columns.remove(this.openButtonColModel);
    } else if (departmentId === '2000') {
      // Depository
      this.showDefaultSearchResultsView();
      this.searchResultsGrid.viewGrid.columns.remove(this.associatedLicensesColModel);
      this.searchResultsGrid.viewGrid.columns.remove(this.openButtonColModel);
    } else if (departmentId === '3000') {
      // Securities
      this.searchResultsGrid.viewGrid.columns.reset();
      this.searchResultsGrid.viewGrid.columns.add(this.lastNameColModel);
      this.searchResultsGrid.viewGrid.columns.add(this.firstNameColModel);
      this.searchResultsGrid.viewGrid.columns.add(this.addressColModel);
      this.searchResultsGrid.viewGrid.columns.add(this.associatedLicensesColModel);
    } else if (departmentId === '4000') {
      // Consumer Services
      this.showDefaultSearchResultsView();
    }
    this.searchResultsGrid.collection.fullCollection.reset();
  },

  showDefaultSearchResultsView: function() {
    this.searchResultsGrid.viewGrid.columns.reset();
    this.searchResultsGrid.viewGrid.columns.add(this.lastNameColModel);
    this.searchResultsGrid.viewGrid.columns.add(this.firstNameColModel);
    this.searchResultsGrid.viewGrid.columns.add(this.titleColModel);
    this.searchResultsGrid.viewGrid.columns.add(this.phoneNumberColModel);
    this.searchResultsGrid.viewGrid.columns.add(this.emailColModel);
    this.searchResultsGrid.viewGrid.columns.add(this.addressColModel);
    this.searchResultsGrid.viewGrid.columns.add(this.associatedLicensesColModel);
    this.searchResultsGrid.viewGrid.columns.add(this.openButtonColModel);
  },

  onClickSearch: function() {
    var data = Syphon.serialize(this, {
      inputReaders: Syphon.SearchInputReaderSet
    });

    if (data.departmentId === '1000') {
      this.searchNonDep(data);
    } else if (data.departmentId === '2000') {
      this.searchDep(data);
    } else if (data.departmentId === '3000') {
      this.searchSec(data);
    } else if (data.departmentId === '4000') {
      this.searchConSer(data);
    }
  },

  onClickClearFilters: function() {
    this.ui.formInputs.val('');
  },

  searchNonDep: function(data) {
    var self = this;

    // If no inputs were given, do not perform a search.
    if (!data.firstName && !data.lastName) {
      return;
    }
    this.searchSummaryView.reset();

    var laddaContext = Ladda.create(this.ui.search[0]);
    laddaContext.start();
    var body = bodybuilder();

    if (data.firstName) {
      body.query('bool', function(b) {
        b.orQuery('match', 'firstName', {
          query: data.firstName,
          fuzziness: 'AUTO'
        });
        b.orQuery('wildcard', 'firstName', data.firstName.toLowerCase() + '*');
        return b;
      });
    }

    if (data.lastName) {
      body.query('bool', function(b) {
        b.orQuery('match', 'lastName', {
          query: data.lastName,
          fuzziness: 'AUTO'
        });
        b.orQuery('wildcard', 'lastName', data.lastName.toLowerCase() + '*');
        return b;
      });
    }

    body.size(this.model.get('searchLimit'));
    // Keep for testing
    // console.log(JSON.stringify(bodyObj, null, 2));
    SearchNonDepIndividuals(body.build())
      .done(function(data) {
        _.each(data.hits, function(hit) {
          if (hit.email) {
            hit.emails = [
              {
                value: hit.email
              }
            ];
          }

          if (!_.some(hit.address)) {
            hit.address = null;
          }

          hit.phoneNumber = hit.homePhoneNumber || '--';

          hit.licenses = _.reduce(
            hit.licenses,
            function(licenses, license) {
              if (license.licenseNumber) {
                licenses.push({
                  value: license.licenseType + ' - ' + license.licenseNumber
                });
              }
              return licenses;
            },
            []
          );
        });

        laddaContext.stop();
        self.searchResultsGrid.collection.fullCollection.reset(data.hits);
        self.searchSummaryView.display(data);
      })
      .fail(function(err) {
        laddaContext.stop();
      });
  },

  searchDep: function(data) {
    var self = this;

    // If no inputs were given, do not perform a search.
    if (!data.firstName && !data.lastName) {
      return;
    }
    this.searchSummaryView.reset();

    var laddaContext = Ladda.create(this.ui.search[0]);
    laddaContext.start();
    var body = bodybuilder();

    if (data.firstName) {
      body.query('bool', function(b) {
        b.orQuery('match', 'firstName', {
          query: data.firstName,
          fuzziness: 'AUTO'
        });
        b.orQuery('wildcard', 'firstName', data.firstName.toLowerCase() + '*');
        return b;
      });
    }

    if (data.lastName) {
      body.query('bool', function(b) {
        b.orQuery('match', 'lastName', {
          query: data.lastName,
          fuzziness: 'AUTO'
        });
        b.orQuery('wildcard', 'lastName', data.lastName.toLowerCase() + '*');
        return b;
      });
    }

    body.size(this.model.get('searchLimit'));
    // Keep for testing
    // console.log(JSON.stringify(bodyObj, null, 2));
    SearchDepPeople(body.build(), {})
      .done(function(data) {
        _.each(data.hits, function(hit) {
          hit.emails = _.map(hit.emails, function(email) {
            return {
              value: email.email
            };
          });
        });

        laddaContext.stop();
        self.searchResultsGrid.collection.fullCollection.reset(data.hits);
        self.searchSummaryView.display(data);
      })
      .fail(function(err) {
        laddaContext.stop();
      });
  },

  searchSec: function(data) {
    var self = this;

    // If no inputs were given, do not perform a search.
    if (!data.firstName && !data.lastName) {
      return;
    }
    this.searchSummaryView.reset();

    var laddaContext = Ladda.create(this.ui.search[0]);
    laddaContext.start();
    var body = bodybuilder();

    if (data.firstName) {
      body.query('bool', function(b) {
        b.orQuery('match', 'firstName', {
          query: data.firstName,
          fuzziness: 'AUTO'
        });
        b.orQuery('wildcard', 'firstName', data.firstName.toLowerCase() + '*');
        return b;
      });
    }

    if (data.lastName) {
      body.query('bool', function(b) {
        b.orQuery('match', 'lastName', {
          query: data.lastName,
          fuzziness: 'AUTO'
        });
        b.orQuery('wildcard', 'lastName', data.lastName.toLowerCase() + '*');
        return b;
      });
    }

    body.size(this.model.get('searchLimit'));
    // Keep for testing
    // console.log(JSON.stringify(bodyObj, null, 2));
    SearchSecIndividuals(body.build())
      .done(function(data) {
        _.each(data.hits, function(hit) {
          hit.title = '--';
          hit.phoneNumber = '--';

          if (!_.some(hit.address)) {
            hit.address = null;
          }

          hit.licenses = _.chain(hit.licenses)
            .pluck('type')
            .uniq()
            .map(function(license) {
              return {
                value: license
              };
            })
            .value();
        });

        laddaContext.stop();
        self.searchResultsGrid.collection.fullCollection.reset(data.hits);
        self.searchSummaryView.display(data);
      })
      .fail(function(err) {
        laddaContext.stop();
      });
  },

  searchConSer: function(data) {
    var self = this;

    // If no inputs were given, do not perform a search.
    if (!data.firstName && !data.lastName) {
      return;
    }
    this.searchSummaryView.reset();

    var laddaContext = Ladda.create(this.ui.search[0]);
    laddaContext.start();
    var body = bodybuilder();

    if (data.firstName) {
      body.query('bool', function(b) {
        b.orQuery('match', 'firstName', {
          query: data.firstName,
          fuzziness: 'AUTO'
        });
        b.orQuery('wildcard', 'firstName', data.firstName.toLowerCase() + '*');
        return b;
      });
    }

    if (data.lastName) {
      body.query('bool', function(b) {
        b.orQuery('match', 'lastName', {
          query: data.lastName,
          fuzziness: 'AUTO'
        });
        b.orQuery('wildcard', 'lastName', data.lastName.toLowerCase() + '*');
        return b;
      });
    }

    body.size(this.model.get('searchLimit'));
    // Keep for testing
    // console.log(JSON.stringify(bodyObj, null, 2));
    SearchConSerIndividuals(body.build())
      .done(function(data) {
        _.each(data.hits, function(hit) {
          if (hit.email) {
            hit.emails = [
              {
                value: hit.email
              }
            ];
          }
        });

        laddaContext.stop();
        self.searchResultsGrid.collection.fullCollection.reset(data.hits);
        self.searchSummaryView.display(data);
      })
      .fail(function(err) {
        laddaContext.stop();
      });
  }
});
