var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "  <div class=\"row\">\r\n    <div class=\"col-sm-12 col-md-6\">\r\n      <div class=\"form-group\">\r\n        <label class=\"control-label col-sm-3 col-md-4\">Organization</label>\r\n        <div class=\"form-control-static col-sm-9 col-md-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.dbaName : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\r\n      </div>\r\n    </div>\r\n    <div class=\"col-sm-12 col-md-6\">\r\n      <div class=\"form-group\">\r\n        <label class=\"control-label col-sm-3 col-md-4\">Organization License</label>\r\n        <div class=\"form-control-static col-sm-9 col-md-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.licenseType : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\r\n      </div>\r\n    </div>\r\n  </div>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isOrganization : depth0),"===","0",{"name":"ifCond","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "  <div class=\"row\">\r\n    <div class=\"col-sm-12 col-md-6\">\r\n      <div class=\"form-group\">\r\n        <label class=\"control-label col-sm-3 col-md-4\">Individual</label>\r\n        <div class=\"form-control-static col-sm-9 col-md-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.dbaName : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\r\n      </div>\r\n    </div>\r\n    <div class=\"col-sm-12 col-md-6\">\r\n      <div class=\"form-group\">\r\n        <label class=\"control-label col-sm-3 col-md-4\">Individual License</label>\r\n        <div class=\"form-control-static col-sm-9 col-md-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.licenseType : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\r\n      </div>\r\n    </div>\r\n  </div>\r\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "Yes";
},"8":function(container,depth0,helpers,partials,data) {
    return "No";
},"10":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"col-sm-12 col-md-6\">\r\n      <div class=\"form-group\">\r\n        <label class=\"control-label col-sm-3 col-md-4\">Senior Victim Count</label>\r\n        <div class=\"form-control-static col-sm-9 col-md-8\">"
    + container.escapeExpression((helpers["default"] || (depth0 && depth0["default"]) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.seniorVictimCount : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\r\n      </div>\r\n    </div>\r\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.violations : depth0),{"name":"each","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"13":function(container,depth0,helpers,partials,data) {
    var helper;

  return "          <div>"
    + container.escapeExpression(((helper = (helper = helpers.violationType || (depth0 != null ? depth0.violationType : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"violationType","hash":{},"data":data}) : helper)))
    + "</div>\r\n";
},"15":function(container,depth0,helpers,partials,data) {
    return "          <div>--</div>\r\n";
},"17":function(container,depth0,helpers,partials,data) {
    return "  <div class=\"row\">\r\n    <div class=\"col-sm-12\">\r\n      <div class=\"form-group\">\r\n        <label class=\"control-label col-sm-3 col-md-2\">Other Violation</label>\r\n        <div class=\"form-control-static col-sm-9 col-md-10\">"
    + container.escapeExpression((helpers["default"] || (depth0 && depth0["default"]) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.otherViolation : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\r\n      </div>\r\n    </div>\r\n  </div>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "\r\n<div class=\"form-horizontal form-static\">\r\n"
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias2).call(alias1,(depth0 != null ? depth0.isOrganization : depth0),"===","1",{"name":"ifCond","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "  <div class=\"row\">\r\n    <div class=\"col-sm-12 col-md-6\">\r\n      <div class=\"form-group\">\r\n        <label class=\"control-label col-sm-3 col-md-4\">Case Number</label>\r\n        <div class=\"form-control-static col-sm-9 col-md-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.caseNumber : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\r\n      </div>\r\n    </div>\r\n    <div class=\"col-sm-12 col-md-6\">\r\n      <div class=\"form-group\">\r\n        <label class=\"control-label col-sm-3 col-md-4\">Category</label>\r\n        <div class=\"form-control-static col-sm-9 col-md-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.category : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\r\n      </div>\r\n    </div>\r\n  </div>\r\n  <div class=\"row\">\r\n    <div class=\"col-sm-12 col-md-6\">\r\n      <div class=\"form-group\">\r\n        <label class=\"control-label col-sm-3 col-md-4\"> Case Status</label>\r\n        <div class=\"form-control-static col-sm-9 col-md-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.status : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\r\n      </div>\r\n    </div>\r\n    <div class=\"col-sm-12 col-md-6\">\r\n      <div class=\"form-group\">\r\n        <label class=\"control-label col-sm-3 col-md-4\">Opened Date</label>\r\n        <div class=\"form-control-static col-sm-9 col-md-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.openedDate : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\r\n      </div>\r\n    </div>\r\n  </div>\r\n  <div class=\"row\">\r\n    <div class=\"col-sm-12 col-md-6\">\r\n      <div class=\"form-group\">\r\n        <label class=\"control-label col-sm-3 col-md-4\">Closed Date</label>\r\n        <div class=\"form-control-static col-sm-9 col-md-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.closedDate : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\r\n      </div>\r\n    </div>\r\n    <div class=\"col-sm-12 col-md-6\">\r\n      <div class=\"form-group\">\r\n        <label class=\"control-label col-sm-3 col-md-4\">Date Upgraded</label>\r\n        <div class=\"form-control-static col-sm-9 col-md-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.upgradedDate : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\r\n      </div>\r\n    </div>\r\n  </div>\r\n  <div class=\"row\">\r\n    <div class=\"col-sm-12 col-md-6\">\r\n      <div class=\"form-group\">\r\n        <label class=\"control-label col-sm-3 col-md-4\">Type</label>\r\n        <div class=\"form-control-static col-sm-9 col-md-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.type : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\r\n      </div>\r\n    </div>\r\n    <div class=\"col-sm-12 col-md-6\">\r\n      <div class=\"form-group\">\r\n        <label class=\"control-label col-sm-3 col-md-4\">Office Assigned</label>\r\n        <div class=\"form-control-static col-sm-9 col-md-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.office : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\r\n      </div>\r\n    </div>\r\n  </div>\r\n  <div class=\"row\">\r\n    <div class=\"col-sm-12 col-md-6\">\r\n      <div class=\"form-group\">\r\n        <label class=\"control-label col-sm-3 col-md-4\">Primary Examiner</label>\r\n        <div class=\"form-control-static col-sm-9 col-md-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.primaryExaminer : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\r\n      </div>\r\n    </div>\r\n  </div>\r\n  <div class=\"row\">\r\n    <div class=\"col-sm-12\">\r\n      <div class=\"form-group\">\r\n        <label class=\"control-label col-sm-3 col-md-2\">Description</label>\r\n        <div class=\"form-control-static col-sm-9 col-md-10\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.description : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\r\n      </div>\r\n    </div>\r\n  </div>\r\n  <div class=\"row\">\r\n    <div class=\"col-sm-12 col-md-6\">\r\n      <div class=\"form-group\">\r\n        <label class=\"control-label col-sm-3 col-md-4\">Victims 60 or older involved</label>\r\n        <div class=\"form-control-static col-sm-9 col-md-8\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isVictimsSenior : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\r\n      </div>\r\n    </div>\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isVictimsSenior : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  </div>\r\n  <div class=\"row\">\r\n    <div class=\"col-sm-9 col-md-10 col-sm-offset-3 col-md-offset-2\">\r\n      <h3>Allegations</h3>\r\n    </div>\r\n  </div>\r\n  <div class=\"row\">\r\n    <div class=\"col-sm-12\">\r\n      <div class=\"form-group\">\r\n        <label class=\"control-label col-sm-3 col-md-2\">Violation Type</label>\r\n        <div class=\"form-control-static col-sm-9 col-md-10\">\r\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.violations : depth0)) != null ? stack1.length : stack1),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.program(15, data, 0),"data":data})) != null ? stack1 : "")
    + "        </div>\r\n      </div>\r\n    </div>\r\n  </div>\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.hasOtherViolation : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>";
},"useData":true});