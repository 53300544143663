/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Radio = require('backbone.radio');
var Ladda = require('ladda');

var makeUploadCollection = require('common/util/makeUploadCollection');
var toggleForm = require('common/util/toggleForm');
var FileUploadView = require('common/views/FileUploadView');
var ContentServerEditorLinkView = require('common/views/ContentServerEditorLinkView');
var FileUploadTableFreeTextView = require('../../../common/views/FileUploadTableFreeTextView');

var SubmitInitialReviewFilingTask = require('../../services/SubmitInitialReviewFilingTask');

var tmplDepAA1ReviewFilingWorkTabView = require('./DepAA1ReviewFilingWorkTabView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepAA1ReviewFilingWorkTabView,

  className: 'aa1-review-filing-work-tab-view container-fluid',

  ui: {
    previousDecisionContainer: '.previous-decision-container',
    reviewDecision: '.review-decision',
    reviewDecisionForm: '.review-decision-form',
    acceptInfoForm: '.accept-info-form',
    acceptReviewerForm: '.accept-reviewer-form',
    withdrawnForm: '.withdrawn-form',
    isReturnedForm: '.is-returned-form',
    notifyEntityForm: '.notify-entity-form',
    withdrawalLetterContainer: '.withdrawal-letter-container',
    acknowledgementLetterContainer: '.acknowledgement-letter-container',
    acceptanceLetter: '.acceptance-letter',
    acceptanceLetterContainer: '.acceptance-letter-container',
    federalDocumentsContainer: '.federal-documents-container',
    checklistLinkContainer: '.checklist-link-container',
    analysisMemoLinkContainer: '.analysis-memo-link-container',
    decisionLetterLinkContainer: '.decision-letter-link-container',
    checklistLinkSection: '.checklist-link-section',
    analysisMemoLinkSection: '.analysis-memo-link-section',
    decisionLetterLinkSection: '.decision-letter-link-section',
    documentsSection: '.documents-section',
    reviewRoleCheckbox: '.review-role-checkbox',
    reviewRoleCheckboxErrorContainer: '.review-role-checkbox-error-container',
    validationErrorContainer: '.validation-error-container',
    pendingItemsLabel: '.pending-items-label',
    pendingItemsSelect: '.pending-items-select',
    isAcceptedContainer: '.is-accepted',
    isWithrawnContainer: '.is-withdrawn',
    isReturnedContainer: '.is-returned',
    notifyEntityFormContainer: '.notify-entity-form-container',
    dueDate: '.due-date',
    submit: '.submit',
    isNotifyEntity: 'input[name="isNotifyEntity"]'
  },

  regions: {
    withdrawalLetterContainer: '@ui.withdrawalLetterContainer',
    acknowledgementLetterContainer: '@ui.acknowledgementLetterContainer',
    acceptanceLetterContainer: '@ui.acceptanceLetterContainer',
    federalDocumentsContainer: '@ui.federalDocumentsContainer',
    checklistLinkContainer: '@ui.checklistLinkContainer',
    analysisMemoLinkContainer: '@ui.analysisMemoLinkContainer',
    decisionLetterLinkContainer: '@ui.decisionLetterLinkContainer'
  },

  events: {
    'change @ui.reviewDecision': 'onChangeReviewDecision',
    'change @ui.pendingItemsSelect': 'onChangePendingItems',
    'click @ui.submit': 'onClickSubmit',
    'click @ui.isNotifyEntity': 'onChangeIsNotifyEntity'
  },

  templateHelpers: function() {
    var filingDetails = this.model.get('filingDetails') || {};
    var filingContent = this.model.get('filingContent') || {};

    var isBank = filingDetails.entityCategoryId === '1';
    var isTypeAudit = filingDetails.filingTypeId === '306';
    var isTypeCIF = filingDetails.filingTypeId === '305';
    var isTypeSEG = filingDetails.filingTypeId === '204';
    var isActionAddOrAmend = _.contains(['1', '2'], filingContent.fundActionId);

    return {
      isBank: isBank,
      hideFedApplicationBreakdown: this.isTrust || isTypeSEG,
      hideWorkflow: isTypeAudit || (isTypeCIF && isActionAddOrAmend)
    };
  },

  onBeforeRender: function() {
    var filingDetails = this.model.get('filingDetails') || {};

    this.isTrust = filingDetails.entityCategoryId === '3';
  },

  onBeforeShow: function() {
    this.ui.dueDate.datepicker();

    var filingDetails = this.model.get('filingDetails') || {};

    if (this.hideWorkflow) {
      this.ui.acceptReviewerForm.hide();
    }

    this.withdrawalLetter = new FileUploadView({
      isRequired: true,
      collection: makeUploadCollection(this.model.get('withdrawalLetterCollection')),
      isReadOnly: this.options.isReadOnly,
      metadata: {
        documentTypeId: '20006'
      }
    });
    this.showChildView('withdrawalLetterContainer', this.withdrawalLetter);

    this.acknowledgementLetter = new FileUploadView({
      isRequired: true,
      collection: makeUploadCollection(this.model.get('acknowlegementLetterCollection')),
      isReadOnly: this.options.isReadOnly,
      metadata: {
        documentTypeId: '20003'
      }
    });
    this.showChildView('acknowledgementLetterContainer', this.acknowledgementLetter);

    this.acceptanceLetter = new FileUploadView({
      isRequired: false,
      collection: makeUploadCollection(this.model.get('acceptanceLetterCollection')),
      isReadOnly: this.options.isReadOnly,
      metadata: {
        documentTypeId: '20004'
      }
    });
    this.showChildView('acceptanceLetterContainer', this.acceptanceLetter);

    this.showChildView(
      'checklistLinkContainer',
      new ContentServerEditorLinkView({
        model: new Backbone.Model({
          linkText: 'Edit Checklist',
          contentServerId: filingDetails.checklistCSId
        })
      })
    );

    this.showChildView(
      'analysisMemoLinkContainer',
      new ContentServerEditorLinkView({
        model: new Backbone.Model({
          linkText: 'Edit Analysis Memo',
          contentServerId: filingDetails.analysisMemoCSId
        })
      })
    );

    this.showChildView(
      'decisionLetterLinkContainer',
      new ContentServerEditorLinkView({
        model: new Backbone.Model({
          linkText: 'Edit Decision Letter',
          contentServerId: filingDetails.decisionLetterCSId
        })
      })
    );

    var showChecklist = filingDetails.checklistCSId;
    var showAnalysisMemo = filingDetails.analysisMemoCSId;
    var showDecisionLetter = filingDetails.decisionLetterCSId;
    var isTypeSEG = filingDetails.filingTypeId === '204';

    if (!showChecklist) {
      this.ui.checklistLinkSection.hide();
    }
    if (!showAnalysisMemo) {
      this.ui.analysisMemoLinkSection.hide();
    }
    if (!showDecisionLetter) {
      this.ui.decisionLetterLinkSection.hide();
    }
    if (!showChecklist && !showAnalysisMemo && !showDecisionLetter) {
      this.ui.documentsSection.hide();
    }

    if (!this.isTrust && !isTypeSEG) {
      this.federalDocuments = new FileUploadTableFreeTextView({
        metadata: {
          documentTypeId: '20001'
        },
        isReadOnly: this.options.isReadOnly,
        emptyText: 'No Federal Documents Provided',
        collection: makeUploadCollection(this.model.get('federalBreakdownCollection'))
      });
      this.showChildView('federalDocumentsContainer', this.federalDocuments);
    }
  },

  onChangeReviewDecision: function() {
    var reviewDecision = this.ui.reviewDecision.val();

    var isAccepted = reviewDecision === '1';
    var isReturned = reviewDecision === '2';
    var isWithdrawn = reviewDecision === '3';

    toggleForm(this.ui.notifyEntityFormContainer, isAccepted || isWithdrawn);
    toggleForm(this.ui.isAcceptedContainer, isAccepted);
    toggleForm(this.ui.isWithrawnContainer, isWithdrawn);
    toggleForm(this.ui.isReturnedContainer, isReturned);
  },

  onChangePendingItems: function() {
    // Change label on 'Acceptance Letter' to indicate there are pending items
    var pendingItems = this.ui.pendingItemsSelect.val();
    pendingItems === '1' ? this.ui.pendingItemsLabel.show() : this.ui.pendingItemsLabel.hide();
  },
  onChangeIsNotifyEntity: function(e) {
    var notifyEntity = this.ui.isNotifyEntity.filter(':checked').val() === '1';
    var isAccepted = this.ui.reviewDecision.val() === '1';
   if (notifyEntity && isAccepted) {
      this.ui.acceptanceLetterContainer.attr('data-parsley-required', true);
      this.ui.acceptanceLetter.attr('data-parsley-required', true);
      this.ui.acceptanceLetter.toggleClass('required', true);
      this.acceptanceLetter.options.isRequired = true;
    } else {
      this.ui.acceptanceLetterContainer.removeAttr('data-parsley-required');
      this.acceptanceLetter.options.isRequired = false;
      this.ui.acceptanceLetter.toggleClass('required', false);
      this.acceptanceLetter.removeValidationErrors();
    }
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;
    this.validate().done(function() {
      var l = Ladda.create(self.ui.submit[0]);
      l.start();
      SubmitInitialReviewFilingTask(self.getFormData())
        .done(function() {
          Radio.channel('navigate').trigger('show:int:dp:dashboard');
        })
        .fail(function() {
          l.stop();
        });
    });
  },

  validate: function() {
    this.removeValidationErrors();

    var defer = $.Deferred();
    var validationPromises = [this.ui.reviewDecisionForm.parsley().whenValidate()];
    
    var filingDetails = this.model.get('filingDetails') || {};
    var filingContent = this.model.get('filingContent') || {};

    var hideWorkflow = false;
    var isTypeAudit = filingDetails.filingTypeId === '306';
    var isTypeCIF = filingDetails.filingTypeId === '305';
    var isActionAddOrAmend = _.contains(['1', '2'], filingContent.fundActionId);
    var isTypeSEG = filingDetails.filingTypeId === '204';
    
    if (isTypeAudit) {
      hideWorkflow = true;
    }
    if (isTypeCIF && isActionAddOrAmend){
      hideWorkflow = true;
    }
    
    var reviewDecision = this.ui.reviewDecision.val();

    var isAccepted = reviewDecision === '1';
    var isReturned = reviewDecision === '2';
    var isWithdrawn = reviewDecision === '3';

    if (isAccepted) {
      var reviewRoles = this.ui.reviewRoleCheckbox.filter(':checked') || [];

      if (!hideWorkflow && reviewRoles.length < 1) {
        this.ui.acceptReviewerForm.addClass('has-error');
        this.ui.reviewRoleCheckboxErrorContainer.show();
        defer.reject();
      } else {
        defer.resolve();
      }

      validationPromises.push(this.ui.acceptInfoForm.parsley().whenValidate());
      validationPromises.push(this.ui.notifyEntityForm.parsley().whenValidate());
      validationPromises.push(this.acceptanceLetter.validate());

      if (!this.isTrust && !isTypeSEG) {
        validationPromises.push(
          this.federalDocuments.validate({
            type: 'submit'
          })
        );
      }

      validationPromises.push(defer.promise());
    } else if (isReturned) {
      validationPromises.push(this.ui.isReturnedForm.parsley().whenValidate());
      validationPromises.push(this.acknowledgementLetter.validate());
    } else if (isWithdrawn) {
      validationPromises.push(this.ui.withdrawnForm.parsley().whenValidate());
      validationPromises.push(this.ui.notifyEntityForm.parsley().whenValidate());
      validationPromises.push(this.withdrawalLetter.validate());
    }

    return $.when.apply($, validationPromises);
  },

  removeValidationErrors: function() {
    this.ui.acceptReviewerForm.removeClass('has-error');
    this.ui.validationErrorContainer.hide();
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);

    var checkedBoxes = this.ui.reviewRoleCheckbox.filter(':checked');
    formData.reviewRoles = _.map(checkedBoxes, function(checkedBox) {
      return checkedBox.value;
    });

    _.extend(formData, {
      taskId: this.model.get('taskId'),
      filingId: this.model.get('filingId'),
      acceptanceLetterDocId: this.acceptanceLetter.getFirstFileId(),
      withdrawalLetterDocId: this.withdrawalLetter.getFirstFileId(),
      acknowledgementLetterDocId: this.acknowledgementLetter.getFirstFileId(),
      federalDocuments: this.federalDocuments ? this.federalDocuments.getFormData() : []
    });

    if (this.options.mode === 'int-submission') {
      formData.decisionId = '1';
    }
    return formData;
  }
});
