var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "\r\n<div class=\"row m-b-lg\">\r\n  <div class=\"col-md-6 col-sm-12 form-static\">\r\n    <div class=\"row\">\r\n      <h3 class=\"m-t-n col-sm-offset-3 col-sm-9 col-md-6 col-md-offset-6\">Total Costs</h3>\r\n    </div>\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-3 col-md-6\">Total Costs</label>\r\n      <div class=\"form-control-static col-sm-9 col-md-6\">\r\n        "
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.totalCostsFormatted : depth0),"$0.00",{"name":"default","hash":{},"data":data}))
    + "\r\n      </div>\r\n    </div>\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-3 col-md-6\">Total Amount Paid</label>\r\n      <div class=\"form-control-static col-sm-9 col-md-6\">\r\n        "
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.paidFormatted : depth0),"$0.00",{"name":"default","hash":{},"data":data}))
    + "\r\n      </div>\r\n    </div>\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-3 col-md-6\">Restitution Agreed By Order</label>\r\n      <div class=\"form-control-static col-sm-9 col-md-6\">\r\n        "
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.restitutionAgreedByOrderFormatted : depth0),"$0.00",{"name":"default","hash":{},"data":data}))
    + "\r\n      </div>\r\n    </div>\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-3 col-md-6\">Total Amount Due</label>\r\n      <div class=\"form-control-static col-sm-9 col-md-6\">\r\n        "
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.dueFormatted : depth0),"$0.00",{"name":"default","hash":{},"data":data}))
    + "\r\n      </div>\r\n    </div>\r\n  </div>\r\n  <div class=\"col-md-6 col-sm-12 form-static\">\r\n    <div class=\"row\">\r\n      <h3 class=\"m-t-n col-sm-offset-3 col-sm-9 col-md-6 col-md-offset-6\">Administrative Assessments</h3>\r\n    </div>\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-3 col-md-6\">Total Administrative Assessments Costs</label>\r\n      <div class=\"form-control-static col-sm-9 col-md-6\">\r\n        "
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.adminAssessmentsFormatted : depth0),"$0.00",{"name":"default","hash":{},"data":data}))
    + "\r\n      </div>\r\n    </div>\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-3 col-md-6\">Administrative Assessments Costs Paid</label>\r\n      <div class=\"form-control-static col-sm-9 col-md-6\">\r\n        "
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.adminAssessmentsPaidFormatted : depth0),"$0.00",{"name":"default","hash":{},"data":data}))
    + "\r\n      </div>\r\n    </div>\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-3 col-md-6\">Administrative Assessments Costs Due</label>\r\n      <div class=\"form-control-static col-sm-9 col-md-6\">\r\n        "
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.adminAssessmentsDueFormatted : depth0),"$0.00",{"name":"default","hash":{},"data":data}))
    + "\r\n      </div>\r\n    </div>\r\n  </div>\r\n</div>\r\n<div class=\"row m-b-lg\">\r\n  <div class=\"col-md-6 col-sm-12 form-static\">\r\n    <div class=\"row\">\r\n      <h3 class=\"m-t-n col-sm-offset-3 col-sm-9 col-md-6 col-md-offset-6\">Investigative</h3>\r\n    </div>\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-3 col-md-6\">Total Investigative Costs</label>\r\n      <div class=\"form-control-static col-sm-9 col-md-6\">\r\n        "
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.investigativeCostsFormatted : depth0),"$0.00",{"name":"default","hash":{},"data":data}))
    + "\r\n      </div>\r\n    </div>\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-3 col-md-6\">Investigative Costs Paid</label>\r\n      <div class=\"form-control-static col-sm-9 col-md-6\">\r\n        "
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.investigativePaidFormatted : depth0),"$0.00",{"name":"default","hash":{},"data":data}))
    + "\r\n      </div>\r\n    </div>\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-3 col-md-6\">Investigative Costs Due</label>\r\n      <div class=\"form-control-static col-sm-9 col-md-6\">\r\n        "
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.investigativeDueFormatted : depth0),"$0.00",{"name":"default","hash":{},"data":data}))
    + "\r\n      </div>\r\n    </div>\r\n  </div>\r\n  <div class=\"col-md-6 col-sm-12 form-static\">\r\n    <div class=\"row\">\r\n      <h3 class=\"m-t-n col-sm-offset-3 col-sm-9 col-md-6 col-md-offset-6\">Legal</h3>\r\n    </div>\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-3 col-md-6\">Total Legal Costs</label>\r\n      <div class=\"form-control-static col-sm-9 col-md-6\">\r\n        "
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.legalCostsFormatted : depth0),"$0.00",{"name":"default","hash":{},"data":data}))
    + "\r\n      </div>\r\n    </div>\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-3 col-md-6\">Legal Costs Paid</label>\r\n      <div class=\"form-control-static col-sm-9 col-md-6\">\r\n        "
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.legalPaidFormatted : depth0),"$0.00",{"name":"default","hash":{},"data":data}))
    + "</div>\r\n    </div>\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-3 col-md-6\">Legal Costs Due</label>\r\n      <div class=\"form-control-static col-sm-9 col-md-6\">\r\n        "
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.legalDueFormatted : depth0),"$0.00",{"name":"default","hash":{},"data":data}))
    + "\r\n      </div>\r\n    </div>\r\n  </div>\r\n</div>\r\n<div class=\"row m-b-lg\">\r\n  <div class=\"col-md-6 col-sm-12 form-static\">\r\n    <div class=\"row\">\r\n      <h3 class=\"m-t-n col-sm-offset-3 col-sm-9 col-md-6 col-md-offset-6\">Civil Penalty</h3>\r\n    </div>\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-3 col-md-6\">Total Civil Penalty Costs</label>\r\n      <div class=\"form-control-static col-sm-9 col-md-6\">\r\n        "
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.civilPenaltyFormatted : depth0),"$0.00",{"name":"default","hash":{},"data":data}))
    + "\r\n      </div>\r\n    </div>\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-3 col-md-6\">Civil Penalty Costs Paid</label>\r\n      <div class=\"form-control-static col-sm-9 col-md-6\">\r\n        "
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.civilPaidFormatted : depth0),"$0.00",{"name":"default","hash":{},"data":data}))
    + "\r\n      </div>\r\n    </div>\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-3 col-md-6\">Civil Penalty Costs Due</label>\r\n      <div class=\"form-control-static col-sm-9 col-md-6\">\r\n        "
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.civilDueFormatted : depth0),"$0.00",{"name":"default","hash":{},"data":data}))
    + "\r\n      </div>\r\n    </div>\r\n  </div>\r\n  <div class=\"col-md-6 col-sm-12 form-static\">\r\n    <div class=\"row\">\r\n      <h3 class=\"m-t-n col-sm-offset-3 col-sm-9 col-md-6 col-md-offset-6\">Global Settlement</h3>\r\n    </div>\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-3 col-md-6\">Total Global Settlement Costs</label>\r\n      <div class=\"form-control-static col-sm-9 col-md-6\">\r\n        "
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.globalSettlementFormatted : depth0),"$0.00",{"name":"default","hash":{},"data":data}))
    + "\r\n      </div>\r\n    </div>\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-3 col-md-6\">Global Settlement Costs Paid</label>\r\n      <div class=\"form-control-static col-sm-9 col-md-6\">\r\n        "
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.globalPaidFormatted : depth0),"$0.00",{"name":"default","hash":{},"data":data}))
    + "</div>\r\n    </div>\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-3 col-md-6\">Global Settlement Costs Due</label>\r\n      <div class=\"form-control-static col-sm-9 col-md-6\">\r\n        "
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.globalDueFormatted : depth0),"$0.00",{"name":"default","hash":{},"data":data}))
    + "\r\n      </div>\r\n    </div>\r\n  </div>\r\n</div>";
},"useData":true});