/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(categoryId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetAllFilingLookups: {
        $xmlns: 'http://dobs.pa.gov/dp/lookup/bpm/1.0'
      }
    },
    blueprintArrays: [
      'GetAllFilingLookupsResponse.Lookups.DP_LU_FilingType',
      'GetAllFilingLookupsResponse.Lookups.DP_LU_FilingProcessingOption',
      'GetAllFilingLookupsResponse.Lookups.DP_LU_FilingProcessingAction',
      'GetAllFilingLookupsResponse.Lookups.DP_LU_FilingRecordStatus',
      'GetAllFilingLookupsResponse.Lookups.DP_LU_FilingWorkflowStatus',
      'GetAllFilingLookupsResponse.Lookups.DP_LU_FilingConditionType',
      'GetAllFilingLookupsResponse.Lookups.DP_LU_FilingConditionFrequency',
      'GetAllFilingLookupsResponse.Lookups.DP_LU_FilingConditionStatus',
      'GetAllFilingLookupsResponse.Lookups.DP_LU_FilingSubsidiaryStatus',
      'GetAllFilingLookupsResponse.Lookups.DP_LU_FilingWorkflowDecisionType'
    ],
    blueprint: {
      filingTypes: [
        '$..DP_LU_FilingType',
        {
          id: '$..ID',
          name: '$..Name',
          categoryId: '$..CategoryID'
        }
      ],
      filingRecordStatus: [
        '$..DP_LU_FilingRecordStatus',
        {
          id: '$..ID',
          status: '$..Status'
        }
      ],
      filingWorkflowStatus: [
        '$..DP_LU_FilingWorkflowStatus',
        {
          id: '$..ID',
          status: '$..Status'
        }
      ],
      filingProcessingOptions: [
        '$..DP_LU_FilingProcessingOption',
        {
          id: '$..ID',
          option: '$..Option'
        }
      ],
      filingProcessingActions: [
        '$..DP_LU_FilingProcessingAction',
        {
          id: '$..ID',
          action: '$..Action'
        }
      ],
      filingSubsidiaryStatus: [
        '$..DP_LU_FilingSubsidiaryStatus',
        {
          id: '$..ID',
          status: '$..Status'
        }
      ],
      filingConditionTypes: [
        '$..DP_LU_FilingConditionType',
        {
          id: '$..ID',
          type: '$..Type'
        }
      ],
      filingConditionFrequencies: [
        '$..DP_LU_FilingConditionFrequency',
        {
          id: '$..ID',
          frequency: '$..Frequency'
        }
      ],
      filingConditionStatus: [
        '$..DP_LU_FilingConditionStatus',
        {
          id: '$..ID',
          status: '$..Status'
        }
      ],
      filingWorkflowDecisionTypes: [
        '$..DP_LU_FilingWorkflowDecisionType',
        {
          id: '$..ID',
          filingWorkflowDecisionType: '$..FilingWorkflowDecisionType'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      if (categoryId) {
        response.filingTypes = _.filter(response.filingTypes, function(filngType) {
          return filngType.categoryId === categoryId;
        });
      }

      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
