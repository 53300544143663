/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');

var modelGet = require('common/util/modelGet');
var BreadcrumbView = require('common/views/BreadcrumbView');
var LicenseHeaderBarView = require('../../common/views/LicenseHeaderBarView');

var LicenseCertificationStatusChangeApplication = require('../forms/LicenseCertificationStatusChangeApplication');

var tmplLicenseCertificationStatusChangeView = require('../templates/LicenseCertificationStatusChangeView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplLicenseCertificationStatusChangeView,

  className: 'license-certification-status-change-view container-fluid',

  ui: {
    headerBarContainer: '.header-bar-container',
    breadCrumbContainer: '.breadcrumb-container',
    licenseCertificationStatusChangeFormContainer: '.license-certification-status-change-form-container'
  },

  regions: {
    headerBarContainer: '@ui.headerBarContainer',
    breadCrumbContainer: '@ui.breadCrumbContainer',
    licenseCertificationStatusChangeFormContainer: '@ui.licenseCertificationStatusChangeFormContainer'
  },

  onBeforeShow: function() {
    var entityId = this.model.get('entityId');
    var licenseId = this.model.get('licenseId');
    var entityName = modelGet(this.model, 'entityIdentifier.entityName');
    var licenseType = modelGet(this.model, 'licenseIdentifier.licenseType');
    var licenseNumber = modelGet(this.model, 'licenseIdentifier.licenseNumber');
    var currentCreditCounselors = modelGet(this.model, 'licenseDashboard.creditCounselors');
    var creditCounselorStatuses = modelGet(this.model, 'licenseDashboard.creditCounselorStatuses');

    this.showChildView(
      'headerBarContainer',
      new LicenseHeaderBarView({
        model: new Backbone.Model(this.model.get('licenseIdentifier'))
      })
    );

    this.showChildView(
      'breadCrumbContainer',
      new BreadcrumbView({
        breadcrumbs: [
          {
            text: 'Home',
            link: '#ext/nondep/dashboard'
          },
          {
            text: entityName,
            link: '#ext/nondep/entity/' + entityId + '/dashboard'
          },
          {
            text: 'License #' + licenseNumber + ' - ' + licenseType,
            link: '#ext/nondep/entity/' + entityId + '/license/' + licenseId
          },
          {
            text: 'Apply to Change of Status of Certification'
          }
        ]
      })
    );

    this.showChildView(
      'licenseCertificationStatusChangeFormContainer',
      new LicenseCertificationStatusChangeApplication({
        isReadOnly: false,
        model: new Backbone.Model({
          entityId: entityId,
          licenseId: licenseId,
          currentCreditCounselors: currentCreditCounselors,
          creditCounselorStatuses: creditCounselorStatuses
        })
      })
    );

    Radio.channel('nd:submit-application').on(
      'submit',
      function() {
        Radio.channel('navigate').trigger(
          'show:ext:nd:license-dashboard',
          this.model.get('entityId'),
          this.model.get('licenseId')
        );
      }.bind(this)
    );
  }
});
