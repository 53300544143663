/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var FileUploadCollection = require('psa-backbone/models/FileUploadCollection');

var parsleyUtil = require('common/util/parsleyUtil');
var FileUploadView = require('common/views/FileUploadView');
var modelGet = require('common/util/modelGet');

var DepNewSubfundCollectionView = require('./DepNewSubfundCollectionView');

var tmplDepCollectiveInvestmentFundAddSubFormView = require('./DepCollectiveInvestmentFundAddSubFormView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepCollectiveInvestmentFundAddSubFormView,

  className: 'dep-collective-investment-fund-add-sub-form-view',

  behaviors: {
    ReadOnlyFormBehavior: {}
  },

  ui: {
    addFundForm: '.add-fund-form',
    addSubfund: '.add-subfund',
    addSubfundSection: '.add-subfund-section',
    newSubfundCollectionContainer: '.new-subfund-collection-container',
    datePickerFields: '.date-picker',
    trustDocumentContainer: '.trust-document-container',
    supportingDocumentsContainer: '.supporting-documents-container'
  },

  regions: {
    newSubfundCollectionContainer: '@ui.newSubfundCollectionContainer',
    trustDocumentContainer: '@ui.trustDocumentContainer',
    datePickerFields: '@ui.datePickerFields',
    supportingDocumentsContainer: '@ui.supportingDocumentsContainer'
  },

  events: {
    'click @ui.addSubfund': 'onClickAddSubfund'
  },

  childEvents: {
    'remove:subfund': 'onChildRemoveSubfund'
  },

  templateHelpers: function() {
    return {
      isReadOnly: this.options.isReadOnly
    };
  },

  onBeforeShow: function() {
    var filingContent = this.model.get('filingContent') || {};
    var supportingDocuments = _.pluck(modelGet(this.model, 'filingContent.supportingDocuments', []), 'file');

    var trustDocId = modelGet(this.model, 'filingContent.trustDocument.fileId');
    if (trustDocId) {
      var trustDocument = modelGet(this.model, 'filingContent.trustDocument');
    }

    Syphon.deserialize(this, this.model.get('filingContent'));

    this.newSubfunds = new DepNewSubfundCollectionView({
      collection: new Backbone.Collection(filingContent.newSubFunds)
    });
    this.showChildView('newSubfundCollectionContainer', this.newSubfunds);

    this.trustDocument = new FileUploadView({
      isReadOnly: this.options.isReadOnly,
      isRequired: true,
      collection: new FileUploadCollection(trustDocument),
      metadata: {
        documentTypeId: 20001
      }
    });
    this.showChildView('trustDocumentContainer', this.trustDocument);

    this.supportingDocumentsView = new FileUploadView({
      isReadOnly: this.options.isReadOnly,
      allowMultipleFiles: true,
      collection: new FileUploadCollection(supportingDocuments),
      metadata: {
        documentTypeId: 20001
      }
    });
    this.showChildView('supportingDocumentsContainer', this.supportingDocumentsView);

    this.ui.datePickerFields.datepicker();
  },

  onClickAddSubfund: function(e) {
    e.preventDefault();
    this.newSubfunds.collection.add({});
  },

  onChildRemoveSubfund: function(childView) {
    this.newSubfunds.collection.remove(childView);
  },

  removeValidationErrors: function() {
    this.ui.addFundForm.parsley().reset();
    this.trustDocument.removeValidationErrors();
  },

  validate: function(config) {
    this.removeValidationErrors();

    if (config.type === 'save') {
      // Turn off required field validation for this form
      parsleyUtil.disableRequiredValidation(this.ui.addFundForm);
    } else {
      var validationPromises = [this.ui.addFundForm.parsley().whenValidate(), this.trustDocument.validate()];

      // Turn on required field validation for this form
      parsleyUtil.enableRequiredValidation(this.ui.addFundForm);
    }
    return $.when.apply($, validationPromises);
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    formData.newSubfunds = this.newSubfunds.getFormData();
    formData.trustDocId = this.trustDocument.getFirstFileId();
    formData.supportingDocuments = this.supportingDocumentsView.collection.map(function(file) {
      return {
        fileId: file.get('fileId'),
        documentTypeId: modelGet(file, 'metadata.documentTypeId')
      };
    });
    return formData;
  }
});
