/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var parsleyUtil = require('common/util/parsleyUtil');

var CreditServicesLoanBrokerAgentCollectionView = require('./CreditServicesLoanBrokerAgentsCollectionView');

var tmplCreditServicesLoanBrokerAgentsSectionView = require('./CreditServicesLoanBrokerAgentsSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplCreditServicesLoanBrokerAgentsSectionView,

  className: 'credit-services-loan-broker-agents-section-view',

  ui: {
    addAgent: '.btn-add-agent',
    agentCollectionContainer: '.broker-agents-collection-container'
  },

  regions: {
    agentCollectionContainer: '@ui.agentCollectionContainer'
  },

  events: {
    'click @ui.addAgent': 'onClickAddAgent'
  },

  onBeforeShow: function() {
    this.agents = new CreditServicesLoanBrokerAgentCollectionView({
      isReadOnly: this.options.isReadOnly,
      collection: new Backbone.Collection(this.model.get('agents'))
    });

    this.showChildView('agentCollectionContainer', this.agents);
  },

  onClickAddAgent: function(e) {
    e.preventDefault();
    this.agents.collection.unshift({});
  },

  validate: function(config) {
    this.ui.agentCollectionContainer.parsley().reset();

    if (config.type === 'save') {
      parsleyUtil.disableRequiredValidation(this.ui.agentCollectionContainer);
    } else {
      parsleyUtil.enableRequiredValidation(this.ui.agentCollectionContainer);
    }

    return this.ui.agentCollectionContainer.parsley().whenValidate();
  },

  getFormData: function() {
    return this.agents.getFormData();
  }
});
