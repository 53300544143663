/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var NProgress = require('nprogress');

var NavUtils = require('psa-core/util/nav');

var Scaffold = require('common/layout/Scaffold');

var DepProgressReportEditView = require('../views/DepProgressReportEditView');

var GetProgresReport = require('../services/GetProgressReport');
var GetAllEnforcementLookups = require('../services/GetAllEnforcementLookups');
var GetEnforcementIdentifiers = require('../services/GetEnforcementIdentifiers');

module.exports = function(entityId, enforcementId, progressReportId) {
  NProgress.start();
  NProgress.set(0.6);
  $.when(GetEnforcementIdentifiers(enforcementId), GetProgresReport(progressReportId))
    .done(function(enforcementIdentifiers, progressReport) {
      GetAllEnforcementLookups(enforcementIdentifiers.categoryId)
        .done(function(enforcementLookups) {
          NavUtils.navigate(
            'dep/entity/' +
              entityId +
              '/enforcement/' +
              enforcementId +
              '/progress-report/' +
              progressReportId +
              '/edit'
          );
          Scaffold.content.show(
            new DepProgressReportEditView({
              viewMode: 'int',
              model: new Backbone.Model({
                entityId: entityId,
                enforcementId: enforcementId,
                progressReportId: progressReportId,
                enforcementIdentifiers: enforcementIdentifiers,
                progressReport: progressReport,
                quarters: enforcementLookups.quarters,
                progressReportStatuses: enforcementLookups.progressReportStatuses,
                reportYears: enforcementLookups.progressReportYears
              })
            })
          );
          NProgress.done();
        })
        .fail(function(err) {
          NProgress.done();
        });
    })
    .fail(function(err) {
      NProgress.done();
    });
};
