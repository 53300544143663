/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */
var Backbone = require('backbone');
var NProgress = require('nprogress');
var _ = require('underscore');

var GetDocumentTypesByDepartment = require('common/services/GetDocumentTypesByDepartment');

var GetExamDetails = require('../../../exam-dashboard/services/GetExamDetails');
var GetExamFindingsDocuments = require('../../../exam-dashboard/services/GetExamFindingsDocuments');
var GetNextExternalTaskByExam = require('../../services/GetNextExternalTaskByExam');

var DepEntityViewExamDocsTaskView = require('./DepEntityViewExamDocsTaskView');

module.exports = {
  event: 'build:dp:entity-view-exam-documents',

  channel: 'task',

  builder: function(task) {
    var defer = $.Deferred();

    var examId = task.taskData.DP.dpExamId;

    $.when(
      GetExamDetails(examId),
      GetExamFindingsDocuments(examId),
      GetDocumentTypesByDepartment('DEPOSITORY'),
      GetNextExternalTaskByExam(examId)
    )
      .done(function(details, documents, documentTypes, nextTaskId) {
        NProgress.set(0.85);

        documents = _.filter(documents, function(document) {
          return document.documentTypeId !== '20011';
        });

        defer.resolve(
          new DepEntityViewExamDocsTaskView({
            model: new Backbone.Model({
              examDetails: details,
              task: task,
              taskId: task.taskId,
              examId: examId,
              documents: documents,
              documentTypes: documentTypes,
              nextTaskId: nextTaskId
            })
          })
        );
      })
      .fail(function(err) {
        defer.reject(err);
      });
    return defer.promise();
  }
};
