/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(recordCategory) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetAllRecordLookups: {
        $xmlns: 'http://dobs.pa.gov/cf/lookup/bpm/1.0'
      }
    },
    blueprintArrays: [
      'GetAllRecordLookupsResponse.EntityTypes.CF_LU_EntityType',
      'GetAllRecordLookupsResponse.OfferingTypes.CF_LU_OfferingType',
      'GetAllRecordLookupsResponse.RecordCategories.CF_LU_RecordCategory',
      'GetAllRecordLookupsResponse.FeeTypes.CF_LU_FeeType',
      'GetAllRecordLookupsResponse.AffiliateSecondaryNameTypes.CF_LU_AffiliateSecondaryNameType',
      'GetAllRecordLookupsResponse.InvestmentHistoryTransactions.CF_LU_InvestmentHistoryTransaction',
      'GetAllRecordLookupsResponse.RecordTypes.CF_LU_RecordType',
      'GetAllRecordLookupsResponse.RecordStatuses.CF_LU_RecordStatus',
      'GetAllRecordLookupsResponse.FilingApplicationTypes.CF_LU_FilingApplicationType',
      'GetAllRecordLookupsResponse.FilingTypes.CF_LU_FilingType'
    ],
    blueprint: {
      entityTypes: [
        '$..CF_LU_EntityType',
        {
          id: '$..ID',
          type: '$..Type'
        }
      ],
      offeringTypes: [
        '$..CF_LU_OfferingType',
        {
          id: '$..ID',
          type: '$..Type'
        }
      ],
      recordCategories: [
        '$..CF_LU_RecordCategory',
        {
          id: '$..ID',
          status: '$..Category'
        }
      ],
      feeTypes: [
        '$..CF_LU_FeeType',
        {
          id: '$..ID',
          type: '$..Type'
        }
      ],
      affiliateSecondaryNameTypes: [
        '$..CF_LU_AffiliateSecondaryNameType',
        {
          id: '$..ID',
          type: '$..Type'
        }
      ],
      investmentHistoryTransactions: [
        '$..CF_LU_InvestmentHistoryTransaction',
        {
          id: '$..ID',
          status: '$..Status'
        }
      ],
      recordTypes: [
        '$..CF_LU_RecordType',
        {
          id: '$..ID',
          type: '$..Type',
          suffix: '$..Suffix',
          isReg: '$..IsReg',
          isInv: '$..IsInv',
          isExem: '$..IsExem'
        }
      ],
      recordStatuses: [
        '$..CF_LU_RecordStatus',
        {
          id: '$..ID',
          status: '$..Status',
          isReg: '$..IsReg',
          isInv: '$..IsInv',
          isExem: '$..IsExem'
        }
      ],
      filingApplicationTypes: [
        '$..CF_LU_FilingApplicationType',
        {
          id: '$..ID',
          type: '$..Type'
        }
      ],
      filingTypes: [
        '$..CF_LU_FilingType',
        {
          id: '$..ID',
          type: '$..Type',
          filingApplicationTypeId: '$..FilingApplicationTypeID',
          isNotification: '$..IsNotification',
          noticeType: '$..NoticeType',
          downloadFormDocId: '$..DownloadFormDocID',
          downloadFormDocName: '$..DownloadFormDocName'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      switch (recordCategory) {
        case 'Exemption':
          response.recordTypes = _.where(response.recordTypes, { isExem: 'true' });
          response.recordStatuses = _.where(response.recordStatuses, { isExem: 'true' });
          break;
        case 'Investment':
          response.recordTypes = _.where(response.recordTypes, { isInv: 'true' });
          response.recordStatuses = _.where(response.recordStatuses, { isInv: 'true' });
          break;
        case 'Registration':
          response.recordTypes = _.where(response.recordTypes, { isReg: 'true' });
          response.recordStatuses = _.where(response.recordStatuses, { isReg: 'true' });
          break;
        default:
          break;
      }
      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
