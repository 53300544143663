/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */
var _ = require('underscore');
var Marionette = require('backbone.marionette');

var AO1ReviewLicenseBondView = require('./AO1ReviewLicenseBondView');

module.exports = Marionette.CollectionView.extend({
  className: 'ao1-review-license-bond-collection-view',

  childView: AO1ReviewLicenseBondView,

  ui: {},

  childViewOptions: function(model, index) {
    // Pass the index to the collection view
    return {
      childIndex: index,
      entityBondCompanies: this.options.entityBondCompanies,
      entityBondType: this.options.entityBondType,
      entityBondStatus: this.options.entityBondStatus
    };
  },

  childEvents: {
    'remove:bond': 'onChildClickRemoveBond'
  },

  onChildClickRemoveBond: function(childView) {
    // Keep track of deletions to display on internal amendment review page
    var deletedBond = childView.model;
    if (this.options.isAmendment) {
      var deletedBondId = deletedBond.get('id');

      if (deletedBondId) {
        deletedBond.set({ isDeleted: '1' });
        this.deletedBonds.push(deletedBond.toJSON());
      } else {
        deletedBond.set({ isDeleted: '0' });
      }
    }

    this.collection.remove(deletedBond);
  },

  hasOneOfEachBonds: function() {
    var requiredBondTypeIds = _.pluck(this.options.requiredBondTypes, 'id');

    // if there's fewer bonds than there are types, return false
    var numBondTypes = requiredBondTypeIds.length;
    if (this.collection.length < numBondTypes) {
      return false;
    }

    // compare entered bond types to required options
    var selectedBondTypes = this.children.map(function(child) {
      return child.getSelectedBondType();
    });
    return _.intersection(requiredBondTypeIds, selectedBondTypes).length === requiredBondTypeIds.length;
  },

  getFormData: function() {
    var formData = this.children.map(function(child) {
      return child.getFormData();
    });

    return formData;
  }
});
