/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');

var SideNavSectionView = require('common/views/SideNavSectionView');
var switchToPrint = require('common/util/switchToPrint');

var Ladda = require('ladda');

var ConsumerDiscountRenewalGeneralDetailsSectionView = require('./ConsumerDiscountRenewalGeneralDetailsSectionView');
var ConsumerDiscountRenewalCertifyCapitalSectionView = require('./ConsumerDiscountRenewalCertifyCapitalSectionView');
var ConsumerDiscountRenewalBranchesSectionView = require('./ConsumerDiscountRenewalBranchesSectionView');
var ConsumerDiscountRenewalAffirmationSectionView = require('./ConsumerDiscountRenewalAffirmationSectionView');
var ConsumerDiscountRenewalRequirementsView = require('./ConsumerDiscountRenewalRequirementsView');

var tmplConsumerDiscountRenewalApplicationView = require('./ConsumerDiscountRenewalApplicationView.hbs');

var SubmitConsumerDiscountRenewalApplication = require('../../services/SubmitConsumerDiscountRenewalApplication');

module.exports = Marionette.LayoutView.extend({
  template: tmplConsumerDiscountRenewalApplicationView,

  className: 'consumer-discount-renewal-application-view row m-b-md',

  behaviors: {
    ReadOnlyFormBehavior: {}
  },

  ui: {
    consumerDiscountRenewalApplicationContainer: '.consumer-discount-renewal-application-container'
  },

  regions: {
    consumerDiscountRenewalApplicationContainer: '@ui.consumerDiscountRenewalApplicationContainer'
  },

  onBeforeShow: function() {
    var mode = this.options.mode;
    var isForPrinting = mode === 'print';
    var isExpandInternal = mode === 'int-expand';

    this.form = new SideNavSectionView({
      useNavigationButtons: !this.options.isReadOnly,
      expanded: isForPrinting || isExpandInternal,
      collection: new Backbone.Collection([
        {
          id: 'consumerDiscountRenewalRequirements',
          title: 'Requirements',
          view: new ConsumerDiscountRenewalRequirementsView({
            mode: this.options.isReadOnly,
            model: this.model
          })
        },
        {
          id: 'consumerDiscountRenewalGeneralDetails',
          title: 'General Details',
          view: new ConsumerDiscountRenewalGeneralDetailsSectionView({
            isReadOnly: this.options.isReadOnly,
            model: this.model
          })
        },
        {
          id: 'consumerDiscountRenewalCertifyCapital',
          title: 'Certification of Capital',
          view: new ConsumerDiscountRenewalCertifyCapitalSectionView({
            isReadOnly: this.options.isReadOnly,
            model: this.model
          })
        },
        {
          id: 'consumerDiscountRenewalBranches',
          title: 'Branch Licenses',
          view: new ConsumerDiscountRenewalBranchesSectionView({
            isReadOnly: this.options.isReadOnly,
            model: this.model
          })
        },
        {
          id: 'consumerDiscountRenewalAffirmation',
          title: 'Affirmation',
          view: new ConsumerDiscountRenewalAffirmationSectionView({
            mode: this.options.mode,
            model: this.model
          })
        }
      ]),
      buttons: [
        {
          buttonText: 'Save',
          buttonClass: 'btn-default ladda-button',
          buttonIconClass: 'fa-save',
          labelClass: 'ladda-label',
          buttonAttrs: {
            'data-style': 'expand-right'
          },
          onClick: this.onClickSave.bind(this)
        }
      ]
    });

    this.showChildView('consumerDiscountRenewalApplicationContainer', this.form);
  },

  onAttach: function() {
    if (this.options.mode === 'print') {
      switchToPrint(this.$el, '.input-view');
    }
  },

  onClickSave: function(e) {
    e.preventDefault();
    var self = this;
    self.form.validateAll({ type: 'save' }).done(function() {
      var l = Ladda.create(e.currentTarget);
      l.start();
      var rawFormData = self.form.getFormData();
      var formData = {
        applicationId: self.model.get('applicationDetails').applicationId,
        generalDetails: rawFormData.consumerDiscountRenewalGeneralDetails,
        certifyCapital: rawFormData.consumerDiscountRenewalCertifyCapital,
        branchLicenses: rawFormData.consumerDiscountRenewalBranches,
        affirmation: rawFormData.consumerDiscountRenewalAffirmation
      };

      SubmitConsumerDiscountRenewalApplication(formData)
        .done(function() {
          l.stop();
        })
        .fail(function() {
          l.stop();
        });
    });
  }
});
