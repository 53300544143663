/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Radio = require('backbone.radio');
var Ladda = require('ladda');
var moment = require('moment');

var Session = require('psa-core/Session');

var modelGet = require('common/util/modelGet');
var toggleForm = require('common/util/toggleForm');
var HeaderBarView = require('common/views/HeaderBarView');
var BreadcrumbView = require('common/views/BreadcrumbView');
var FileUploadView = require('common/views/FileUploadView');
var FileUploadCollection = require('psa-backbone/models/FileUploadCollection');

var SubmitResolveExamTask = require('../../services/SubmitResolveExamTask');

var tmplSecResolveExamTaskView = require('./SecResolveExamTaskView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplSecResolveExamTaskView,

  className: 'sec-resolve-exam-task-view container-fluid',

  ui: {
    headerBar: '.header-bar',
    breadcrumb: '.breadcrumb-container',
    resolveExamForm: '.resolve-exam-form',
    action: '.action',
    needAdditionalInfo: '.need-additional-info',
    extAttachmentsContainer: '.ext-attachments-container',
    subsequentRequestContainer: '.subsequent-request-container',
    submit: '.submit',
    missingFile: '.missing-file',
    externalComments: 'textarea[name="externalComments"]'
  },

  regions: {
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb',
    extAttachmentsContainer: '@ui.extAttachmentsContainer',
    subsequentRequestContainer: '@ui.subsequentRequestContainer'
  },

  behaviors: {
    TaskBehavior: {}
  },

  events: {
    'change @ui.action': 'onChangeAction',
    'click @ui.submit': 'onClickSubmit'
  },

  templateHelpers: function() {
    var docPhaseId = modelGet(this.model, 'exam.documentPhaseId');
    var isRFI = docPhaseId === '2';
    var isSubsequentRequest = docPhaseId === '3';
    return {
      showExternalReply: isRFI || isSubsequentRequest
    };
  },

  onBeforeRender: function() {
    // Only show 'Need additional info' and 'Schedule exam' as options for Action
    var actions = _.filter(this.model.get('actions'), function(action) {
      return action.id === '1' || action.id === '2';
    });
    this.model.set({ actions: actions });
  },

  onBeforeShow: function() {
    var exam = this.model.get('exam') || {};
    var examDocuments = exam.examDocuments || [];
    var docPhaseId = exam.documentPhaseId;

    var headerBarColumns = [
      {
        name: 'applicantLegalName',
        label: 'Applicant Legal Name',
        minWidth: '160px',
        maxWidth: '300px'
      },
      {
        name: 'crdNumber',
        label: 'CRD Number',
        minWidth: '160px',
        maxWidth: '300px'
      },
      {
        name: 'examNumber',
        label: 'Exam Number',
        minWidth: '160px',
        maxWidth: '300px'
      },
      {
        name: 'examType',
        label: 'Exam Type',
        minWidth: '160px',
        maxWidth: '300px'
      },
      {
        name: 'primaryExaminer',
        label: 'Primary Examiner',
        minWidth: '160px',
        maxWidth: '300px'
      }
    ];

    this.showChildView(
      'headerBar',
      new HeaderBarView({
        columns: new Backbone.Collection(headerBarColumns),
        model: new Backbone.Model(exam)
      })
    );

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#sec/dashboard',
            text: 'Home'
          },
          {
            text: modelGet(this.model, 'task.taskType.description')
          }
        ]
      })
    );

    var internalComments;
    if (exam.internalComments) {
      internalComments = '\n\n' + exam.internalComments;
    } else {
      internalComments = '';
    }

    exam.internalComments = internalComments;

    Syphon.deserialize(this, exam);

    var isRFI = docPhaseId === '2';
    var isSubsequentRequest = docPhaseId === '3';
    var showExternalReply = isRFI || isSubsequentRequest;
    if (showExternalReply) {
      this.extAttachmentsView = new FileUploadView({
        collection: new FileUploadCollection(examDocuments),
        emptyText: 'No External Attachments',
        isReadOnly: true
      });
      this.showChildView('extAttachmentsContainer', this.extAttachmentsView);
    }

    this.subsequentRequestView = new FileUploadView({
      collection: new Backbone.Collection(),
      isRequired: true,
      allowMultipleFiles: true,
      metadata: {
        documentTypeId: 30014
      }
    });
    this.showChildView('subsequentRequestContainer', this.subsequentRequestView);
  },

  onChangeAction: function() {
    var needAdditionalInfo = this.ui.action.val() === '1';
    toggleForm(this.ui.needAdditionalInfo, needAdditionalInfo);
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;
    var formData = this.getFormData();

    this.validate().done(function() {
      var l = Ladda.create(self.ui.submit[0]);
      l.start();
      SubmitResolveExamTask(formData)
        .done(function() {
          Radio.channel('navigate').trigger('show:int:sc:dashboard');
        })
        .fail(function() {
          l.stop();
        });
    });
  },

  validateAdditionalInfo: function() {
    // Check that either external comments or subsequent
    // request attachment exists

    var defer = $.Deferred();
    this.ui.missingFile.hide();

    var hasComments = !!this.ui.externalComments.val();
    var uploads = this.subsequentRequestView.collection;
    var hasSuccessfulFile = false;

    if (uploads) {
      hasSuccessfulFile = uploads.some(function(model) {
        return model.get('fileId');
      });
    }

    if (hasSuccessfulFile || hasComments) {
      defer.resolve();
    } else {
      this.ui.missingFile.show();
      defer.reject();
    }

    return defer.promise();
  },

  validate: function() {
    this.ui.resolveExamForm.parsley().reset();
    this.subsequentRequestView.removeValidationErrors();

    var validationPromises = [this.ui.resolveExamForm.parsley().whenValidate()];

    var needAdditionalInfo = this.ui.action.val() === '1';
    if (needAdditionalInfo) {
      validationPromises.push(this.validateAdditionalInfo());
    }

    return $.when.apply($, validationPromises);
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    formData.examId = this.model.get('examId');
    formData.taskId = this.model.get('taskId');

    var needAdditionalInfo = this.ui.action.val() === '1';
    if (needAdditionalInfo) {
      formData.subsequentRequestDocumentIds = this.subsequentRequestView.collection.pluck('fileId');
    }

    var timestamp = moment().format('MM/DD/YYYY h:mm A');
    var userName = Session.user.description;

    formData.internalComments = '[' + userName + ' - ' + timestamp + '] \n' + formData.internalComments;

    return formData;
  }
});