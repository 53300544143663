var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "            <div class=\"checkbox\">\r\n              <label>\r\n                <input type=\"checkbox\" class=\"license-to-surrender\" name=\"branchLicense\" id="
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + " value="
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\r\n                  data-parsley-multiple=\"license-checkbox\">License #\r\n                "
    + alias4(((helper = (helper = helpers.licenseNumber || (depth0 != null ? depth0.licenseNumber : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"licenseNumber","hash":{},"data":data}) : helper)))
    + " - "
    + alias4(((helper = (helper = helpers.AddressLine1 || (depth0 != null ? depth0.AddressLine1 : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"AddressLine1","hash":{},"data":data}) : helper)))
    + "\r\n              </label>\r\n            </div>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "      <div class=\"form-group required\">\r\n        <label class=\"control-label col-sm-3\">Affirmation</label>\r\n        <label class=\"control-label font-weight-normal text-left col-sm-9\">\r\n          <input type=\"checkbox\" name=\"isCertified\" value=\"1\" data-parsley-required=\"true\"\r\n            data-parsley-errors-container=\".affirmation-error-container\" />\r\n          I understand by submitting this Surrender License Application: I am agreeing to be bound by the\r\n          following\r\n          declaration: \"I declare that all of my answers on this License Application are complete, true and correct. I\r\n          make this declaration subject to the penalties of 18 PA.C.S. § 4904 relating to unsworn falsification to\r\n          authorities.\"\r\n        </label>\r\n        <div class=\"col-sm-offset-3 col-sm-9\">\r\n          <div class=\"affirmation-error-container\"></div>\r\n        </div>\r\n      </div>\r\n      <div class=\"form-group required\">\r\n        <div class=\"col-sm-offset-3 col-sm-9\">\r\n          <label><strong>Enter Name Below</strong></label>\r\n          <input type=\"text\" class=\"form-control\" name=\"certificationName\" data-parsley-required=\"true\"\r\n            data-parsley-maxlength=\"100\" />\r\n        </div>\r\n      </div>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda;

  return "\r\n<div class=\"col-sm-8 col-sm-offset-1 m-b-lg\">\r\n  <div class=\"form-horizontal\">\r\n    <form class=\"license-surrender-form\">\r\n      <div class=\"form-group required\">\r\n        <label class=\"control-label col-sm-3\">Effective Date of Surrender</label>\r\n        <div class=\"col-sm-4\">\r\n          <div class=\"input-group\">\r\n            <input type=\"text\" name=\"surrenderDate\" class=\"form-control datepicker surrender-date\"\r\n              placeholder=\"Date of Surrender\" data-parsley-required=\"true\"\r\n              data-parsley-date=\"\" data-parsley-trigger-after-failure=\"changeDate\" \r\n              data-parsley-errors-container=\"#dateSurrenderedErrorContainer\"/>\r\n            <div class=\"input-group-addon\">\r\n              <i class=\"fa fa-calendar\"></i>\r\n            </div>\r\n          </div>\r\n          <div class=\"dateSurrenderedErrorContainer\"></div>\r\n        </div>\r\n      </div>\r\n      <div class=\"form-group required surrenderReason\">\r\n        <label class=\"control-label col-sm-3\">Reason for license surrender</label>\r\n        <div class=\"col-sm-9\">\r\n          <textarea name=\"reason\" class=\"form-control input-view\" data-parsley-required=\"true\"\r\n            placeholder=\"Please provide reason for license surrender.\" rows=\"6\">"
    + alias4(((helper = (helper = helpers.reason || (depth0 != null ? depth0.reason : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"reason","hash":{},"data":data}) : helper)))
    + "</textarea>\r\n          <div class=\"pre-wrap\" id=\"reasonPrintView\"></div>\r\n        </div>\r\n      </div>\r\n\r\n      <div class=\"form-group\">\r\n        <label class=\"control-label col-sm-3\">Upload attachments</label>\r\n        <p class=\"col-sm-9 file-upload-instruction\">Please Attach the following information on the status of each loan\r\n          to Pennsylvania consumers that are outstanding:<br />\r\n          &#8226; Name of Consumer <br />\r\n          &#8226; Consumer Address and telephone number <br />\r\n          &#8226; Current Application Status <br />\r\n          &#8226; Loan Number <br />\r\n          &#8226; Amount of Loan <br />\r\n          &#8226; Contact information for applicable lender with who each loan will be placed <br />\r\n          &#8226; Date loan will be resolved <br />\r\n        </p>\r\n        <div class=\"file-upload-container col-sm-9 col-sm-offset-3\">\r\n        </div>\r\n        <div class=\"col-sm-9 col-sm-offset-3 file-upload-error-container help-block collapse\">\r\n          <span>At least one attachment is required.</span>\r\n        </div>\r\n      </div>\r\n      <div class=\"form-group required\">\r\n        <label class=\"control-label col-sm-3\">Licenses to Surrender:</label>\r\n        <div class=\"col-sm-9 license-list\">\r\n          <div class=\"checkbox\">\r\n            <label>\r\n              <input type=\"checkbox\" class=\"license-to-surrender\" name=\"principleLicense\" id=\""
    + alias4(((helper = (helper = helpers.licenseId || (depth0 != null ? depth0.licenseId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"licenseId","hash":{},"data":data}) : helper)))
    + "\"\r\n                value=\""
    + alias4(((helper = (helper = helpers.licenseId || (depth0 != null ? depth0.licenseId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"licenseId","hash":{},"data":data}) : helper)))
    + "\" data-parsley-multiple=\"license-checkbox\" data-parsley-required=\"true\"\r\n                data-parsley-errors-container=\"#license-checkbox-error-container\"\r\n                data-parsley-error-message=\"At least one license must be selected.\">License #\r\n              "
    + alias4(alias5(((stack1 = (depth0 != null ? depth0.licenseDashboard : depth0)) != null ? stack1.licenseNumber : stack1), depth0))
    + " -\r\n              "
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? depth0.licenseDashboard : depth0)) != null ? stack1.licenseAddress : stack1)) != null ? stack1.addressLine1 : stack1), depth0))
    + "\r\n            </label>\r\n          </div>\r\n          <div class=\"branch-license-container\">\r\n            <h4 class=\"m-b-n m-t-md\">Branch License(s)</h4>\r\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.childLicenses : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "          </div>\r\n          <span id=\"license-checkbox-error-container\"></span>\r\n        </div>\r\n      </div>\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showAffirmation : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </form>\r\n    <div class=\"row\">\r\n      <div class=\"btn-container col-sm-9 col-sm-offset-3\">\r\n        <a href=\"#ext/nondep/entity/"
    + alias4(((helper = (helper = helpers.entityId || (depth0 != null ? depth0.entityId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"entityId","hash":{},"data":data}) : helper)))
    + "/license/"
    + alias4(((helper = (helper = helpers.licenseId || (depth0 != null ? depth0.licenseId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"licenseId","hash":{},"data":data}) : helper)))
    + "\" class=\"btn btn-default cancel-button\"\r\n          role=\"button\">Cancel</a>\r\n        <button class=\"btn btn-primary submit-button ladda-button\" data-style=\"expand-right\" type=\"button\">\r\n          <spanclass=\"ladda-label\">Submit</span>\r\n        </button>\r\n      </div>\r\n    </div>\r\n  </div>\r\n</div>";
},"useData":true});