/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');

var InvoiceGridView = require('common/views/InvoiceGridView');

// require('../styles/ExamPaymentDetailsView.css');
var tmplExamPaymentDetailsView = require('../templates/ExamPaymentDetailsView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplExamPaymentDetailsView,

  className: 'exam-payment-details-view',

  ui: {
    examInvoiceContainer: '.exam-invoice-container'
  },

  regions: {
    examInvoiceContainer: '@ui.examInvoiceContainer'
  },

  onBeforeShow: function() {
    this.showChildView(
      'examInvoiceContainer',
      new InvoiceGridView({
        emptyText: 'No Exam Billing Items Found',
        collection: new Backbone.Collection(this.model.get('examInvoiceItems'))
      })
    );
  }
});
