var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "            <option value=\""
    + alias4(((helper = (helper = helpers.statusId || (depth0 != null ? depth0.statusId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"statusId","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.status || (depth0 != null ? depth0.status : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"status","hash":{},"data":data}) : helper)))
    + "</option>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "            <option value=\""
    + alias4(((helper = (helper = helpers.officeTypeId || (depth0 != null ? depth0.officeTypeId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"officeTypeId","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.officeType || (depth0 != null ? depth0.officeType : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"officeType","hash":{},"data":data}) : helper)))
    + "</option>\r\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "            <option value=\""
    + alias4(((helper = (helper = helpers.locationTypeId || (depth0 != null ? depth0.locationTypeId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"locationTypeId","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.locationType || (depth0 != null ? depth0.locationType : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"locationType","hash":{},"data":data}) : helper)))
    + "</option>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "\r\n<div class=\"form-horizontal\">\r\n  <div class=\"row\">\r\n    <div class=\"col-sm-6\">\r\n      <div class=\"form-group required\">\r\n        <label class=\"control-label col-sm-6\">Status</label>\r\n        <div class=\"col-sm-6\">\r\n          <select name=\"statusId\" class=\"location-status-select form-control\" data-parsley-required=\"true\">\r\n            <option value=\"\">--</option>\r\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.locationStatus : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "          </select>\r\n        </div>\r\n      </div>\r\n    </div>\r\n    <div class=\"col-sm-6\">\r\n      <div class=\"form-group \">\r\n        <label class=\"control-label col-sm-6\">Date Opened</label>\r\n        <div class=\"col-sm-6\">\r\n          <div class=\"input-group\">\r\n            <input type=\"text\" name=\"openedDate\" class=\"form-control datepicker opened-Date\" placeholder=\"Date Opened\"\r\n               data-parsley-errors-container=\"#openDateErrorsContainer\" data-parsley-date=\"\"\r\n              data-parsley-trigger-after-failure=\"changeDate\" />\r\n            <div class=\"input-group-addon\">\r\n              <i class=\"fa fa-calendar\"></i>\r\n            </div>\r\n          </div>\r\n        </div>\r\n        <div class=\"col-sm-6 col-sm-offset-6\">\r\n          <div id=\"openDateErrorsContainer\"></div>\r\n        </div>\r\n      </div>\r\n    </div>\r\n  </div>\r\n  <div class=\"row\">\r\n    <div class=\"col-sm-6\">\r\n      <div class=\"form-group\">\r\n        <label class=\"control-label col-sm-6\">Office Type</label>\r\n        <div class=\"col-sm-6\">\r\n          <select name=\"officeTypeId\" class=\"office-type-select form-control\">\r\n            <option value=\"\">--</option>\r\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.locationOfficeType : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "          </select>\r\n        </div>\r\n      </div>\r\n    </div>\r\n    <div class=\"col-sm-6\">\r\n      <div class=\"form-group \">\r\n        <label class=\"control-label col-sm-6\">Date Closed</label>\r\n        <div class=\"col-sm-6\">\r\n          <div class=\"input-group\">\r\n            <input type=\"text\" name=\"closedDate\" class=\"form-control datepicker closed-Date\" placeholder=\"Date Closed\"\r\n              data-parsley-errors-container=\"#closedDateChangeErrorsContainer\" data-parsley-date=\"\"\r\n              data-parsley-trigger-after-failure=\"changeDate\" />\r\n            <div class=\"input-group-addon\">\r\n              <i class=\"fa fa-calendar\"></i>\r\n            </div>\r\n          </div>\r\n        </div>\r\n        <div class=\"col-sm-6 col-sm-offset-6\">\r\n          <div id=\"closedDateChangeErrorsContainer\"></div>\r\n        </div>\r\n      </div>\r\n    </div>\r\n  </div>\r\n  <div class=\"row\">\r\n    <div class=\"col-sm-6 activity-type\">\r\n      <div class=\"form-group\">\r\n        <label class=\"control-label col-sm-6\">Activities</label>\r\n        <div class=\"col-sm-6\">\r\n          <div class=\"activity-type-select\"></div>\r\n        </div>\r\n      </div>\r\n    </div>\r\n    <div class=\"col-sm-6\">\r\n      <div class=\"form-group\">\r\n        <label class=\"control-label col-sm-6\">New Location Type</label>\r\n        <div class=\"col-sm-6\">\r\n          <select name=\"locationTypeId\" class=\"form-control\">\r\n            <option value=\"\">--</option>\r\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.locationType : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "          </select>\r\n        </div>\r\n      </div>\r\n    </div>\r\n  </div>\r\n  <div class=\"address\"></div>\r\n  <div class=\"form-group relocated-to\">\r\n    <label class=\"control-label col-sm-3\">Relocated To</label>\r\n    <div class=\"col-sm-9\">\r\n      <textarea class=\"form-control\" rows=\"4\" cols=\"100\" name=\"relocatedTo\"\r\n        placeholder=\"Address, City, State, Zip and County where it was Relocated To\"></textarea>\r\n    </div>\r\n  </div>\r\n  <div class=\"form-group Comments\">\r\n    <label class=\"control-label col-sm-3\">Comments</label>\r\n    <div class=\"col-sm-9\">\r\n      <textarea class=\"form-control\" rows=\"4\" cols=\"100\" name=\"comments\" placeholder=\"Comments\"></textarea>\r\n    </div>\r\n  </div>\r\n</div>";
},"useData":true});