/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Ladda = require('ladda');
var Radio = require('backbone.radio');

var AddressDisplayView = require('common/views/AddressDisplayView');
var SelectFieldView = require('common/views/SelectFieldView');
var BreadcrumbView = require('common/views/BreadcrumbView');
var addressToString = require('common/util/addressToString');

var EntityHeaderBarView = require('../../common/views/EntityHeaderBarView');
var GetActiveLicensesByTypeAndEntity = require('../services/GetActiveLicensesByTypeAndEntity');
var SubmitCreateNewLicense = require('../services/SubmitCreateNewLicense');

var tmplNonDepEntityNewLicenseView = require('../templates/NonDepEntityNewLicenseView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepEntityNewLicenseView,

  className: 'nondep-entity-new-license-view container-fluid',

  ui: {
    headerBar: '.header-bar',
    breadcrumb: '.breadcrumb-container',
    applyForLicenseForm: '.apply-for-license-form',
    licenseTypeSelect: '.license-type-select',
    parentLicenseFormGroup: '.parent-license-form-group',
    parentLicenseSelectContainer: '.parent-license-select-container',
    parentLicenseSelect: '.parent-license-select',
    locationSelect: '.location-select',
    submit: '.submit',
    effectiveStartDate: '.effective-start-date',
    effectiveEndDate: '.effective-end-date'
  },

  regions: {
    parentLicenseSelectContainer: '@ui.parentLicenseSelectContainer',
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb'
  },

  events: {
    'change @ui.licenseTypeSelect': 'onChangeLicenseType',
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeShow: function() {
    this.showChildView(
      'headerBar',
      new EntityHeaderBarView({
        model: new Backbone.Model(this.model.get('entityIdentifiers'))
      })
    );

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#nondep/dashboard',
            text: 'Home'
          },
          {
            link: '#nondep/entity/' + this.model.get('entityIdentifiers').id + '/dashboard',
            text: this.model.get('entityIdentifiers').entityName
          },
          {
            text: 'Create New License'
          }
        ]
      })
    );

    this.ui.locationSelect.select2({
      allowClear: true,
      data: this.model.get('entityLocations'),
      dropdownCssClass: 'select2-options-bordered',
      templateResult: function(location) {
        var addressMarkup = new AddressDisplayView({
          model: new Backbone.Model(location)
        }).render().el.outerHTML;
        if (location.isPrimaryPlaceOfBusiness) {
          addressMarkup += '<span class="label label-primary">Headquarters</span>';
        }
        return $(addressMarkup);
      },
      templateSelection: function(location) {
        if (location.addressLine1) {
          return $('<span>' + location.addressLine1 + '</span>');
        } else {
          return $('<span>--</span>');
        }
      },
      matcher: function(params, data) {
        // If there are no search terms, return all of the data
        if (!params.term || params.term.trim() === '') {
          return data;
        }
        // Reduce address to single string for matching.
        var addressStr = addressToString(data, { separator: ' ' }) || '';
        var searchTerm = params.term.toLowerCase();
        var target = addressStr.toLowerCase();
        if (target.indexOf(searchTerm) > -1) {
          return data;
        }
        // Return `null` if the term should not be displayed
        return null;
      }
    });

    this.parentLicenseSelect = new SelectFieldView({
      isDisabled: true,
      isRequired: true,
      name: 'parentLicenseId',
      selectClass: 'parent-license-select',
      collection: new Backbone.Collection([])
    });
    this.showChildView('parentLicenseSelectContainer', this.parentLicenseSelect);

    this.ui.effectiveStartDate.datepicker({});
    this.ui.effectiveEndDate.datepicker({});
  },

  onChangeLicenseType: function(e) {
    var self = this;
    var formData = Syphon.serialize(this);
    var licenseTypes = this.model.get('licenseTypes');
    if (formData.licenseTypeId) {
      var selectedLicenseType = _.findWhere(licenseTypes, {
        id: formData.licenseTypeId
      });
      if (selectedLicenseType.isBranch) {
        var data = {
          licenseTypeId: selectedLicenseType.branchParentLicenseTypeId,
          entityId: this.model.get('entityIdentifiers').id
        };
        GetActiveLicensesByTypeAndEntity(data)
          .done(function(licenses) {
            var options = licenses.map(function(license) {
              return {
                value: license.id,
                text: license.licenseType + ' License #' + license.licenseNumber + ' at ' + license.addressLine1
              };
            });
            self.parentLicenseSelect.collection.reset(options);
            self.setParentLicenseDisabled(false);
          })
          .fail(function(err) {
            self.setParentLicenseDisabled(true);
          });
      } else {
        this.setParentLicenseDisabled(true);
      }
    } else {
      this.setParentLicenseDisabled(true);
    }
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;
    var l = Ladda.create(this.ui.submit[0]);
    l.start();
    this.ui.applyForLicenseForm
      .parsley()
      .whenValidate()
      .done(function() {
        var formData = Syphon.serialize(self);
        SubmitCreateNewLicense(formData)
          .done(function(licenseId) {
            Radio.channel('navigate').trigger('show:int:nd:license-dashboard', self.model.get('entityId'), licenseId);
          })
          .fail(function() {
            l.stop();
          });
      })
      .fail(function() {
        l.stop();
      });
  },

  setParentLicenseDisabled: function(isDisabled) {
    if (isDisabled) {
      this.parentLicenseSelect.setValue('');
      this.ui.parentLicenseFormGroup.addClass('disabled');
      this.parentLicenseSelect.setDisabled(true);
    } else {
      this.ui.parentLicenseFormGroup.removeClass('disabled');
      this.parentLicenseSelect.setDisabled(false);
    }
  }
});
