/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backgrid = require('backgrid');

var getUserIdFromDn = require('../../util/getUserIdFromDn');

var tmplAssigneeCell = require('./AssigneeCell.hbs');

/**
 * The AssigneeCell expects the cell value to have the following structure:
 * @typedef {Object} AssigneDetails
 * @property {string|null} assignee Name of the user who is currently assigned to the task
 * @property {'role'|'user'} targetType Whether the task is for a role or user
 * @property {string} targetDn Target DN
 * @example
 * {
 *   assignee: 'John Doe',
 *   targetType: 'role',
 *   targetDn: 'cn=Application Reviewer,cn=DoBS Roles,cn=cordys,cn=dev,o=PA.LCL'
 * }
 */
var AssigneeCell = Backgrid.Cell.extend({
  className: 'assignee-cell',

  render: function() {
    var cellValue = this.model.get(this.column.get('name')) || {};
    var assignee = cellValue.assignee;
    var targetType = cellValue.targetType;
    var targetDn = cellValue.targetDn;
    var targetName = getUserIdFromDn(targetDn);
    var showEmptyIndicator = false;
    var content = '--';
    if (assignee || targetName) {
      content = tmplAssigneeCell({
        isAssigned: Boolean(assignee),
        showEmptyIndicator: showEmptyIndicator,
        targetType: targetType,
        targetName: targetName,
        assignee: assignee
      });
    }
    this.$el.empty().append(content);
    this.$el.addClass(this.column.get('name'));
    this.delegateEvents();
    return this;
  }
});

var AssigneeSortValue = function(rowModel, columnName) {
  var cellValue = rowModel.get(columnName);
  var assignee = cellValue.assignee;
  var targetDn = cellValue.targetDn;
  var targetName = getUserIdFromDn(targetDn);
  if (assignee) {
    return assignee;
  }
  if (targetName) {
    return targetName;
  }
  return -1;
};

module.exports = {
  AssigneeCell: AssigneeCell,
  AssigneeSortValue: AssigneeSortValue
};
