/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var NProgress = require('nprogress');
var Backbone = require('backbone');

var NavUtils = require('psa-core/util/nav');

var Scaffold = require('common/layout/Scaffold');
var GetEntityIdentifiers = require('../../common/services/GetEntityIdentifiers');

var NonDepEntityNewLocationView = require('../views/NonDepEntityNewLocationView');

module.exports = function(args) {
  NProgress.start();
  NProgress.set(0.6);

  var entityId = args.entityId;

  GetEntityIdentifiers(entityId)
    .done(function(entityIdentifier) {
      if (args.viewMode === 'int') {
        NavUtils.navigate('nondep/entity/' + entityId + '/location/new');
      } else {
        NavUtils.navigate('ext/nondep/entity/' + entityId + '/location/new');
      }

      Scaffold.content.show(
        new NonDepEntityNewLocationView({
          viewMode: args.viewMode,
          model: new Backbone.Model(
            _.extend(
              {
                entityId: entityId
              },
              entityIdentifier
            )
          )
        })
      );
      NProgress.done();
    })
    .fail(function(err) {
      NProgress.done();
    });
};
