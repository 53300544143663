/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var _get = require('lodash.get');
var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');
var getUserIdFromDn = require('common/util/getUserIdFromDn');

module.exports = function() {
  var deferred = $.Deferred();
  $.soap({
    body: {
      GetAvailableTasks: {
        $xmlns: 'http://dobs.pa.gov/dp/task/bpm/1.0'
      }
    },
    blueprintArrays: [
      'GetAvailableTasksResponse.Tasks.NOTF_TASK_INSTANCE',
      'GetAvailableTasksResponse.Filings.DP_Filing',
      'GetAvailableTasksResponse.Exams.DP_EntityExam',
      'GetAvailableTasksResponse.Correspondences.DP_Correspondence',
      'GetAvailableTasksResponse.TaskTypes.CM_LU_TaskType'
    ],
    blueprint: {
      tasks: [
        '$..NOTF_TASK_INSTANCE',
        {
          taskId: '$..TaskId',
          deliveryDate: '$..DeliveryDate',
          entityId: '$..DPEntityID',
          examId: '$..DPExamID',
          correspondenceId: '$..DPCorrespondenceID',
          taskTypeName: '$..TaskTypeName',
          filingId: '$..DPFilingID',
          assignedRole: '$..Target'
        }
      ],
      taskMetaData: {
        taskTypes: [
          '$..CM_LU_TaskType',
          {
            id: '$.ID',
            name: '$.Name',
            description: '$.Description'
          }
        ],
        filings: [
          '$..DP_Filing',
          {
            id: '$..FilingID',
            entityName: '$..EntityName',
            filingType: '$..FilingType',
            decisionDueDate: '$..DecisionDueDate',
            submissionTypeName: '$..SubmissionTypeName',
            filingTypeId: '$..FilingTypeID'
          }
        ],
        exams: [
          '$..DP_EntityExam',
          {
            id: '$..ID',
            entityName: '$..EntityName',
            examType: '$..ExamType',
            dueDate: '$..DueDate'
          }
        ],
        correspondences: [
          '$..DP_Correspondence',
          {
            id: '$..CorrespondenceID',
            entityId: '$..EntityID',
            entityName: '$..EntityName'
          }
        ]
      }
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      _.each(response.tasks, function(task) {
        task.deliveryDate = moment.utc(task.deliveryDate, moment.ISO_8601).local();

        var entityId = _get(task, 'entityId', {});
        var taskTypeName = _get(task, 'taskTypeName', {});
        var filingId = _get(task, 'filingId', {});
        var correspondenceId = _get(task, 'correspondenceId', {});
        var examId = _get(task, 'examId', {});

        if (taskTypeName.__text) {
          taskTypeName = taskTypeName.__text;
        }

        if (entityId.__text) {
          entityId = entityId.__text;
        }

        if (filingId.__text) {
          filingId = filingId.__text;
        }

        if (correspondenceId.__text) {
          correspondenceId = correspondenceId.__text;
        }

        if (task.assignedRole && task.assignedRole.__text) {
          task.assignedRole = getUserIdFromDn(task.assignedRole.__text);
        } else {
          task.assignedRole = getUserIdFromDn(task.assignedRole);
        }

        var type = _.find(response.taskMetaData.taskTypes, function(type) {
          return taskTypeName === type.name;
        });

        task.type = type.description;

        if (filingId) {
          var filing = _.find(response.taskMetaData.filings, function(filing) {
            return filingId === filing.id;
          });

          if (filing) {
            //add submission type to filing type if generic submission
            if (_.includes(['100', '200', '300'], filing.filingTypeId) && filing.submissionTypeName) {
              task.filingType = filing.filingType + ' - ' + filing.submissionTypeName;
            } else {
              task.filingType = filing.filingType;
            }
            task.entityName = filing.entityName;
            if (filing.decisionDueDate) {
              task.dueDate = moment(filing.decisionDueDate, 'YYYY-MM-DD');
            }
            // Update task name for submissions
            if (_.includes(['111', '205', '305', '306', '307'], filing.filingTypeId)) {
              task.type = 'Review Submission';
            }
          }
        }

        if (correspondenceId) {
          var correspondence = _.find(response.taskMetaData.correspondences, function(correspondence) {
            return correspondenceId === correspondence.id;
          });
          if (correspondence) {
            task.entityName = correspondence.entityName;
          }
        }

        if (examId) {
          var exam = _.find(response.taskMetaData.exams, function(exam) {
            return examId === exam.id;
          });
          if (exam) {
            task.entityName = exam.entityName;
            if (exam.dueDate) {
              task.dueDate = moment(exam.dueDate, 'YYYY-MM-DD');
            }
            task.filingType = '--';
          }
        }
      });
      deferred.resolve(response.tasks);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
