/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');

var modelGet = require('common/util/modelGet');

var GridView = require('psa-marionette/views/GridView');
var PageableCollection = require('psa-backbone/models/PageableCollection');

var MomentCell = require('common/backgrid/MomentCell');
var ButtonLinkCell = require('common/backgrid/ButtonLinkCell');

var tmpleNonDepLicenseContactHistoryView = require('../templates/NonDepLicenseContactHistoryView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmpleNonDepLicenseContactHistoryView,

  className: 'non-dep-license-contact-history-view',

  ui: {
    licenseContactHistoryGridContainer: '.license-contact-history-grid-container'
  },

  regions: {
    licenseContactHistoryGridContainer: '@ui.licenseContactHistoryGridContainer'
  },

  onBeforeShow: function() {
    var entityId = this.model.get('entityId');
    var licenseId = this.model.get('licenseId');

    this.contactHistoryGrid = new GridView({
      columns: [
        {
          name: 'createdDate',
          label: 'Date Changed',
          cell: MomentCell.MomentCell,
          formatter: MomentCell.MomentDateTimeFormatter.extend({
            emptyValue: '--'
          }),
          sortValue: MomentCell.MomentSortValue,
          headerCell: 'custom',
          width: 40,
          editable: false
        },
        {
          name: 'createdBy',
          label: 'Changed By',
          cell: 'string',
          headerCell: 'custom',
          width: 40,
          editable: false
        },
        {
          name: '',
          label: '',
          cell: ButtonLinkCell.extend({
            name: 'Open',
            newTab: true,
            buttonClass: 'btn-primary btn-sm',
            href: function(model) {
              var snapshotId = model.get('id');
              return '#nondep/entity/' + entityId + '/license/' + licenseId + '/contact-history/' + snapshotId;
            }
          }),
          headerCell: 'custom',
          width: 20,
          editable: false,
          sortable: false
        }
      ],
      collection: new PageableCollection(modelGet(this.model, 'contactHistory', []), { pageSize: 25 }),
      emptyText: 'No License Contact History'
    });

    this.showChildView('licenseContactHistoryGridContainer', this.contactHistoryGrid);

    this.contactHistoryGrid.viewGrid.sort('createdDate', 'descending');
  }
});
