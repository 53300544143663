/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var moment = require('moment');

var momentUtil = require('common/util/momentUtil');
var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(examId) {
  var defer = $.Deferred();

  $.soap({
    body: {
      GetExamDetailsForInternal: {
        $xmlns: 'http://dobs.pa.gov/nd/exam/bpm/1.0',
        ExamID: examId
      }
    },
    blueprintArrays: [
      'GetExamDetailsForInternalResponse.ExamViolations.ND_ExamViolation',
      'GetExamDetailsForInternalResponse.ExamResultsSupportingDocuments.ND_ExamResultsSupportingDocument',
      'GetExamDetailsForInternalResponse.ExamComplianceViolations.ND_ExamComplianceViolation',
      'GetExamDetailsForInternalResponse.SecondaryExaminers.ND_SecondaryExaminer'
    ],
    blueprint: {
      CSFolderId: '$..CSFolderID',
      regionalDirectorReviewedDate: '$..RegionalDirectorReviewedDate',
      examPlacementId: '$..ExamPlacementID',
      examPlacementName: '$..ExamPlacementName',
      isRepeatViolation: '$..IsRepeatVolation',
      backgroundInfo: '$..BackgroundInfo',
      billingComments: '$..BillingComments',
      refundAmountOverride: '$..RefundAmountOverride',
      rating: '$..Rating',
      hourlyRate: '$..HourlyRate',
      examDocumentId: '$..ExamDocumentID',
      examDocument: {
        fileId: '$..ExamDocumentID',
        fileName: '$..ExamDocumentName'
      },
      regionalDirectorReviewDocument: {
        fileId:  '$..RegionalDirectorReviewDocumentID',
        fileName: '$..RegionalDirectorReviewDocument'
      },
      reviewExaminerUserId: '$..ReviewExaminerUserId',
      closingLetterSentDate: '$..ClosingLetterSentDate',
      closingLetterDocumentId: '$..ClosingLetterDocumentID',
      closingLetterDocumentName: '$..ClosingLetterDocumentName',
      isCompliance: '$..IsCompliance',
      examViolations: [
        '$..ND_ExamViolation',
        {
          id: '$..ID',
          examId: '$..ExamID',
          examViolationCodeId: '$..ExamViolationCodeID',
          examViolationCorrectiveActionId: '$..ExamViolationCorrectiveActionID',
          refundAmount: '$..Amount',
          violationCodeName: '$..ViolationCodeName',
          violationCodeDesc: '$..ViolationCodeDesc',
          correctiveActionName: '$..CorrectiveActionName',
          correctiveActionDesc: '$..CorrectiveActionDesc'
        }
      ],
      examResultsSupportingDocuments: [
        '$..ND_ExamResultsSupportingDocument',
        {
          id: '$.ID',
          examId: '$.ExamID',
          documentId: '$.DocumentID',
          file: {
            fileId: '$.DocumentID',
            fileName: '$.DocumentName'
          },
          documentTypeId: '$.DocumentTypeID',
          documentType: '$.DocumentType'
        }
      ],
      examComplianceViolations: [
        '$..ND_ExamComplianceViolation',
        {
          id: '$.ID',
          examId: '$.ExamID',
          complianceViolationId: '$.ComplianceViolationID',
          code: '$.Code',
          name: '$.Name'
        }
      ],
      secondaryExaminers: [
        '$..ND_SecondaryExaminer',
        {
          id: '$.ID',
          examID: '$.ExamID',
          examinerUserId: '$.ExaminerUserId',
          isDeleted: '$.IsDeleted',
          createdDate: '$.CreatedDate',
          createdBy: '$.CreatedBy'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      response.regionalDirectorReviewedDate = momentUtil.formatStr(response.regionalDirectorReviewedDate, {
        sourceFormat: moment.ISO_8601,
        targetFormat: 'MM/DD/YYYY hh:mm a',
        default: '--'
      });

      response.closingLetterSentDate = momentUtil.formatStr(response.closingLetterSentDate, {
        sourceFormat: moment.ISO_8601,
        targetFormat: 'MM/DD/YYYY hh:mm a',
        default: '--'
      });

      _.each(response.examViolations, function(examViolation) {
        examViolation.violationName = examViolation.violationCodeName + ' - ' + examViolation.violationCodeDesc;
        examViolation.violationCorrectiveActionName =
          examViolation.correctiveActionName + ' - ' + examViolation.correctiveActionDesc;
      });

      response.complianceViolationIds = _.reduce(
        response.examComplianceViolations,
        function(memo, examComplianceViolation) {
          memo.push(examComplianceViolation.complianceViolationId);
          return memo;
        },
        []
      );

      response.secondaryExaminerList = _.reduce(
        response.secondaryExaminers,
        function(memo, examiner, index) {
          if (index < response.secondaryExaminers.length - 1) {
            return memo + examiner.examinerUserId + ', ';
          }
          return memo + examiner.examinerUserId;
        },
        ''
      );

      response.isCompliance = response.isCompliance === '1';

      defer.resolve(response);
    })
    .fail(function(err) {
      defer.reject(err);
    });

  return defer.promise();
};
