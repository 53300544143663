/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var _get = require('lodash.get');
var Syphon = require('backbone.syphon');

var CommentsDocumentView = require('common/views/CommentsDocumentView');

var makeUploadCollection = require('common/util/makeUploadCollection');

var tmplDepBankConversionRequiredInfoDocSectionView = require('./DepBankConversionRequiredInfoDocSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepBankConversionRequiredInfoDocSectionView,

  className: 'dep-bank-conversion-required-info-doc-section-view',

  ui: {
    articlesOfConversionDocContainer: '.articles-of-conversion-doc-container',
    bylawsDocContainer: '.bylaws-doc-container',
    nameReservationDocContainer: '.name-reservation-doc-container',
    federalApprovalDocContainer: '.federal-approval-doc-container'
  },

  regions: {
    articlesOfConversionDocContainer: '@ui.articlesOfConversionDocContainer',
    bylawsDocContainer: '@ui.bylawsDocContainer',
    nameReservationDocContainer: '@ui.nameReservationDocContainer',
    federalApprovalDocContainer: '@ui.federalApprovalDocContainer'
  },

  onBeforeShow: function() {
    var filingContent = this.model.get('filingContent') || {};

    this.articlesOfConversionDoc = new CommentsDocumentView({
      isReadOnly: this.options.isReadOnly,
      allowMultipleFiles: false,
      isRequired: true,
      fileUploadLabel: 'Articles of Conversion',
      commentsLabel: 'Articles of Conversion Comments',
      commentsName: 'articlesOfConversionComments',
      collection: makeUploadCollection(_get(filingContent, 'articlesOfConversionDoc.file')),
      model: new Backbone.Model({
        articlesOfConversionComments: _get(filingContent, 'articlesOfConversionDoc.comments')
      }),
      documentTypeId: 20001
    });
    this.showChildView('articlesOfConversionDocContainer', this.articlesOfConversionDoc);

    this.bylawsDoc = new CommentsDocumentView({
      isReadOnly: this.options.isReadOnly,
      allowMultipleFiles: false,
      isRequired: true,
      fileUploadLabel: 'Revised Bylaws',
      commentsLabel: 'Revised Bylaws Comments',
      commentsName: 'bylawsComments',
      collection: makeUploadCollection(_get(filingContent, 'bylawsDoc.file')),
      model: new Backbone.Model({
        bylawsComments: _get(filingContent, 'bylawsDoc.comments')
      }),
      documentTypeId: 20001
    });
    this.showChildView('bylawsDocContainer', this.bylawsDoc);

    this.nameReservationDoc = new CommentsDocumentView({
      isReadOnly: this.options.isReadOnly,
      allowMultipleFiles: false,
      isRequired: true,
      fileUploadLabel: 'Evidence of Name Reservation',
      commentsLabel: 'Evidence of Name Reservation Comments',
      commentsName: 'nameReservationComments',
      collection: makeUploadCollection(_get(filingContent, 'nameReservationDoc.file')),
      model: new Backbone.Model({
        nameReservationComments: _get(filingContent, 'nameReservationDoc.comments')
      }),
      documentTypeId: 20001
    });
    this.showChildView('nameReservationDocContainer', this.nameReservationDoc);

    this.federalApprovalDoc = new CommentsDocumentView({
      isReadOnly: this.options.isReadOnly,
      allowMultipleFiles: false,
      isRequired: true,
      fileUploadLabel: 'Approval of Federal Supervisory Agency',
      commentsLabel: 'Approval of Federal Supervisory Agency Comments',
      commentsName: 'federalApprovalComments',
      collection: makeUploadCollection(_get(filingContent, 'federalApprovalDoc.file')),
      model: new Backbone.Model({
        federalApprovalComments: _get(filingContent, 'federalApprovalDoc.comments')
      }),
      documentTypeId: 20001
    });
    this.showChildView('federalApprovalDocContainer', this.federalApprovalDoc);
  },

  validate: function(config) {
    this.articlesOfConversionDoc.removeValidationErrors();
    this.bylawsDoc.removeValidationErrors();
    this.nameReservationDoc.removeValidationErrors();
    this.federalApprovalDoc.removeValidationErrors();

    if (config.type === 'submit') {
      var validationPromises = [
        this.articlesOfConversionDoc.validate(),
        this.bylawsDoc.validate(),
        this.nameReservationDoc.validate(),
        this.federalApprovalDoc.validate()
      ];
    }
    return $.when.apply($, validationPromises);
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    formData.articlesOfConversionDocId = this.articlesOfConversionDoc.getFirstFileId();
    formData.bylawsDocId = this.bylawsDoc.getFirstFileId();
    formData.nameReservationDocId = this.nameReservationDoc.getFirstFileId();
    formData.federalApprovalDocId = this.federalApprovalDoc.getFirstFileId();
    return formData;
  }
});
