/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');

var EmptySetView = require('common/views/EmptySetView');

var TimeTrkNonDepExpenseRowView = require('./TimeTrkNonDepExpenseRowView');

module.exports = Marionette.CollectionView.extend({
  className: 'time-trk-non-dep-expense-row-collection-view',

  childView: TimeTrkNonDepExpenseRowView,

  emptyView: EmptySetView,

  emptyViewOptions: function() {
    return {
      emptyText: 'No Expenses.'
    };
  },

  childEvents: {
    'remove:expense': 'onChildClickRemoveExpense'
  },

  childViewOptions: function(model, index) {
    return {
      childIndex: index,
      isReadOnly: this.options.isReadOnly,
      timesheet: this.model.get('timesheet'),
      categories: this.model.get('categories'),
      licenseTypes: this.model.get('licenseTypes')
    };
  },

  onChildClickRemoveExpense: function(childView) {
    this.collection.remove(childView.model);
  },

  getFormData: function() {
    if (this.collection.length > 0) {
      return this.children.map(function(child) {
        return child.getFormData();
      });
    }
  }
});
