/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');

var GridView = require('psa-marionette/views/GridView');
var PageableCollection = require('psa-backbone/models/PageableCollection');

var MomentCell = require('common/backgrid/MomentCell');

var tmplNonDepEntityOfficerHistoryView = require('../templates/NonDepEntityOfficerHistoryView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepEntityOfficerHistoryView,

  className: 'non-dep-entity-officer-history-view container-fluid',

  ui: {
    entityOfficerHistory: '.entity-officer-history-container'
  },

  regions: {
    entityOfficerHistory: '@ui.entityOfficerHistory'
  },

  onBeforeShow: function() {
    this.entityOfficerHistory = new GridView({
      columns: [
        {
          name: 'modifiedDate',
          label: 'Changed Date',
          cell: MomentCell.MomentCell,
          formatter: MomentCell.MomentDateTimeFormatter.extend({
            emptyValue: '--'
          }),
          sortValue: MomentCell.MomentSortValue,
          headerCell: 'custom',
          width: 16,
          editable: false
        },
        {
          name: 'modifiedBy',
          label: 'Changed By',
          cell: 'string',
          headerCell: 'custom',
          width: 16,
          editable: false
        },
        {
          name: 'firstName',
          label: 'First Name',
          cell: 'string',
          headerCell: 'custom',
          width: 17,
          editable: false
        },
        {
          name: 'lastName',
          label: 'Last Name',
          cell: 'string',
          headerCell: 'custom',
          width: 17,
          editable: false
        },
        {
          name: 'email',
          label: 'Email',
          cell: 'string',
          headerCell: 'custom',
          width: 17,
          editable: false
        },
        {
          name: 'entityOfficerTypeName',
          label: 'Officer Type',
          cell: 'string',
          headerCell: 'custom',
          width: 17,
          editable: false
        }
      ],
      emptyText: 'No Officer History',
      collection: new PageableCollection(this.model.get('officerHistory'), { pageSize: 25 })
    });

    this.showChildView('entityOfficerHistory', this.entityOfficerHistory);
  }
});
