/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var NProgress = require('nprogress');

var NavUtils = require('psa-core/util/nav');

var Scaffold = require('common/layout/Scaffold');
var GetUsersForRole = require('common/services/GetUsersForRole');

var SecOrganizationLicenseFinancialsEditView = require('../views/SecOrganizationLicenseFinancialsEditView');
var GetOrganizationLicenseFinancials = require('../services/GetOrganizationLicenseFinancials');
var GetOrganizationLicenseIdentifiers = require('../services/GetOrganizationLicenseIdentifiers');

module.exports = function(organizationId, licenseId, financialsId) {
  NProgress.start();
  NProgress.set(0.6);
  $.when(
    GetOrganizationLicenseFinancials(financialsId),
    GetOrganizationLicenseIdentifiers(licenseId),
    GetUsersForRole('SC - Chief Accounting')
  )
    .done(function(financials, identifiers, chiefAccountingUsers) {
      NavUtils.navigate(
        '#sec/organization/' + organizationId + '/license/' + licenseId + '/financials/' + financialsId + '/edit'
      );
      Scaffold.content.show(
        new SecOrganizationLicenseFinancialsEditView({
          viewMode: 'int',
          model: new Backbone.Model({
            organizationId: organizationId,
            licenseId: licenseId,
            financialsId: financialsId,
            financials: financials,
            identifiers: identifiers,
            chiefAccountingUsers: chiefAccountingUsers
          })
        })
      );
      NProgress.done();
    })
    .fail(function(err) {
      NProgress.done();
    });
};
