/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Ladda = require('ladda');
var Radio = require('backbone.radio');

var BreadcrumbView = require('common/views/BreadcrumbView');

var DepSEGFormView = require('./DepSEGFormView');
var SubmitSEG = require('../services/SubmitSEG');

var tmplDepSEGEditView = require('../templates/DepSEGEditView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepSEGEditView,

  className: 'dep-seg-edit-view container-fluid',

  ui: {
    segForm: '.seg-form-container',
    breadcrumb: '.breadcrumb-container',
    submit: '.btn-submit'
  },

  regions: {
    segForm: '@ui.segForm',
    breadcrumb: '@ui.breadcrumb'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeShow: function() {
    var seg = this.model.get('seg') || {};
    var segId = this.model.get('segId');
    var entityId = this.model.get('entityId');

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#dep/dashboard',
            text: 'Home'
          },
          {
            link: '#dep/entity/' + entityId + '/dashboard',
            text: this.model.get('entityIdentifiers').entityName
          },
          {
            text: seg.name,
            link: '#dep/entity/' + entityId + '/seg/' + segId + '/dashboard'
          },
          {
            text: 'Edit SEG'
          }
        ]
      })
    );

    this.segForm = new DepSEGFormView({
      model: this.model
    });

    this.showChildView('segForm', this.segForm);
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    this.validate().done(
      function() {
        var laddaContext = Ladda.create(this.ui.submit.get(0));
        laddaContext.start();
        var formData = this.getFormData();
        formData.isDeleted = 0;
        SubmitSEG(formData)
          .done(function() {
            Radio.channel('navigate').trigger('show:int:dp:entity:seg', formData.entityId, formData.segId);
          })
          .fail(function() {
            laddaContext.stop();
          });
      }.bind(this)
    );
  },

  validate: function() {
    return this.ui.segForm.parsley().whenValidate();
  },

  getFormData: function() {
    return this.segForm.getFormData();
  }
});
