/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');
var _get = require('lodash.get');
var Ladda = require('ladda');

var BreadcrumbView = require('common/views/BreadcrumbView');
var DepIrregularityFormView = require('./DepIrregularityFormView');

var SubmitIrregularity = require('../services/SubmitIrregularity');

var tmplDepIrregularityNewView = require('../templates/DepIrregularityNewView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepIrregularityNewView,

  className: 'dep-add-irregularity-view container-fluid',

  ui: {
    breadCrumbContainer: '.breadcrumb-container',
    irregularityFormContainer: '.irregularity-form-container',
    submitButton: '.btn-submit'
  },

  regions: {
    breadCrumbContainer: '@ui.breadCrumbContainer',
    irregularityFormContainer: '@ui.irregularityFormContainer'
  },

  events: {
    'click @ui.categoryDropdown': 'onChangeCategory',
    'click @ui.submitButton': 'onClickSubmit'
  },

  onBeforeShow: function() {
    var entityId = this.model.get('entityId');
    var entityName = _get(this.model.get('entityIdentifiers'), 'entityName');

    this.showChildView(
      'breadCrumbContainer',
      new BreadcrumbView({
        breadcrumbs: [
          {
            text: 'Home',
            link: '#dep/dashboard'
          },
          {
            text: entityName,
            link: '#dep/entity/' + entityId + '/dashboard'
          },
          {
            text: 'New Irregularity'
          }
        ]
      })
    );

    this.irregularityForm = new DepIrregularityFormView({
      model: this.model
    });

    this.showChildView('irregularityFormContainer', this.irregularityForm);
  },

  onClickSubmit: function(event) {
    event.preventDefault();
    var self = this;
    this.validate().done(function() {
      var laddaContext = Ladda.create(self.ui.submitButton.get(0));
      laddaContext.start();

      var formData = self.getFormData();
      formData.isDeleted = 0;
      SubmitIrregularity(formData)
        .done(function(irregularityId) {
          Radio.channel('navigate').trigger('show:int:dp:irregularity', self.model.get('entityId'), irregularityId);
        })
        .fail(function(err) {
          laddaContext.stop();
        });
    });
  },

  validate: function() {
    return this.ui.irregularityFormContainer.parsley().whenValidate();
  },

  getFormData: function() {
    return this.irregularityForm.getFormData();
  }
});
