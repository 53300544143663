/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');

var NonDepLicenseContactDetailCollectionView = require('./NonDepLicenseContactDetailCollectionView');

require('../styles/NonDepLicenseContactTypeView.css');
var tmplNonDepLicenseContactTypeView = require('../templates/NonDepLicenseContactTypeView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepLicenseContactTypeView,

  className: 'non-dep-license-contact-type-view',

  ui: {
    contactsContainer: '.contacts-container'
  },

  regions: {
    contactsContainer: '@ui.contactsContainer'
  },

  onBeforeShow: function() {
    this.showChildView(
      'contactsContainer',
      new NonDepLicenseContactDetailCollectionView({
        viewMode: this.options.viewMode,
        contactTypeName: this.model.get('name'),
        collection: new Backbone.Collection(this.model.get('contacts'))
      })
    );
  }
});
