/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var _get = require('lodash.get');
var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(entityId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetExternalTasksByEntity: {
        $xmlns: 'http://dobs.pa.gov/dp/task/bpm/1.0',
        EntityID: entityId
      }
    },
    blueprintArrays: [
      'GetExternalTasksByEntityResponse.Tasks.NOTF_TASK_INSTANCE',
      'GetExternalTasksByEntityResponse.TaskTypes.CM_LU_TaskType'
    ],
    blueprint: {
      tasks: [
        '$..NOTF_TASK_INSTANCE',
        {
          taskId: '$..TaskId',
          deliveryDate: '$..DeliveryDate',
          dueDate: '$..DueDate',
          filingId: '$..DPFilingID',
          entityId: '$..DPEntityID',
          examId: '$..DPExamID',
          typeName: '$..TaskTypeName'
        }
      ],
      taskMetaData: {
        taskTypes: [
          '$..CM_LU_TaskType',
          {
            name: '$..Name',
            description: '$..Description'
          }
        ]
      }
    }
  })
    .done(function(response) {
      cleanUpResponse(response, { cleanUpTextKeys: true });

      _.each(response.tasks, function(task) {
        var typeName = _get(task, 'typeName', {});

        task.deliveryDate = moment.utc(task.deliveryDate, moment.ISO_8601).local();

        if (task.dueDate) {
          task.dueDate = moment(task.dueDate, 'YYYY-MM-DD');
        }

        var type = _.find(response.taskMetaData.taskTypes, function(type) {
          return typeName === type.name;
        });

        task.typeName = _get(type, 'description');
      });

      delete response.taskMetaData;

      deferred.resolve(response.tasks);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
