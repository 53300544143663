/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade sec
 * ret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(departmentId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetAllCategories: {
        $xmlns: 'http://dobs.pa.gov/tt/lookup/bpm/1.0'
      }
    },
    blueprintArrays: ['GetAllCategoriesResponse.TT_LU_Category'],
    blueprint: {
      categories: [
        '$..TT_LU_Category',
        {
          id: '$..ID',
          category: '$..Category',
          departmentId: '$..DepartmentID',
          depEntityCategoryId: '$..DepEntityCategoryID'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      response.categories = _.where(response.categories, { departmentId: departmentId });

      deferred.resolve(response.categories);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
