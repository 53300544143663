/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var FileUploadTableView = require('common/views/FileUploadTableView');

var tmplDepInitiateCorrespondenceFormView = require('../templates/DepInitiateCorrespondenceFormView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepInitiateCorrespondenceFormView,

  className: 'dep-initiate-correspondence-form-view',

  ui: {
    fileContainer: '.file-container',
    correspondenceForm: '.correspondence-form',
    correspondenceComments: '.correspondence-comments',
    externalUser: '.external-user',
    missingFile: '.missing-file'
  },

  regions: {
    fileContainer: '@ui.fileContainer'
  },

  onBeforeShow: function() {
    this.fileUploadTable = new FileUploadTableView({
      documentTypes: this.model.get('documentTypes'),
      emptyText: 'No Document Provided',
      collection: new Backbone.Collection()
    });
    this.showChildView('fileContainer', this.fileUploadTable);
  },

  removeValidationErrors: function() {
    this.ui.missingFile.hide();
  },

  validate: function() {
    var defer = $.Deferred();
    this.removeValidationErrors();

    var hasOneSuccessfulFileOrComment = true;
    var hasExternalUser = false;
    var hasComments = !!this.ui.correspondenceComments.val();
    var uploads = this.fileUploadTable.collection;

    this.ui.externalUser
      .parsley()
      .whenValidate()
      .done(function() {
        hasExternalUser = true;
      });

    if (!uploads.length && !hasComments) {
      hasOneSuccessfulFileOrComment = false;
    } else {
      var hasSuccessfulFile;
      if (uploads) {
        hasSuccessfulFile = uploads.some(function(model) {
          return model.get('file');
        });
      }
      hasOneSuccessfulFileOrComment = hasComments || hasSuccessfulFile;
    }
    if (!hasOneSuccessfulFileOrComment) {
      this.ui.missingFile.show();
      defer.reject();
    }
    if (hasOneSuccessfulFileOrComment && hasExternalUser) {
      defer.resolve();
    }

    return defer.promise();
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);

    formData.documents = this.fileUploadTable.getFormData();

    return formData;
  }
});
