/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Ladda = require('ladda');
var Marionette = require('backbone.marionette');

var PageableCollection = require('psa-backbone/models/PageableCollection');
var GridView = require('psa-marionette/views/GridView');
var SelectBoxCell = require('psa-backgrid/SelectBoxCell');

var BooleanCell = require('common/backgrid/BooleanCell');
var AddressCell = require('common/backgrid/AddressCell');
var BreadcrumbView = require('common/views/BreadcrumbView');
var MomentCell = require('common/backgrid/MomentCell');
var LinkCell = require('common/backgrid/LinkCell');

var NonDepInternalSidebarNavView = require('../../common/views/NonDepInternalSidebarNavView');
var GetIssueExamDashboard = require('../services/GetIssueExamDashboard');
var SubmitIssueExam = require('../services/SubmitIssueExam');

require('../styles/NonDepInternalIssueExamView.css');
var tmplNonDepInternalIssueExamView = require('../templates/NonDepInternalIssueExamView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepInternalIssueExamView,

  className: 'non-dep-internal-issue-exam-view container-fluid',

  ui: {
    sideBar: '.side-bar-container',
    licenses: '.licenses-container',
    breadcrumb: '.breadcrumb-container',

    applyButton: '#apply',
    issueSelectedExams: '#issue',
    licenseType: '#licenseType',
    noLicenseSelectedAlert: '#noLicenseSelectedAlert',
    missingRegionAlert: '#missingRegionAlert'
  },

  regions: {
    sideBar: '@ui.sideBar',
    licenses: '@ui.licenses',
    breadcrumb: '@ui.breadcrumb'
  },

  events: {
    'click @ui.applyButton': 'onClickApply',
    'click @ui.issueSelectedExams': 'onClickIssueSelectedExams'
  },

  onBeforeShow: function() {
    var examYear = this.model.get('examYear');
    var examYearId = this.model.get('examYearId');

    this.showChildView('sideBar', new NonDepInternalSidebarNavView());

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#nondep/dashboard/exams',
            text: 'Exams'
          },
          {
            link: '#nondep/dashboard/exams/' + examYearId,
            text: examYear + ' Exam Year'
          },
          {
            text: 'Issue Exams'
          }
        ]
      })
    );

    this.ui.licenseType.select2();

    this.licenseGrid = new GridView({
      columns: [
        {
          name: 'isIssuing',
          label: 'Issue',
          cell: BooleanCell,
          headerCell: 'custom',
          width: 5,
          editable: true,
          sortable: false
        },
        {
          name: 'entityName',
          label: 'Entity Name',
          cell: LinkCell.extend({
            emptyText: '--',
            newTab: true,
            href: function(model) {
              var entityId = model.get('entityId');
              var url = entityId ? '#nondep/entity/' + entityId + '/dashboard' : '';
              return url;
            }
          }),
          headerCell: 'custom',
          width: 15,
          editable: false
        },
        {
          name: 'licenseNumber',
          label: 'License Number',
          cell: LinkCell.extend({
            emptyText: '--',
            newTab: true,
            href: function(model) {
              var entityId = model.get('entityId');
              var licenseId = model.get('licenseId');
              var url = entityId && licenseId ? '#nondep/entity/' + entityId + '/license/' + licenseId : '';
              return url;
            }
          }),
          headerCell: 'custom',
          width: 13,
          editable: false
        },
        {
          name: 'originalIssueDate',
          label: 'Original License Date',
          cell: MomentCell.MomentCell,
          formatter: MomentCell.MomentDateFormatter.extend({
            emptyValue: '--'
          }),
          sortValue: MomentCell.MomentSortValue,
          headerCell: 'custom',
          width: 12,
          editable: false
        },
        {
          name: 'previousExamCoverageEndDate',
          label: 'Previous Exam Coverage End Date',
          cell: MomentCell.MomentCell,
          formatter: MomentCell.MomentDateFormatter.extend({
            emptyValue: '--'
          }),
          sortValue: MomentCell.MomentSortValue,
          headerCell: 'custom',
          width: 12,
          editable: false
        },
        {
          name: 'county',
          label: 'County',
          cell: 'string',
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'address',
          label: 'Street Address',
          cell: AddressCell,
          headerCell: 'custom',
          width: 15,
          editable: false,
          sortable: false
        },
        {
          name: 'sortByDate',
          label: 'Sort By',
          cell: MomentCell.MomentCell,
          formatter: MomentCell.MomentDateFormatter.extend({
            emptyValue: '--'
          }),
          sortValue: MomentCell.MomentSortValue,
          headerCell: 'custom',
          width: 12,
          editable: false,
          sortable: false
        },
        {
          name: 'nonDExamRegionId',
          label: 'Region',
          cell: SelectBoxCell,
          headerCell: 'custom',
          width: 10,
          editable: !this.options.isReadOnly,
          sortable: false,
          noValueText: '--',
          selectOpts: new Backbone.Collection(this.model.get('regions'))
        }
      ],
      collection: new PageableCollection(this.model.get('licenses'), { pageSize: 100 }),
      emptyText: 'No Licenses Found'
    });

    this.showChildView('licenses', this.licenseGrid);
  },

  onClickApply: function(e) {
    e.preventDefault();

    var self = this,
      laddaContext = Ladda.create(e.currentTarget).start();

    GetIssueExamDashboard(this.ui.licenseType.val())
      .done(function(dashboard) {
        laddaContext.stop();
        self.licenseGrid.collection.fullCollection.reset(dashboard);
      })
      .fail(function() {
        laddaContext.stop();
      });
  },

  onClickIssueSelectedExams: function() {
    var self = this;

    var selectedLicenses = _.where(this.licenseGrid.collection.fullCollection.toJSON(), { isIssuing: true });
    if (this.validate(selectedLicenses)) {
      var l = Ladda.create(self.ui.issueSelectedExams[0]);
      l.start();

      var formData = {
        examYearId: self.model.get('examYearId'),
        licenses: selectedLicenses
      };

      SubmitIssueExam(formData)
        .done(function() {
          var models = self.licenseGrid.collection.fullCollection.filter(function(model) {
            return !model.get('isIssuing');
          });
          self.licenseGrid.collection.fullCollection.reset(models);
        })
        .done(function() {
          l.stop();
        })
        .fail(function() {
          l.stop();
        });
    }
  },

  validate: function(selectedLicenses) {
    this.ui.missingRegionAlert.hide();
    this.ui.noLicenseSelectedAlert.hide();

    if (selectedLicenses.length === 0) {
      this.ui.noLicenseSelectedAlert.show();
      return false;
    } else if (
      _.findWhere(selectedLicenses, { nonDExamRegionId: null }) ||
      _.findWhere(selectedLicenses, { nonDExamRegionId: '' })
    ) {
      this.ui.missingRegionAlert.show();
      return false;
    } else {
      return true;
    }
  }
});
