/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var moment = require('moment');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

require('./AO1ReviewLicenseBondView.css');
var tmplAO1ReviewLicenseBondView = require('./AO1ReviewLicenseBondView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplAO1ReviewLicenseBondView,

  className: 'ao1-review-license-bond-view panel panel-info',

  ui: {
    bondForm: '.license-bond-form-view',
    expiration: '.expiration',
    requiredTo: '.requiredTo',
    removeBond: '.remove-bond'
  },

  triggers: {
    'click @ui.removeBond': 'remove:bond'
  },

  templateHelpers: function() {
    return {
      entityBondCompanies: this.options.entityBondCompanies,
      entityBondType: this.options.entityBondType,
      entityBondStatus: this.options.entityBondStatus
    };
  },

  onBeforeShow: function() {
    Syphon.deserialize(this, this.model.get('licenseBond'));

    this.ui.expiration.datepicker({
      startView: 'days'
    });

    this.ui.requiredTo.datepicker({
      startView: 'days'
    });
  },

  onBeforeRender: function() {
    var bondType = this.model.get('entityBondType');
    this.model.attributes.entityBondType = _.filter(bondType, function(bond) {
      return bond.id !== '1';
    });
  },

  validate: function() {
    this.ui.bondForm.parsley().reset();
    return this.ui.bondForm.parsley().whenValidate();
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    formData.id = this.model.get('id');
    formData.expiration = moment(formData.expiration, 'MM/DD/YYYY').isValid()
      ? moment(formData.expiration, 'MM/DD/YYYY').format('YYYY-MM-DD')
      : '';
    formData.requiredTo = moment(formData.requiredTo, 'MM/DD/YYYY').isValid()
      ? moment(formData.requiredTo, 'MM/DD/YYYY').format('YYYY-MM-DD')
      : '';
    return formData;
  }
});
