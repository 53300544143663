/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(categoryId, entityTypeId) {
  var defer = $.Deferred();
  $.soap({
    body: {
      GetAllExamsLookups: {
        $xmlns: 'http://dobs.pa.gov/dp/lookup/bpm/1.0'
      }
    },
    blueprintArrays: [
      'GetAllExamsLookupsResponse.Lookups.ExamActions.DP_LU_ExamAction',
      'GetAllExamsLookupsResponse.Lookups.ExamAreas.DP_LU_ExamArea',
      'GetAllExamsLookupsResponse.Lookups.ExamBSAs.DP_LU_ExamBSA',
      'GetAllExamsLookupsResponse.Lookups.ExamConductedByLeadAgencies.DP_LU_ExamConductedByLeadAgency',
      'GetAllExamsLookupsResponse.Lookups.ExamScopes.DP_LU_ExamScope',
      'GetAllExamsLookupsResponse.Lookups.ExamStatuses.DP_LU_ExamStatus',
      'GetAllExamsLookupsResponse.Lookups.ExamTypes.DP_LU_ExamType',
      'GetAllExamsLookupsResponse.Lookups.ExamViolationDescriptions.DP_LU_ExamViolationDescription',
      'GetAllExamsLookupsResponse.Lookups.ExamViolationLegalCites.DP_LU_ExamViolationLegalCite',
      'GetAllExamsLookupsResponse.Lookups.ExamConcentrationTypes.DP_LU_ExamConcentrationsType',
      'GetAllExamsLookupsResponse.Lookups.ExamConcentrationSubTypes.DP_LU_ExamConcentrationsSubType',
      'GetAllExamsLookupsResponse.Lookups.ExamReviewRoles.DP_LU_ExamReviewRole',
      'GetAllExamsLookupsResponse.Lookups.ExamCRAs.DP_LU_ExamCRA',
      'GetAllExamsLookupsResponse.Lookups.ExamFederalAgencies.DP_LU_ExamFederalAgency',
      'GetAllExamsLookupsResponse.Lookups.TSPExamRankings.DP_LU_TSPExamRanking',
      'GetAllExamsLookupsResponse.Lookups.TSPPriorityRankings.DP_LU_TSPPriorityRanking',
      'GetAllExamsLookupsResponse.Lookups.TSPRegionDistricts.DP_LU_ExamRegionDistrict',
      'GetAllExamsLookupsResponse.Lookups.TargetCodes.DP_LU_TargetCodes'
    ],
    blueprint: {
      examActions: [
        '$..DP_LU_ExamAction',
        {
          id: '$..ID',
          action: '$..Action'
        }
      ],
      examAreas: [
        '$..DP_LU_ExamArea',
        {
          id: '$..ID',
          area: '$..Area',
          categoryId: '$..CategoryID'
        }
      ],
      examBSAs: [
        '$..DP_LU_ExamBSA',
        {
          id: '$..ID',
          bsa: '$..BSA'
        }
      ],
      examConductedByLeadAgencies: [
        '$..DP_LU_ExamConductedByLeadAgency',
        {
          id: '$..ID',
          option: '$..Option',
          categoryId: '$..CategoryID',
          isTSP: '$..IsTSP'
        }
      ],
      examScopes: [
        '$..DP_LU_ExamScope',
        {
          id: '$..ID',
          scope: '$..Scope',
          isTSP: '$..IsTSP'
        }
      ],
      examStatuses: [
        '$..DP_LU_ExamStatus',
        {
          id: '$..ID',
          status: '$..Status'
        }
      ],
      examTypes: [
        '$..DP_LU_ExamType',
        {
          id: '$..ID',
          type: '$..ExamType',
          isTSP: '$..IsTSP'
        }
      ],
      examViolationDescriptions: [
        '$..DP_LU_ExamViolationDescription',
        {
          id: '$..ID',
          description: '$..Description',
          categoryId: '$..CategoryID'
        }
      ],
      examViolationLegalCites: [
        '$..DP_LU_ExamViolationLegalCite',
        {
          id: '$..ID',
          type: '$..Cite'
        }
      ],
      examConcentrationTypes: [
        '$..DP_LU_ExamConcentrationsType',
        {
          id: '$..ID',
          type: '$..ConcentrationsType'
        }
      ],
      examConcentrationSubTypes: [
        '$..DP_LU_ExamConcentrationsSubType',
        {
          id: '$..ID',
          subType: '$..ConcentrationsSubType',
          typeId: '$..ConcentrationTypeID'
        }
      ],
      examReviewRoles: [
        '$..DP_LU_ExamReviewRole',
        {
          id: '$..ID',
          role: '$..Role',
          roleCN: '$..RoleCN',
          categoryId: '$..CategoryID'
        }
      ],
      examCRAs: [
        '$..DP_LU_ExamCRA',
        {
          id: '$..ID',
          cra: '$..CRA'
        }
      ],
      examFederalAgencies: [
        '$..DP_LU_ExamFederalAgency',
        {
          id: '$..ID',
          agency: '$..Agency'
        }
      ],
      examRegionDistricts: [
        '$..DP_LU_ExamRegionDistrict',
        {
          id: '$..ID',
          region: '$..RegionDistrict'
        }
      ],
      examRanking: [
        '$..DP_LU_TSPExamRanking',
        {
          id: '$..ID',
          ranking: '$..Ranking'
        }
      ],
      examPriorityRanking: [
        '$..DP_LU_TSPPriorityRanking',
        {
          id: '$..ID',
          ranking: '$..Ranking'
        }
      ],
      targetCodes: [
        '$..DP_LU_TargetCodes',
        {
          id: '$..ID',
          name: '$..Name'
        }
      ],
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      var isTSP = entityTypeId === '3' || entityTypeId === '12' || entityTypeId === '23';
      if (isTSP) {
        response.examTypes = _.where(response.examTypes, { isTSP: '1' });
        response.examScopes = _.where(response.examScopes, { isTSP: '1' });
        response.examConductedByLeadAgencies = _.where(response.examConductedByLeadAgencies, { isTSP: '1' });
      } else {
        response.examTypes = _.where(response.examTypes, { isTSP: '0' });
        response.examScopes = _.where(response.examScopes, { isTSP: '0' });
        response.examConductedByLeadAgencies = _.where(response.examConductedByLeadAgencies, { isTSP: '0' });
      }
      if (categoryId) {
        response.examAreas = _.where(response.examAreas, { categoryId: categoryId });
        response.examConductedByLeadAgencies = _.where(response.examConductedByLeadAgencies, {
          categoryId: categoryId
        });
        response.examViolationDescriptions = _.where(response.examViolationDescriptions, { categoryId: categoryId });
        response.examReviewRoles = _.where(response.examReviewRoles, { categoryId: categoryId });
      }
      defer.resolve(response);
    })
    .fail(function(err) {
      defer.reject(err);
    });

  return defer.promise();
};
