/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Ladda = require('ladda');
var Syphon = require('backbone.syphon');
var Radio = require('backbone.radio');

var parsleyUtil = require('common/util/parsleyUtil');

var SubmitCreditServicesLoanBrokerApplication = require('../../services/SubmitCreditServicesLoanBrokerApplication');

var tmplCreditServicesLoanBrokerAffirmationSectionView = require('./CreditServicesLoanBrokerAffirmationSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplCreditServicesLoanBrokerAffirmationSectionView,

  className: 'credit-services-loan-broker-affirmation-section-view',

  ui: {
    affirmationForm: '.affirmation-form',
    submit: '.submit'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  templateHelpers: function() {
    return {
      mode: this.options.mode
    };
  },

  onBeforeShow: function(e) {
    if (this.options.mode === 'print' || this.options.mode === 'int' || this.options.mode === 'int-expand') {
      Syphon.deserialize(this, this.model.get('application'));
    }
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;

    var l = Ladda.create(this.ui.submit[0]);
    l.start();

    this.sideNavView
      .validateAll({ type: 'submit' })
      .done(function() {
        var formData = self.sideNavView.getFormData();
        formData.applicationId = self.model.get('application').applicationId;
        SubmitCreditServicesLoanBrokerApplication(formData)
          .done(function() {
            Radio.channel('nd:draft-license-application').trigger('proceed-to-payment');
            Radio.channel('nd:submit-application').trigger('resubmit', l);
            if (self.options.mode === 'new') {
              l.stop();
            }
          })
          .fail(function() {
            l.stop();
          });
      })
      .fail(function() {
        l.stop();
      });
  },

  validate: function(config) {
    this.ui.affirmationForm.parsley().reset();
    if (config.type === 'save') {
      // Turn off required field validation for this form
      parsleyUtil.disableRequiredValidation(this.ui.affirmationForm);
    } else {
      // Turn on required field validation for this form
      parsleyUtil.enableRequiredValidation(this.ui.affirmationForm);
    }
    return this.ui.affirmationForm.parsley().whenValidate();
  },

  getFormData: function() {
    return Syphon.serialize(this);
  }
});
