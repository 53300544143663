var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda;

  return "\r\n<div class=\"page-title clearfix\">\r\n  <h1 class=\"m-n p-n\">"
    + alias4(((helper = (helper = helpers.entityName || (depth0 != null ? depth0.entityName : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"entityName","hash":{},"data":data}) : helper)))
    + "</h1>\r\n</div>\r\n<div class=\"page-subtitle\">\r\n  <h2>Exam</h2>\r\n  <div class=\"page-subtitle-details\">"
    + alias4(alias5(((stack1 = (depth0 != null ? depth0.exam : depth0)) != null ? stack1.examType : stack1), depth0))
    + " - "
    + alias4(alias5(((stack1 = (depth0 != null ? depth0.exam : depth0)) != null ? stack1.leadAgency : stack1), depth0))
    + "</div>\r\n</div>\r\n<div class=\"breadcrumb-container\"></div>\r\n<div class=\"col-sm-10 col-sm-offset-1\">\r\n  <div class=\"row\">\r\n    <div class=\"col-sm-12 col-sm-offset-3 col-md-offset-2 col-md-10\">\r\n      <h3 class=\"m-t-xs m-b-lg\">Edit Exam Other Information</h3>\r\n    </div>\r\n  </div>\r\n  <form class=\"form-horizontal\">\r\n    <div class=\"row\">\r\n      <label class=\"control-label col-sm-3 col-md-2\">Concentrations</label>\r\n      <div class=\"col-sm-9 col-md-10\">\r\n        <button class=\"btn btn-primary btn-add-concentration\">Add Concentration</button>\r\n        <div class=\"concentration-information-grid-container\"></div>\r\n      </div>\r\n    </div>\r\n    <div class=\"row\">\r\n      <label class=\"control-label col-sm-3 col-md-2\">Violations</label>\r\n      <div class=\"col-sm-9 col-md-10\">\r\n        <button class=\"btn btn-primary btn-add-violation\">Add Violation</button>\r\n        <div class=\"violation-information-grid-container\"></div>\r\n      </div>\r\n    </div>\r\n  </form>\r\n  <div class=\"row\">\r\n    <div class=\"col-sm-9 col-sm-offset-3 col-md-10 col-md-offset-2\">\r\n      <a class=\"btn btn-default\" href=\"#dep/entity/"
    + alias4(((helper = (helper = helpers.entityId || (depth0 != null ? depth0.entityId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"entityId","hash":{},"data":data}) : helper)))
    + "/exam/"
    + alias4(alias5(((stack1 = (depth0 != null ? depth0.exam : depth0)) != null ? stack1.id : stack1), depth0))
    + "/dashboard\">Cancel</a>\r\n      <button class=\"btn btn-primary btn-submit\">Submit</button>\r\n    </div>\r\n  </div>\r\n</div>";
},"useData":true});