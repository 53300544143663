/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var AddressView = require('common/views/AddressView');

require('./CreditServicesLoanBrokerLenderView.css');
var tmplLicenseContactView = require('./CreditServicesLoanBrokerLenderView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplLicenseContactView,

  className: 'credit-services-loan-broker-agent-view panel panel-info',

  ui: {
    address: '.address',
    removeLender: '.remove-lender',
    lenderNumber: '.lender-number'
  },

  regions: {
    address: '@ui.address'
  },

  triggers: {
    'click @ui.removeLender': 'remove:lender'
  },

  behaviors: {
    PhoneFormatBehavior: {}
  },

  templateHelpers: function() {
    return {
      modelId: this.model.cid
    };
  },

  onBeforeShow: function() {
    this.showChildView(
      'address',
      new AddressView({
        horizontal: false,
        startingCountry: this.model.get('country'),
        startingPostalCode: this.model.get('postalCode'),
        startingCounty: this.model.get('county'),
        isRequired: true
      })
    );

    Syphon.deserialize(this, this.model.toJSON());
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    return formData;
  }
});
