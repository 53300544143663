/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(recordId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetPriorNamesByRecord: {
        $xmlns: 'http://dobs.pa.gov/cf/record/bpm/1.0',
        RecordID: recordId
      }
    },
    blueprintArrays: ['GetPriorNamesByRecordResponse.CF_PriorName'],
    blueprint: {
      priorNames: [
        '$..CF_PriorName',
        {
          id: '$..ID',
          recordId: '$..RecordID',
          priorName: '$..PriorName',
          changeDate: '$..ChangeDate',
          isDeleted: '$..IsDeleted',
          createdDate: '$..CreatedDate',
          createdBy: '$..CreatedBy',
          modifiedDate: '$..ModifiedDate',
          modifiedBy: '$..ModifiedBy'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      _.each(response.priorNames, function(priorName) {
        if (priorName.changeDate) {
          priorName.changeDate = moment(priorName.changeDate, 'YYYY-MM-DD');
        }
      });

      deferred.resolve(response.priorNames);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
