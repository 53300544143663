var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression;

  return "\r\n<h1 class=\"page-title\">"
    + alias1(container.lambda(((stack1 = (depth0 != null ? depth0.entityIdentifiers : depth0)) != null ? stack1.entityName : stack1), depth0))
    + "</h1>\r\n<div class=\"header-bar\"></div>\r\n<div class=\"breadcrumb-container\"></div>\r\n<h2 class=\"m-t-n\">Initiate Correspondence</h2>\r\n<div class=\"form-horizontal col-sm-9 col-sm-offset-1 correspondence-form-container\">\r\n</div>\r\n<div class=\"form-horizontal col-sm-9 col-sm-offset-1\">\r\n  <div class=\"row m-b-lg\">\r\n    <div class=\"col-sm-12\">\r\n      <div class=\"form-group\">\r\n        <div class=\"col-sm-9 col-sm-offset-3\">\r\n          <a href=\"#nondep/entity/"
    + alias1(((helper = (helper = helpers.entityId || (depth0 != null ? depth0.entityId : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"entityId","hash":{},"data":data}) : helper)))
    + "/dashboard\" class=\"btn btn-default\">Cancel</a>\r\n          <button class=\"btn btn-primary btn-submit submit\">Submit</button>\r\n        </div>\r\n      </div>\r\n    </div>\r\n  </div>\r\n</div>";
},"useData":true});