/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

/**
 * Checks whether the given task type Id is for a Non-Depository amendment task
 * @param {string} taskTypeId The task type Id being checked.
 * @returns {boolean} Whether the task is a Non-Depository amendment task
 * @example
 *
 * isNonDepAmendmentTask('10003')
 * // => true
 */
module.exports = function(taskTypeId) {
  var validTaskTypeIds = ['10003', '10103', '10203', '10303', '10403'];
  return _.contains(validTaskTypeIds, taskTypeId);
};
