/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');
var toggleForm = require('common/util/toggleForm');

require('../styles/TimeTrkTotalsView.css');
var tmplTimeTrkTotalsView = require('../templates/TimeTrkTotalsView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplTimeTrkTotalsView,

  className: 'time-trk-totals-view',

  ui: {
    panelBody: '.panel-body',
    removeEntry: '.remove-entry',
    depErrorMessage: '.dep-error-message',
    nonDepErrorMessage: '.nondep-error-message',
    secErrorMessage: '.sec-error-message'
  },

  regions: {
    panelBody: '@ui.panelBody'
  },

  triggers: {
    'click @ui.removeEntry': 'remove:entry'
  },

  onBeforeRender: function() {
    this.calculateTotalTimes();
  },

  onBeforeShow: function() {
    if (this.options.submitAttempted) {
      toggleForm(this.ui.secErrorMessage, this.options.showSecErrorMessage);
      this.validateTotalTimes();
    } else {
      toggleForm(this.ui.depErrorMessage, false);
      toggleForm(this.ui.nonDepErrorMessage, false);
      toggleForm(this.ui.secErrorMessage, false);
    }
  },

  calculateTotalTimes: function() {
    var self = this;

    var emptyTimeRow = {
      startDayPlus1Total: 0.0,
      startDayPlus2Total: 0.0,
      startDayPlus3Total: 0.0,
      startDayPlus4Total: 0.0,
      startDayPlus5Total: 0.0,
      startDayPlus8Total: 0.0,
      startDayPlus9Total: 0.0,
      startDayPlus10Total: 0.0,
      startDayPlus11Total: 0.0,
      startDayPlus12Total: 0.0
    };

    var timeEntries = this.model.get('timeEntries') || {};

    timeEntries = timeEntries.length ? timeEntries : emptyTimeRow;

    // Initialize totals to 0
    var totals = emptyTimeRow;
    _.each(timeEntries, function(timeEntry) {
      totals.startDayPlus1Total += parseFloat(timeEntry.startDayPlus1 || 0.0);
      totals.startDayPlus2Total += parseFloat(timeEntry.startDayPlus2 || 0.0);
      totals.startDayPlus3Total += parseFloat(timeEntry.startDayPlus3 || 0.0);
      totals.startDayPlus4Total += parseFloat(timeEntry.startDayPlus4 || 0.0);
      totals.startDayPlus5Total += parseFloat(timeEntry.startDayPlus5 || 0.0);
      totals.startDayPlus8Total += parseFloat(timeEntry.startDayPlus8 || 0.0);
      totals.startDayPlus9Total += parseFloat(timeEntry.startDayPlus9 || 0.0);
      totals.startDayPlus10Total += parseFloat(timeEntry.startDayPlus10 || 0.0);
      totals.startDayPlus11Total += parseFloat(timeEntry.startDayPlus11 || 0.0);
      totals.startDayPlus12Total += parseFloat(timeEntry.startDayPlus12 || 0.0);
    });
    self.model.set('timeTotals', totals);
  },

  validateTotalTimes: function() {
    var defer = $.Deferred();
    var self = this;
    var validTime = true;
    var totals = this.model.get('timeTotals');
    var timesheet = this.model.get('timesheet') || {};
    var dptId = timesheet.departmentId;

    var isNonDep = dptId === '1000';
    var isDep = dptId === '2000';
    var isSec = dptId === '3000';

    if (isNonDep) {
      _.each(totals, function(total, key) {
        if (total !== 7.5) {
          self.$('#' + key).addClass('error');
          validTime = false;
        }
      });
      toggleForm(this.ui.nonDepErrorMessage, !validTime);
    }
    if (isDep) {
      _.each(totals, function(total, key) {
        if (total > 7.5) {
          self.$('#' + key).addClass('error');
          validTime = false;
        }
      });
      toggleForm(this.ui.depErrorMessage, !validTime);
    }

    if (isSec) {
      _.each(totals, function(total, key) {
        if (total > 24) {
          self.$('#' + key).addClass('error');
          validTime = false;
        }
      });
      toggleForm(this.ui.secErrorMessage, !validTime);
    }

    if (validTime) {
      defer.resolve();
    } else {
      defer.reject();
    }
    return defer.promise();
  }
});
