/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

module.exports = function(formData) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      SubmitInvInqComplainant: {
        $xmlns: 'http://dobs.pa.gov/sc/investigationinquiry/bpm/1.0',
        ComplainantID: formData.complainantId,
        InvInqID: formData.invInqId,
        Name: formData.name,
        Age: formData.age,
        Email: formData.email,
        Phone: formData.phone,
        Fax: formData.fax,
        Note: formData.note,
        AddressLine1: formData.addressLine1,
        AddressLine2: formData.addressLine2,
        AddressLine3: formData.addressLine3,
        County: formData.county,
        City: formData.city,
        State: formData.state,
        PostalCode: formData.postalCode,
        Country: formData.country,
        IsDeleted: formData.isDeleted
      }
    }
  })
    .done(function() {
      deferred.resolve();
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
