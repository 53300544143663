/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var moment = require('moment');
var momentUtil = require('common/util/momentUtil');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(licenseId) {
  var deferred = $.Deferred();
  $.soap({
    body: {
      GetLicenseDetails: {
        $xmlns: 'http://dobs.pa.gov/nd/license/bpm/1.0',
        LicenseID: licenseId
      }
    },
    blueprint: {
      id: '$..ID',
      CSFolderId: '$..CSFolderID',
      entityName: '$..EntityName',
      licenseLocationID: '$..LocationID',
      licenseNumber: '$..LicenseNumber',
      nmlsId: '$..NMLSID',
      licenseType: '$..LicenseType',
      licenseTypeId: '$..LicenseTypeID',
      licenseStatus: '$..LicenseStatus',
      licenseStatusId: '$..LicenseStatusID',
      certificateDocument: {
        fileId: '$..CertificateDocumentID',
        fileName: '$..CertificateDocumentName'
      },
      effectiveStartDate: '$..EffectiveStartDate',
      effectiveEndDate: '$..EffectiveEndDate',
      nmlsEntityId: '$..NMLSEntityID',
      nmlsTransitionDate: '$..NMLSTransitionDate',
      originalLicenseNumber: '$..OriginalLicenseNumber',
      sapAccount: '$..SAPAccount',
      nextExamDate: '$..NextExamDate',
      previousExamDate: '$..PreviousExamDate',
      hasInProgressExam: '$..HasInProgressExam',
      nonDExamRegion: '$..NonDExamRegion',
      nonDExamRegionId: '$..NonDExamRegionID',
      isBranch: '$..IsBranch',
      originalIssueDate: '$..OriginalIssueDate',
      licenseAddress: {
        addressLine1: '$..LicenseAddressLine1',
        addressLine2: '$..LicenseAddressLine2',
        addressLine3: '$..LicenseAddressLine3',
        city: '$..LicenseCity',
        state: '$..LicenseState',
        postalCode: '$..LicensePostalCode',
        county: '$..LicenseCounty',
        country: '$..LicenseCountry'
      },
      officeManager: {
        id: '$..OfficeManagerID',
        firstName: '$..OfficeManagerFirstName',
        lastName: '$..OfficeManagerLastName',
        email: '$..OfficeManagerEmail',
        homePhoneNumber: '$..OfficeManagerHomePhoneNumber',
        cellPhoneNumber: '$..OfficeManagerCellPhoneNumber',
        address: {
          addressLine1: '$..OfficeManagerAddressLine1',
          addressLine2: '$..OfficeManagerAddressLine2',
          addressLine3: '$..OfficeManagerAddressLine3',
          city: '$..OfficeManagerCity',
          state: '$..OfficeManagerState',
          postalCode: '$..OfficeManagerPostalCode',
          county: '$..OfficeManagerCounty',
          country: '$..OfficeManagerCountry'
        }
      }
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      response.originalIssueDate = momentUtil.formatStr(
        response.originalIssueDate,
        momentUtil.defaultInboundDateConfig
      );
      response.effectiveStartDate = momentUtil.formatStr(
        response.effectiveStartDate,
        momentUtil.defaultInboundDateConfig
      );
      response.effectiveEndDate = momentUtil.formatStr(response.effectiveEndDate, momentUtil.defaultInboundDateConfig);
      response.nextExamDate = momentUtil.formatStr(
        response.nextExamDate,
        _.extend({}, momentUtil.defaultInboundDateConfig, { default: 'Not Scheduled' })
      );
      response.previousExamDate = momentUtil.formatStr(
        response.previousExamDate,
        _.extend({}, momentUtil.defaultInboundDateConfig, { default: '--' })
      );
      response.nmlsTransitionDate = momentUtil.formatStr(
        response.nmlsTransitionDate,
        momentUtil.defaultInboundDateConfig
      );
      response.originalLicenseNumber = response.originalLicenseNumber || '--';
      response.nmlsEntityId = response.nmlsEntityId || '--';
      response.sapAccount = response.sapAccount || '--';

      if (response.officeManager.id === null) {
        delete response.officeManager;
      } else {
        response.officeManager.homePhoneNumber = response.officeManager.homePhoneNumber || '--';
        response.officeManager.cellPhoneNumber = response.officeManager.cellPhoneNumber || '--';
        response.officeManager.email = response.officeManager.email || '--';
      }

      _.each(response.bonds, function(bond) {
        bond.expiration = bond.expiration ? moment(bond.expiration, moment.ISO_8601).format('MM/DD/YYYY') : '--';
        bond.requiredTo = bond.requiredTo ? moment(bond.requiredTo, moment.ISO_8601).format('MM/DD/YYYY') : '--';
      });

      response.isBranch = response.isBranch === '1';
      response.isNMLSLicense = !!response.nmlsId;

      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
