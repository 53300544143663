var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=helpers.helperMissing;

  return "<div class=\"page-title clearfix\">\r\n  <h1 class=\"pull-left m-n p-n\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.entityIdentifiers : depth0)) != null ? stack1.entityName : stack1), depth0))
    + "</h1>\r\n  <div class=\"page-title-buttons-container\">\r\n    <div class=\"officer-action-drop-down\"></div>\r\n  </div>\r\n</div>\r\n<div class=\"page-subtitle\">\r\n  <h2>Officer</h2>\r\n  <div class=\"page-subtitle-details\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.officer : depth0)) != null ? stack1.officerType : stack1), depth0))
    + " - "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.officer : depth0)) != null ? stack1.firstName : stack1), depth0))
    + " "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.officer : depth0)) != null ? stack1.lastName : stack1), depth0))
    + "</div>\r\n</div>\r\n<div class=\"breadcrumb-container\"></div>\r\n\r\n<div class=\"form-horizontal form-static col-sm-8 col-sm-offset-2 m-b-md\">\r\n  <div class=\"row\">\r\n    <div class=\"col-md-6 col-sm-12\">\r\n      <div class=\"form-group\">\r\n        <label class=\"control-label col-sm-3 col-md-4\">Officer Type</label>\r\n        <div class=\"form-control-static col-sm-9 col-md-8\">"
    + alias2((helpers["default"] || (depth0 && depth0["default"]) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.officer : depth0)) != null ? stack1.officerType : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\r\n      </div>\r\n    </div>\r\n    <div class=\"col-md-6 col-sm-12\">\r\n      <div class=\"form-group\">\r\n        <label class=\"control-label col-sm-3 col-md-4\">Officer Title</label>\r\n        <div class=\"form-control-static col-sm-9 col-md-8\">"
    + alias2((helpers["default"] || (depth0 && depth0["default"]) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.officer : depth0)) != null ? stack1.title : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\r\n      </div>\r\n    </div>\r\n  </div>\r\n  <div class=\"row\">\r\n    <div class=\"col-md-6 col-sm-12\">\r\n      <div class=\"form-group\">\r\n        <label class=\"control-label col-sm-3 col-md-4\">First Name</label>\r\n        <div class=\"form-control-static col-md-8 col-sm-3\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.officer : depth0)) != null ? stack1.firstName : stack1), depth0))
    + "</div>\r\n      </div>\r\n    </div>\r\n    <div class=\"col-md-6 col-sm-12\">\r\n      <div class=\"form-group\">\r\n        <label class=\"control-label col-sm-3 col-md-4\">Last Name</label>\r\n        <div class=\"form-control-static col-sm-9 col-md-8\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.officer : depth0)) != null ? stack1.lastName : stack1), depth0))
    + "</div>\r\n      </div>\r\n    </div>\r\n  </div>\r\n  <div class=\"row\">\r\n    <div class=\"col-md-6 col-sm-12\">\r\n      <div class=\"form-group\">\r\n        <label class=\"control-label col-sm-3 col-md-4\">Middle Initial</label>\r\n        <div class=\"form-control-static col-md-8 col-sm-3\">"
    + alias2((helpers["default"] || (depth0 && depth0["default"]) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.officer : depth0)) != null ? stack1.middleInitial : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\r\n      </div>\r\n    </div>\r\n    <div class=\"col-md-6 col-sm-12\">\r\n      <div class=\"form-group\">\r\n        <label class=\"control-label col-sm-3 col-md-4\">Main Contact</label>\r\n        <div class=\"form-control-static col-sm-9 col-md-8\">\r\n          "
    + alias2((helpers["default"] || (depth0 && depth0["default"]) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.officer : depth0)) != null ? stack1.isMainContactDisplay : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\r\n      </div>\r\n    </div>\r\n  </div>\r\n  <div class=\"row\">\r\n    <div class=\"col-md-6 col-sm-12\">\r\n      <div class=\"form-group\">\r\n        <label class=\"control-label col-sm-3 col-md-4\">Term Length</label>\r\n        <div class=\"form-control-static col-sm-9 col-md-8\">"
    + alias2((helpers["default"] || (depth0 && depth0["default"]) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.officer : depth0)) != null ? stack1.termLength : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\r\n      </div>\r\n    </div>\r\n    <div class=\"col-md-6 col-sm-12\">\r\n      <div class=\"form-group\">\r\n        <label class=\"control-label col-sm-3 col-md-4\">Status</label>\r\n        <div class=\"form-control-static col-sm-9 col-md-8\">"
    + alias2((helpers["default"] || (depth0 && depth0["default"]) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.officer : depth0)) != null ? stack1.status : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\r\n      </div>\r\n    </div>\r\n  </div>\r\n  <div class=\"row\">\r\n    <div class=\"col-md-6 col-sm-12\">\r\n      <div class=\"form-group\">\r\n        <label class=\"control-label col-sm-3 col-md-4\">Term Start Date</label>\r\n        <div class=\"form-control-static col-sm-9 col-md-8\">"
    + alias2((helpers["default"] || (depth0 && depth0["default"]) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.officer : depth0)) != null ? stack1.termStartDate : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\r\n      </div>\r\n    </div>\r\n    <div class=\"col-md-6 col-sm-12\">\r\n      <div class=\"form-group\">\r\n        <label class=\"control-label col-sm-3 col-md-4\">Term End Date</label>\r\n        <div class=\"form-control-static col-sm-9 col-md-8\">"
    + alias2((helpers["default"] || (depth0 && depth0["default"]) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.officer : depth0)) != null ? stack1.termEndDate : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\r\n      </div>\r\n    </div>\r\n  </div>\r\n  <div class=\"row\">\r\n    <div class=\"col-md-12\">\r\n      <div class=\"form-group\">\r\n        <label class=\"control-label col-sm-3 col-md-2\">Comments</label>\r\n        <p class=\"form-control-static col-sm-9 col-md-10\">"
    + alias2((helpers["default"] || (depth0 && depth0["default"]) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.officer : depth0)) != null ? stack1.comment : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</p>\r\n      </div>\r\n    </div>\r\n  </div>\r\n</div>";
},"useData":true});