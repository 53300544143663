var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "    <a role=\"button\" class=\"btn btn-primary\" href=\"#dep/entity/"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.fund : depth0)) != null ? stack1.entityId : stack1), depth0))
    + "/filing/"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.fund : depth0)) != null ? stack1.filingId : stack1), depth0))
    + "/dashboard\" target=\"_blank\">Open\r\n      Submission</a>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "\r\n<div class=\"page-title clearfix\">\r\n  <h1 class=\"m-n p-n\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.fund : depth0)) != null ? stack1.entityName : stack1), depth0))
    + "</h1>\r\n  <div class=\"page-title-buttons-container\">\r\n    <div class=\"cs-link-container\"></div>\r\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.hasRelatedFiling : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    <div class=\"dropdown-container\"></div>\r\n  </div>\r\n</div>\r\n<div class=\"page-subtitle\">\r\n  <h2>Collective Investment Sub-Fund</h2>\r\n  <div class=\"page-subtitle-details\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.fund : depth0)) != null ? stack1.name : stack1), depth0))
    + "</div>\r\n</div>\r\n<div class=\"breadcrumb-container\"></div>\r\n<div class=\"sub-fund-wrapper\"></div>";
},"useData":true});