/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

require('../styles/ExamViolationModalView.css');
var tmplExamViolationModalView = require('../templates/ExamViolationModalView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplExamViolationModalView,

  className: 'exam-violation-modal-view',

  ui: {
    formViolation: '.form-violation',
    addViolationBtn: '.add-violation',
    formInputs: ':input:not(textarea)'
  },

  events: {
    'click @ui.addViolationBtn': 'onClickAddViolation',
    'keydown @ui.formInputs': 'onKeydownOfInputs'
  },

  templateHelpers: function() {
    return {
      examViolationCodes: this.options.examViolationCodes,
      examViolationCorrectiveActions: this.options.examViolationCorrectiveActions
    };
  },

  onBeforeShow: function() {
    if (this.model) {
      Syphon.deserialize(this, this.model.toJSON());
    }
  },

  onClickAddViolation: function(e) {
    e.preventDefault();
    this.addViolation();
  },

  onKeydownOfInputs: function(e) {
    if (e.keyCode === 13) {
      e.preventDefault();
      this.addViolation();
    }
  },

  addViolation: function() {
    var self = this;

    this.ui.formViolation
      .parsley()
      .whenValidate()
      .done(function() {
        var formData = Syphon.serialize(self);

        var violation = _.findWhere(self.options.examViolationCodes, { id: formData.examViolationCodeId });
        var violationCorrectiveAction = _.findWhere(self.options.examViolationCorrectiveActions, {
          id: formData.examViolationCorrectiveActionId
        });

        formData.violationName = violation.name + ' - ' + violation.description;
        formData.violationCorrectiveActionName =
          violationCorrectiveAction.name + ' - ' + violationCorrectiveAction.description;
        self.triggerMethod('modal:close', formData);
      });
  }
});
