/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(entityId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetSEGsByEntity: {
        $xmlns: 'http://dobs.pa.gov/dp/seg/bpm/1.0',
        EntityID: entityId
      }
    },
    blueprintArrays: [
      'GetSEGsByEntityResponse.SEGs.DP_EntitySEG',
      'GetSEGsByEntityResponse.SEGIndividuals.DP_EntitySEGIndividual'
    ],
    blueprint: {
      segs: [
        '$..DP_EntitySEG',
        {
          id: '$..ID',
          employeeCount: '$..EmployeeCount',
          status: '$..Status',
          entityId: '$..EntityID',
          name: '$..Name',
          approvalDate: '$..ApprovalDate',
          terminationDate: '$..TerminationDate',
          county: '$..County',
          address: {
            addressLine1: '$..AddressLine1',
            addressLine2: '$..AddressLine2',
            addressLine3: '$..AddressLine3',
            county: '$..County',
            city: '$..City',
            state: '$..State',
            postalCode: '$..PostalCode',
            country: '$..Country'
          }
        }
      ],
      segIndividuals: [
        '$..DP_EntitySEGIndividual',
        {
          id: '$..ID',
          segId: '$..SEGID',
          individualTypeId: '$..IndividualTypeID',
          individualType: '$..IndividualType'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      _.each(response.segs, function(seg) {
        seg.employeeCount = seg.employeeCount || '--';
        seg.status = seg.status || '--';

        seg.segIndividualList = _.reduce(
          response.segIndividuals,
          function(memo, individual) {
            if (individual.segId === seg.id) {
              memo.push({
                value: individual.individualType
              });
            }
            return memo;
          },
          []
        );

        if (seg.approvalDate) {
          seg.approvalDate = moment(seg.approvalDate, 'YYYY-MM-DD');
        }

        if (seg.terminationDate) {
          seg.terminationDate = moment(seg.terminationDate, 'YYYY-MM-DD');
        }

        var address = seg.address;
        if (address.city && address.state) {
          seg.segAddressDisplay = address.city + ', ' + address.state;
        } else if (address.city) {
          seg.segAddressDisplay = address.city;
        } else if (address.state) {
          seg.segAddressDisplay = address.state;
        } else {
          seg.segAddressDisplay = '--';
        }
      });
      deferred.resolve(response.segs);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
