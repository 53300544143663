/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var NProgress = require('nprogress');
var _get = require('lodash.get');

var GetDocumentTypesByDepartment = require('common/services/GetDocumentTypesByDepartment');

var GetFinancialStatement = require('../../services/GetFinancialStatement');
var GetAllFinancialLookups = require('../../services/GetAllFinancialLookups');
var GetOrganizationDetails = require('../../../organization-dashboard/services/GetOrganizationDetails');

var SecReviewApplicationDirectorFinancialTaskView = require('./SecReviewApplicationDirectorFinancialTaskView');

module.exports = {
  event: 'build:sc:review-application-director-financial',

  channel: 'task',

  builder: function(task) {
    var defer = $.Deferred();
    var financialStatementId = _get(task, 'taskData.SC.scFinancialStatementId');

    $.when(
      GetFinancialStatement(financialStatementId),
      GetDocumentTypesByDepartment('SECURITIES'),
      GetAllFinancialLookups()
    )
      .done(function(financialStatement, documentTypes, lookups) {
        GetOrganizationDetails(financialStatement.organizationId)
          .done(function(organization) {
            NProgress.set(0.85);
            defer.resolve(
              new SecReviewApplicationDirectorFinancialTaskView({
                model: new Backbone.Model({
                  task: task,
                  taskId: task.taskId,
                  organization: organization,
                  financialStatementId: financialStatementId,
                  financialStatement: financialStatement,
                  documentTypes: documentTypes,
                  reviewDecisionTypes: lookups.reviewDecisionTypes
                })
              })
            );
          })
          .fail(function(err) {
            NProgress.done();
            defer.reject(err);
          });
      })
      .fail(function(err) {
        NProgress.done();
        defer.reject(err);
      });
    return defer.promise();
  }
};
