var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "\r\n<SOAP:Envelope xmlns:SOAP=\"http://schemas.xmlsoap.org/soap/envelope/\">\r\n  <SOAP:Body>\r\n    <DownloadDocumentStatic xmlns=\"http://dobs.pa.gov/cm/documentmgmt/bpm/1.0\">\r\n      <DocumentID>"
    + alias4(((helper = (helper = helpers.fileId || (depth0 != null ? depth0.fileId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"fileId","hash":{},"data":data}) : helper)))
    + "</DocumentID>\r\n      <CSDocumentID>"
    + alias4(((helper = (helper = helpers.csDocumentId || (depth0 != null ? depth0.csDocumentId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"csDocumentId","hash":{},"data":data}) : helper)))
    + "</CSDocumentID>\r\n    </DownloadDocumentStatic>\r\n  </SOAP:Body>\r\n</SOAP:Envelope>";
},"useData":true});