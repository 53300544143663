/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Radio = require('backbone.radio');
var NProgress = require('nprogress');

var Scaffold = require('../layout/Scaffold');

var GetTaskData = require('../services/GetTaskData');
var showErrorMessage = require('../util/showErrorMessage');

module.exports = function(taskId) {
  NProgress.start();
  GetTaskData(taskId)
    .done(function(taskData) {
      NProgress.set(0.33);
      var TaskBuilderPromise = Radio.channel('task').request('build:' + taskData.taskType.formIdentifier, taskData);
      if (!TaskBuilderPromise) {
        NProgress.done();
        showErrorMessage({
          errorCode: 'task.not.found',
          errorTitle: 'Task Type Not Found',
          errorMessage: 'The type of task being loaded could not be found in the system. Contact site administrator.'
        });
        return;
      }
      TaskBuilderPromise.done(function(taskView) {
        NProgress.done();
        taskView.options.taskId = taskId;
        Scaffold.content.show(taskView);
      }).fail(function(err) {
        NProgress.done();
      });
    })
    .fail(function(err) {
      NProgress.done();
    });
};
