/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var moment = require('moment');

var GridView = require('psa-marionette/views/GridView');
var UIUtil = require('psa-core/util/ui');

var MultiButtonCell = require('common/backgrid/MultiButtonCell');
var DollarCell = require('common/backgrid/DollarCell');
var FileUploadView = require('common/views/FileUploadView');
var FileUploadTableView = require('common/views/FileUploadTableView');
var makeUploadCollection = require('common/util/makeUploadCollection');

var modelGet = require('common/util/modelGet');
var makeFormReadOnly = require('common/util/makeFormReadOnly');
var toggleForm = require('common/util/toggleForm');

var ExamViolationModalView = require('./ExamViolationModalView');

require('../styles/ExamRecordedResultsView.css');
var tmplExamRecordedResultsView = require('../templates/ExamRecordedResultsView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplExamRecordedResultsView,

  className: 'exam-recorded-results-view',

  ui: {
    addViolationBtn: '.add-violation',
    violationsGridContainer: '.violations-grid-container',
    examRatingContainer: '.exam-rating-container',
    examDocumentContainer: '.exam-document-container',
    supportingDocumentsContainer: '.supporting-documents-container',
    examStartDate: '.exam-start-date',
    examEndDate: '.exam-end-date'
  },

  regions: {
    violationsGridContainer: '@ui.violationsGridContainer',
    examDocumentContainer: '@ui.examDocumentContainer',
    supportingDocumentsContainer: '@ui.supportingDocumentsContainer'
  },

  events: {
    'click @ui.addViolationBtn': 'onClickAddViolation'
  },

  /**
   * This builder accepts the following modes:
   * ----------------------------------------
   * examiner
   * regional-director
   * exam-director
   */
  initialize: function(options) {
    this.mode = options.mode;
  },

  templateHelpers: function() {
    return {
      mode: this.mode
    };
  },

  onBeforeShow: function(e) {
    this.violationsGrid = new GridView({
      columns: [
        {
          name: 'violationName',
          label: 'Violation',
          cell: 'string',
          headerCell: 'custom',
          width: 35,
          editable: false
        },
        {
          name: 'violationCorrectiveActionName',
          label: 'Status',
          cell: 'string',
          headerCell: 'custom',
          width: 33,
          editable: false
        },
        {
          name: 'refundAmount',
          label: 'Refund Amount',
          cell: DollarCell,
          headerCell: 'custom',
          width: 16,
          editable: false
        },
        {
          name: 'editDeleteMultiButtonCell',
          label: '',
          width: 16,
          cell: MultiButtonCell.extend({
            buttons: [
              {
                name: 'Edit',
                labelClass: '',
                buttonClass: 'btn-primary btn-sm',
                onClick: this.onClickEditViolation.bind(this)
              },
              {
                name: 'Remove',
                labelClass: '',
                buttonClass: 'btn-danger btn-sm',
                onClick: this.onClickRemoveViolation.bind(this)
              }
            ]
          }),
          editable: false,
          sortable: false
        }
      ],
      collection: new Backbone.Collection(modelGet(this.model, 'exam.examViolations', [])),
      emptyText: 'No Violations Added'
    });

    // Remove Edit and Delete buttons when form is being displayed for review
    var isExamReview = this.mode !== 'examiner';
    if (isExamReview) {
      this.violationsGrid.options.columns.splice(4, 1);
      this.violationsGrid.options.columns.splice(3, 1);
      this.violationsGrid.options.columns[0].width = 42;
      this.violationsGrid.options.columns[1].width = 42;
    }

    this.showChildView('violationsGridContainer', this.violationsGrid);

    if (!isExamReview) {
      this.$('.table').addClass('table-bordered');
    }

    this.examDocument = new FileUploadView({
      collection: makeUploadCollection(this.model.get('exam').examDocument),
      isRequired: true,
      isReadOnly: isExamReview,
      metadata: {
        documentTypeId: 10008
      }
    });

    this.showChildView('examDocumentContainer', this.examDocument);

    var mode = this.mode;

    // Show the rating field if the exam is on a certain type of license
    var licenseTypeCode = this.model.get('exam').licenseTypeCode;
    var showRating = _.contains(['ML', 'MB', 'MT'], licenseTypeCode);
    toggleForm(this.ui.examRatingContainer, showRating);

    Syphon.deserialize(this, this.model.get('exam'));

    this.ui.examStartDate.datepicker();
    this.ui.examEndDate.datepicker();

    var decisionName = modelGet(this.model, 'previousReviewDecision.decisionName');
    if (mode === 'examiner' && decisionName !== 'Revision Required') {
      var date = moment().format('MM/DD/YYYY');
      var examStartDate = this.model.get('exam').examDate;
      if (this.model.get('exam').examLeadTimeDays) {
        moment(date, 'MM/DD/YYYY').add(this.model.get('exam').examLeadTimeDays, 'days');
      }
      if (this.model.get('exam').previousExam) {
        examStartDate = this.model.get('exam').previousExam;
      }

      examStartDate = moment(examStartDate).format('MM/DD/YYYY');

      this.ui.examStartDate.datepicker('setDate', examStartDate);
      this.ui.examEndDate.datepicker('setDate', date);
    }

    // Set the parts of the form which should be read only in different cases
    var isRegionalDirectorReview = this.mode === 'regional-director';
    var isExamDirectorReview = this.mode === 'exam-director';
    if (isRegionalDirectorReview) {
      makeFormReadOnly(this.$el);
    } else if (isExamDirectorReview) {
      makeFormReadOnly(this.$el);
    }

    // Set the documentType property to be the documentTypeId while form is
    // being edited
    var supportingDocuments = modelGet(this.model, 'exam.examResultsSupportingDocuments', []);
    if (this.mode === 'examiner') {
      _.each(supportingDocuments, function(document) {
        document.documentType = document.documentTypeId;
      });
    }

    this.fileUploadTable = new FileUploadTableView({
      isReadOnly: isExamReview,
      collection: new Backbone.Collection(supportingDocuments),
      emptyText: 'No Supporting Documents Added',
      documentTypes: this.options.documentTypes
    });
    this.showChildView('supportingDocumentsContainer', this.fileUploadTable);
  },

  onClickAddViolation: function(e) {
    e.preventDefault();
    this.openViolationModal();
  },

  onClickEditViolation: function(e, model) {
    e.preventDefault();
    this.openViolationModal(model);
  },

  onClickRemoveViolation: function(e, model) {
    e.preventDefault();
    this.violationsGrid.collection.remove(model);
  },

  validate: function() {
    return $.when(this.examDocument.validate(), this.fileUploadTable.validate());
  },

  getFormData: function() {
    var supportingDocuments = this.fileUploadTable.getFormData();
    var examDocumentId = this.examDocument.getFirstFileId();
    var violations = this.violationsGrid.collection.toJSON();

    return {
      supportingDocuments: supportingDocuments,
      violations: violations,
      examDocumentId: examDocumentId
    };
  },

  openViolationModal: function(model) {
    var self = this;
    UIUtil.showModal({
      title: 'Exam Violation',
      dialogClasses: 'modal-dialog-exam-violation-modal-view',
      view: new ExamViolationModalView({
        examViolationCodes: modelGet(this.model, 'examLookups.examViolationCodes'),
        examViolationCorrectiveActions: modelGet(this.model, 'examLookups.examViolationCorrectiveActions'),
        model: model
      })
    }).done(function(violation) {
      if (model) {
        // Edit
        model.set(violation);
      } else {
        // Add
        self.violationsGrid.collection.add(violation);
      }
    });
  }
});
