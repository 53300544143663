/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(data) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetComplianceByObject: {
        $xmlns: 'http://dobs.pa.gov/sc/compliance/bpm/1.0',
        ObjectID: data.objectId,
        ObjectTypeID: data.objectTypeId
      }
    },
    blueprintArrays: ['GetComplianceByObjectResponse.SC_Compliance'],
    blueprint: {
      compliances: [
        '$..SC_Compliance',
        {
          id: '$..ID',
          issuedDate: '$..IssuedDate',
          actionTypeId: '$..ActionTypeID',
          actionType: '$..ActionType',
          criminalAuthActionId: '$..CriminalAuthActionID',
          criminalAuthAction: '$..CriminalAuthAction',
          otherActionTypeId: '$..OtherActionTypeID',
          otherActionType: '$..OtherActionType'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      _.each(response.compliances, function(compliance) {
        if (compliance.issuedDate) {
          compliance.issuedDate = moment(compliance.issuedDate, 'YYYY-MM-DD');
        }
        compliance.actionType = compliance.actionType || '--';
        compliance.criminalAuthAction = compliance.criminalAuthAction || '--';
        compliance.otherActionType = compliance.otherActionType || '--';
      });

      deferred.resolve(response.compliances);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
