var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=helpers.helperMissing, alias5="function";

  return "\r\n<h1 class=\"page-title\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.individualDetails : depth0)) != null ? stack1.individualType : stack1), depth0))
    + " - "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.individualDetails : depth0)) != null ? stack1.firstName : stack1), depth0))
    + " "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.individualDetails : depth0)) != null ? stack1.lastName : stack1), depth0))
    + "</h1>\r\n<div class=\"breadcrumb-container\"></div>\r\n<div class=\"form-horizontal m-b-lg col-sm-9 col-sm-offset-1\">\r\n  <div class=\"row\">\r\n    <div class=\"col-sm-9 col-sm-offset-3\">\r\n      <h2 class=\"m-t-n\">Initiate Correspondence</h2>\r\n    </div>\r\n  </div>\r\n  <div class=\"correspondence-form-container\"></div>\r\n  <div class=\"form-horizontal\">\r\n    <div class=\"form-group\">\r\n      <div class=\"col-sm-9 col-sm-offset-3\">\r\n        <a href=\"#nondep/entity/"
    + alias2(((helper = (helper = helpers.entityId || (depth0 != null ? depth0.entityId : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"entityId","hash":{},"data":data}) : helper)))
    + "/license/"
    + alias2(((helper = (helper = helpers.licenseId || (depth0 != null ? depth0.licenseId : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"licenseId","hash":{},"data":data}) : helper)))
    + "/dashboard\" class=\"btn btn-default\">Cancel</a>\r\n        <button class=\"btn btn-primary btn-submit submit\">Submit</button>\r\n      </div>\r\n    </div>\r\n  </div>\r\n</div>";
},"useData":true});