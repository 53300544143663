/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(subsidiaryId) {
  var defer = $.Deferred();

  $.soap({
    body: {
      GetSubsidiary: {
        $xmlns: 'http://dobs.pa.gov/dp/subsidiary/bpm/1.0',
        SubsidiaryID: subsidiaryId
      }
    },
    blueprint: {
      entityId: '$..EntityID',
      name: '$..Name',
      status: '$..Status',
      statusId: '$..StatusID',
      statusDate: '$..StatusDate',
      typeId: '$..TypeID',
      type: '$..Type',
      approvalDate: '$..ApprovalDate',
      comments: '$..Comments'
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      if (response.statusDate) {
        response.statusDate = moment(response.statusDate, 'YYYY-MM-DD').format('MM/DD/YYYY');
      }
      if (response.approvalDate) {
        response.approvalDate = moment(response.approvalDate, 'YYYY-MM-DD').format('MM/DD/YYYY');
      }
      defer.resolve(response);
    })
    .fail(function(err) {
      defer.reject(err);
    });

  return defer.promise();
};
