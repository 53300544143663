/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var NProgress = require('nprogress');
var Radio = require('backbone.radio');
var _get = require('lodash.get');

var GetFilingDetails = require('../../../common/services/GetFilingDetails');

var DepEntitySubmitFilingTaskView = require('./DepEntitySubmitFilingTaskView');

module.exports = {
  event: 'build:dp:entity-update-filing',

  channel: 'task',

  builder: function(task) {
    var defer = $.Deferred();

    var filingId = _get(task, 'taskData.DP.dpFilingId');

    GetFilingDetails(filingId).done(function(filingDetails) {
      NProgress.set(0.5);

      var filingFormIdentifier = filingDetails.formIdentifier;

      var BuildFilingPromise = Radio.channel('dp:filing').request('build:' + filingFormIdentifier, {
        filingId: filingId,
        entityCategoryId: filingDetails.entityCategoryId,
        mode: 'resubmit',
        isReadOnly: false,
        taskId: task.taskId
      });

      BuildFilingPromise.done(function(filingFormView) {
        NProgress.set(0.85);
        filingFormView.model.set({
          filingId: filingId,
          filingDetails: filingDetails
        });
        defer.resolve(
          new DepEntitySubmitFilingTaskView({
            filingFormView: filingFormView,
            model: new Backbone.Model({
              taskId: task.taskId,
              task: task,
              filingId: filingId,
              filingDetails: filingDetails
            })
          })
        );
      }).fail(function(err) {
        defer.reject(err);
      });
    });
    return defer.promise();
  }
};
