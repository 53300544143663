var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "\r\n<div class=\"row\">\r\n  <div class=\"col-sm-12 col-md-6\">\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-3 col-md-4\">BD/Underwriter</label>\r\n      <div class=\"form-control-static col-sm-9 col-md-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.BDUnderwriter : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\r\n    </div>\r\n  </div>\r\n</div>\r\n<div class=\"row\">\r\n  <div class=\"col-sm-12 col-md-6\">\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-3 col-md-4\">Construction of Buildings & Facilities</label>\r\n      <div class=\"form-control-static col-sm-9 col-md-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.buildingsConstructionAmountDisplay : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\r\n    </div>\r\n  </div>\r\n  <div class=\"col-sm-12 col-md-6\">\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-3 col-md-4\">Equipment Purchases</label>\r\n      <div class=\"form-control-static col-sm-9 col-md-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.equipmentPurchasesAmountDisplay : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\r\n    </div>\r\n  </div>\r\n</div>\r\n<div class=\"row\">\r\n  <div class=\"col-sm-12 col-md-6\">\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-3 col-md-4\">Research & Development</label>\r\n      <div class=\"form-control-static col-sm-9 col-md-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.researchAmountDisplay : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\r\n    </div>\r\n  </div>\r\n  <div class=\"col-sm-12 col-md-6\">\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-3 col-md-4\">Labor & Production Cost</label>\r\n      <div class=\"form-control-static col-sm-9 col-md-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.laborProductionCostDisplay : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\r\n    </div>\r\n  </div>\r\n</div>\r\n<div class=\"row\">\r\n  <div class=\"col-sm-12 col-md-12\">\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-3 col-md-2\">Securities Description</label>\r\n      <p class=\"form-control-static col-sm-9 col-md-10\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.securitiesDescription : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</p>\r\n    </div>\r\n  </div>\r\n</div>";
},"useData":true});