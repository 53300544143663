/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Radio = require('backbone.radio');
var Ladda = require('ladda');

var UIUtil = require('psa-core/util/ui');

var BreadcrumbView = require('common/views/BreadcrumbView');
var ConSerIndividualSearchModalView = require('./ConSerIndividualSearchModalView');

var SubmitEntityIndividual = require('../services/SubmitEntityIndividual');

var tmplConSerEntityJoinIndividualView = require('../templates/ConSerEntityJoinIndividualView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplConSerEntityJoinIndividualView,

  className: 'con-ser-entity-join-individual-view container-fluid',

  ui: {
    breadcrumb: '.breadcrumb-container',
    individualSelectionForm: '.individual-selection-form',
    individualSearchButton: '.btn-individual-search',
    individualClearButton: '.clear-individual',
    selectedIndividualId: '.selected-individual-id',
    selectedIndividualName: '.selected-individual-name',
    submit: '.submit'
  },

  regions: {
    breadcrumb: '@ui.breadcrumb'
  },

  events: {
    'click @ui.submit': 'onClickSubmit',
    'click @ui.individualSearchButton': 'onClickIndividualSearchButton',
    'click @ui.individualClearButton': 'onClearIndividual'
  },

  onBeforeShow: function() {
    var entity = this.model.get('entity') || {};
    var entityId = entity.entityId;

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#conser/dashboard',
            text: 'Home'
          },
          {
            link: '#conser/entity/' + entityId + '/dashboard',
            text: entity.entityName
          },
          {
            text: 'Edit Entity Details'
          }
        ]
      })
    );
  },

  onClickIndividualSearchButton: function(e) {
    e.preventDefault();
    var self = this;

    UIUtil.showModal({
      title: 'Search for an Individual',
      dialogClasses: 'modal-dialog-individual-search-modal-view modal-lg',
      view: new ConSerIndividualSearchModalView({
        model: self.model
      })
    }).done(function(individual) {
      if (individual) {
        self.ui.selectedIndividualId.val(individual.individualId);
        self.ui.selectedIndividualName.val(individual.firstName + ' ' + individual.lastName);
        self.validate();
      } else {
        self.ui.selectedIndividualId.val('');
        self.ui.selectedIndividualName.val('');
      }
    });
  },

  onClearIndividual: function(e) {
    e.preventDefault();
    this.ui.selectedIndividualId.val('');
    this.ui.selectedIndividualName.val('');
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;
    this.validate().done(function() {
      var l = Ladda.create(self.ui.submit[0]);
      l.start();
      var formData = self.getFormData();
      var departmentId = '4000';
      SubmitEntityIndividual(formData)
        .done(function() {
          Radio.channel('navigate').trigger('show:int:cs:entity', departmentId, self.model.get('entityId'));
        })
        .fail(function() {
          l.stop();
        });
    });
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    formData.shouldDelete = false;
    formData.entityId = this.model.get('entityId');

    return formData;
  },

  validate: function() {
    return this.ui.individualSelectionForm.parsley().whenValidate();
  }
});
