/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Syphon = require('backbone.syphon');
var Marionette = require('backbone.marionette');
var Handlebars = require('handlebars');

var modelGet = require('common/util/modelGet');
var makeFormReadOnly = require('common/util/makeFormReadOnly');

var ExamQuestionFileUploadTableView = require('./ExamQuestionFileUploadTableView');

require('../styles/ExamQuestionView.css');
var tmplExamQuestionView = require('../templates/ExamQuestionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplExamQuestionView,

  className: 'exam-question-view',

  ui: {
    fileUploadContainer: '.file-upload-container',
    notApplicable: '.not-applicable',
    notApplicableContainer: '.not-applicable-container',
    notApplicableExplainationContainer: '.not-applicable-explaination-container',
    notApplicableExplaination: '.not-applicable-explaination',
    answerContainer: '.answer-container',
    responseValue: '[name="responseValue"]'
  },

  regions: {
    fileUploadContainer: '@ui.fileUploadContainer'
  },

  events: {
    'change @ui.notApplicable': 'onChangeNotApplicable'
  },

  initialize: function() {
    var itemType = this.model.get('itemType');
    this.isAttachmentQuestion = this.model.get('itemType') === 'ATTACH';
    this.isQuestion = _.contains(['ATTACH', 'BOOL', 'DOLLAR', 'NUMBER', 'TEXT'], itemType);
  },

  templateHelpers: function() {
    var template = Handlebars.compile(this.model.get('item'));
    return {
      isQuestion: this.isQuestion,
      item: template(this.options.examQuestionParameters)
    };
  },

  onBeforeShow: function() {
    var mode = this.options.mode;
    if (this.isAttachmentQuestion) {
      this.fileUploadTable = new ExamQuestionFileUploadTableView({
        emptyText: 'No Documents Attached',
        mode: mode,
        metadata: {
          documentTypeId: this.model.get('documentTypeId')
        }
      });
      this.showChildView('fileUploadContainer', this.fileUploadTable);
    }
    if (this.model.get('response')) {
      var isNotApplicable = modelGet(this.model, 'response.isNotApplicable');
      this.toggleApplicable(isNotApplicable);
      Syphon.deserialize(this, this.model.get('response'));
      var responseDocuments = modelGet(this.model, 'response.documents', []);
      if (responseDocuments.length && this.isAttachmentQuestion) {
        this.fileUploadTable.collection.add(responseDocuments);
      }
    }

    this.onChangeNotApplicable();

    var isInternal = mode === 'record-results' || mode === 'review-results';
    if (isInternal) {
      makeFormReadOnly(this.ui.notApplicableContainer);
      this.ui.responseValue.prop('disabled', true);
    }
  },

  onChangeNotApplicable: function() {
    this.toggleApplicable(this.isNotApplicable());
  },

  validate: function() {
    var isApplicable = !this.isNotApplicable();
    if (this.isAttachmentQuestion && isApplicable) {
      return this.fileUploadTable.validate();
    } else {
      return null;
    }
  },

  getFormData: function() {
    // Return nothing if this is simply a text display
    if (!this.isQuestion) {
      return null;
    }

    var formData = Syphon.serialize(this);
    _.extend(formData, {
      examSetupQuestionId: this.model.get('examSetupQuestionId')
    });

    if (this.isAttachmentQuestion) {
      _.extend(formData, {
        isResponseDocuments: true,
        responseDocuments: this.fileUploadTable.getFormData()
      });
    }

    // Dont send answers if the user selects not applicable
    if (this.isNotApplicable()) {
      formData.responseDocuments = [];
      formData.responseValue = null;
    } else {
      // Dont send Not Applicable Reason unless necessary
      formData.notApplicableReason = null;
    }

    // Return nothing if user enters nothing
    if (this.isAttachmentQuestion && !formData.responseDocuments.length && !this.isNotApplicable()) {
      return null;
    }
    if (!this.isAttachmentQuestion && !formData.responseValue && !this.isNotApplicable()) {
      return null;
    }
    return formData;
  },

  getRetainedDocuments: function() {
    if (this.isAttachmentQuestion) {
      return this.fileUploadTable.getRetainedDocuments();
    }
  },

  isNotApplicable: function() {
    return this.ui.notApplicable.prop('checked');
  },

  toggleApplicable: function(isNotApplicable) {
    if (isNotApplicable) {
      this.ui.notApplicableExplainationContainer.show();
      this.ui.answerContainer.hide();
      this.ui.notApplicableExplaination.prop('disabled', false);
    } else {
      this.ui.notApplicableExplainationContainer.hide();
      this.ui.answerContainer.show();
      this.ui.notApplicableExplaination.prop('disabled', true);
    }
  }
});
