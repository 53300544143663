/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var GridView = require('psa-marionette/views/GridView');
var Marionette = require('backbone.marionette');

var MomentCell = require('common/backgrid/MomentCell');
var ButtonLinkCell = require('common/backgrid/ButtonLinkCell');

var tmplDepEntitySubsidiarySectionView = require('../templates/DepEntitySubsidiarySectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepEntitySubsidiarySectionView,

  className: 'dep-subsidiary-section-view',

  ui: {
    subsidiariesList: '.subsidiary-list-container'
  },

  regions: {
    subsidiariesList: '@ui.subsidiariesList'
  },

  onBeforeShow: function() {
    var gridColumns = [
      {
        name: 'name',
        label: 'Name',
        cell: 'string',
        headerCell: 'custom',
        width: 15,
        editable: false
      },
      {
        name: 'status',
        label: 'Status',
        cell: 'string',
        headerCell: 'custom',
        width: 8,
        editable: false
      },
      {
        name: 'statusDate',
        label: 'Status Date',
        cell: MomentCell.MomentCell,
        formatter: MomentCell.MomentDateFormatter.extend({
          emptyValue: '--'
        }),
        sortValue: MomentCell.MomentSortValue,
        headerCell: 'custom',
        width: 10,
        editable: false
      },
      {
        name: 'type',
        label: 'Type',
        cell: 'string',
        headerCell: 'custom',
        width: 12,
        editable: false
      },
      {
        name: 'approvalDate',
        label: 'Approval Date',
        cell: MomentCell.MomentCell,
        formatter: MomentCell.MomentDateFormatter.extend({
          emptyValue: '--'
        }),
        sortValue: MomentCell.MomentSortValue,
        headerCell: 'custom',
        width: 10,
        editable: false
      },
      {
        name: 'comments',
        label: 'Comments',
        cell: 'string',
        headerCell: 'custom',
        width: 35,
        editable: false
      },
      {
        name: '',
        label: '',
        cell: ButtonLinkCell.extend({
          name: 'Open',
          buttonClass: 'btn-primary btn-sm',
          href: function(model) {
            var entityId = model.get('entityId');
            var subsidiaryId = model.get('id');
            return '#dep/entity/' + entityId + '/subsidiary/' + subsidiaryId + '/dashboard';
          }
        }),
        headerCell: 'custom',
        width: 10,
        editable: false,
        sortable: false
      }
    ];

    var entityTypeId = this.options.entityTypeId;
    var isCreditUnion = entityTypeId === '11';

    this.subsidiariesGrid = new GridView({
      columns: gridColumns,
      emptyText: isCreditUnion ? 'No CUSOs' : 'No Subsidiaries',
      collection: this.collection
    });
    this.showChildView('subsidiariesList', this.subsidiariesGrid);
  }
});
