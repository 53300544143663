/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var _get = require('lodash.get');
var momentUtil = require('common/util/momentUtil');

module.exports = function(formData) {
  var filingId = formData.filingId;
  var pointOfContact = formData.pointOfContact;
  var lawyerExternalUserId = _get(formData, 'collectiveInvestmentFundSubmissionLawyer.lawyerExternalUserId');
  var funds = formData.funds;
  var newSubfunds = funds.newSubfunds || [];
  var subfundActions = funds.subfundActions || [];
  var supportingDocuments = funds.supportingDocuments || [];
  var certification = formData.collectiveInvestmentFundCertification || {};
  var formOutboundDateConfig = {
    sourceFormat: 'MM/DD/YYYY',
    targetFormat: 'YYYY-MM-DD',
    default: ''
  };

  funds.terminationDate = momentUtil.formatStr(funds.terminationDate, formOutboundDateConfig);
  funds.effectiveDateAmend = momentUtil.formatStr(funds.effectiveDateAmend, formOutboundDateConfig);
  funds.effectiveDateTrust = momentUtil.formatStr(funds.effectiveDateTrust, formOutboundDateConfig);

  _.each(subfundActions, function(subfundAction) {
    subfundAction.subfundTerminationDate = momentUtil.formatStr(
      subfundAction.subfundTerminationDate,
      formOutboundDateConfig
    );
  });
  return $.soap({
    body: {
      SubmitCollectiveInvestmentFundFiling: {
        $xmlns: 'http://dobs.pa.gov/dp/filing/bpm/1.0',
        FilingID: filingId,
        CollectiveInvestmentFundFilingID: formData.collectiveInvestmentFundFilingId,
        FundActionID: funds.fundActionId,
        FundID: funds.fundId,
        FundName: funds.fundName,
        InvestmentPowers: funds.investmentPowers,
        IncomeAllocation: funds.incomeAllocation,
        ParticipationTOC: funds.participationTOC,
        AssetValuaton: funds.assetValuaton,
        ValuationPeriod: funds.valuationPeriod,
        TerminationBasis: funds.terminationBasis,
        AccountAudit: funds.accountAudit,
        OtherMatters: funds.otherMatters,
        TrustDocID: funds.trustDocId,
        SummaryOfChanges: funds.summaryOfChanges,
        TrustAmendedDocID: funds.trustAmendedDocId,
        TrustRedlineDocID: funds.trustRedlineDocId,
        TerminationDate: funds.terminationDate,
        EffectiveAmendDate: funds.effectiveDateAmend,
        EffectiveTrustDate: funds.effectiveDateTrust,
        IsCertified: certification.isCertified ? '1' : '0',
        CertificationName: certification.certificationName,
        CertificationDocID: certification.certificationDocId,
        SupportingDocDescriptionField: funds.supportingDocDescriptionField,
        PointOfContact: {
          Name: pointOfContact.name,
          Title: pointOfContact.title,
          Phone: pointOfContact.phone,
          Email: pointOfContact.email,
          AddressLine1: pointOfContact.addressLine1,
          AddressLine2: pointOfContact.addressLine2,
          AddressLine3: pointOfContact.addressLine3,
          City: pointOfContact.city,
          State: pointOfContact.state,
          PostalCode: pointOfContact.postalCode,
          County: pointOfContact.county,
          Country: pointOfContact.country
        },
        LawyerExternalUserID: lawyerExternalUserId,
        NewSubfunds: (function() {
          if (!newSubfunds || newSubfunds.length === 0) {
            return {};
          }
          return {
            NewSubfund: _.map(newSubfunds, function(newSubfund) {
              return {
                SubfundName: newSubfund.subfundName
              };
            })
          };
        })(),
        SubfundActions: (function() {
          if (!subfundActions || subfundActions.length === 0) {
            return {};
          }
          return {
            SubfundAction: _.map(subfundActions, function(subfundAction) {
              return {
                SubFundID: subfundAction.subFundId,
                SubfundName: subfundAction.subfundName,
                SummaryOfChanges: subfundAction.summaryOfChanges,
                FundActionID: subfundAction.subfundActionId,
                IsTerminated: subfundAction.subfundActionId === '3' ? 1 : 0,
                TerminationDate: subfundAction.subfundTerminationDate,
                EffectiveTrustDate: subfundAction.subfundEffectiveDateTrust,
                EffectiveAmendDate: subfundAction.subfundEffectiveDateAmend
              };
            })
          };
        })(),
        SupportingDocuments: (function() {
          if (!supportingDocuments.length) {
            return {};
          }
          return {
            Document: _.map(supportingDocuments, function(supportingDocument) {
              return {
                DocumentID: supportingDocument.fileId
              };
            })
          };
        })()
      }
    }
  });
};
