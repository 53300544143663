/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Radio = require('backbone.radio');
var Ladda = require('ladda');
var _ = require('underscore');

var momentUtil = require('common/util/momentUtil');
var modelGet = require('common/util/modelGet');

var BreadcrumbView = require('common/views/BreadcrumbView');
var SecExamHeaderBarView = require('../../exam-dashboard/views/SecExamHeaderBarView');
var SecInvInqHeaderBarView = require('../../investigation-inquiry-dashboard/views/SecInvInqHeaderBarView');
var SubmitExamInvInqStaff = require('../services/SubmitExamInvInqStaff');

var tmplSecExamInvInqStaffMemberEditView = require('../templates/SecExamInvInqStaffMemberEditView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplSecExamInvInqStaffMemberEditView,

  className: 'sec-exam-inv-inq-staff-member-edit-view container-fluid',

  ui: {
    headerBar: '.header-bar-container',
    breadcrumb: '.breadcrumb-container',
    staffForm: '.staff-form',
    datePickerField: '.date-picker',
    submit: '.submit'
  },

  regions: {
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  templateHelpers: function() {
    var staffMembers = modelGet(this.model, 'staffMembers', []);
    var staffMember = modelGet(this.model, 'staffMember.staffMember');
    var staffMemberFirstName = modelGet(this.model, 'staffMember.firstName');
    var staffMemberLastName = modelGet(this.model, 'staffMember.lastName');

    /*
     * Current staff member could no longer have role, add current staff member
     * with internal user data if username does not exist in staffMembers array
     */
    var currentStaffMemberHasRole = _.find(staffMembers, function(cm) {
      return staffMember === cm.userId;
    });

    if (staffMember && !currentStaffMemberHasRole) {
      var currentStaffMember = {
        userId: staffMember,
        name: !staffMemberFirstName && !staffMemberLastName ? '' : staffMemberFirstName + ' ' + staffMemberLastName
      };
      staffMembers.splice(_.sortedIndex(staffMembers, currentStaffMember, 'name'), 0, currentStaffMember);
    }

    return {
      staffMembers: staffMembers
    };
  },

  onBeforeShow: function() {
    var examIdentifiers = this.model.get('examIdentifiers') || {};
    var identifiers = this.model.get('identifiers') || {};
    var isExam = this.model.get('isExam');
    var staffMember = this.model.get('staffMember') || {};
    var objectId = this.model.get('objectId');
    var objectLicenseId = identifiers.objectLicenseId;

    var headerView;

    var breadcrumbs = [
      {
        link: '#sec/dashboard',
        text: 'Home'
      }
    ];

    if (isExam) {
      breadcrumbs = breadcrumbs.concat([
        {
          link: '#sec/organization/' + examIdentifiers.organizationId + '/dashboard',
          text: examIdentifiers.applicantLegalName
        },
        {
          link:
            '#sec/organization/' +
            examIdentifiers.organizationId +
            '/license/' +
            examIdentifiers.organizationLicenseId +
            '/dashboard',
          text: examIdentifiers.licenseType
        },
        {
          link: '#sec/exam/' + objectId + '/dashboard',
          text: 'Exam (' + examIdentifiers.examNumber + ')'
        }
      ]);
      headerView = new SecExamHeaderBarView({
        model: new Backbone.Model(examIdentifiers)
      });
    } else if (this.model.get('isInvInq')) {
      if (identifiers.isOrganization === '1') {
        breadcrumbs.push({
          link: '#sec/organization/' + identifiers.objectId + '/dashboard',
          text: identifiers.applicantLegalName
        });
        if (objectLicenseId) {
          breadcrumbs.push({
            link:
              '#sec/organization/' + identifiers.objectId + '/license/' + identifiers.objectLicenseId + '/dashboard',
            text: identifiers.licenseType
          });
        }
      } else {
        breadcrumbs.push({
          link: '#sec/individual/' + identifiers.objectId + '/dashboard',
          text: identifiers.applicantLegalName
        });
        if (objectLicenseId) {
          breadcrumbs.push({
            link: '#sec/individual/' + identifiers.objectId + '/license/' + identifiers.objectLicenseId + '/dashboard',
            text: identifiers.licenseType
          });
        }
      }
      breadcrumbs = breadcrumbs.concat([
        {
          link: '#sec/investigation-inquiry/' + identifiers.invInqId + '/dashboard',
          text: identifiers.category + ' (' + identifiers.caseNumber + ')'
        }
      ]);

      headerView = new SecInvInqHeaderBarView({
        model: new Backbone.Model(identifiers),
        isLicenseInvInq: !!identifiers.objectLicenseId
      });
    }
    breadcrumbs.push({
      text: 'Staff Member'
    });

    this.showChildView('headerBar', headerView);

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: breadcrumbs
      })
    );

    Syphon.deserialize(this, staffMember);

    this.ui.datePickerField.datepicker({});
  },

  onClickSubmit: function(event) {
    event.preventDefault();
    var self = this;
    this.validate().done(function() {
      var l = Ladda.create(self.ui.submit[0]);
      l.start();
      var formData = self.getFormData();
      SubmitExamInvInqStaff(formData)
        .done(function(directorId) {
          if (self.model.get('isExam')) {
            Radio.channel('navigate').trigger('show:int:sc:exam-dashboard', self.model.get('objectId'));
          } else if (self.model.get('isInvInq')) {
            Radio.channel('navigate').trigger(
              'show:int:sc:investigation-inquiry-dashboard',
              self.model.get('objectId')
            );
          }
        })
        .fail(function() {
          l.stop();
        });
    });
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);

    formData.objectId = this.model.get('objectId');
    formData.objectTypeId = this.model.get('objectTypeId');
    formData.staffId = this.model.get('staffId');

    formData.removedDate = momentUtil.formatStr(formData.removedDate, momentUtil.defaultOutboundDateConfig);
    formData.assignedDate = momentUtil.formatStr(formData.assignedDate, momentUtil.defaultOutboundDateConfig);

    return formData;
  },

  validate: function() {
    return this.ui.staffForm.parsley().whenValidate();
  }
});
