/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var NProgress = require('nprogress');

var NavUtils = require('psa-core/util/nav');

var GetUsersForRole = require('common/services/GetUsersForRole');
var Scaffold = require('common/layout/Scaffold');

var GetAllCorrespondences = require('../services/GetAllCorrespondences');
var GetAllComplaintLookups = require('../services/GetAllComplaintLookups');

var ConSerCorrespondenceSearchView = require('../views/ConSerCorrespondenceSearchView');

module.exports = function() {
  NProgress.start();
  NProgress.set(0.6);

  $.when(GetAllCorrespondences(), GetUsersForRole('CS - User'), GetAllComplaintLookups())
    .done(function(correspondences, csUsers, lookups) {
      Scaffold.content.show(
        new ConSerCorrespondenceSearchView({
          model: new Backbone.Model({
            correspondences: correspondences,
            csUsers: csUsers,
            status: lookups.status
          })
        })
      );
      NavUtils.navigate('conser/correspondence-search');
      NProgress.done();
    })
    .fail(function() {
      NProgress.done();
    });
};
