/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var NProgress = require('nprogress');

var NavUtils = require('psa-core/util/nav');

var Scaffold = require('common/layout/Scaffold');

var GetAllCategories = require('../../common/services/GetAllCategories');
var GetTimesheet = require('../../common/services/GetTimesheet');
var GetAllLicenseTypes = require('../../../non-depository/common/services/GetAllLicenseTypes');
var GetTimeTrackingLookupsByDepartmentName = require('../../common/services/GetTimeTrackingLookupsByDepartmentName');
var TimeTrkNonDepExaminerEntryView = require('../../nondep-timesheet/views/TimeTrkNonDepExaminerEntryView');

module.exports = function(timesheetId) {
  NProgress.start();
  NProgress.set(0.6);

  var nonDepDptId = '1000';
  $.when(
    GetAllCategories(nonDepDptId),
    GetTimesheet({ timesheetId: timesheetId }),
    GetAllLicenseTypes(),
    GetTimeTrackingLookupsByDepartmentName('NON_DEPOSITORY')
  ).done(function(categories, timesheet, licenseTypes, timeTrackingLookups) {
    NavUtils.navigate('#nondep/time-tracking/view/' + timesheetId);
    Scaffold.content.show(
      new TimeTrkNonDepExaminerEntryView({
        viewMode: 'mt', // Possible values of viewMode: 'examiner', 'reviewer', 'mt'
        model: new Backbone.Model({
          timesheetId: timesheetId,
          payPeriod: timesheet.payPeriod,
          categories: categories,
          timesheet: timesheet,
          licenseTypes: licenseTypes,
          timesheetStatuses: timeTrackingLookups.timesheetStatuses
        }),
        isReadOnly: true
      })
    );
    NProgress.done();
  });
};
