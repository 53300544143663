/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Ladda = require('ladda');

var SubmitExamEIC = require('../services/SubmitExamEIC');

var tmplDepExamReassignModalView = require('../templates/DepExamReassignModalView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepExamReassignModalView,

  className: 'dep-exam-reassign-modal-view',

  ui: {
    reassignExamForm: '.reassign-exam-form',
    submit: '.submit'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;
    this.ui.reassignExamForm
      .parsley()
      .whenValidate()
      .done(function() {
        var l = Ladda.create(self.ui.submit[0]);
        l.start();
        var formData = Syphon.serialize(self);
        formData.examId = self.model.get('id');
        SubmitExamEIC(formData)
          .done(function() {
            // Reload page after exam has been successfully reassigned.
            Backbone.history.loadUrl();
            self.triggerMethod('modal:close');
          })
          .fail(function() {
            self.triggerMethod('modal:close');
          });
      });
  }
});
