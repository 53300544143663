/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');

var UIUtil = require('psa-core/util/ui');

var SideNavSectionView = require('common/views/SideNavSectionView');
var BreadcrumbView = require('common/views/BreadcrumbView');
var DropdownView = require('common/views/DropdownView');
var ContentServerLinkView = require('common/views/ContentServerLinkView');
var modelGet = require('common/util/modelGet');
var HeaderBarView = require('common/views/HeaderBarView');
var userHasRole = require('common/util/userHasRole');

var NonDepComplianceGeneralInformationSectionView = require('./NonDepComplianceGeneralInformationSectionView');
var NonDepCompliancePaymentDetailsSectionView = require('./NonDepCompliancePaymentDetailsSectionView');
var NonDepCompliancePaymentScheduleSectionView = require('./NonDepCompliancePaymentScheduleSectionView');
var NonDepComplianceNotesSectionView = require('./NonDepComplianceNotesSectionView');
var NonDepComplianceAuditLogSectionView = require('./NonDepComplianceAuditLogSectionView');
var NonDepComplianceEditStatusModalView = require('./NonDepComplianceEditStatusModalView');

var tmplNonDepInternalComplianceDashboardView = require('../templates/NonDepInternalComplianceDashboardView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepInternalComplianceDashboardView,

  className: 'non-dep-compliance-dashboard-view container-fluid',

  ui: {
    complianceWrapper: '.compliance-wrapper',
    breadcrumb: '.breadcrumb-container',
    headerBar: '.header-bar',
    csLinkContainer: '.cs-link-container',
    complianceActionDropDown: '.dropdown-container'
  },

  regions: {
    headerBar: '@ui.headerBar',
    complianceWrapper: '@ui.complianceWrapper',
    breadcrumb: '@ui.breadcrumb',
    csLinkContainer: '@ui.csLinkContainer',
    complianceActionDropDown: '@ui.complianceActionDropDown'
  },

  onBeforeShow: function() {
    var complianceCase = this.model.get('case');
    var dropDownOptions = [
      {
        text: 'Edit General Information',
        link: '#nondep/compliance/case/' + complianceCase.complianceId + '/edit/general-information'
      },
      {
        text: 'Edit Payment Details',
        link: '#nondep/compliance/case/' + complianceCase.complianceId + '/edit/payment-details'
      }
    ];

    // Show initiate correspondence if a compliance case is created
    if (complianceCase.statusId !== '1' && complianceCase.statusId !== '5') {
      dropDownOptions = dropDownOptions.concat([
        {
          isDivider: true
        },
        {
          text: 'Initiate Correspondence',
          link: '#nondep/compliance/case/' + complianceCase.complianceId + '/initiate-correspondence'
        }
      ]);
    }

    // ND - Compliance Manager are able to update the Compliance Status
    if (userHasRole('ND - Compliance Manager')) {
      dropDownOptions = dropDownOptions.concat([
        {
          isDivider: true
        },
        {
          text: 'Edit Compliance Status',
          onClick: this.onEditComplianceStatus.bind(this)
        }
      ]);
    }

    this.showChildView(
      'complianceActionDropDown',
      new DropdownView({
        rightAlign: true,
        model: new Backbone.Model({
          name: 'Compliance Actions',
          options: dropDownOptions
        })
      })
    );

    var headerData = {
      title: 'Compliance',
      caseNumber: complianceCase.caseNumber,
      tin: complianceCase.tin,
      licenseNumber: complianceCase.licenseNumber
    };

    this.showChildView(
      'headerBar',
      new HeaderBarView({
        title: 'Compliance',
        columns: new Backbone.Collection([
          {
            name: 'caseNumber',
            label: 'Case Number'
          },
          {
            name: 'tin',
            label: 'Federal ID Number'
          },
          {
            name: 'licenseNumber',
            label: 'License Number'
          }
        ]),
        model: new Backbone.Model(headerData)
      })
    );

    this.showChildView(
      'csLinkContainer',
      new ContentServerLinkView({
        model: new Backbone.Model({
          buttonText: 'Open Compliance Folder',
          contentServerId: modelGet(this.model, 'case.CSFolderId')
        })
      })
    );

    var breadcrumbs = [
      {
        link: '#nondep/dashboard',
        text: 'Home'
      },
      {
        text: complianceCase.entityName,
        link: '#nondep/entity/' + complianceCase.entityId + '/dashboard'
      },
      {
        text: 'Case #' + complianceCase.caseNumber
      }
    ];

    if (complianceCase.licenseId) {
      breadcrumbs.splice(2, 0, {
        link: '#nondep/entity/' + complianceCase.entityId + '/license/' + complianceCase.licenseId,
        text: 'License #' + complianceCase.licenseNumber + ' - ' + complianceCase.licenseType
      });
    }

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: breadcrumbs
      })
    );

    this.complianceCase = new SideNavSectionView({
      useNavigationButtons: false,
      collection: new Backbone.Collection([
        {
          id: 'generalInformation',
          title: 'General Information',
          view: new NonDepComplianceGeneralInformationSectionView({
            model: this.model
            // isReadOnly: this.options.isReadOnly
          })
        },
        {
          id: 'paymentDetails',
          title: 'Payment Details',
          view: new NonDepCompliancePaymentDetailsSectionView({
            model: this.model
            // isReadOnly: this.options.isReadOnly
          })
        },
        {
          id: 'paymentSchedule',
          title: 'Payment Schedule',
          view: new NonDepCompliancePaymentScheduleSectionView({
            model: this.model
            // isReadOnly: this.options.isReadOnly
          })
        },
        {
          id: 'notes',
          title: 'Notes',
          view: new NonDepComplianceNotesSectionView({
            model: this.model
          })
        },
        {
          id: 'auditLog',
          title: 'Audit Log',
          view: new NonDepComplianceAuditLogSectionView({
            model: this.model
          })
        }
      ])
    });
    this.showChildView('complianceWrapper', this.complianceCase);
  },

  onEditComplianceStatus: function(e) {
    e.preventDefault();
    UIUtil.showModal({
      title: 'Edit Compliance Status',
      view: new NonDepComplianceEditStatusModalView({
        model: this.model
      })
    }).done(function() {
      Backbone.history.loadUrl();
    });
  }
});
