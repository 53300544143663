/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var GridView = require('psa-marionette/views/GridView');
var UIUtil = require('psa-core/util/ui');

var ParagraphCell = require('common/backgrid/ParagraphCell');
var MultiButtonCell = require('common/backgrid/MultiButtonCell');
var MomentCell = require('common/backgrid/MomentCell');
var ConfirmationModalView = require('common/views/ConfirmationModalView');

var SubmitComplianceSanction = require('../services/SubmitComplianceSanction');

module.exports = GridView.extend({
  className: 'sec-compliance-sanctions-section-view',

  onBeforeRender: function() {
    if (GridView.prototype.onBeforeRender) {
      GridView.prototype.onBeforeRender.apply(this.arguments);
    }
    this.options.columns = [
      {
        name: 'sanction',
        label: 'Sanction',
        cell: 'string',
        headerCell: 'custom',
        width: 15,
        editable: false
      },
      {
        name: 'note',
        label: 'Note',
        cell: ParagraphCell,
        headerCell: 'custom',
        width: 15,
        editable: false
      },
      {
        name: 'createdBy',
        label: 'Created By',
        cell: 'string',
        headerCell: 'custom',
        width: 15,
        editable: false
      },
      {
        name: 'createdDate',
        label: 'Created On',
        cell: MomentCell.MomentCell,
        formatter: MomentCell.MomentDateTimeFormatter.extend({
          emptyValue: '--'
        }),
        sortValue: MomentCell.MomentSortValue,
        headerCell: 'custom',
        width: 15,
        editable: false
      },
      {
        name: 'modifiedBy',
        label: 'Modified By',
        cell: 'string',
        headerCell: 'custom',
        width: 15,
        editable: false
      },
      {
        name: 'modifiedDate',
        label: 'Modified On',
        cell: MomentCell.MomentCell,
        formatter: MomentCell.MomentDateTimeFormatter.extend({
          emptyValue: '--'
        }),
        sortValue: MomentCell.MomentSortValue,
        headerCell: 'custom',
        width: 15,
        editable: false
      },
      {
        name: 'editDeleteMultiButtonCell',
        label: '',
        width: 10,
        cell: MultiButtonCell.extend({
          buttons: [
            {
              name: '',
              href: function(model) {
                return (
                  '#sec/compliance/' +
                  model.get('complianceId') +
                  '/sanction/' +
                  model.get('complianceSanctionId') +
                  '/edit'
                );
              },
              labelClass: 'fa fa-edit',
              buttonClass: 'btn-sm btn-primary edit-sanction'
            },
            {
              name: '',
              labelClass: 'fa fa-trash',
              buttonClass: 'btn-danger btn-sm delete-sanction',
              onClick: this.onClickDelete.bind(this)
            }
          ]
        }),
        editable: false,
        sortable: false
      }
    ];
    this.options.emptyText = 'No Sanctions';
  },

  onClickDelete: function(e, model) {
    e.preventDefault();
    UIUtil.showModal({
      title: 'Confirm Deleting Sanction',
      confirmText: 'Delete Sanction',
      view: new ConfirmationModalView({
        model: new Backbone.Model({
          message: 'Are you sure you want to delete this sanction?',
          onConfirm: function() {
            return SubmitComplianceSanction({
              complianceSanctionId: model.get('complianceSanctionId'),
              isDeleted: '1'
            });
          },
          onConfirmDone: function() {
            this.collection.remove(model);
          }.bind(this)
        })
      })
    });
  }
});
