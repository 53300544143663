var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "\r\n<div class=\"row share-info-row sub-form-vertical m-b-sm\">\r\n  <div class=\"col-sm-3\">\r\n    <div class=\"form-group required\">            \r\n      <input type=\"text\" class=\"form-control\" name=\"name\" placeholder=\"Name of each seller or transferor\" data-parsley-required=\"true\"\r\n        data-parsley-maxlength=\"100\" data-parsley-errors-container=\"#nameError"
    + alias4(((helper = (helper = helpers.childIndex || (depth0 != null ? depth0.childIndex : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"childIndex","hash":{},"data":data}) : helper)))
    + "\" />\r\n      <div id=\"nameError"
    + alias4(((helper = (helper = helpers.childIndex || (depth0 != null ? depth0.childIndex : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"childIndex","hash":{},"data":data}) : helper)))
    + "\"></div>\r\n    </div>\r\n  </div>\r\n  <div class=\"col-sm-2\">\r\n    <div class=\"form-group\">            \r\n      <input type=\"text\" class=\"form-control\" name=\"shareNowHold\" placeholder=\"\"\r\n        data-parsley-maxlength=\"100\" data-parsley-errors-container=\"#shareNowHoldError"
    + alias4(((helper = (helper = helpers.childIndex || (depth0 != null ? depth0.childIndex : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"childIndex","hash":{},"data":data}) : helper)))
    + "\" />\r\n      <div id=\"shareNowHoldError"
    + alias4(((helper = (helper = helpers.childIndex || (depth0 != null ? depth0.childIndex : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"childIndex","hash":{},"data":data}) : helper)))
    + "\"></div>\r\n    </div>\r\n  </div>\r\n  <div class=\"col-sm-3\">\r\n    <div class=\"form-group\">            \r\n      <input type=\"text\" class=\"form-control\" name=\"shareToBeSold\" placeholder=\"\"\r\n        data-parsley-maxlength=\"100\" data-parsley-errors-container=\"#shareToBeSoldError"
    + alias4(((helper = (helper = helpers.childIndex || (depth0 != null ? depth0.childIndex : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"childIndex","hash":{},"data":data}) : helper)))
    + "\" />\r\n      <div id=\"shareToBeSoldError"
    + alias4(((helper = (helper = helpers.childIndex || (depth0 != null ? depth0.childIndex : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"childIndex","hash":{},"data":data}) : helper)))
    + "\"></div>\r\n    </div>\r\n  </div>\r\n  <div class=\"col-sm-3\">\r\n    <div class=\"form-group\">            \r\n      <input type=\"text\" class=\"form-control\" name=\"shareAfterCompletion\" placeholder=\"\"\r\n        data-parsley-maxlength=\"100\" data-parsley-errors-container=\"#shareAfterCompletionError"
    + alias4(((helper = (helper = helpers.childIndex || (depth0 != null ? depth0.childIndex : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"childIndex","hash":{},"data":data}) : helper)))
    + "\" />\r\n      <div id=\"shareAfterCompletionErrorError"
    + alias4(((helper = (helper = helpers.childIndex || (depth0 != null ? depth0.childIndex : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"childIndex","hash":{},"data":data}) : helper)))
    + "\"></div>\r\n    </div>\r\n  </div>\r\n  <div class=\"col-sm-1 m-b-n remove-container\">\r\n    <button class=\"btn btn-danger btn-sm remove-share-info\">\r\n      <i class=\"fa fa-times\" aria-hidden=\"true\"></i>\r\n    </button>\r\n  </div>\r\n</div>";
},"useData":true});