/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(formData) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      SubmitEntityOfficer: {
        $xmlns: 'http://dobs.pa.gov/dp/officer/bpm/1.0',
        Officer: {
          OfficerID: formData.officerId,
          EntityID: formData.entityId,
          OfficerTypeID: formData.officerTypeId,
          FirstName: formData.firstName,
          LastName: formData.lastName,
          MiddleInitial: formData.middleInitial,
          Title: formData.title,
          StatusID: formData.statusId,
          TermLength: formData.termLength,
          TermStartDate: formData.termStartDate,
          TermEndDate: formData.termEndDate,
          IsMainContact: formData.isMainContact,
          Comment: formData.comment,
          IsDeleted: formData.isDeleted
        }
      }
    },
    blueprint: {
      officerId: '$..OfficerID'
    }
  })
    .done(function(response) {
      cleanUpResponse(response, { cleanUpTextKeys: true });
      deferred.resolve(response.officerId);
    })
    .fail(function(err) {
      deferred.reject(err);
    });

  return deferred.promise();
};
