/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var NProgress = require('nprogress');

var NavUtils = require('psa-core/util/nav');

var Scaffold = require('common/layout/Scaffold');

var GetIndividual = require('../services/GetIndividual');
var GetAllLicenseAddressLookups = require('../../common/services/GetAllLicenseAddressLookups');
var SecIndividualAddressEditView = require('../views/SecIndividualAddressEditView');

module.exports = function(individualId) {
  NProgress.start();
  NProgress.set(0.6);
  $.when(GetIndividual(individualId), GetAllLicenseAddressLookups())
    .done(function(individual, lookups) {
      NavUtils.navigate('#sec/individual/' + individualId + '/new-address');
      Scaffold.content.show(
        new SecIndividualAddressEditView({
          model: new Backbone.Model({
            individualId: individualId,
            individual: individual,
            addressType: lookups.addressType
          })
        })
      );
      NProgress.done();
    })
    .fail(function() {
      NProgress.done();
    });
};
