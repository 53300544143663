/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');

var AppCardView = require('./AppCardView');
var EmptySetView = require('../EmptySetView');

module.exports = Marionette.CollectionView.extend({
  childView: AppCardView,

  emptyView: EmptySetView,

  className: 'app-card-collection-view',

  emptyViewOptions: function() {
    return {
      emptyText:
        'You do not have permission to access portals for any DoBS programs. Please contact your administrator.'
    };
  }
});
