/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Ladda = require('ladda');
var Radio = require('backbone.radio');

var modelGet = require('common/util/modelGet');
var BreadcrumbView = require('common/views/BreadcrumbView');

var DepEnforcementDetailsFormView = require('./DepEnforcementDetailsFormView');

var SubmitEnforcement = require('../services/SubmitEnforcement');

var tmplDepEnforcementNewView = require('../templates/DepEnforcementNewView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepEnforcementNewView,

  className: 'dep-enforcement-new-view container-fluid',

  ui: {
    enforcementDetailsForm: '.enforcement-details-form-container',
    breadcrumb: '.breadcrumb-container',
    submit: '.btn-submit'
  },

  regions: {
    enforcementDetailsForm: '@ui.enforcementDetailsForm',
    breadcrumb: '@ui.breadcrumb'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeShow: function() {
    var entityId = this.model.get('entityId');
    var entityName = modelGet(this.model, 'entityIdentifiers.entityName');

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#dep/dashboard',
            text: 'Home'
          },
          {
            link: '#dep/entity/' + entityId + '/dashboard',
            text: entityName
          },
          {
            text: 'New Enforcement Action'
          }
        ]
      })
    );

    this.enforcementDetailsForm = new DepEnforcementDetailsFormView({
      model: this.model
    });

    this.showChildView('enforcementDetailsForm', this.enforcementDetailsForm);
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    this.validate().done(
      function() {
        var laddaContext = Ladda.create(this.ui.submit.get(0));
        laddaContext.start();
        var formData = this.getFormData();
        SubmitEnforcement(formData)
          .done(function(enforcementId) {
            Radio.channel('navigate').trigger('show:int:dp:enforcement', formData.entityId, enforcementId);
          })
          .fail(function() {
            laddaContext.stop();
          });
      }.bind(this)
    );
  },

  validate: function() {
    return this.ui.enforcementDetailsForm.parsley().whenValidate();
  },

  getFormData: function() {
    return this.enforcementDetailsForm.getFormData();
  }
});
