/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

var cleanUpResponse = require('../util/cleanUpResponse');

module.exports = function(role) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetUsersForRole: {
        $xmlns: 'http://dobs.pa.gov/cm/user/bpm/1.0',
        Role: role
      }
    },
    blueprintArrays: ['GetUsersForRoleResponse.GetUsersForRoleResponse.tuple'],
    blueprint: {
      users: [
        '$..tuple',
        {
          userId: '$..cn.string',
          name: '$..description.string'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      var usersSorted = _.sortBy(response.users, 'name');
      deferred.resolve(usersSorted);
    })
    .fail(function(err) {
      deferred.reject(err);
    });
  return deferred.promise();
};
