/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var NProgress = require('nprogress');

var NavUtils = require('psa-core/util/nav');

var Scaffold = require('common/layout/Scaffold');

var GetOrganizationIdentifiers = require('../../common/services/GetOrganizationIdentifiers');
var GetEntityExternalUser = require('../services/GetEntityExternalUser');
var SecEntityManageUserView = require('../views/SecEntityManageUserView');

module.exports = function(organizationId, userId) {
  NProgress.start();
  NProgress.set(0.6);

  $.when(GetEntityExternalUser(userId), GetOrganizationIdentifiers(organizationId))
    .done(function(externalUser, organizationIdentifier) {
      NavUtils.navigate('sec/organization/' + organizationId + '/user/' + userId);
      Scaffold.content.show(
        new SecEntityManageUserView({
          model: new Backbone.Model({
            userId: userId,
            organizationId: organizationId,
            organizationIdentifier: organizationIdentifier,
            externalUser: externalUser
          })
        })
      );
      NProgress.done();
    })
    .fail(function(err) {
      NProgress.done();
    });
};
