/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(filingId) {
  var deferred = $.Deferred();
  $.soap({
    body: {
      GetBankMergerAcqFiling: {
        $xmlns: 'http://dobs.pa.gov/dp/filing/bpm/1.0',
        FilingID: filingId
      }
    },
    blueprintArrays: ['GetBankMergerAcqFilingResponse.SupportingDocuments.DP_FilingSupportingDocument'],
    blueprint: {
      bankMergerAcqFilingId: '$..ID',
      filingId: '$..FilingID',
      entityId: '$..EntityID',
      acqMergerFilingTypeId: '$..AcqMergerFilingTypeID',
      filedPursuantToOther: '$..FiledPursuantToOther',
      targetTypeId: '$..TargetTypeID',
      targetHCFedReserveBankId: '$..TargetHCFedReserveBankID',
      targetHCFedReserveBankOther: '$..TargetHCFedReserveBankOther',
      targetName: '$..TargetName',
      targetHCName: '$..TargetHCName',
      targetBusiness: '$..TargetBusiness',
      acquiringfrbDistrict:  '$..AcquiringfrbDistrict',
      targetHCfrbDistrict: '$..TargetHCfrbDistrict',
      isApplicable: '$..IsApplicable',
      acquiringAddress: {
        targetAddressId: '$..AcquiringAddressID',
        addressLine1: '$..AcquiringAddressLine1',
        addressLine2: '$..AcquiringAddressLine2',
        addressLine3: '$..AcquiringAddressLine3',
        city: '$..AcquiringCity',
        country: '$..AcquiringCountry',
        county: '$..AcquiringCounty',
        postalCode: '$..AcquiringPostalCode',
        state: '$..AcquiringState'
      },
      targetHCAddress: {
        targetAddressId: '$..TargetHCAddressID',
        addressLine1: '$..TargetAddressLine1',
        addressLine2: '$..TargetAddressLine2',
        addressLine3: '$..TargetAddressLine3',
        city: '$..TargetCity',
        country: '$..TargetCountry',
        county: '$..TargetCounty',
        postalCode: '$..TargetPostalCode',
        state: '$..TargetState'
      },
      targetAddress: {
        targetAddressId: '$..TargetAddressID',
        addressLine1: '$..AddressLine1',
        addressLine2: '$..AddressLine2',
        addressLine3: '$..AddressLine3',
        city: '$..City',
        country: '$..Country',
        county: '$..County',
        postalCode: '$..PostalCode',
        state: '$..State'
      },
      targetPhone: '$..TargetPhone',
      targetEmail: '$..TargetEmail',
      charterStateCharterId: '$..CharterStateCharterID',
      charterCharterTypeId: '$..CharterCharterTypeID',
      charterState: '$..CharterState',
      charterIsTrustPowers: '$..CharterIsTrustPowers',
      ownershipStructureId: '$..OwnershipStructureID',
      primaryRegulatorId: '$..PrimaryRegulatorID',
      companyName: '$..CompanyName',
      insuranceActivity: '$..InsuranceActivity',
      acquiringName: '$..AcquiringName',
      linesOfBusiness: '$..LinesOfBusiness',
      proposedTransaction: '$..ProposedTransaction',
      managementRelationships: '$..ManagementRelationships',
      additionalComments: '$..AdditionalComments',

      publicationReqs: {
        file: {
          fileId: '$..PublicationReqsDocID',
          fileName: '$..PublicationReqsDocName'
        },
        comments: '$..PublicationReqsComments'
      },
      nameTypeId: '$..NameTypeID',
      proposedName: '$..ProposedName',
      proposedNameComments: '$..ProposedNameComments',
      interimName: '$..InterimName',
      interimNameComments: '$..InterimNameComments',
      isUploadingFedApp: '$..IsUploadingFedApp',
      federalApplications: {
        file: {
          fileId: '$..FederalApplicationDocID',
          fileName: '$..FederalApplicationDocName'
        },
        comments: '$..FederalApplicationComments'
      },
      articlesOfMerger: {
        file: {
          fileId: '$..ArticlesOfMergerDocID',
          fileName: '$..ArticlesOfMergerDocName'
        },
        comments: '$..ArticlesOfMergerComments'
      },
      planOfMerger: {
        file: {
          fileId: '$..PlanOfMergerDocID',
          fileName: '$..PlanOfMergerDocName'
        },
        comments: '$..PlanOfMergerComments'
      },
      meetingNotice: {
        file: {
          fileId: '$..MeetingNoticeDocID',
          fileName: '$..MeetingNoticeDocName'
        },
        comments: '$..MeetingNoticeComments'
      },
      nameReservationcomments: '$..NameReservationComments',
      branchDocumentation: {
        file: {
          fileId: '$..BranchDocumentationDocID',
          fileName: '$..BranchDocumentationDocName'
        },
        comments: '$..BranchDocumentationComments'
      },
      subsidiaryAffiliates: {
        file: {
          fileId: '$..SubsidiaryDocID',
          fileName: '$..SubsidiaryDocName'
        },
        comments: '$..SubsidiaryComments'
      },
      questionnaire: {
        file: {
          fileId: '$..QuestionnaireDocID',
          fileName: '$..QuestionnaireDocName'
        },
        comments: '$..QuestionnaireComments'
      },
      isTrustMerger: '$..IsTrustMerger',
      isSavingsBankMerger: '$..IsSavingsBankMerger',
      isHoldingCompany: '$..IsHoldingCompany',
      transactionPurpose: '$..TransactionPurpose',
      mergerIssues: '$..MergerIssues',
      nonconformingActivities: '$..NonconformingActivities',
      officalInformation: '$..OfficalInformation',
      communityConvenience: '$..CommunityConvenience',

      proposedNameDocument: {
        fileId: '$..ProposedNameDocumentID',
        fileName: '$..ProposedNameDocName'
      },
      interimNameDocument: {
        fileId: '$..InterimNameDocumentID',
        fileName: '$..InterimNameDocName'
      },
      balanceSheetDocument: {
        fileId: '$..BalanceSheetDocID',
        fileName: '$..BalanceSheetDocName'
      },
      incomeStatementDocument: {
        fileId: '$..IncomeStatementDocID',
        fileName: '$..IncomeStatementDocName'
      },
      capitalScheduleDocument: {
        fileId: '$..CapitalScheduleDocID',
        fileName: '$..CapitalScheduleDocName'
      },
      supportingDocuments: [
        '$..DP_FilingSupportingDocument',
        { file: { fileId: '$.DocumentID', fileName: '$.DocumentName' } }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      var checkboxes = ['isTrustMerger', 'isSavingsBankMerger'];
      _.each(checkboxes, function(attr) {
        response[attr] = response[attr] === '1';
      });

      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
