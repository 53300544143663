/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');

var PageableCollection = require('psa-backbone/models/PageableCollection');
var GridView = require('psa-marionette/views/GridView');

var ButtonLinkCell = require('common/backgrid/ButtonLinkCell');
var MomentCell = require('common/backgrid/MomentCell');

var NonDepInternalSidebarNavView = require('../../common/views/NonDepInternalSidebarNavView');

require('../styles/NonDepInternalExamsView.css');
var tmplNonDepInternalExamsView = require('../templates/NonDepInternalExamsView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepInternalExamsView,

  className: 'non-dep-internal-exams-view container-fluid',

  ui: {
    sideBar: '.side-bar-container',
    exams: '.exams-container'
  },

  regions: {
    sideBar: '@ui.sideBar',
    exams: '@ui.exams'
  },

  onBeforeShow: function() {
    this.showChildView('sideBar', new NonDepInternalSidebarNavView());

    var examsGridView = new GridView({
      columns: [
        {
          name: 'yearPeriod',
          label: 'Exam Year',
          cell: 'string',
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'startDate',
          label: 'Start Date',
          cell: MomentCell.MomentCell,
          formatter: MomentCell.MomentDateFormatter.extend({
            emptyValue: '--'
          }),
          sortValue: MomentCell.MomentSortValue,
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'endDate',
          label: 'End Date',
          cell: MomentCell.MomentCell,
          formatter: MomentCell.MomentDateFormatter.extend({
            emptyValue: '--'
          }),
          sortValue: MomentCell.MomentSortValue,
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: '',
          label: 'Exam Statistics',
          cell: ButtonLinkCell.extend({
            name: 'Open',
            buttonClass: 'btn-primary pull-left',
            href: function(model) {
              return '#nondep/dashboard/exams/' + model.get('id') + '/statistics';
            }
          }),
          headerCell: 'custom',
          width: 10,
          editable: false,
          sortable: false
        },
        {
          name: '',
          label: 'View Exams',
          cell: ButtonLinkCell.extend({
            name: 'Open',
            buttonClass: 'btn-primary pull-left',
            href: function(model) {
              return '#nondep/dashboard/exams/' + model.get('id');
            }
          }),
          headerCell: 'custom',
          width: 10,
          editable: false,
          sortable: false
        }
      ],
      collection: new PageableCollection(this.model.get('examPeriods'), { pageSize: 10 }),
      emptyText: 'No Exam Periods'
    });
    this.showChildView('exams', examsGridView);
    examsGridView.getViewGrid().sort('yearPeriod', 'descending');
  }
});
