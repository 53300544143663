/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(examId) {
  var defer = $.Deferred();

  $.soap({
    body: {
      GetExamSetupQuestionParameters: {
        $xmlns: 'http://dobs.pa.gov/nd/exam/bpm/1.0',
        ExamID: examId
      }
    },
    blueprint: {
      isOffsite: '$..IsOffsite',
      examSetupCompletionDateStr: '$..ExamSetupCompletionDate',
      examDateStr: '$..ExamDate',
      examCoverageStartDateStr: '$..ExamCoverageStartDate',
      examCoverageEndDateStr: '$..ExamCoverageEndDate',
      loanLogDueDate: '$..LoanLogDueDate',
      examMaterialsDueDate: '$..ExamMaterialsDueDate'
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      response.isOffsite = response.isOffsite === '1';

      var parameters = {
        notifDueDate: null, // loan log due date
        dueDate: null, // exam materials due date
        periodFrom: null,
        periodTo: null
      };

      if (response.loanLogDueDate) {
        parameters.notifDueDate = moment(response.loanLogDueDate, 'YYYY-MM-DD').format('MM/DD/YYYY');
      }

      if (response.examMaterialsDueDate) {
        parameters.dueDate = moment(response.examMaterialsDueDate, 'YYYY-MM-DD').format('MM/DD/YYYY');
      }

      if (response.examCoverageStartDateStr) {
        var examCoverageStartMoment = moment(response.examCoverageStartDateStr, moment.ISO_8601);
        parameters.periodFrom = examCoverageStartMoment.format('MM/DD/YYYY');
      }
      if (response.examCoverageEndDateStr) {
        var examCoverageEndMoment = moment(response.examCoverageEndDateStr, moment.ISO_8601);
        parameters.periodTo = examCoverageEndMoment.format('MM/DD/YYYY');
      }

      defer.resolve(parameters);
    })
    .fail(function(err) {
      defer.reject();
    });

  return defer.promise();
};
