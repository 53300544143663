/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');

var PageableCollection = require('psa-backbone/models/PageableCollection');
var GridView = require('psa-marionette/views/GridView');

var userHasRole = require('common/util/userHasRole');
var BreadcrumbView = require('common/views/BreadcrumbView');

var NonDepInternalSidebarNavView = require('../../common/views/NonDepInternalSidebarNavView');

require('../styles/NonDepInternalExamStatisticsView.css');
var tmplNonDepInternalExamStatisticsView = require('../templates/NonDepInternalExamStatisticsView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepInternalExamStatisticsView,

  className: 'non-dep-internal-exam-statistics-view container-fluid',

  ui: {
    sideBar: '.side-bar-container',
    exams: '.exams-container',
    breadcrumb: '.breadcrumb-container'
  },

  regions: {
    sideBar: '@ui.sideBar',
    exams: '@ui.exams',
    breadcrumb: '@ui.breadcrumb'
  },

  templateHelpers: function() {
    var showIssueExamsBtn = false;
    var showAssignExaminersBtn = false;
    if (userHasRole('ND - Exam Director')) {
      showIssueExamsBtn = true;
    }
    if (
      userHasRole('ND - Regional Director West') ||
      userHasRole('ND - Regional Director East') ||
      userHasRole('ND - Regional Director Harrisburg')
    ) {
      showAssignExaminersBtn = true;
    }
    return {
      showAssignExaminersBtn: showAssignExaminersBtn,
      showIssueExamsBtn: showIssueExamsBtn
    };
  },

  onBeforeShow: function() {
    this.showChildView('sideBar', new NonDepInternalSidebarNavView());

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#nondep/dashboard/exams',
            text: 'Exams'
          },
          {
            text: 'Exam Statistics'
          }
        ]
      })
    );

    this.showChildView(
      'exams',
      new GridView({
        columns: [
          {
            name: 'licenseTypeName',
            label: 'License Type',
            cell: 'string',
            headerCell: 'custom',
            width: 36,
            editable: false
          },
          {
            name: 'examInitiated',
            label: 'Exams Initiated',
            cell: 'string',
            headerCell: 'custom',
            width: 16,
            editable: false
          },
          {
            name: 'examScheduled',
            label: 'Exams Scheduled',
            cell: 'string',
            headerCell: 'custom',
            width: 16,
            editable: false
          },
          {
            name: 'examInProgress',
            label: 'Exams In-Progress',
            cell: 'string',
            headerCell: 'custom',
            width: 16,
            editable: false
          },
          {
            name: 'examCompleted',
            label: 'Exams Completed',
            cell: 'string',
            headerCell: 'custom',
            width: 16,
            editable: false
          }
        ],
        collection: new PageableCollection(this.model.get('examStat'), { pageSize: 10 }),
        emptyText: 'No Exam Statistics to Show'
      })
    );
  }
});
