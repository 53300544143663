/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Ladda = require('ladda');
var moment = require('moment');

var EmptyView = require('common/views/EmptyView');
var FileUploadView = require('common/views/FileUploadView');
var SpinnerView = require('common/views/SpinnerView');
var BreadcrumbView = require('common/views/BreadcrumbView');
var modelGet = require('common/util/modelGet');
var makeUploadCollection = require('common/util/makeUploadCollection');
var GenerateAdHocDocument = require('../../common/services/GenerateAdHocDocument');
var LicenseHeaderBarView = require('../../common/views/LicenseHeaderBarView');

var NonDepLicenseDocGen15DayAdminActionView = require('./NonDepLicenseDocGen15DayAdminActionView');
var NonDepLicenseDocGenIRTRView = require('./NonDepLicenseDocGenIRTRView');
var NonDepLicenseDocGenRTRView = require('./NonDepLicenseDocGenRTRView');
var NonDepLicenseDocGenTentSaleView = require('./NonDepLicenseDocGenTentSaleView');

require('../styles/NonDepLicenseDocGenView.css');
var tmplNonDepLicenseDocGenView = require('../templates/NonDepLicenseDocGenView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepLicenseDocGenView,

  className: 'non-dep-license-doc-gen-view container-fluid',

  ui: {
    headerBarContainer: '.header-bar-container',
    breadcrumb: '.breadcrumb-container',
    parametersContainer: '.parameters-container',
    documentTemplateName: '.document-template-name',
    submit: '.submit',
    docGenForm: '.doc-gen-form',
    documentContainer: '.document-container',
    downloadContainer: '.download-container'
  },

  regions: {
    headerBarContainer: '@ui.headerBarContainer',
    breadcrumb: '@ui.breadcrumb',
    parametersContainer: '@ui.parametersContainer',
    documentContainer: '@ui.documentContainer',
    downloadContainer: '@ui.downloadContainer'
  },

  templateHelpers: function() {
    var licenseTypeId = this.model.get('licenseIdentifier').licenseTypeId;
    return {
      showRTR: !_.contains(['6', '33', '34'], licenseTypeId)
    };
  },

  events: {
    'change @ui.documentTemplateName': 'onChangeDocumentTemplate',
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeShow: function() {
    var entityId = this.model.get('entityId');
    var entityName = modelGet(this.model, 'licenseIdentifier.entityName');
    var licenseNumber = modelGet(this.model, 'licenseIdentifier.licenseNumber');
    var licenseType = modelGet(this.model, 'licenseIdentifier.licenseType');
    var licenseId = modelGet(this.model, 'licenseIdentifier.id');

    this.showChildView(
      'headerBarContainer',
      new LicenseHeaderBarView({
        model: new Backbone.Model(this.model.get('licenseIdentifier'))
      })
    );

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            text: 'Home',
            link: '#nondep/dashboard'
          },
          {
            text: entityName,
            link: '#nondep/entity/' + entityId + '/dashboard'
          },
          {
            text: 'License #' + licenseNumber + ' - ' + licenseType,
            link: '#nondep/entity/' + entityId + '/license/' + licenseId
          },
          {
            text: 'Generate Document'
          }
        ]
      })
    );
  },

  onChangeDocumentTemplate: function() {
    var templateName = this.ui.documentTemplateName.val();
    if (templateName === 'ND_15_DAY_ADMINISTRATIVE_ACTION_TEMPLATE') {
      this.showChildView('parametersContainer', new NonDepLicenseDocGen15DayAdminActionView());
    } else if (templateName === 'IRTR_TEMPLATE') {
      this.showChildView('parametersContainer', new NonDepLicenseDocGenIRTRView());
    } else if (templateName === 'RTR_TEMPLATE') {
      this.showChildView('parametersContainer', new NonDepLicenseDocGenRTRView());
    } else if (templateName === 'ND_TENT_SALE_TEMPLATE') {
      this.showChildView('parametersContainer', new NonDepLicenseDocGenTentSaleView());
    } else if (
      templateName === 'ND_MORTGAGE_ORIGINATOR_INACTIVE_TEMPLATE' ||
      templateName === 'ND_15_DAY_UNLICENSED_LOCATION_SPONSORSHIP_TEMPLATE' ||
      templateName === 'ND_CERTIFICATE_TEMPLATE' ||
      templateName === ''
    ) {
      this.showChildView('parametersContainer', new EmptyView());
    }
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;
    var licenseTypeId = modelGet(this.model, 'licenseIdentifier.licenseTypeId');

    var licenseTypeIdRTR = {
      '1': 'ND_CHECK_CASHIER_RTR_TEMPLATE',
      '2': 'ND_CHECK_CASHIER_RTR_TEMPLATE',
      '3': 'ND_MOTOR_VEHICLE_RTR_TEMPLATE',
      '4': 'ND_CONSUMER_DISCOUNT_RTR_TEMPLATE',
      '5': 'ND_CONSUMER_DISCOUNT_RTR_TEMPLATE',
      '7': 'ND_DEBT_MANAGEMENT_RTR_TEMPLATE',
      '8': 'ND_DEBT_MANAGEMENT_RTR_TEMPLATE',
      '9': 'ND_DEBT_MANAGEMENT_RTR_TEMPLATE',
      '10': 'ND_DEBT_MANAGEMENT_RTR_TEMPLATE',
      '11': 'ND_DEBT_SETTLEMENT_RTR_TEMPLATE',
      '12': 'ND_DEBT_SETTLEMENT_RTR_TEMPLATE',
      '13': 'ND_DEBT_SETTLEMENT_RTR_TEMPLATE',
      '14': 'ND_DEBT_SETTLEMENT_RTR_TEMPLATE',
      '15': 'ND_MOTOR_VEHICLE_RTR_TEMPLATE',
      '16': 'ND_MONEY_TRANSMITTER_RTR_TEMPLATE',
      '17': 'ND_MORTGAGE_LICENSING_RTR_TEMPLATE',
      '18': 'ND_MORTGAGE_LICENSING_RTR_TEMPLATE',
      '19': 'ND_MORTGAGE_DISCOUNT_RTR_TEMPLATE',
      '20': 'ND_MORTGAGE_DISCOUNT_RTR_TEMPLATE',
      '21': 'ND_MORTGAGE_LICENSING_RTR_TEMPLATE',
      '22': 'ND_MORTGAGE_LICENSING_RTR_TEMPLATE',
      '23': 'ND_MORTGAGE_LICENSING_RTR_TEMPLATE',
      '24': 'ND_MORTGAGE_LICENSING_RTR_TEMPLATE',
      '25': 'ND_MORTGAGE_ORIGINATOR_RTR_TEMPLATE',
      '26': 'ND_MORTGAGE_LICENSING_RTR_TEMPLATE',
      '27': 'ND_MORTGAGE_LICENSING_RTR_TEMPLATE',
      '28': 'ND_PAWNBROKER_RTR_TEMPLATE',
      '29': 'ND_MORTGAGE_LICENSING_RTR_TEMPLATE',
      '30': 'ND_MORTGAGE_LICENSING_RTR_TEMPLATE',
      '31': 'ND_MORTGAGE_LICENSING_RTR_TEMPLATE',
      '32': 'ND_MORTGAGE_LICENSING_RTR_TEMPLATE',
      '35': 'ND_MOTOR_VEHICLE_RTR_TEMPLATE',
      '36': 'ND_MORTGAGE_ORIGINATOR_RTR_TEMPLATE',
      '37': 'ND_PAWNBROKER_RTR_TEMPLATE'
    };

    var licenseTypeIdIRTR = {
      '1': 'ND_CHECK_CASHIER_IRTR_TEMPLATE',
      '2': 'ND_CHECK_CASHIER_IRTR_TEMPLATE',
      '3': 'ND_MOTOR_VEHICLE_IRTR_TEMPLATE',
      '4': 'ND_CONSUMER_DISCOUNT_IRTR_TEMPLATE',
      '5': 'ND_CONSUMER_DISCOUNT_IRTR_TEMPLATE',
      '7': 'ND_DEBT_MANAGEMENT_IRTR_TEMPLATE',
      '8': 'ND_DEBT_MANAGEMENT_IRTR_TEMPLATE',
      '9': 'ND_DEBT_MANAGEMENT_IRTR_TEMPLATE',
      '10': 'ND_DEBT_MANAGEMENT_IRTR_TEMPLATE',
      '11': 'ND_DEBT_SETTLEMENT_IRTR_TEMPLATE',
      '12': 'ND_DEBT_SETTLEMENT_IRTR_TEMPLATE',
      '13': 'ND_DEBT_SETTLEMENT_IRTR_TEMPLATE',
      '14': 'ND_DEBT_SETTLEMENT_IRTR_TEMPLATE',
      '15': 'ND_MOTOR_VEHICLE_IRTR_TEMPLATE',
      '16': 'ND_MONEY_TRANSMITTER_IRTR_TEMPLATE',
      '17': 'ND_MORTGAGE_LICENSING_IRTR_TEMPLATE',
      '18': 'ND_MORTGAGE_LICENSING_IRTR_TEMPLATE',
      '19': 'ND_MORTGAGE_DISCOUNT_IRTR_TEMPLATE',
      '20': 'ND_MORTGAGE_DISCOUNT_IRTR_TEMPLATE',
      '21': 'ND_MORTGAGE_LICENSING_IRTR_TEMPLATE',
      '22': 'ND_MORTGAGE_LICENSING_IRTR_TEMPLATE',
      '23': 'ND_MORTGAGE_LICENSING_IRTR_TEMPLATE',
      '24': 'ND_MORTGAGE_LICENSING_IRTR_TEMPLATE',
      '25': 'ND_MORTGAGE_ORIGINATOR_IRTR_TEMPLATE',
      '26': 'ND_MORTGAGE_LICENSING_IRTR_TEMPLATE',
      '27': 'ND_MORTGAGE_LICENSING_IRTR_TEMPLATE',
      '28': 'ND_PAWNBROKER_IRTR_TEMPLATE',
      '29': 'ND_MORTGAGE_LICENSING_IRTR_TEMPLATE',
      '30': 'ND_MORTGAGE_LICENSING_IRTR_TEMPLATE',
      '31': 'ND_MORTGAGE_LICENSING_IRTR_TEMPLATE',
      '32': 'ND_MORTGAGE_LICENSING_IRTR_TEMPLATE',
      '35': 'ND_MOTOR_VEHICLE_IRTR_TEMPLATE',
      '36': 'ND_MORTGAGE_ORIGINATOR_IRTR_TEMPLATE',
      '37': 'ND_PAWNBROKER_IRTR_TEMPLATE'
    };

    this.validate().done(function() {
      var formData = self.getFormData();
      formData.licenseId = modelGet(self.model, 'licenseIdentifier.id');
      var templateName = formData.documentTemplateName;
      if (templateName === 'RTR_TEMPLATE') {
        formData.documentTemplateName = licenseTypeIdRTR[licenseTypeId];
      } else if (templateName === 'IRTR_TEMPLATE') {
        formData.documentTemplateName = licenseTypeIdIRTR[licenseTypeId];
      }

      var l = Ladda.create(self.ui.submit[0]);
      l.start();

      // Show label for downloaded doc
      self.ui.downloadContainer.show();

      // Show spinner where document will appear
      self.showChildView(
        'documentContainer',
        new SpinnerView({
          spinner: {
            scale: '0.5'
          }
        })
      );

      GenerateAdHocDocument(formData)
        .done(function(file) {
          self.showChildView(
            'documentContainer',
            new FileUploadView({
              isReadOnly: true,
              collection: makeUploadCollection(file)
            })
          );
          l.stop();
        })
        .fail(function() {
          l.stop();
          self.showChildView('documentContainer', new EmptyView());
        });
    });
  },

  validate: function() {
    return this.ui.docGenForm.parsley().whenValidate();
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);

    if (formData.tentSaleDate) {
      formData.tentSaleDate = moment(formData.tentSaleDate, 'MM/DD/YYYY').format('YYYY-MM-DD');
    }

    if (formData.advisoryLetterDate) {
      formData.advisoryLetterDate = moment(formData.advisoryLetterDate, 'MM/DD/YYYY').format('YYYY-MM-DD');
    }
    return formData;
  }
});
