var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "<div class=\"page-title clearfix\">\r\n  <h1 class=\"pull-left m-n p-n\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.task : depth0)) != null ? stack1.activity : stack1), depth0))
    + "</h1>\r\n</div>\r\n<div class=\"breadcrumb-container\"></div>\r\n<div class=\"col-sm-8 col-sm-offset-2 notification-container\">\r\n  <div class=\"m-b-lg\">\r\n    <b>The License Number for Entity <br>\r\n     "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.application : depth0)) != null ? stack1.entityName : stack1), depth0))
    + "  \r\n      for the <br>\r\n      "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.application : depth0)) != null ? stack1.licenseType : stack1), depth0))
    + " license is #"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.license : depth0)) != null ? stack1.licenseNumber : stack1), depth0))
    + "\r\n    </b>\r\n  </div>\r\n  <div class=\"document-container\"></div>\r\n  <button class=\"btn btn-primary submit ladda-button\" data-style=\"expand-right\" type=\"button\">\r\n    <span class=\"ladda-label\">Acknowledge</span>\r\n  </button>\r\n</div>";
},"useData":true});