/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');

var PlaceholderView = require('common/views/PlaceholderView');

var DepEntityExamSectionView = require('../views/DepEntityExamSectionView');

var GetEntityExamsByEntity = require('../services/GetEntityExamsByEntity');
var GetEntityHCExamsByEntity = require('../services/GetEntityHCExamsByEntity');

module.exports = {
  channel: 'dp',

  event: 'build:exam-section-view',

  builder: function(entityId, entityTypeId) {
    return new PlaceholderView({
      viewPromise: buildEntityExamView(entityId, entityTypeId)
    });
  }
};

function buildEntityExamView(entityId, entityTypeId) {
  var deferred = $.Deferred();

  var examServicePromise;
  if (entityTypeId === '2') {
    examServicePromise = $.when(GetEntityExamsByEntity(entityId), GetEntityHCExamsByEntity(entityId));
  } else {
    examServicePromise = GetEntityExamsByEntity(entityId);
  }

  examServicePromise
    .done(function(exams, bhcExams) {
      deferred.resolve(
        new DepEntityExamSectionView({
          entityTypeId: entityTypeId,
          model: new Backbone.Model({
            entityId: entityId,
            exams: exams,
            bhcExams: bhcExams
          })
        })
      );
    })
    .fail(function(err) {
      deferred.reject(err);
    });
  return deferred.promise();
}
