/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var tmplCorpFinExemSecuritiesDescriptionFormView = require('../templates/CorpFinExemSecuritiesDescriptionFormView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplCorpFinExemSecuritiesDescriptionFormView,

  className: 'corp-fin-exem-securities-description-form-view row',

  onBeforeShow: function() {
    var record = this.model.get('record') || {};

    Syphon.deserialize(this, record);
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    formData.exemptionId = this.model.get('recordId');

    return formData;
  }
});
