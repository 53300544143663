/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');
var FileUploadCollection = require('psa-backbone/models/FileUploadCollection');

var UIUtil = require('psa-core/util/ui');

var modelGet = require('common/util/modelGet');

var ConfirmationModalView = require('common/views/ConfirmationModalView');
var BreadcrumbView = require('common/views/BreadcrumbView');
var FileUploadView = require('common/views/FileUploadView');

var SubmitAcknowledgeNotification = require('../../services/SubmitAcknowledgeNotification');

var tmplDepEntityViewNotificationTaskView = require('./DepEntityViewNotificationTaskView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepEntityViewNotificationTaskView,

  className: 'entity-view-notification-task-view container-fluid',

  ui: {
    breadcrumb: '.breadcrumb-container',
    documentContainer: '.document-container',
    submit: '.submit',
    notificationContainer: '.notification-container'
  },

  regions: {
    breadcrumb: '@ui.breadcrumb',
    documentContainer: '@ui.documentContainer'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeRender: function() {},

  onBeforeShow: function() {
    var entityId = this.model.get('entityId');
    var entityName = modelGet(this.model, 'entityIdentifiers.entityName');
    var taskDescription = modelGet(this.model, 'task.taskType.description');
    var documents = modelGet(this.model, 'notification.documents');
    _.each(documents, function(document) {
      document = _.extend(document, document.file);
    });

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#ext/dep/dashboard',
            text: 'Home'
          },
          {
            link: '#ext/dep/entity/' + entityId + '/dashboard',
            text: entityName
          },
          {
            text: taskDescription
          }
        ]
      })
    );

    if (documents.length) {
      this.fileUploadTable = new FileUploadView({
        isReadOnly: true,
        collection: new FileUploadCollection(documents),
        emptyText: 'No Related Documents'
      });
      this.showChildView('documentContainer', this.fileUploadTable);
    }
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var taskId = this.model.get('taskId');
    var notificationId = this.model.get('notificationId');
    var filingId = modelGet(this.model, 'task.taskData.DP.dpFilingId');
    this.validate().done(function() {
      UIUtil.showModal({
        title: 'Submit Notification',
        view: new ConfirmationModalView({
          confirmText: 'Submit',
          model: new Backbone.Model({
            message:
              'Do not acknowledge and submit until you have downloaded and saved the attachments. Once this notification is submitted, access to the notification and its attachments will be revoked.',
            onConfirm: function() {
              return SubmitAcknowledgeNotification(taskId, notificationId, filingId);
            },
            onConfirmDone: function() {
              Radio.channel('navigate').trigger('show:ext:dp:dashboard');
            }
          })
        })
      });
    });
  },

  validate: function() {
    return this.ui.notificationContainer.parsley().whenValidate();
  }
});
