/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

module.exports = function(entityTypeId) {
  if (entityTypeId === '1') {
    return {
      idCharterNumber: true,
      isComplex: true,
      subsidaryBankSection: true,
      stateRegulator: true,
      ffSection: true,
      lid: true,
      mbl: true,
      isCollectiveInvestmentFund: true,
      FOMType: true,
      mainPhoneNumberSection: true,
      frsrssd: true,
      occcharter: true,
      fdicuninum: true,
      itEnforcements: true
    };
  } else if (entityTypeId === '2') {
    return {
      certificationNumber: true,
      idCharterNumber: true,
      isCollectiveInvestmentFund: true,
      stateRegulator: true,
      ffSection: true,
      lid: true,
      mbl: true,
      fhlb: true,
      FOMType: true,
      mainPhoneNumberSection: true,
      frsrssd: true,
      occcharter: true,
      fdicuninum: true,
      itEnforcements: true
    };
  } else if (entityTypeId === '3'|| entityTypeId === '10') {
    return {
      ownershipStructure: true,
      corporateStructure: true,
      RSSDId: true,
      certificationNumber: true,
      isComplex: true,
      isCollectiveInvestmentFund: true,
      totalAssets: true,
      regulatorSection: true,
      holdingCompanySection: true,
      subsidaryBankSection: true,
      useCorpAddress: true,
      mailingAddress: true,
      website: true,
      ffSection: true,
      lid: true,
      mbl: true,
      fhlb: true,
      FOMType: true,
      mainPhoneNumberSection: true,
      itEnforcements: true
    };
  } else if (entityTypeId === '4' || entityTypeId === '8' ) {
    return {
      idCharterNumber: true,
      isComplex: true,
      subsidaryBankSection: true,
      stateRegulator: true,
      ffSection: true,
      lid: true,
      mbl: true,
      isCollectiveInvestmentFund: true,
      FOMType: true,
      mainPhoneNumberSection: true,
      frsrssd: true,
      occcharter: true,
      fdicuninum: true,
      enforcementActionSection: true,
      examSection: true
    };
  } else if (entityTypeId === '5' || entityTypeId === '9') {
    return {
      dbas: true,
      ownershipStructure: true,
      corporateStructure: true,
      status: true,
      RSSDId: true,
      certificationNumber: true,
      idCharterNumber: true,
      isComplex: true,
      isCollectiveInvestmentFund: true,
      totalAssets: true,
      regulatorSection: true,
      holdingCompanySection: true,
      subsidaryBankSection: true,
      addressSection: true,
      ffSection: true,
      lid: true,
      mbl: true,
      fhlb: true,
      website: true,
      FOMType: true,
      mainPhoneNumberSection: true,
      frsrssd: true,
      occcharter: true,
      fdicuninum: true,
      enforcementActionSection: true,
      examSection: true
    };
  } else if (entityTypeId === '7') {
    return {
      certificationNumber: true,
      idCharterNumber: true,
      isComplex: true,
      isCollectiveInvestmentFund: true,
      totalAssets: true,
      holdingCompanySection: true,
      subsidaryBankSection: true,
      ffSection: true,
      lid: true,
      mbl: true,
      fhlb: true,
      website: true,
      FOMType: true,
      mainPhoneNumberSection: true,
      frsrssd: true,
      occcharter: true,
      fdicuninum: true,
      itEnforcements: true,
      enforcementActionSection: true,
      examSection: true
    };
  } else if (entityTypeId === '11') {
    return {
      dbas: true,
      ownershipStructure: true,
      corporateStructure: true,
      idCharterNumber: true,
      isCollectiveInvestmentFund: true,
      isComplex: true,
      regulatorSection: true,
      ffSection: true,
      RSSDId: true,
      holdingCompanySection: true,
      subsidaryBankSection: true,
      frsrssd: true,
      occcharter: true,
      fdicuninum: true,
      complianceAndCRAEnforcements: true,
      moecaRating: true
    };
  } else if (entityTypeId === '12') {
    return {
      dbas: true,
      charterType: true,
      ownershipStructure: true,
      corporateStructure: true,
      RSSDId: true,
      certificationNumber: true,
      isComplex: true,
      isCollectiveInvestmentFund: true,
      totalAssets: true,
      regulatorSection: true,
      holdingCompanySection: true,
      subsidaryBankSection: true,
      useCorpAddress: true,
      mailingAddress: true,
      website: true,
      ffSection: true,
      lid: true,
      mbl: true,
      fhlb: true,
      FOMType: true,
      frsrssd: true,
      occcharter: true,
      fdicuninum: true,
      complianceAndCRAEnforcements: true,
      moecaRating: true
    };
  } else if (entityTypeId === '13') {
    return {
      dbas: true,
      ownershipStructure: true,
      corporateStructure: true,
      RSSDId: true,
      idCharterNumber: true,
      isComplex: true,
      isCollectiveInvestmentFund: true,
      totalAssets: true,
      regulatorSection: true,
      ffSection: true,
      holdingCompanySection: true,
      lid: true,
      mbl: true,
      fhlb: true,
      FOMType: true,
      subsidaryBankSection: true,
      frsrssd: true,
      occcharter: true,
      fdicuninum: true,
      enforcementActionSection: true,
      examSection: true
    };
  } else if (entityTypeId === '14' || entityTypeId === '25') {
    return {
      dbas: true,
      ownershipStructure: true,
      corporateStructure: true,
      status: true,
      RSSDId: true,
      certificationNumber: true,
      idCharterNumber: true,
      isComplex: true,
      isCollectiveInvestmentFund: true,
      totalAssets: true,
      regulatorSection: true,
      holdingCompanySection: true,
      subsidaryBankSection: true,
      addressSection: true,
      ffSection: true,
      lid: true,
      mbl: true,
      fhlb: true,
      website: true,
      FOMType: true,
      frsrssd: true,
      occcharter: true,
      fdicuninum: true,
      enforcementActionSection: true,
      examSection: true
    };
  } else if (entityTypeId === '21' || entityTypeId === '22') {
    return {
      idCharterNumber: true,
      isComplex: true,
      ffSection: true,
      RSSDId: true,
      regulatorSection: true,
      lid: true,
      mbl: true,
      fhlb: true,
      subsidaryBankSection: true,
      FOMType: true,
      frsrssd: true,
      occcharter: true,
      fdicuninum: true,
      complianceAndCRAEnforcements: true,
      itEnforcements: true
    };
  } else if (entityTypeId === '23') {
    return {
      charterType: true,
      ownershipStructure: true,
      corporateStructure: true,
      RSSDId: true,
      certificationNumber: true,
      isComplex: true,
      isCollectiveInvestmentFund: true,
      totalAssets: true,
      regulatorSection: true,
      holdingCompanySection: true,
      subsidaryBankSection: true,
      ffSection: true,
      useCorpAddress: true,
      mailingAddress: true,
      lid: true,
      mbl: true,
      fhlb: true,
      FOMType: true,
      frsrssd: true,
      occcharter: true,
      fdicuninum: true,
      complianceAndCRAEnforcements: true,
      itEnforcements: true
    };
  } else if (entityTypeId === '24') {
    return {
      ownershipStructure: true,
      corporateStructure: false,
      certificationNumber: true,
      isComplex: true,
      idCharterNumber: true,
      isCollectiveInvestmentFund: true,
      totalAssets: true,
      holdingCompanySection: true,
      mailingAddress: true,
      RSSDId: true,
      regulatorSection: true,
      lid: true,
      mbl: true,
      fhlb: true,
      subsidaryBankSection: true,
      FOMType: true,
      frsrssd: true,
      occcharter: true,
      fdicuninum: true,
      enforcementActionSection: true,
      complianceAndCRAEnforcements: true,
      itEnforcements: true,
      examSection: true
    };
  }
};
