/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var moment = require('moment');

module.exports = function(formData) {
  var defer = $.Deferred();
  formData.finePaidAckLetterSentDate = formData.finePaidAckLetterSentDate
    ? moment(formData.finePaidAckLetterSentDate, 'MM/DD/YYYY').format('YYYY-MM-DD')
    : '';
  formData.finePaidInFullDate = formData.finePaidInFullDate
    ? moment(formData.finePaidInFullDate, 'MM/DD/YYYY').format('YYYY-MM-DD')
    : '';
  formData.firstPaymentDate = formData.firstPaymentDate
    ? moment(formData.firstPaymentDate, 'MM/DD/YYYY').format('YYYY-MM-DD')
    : '';
  formData.fullyExecutedDate = formData.fullyExecutedDate
    ? moment(formData.fullyExecutedDate, 'MM/DD/YYYY').format('YYYY-MM-DD')
    : '';
  formData.lastPaymentDate = formData.lastPaymentDate
    ? moment(formData.lastPaymentDate, 'MM/DD/YYYY').format('YYYY-MM-DD')
    : '';
  formData.negotiatedDate = formData.negotiatedDate
    ? moment(formData.negotiatedDate, 'MM/DD/YYYY').format('YYYY-MM-DD')
    : '';
  formData.sentToEntityDate = formData.sentToEntityDate
    ? moment(formData.sentToEntityDate, 'MM/DD/YYYY').format('YYYY-MM-DD')
    : '';
  formData.sentToLegalDate = formData.sentToLegalDate
    ? moment(formData.sentToLegalDate, 'MM/DD/YYYY').format('YYYY-MM-DD')
    : '';

  $.soap({
    body: {
      SubmitComplianceGeneralInformation: {
        $xmlns: 'http://dobs.pa.gov/nd/compliance/bpm/1.0',
        ComplianceID: formData.complianceId,
        ComplianceViolations: {
          ComplianceViolationID: formData.violationCodes
        },
        DocketNumber: formData.docketNumber,
        OrderTypeID: formData.orderTypeId,
        FineAmountOrdered: formData.fineAmountOrdered,
        FinePaidAckLetterSentDate: formData.finePaidAckLetterSentDate,
        FinePaidInFullDate: formData.finePaidInFullDate,
        FirstPaymentDate: formData.firstPaymentDate,
        FullyExecutedDate: formData.fullyExecutedDate,
        LastPaymentDate: formData.lastPaymentDate,
        NegotiatedDate: formData.negotiatedDate,
        SentToEntityDate: formData.sentToEntityDate,
        SentToLegalDate: formData.sentToLegalDate
      }
    }
  })
    .done(function() {
      defer.resolve();
    })
    .fail(function(err) {
      defer.reject(err);
    });

  return defer.promise();
};
