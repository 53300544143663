var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "\r\n\r\n<div class=\"page-title clearfix\">\r\n  <h1 class=\"pull-left m-n p-n\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.task : depth0)) != null ? stack1.taskType : stack1)) != null ? stack1.description : stack1), depth0))
    + " for "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.filingDetails : depth0)) != null ? stack1.filingType : stack1), depth0))
    + "</h1>\r\n  <div class=\"page-title-buttons-container\">\r\n    <a role=\"button\" class=\"btn btn-primary\"\r\n      href=\"#dep/entity/"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.filingDetails : depth0)) != null ? stack1.entityId : stack1), depth0))
    + "/filing/"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.filingDetails : depth0)) != null ? stack1.filingId : stack1), depth0))
    + "/dashboard\" target=\"_blank\">Open\r\n      Filing</a>\r\n  </div>\r\n</div>\r\n<div class=\"header-bar\"></div>\r\n<div class=\"breadcrumb-container\"></div>\r\n<div class=\"task-status\"></div>\r\n<div class=\"form-horizontal\">\r\n  <form class=\"review-withdrawal-form\">\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-3\">Status</label>\r\n      <div class=\"col-sm-9 col-md-7 form-control-static\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.filingDetails : depth0)) != null ? stack1.recordStatus : stack1), depth0))
    + "</div>\r\n    </div>\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-3\">Withdrawal Reason</label>\r\n      <div class=\"col-sm-9 col-md-7 form-control-static\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.filingDetails : depth0)) != null ? stack1.withdraw : stack1)) != null ? stack1.withdrawReasonExt : stack1), depth0))
    + "</div>\r\n    </div>\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-3\">Withdrawal Document</label>\r\n      <div class=\"col-sm-9 ext-withdrawal-letter-container\"></div>\r\n    </div>\r\n    <div class=\"form-group withdrawal-letter required\">\r\n      <label class=\"control-label col-sm-3\">Withdrawal Letter</label>\r\n      <div class=\"col-sm-9 withdrawal-letter-container\"></div>\r\n    </div>\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-3\">Comments</label>\r\n      <div class=\"col-sm-9 col-md-7\">\r\n        <textarea type=\"text\" name=\"withdrawalReason\" class=\"form-control withdrawal-reason\" placeholder=\"Comments\"\r\n          rows=\"5\"></textarea>\r\n      </div>\r\n    </div>\r\n  </form>\r\n  <div class=\"form-group\">\r\n    <div class=\"col-sm-9 col-lg-7 col-sm-offset-3\">\r\n      <a href=\"#dep/dashboard\" class=\"btn btn-default\" role=\"button\">Cancel</a>\r\n      <button type=\"button\" class=\"btn btn-primary submit\">Submit</button>\r\n      <div class=\"task-actions\"></div>\r\n    </div>\r\n  </div>\r\n</div>";
},"useData":true});