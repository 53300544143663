var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression;

  return "\r\n<div class=\"page-title clearfix\">\r\n  <h1 class=\"pull-left m-n p-n\">"
    + alias1(container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.task : depth0)) != null ? stack1.taskType : stack1)) != null ? stack1.description : stack1), depth0))
    + "</h1>\r\n</div>\r\n<div class=\"header-bar\"></div>\r\n<div class=\"breadcrumb-container\"></div>\r\n<div class=\"task-status\"></div>\r\n<div class=\"form-horizontal\">\r\n  <form class=\"examination-closing-form\">\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-3\">Link to Exam Dashboard</label>\r\n      <div class=\"col-sm-9\">\r\n        <a href=\"#sec/exam/"
    + alias1(((helper = (helper = helpers.examId || (depth0 != null ? depth0.examId : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"examId","hash":{},"data":data}) : helper)))
    + "/dashboard\" target=\"_blank\" class=\"btn btn-primary\">Open Exam\r\n          Dashboard</a>\r\n      </div>\r\n    </div>\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-3\">Internal Comments</label>\r\n      <div class=\"col-sm-6\">\r\n        <textarea class=\"form-control\" rows=\"5\" name=\"internalComments\" placeholder=\"Internal Comments\"></textarea>\r\n      </div>\r\n    </div>\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-3 \">Send Notification?</label>\r\n      <div class=\"col-sm-9\">\r\n        <label class=\"radio-inline form-label\">\r\n          <input type=\"radio\" name=\"isSendNotification\" value=\"1\" />\r\n          Yes\r\n        </label>\r\n        <label class=\"radio-inline form-label\">\r\n          <input type=\"radio\" name=\"isSendNotification\" value=\"0\" />\r\n          No\r\n        </label>\r\n      </div>\r\n    </div>\r\n  </form>\r\n  <div class=\"form-group\">\r\n    <div class=\"col-sm-9 col-sm-offset-3\">\r\n      <a href=\"#sec/dashboard\" class=\"btn btn-default\" role=\"button\">Cancel</a>\r\n      <button type=\"button\" class=\"btn btn-primary submit\">Submit</button>\r\n      <div class=\"task-actions\"></div>\r\n    </div>\r\n  </div>\r\n</div>";
},"useData":true});