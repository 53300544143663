/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(entityId) {
  var deferred = $.Deferred();
  $.soap({
    body: {
      GetExistingEntityContactTypes: {
        $xmlns: 'http://dobs.pa.gov/dp/contact/bpm/1.0',
        EntityID: entityId
      }
    },
    blueprintArrays: ['GetExistingEntityContactTypesResponse.ExistingContactTypes.ContactTypeID'],
    blueprint: {
      existingContactTypes: '$..ContactTypeID'
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      response.existingContactTypes = _.filter(response.existingContactTypes, function(contact) {
        return contact !== '3' && contact !== '6';
      });

      deferred.resolve(response.existingContactTypes);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
