var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "          <option value=\""
    + alias4(((helper = (helper = helpers.userId || (depth0 != null ? depth0.userId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"userId","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + " - ("
    + alias4(((helper = (helper = helpers.userId || (depth0 != null ? depth0.userId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"userId","hash":{},"data":data}) : helper)))
    + ")</option>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "\r\n<div class=\"side-bar-container pull-left\"></div>\r\n<div class=\"dashboard-container pull-right\">\r\n  <h2>Next Step Search</h2>\r\n  <div class=\"row\">\r\n    <div class=\"col-sm-3\">\r\n      <div class=\"form-group\">\r\n        <label class=\"control-label\">Exam/Inquiry/Investigation Number</label>\r\n        <input type=\"text\" name=\"caseNumber\" class=\"form-control\" placeholder=\"Exam/Inquiry/Investigation Number\" />\r\n      </div>\r\n    </div>\r\n    <div class=\"col-sm-3\">\r\n      <div class=\"form-group\">\r\n        <label class=\"control-label\">Type</label>\r\n        <select name=\"nextStepType\" class=\"form-control\">\r\n          <option value=\"\">--</option>\r\n          <option value=\"EXAM\">Exam</option>\r\n          <option value=\"INV\">Investigation</option>\r\n          <option value=\"INQ\">Inquiry</option>\r\n          <option value=\"OTHER\">Other</option>\r\n        </select>\r\n      </div>\r\n    </div>\r\n    <div class=\"col-sm-3\">\r\n      <div class=\"form-group\">\r\n        <label class=\"control-label\">Assigned To</label>\r\n        <select name=\"nextStepAssignedTo\" class=\"form-control assignee-select\">\r\n          <option value=\"\">--</option>\r\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.scUsers : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </select>\r\n      </div>\r\n    </div>\r\n    <div class=\"col-sm-3\">\r\n      <div class=\"form-group\">\r\n        <label class=\"control-label\">Search Type</label>\r\n        <select name=\"objectType\" class=\"form-control\">\r\n          <option value=\"\">--</option>\r\n          <option value=\"INDIVIDUAL\">Individual</option>\r\n          <option value=\"ORGANIZATION\">Organization</option>\r\n        </select>\r\n      </div>\r\n    </div>\r\n  </div>\r\n  <div class=\"row\">\r\n    <div class=\"col-sm-3 org-filter\">\r\n      <div class=\"form-group\">\r\n        <label class=\"control-label\">DBA Name</label>\r\n        <input type=\"text\" name=\"dbaName\" class=\"form-control\" placeholder=\"DBA Name\">\r\n      </div>\r\n    </div>\r\n    <div class=\"col-sm-3 ind-filter\">\r\n      <div class=\"form-group\">\r\n        <label class=\"control-label\">Last Name</label>\r\n        <input type=\"text\" name=\"lastName\" class=\"form-control\" placeholder=\"Last Name\">\r\n      </div>\r\n    </div>\r\n    <div class=\"col-sm-3 ind-filter\">\r\n      <div class=\"form-group\">\r\n        <label class=\"control-label\">First Name</label>\r\n        <input type=\"text\" name=\"firstName\" class=\"form-control\" placeholder=\"First Name\">\r\n      </div>\r\n    </div>\r\n  </div>\r\n  <div class=\"search-buttons m-b-sm\">\r\n    <button class=\"btn btn-primary ladda-button search\" data-style=\"expand-right\">\r\n      <span class=\"ladda-label\"><i class=\"fa fa-search m-r-sm\"></i>Search</span>\r\n    </button>\r\n    <button class=\"btn btn-default clear-filters\"><i class=\"fa fa-eraser m-r-sm\"></i>Clear Filters</button>\r\n    <span class=\"search-summary\"></span>\r\n  </div>\r\n  <div class=\"search-results-container\"></div>\r\n</div>";
},"useData":true});