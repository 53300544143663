/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Radio = require('backbone.radio');
var NProgress = require('nprogress');

var GetApplicationDetails = require('../../common/services/GetApplicationDetails');
var GetApplicationStatus = require('../../common/services/GetApplicationStatus');
var GetReviewDecision = require('../services/GetReviewDecision');

module.exports = function(applicationId) {
  var defer = $.Deferred();
  $.when(
    GetApplicationDetails(applicationId),
    GetApplicationStatus(applicationId),
    GetReviewDecision({ objectId: applicationId, objectTypeId: 'APPLICATION' })
  )
    .done(function(applicationDetails, applicationStatus, previousReviewDecision) {
      NProgress.set(0.5);
      applicationDetails.applicationStatus = applicationStatus.internalStatus;

      var applicationFormIdentifier = applicationDetails.formIdentifier;

      var applicationTypeId = applicationDetails.applicationTypeId;
      var entityIsVerified = applicationDetails.entityIsVerified;
      var promises = [
        // Application Form Builder Promise
        Radio.channel('nd:application').request('build:' + applicationFormIdentifier, {
          applicationId: applicationId,
          applicationTypeId: applicationTypeId,
          isReadOnly: true,
          mode: 'int'
        })
      ];

      // If the entity is not yet verified, also build the entity form to show
      // on the review screen.
      if (!entityIsVerified) {
        promises.push(
          Radio.channel('nd:entity-form').request('build:entity-form', {
            entityId: applicationDetails.entityId,
            isReadOnly: true
          })
        );
      }
      $.when
        .apply($, promises)
        .done(function(applicationFormView, entityForm) {
          applicationFormView.model.set('applicationDetails', applicationDetails);

          defer.resolve({
            applicationDetails: applicationDetails,
            entityForm: entityForm,
            applicationFormView: applicationFormView,
            previousReviewDecision: previousReviewDecision
          });
        })
        .fail(function(err) {
          defer.reject(err);
        });
    })
    .fail(function(err) {
      defer.reject(err);
    });
  return defer.promise();
};
