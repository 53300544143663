var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "\r\n<div class=\"page-title clearfix\">\r\n  <h1 class=\"pull-left m-n p-n\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.task : depth0)) != null ? stack1.taskType : stack1)) != null ? stack1.description : stack1), depth0))
    + "</h1>\r\n</div>\r\n<div class=\"header-bar-container\"></div>\r\n<div class=\"breadcrumb-container\"></div>\r\n<div class=\"task-status\"></div>\r\n\r\n<div class=\"container-fluid m-b-lg\">\r\n  <form class=\"form-horizontal determination-form\">\r\n    <div class=\"col-sm-10 col-sm-offset-1\">\r\n      <div class=\"case-summary-container\">\r\n        <div class=\"form-group\">\r\n          <label class=\"control-label col-sm-4 col-md-3\">Jump To</label>\r\n          <div class=\"form-control-static col-sm-8 col-md-9 jump-to-container\"></div>\r\n        </div>\r\n        <div class=\"summary-container\"></div>\r\n        <div class=\"form-group\">\r\n          <label class=\"control-label col-sm-4 col-md-3\">Narrative</label>\r\n          <p class=\"form-control-static text-break col-sm-6 col-md-7\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.complianceDetails : depth0)) != null ? stack1.narrative : stack1), depth0))
    + "</p>\r\n        </div>\r\n      </div>\r\n      <div class=\"form-group required\">\r\n        <label class=\"control-label col-sm-4 col-md-3\">Decision</label>\r\n        <div class=\"col-sm-6 col-md-7 \">\r\n          <select name=\"decisionId\" class=\"decision-select form-control\" data-parsley-required=\"true\"\r\n            data-parsley-errors-container=\".decision-select-error-container\">\r\n            <option value=\"\" selected>--</option>\r\n            <option value=\"2103\">Violation - Issue Warning Letter</option>\r\n            <option value=\"2105\">Violation - Create Enforcement Action</option>\r\n            <option value=\"2104\">No Violation</option>\r\n          </select>\r\n          <div class=\"decision-select-error-container\"></div>\r\n        </div>\r\n      </div>\r\n      <div class=\"form-group required warning-letter-upload-form\">\r\n        <label class=\"control-label col-sm-4 col-md-3\">Warning Letter</label>\r\n        <div class=\"col-sm-6 col-md-7\">\r\n          <div class=\"warning-letter-upload-container\"></div>\r\n          <div class=\"case-decision-select-error-container\"></div>\r\n        </div>\r\n      </div>\r\n      <div class=\"col-sm-6 col-md-7 col-sm-offset-4 col-md-offset-3\">\r\n        <a class=\"btn btn-default btn-cancel\" href=\"#nondep/dashboard\">Cancel</a>\r\n        <button class=\"btn btn-primary btn-submit\">Submit</button>\r\n        <div class=\"task-actions\"></div>\r\n      </div>\r\n    </div>\r\n  </form>\r\n</div>";
},"useData":true});