/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var moment = require('moment');

var parsleyUtil = require('common/util/parsleyUtil');

var FileUploadCollection = require('psa-backbone/models/FileUploadCollection');

var FileUploadView = require('common/views/FileUploadView');
var makeUploadCollection = require('common/util/makeUploadCollection');
var modelGet = require('common/util/modelGet');

var tmplConsumerDiscountRenewalGeneralDetailsSectionView = require('./ConsumerDiscountRenewalGeneralDetailsSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplConsumerDiscountRenewalGeneralDetailsSectionView,

  className: 'consumer-discount-renewal-general-details-section-view',

  ui: {
    criminalOfficerFileContainer: '.criminal-officer-file-container',
    administrativeActionFileContainer: '.administrative-action-file-container',
    generalDetailsForm: '.general-details-form',
    legalProceedingsFileContainer: '.legal-proceedings-file-container',
    applicationDocumentsFileContainer: '.application-documents-container',
    bondFileContainer: '.bond-file-container',
    bondNumber: '.bond-number',
    bondCompany: '.bond-company',
    bondCompanyFormGroup: '.bond-company-form-group',
    bondNumberFormGroup: '.bond-number-form-group',
    attachFormGroup: '.attach-form-group'
  },

  regions: {
    criminalOfficerFileContainer: '@ui.criminalOfficerFileContainer',
    administrativeActionFileContainer: '@ui.administrativeActionFileContainer',
    legalProceedingsFileContainer: '@ui.legalProceedingsFileContainer',
    applicationDocumentsFileContainer: '@ui.applicationDocumentsFileContainer',
    bondFileContainer: '.bond-file-container'
  },

  behaviors: {
    RadioGroupToggleBehavior: {
      radios: [
        {
          target: '#hasCriminalOfficerExplainationArea',
          radioGroup: '[name="hasCriminalOfficer"]',
          valueToShow: '1'
        },
        {
          target: '#hasOfficerWithAdministrativeActionExplainationArea',
          radioGroup: '[name="hasOfficerWithAdministrativeAction"]',
          valueToShow: '1'
        },
        {
          target: '#hasLegalProceedingsExplainationArea',
          radioGroup: '[name="hasLegalProceedings"]',
          valueToShow: '1'
        }
      ]
    }
  },

  initialize: function() {
    this.MINIMUM_LICENSE_AGE = 3;
  },

  templateHelpers: function() {
    return {
      minimumLicenseAge: this.MINIMUM_LICENSE_AGE
    };
  },

  onBeforeShow: function() {
    var originalIssueDate = modelGet(this.model, 'applicationDetails.license.originalIssueDate');
    var today = moment();
    var licenseAgeInYears = today.diff(originalIssueDate, 'years');
    var mustGiveBondInfo = licenseAgeInYears < this.MINIMUM_LICENSE_AGE;

    this.criminalOfficerFile = new FileUploadView({
      collection: makeUploadCollection(this.model.get('renewalData').hasCriminalOfficerDocument),
      isReadOnly: this.options.isReadOnly,
      metadata: {
        documentTypeId: 10019
      }
    });
    this.showChildView('criminalOfficerFileContainer', this.criminalOfficerFile);

    this.administrativeActionFile = new FileUploadView({
      collection: makeUploadCollection(this.model.get('renewalData').hasOfficerWithAdministrativeActionDocument),
      isReadOnly: this.options.isReadOnly,
      metadata: {
        documentTypeId: 10019
      }
    });
    this.showChildView('administrativeActionFileContainer', this.administrativeActionFile);

    this.legalProceedingsFile = new FileUploadView({
      collection: makeUploadCollection(this.model.get('renewalData').hasLegalProceedingsDocument),
      isReadOnly: this.options.isReadOnly,
      metadata: {
        documentTypeId: 10023
      }
    });
    this.showChildView('legalProceedingsFileContainer', this.legalProceedingsFile);

    this.bondFile = new FileUploadView({
      isRequired: mustGiveBondInfo,
      collection: makeUploadCollection(this.model.get('renewalData').bondDocument),
      isReadOnly: this.options.isReadOnly,
      metadata: {
        documentTypeId: 10019
      }
    });
    this.showChildView('bondFileContainer', this.bondFile);

    this.applicationDocumentsFile = new FileUploadView({
      isRequired: true,
      allowMultipleFiles: true,
      collection: new FileUploadCollection(this.model.get('renewalData').applicationDocuments),
      isReadOnly: this.options.isReadOnly,
      metadata: {
        documentTypeId: 10023
      }
    });
    this.showChildView('applicationDocumentsFileContainer', this.applicationDocumentsFile);

    Syphon.deserialize(this, this.model.get('renewalData'));

    if (!mustGiveBondInfo) {
      this.ui.bondNumber.removeAttr('data-parsley-required');
      this.ui.bondNumberFormGroup.removeClass('required');
      this.ui.bondCompany.removeAttr('data-parsley-required');
      this.ui.bondCompanyFormGroup.removeClass('required');
      this.ui.attachFormGroup.removeClass('required');
    }
  },

  validate: function(config) {
    if (config.type === 'save') {
      parsleyUtil.disableRequiredValidation(this.ui.generalDetailsForm);
      return $.when(this.ui.generalDetailsForm.parsley().whenValidate());
    } else {
      parsleyUtil.enableRequiredValidation(this.ui.generalDetailsForm);
      return $.when(this.ui.generalDetailsForm.parsley().whenValidate(), this.bondFile.validate());
    }
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    _.extend(formData, {
      hasCriminalOfficerDocumentId: this.criminalOfficerFile.getFirstFileId(),
      hasOfficerWithAdministrativeActionDocumentId: this.administrativeActionFile.getFirstFileId(),
      bondDocumentId: this.bondFile.getFirstFileId(),
      hasLegalProceedingsDocumentId: this.legalProceedingsFile.getFirstFileId(),
      applicationDocuments: this.applicationDocumentsFile.collection.pluck('fileId')
    });
    return formData;
  }
});
