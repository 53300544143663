/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2018] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');

var GetLicenseInfoDetail = require('../services/GetLicenseInfoDetail');
var GetAllLicenseInfoDetailLookups = require('../services/GetAllLicenseInfoDetailLookups');
var SecLicenseInfoModalView = require('../views/SecLicenseInfoModalView');

module.exports = {
  channel: 'sc',

  event: 'build:license-info-modal-view',

  builder: function(data) {
    var defer = $.Deferred();
    $.when(GetLicenseInfoDetail(data.id), GetAllLicenseInfoDetailLookups())
      .done(function(licenseInfo, lookups) {
        defer.resolve(
          new SecLicenseInfoModalView({
            objectTypeId: data.objectTypeId,
            model: new Backbone.Model({
              licenseId: data.objectId,
              licenseInfo: licenseInfo,
              licenseDescription: lookups.licenseDescription
            })
          })
        );
      })
      .fail(function(err) {
        defer.reject(err);
      });
    return defer.promise();
  }
};
