/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');

var parsleyUtil = require('common/util/parsleyUtil');

var NonDepEntityFormLocationCollectionView = require('./NonDepEntityFormLocationCollectionView');

var tmplNonDepEntityFormSectionLocationsView = require('../templates/NonDepEntityFormSectionLocationsView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepEntityFormSectionLocationsView,

  className: 'nondep-entity-form-section-locations-view',

  ui: {
    addLocation: '.add-location',
    locationCollectionContainer: '.location-collection-container',
    missingPPoBAlert: '.alert.missing-ppob'
  },

  regions: {
    locationCollectionContainer: '@ui.locationCollectionContainer'
  },

  events: {
    'click @ui.addLocation': 'onClickAddLocation'
  },

  childEvents: {
    'selected:primary-place-of-business': 'onChangePrimaryPlaceOfBusiness'
  },

  onBeforeShow: function() {
    this.locations = new NonDepEntityFormLocationCollectionView({
      collection: new Backbone.Collection(this.model.get('locations'))
    });
    this.showChildView('locationCollectionContainer', this.locations);
  },

  onClickAddLocation: function(e) {
    e.preventDefault();
    this.locations.collection.add({});
  },

  onChangePrimaryPlaceOfBusiness: function() {
    this.ui.missingPPoBAlert.hide();
  },

  validate: function(config) {
    var defer = $.Deferred();
    this.ui.missingPPoBAlert.hide();
    this.ui.locationCollectionContainer.parsley().reset();
    if (config.type === 'save') {
      // Turn off required field validation for this form
      parsleyUtil.disableRequiredValidation(this.ui.locationCollectionContainer);
    } else {
      // Turn on required field validation for this form
      parsleyUtil.enableRequiredValidation(this.ui.locationCollectionContainer);
    }

    if (config.type === 'submit') {
      if (!this.locations.hasOnePrimaryPlaceOfBusiness()) {
        this.ui.missingPPoBAlert.show();
        defer.reject();
      }
    }

    this.ui.locationCollectionContainer
      .parsley()
      .whenValidate()
      .done(function() {
        defer.resolve();
      })
      .fail(function() {
        defer.reject();
      });
    return defer.promise();
  },

  getFormData: function() {
    return this.locations.getFormData();
  }
});
