/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(invInqId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetInvestigationInquiryRelatedCaseByInvInq: {
        $xmlns: 'http://dobs.pa.gov/sc/investigationinquiry/bpm/1.0',
        InvInqID: invInqId
      }
    },
    blueprintArrays: ['GetInvestigationInquiryRelatedCaseByInvInqResponse.SC_InvestigationInquiryRelatedCase'],
    blueprint: {
      cases: [
        '$..SC_InvestigationInquiryRelatedCase',
        {
          id: '$..ID',
          invInqId: '$..InvInqID',
          relatedTypeId: '$..RelatedTypeID',
          relatedType: '$..RelatedType',
          objectTypeId: '$..ObjectTypeID',
          objectId: '$..ObjectID',
          category: '$..Category',
          status: '$..Status',
          caseNumber: '$..CaseNumber',
          openedDate: '$..OpenedDate',
          closedDate: '$..ClosedDate',
          upgradedDate: '$..UpgradedDate',
          otherCaseInfo: '$..OtherCaseInfo',
          isDeleted: '$..IsDeleted'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      response.relatedCases = [];
      response.otherSubjects = [];

      _.each(response.cases, function(relatedCase) {
        if (relatedCase.openedDate) {
          relatedCase.openedDate = moment(relatedCase.openedDate, 'YYYY-MM-DD');
        }
        if (relatedCase.closedDate) {
          relatedCase.closedDate = moment(relatedCase.closedDate, 'YYYY-MM-DD');
        }
        if (relatedCase.upgradedDate) {
          relatedCase.upgradedDate = moment(relatedCase.upgradedDate, 'YYYY-MM-DD');
        }

        if (relatedCase.relatedTypeId === '4') {
          response.otherSubjects.push(relatedCase);
        } else if (relatedCase.relatedTypeId === '1') {
          relatedCase.objectType = 'exam';
          response.relatedCases.push(relatedCase);
        } else {
          relatedCase.objectType = 'investigation-inquiry';
          response.relatedCases.push(relatedCase);
        }
      });

      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
