/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _get = require('lodash.get');

var Session = require('psa-core/Session');

var SidebarNavView = require('common/views/SidebarNavView');

var Config = require('../../../config/Config');

var tmplNonDepInternalSidebarNavView = require('../templates/NonDepInternalSidebarNavView.hbs');

module.exports = SidebarNavView.extend({
  template: tmplNonDepInternalSidebarNavView,

  templateHelpers: function() {
    var iHubReportDirectory = _get(Session, 'departments.NON_DEPOSITORY.iHubReportDirectory');
    var iHubFolderLink = Config.getIHubFolderLink(iHubReportDirectory);

    return {
      iHubFolderLink: iHubFolderLink
    };
  }
});
