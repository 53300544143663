/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var parsleyUtil = require('common/util/parsleyUtil');
var toggleForm = require('common/util/toggleForm');
var AddressView = require('common/views/AddressView');

require('../styles/DepFilingPointOfContactSectionView.css');
var tmplDepFilingPointOfContactSectionView = require('../templates/DepFilingPointOfContactSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepFilingPointOfContactSectionView,

  className: 'dep-filing-point-of-contact-section-view',

  ui: {
    pointOfContactForm: '.point-of-contact-form',
    businessContainer: '.business-container',
    addressContainer: '.address-container'
  },

  regions: {
    addressContainer: '@ui.addressContainer'
  },

  behaviors: {
    ReadOnlyFormBehavior: {}
  },

  templateHelpers: function() {
    return {
      mode: this.options.mode,
      isSubmission: this.options.isSubmission
    };
  },

  onBeforeShow: function() {
    if (this.options.hasAddress) {
      this.showChildView(
        'addressContainer',
        new AddressView({
          horizontal: false,
          startingCountry: this.model.get('country'),
          startingPostalCode: this.model.get('postalCode'),
          startingCounty: this.model.get('county'),
          isRequired: true
        })
      );
    }

    if (!this.options.hasBusiness) {
      toggleForm(this.ui.businessContainer, false);
    }

    Syphon.deserialize(this, this.model.toJSON());
  },

  validate: function(config) {
    this.ui.pointOfContactForm.parsley().reset();
    if (config.type === 'save') {
      // Turn off required field validation for this form
      parsleyUtil.disableRequiredValidation(this.ui.pointOfContactForm);
    } else {
      // Turn on required field validation for this form
      parsleyUtil.enableRequiredValidation(this.ui.pointOfContactForm);
    }
    return this.ui.pointOfContactForm.parsley().whenValidate();
  },

  getFormData: function() {
    return Syphon.serialize(this);
  }
});
