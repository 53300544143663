/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Ladda = require('ladda');
var Radio = require('backbone.radio');
var Syphon = require('backbone.syphon');
var moment = require('moment');

var Session = require('psa-core/Session');
var UIUtil = require('psa-core/util/ui');

var modelGet = require('common/util/modelGet');
var HeaderBarView = require('common/views/HeaderBarView');
var BreadcrumbView = require('common/views/BreadcrumbView');
var ContentServerLinkView = require('common/views/ContentServerLinkView');
var ConfirmationModalView = require('common/views/ConfirmationModalView');

var SubmitReviewApplicationBD = require('../../services/SubmitReviewApplicationBD');

var tmplSecReviewApplicationBDTaskView = require('./SecReviewApplicationBDTaskView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplSecReviewApplicationBDTaskView,

  className: 'sec-review-application-bd-task-view container-fluid',

  ui: {
    breadcrumb: '.breadcrumb-container',
    documentContainer: '.document-container',
    submit: '.submit',
    headerBar: '.header-bar-container',
    csLinkContainer: '.cs-link-container',
    examineApplicationContainer: '.examine-application-container',
    fileUploadTableContainer: '.file-upload-table-container'
  },

  regions: {
    breadcrumb: '@ui.breadcrumb',
    headerBar: '@ui.headerBar',
    csLinkContainer: '@ui.csLinkContainer',
    documentContainer: '@ui.documentContainer',
    fileUploadTableContainer: '@ui.fileUploadTableContainer'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  behaviors: {
    TaskBehavior: {}
  },

  onBeforeRender: function() {},

  onBeforeShow: function() {
    var taskDescription = modelGet(this.model, 'task.taskType.description');
    var application = modelGet(this.model, 'application');
    var headerBarColumns = [
      {
        name: 'applicantLegalName',
        label: 'Applicant Legal Name',
        minWidth: '160px',
        maxWidth: '200px'
      },
      {
        name: 'CRDNumber',
        label: 'CRD Number',
        minWidth: '160px',
        maxWidth: '200px'
      },
      {
        name: 'applicationType',
        label: 'Application Type',
        minWidth: '160px',
        maxWidth: '200px'
      },
      {
        name: 'licenseType',
        label: 'License Type',
        minWidth: '160px',
        maxWidth: '200px'
      },
      {
        name: 'filingExaminerUserName',
        label: 'Primary Examiner',
        minWidth: '160px',
        maxWidth: '200px'
      }
    ];

    var headerbar = modelGet(this.model, 'entityIdentifiers');
    headerbar.applicationType = application.applicationType;
    headerbar.licenseType = application.licenseType;
    headerbar.filingExaminerUserName = application.filingExaminerUserName;

    this.showChildView(
      'headerBar',
      new HeaderBarView({
        columns: new Backbone.Collection(headerBarColumns),
        model: new Backbone.Model(headerbar)
      })
    );

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#sec/dashboard',
            text: 'Home'
          },
          {
            text: taskDescription
          }
        ]
      })
    );

    this.showChildView(
      'csLinkContainer',
      new ContentServerLinkView({
        model: new Backbone.Model({
          buttonText: 'Open Application Folder',
          contentServerId: modelGet(this.model, 'application.csFolderId')
        })
      })
    );

    // this.fileUploadTable = new FileUploadTableView({
    //   documentTypes: this.model.get('documentTypes'),
    //   emptyText: 'No Document Provided',
    //   collection: new Backbone.Collection()
    // });
    // this.showChildView('fileUploadTableContainer', this.fileUploadTable);

    var comments;
    if (modelGet(this.model, 'application.comments')) {
      comments = '\n\n' + modelGet(this.model, 'application.comments');
    } else {
      comments = '';
    }

    Syphon.deserialize(this, { comments: comments });
  },

  onClickSubmit: function(e) {
    var self = this;
    e.preventDefault();
    this.validate().done(
      function() {
        var formData = self.getFormData();
        var applicationDecisionId = formData.applicationDecisionId;
        var recommendationList = { 3: 'Withdrawal', 4: 'Withdraw Abandon', 5: 'Denial' };
        var recommendation = recommendationList[applicationDecisionId];
        if (recommendation) {
          var message = 'Please upload a ' + recommendation + ' Letter via Content Server.';
          UIUtil.showModal({
            title: 'Confirm Submitting Task',
            view: new ConfirmationModalView({
              confirmText: 'Submit Task',
              model: new Backbone.Model({
                message: message,
                onConfirm: function() {
                  return SubmitReviewApplicationBD(formData);
                },
                onConfirmDone: function() {
                  Radio.channel('navigate').trigger('show:int:sc:dashboard');
                }
              })
            })
          });
        } else {
          var laddaContext = Ladda.create(this.ui.submit.get(0));
          laddaContext.start();
          SubmitReviewApplicationBD(formData)
            .done(function() {
              Radio.channel('navigate').trigger('show:int:sc:dashboard');
            })
            .fail(function() {
              laddaContext.stop();
            });
        }
      }.bind(this)
    );
  },

  validate: function() {
    return $.when.apply($, [
      this.ui.examineApplicationContainer.parsley().whenValidate()
      //   this.fileUploadTable.validate({ type: 'submit' }, 0)
    ]);
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    var taskId = this.model.get('taskId');
    formData.taskId = taskId;
    formData.id = modelGet(this.model, 'application.id');
    var timestamp = moment().format('MM/DD/YYYY h:mm A');
    var userName = Session.user.description;
    formData.comments = '[' + userName + ' - ' + timestamp + '] \n' + formData.comments;
    // formData.documents = this.fileUploadTable.getFormData();
    return formData;
  }
});
