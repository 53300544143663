/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');
var Ladda = require('ladda');
var moment = require('moment');

var ButtonCell = require('common/backgrid/ButtonCell');
var ButtonLinkCell = require('common/backgrid/ButtonLinkCell');
var GridSpinnerView = require('common/views/GridSpinnerView');
var MomentCell = require('common/backgrid/MomentCell');
var LinkCell = require('common/backgrid/LinkCell');

var ClaimTask = require('../../common/services/ClaimTask');
var NonDepInternalSidebarNavView = require('../../common/views/NonDepInternalSidebarNavView');

require('../styles/NonDepInternalDashboardView.css');
var tmplNonDepInternalDashboardView = require('../templates/NonDepInternalDashboardView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepInternalDashboardView,

  className: 'non-dep-internal-dashboard-view container-fluid',

  ui: {
    sideBar: '.side-bar-container',
    assignedTasks: '.assigned-tasks-container',
    availableTasks: '.available-tasks-container'
  },

  regions: {
    sideBar: '@ui.sideBar',
    assignedTasks: '@ui.assignedTasks',
    availableTasks: '@ui.availableTasks'
  },

  onBeforeShow: function() {
    this.showChildView('sideBar', new NonDepInternalSidebarNavView());

    var userTasksGridColumns = [
      {
        name: 'type',
        label: 'Task Name',
        cell: 'string',
        headerCell: 'custom',
        width: 25,
        editable: false
      },
      {
        name: 'licenseType',
        label: 'License Type',
        cell: 'string',
        headerCell: 'custom',
        width: 10,
        editable: false
      },
      {
        name: 'entityName',
        label: 'Entity Name',
        cell: LinkCell.extend({
          emptyText: '--',
          href: function(model) {
            var entityId = model.get('entityId');
            var url = entityId ? '#nondep/entity/' + entityId + '/dashboard' : '';
            return url;
          }
        }),
        headerCell: 'custom',
        width: 15,
        editable: false
      },
      {
        name: 'licenseNumber',
        label: 'License Number',
        cell: LinkCell.extend({
          emptyText: '--',
          href: function(model) {
            var entityId = model.get('entityId');
            var licenseId = model.get('licenseId');
            var url = entityId && licenseId ? '#nondep/entity/' + entityId + '/license/' + licenseId : '';
            return url;
          }
        }),
        headerCell: 'custom',
        width: 10,
        editable: false
      },
      {
        name: 'location',
        label: 'Location',
        cell: 'string',
        headerCell: 'custom',
        width: 10,
        editable: false
      },
      {
        name: 'city',
        label: 'City',
        cell: 'string',
        headerCell: 'custom',
        width: 10,
        editable: false
      },
      {
        name: 'state',
        label: 'State',
        cell: 'string',
        headerCell: 'custom',
        width: 5,
        editable: false
      },
      {
        name: 'assignedClaimedDate',
        label: 'Assigned/Claimed On',
        cell: MomentCell.MomentCell,
        formatter: MomentCell.MomentDateTimeFormatter.extend({
          emptyValue: '--'
        }),
        sortValue: MomentCell.MomentSortValue,
        headerCell: 'custom',
        width: 20,
        editable: false
      },
      {
        name: '',
        label: '',
        cell: ButtonLinkCell.extend({
          name: 'Open',
          buttonClass: 'btn-primary btn-sm',
          href: function(model) {
            return '#nondep/task/' + model.get('taskID');
          }
        }),
        headerCell: 'custom',
        width: 10,
        editable: false,
        sortable: false
      }
    ];

    this.assignedTasksView = new GridSpinnerView({
      columns: userTasksGridColumns,
      spinner: {
        scale: 0.75,
        length: 10
      },
      pageable: true
    });

    this.showChildView('assignedTasks', this.assignedTasksView);

    Radio.channel('nd:internal-dashboard')
      .request('build:assigned-tasks-grid-view', userTasksGridColumns)
      .done(
        function(gridView) {
          if (!this.isDestroyed) {
            this.assignedTasksView = gridView;
            this.showChildView('assignedTasks', gridView);
            gridView.viewGrid.sort('assignedClaimedDate', 'descending');
          }
        }.bind(this)
      );

    var availableTasksGridColumns = [
      {
        name: 'type',
        label: 'Task Name',
        cell: 'string',
        headerCell: 'custom',
        width: 25,
        editable: false
      },
      {
        name: 'licenseType',
        label: 'License Type',
        cell: 'string',
        editable: false,
        headerCell: 'custom',
        width: 10
      },
      {
        name: 'entityName',
        label: 'Entity Name',
        cell: LinkCell.extend({
          emptyText: '--',
          href: function(model) {
            var entityId = model.get('entityId');
            var url = entityId ? '#nondep/entity/' + entityId + '/dashboard' : '';
            return url;
          }
        }),
        headerCell: 'custom',
        width: 15,
        editable: false
      },
      {
        name: 'nmlsId',
        label: 'NMLS ID',
        cell: 'string',
        editable: false,
        headerCell: 'custom',
        width: 5
      },
      {
        name: 'licenseNumber',
        label: 'License Number',
        cell: LinkCell.extend({
          emptyText: '--',
          href: function(model) {
            var entityId = model.get('entityId');
            var licenseId = model.get('licenseId');
            var url = entityId && licenseId ? '#nondep/entity/' + entityId + '/license/' + licenseId : '';
            return url;
          }
        }),
        headerCell: 'custom',
        width: 5,
        editable: false
      },
      {
        name: 'location',
        label: 'Location',
        cell: 'string',
        headerCell: 'custom',
        width: 10,
        editable: false
      },
      {
        name: 'city',
        label: 'City',
        cell: 'string',
        headerCell: 'custom',
        width: 10,
        editable: false
      },
      {
        name: 'state',
        label: 'State',
        cell: 'string',
        headerCell: 'custom',
        width: 5,
        editable: false
      },
      {
        name: 'deliveryDate',
        label: 'Assigned On',
        cell: MomentCell.MomentCell,
        formatter: MomentCell.MomentDateTimeFormatter.extend({
          emptyValue: '--'
        }),
        sortValue: MomentCell.MomentSortValue,
        headerCell: 'custom',
        width: 10,
        editable: false
      },
      {
        name: 'assignedRole',
        label: 'Assigned Role',
        cell: 'string',
        headerCell: 'custom',
        width: 5,
        editable: false
      },
      {
        name: 'claim',
        label: '',
        cell: ButtonCell.extend({
          name: 'Claim',
          onClick: this.onClickClaimAvailableTask.bind(this),
          buttonAttrs: {
            'data-style': 'zoom-in',
            'data-color': '#EEEEEE',
            'data-spinner-color': '#EEEEEE'
          },
          buttonClass: 'btn-primary btn-sm ladda-button',
          labelClass: 'ladda-label'
        }),
        headerCell: 'custom',
        width: 10,
        editable: false,
        sortable: false
      }
    ];

    this.availableTasksView = new GridSpinnerView({
      columns: availableTasksGridColumns,
      spinner: {
        scale: 0.75,
        length: 10
      },
      pageable: true
    });

    this.showChildView('availableTasks', this.availableTasksView);

    Radio.channel('nd:internal-dashboard')
      .request('build:available-tasks-grid-view', availableTasksGridColumns)
      .done(
        function(gridView) {
          if (!this.isDestroyed) {
            this.availableTasksView = gridView;
            this.showChildView('availableTasks', gridView);
            gridView.viewGrid.sort('deliveryDate', 'descending');
          }
        }.bind(this)
      );
  },

  onClickClaimAvailableTask: function(e, model) {
    e.preventDefault();

    var laddaContext = Ladda.create(e.currentTarget);
    laddaContext.start();

    ClaimTask(model.get('taskID'))
      .done(
        function() {
          this.viewContext.availableTasksView.viewGrid.removeRow(model);
          // Set the initial value of the assignedClaimedDate to be now.
          model.set('assignedClaimedDate', moment());
          var assignedTasksGrid = this.viewContext.assignedTasksView.viewGrid;
          assignedTasksGrid.collection.add(model);
          assignedTasksGrid.sort('assignedClaimedDate', 'descending');
        }.bind({
          event: e,
          viewContext: this
        })
      )
      .fail(
        function(err) {
          this.laddaContext.stop();
        }.bind({
          event: e,
          viewContext: this
        })
      );
  }
});
