/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');

var GetEntityLocations = require('../../common/services/GetEntityLocations');

var GetLicenseLocationChangeApplication = require('../services/GetLicenseLocationChangeApplication');
var NonDepLicenseAddressChangeFormView = require('../forms/LicenseAddressChangeApplication');
var GetLicenseDashboard = require('../../license-dashboard/services/GetLicenseDashboard');

module.exports = {
  event: 'build:amendment:license-address-change',

  channel: 'nd:application',

  builder: function(config) {
    var defer = $.Deferred();

    GetLicenseLocationChangeApplication(config.applicationId)
      .done(function(appData) {
        $.when(GetEntityLocations(appData.entityId), GetLicenseDashboard(appData.licenseId))
          .done(function(entityLocations, licenseDashboard) {
            defer.resolve(
              new NonDepLicenseAddressChangeFormView({
                isReadOnly: config.isReadOnly,
                viewMode: config.mode,
                mode: config.mode,
                model: new Backbone.Model({
                  applicationId: appData.applicationId,
                  entityId: appData.entityId,
                  effectiveChangeDate: appData.effectiveChangeDate,
                  licenseId: appData.licenseId,
                  newLocationId: appData.newLocationId,
                  isCertified: appData.isCertified,
                  certificationName: appData.certificationName,
                  entityLocations: entityLocations,
                  licenseDashboard: licenseDashboard,
                  applicationDocuments: appData.applicationDocuments
                })
              })
            );
          })
          .fail(function(err) {
            defer.reject(err);
          });
      })
      .fail(function(err) {
        defer.reject(err);
      });

    return defer.promise();
  }
};
