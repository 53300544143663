var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return "    <div>"
    + container.escapeExpression(((helper = (helper = helpers.instructions || (depth0 != null ? depth0.instructions : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"instructions","hash":{},"data":data}) : helper)))
    + "</div><br>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "    <div>Attach any other documentation that you feel is relevant or necessary to the Bureau’s consideration of this request.</div><br>\r\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"form-group\">\r\n      <textarea name=\"supportingDocDescriptionField\" class=\"form-control supporting-doc-description-field\" placeholder=\"Provide brief description of Supporting Documents being attached.\" rows=\"5\" ></textarea>\r\n    </div>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "\r\n<form>\r\n  <div class=\"form-group\">\r\n    <b>Instructions:</b>\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.hasInstructions : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "    <div class=\"supporting-documents-container\"></div>\r\n  </div>\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isTrust : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</form>";
},"useData":true});