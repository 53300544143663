/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(formData) {
  var defer = $.Deferred();
  $.soap({
    body: {
      SubmitContact: {
        $xmlns: 'http://dobs.pa.gov/cs/contact/bpm/1.0',
        Contact: {
          ContactID: formData.contactId,
          DepartmentID: formData.departmentId,
          EntityID: formData.entityId,
          FirstName: formData.firstName,
          LastName: formData.lastName,
          Title: formData.title,
          PhoneNumber: formData.phoneNumber,
          Fax: formData.fax,
          Email: formData.email,
          AddressLine1: formData.addressLine1,
          AddressLine2: formData.addressLine2,
          AddressLine3: formData.addressLine3,
          County: formData.county,
          City: formData.city,
          State: formData.state,
          PostalCode: formData.postalCode,
          Country: formData.country,
          LicenseTypeID: formData.licenseTypeId,
          ContactTypeID: formData.contactTypeID,
          ContactType: formData.contactType,
          IsDeleted: formData.isDeleted
        }
      }
    },
    blueprint: {
      newContactId: '$..NewContactID'
    }
  })
    .done(function(response) {
      cleanUpResponse(response, { cleanUpTextKeys: true });

      defer.resolve(response.newContactId);
    })
    .fail(function(err) {
      defer.reject(err);
    });

  return defer.promise();
};
