/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var modelGet = require('common/util/modelGet');
var parsleyUtil = require('common/util/parsleyUtil');

var tmplDepFilingLawyerSectionView = require('../templates/DepFilingLawyerSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepFilingLawyerSectionView,

  className: 'dep-filing-lawyer-section-view',

  ui: {
    lawyerForm: '.lawyer-form',
    lawyerSelect: '.lawyer-select'
  },

  behaviors: {
    ReadOnlyFormBehavior: {}
  },

  templateHelpers: function() {
    return {
      mode: this.options.mode
    };
  },

  onBeforeShow: function() {
    var lawyers = this.model.get('lawyers');
    var currentLawyerUserId = modelGet(this.model, 'currentLawyer.externalUserId');
    var currentLawyerIsValid = _.find(lawyers, { externalUserId: currentLawyerUserId });
    if (currentLawyerIsValid) {
      this.ui.lawyerSelect.val(currentLawyerUserId);
    }
  },

  validate: function(config) {
    this.ui.lawyerForm.parsley().reset();
    if (config.type === 'save') {
      // Turn off required field validation for this form
      parsleyUtil.disableRequiredValidation(this.ui.lawyerForm);
    } else {
      // Turn on required field validation for this form
      parsleyUtil.enableRequiredValidation(this.ui.lawyerForm);
    }
    return this.ui.lawyerForm.parsley().whenValidate();
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    return formData;
  }
});
