/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(licenseId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetOrganizationLicenseDetails: {
        $xmlns: 'http://dobs.pa.gov/sc/organizationlicense/bpm/1.0',
        LicenseID: licenseId
      }
    },
    blueprintArrays: ['GetOrganizationLicenseDetailsResponse.SC_OrganizationLicenseCustody',
                      'GetOrganizationLicenseDetailsResponse.SC_FinancialStatement'],
    blueprint: {
      id: '$..ID',
      CSFolderId: '$..CSFolderID',
      organizationId: '$..OrganizationID',
      licenseTypeId: '$..LicenseTypeID',
      licenseStatusId: '$..LicenseStatusID',
      licenseStatusDate: '$..LicenseStatusDate',
      filingExaminerUserId: '$..FilingExaminerUserID',
      FINRADate: '$..FINRADate',
      SECNumber: '$..SECNumber',
      SECRegistrationDate: '$..SECRegistrationDate',
      isCustody: '$..IsCustody',
      fiscalYearEnd: '$..FiscalYearEnd',
      isFinancialReportReq: '$..IsFinancialReportReq',
      isDiscretion: '$..IsDiscretion',
      status: '$..Status',
      type: '$..Type',
      filingExaminerUserName: '$..FilingExaminerUserName',
      licenseCustodys: [
        '$..SC_OrganizationLicenseCustody',
        {
          id: '$..ID',
          organizationId: '$..OrganizationID',
          organizationLicenseId: '$..OrganizationLicenseID',
          custodyTypeId: '$..CustodyTypeID',
          type: '$..Type'
        }
      ],
      financialTask :[
        '$..SC_FinancialStatement',
        {
          id: '$..ID',
          workflowstatusID: '$..WorkflowStatusID',
          createdDate: '$..CreatedDate'
        }
      ],
      examInProgress: '$..ExamInProgress'
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      response.licenseCustodyList = _.pluck(response.licenseCustodys, 'type');

      if (response.licenseStatusDate) {
        response.licenseStatusDate = moment(response.licenseStatusDate, 'YYYY-MM-DD').format('MM/DD/YYYY');
      }

      if (response.FINRADate) {
        response.FINRADate = moment(response.FINRADate, 'YYYY-MM-DD').format('MM/DD/YYYY');
      }

      _.each(response.financialTask, function(financial) {
        financial.createdDate = moment(financial.createdDate, moment.ISO_8601).format('MM/DD/YYYY hh:mm a');
      }
      )
      
      if (response.SECRegistrationDate) {
        response.SECRegistrationDate = moment(response.SECRegistrationDate, 'YYYY-MM-DD').format('MM/DD/YYYY');
      }

      if (response.filingExaminerUserId) {
        response.filingExaminerDisplay = response.filingExaminerUserName + ' - (' + response.filingExaminerUserId + ')';
      } else {
        response.filingExaminerDisplay = '--';
      }

      response.custodyTypeIds = _.reduce(
        response.licenseCustodys,
        function(memo, licenseCustody) {
          memo.push(licenseCustody.custodyTypeId);
          return memo;
        },
        []
      );

      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject();
    });
  return deferred.promise();
};
