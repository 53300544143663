/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var NProgress = require('nprogress');

var NavUtils = require('psa-core/util/nav');

var Scaffold = require('common/layout/Scaffold');
var GetUsersForRole = require('common/services/GetUsersForRole');

var GetAllLicenseTypes = require('../../common/services/GetAllLicenseTypes');
var GetExamPeriodById = require('../services/GetExamPeriodById');
var GetAllExamStatuses = require('../services/GetAllExamStatuses');
var GetExamByExamPeriod = require('../services/GetExamByExamPeriod');

var NonDepInternalCurrentExamsView = require('../views/NonDepInternalCurrentExamsView');

module.exports = function(examYearId) {
  NProgress.start();
  NProgress.set(0.6);

  $.when(
    GetExamByExamPeriod(examYearId),
    GetAllExamStatuses(),
    GetAllLicenseTypes(),
    GetUsersForRole('ND - Examiner'),
    GetExamPeriodById(examYearId)
  ).done(function(exams, examStatuses, licenseTypes, examiners, examPeriod) {
    NavUtils.navigate('nondep/dashboard/exams/' + examYearId);
    Scaffold.content.show(
      new NonDepInternalCurrentExamsView({
        model: new Backbone.Model({
          exams: exams,
          examYear: examPeriod.yearPeriod,
          examStatuses: examStatuses,
          licenseTypes: licenseTypes,
          examiners: examiners,
          examYearId: examYearId
        })
      })
    );
    NProgress.done();
  });
};
