/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(data) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetNonFINRAPaymentMemberDetailByObject: {
        $xmlns: 'http://dobs.pa.gov/sc/nonfinrapaymentmemberdetail/bpm/1.0',
        ObjectTypeID: data.objectTypeId,
        ObjectID: data.objectId
      }
    },
    blueprintArrays: ['GetNonFINRAPaymentMemberDetailByObjectResponse.SC_NonFINRAPaymentMemberDetail'],
    blueprint: {
      members: [
        '$..SC_NonFINRAPaymentMemberDetail',
        {
          id: '$..ID',
          objectTypeId: '$..ObjectTypeID',
          objectId: '$..ObjectID',
          typeId: '$..TypeID',
          paidDate: '$..PaidDate',
          regardingId: '$..RegardingID',
          amount: '$..Amount',
          comments: '$..Comments',
          type: '$..Type',
          regarding: '$..Regarding'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      _.each(response.members, function(member) {
        member.type = member.type || '--';
        member.regarding = member.regarding || '--';
        if (member.paidDate) {
          member.paidDate = moment(member.paidDate, 'YYYY-MM-DD');
        }
      });

      deferred.resolve(response.members);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
