/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var NProgress = require('nprogress');

var NavUtils = require('psa-core/util/nav');

var Scaffold = require('common/layout/Scaffold');

var GetOrganizationDetails = require('../services/GetOrganizationDetails');
var GetInternalTasksByOrganization = require('../services/GetInternalTasksByOrganization');
var SecIntOrganizationDashboardView = require('../views/SecIntOrganizationDashboardView');

module.exports = function(organizationId) {
  NProgress.start();
  NProgress.set(0.6);

  $.when(GetOrganizationDetails(organizationId), GetInternalTasksByOrganization(organizationId))
    .done(function(organization, tasks) {
      NavUtils.navigate('#sec/organization/' + organizationId + '/dashboard');
      Scaffold.content.show(
        new SecIntOrganizationDashboardView({
          viewMode: 'int',
          model: new Backbone.Model({ organizationId: organizationId, organization: organization, tasks: tasks })
        })
      );
      NProgress.done();
    })
    .fail(function(err) {
      NProgress.done();
    });
};
