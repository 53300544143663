var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "  <div class=\"side-nav-row\">\r\n    <div class=\"links-container\"></div>\r\n    <div class=\"section-container section-width-"
    + container.escapeExpression(((helper = (helper = helpers.contentWidth || (depth0 != null ? depth0.contentWidth : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"contentWidth","hash":{},"data":data}) : helper)))
    + "\">\r\n      <div class=\"buttons-container\"></div>\r\n      <div class=\"contents-container\"></div>\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.useNavigationButtons : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.useNavigationSavePrevButtons : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </div>\r\n  </div>\r\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "        <div class=\"navigation-container\">\r\n          <button class=\"btn btn-default previous-button collapse\">\r\n            <i class=\"fa fa-chevron-left\"></i>\r\n            Previous\r\n          </button>\r\n          <button class=\"btn btn-default next-button pull-right\">\r\n            Next\r\n            <i class=\"fa fa-chevron-right\"></i>\r\n          </button>\r\n        </div>\r\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "        <div class=\"navigation-container\">\r\n          <button class=\"btn btn-default previous-button collapse\">\r\n            <i class=\"fa fa-chevron-left\"></i>\r\n            Save & Previous\r\n          </button>\r\n          <button class=\"btn btn-default next-button pull-right\">\r\n            Save & Continue\r\n            <i class=\"fa fa-chevron-right\"></i>\r\n          </button>\r\n        </div>\r\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "  <div class=\"buttons-container\"></div>\r\n  <div class=\"contents-container\"></div>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "\r\n"
    + ((stack1 = helpers.unless.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.expanded : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(6, data, 0),"data":data})) != null ? stack1 : "");
},"useData":true});