/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');

var EmptySetView = require('common/views/EmptySetView');

var NonDepEntityFormOfficerOtherLicenseView = require('./NonDepEntityFormOfficerOtherLicenseView');

module.exports = Marionette.CollectionView.extend({
  className: 'nondep-entity-form-officer-other-license-collection-view',

  emptyView: EmptySetView,

  emptyViewOptions: {
    emptyText: 'No Other Associated Licenses Provided'
  },

  childView: NonDepEntityFormOfficerOtherLicenseView,

  childViewOptions: function(model, index) {
    // Pass the index to the collection view
    return {
      childIndex: index,
      viewMode: this.options.viewMode,
      isReadOnly: this.options.isReadOnly
    };
  },

  childEvents: {
    'remove:associated-license': 'onChildClickRemoveAssociatedLicense'
  },

  onBeforeShow: function() {
    if (this.options.isAmendment) {
      this.deletedAssociatedLicenses = [];
    }
  },

  onChildClickRemoveAssociatedLicense: function(childView) {
    // Keep track of deletions to display on internal amendment review page
    var deletedAssociatedLicense = childView.model;
    if (this.options.isAmendment) {
      var deletedAssociatedLicenseId = deletedAssociatedLicense.get('id');
      if (deletedAssociatedLicenseId) {
        deletedAssociatedLicense.set({ isDeleted: '1' });
        this.deletedAssociatedLicenses.push(deletedAssociatedLicense.toJSON());
      } else {
        deletedAssociatedLicense.set({ isDeleted: '0' });
      }
    }
    this.collection.remove(deletedAssociatedLicense);
  },

  getFormData: function() {
    var isAmendment = this.options.isAmendment;

    var deletedAssociatedLicenses = [];
    if (this.model) {
      deletedAssociatedLicenses = this.model.get('deletedAssociatedLicenses') || [];
    }

    var formData = this.children.reduce(function(memo, child) {
      if (child.getFormData && _.isFunction(child.getFormData)) {
        memo.push(child.getFormData());
      }
      return memo;
    }, []);

    if (isAmendment && this.deletedAssociatedLicenses.length) {
      formData = formData.concat(this.deletedAssociatedLicenses);
    }

    // Include prevsiously deleted books and records in formData (resubmissions)
    if (isAmendment && deletedAssociatedLicenses.length) {
      formData = formData.concat(deletedAssociatedLicenses);
    }

    return formData;
  }
});
