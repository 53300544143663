var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "      <span class=\"glyphicon glyphicon-remove\" aria-hidden=\"true\"></span> Deleted Books/Records Address\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isNew : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    return "      <span class=\"glyphicon glyphicon-plus\" aria-hidden=\"true\"></span> New Books/Records Address\r\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isUpdate : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    return "      <span class=\"glyphicon glyphicon-edit\" aria-hidden=\"true\"></span> Updated Books/Records Address\r\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "      Books/Records Address\r\n      ";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        <div class=\"col-sm-9 col-sm-offset-3\">\r\n          <div class=\"input-label red-text\"><b>Previously: </b>"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.oldBooksAndRecord : depth0)) != null ? stack1.companyName : stack1), depth0))
    + "</div>\r\n        </div>\r\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        <div class=\"col-sm-9 col-sm-offset-3\">\r\n          <div class=\"input-label red-text\"><b>Previously: </b>"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.oldBooksAndRecord : depth0)) != null ? stack1.firstName : stack1), depth0))
    + "</div>\r\n        </div>\r\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        <div class=\"col-sm-9 col-sm-offset-3\">\r\n          <div class=\"input-label red-text\"><b>Previously: </b>"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.oldBooksAndRecord : depth0)) != null ? stack1.lastName : stack1), depth0))
    + "</div>\r\n        </div>\r\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <div class=\"col-sm-6 col-sm-offset-6\">\r\n              <div class=\"input-label red-text\"><b>Previously: </b>"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.oldBooksAndRecord : depth0)) != null ? stack1.phone : stack1), depth0))
    + "</div>\r\n            </div>\r\n";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <div class=\"col-sm-6 col-sm-offset-6\">\r\n              <div class=\"input-label red-text\"><b>Previously: </b>"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.oldBooksAndRecord : depth0)) != null ? stack1.fax : stack1), depth0))
    + "</div>\r\n            </div>\r\n";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        <div class=\"col-sm-9 col-sm-offset-3\">\r\n          <div class=\"input-label red-text\"><b>Previously: </b>"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.oldBooksAndRecord : depth0)) != null ? stack1.email : stack1), depth0))
    + "</div>\r\n        </div>\r\n";
},"23":function(container,depth0,helpers,partials,data) {
    return "      <div class=\"row m-b-md\">\r\n        <div class=\"col-sm-9 col-sm-offset-3 -m-t-md\">\r\n          <div class=\"input-label red-text\"><b>Previously: </b>\r\n            <div class=\"old-address\"></div>\r\n          </div>\r\n        </div>\r\n      </div>\r\n";
},"25":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        <div class=\"col-sm-9 col-sm-offset-3\">\r\n          <div class=\"input-label red-text\"><b>Previously: </b>\r\n            <div class=\"pre-wrap\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.oldBooksAndRecord : depth0)) != null ? stack1.description : stack1), depth0))
    + "</div>\r\n          </div>\r\n        </div>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "\r\n<div class=\"panel-heading\" role=\"tab\" id=\"heading"
    + alias4(((helper = (helper = helpers.childIndex || (depth0 != null ? depth0.childIndex : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"childIndex","hash":{},"data":data}) : helper)))
    + "\">\r\n  <div class=\"panel-title\">\r\n    <a data-toggle=\"collapse\" data-parent=\"#accordion\" href=\"#collapse"
    + alias4(((helper = (helper = helpers.childIndex || (depth0 != null ? depth0.childIndex : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"childIndex","hash":{},"data":data}) : helper)))
    + "\" aria-expanded=\"true\"\r\n      aria-controls=\"collapse"
    + alias4(((helper = (helper = helpers.childIndex || (depth0 != null ? depth0.childIndex : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"childIndex","hash":{},"data":data}) : helper)))
    + "\">\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isDeleted : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "    </a>\r\n    <button class=\"btn btn-md btn-danger remove-books-and-records pull-right\">Remove</button>\r\n  </div>\r\n</div>\r\n<div id=\"collapse"
    + alias4(((helper = (helper = helpers.childIndex || (depth0 != null ? depth0.childIndex : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"childIndex","hash":{},"data":data}) : helper)))
    + "\" class=\"panel-collapse collapse in\" role=\"tabpanel\"\r\n  aria-labelledby=\"heading"
    + alias4(((helper = (helper = helpers.childIndex || (depth0 != null ? depth0.childIndex : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"childIndex","hash":{},"data":data}) : helper)))
    + "\">\r\n  <div class=\"panel-body\">\r\n    <form class=\"form-horizontal\">\r\n      <div class=\"form-group required\">\r\n        <label class=\"control-label col-sm-3\">Company Name</label>\r\n        <div class=\"col-sm-9\">\r\n          <input type=\"text\" name=\"companyName\" class=\"form-control\" placeholder=\"Company Name\"\r\n            data-parsley-required=\"true\" data-parsley-maxlength=\"500\" />\r\n        </div>\r\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.amendmentChanges : depth0)) != null ? stack1.companyName : stack1),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "      </div>\r\n      <div class=\"form-group\">\r\n        <label class=\"control-label col-sm-3\">First Name</label>\r\n        <div class=\"col-sm-9\">\r\n          <input type=\"text\" name=\"firstName\" class=\"form-control\" placeholder=\"First Name\"\r\n            data-parsley-maxlength=\"100\" />\r\n        </div>\r\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.amendmentChanges : depth0)) != null ? stack1.firstName : stack1),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "      </div>\r\n      <div class=\"form-group\">\r\n        <label class=\"control-label col-sm-3\">Last Name</label>\r\n        <div class=\"col-sm-9\">\r\n          <input type=\"text\" name=\"lastName\" class=\"form-control\" placeholder=\"Last Name\"\r\n            data-parsley-maxlength=\"100\" />\r\n        </div>\r\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.amendmentChanges : depth0)) != null ? stack1.lastName : stack1),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "      </div>\r\n      <div class=\"row\">\r\n        <div class=\"col-sm-6\">\r\n          <div class=\"form-group required\">\r\n            <label class=\"control-label col-sm-6\">Phone Number</label>\r\n            <div class=\"col-sm-6\">\r\n              <input type=\"tel\" name=\"phone\" class=\"form-control phone-format\" placeholder=\"Phone Number\"\r\n                data-parsley-required=\"true\" data-parsley-maxlength=\"20\" />\r\n            </div>\r\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.amendmentChanges : depth0)) != null ? stack1.phone : stack1),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "          </div>\r\n        </div>\r\n        <div class=\"col-sm-6\">\r\n          <div class=\"form-group\">\r\n            <label class=\"control-label col-sm-6\">Fax Number</label>\r\n            <div class=\"col-sm-6\">\r\n              <input type=\"tel\" name=\"fax\" class=\"form-control phone-format\" placeholder=\"Fax Number\"\r\n                data-parsley-maxlength=\"20\" />\r\n            </div>\r\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.amendmentChanges : depth0)) != null ? stack1.fax : stack1),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "          </div>\r\n        </div>\r\n      </div>\r\n      <div class=\"form-group\">\r\n        <label class=\"control-label col-sm-3\">Email</label>\r\n        <div class=\"col-sm-9\">\r\n          <input type=\"email\" name=\"email\" class=\"form-control\" placeholder=\"Email\" data-parsley-type=\"email\" />\r\n        </div>\r\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.amendmentChanges : depth0)) != null ? stack1.email : stack1),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "      </div>\r\n      <div class=\"address\"></div>\r\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.amendmentChanges : depth0)) != null ? stack1.address : stack1),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "      <div class=\"form-group\">\r\n        <label class=\"control-label col-sm-3\">Comments</label>\r\n        <div class=\"col-sm-9\">\r\n          <textarea class=\"form-control input-view\" rows=\"3\" name=\"description\" id=\"description"
    + alias4(((helper = (helper = helpers.childIndex || (depth0 != null ? depth0.childIndex : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"childIndex","hash":{},"data":data}) : helper)))
    + "\"\r\n            placeholder=\"Description of the records which are held at this location.\"></textarea>\r\n          <div class=\"pre-wrap\" id=\"description"
    + alias4(((helper = (helper = helpers.childIndex || (depth0 != null ? depth0.childIndex : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"childIndex","hash":{},"data":data}) : helper)))
    + "PrintView\"></div>\r\n        </div>\r\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.amendmentChanges : depth0)) != null ? stack1.description : stack1),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "      </div>\r\n    </form>\r\n  </div>\r\n</div>";
},"useData":true});