/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(data) {
  var defer = $.Deferred();

  $.soap({
    body: {
      GetNotes: {
        $xmlns: 'http://dobs.pa.gov/sc/note/bpm/1.0',
        ObjectID: data.objectId,
        ObjectTypeID: data.objectTypeId
      }
    },
    blueprintArrays: ['GetNotesResponse.SC_Note'],
    blueprint: {
      notes: [
        '$..SC_Note',
        {
          id: '$.ID',
          objectTypeId: '$.ObjectTypeID',
          objectId: '$.ObjectID',
          typeId: '$.TypeID',
          type: '$.Type',
          content: '$.Content',
          editDate: '$.EditDate',
          isDeleted: '$.IsDeleted',
          createdDate: '$.CreatedDate',
          createdBy: '$.CreatedBy',
          modifiedDate: '$.ModifiedDate',
          modifiedBy: '$.ModifiedBy'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      _.each(response.notes, function(note) {
        var rawEditDateTime = note.editDate;
        note.createdDate = moment(note.createdDate, moment.ISO_8601).format('MM/DD/YYYY hh:mm a');
        note.modifiedDate = moment(note.modifiedDate, moment.ISO_8601).format('MM/DD/YYYY hh:mm a');
        note.editDate = moment(rawEditDateTime, moment.ISO_8601).format('MM/DD/YYYY');
        note.editTime = moment(rawEditDateTime, moment.ISO_8601).format('LT');
        note.editDateTimeMoment = moment(rawEditDateTime, moment.ISO_8601);
        note.editDateTime = note.editDateTimeMoment.format('MM/DD/YYYY hh:mm a');
      });
      defer.resolve(response.notes);
    })
    .fail(function(err) {
      defer.reject(err);
    });

  return defer.promise();
};
