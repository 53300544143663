/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Ladda = require('ladda');
var Syphon = require('backbone.syphon');
var Radio = require('backbone.radio');

var momentUtil = require('common/util/momentUtil');
var BreadcrumbView = require('common/views/BreadcrumbView');

var DepFundFormView = require('./DepFundFormView');
var SubmitTrustFund = require('../services/SubmitTrustFund');

var tmplDepFundNewView = require('../templates/DepFundNewView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepFundNewView,

  className: 'dep-entity-new-fund-form-view container-fluid',

  ui: {
    breadcrumb: '.breadcrumb-container',
    fundFormContainer: '.new-fund-form-container',
    submit: '.btn-submit'
  },

  regions: {
    breadcrumb: '@ui.breadcrumb',
    fundFormContainer: '@ui.fundFormContainer'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeShow: function() {
    var entityIdentifiers = this.model.get('entityIdentifiers');

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#dep/dashboard',
            text: 'Home'
          },
          {
            link: '#dep/entity/' + entityIdentifiers.entityId + '/dashboard',
            text: entityIdentifiers.entityName
          },
          {
            text: 'New Collective Investment Fund'
          }
        ]
      })
    );

    this.fundForm = new DepFundFormView({
      model: new Backbone.Model({})
    });

    this.showChildView('fundFormContainer', this.fundForm);
  },

  onClickSubmit: function(e) {
    e.preventDefault();

    var laddaContext = Ladda.create(e.currentTarget);
    this.fundForm.validate().done(
      function() {
        laddaContext.start();

        var entityId = this.model.get('entityIdentifiers').entityId;

        var formData = Syphon.serialize(this);
        formData.entityId = entityId;

        formData.effectiveDate = momentUtil.formatStr(formData.effectiveDate, momentUtil.defaultOutboundDateConfig);
        formData.approvalDate = momentUtil.formatStr(formData.approvalDate, momentUtil.defaultOutboundDateConfig);
        formData.lastAmendmentDate = momentUtil.formatStr(
          formData.lastAmendmentDate,
          momentUtil.defaultOutboundDateConfig
        );
        formData.lastAmendmentApprovalDate = momentUtil.formatStr(
          formData.lastAmendmentApprovalDate,
          momentUtil.defaultOutboundDateConfig
        );
        formData.terminatedDate = momentUtil.formatStr(formData.terminatedDate, momentUtil.defaultOutboundDateConfig);
        formData.effectiveAmendDate = momentUtil.formatStr(
          formData.effectiveAmendDate,
          momentUtil.defaultOutboundDateConfig
        );

        SubmitTrustFund(formData)
          .done(function(fundId) {
            Radio.channel('navigate').trigger('show:int:dp:fund', entityId, fundId);
          })
          .fail(function(err) {
            laddaContext.stop();
          });
      }.bind(this)
    );
  }
});
