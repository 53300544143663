/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');

var GridView = require('psa-marionette/views/GridView');
var PageableCollection = require('psa-backbone/models/PageableCollection');
var UIUtil = require('psa-core/util/ui');

var DollarCell = require('common/backgrid/DollarCell');
var MultiButtonCell = require('common/backgrid/MultiButtonCell');
var MomentCell = require('common/backgrid/MomentCell');
var ConfirmationModalView = require('common/views/ConfirmationModalView');

var SubmitNonFINRAPaymentMemberDetail = require('../services/SubmitNonFINRAPaymentMemberDetail');

var tmplSecNonFINRAPaymentMemberDetailSectionView = require('../templates/SecNonFINRAPaymentMemberDetailSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplSecNonFINRAPaymentMemberDetailSectionView,

  className: 'sec-nonfinra-payment-member-detail-section-view',

  ui: {
    licenseGrid: '.license-payment-grid-container'
  },

  regions: {
    licenseGrid: '@ui.licenseGrid'
  },

  onBeforeShow: function() {
    this.licenseCollection = new PageableCollection(this.model.get('paymentMembers'), { pageSize: 25 });
    this.showChildView(
      'licenseGrid',
      new GridView({
        columns: [
          {
            name: 'paidDate',
            label: 'Payment Date',
            cell: MomentCell.MomentCell,
            formatter: MomentCell.MomentDateFormatter.extend({
              emptyValue: '--'
            }),
            sortValue: MomentCell.MomentSortValue,
            headerCell: 'custom',
            width: 15,
            editable: false
          },
          {
            name: 'type',
            label: 'Payment Type',
            cell: 'string',
            headerCell: 'custom',
            width: 20,
            editable: false
          },
          {
            name: 'regarding',
            label: 'Payment Regarding',
            cell: 'string',
            headerCell: 'custom',
            width: 30,
            editable: false
          },
          {
            name: 'amount',
            label: 'Payment Amount',
            cell: DollarCell,
            headerCell: 'custom',
            width: 15,
            editable: false
          },
          {
            name: '',
            label: '',
            cell: MultiButtonCell.extend({
              buttons: [
                {
                  name: '',
                  labelClass: 'fa fa-edit',
                  buttonClass: 'btn-primary btn-sm',
                  onClick: this.onClickEditPaymentMember.bind(this)
                },
                {
                  name: '',
                  labelClass: 'fa fa-trash',
                  buttonClass: 'btn-danger btn-sm',
                  onClick: this.onClickDeletePaymentMember.bind(this)
                }
              ]
            }),
            editable: false,
            sortable: false
          }
        ],
        emptyText: 'No Associated Non FINRA Payment Member Details',
        collection: this.licenseCollection
      })
    );
  },

  onClickEditPaymentMember: function(e, model) {
    var self = this;
    var modalViewPromise = Radio.channel('sc').request('build:license-nonfinra-payment-member-modal-view', {
      objectId: model.get('objectId'),
      id: model.get('id'),
      objectTypeId: self.options.objectTypeId
    });
    UIUtil.showModal({
      title: 'Edit Payment - Non FINRA Member Details',
      view: modalViewPromise
    }).done(function(editedPayment) {
      model.set(editedPayment);
    });
  },

  onClickDeletePaymentMember: function(e, model) {
    var self = this;
    var message = 'Are you sure you want to delete this payment?';
    UIUtil.showModal({
      title: 'Confirm Deleting Payment - Non FINRA Member Details',
      view: new ConfirmationModalView({
        confirmText: 'Delete Payment',
        model: new Backbone.Model({
          message: message,
          onConfirm: function() {
            var id = model.get('id');
            return SubmitNonFINRAPaymentMemberDetail({ id: id, isDeleted: '1' });
          },
          onConfirmDone: function() {
            self.licenseCollection.remove(model);
          }
        })
      })
    });
  }
});
