/**************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var _ = require('underscore');

var tmplComplaintForm = require('../templates/ComplaintForm.hbs');
var SubmitNewComplaint = require('../services/SubmitNewComplaint');
var SubmitNewComplaintFormAttachment = require('../services/SubmitNewComplaintFormAttachment');
var Syphon = require('backbone.syphon');
var Ladda = require('ladda');
var Radio = require('backbone.radio');
var ShowComplaintView = require('../commands/ShowComplaint');
//var fileInput = document.getElementById('formFiles');
var supportingFiles1 = {};
var supportingFiles2 = {};
var supportingFiles3 = {};
var supportingFiles4 = {};
var supportingFiles5 = {};

var isFilesReady = true;

module.exports = Marionette.LayoutView.extend({
  template: tmplComplaintForm,
  className: 'complaint-form-view',

  ui: {
    complaintForm: '.complaint-form',
    submit: '.submit',
    formFiles1: '.form-files-1',
    formFiles2: '.form-files-2',
    formFiles3: '.form-files-3',
    formFiles4: '.form-files-4',
    formFiles5: '.form-files-5'
  },

  regions: {
    complaintForm: '@ui.complaintForm'
  },

  events: {
    'click @ui.submit': 'onClickSubmit',
    'change @ui.formFiles1': 'onChangeFormFiles1',
    'change @ui.formFiles2': 'onChangeFormFiles2',
    'change @ui.formFiles3': 'onChangeFormFiles3',
    'change @ui.formFiles4': 'onChangeFormFiles4',
    'change @ui.formFiles5': 'onChangeFormFiles5'
  },

  onBeforeShow: function() {},

  validate: function() {
    return this.ui.complaintForm.parsley().whenValidate();
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    return formData;
  },

  onChangeFormFiles1: function(event) {
    supportingFiles1 = {};
    isFilesReady = false;
    var files = event.currentTarget.files;

    var filePromises = Object.entries(files).map(item => {
      return new Promise((resolve, reject) => {
        const [index, file] = item;
        const reader = new FileReader();
        reader.readAsBinaryString(file);

        reader.onload = function(event) {
          supportingFiles1['filename'] = file.name;
          supportingFiles1['documents'] = btoa(event.target.result);
          resolve();
        };

        reader.onerror = function() {
          console.log("couldn't read the file");
          reject();
        };
      });
    });

    Promise.all(filePromises)
      .then(() => {
        // if each file processed successfuly then set our state to true
        isFilesReady = true;
      })
      .catch(error => {
        console.log(error);
        console.log('something wrong happened');
      });
  },

  onChangeFormFiles2: function(event) {
    supportingFiles2 = {};
    isFilesReady = false;
    var files = event.currentTarget.files;

    const filePromises = Object.entries(files).map(item => {
      return new Promise((resolve, reject) => {
        const [index, file] = item;
        const reader = new FileReader();
        reader.readAsBinaryString(file);

        reader.onload = function(event) {
          supportingFiles2['filename'] = file.name;
          supportingFiles2['documents'] = btoa(event.target.result);
          resolve();
        };

        reader.onerror = function() {
          console.log("couldn't read the file");
          reject();
        };
      });
    });

    Promise.all(filePromises)
      .then(() => {
        // if each file processed successfuly then set our state to true
        isFilesReady = true;
      })
      .catch(error => {
        console.log(error);
        console.log('something wrong happened');
      });
  },

  onChangeFormFiles3: function(event) {
    supportingFiles3 = {};
    isFilesReady = false;
    var files = event.currentTarget.files;

    const filePromises = Object.entries(files).map(item => {
      return new Promise((resolve, reject) => {
        const [index, file] = item;
        const reader = new FileReader();
        reader.readAsBinaryString(file);

        reader.onload = function(event) {
          supportingFiles3['filename'] = file.name;
          supportingFiles3['documents'] = btoa(event.target.result);
          resolve();
        };

        reader.onerror = function() {
          console.log("couldn't read the file");
          reject();
        };
      });
    });

    Promise.all(filePromises)
      .then(() => {
        // if each file processed successfuly then set our state to true
        isFilesReady = true;
      })
      .catch(error => {
        console.log(error);
        console.log('something wrong happened');
      });
  },

  onChangeFormFiles4: function(event) {
    supportingFiles4 = {};
    isFilesReady = false;
    var files = event.currentTarget.files;

    const filePromises = Object.entries(files).map(item => {
      return new Promise((resolve, reject) => {
        const [index, file] = item;
        const reader = new FileReader();
        reader.readAsBinaryString(file);

        reader.onload = function(event) {
          supportingFiles4['filename'] = file.name;
          supportingFiles4['documents'] = btoa(event.target.result);
          resolve();
        };

        reader.onerror = function() {
          console.log("couldn't read the file");
          reject();
        };
      });
    });

    Promise.all(filePromises)
      .then(() => {
        // if each file processed successfuly then set our state to true
        isFilesReady = true;
      })
      .catch(error => {
        console.log(error);
        console.log('something wrong happened');
      });
  },

  onChangeFormFiles5: function(event) {
    supportingFiles5 = {};
    isFilesReady = false;
    var files = event.currentTarget.files;

    const filePromises = Object.entries(files).map(item => {
      return new Promise((resolve, reject) => {
        const [index, file] = item;
        const reader = new FileReader();
        reader.readAsBinaryString(file);

        reader.onload = function(event) {
          supportingFiles5['filename'] = file.name;
          supportingFiles5['documents'] = btoa(event.target.result);
          resolve();
        };

        reader.onerror = function() {
          console.log("couldn't read the file");
          reject();
        };
      });
    });

    Promise.all(filePromises)
      .then(() => {
        // if each file processed successfuly then set our state to true
        isFilesReady = true;
      })
      .catch(error => {
        console.log(error);
        console.log('something wrong happened');
      });
  },

  onClickSubmit: function(event) {
    event.preventDefault();
    var self = this;

    this.validate().done(function() {
      var l = Ladda.create(self.ui.submit[0]);
      l.start();
      var formData = self.getFormData();
      var supportingDocumentsToSubmit = [];
      if (typeof supportingFiles1['documents'] != 'undefined') {
        supportingDocumentsToSubmit.push({
          document: supportingFiles1['documents'],
          filename: supportingFiles1['filename']
        });
      }
      if (typeof supportingFiles2['documents'] != 'undefined') {
        supportingDocumentsToSubmit.push({
          document: supportingFiles2['documents'],
          filename: supportingFiles2['filename']
        });
      }
      if (typeof supportingFiles3['documents'] != 'undefined') {
        supportingDocumentsToSubmit.push({
          document: supportingFiles3['documents'],
          filename: supportingFiles3['filename']
        });
      }
      if (typeof supportingFiles4['documents'] != 'undefined') {
        supportingDocumentsToSubmit.push({
          document: supportingFiles4['documents'],
          filename: supportingFiles4['filename']
        });
      }
      if (typeof supportingFiles5['documents'] != 'undefined') {
        supportingDocumentsToSubmit.push({
          document: supportingFiles5['documents'],
          filename: supportingFiles5['filename']
        });
      }

      SubmitNewComplaint(formData)
        .done(function(response) {
          _.each(supportingDocumentsToSubmit, function(document) {
            SubmitNewComplaintFormAttachment(formData, response.id, document['filename'], document['document']);
          });
          ShowComplaintView(response.id);
        })
        .fail(function() {
          l.stop();
        });
    });
  }
});

