var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "              <option value=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</option>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return "\r\n<div class=\"page-title clearfix\">\r\n  <h1 class=\"pull-left m-n p-n\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.organizationIdentifier : depth0)) != null ? stack1.applicantLegalName : stack1), depth0))
    + "</h1>\r\n</div>\r\n<div class=\"header-bar-container\"></div>\r\n<div class=\"breadcrumb-container\"></div>\r\n\r\n<h2 class=\"m-t-n\">Manage User - "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.externalUser : depth0)) != null ? stack1.firstName : stack1), depth0))
    + " "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.externalUser : depth0)) != null ? stack1.lastName : stack1), depth0))
    + "</h2>\r\n\r\n<div class=\"row\">\r\n  <div class=\"col-sm-4 col-md-3\">\r\n    <h3 class=\"text-right\">User Details</h3>\r\n  </div>\r\n</div>\r\n<div clas=\"row\">\r\n  <div class=\"form-horizontal form-static user-data\">\r\n    <div class=\"row\">\r\n      <div class=\"form-group m-b-n\">\r\n        <label class=\"control-label col-sm-4 col-md-3\">First Name</label>\r\n        <div class=\"form-control-static col-sm-8 col-md-9\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.externalUser : depth0)) != null ? stack1.firstName : stack1), depth0))
    + "</div>\r\n      </div>\r\n    </div>\r\n    <div class=\"row\">\r\n      <div class=\"form-group m-b-n\">\r\n        <label class=\"control-label col-sm-4 col-md-3\">Last Name</label>\r\n        <div class=\"form-control-static col-sm-8 col-md-9\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.externalUser : depth0)) != null ? stack1.lastName : stack1), depth0))
    + "</div>\r\n      </div>\r\n    </div>\r\n    <div class=\"row\">\r\n      <div class=\"form-group m-b-n\">\r\n        <label class=\"control-label col-sm-4 col-md-3\">Email</label>\r\n        <div class=\"form-control-static col-sm-8 col-md-9\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.externalUser : depth0)) != null ? stack1.email : stack1), depth0))
    + "</div>\r\n      </div>\r\n    </div>\r\n  </div>\r\n</div>\r\n<div class=\"row\">\r\n  <div class=\"col-sm-8 col-md-6\">\r\n    <div class=\"row\">\r\n      <div class=\"col-sm-6\">\r\n        <h3 class=\"text-right\">Entity Privileges</h3>\r\n      </div>\r\n    </div>\r\n    <div class=\"row\">\r\n      <div class=\"col-sm-6 col-md-5 col-md-offset-1\">\r\n        <p class=\"text-right m-b-md\">\r\n          Within the entity "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.organizationIdentifier : depth0)) != null ? stack1.DBAName : stack1), depth0))
    + ", "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.externalUser : depth0)) != null ? stack1.firstName : stack1), depth0))
    + " "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.externalUser : depth0)) != null ? stack1.lastName : stack1), depth0))
    + " should have the following access level:\r\n        </p>\r\n      </div>\r\n    </div>\r\n    <div class=\"row\">\r\n      <div class=\"form-horizontal\">\r\n        <div class=\"form-group\">\r\n          <label class=\"control-label col-sm-6\">Access Level</label>\r\n          <div class=\"col-sm-4\">\r\n            <select name=\"userAccessLevel\" class=\"form-control\">\r\n"
    + ((stack1 = helpers.each.call(alias3,((stack1 = (depth0 != null ? depth0.externalUser : depth0)) != null ? stack1.userAccessLevels : stack1),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            </select>\r\n          </div>\r\n        </div>\r\n      </div>\r\n    </div>\r\n    <div>\r\n      <div class=\"col-sm-6 col-sm-offset-6\">\r\n        <button class=\"btn btn-primary ladda-button save-button\" data-style=\"expand-right\">\r\n          <span class=\"ladda-label\">Save</span>\r\n        </button>\r\n          <a href=\"#sec/organization/"
    + alias2(((helper = (helper = helpers.organizationId || (depth0 != null ? depth0.organizationId : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias3,{"name":"organizationId","hash":{},"data":data}) : helper)))
    + "/dashboard\" class=\"btn btn-default\" role=\"button\">Cancel</a>\r\n      </div>  \r\n    </div>\r\n  </div>\r\n  <div class=\"col-sm-4 col-md-6\">\r\n    <div class=\"row\">\r\n      <div class=\"col-sm-11 col-md-8\">\r\n        <h3 class=\"text-right\">Revoke User Access</h3>\r\n      </div>\r\n    </div>\r\n    <div class=\"row\">\r\n      <div class=\"col-sm-11 col-md-7 col-md-offset-1\">\r\n        <p class=\"text-right m-b-md\">\r\n          Click the following button to revoke "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.externalUser : depth0)) != null ? stack1.firstName : stack1), depth0))
    + " "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.externalUser : depth0)) != null ? stack1.lastName : stack1), depth0))
    + "'s access to the entity\r\n          "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.organizationIdentifier : depth0)) != null ? stack1.DBAName : stack1), depth0))
    + ". The user will\r\n          lose access to the entity, and will need to be re-invited in order to perform any future actions.\r\n        </p>\r\n      </div>\r\n    </div>\r\n    <div class=\"row\">\r\n      <div class=\"col-sm-11 col-md-8\">\r\n        <button class=\"btn btn-danger pull-right ladda-button revoke-button\" data-style=\"expand-right\">\r\n          <span class=\"ladda-label\">Revoke access for "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.externalUser : depth0)) != null ? stack1.firstName : stack1), depth0))
    + " "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.externalUser : depth0)) != null ? stack1.lastName : stack1), depth0))
    + "</span>\r\n        </button>\r\n      </div>\r\n    </div>\r\n  </div>\r\n</div>";
},"useData":true});