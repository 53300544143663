/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var moment = require('moment');
var Marionette = require('backbone.marionette');

var MomentCustomCell = require('psa-backgrid/MomentCustomCell');

var MomentFormatter = Marionette.Object.extend({
  emptyValue: '',

  fromRaw: function(rawValue) {
    var val = moment.isMoment(rawValue) ? rawValue : moment(rawValue, moment.ISO_8601);
    if (_.isNull(val) || !val.isValid() || !this.formatString) {
      return this.emptyValue;
    } else {
      return val.format(this.formatString);
    }
  }
});

var MomentDateFormatter = MomentFormatter.extend({
  formatString: 'MM/DD/YYYY'
});

var MomentDateTimeFormatter = MomentFormatter.extend({
  formatString: 'MM/DD/YYYY[&#x200b;] hh:mm[&nbsp;]a'
});

var MomentSortValue = function(rowModel, columnName) {
  var cellValue = rowModel.get(columnName);
  // if the cell value is a valid moment object, return the date as the
  // number of milliseconds since the Unix Epoch. Otherwise return -1
  // (always sort first)
  cellValue = moment.isMoment(cellValue) ? cellValue : moment(cellValue, moment.ISO_8601);
  if (cellValue.isValid()) {
    return cellValue.valueOf();
  } else {
    return -1;
  }
};

module.exports = {
  MomentCell: MomentCustomCell.MomentCustomCell,
  MomentCellEditor: MomentCustomCell.MomentCellEditor,
  MomentFormatter: MomentFormatter,
  MomentDateFormatter: MomentDateFormatter,
  MomentDateTimeFormatter: MomentDateTimeFormatter,
  MomentSortValue: MomentSortValue
};
