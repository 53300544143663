/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function() {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetAllExamInvInqLookups: {
        $xmlns: 'http://dobs.pa.gov/sc/lookup/bpm/1.0'
      }
    },
    blueprintArrays: [
      'GetAllExamInvInqLookupsResponse.ExamInvInqOffices.SC_LU_ExamInvInqOffice',
      'GetAllExamInvInqLookupsResponse.ExamInvInqViolationTypes.SC_LU_ExamInvInqViolationType',
      'GetAllExamInvInqLookupsResponse.ExamInvInqStaffFunctions.SC_LU_ExamInvInqStaffFunction',
      'GetAllExamInvInqLookupsResponse.ExamInvInqReferralOptions.SC_LU_ExamInvInqReferralOption'
    ],
    blueprint: {
      examInvInqOffices: [
        '$..SC_LU_ExamInvInqOffice',
        {
          id: '$.ID',
          office: '$.Office'
        }
      ],
      examInvInqViolationTypes: [
        '$..SC_LU_ExamInvInqViolationType',
        {
          id: '$.ID',
          violationType: '$.ViolationType'
        }
      ],
      examInvInqStaffFunctions: [
        '$..SC_LU_ExamInvInqStaffFunction',
        {
          id: '$.ID',
          staffFunction: '$.StaffFunction'
        }
      ],
      examInvInqReferralOptions: [
        '$..SC_LU_ExamInvInqReferralOption',
        {
          id: '$.ID',
          referralOption: '$.ReferralOption'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
