/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

/**
 * Takes the response of a SOAP service call, and removes any nil objects that
 * get returned which look like the following:
 *
 * <AMOUNT xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" null="true" xsi:nil="true" />
 *
 * {
 *  $xmlns:xsi: "http://www.w3.org/2001/XMLSchema-instance",
 *  $null: "true",
 *  $xsi:nil: "true"
 * }
 *
 * Takes an optional config object:
 *
 * {
 *  cleanUpTextKeys: bool,
 *  cleanUpEmptyKeys: bool,
 *  excludedKeys: [],
 *  cleanUpPrefixKeys: bool
 * }
 */
var cleanUpResponse = function(inputObj, config, currPath) {
  _.each(inputObj, function(value, key, obj) {
    if (config && _.contains(config.excludedKeys, currPath + '.' + key)) {
      return;
    } else if (_.isObject(value)) {
      if (config && config.cleanUpPrefixKeys && _.has(value, '__prefix')) {
        delete value.__prefix;
        if (_.isEmpty(value)) {
          obj[key] = null;
        }
      }
      if (value.$null === 'true' || value['$xsi:nil'] === 'true' || value.$nil === 'true') {
        obj[key] = null;
      } else if (config && config.cleanUpTextKeys && _.has(value, '__text')) {
        obj[key] = value.__text;
      } else if (
        config &&
        config.cleanUpEmptyKeys &&
        _.every(value, function(v, k) {
          return _.isString(k) && k.indexOf('$') === 0;
        })
      ) {
        obj[key] = null;
      } else {
        cleanUpResponse(value, config, currPath + '.' + key);
      }
    } else if (_.isArray(value)) {
      _.each(value, function(item) {
        cleanUpResponse(item, config, currPath + '.' + key);
      });
    }
  });
};

module.exports = cleanUpResponse;
