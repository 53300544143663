/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var moment = require('moment');

var PanelCellView = require('./PanelCellView');

require('./MomentPanelCellView.css');
var tmplMomentPanelCellView = require('./MomentPanelCellView.hbs');

module.exports = PanelCellView.extend({
  momentFormat: 'MM/DD/YYYY[&#x200b;] hh:mm[&nbsp;]a',

  template: tmplMomentPanelCellView,

  className: 'panel-cell-view moment-panel-cell-view',

  templateHelpers: function() {
    var cellValue = this.dataModel.get(this.model.get('name'));
    if (moment.isMoment(cellValue)) {
      cellValue = cellValue.format(this.momentFormat);
    }
    return {
      value: cellValue
    };
  }
});
