/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

module.exports = function(individualDetails) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      SubmitInternalNewIndividual: {
        $xmlns: 'http://dobs.pa.gov/nd/individual/bpm/1.0',
        FirstName: individualDetails.firstName,
        LastName: individualDetails.lastName,
        MiddleName: individualDetails.middleName,
        Title: individualDetails.title,
        Suffix: individualDetails.suffix,
        SSN: individualDetails.ssn,
        DOB: individualDetails.dob,
        HomePhoneNumber: individualDetails.homePhoneNumber,
        CellPhoneNumber: individualDetails.cellPhoneNumber,
        Email: individualDetails.email,
        Address: {
          AddressLine1: individualDetails.addressLine1,
          AddressLine2: individualDetails.addressLine2,
          AddressLine3: individualDetails.addressLine3,
          City: individualDetails.city,
          State: individualDetails.state,
          PostalCode: individualDetails.postalCode,
          Country: individualDetails.country,
          County: individualDetails.county
        },
        LicenseID: individualDetails.licenseId
      }
    },
    blueprint: {
      newIndividualId: '$..NewIndividualID'
    }
  })
    .done(function(response) {
      deferred.resolve(response.newIndividualId);
    })
    .fail(function() {
      deferred.reject();
    });

  return deferred.promise();
};
