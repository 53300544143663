var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression;

  return "\r\n<nav class=\"navbar navbar-default\">\r\n  <div class=\"container-fluid\">\r\n    <div class=\"navbar-header\">\r\n      <button type=\"button\" class=\"navbar-toggle collapsed\" data-toggle=\"collapse\" data-target=\"#bs-example-navbar-collapse-1\" aria-expanded=\"false\">\r\n        <span class=\"sr-only\">Toggle navigation</span>\r\n        <span class=\"icon-bar\"></span>\r\n        <span class=\"icon-bar\"></span>\r\n        <span class=\"icon-bar\"></span>\r\n      </button>\r\n      <div class=\"logo-text\">\r\n        <img class=\"logo\" src=\"img/logo.png\" alt=\"logo\" />\r\n        <p class=\"navbar-text navbar-brand\">"
    + alias1(((helper = (helper = helpers.brandText || (depth0 != null ? depth0.brandText : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"brandText","hash":{},"data":data}) : helper)))
    + "</p>\r\n      </div>\r\n    </div>\r\n    <div class=\"collapse navbar-collapse\" id=\"int-main-menu\">\r\n      <ul class=\"nav navbar-nav navbar-right\">\r\n        <li><p class=\"navbar-text welcome-text\">Welcome, "
    + alias1(container.lambda(((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.description : stack1), depth0))
    + "</p></li>\r\n        <li><a href=\"#\" class=\"logout\">Logout</a></li>\r\n      </ul>\r\n    </div>\r\n  </div>\r\n</nav>\r\n";
},"useData":true});