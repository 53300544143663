/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(applicationId) {
  var defer = $.Deferred();
  $.soap({
    body: {
      GetApplicationIndividuals: {
        $xmlns: 'http://dobs.pa.gov/nd/application/bpm/1.0',
        ApplicationID: applicationId
      }
    },
    blueprintArrays: ['GetApplicationIndividualsResponse.ND_Individual'],
    blueprint: {
      individuals: [
        '$..ND_Individual',
        {
          id: '$..ID',
          firstName: '$..FirstName',
          lastName: '$..LastName',
          title: '$..Title',
          homePhoneNumber: '$..HomePhoneNumber',
          cellPhoneNumber: '$..CellPhoneNumber',
          email: '$..Email',
          addressLine1: '$..AddressLine1',
          applicationId: '$..ApplicationID',
          locationAddressLine1: '$..LocationAddressLine1'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response.individuals);
      defer.resolve(response.individuals);
    })
    .fail(function(err) {
      defer.reject();
    });

  return defer.promise();
};
