/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(applicationId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetLicenseOfficeManagerChangeApplication: {
        $xmlns: 'http://dobs.pa.gov/nd/application/bpm/1.0',
        ApplicationID: applicationId
      }
    },
    blueprintArrays: ['GetLicenseOfficeManagerChangeApplicationResponse.ApplicationDocuments.ND_ApplicationDocument'],
    blueprint: {
      id: '$..ID',
      applicationId: '$..ApplicationID',
      individualId: '$..IndividualID',
      effectiveDate: '$..EffectiveChangeDate',
      entityId: '$..EntityID.__text',
      licenseId: '$..LicenseID.__text',
      certificationName: '$..CertificationName',
      isCertified: '$..IsCertified',
      officeManager: {
        firstName: '$..FirstName',
        middleName: '$..MiddleName',
        lastName: '$..LastName',
        title: '$..Title',
        suffix: '$..Suffix',
        ssn: '$..SSN',
        dob: '$..DOB',
        cellPhoneNumber: '$..CellPhoneNumber',
        homePhoneNumber: '$..HomePhoneNumber',
        email: '$..Email',
        addressId: '$..AddressID',
        addressLine1: '$..AddressLine1',
        addressLine2: '$..AddressLine2',
        addressLine3: '$..AddressLine3',
        city: '$..City',
        country: '$..Country',
        postalCode: '$..PostalCode',
        state: '$..State'
      },
      applicationDocuments: [
        '$..ND_ApplicationDocument',
        {
          fileId: '$.DocumentID',
          fileName: '$..Name'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      response.isCertified = response.isCertified === '1';
      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
