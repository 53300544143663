var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "Complaint - "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.complaint : depth0)) != null ? stack1.caseNumber : stack1), depth0));
},"3":function(container,depth0,helpers,partials,data) {
    return "New\n    Complaint";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "\n<div class=\"page-title clearfix\">\n  <h1 class=\"pull-left m-n p-n\">"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.complaint : depth0)) != null ? stack1.caseNumber : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "</h1>\n  <div class=\"page-title-buttons-container\">\n    <div class=\"cs-link-container\"></div>\n  </div>\n</div>\n<div class=\"breadcrumb-container\"></div>\n<div class=\"row m-b-md\">\n  <div class=\"col-lg-10 col-sm-12\">\n    <div class=\"complaint-dashboard-container\"></div>\n  </div>\n</div>";
},"useData":true});