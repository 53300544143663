/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(entityId) {
  var defer = $.Deferred();

  $.soap({
    body: {
      GetEntityExamsByEntity: {
        $xmlns: 'http://dobs.pa.gov/dp/exam/bpm/1.0',
        EntityID: entityId
      }
    },
    blueprintArrays: [
      'GetEntityExamsByEntityResponse.Exams.DP_EntityExam',
      'GetEntityExamsByEntityResponse.ExamAreas.DP_ExamArea',
      'GetEntityExamsByEntityResponse.ExamConductedBys.DP_ExamConductedBy'
    ],
    blueprint: {
      exams: [
        '$..DP_EntityExam',
        {
          id: '$.ID',
          entityId: '$.EntityID',
          statusId: '$.StatusID',
          examType: '$.ExamType',
          examScope: '$.ExamScope',
          scopeSubcategory: '$.ScopeSubcategory',
          leadAgency: '$.LeadAgency',
          asOfDate: '$.AsOfDate',
          TSPAsOfDate: '$.TSPAsOfDate',
          dataCenter: '$.DataCenter',
          ssRatingBSAID: '$.SSRatingBSAID',
          ssRatingBSA: '$.SSRatingBSA',
          ss: {
            C: '$.SSRatingC',
            A: '$.SSRatingA',
            M: '$.SSRatingM',
            E: '$.SSRatingE',
            L: '$.SSRatingL',
            S: '$.SSRatingS',
            Composite: '$.SSRatingComposite'
          },
          it: {
            A: '$.ITRatingA',
            M: '$.ITRatingM',
            D: '$.ITRatingD',
            S: '$.ITRatingS',
            Composite: '$.ITRatingComposite'
          },
          trust: {
            M: '$.TrustRatingM',
            O: '$.TrustRatingO',
            E: '$.TrustRatingE',
            C: '$.TrustRatingC',
            A: '$.TrustRatingA',
            Composite: '$.TrustRatingComposite'
          },
          compliance: {
            compositeRating: '$.ComplianceRatingComposite',
            craRating: '$.CRARating'
          },
          ursit: {
            ITAudit: '$.URSITRatingITAudit',
            management: '$.URSITRatingManagement',
            devAuq: '$.URSITRatingDevAcq',
            supportDelivery: '$.URSITRatingSupportDelivery',
            Composite: '$.URSITRatingComposite'
          }
        }
      ],
      examAreas: [
        '$..DP_ExamArea',
        {
          id: '$..ID',
          examId: '$..ExamID',
          examAreaId: '$..ExamAreaID',
          examArea: '$..ExamArea',
          asOfDate: '$..AsOfDate'
        }
      ],
      examConductedBys: [
        '$..DP_ExamConductedBy',
        {
          id: '$..ID',
          examId: '$..ExamID',
          conductedBy: '$..ConductedBy'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      response.ssTrustItExams = [];
      response.compCraExams = [];

      _.each(response.exams, function(exam) {
        if (
          _.some(exam.it, function(rating) {
            return !!rating;
          })
        ) {
          exam.itRatings = _.reduceRight(
            exam.it,
            function(ratings, rating) {
              if (!ratings.length) {
                return '/' + (rating || ' ');
              } else if (ratings.charAt(0) === '/') {
                return (rating || ' ') + ratings;
              }
              return (rating || ' ') + '-' + ratings;
            },
            ''
          );
        } else {
          exam.itRatings = '--';
        }

        if (
          _.some(exam.ss, function(rating) {
            return !!rating;
          })
        ) {
          exam.ssRatings = _.reduceRight(
            exam.ss,
            function(ratings, rating) {
              if (!ratings.length) {
                return '/' + (rating || ' ');
              } else if (ratings.charAt(0) === '/') {
                return (rating || ' ') + ratings;
              }
              return (rating || ' ') + '-' + ratings;
            },
            ''
          );
        } else {
          exam.ssRatings = '--';
        }
        if (
          _.some(exam.trust, function(rating) {
            return !!rating;
          })
        ) {
          exam.trustRatings = _.reduceRight(
            exam.trust,
            function(ratings, rating) {
              if (!ratings.length) {
                return '/' + (rating || ' ');
              } else if (ratings.charAt(0) === '/') {
                return (rating || ' ') + ratings;
              }
              return (rating || ' ') + '-' + ratings;
            },
            ''
          );
        } else {
          exam.trustRatings = '--';
        }
        if (
          _.some(exam.ursit, function(rating) {
            return !!rating;
          })
        ) {
          exam.ursitRatings = _.reduce(
            exam.ursit,
            function(ratings, rating) {
              if (!ratings.length) {
                return '/' + (rating || ' ');
              } else if (ratings.charAt(0) === '/') {
                return (rating || ' ') + ratings;
              }
              return (exam.ursit.ITAudit + '-' + exam.ursit.management + '-' + exam.ursit.devAuq + '-' + exam.ursit.supportDelivery + '/' + exam.ursit.Composite)
            },
            ''
          );
        } else {
          exam.ursitRatings = '--';
        }

        exam.examAreas = _.where(response.examAreas, { examId: exam.id });

        exam.conductedBy = _.reduce(
          response.examConductedBys,
          function(memo, conductedBy) {
            if (conductedBy.examId === exam.id) {
              memo.push({
                value: conductedBy.conductedBy
              });
            }
            return memo;
          },
          []
        );

        exam.compositeRating = exam.compliance.compositeRating || '--';
        exam.craRating = exam.compliance.craRating || '--';
        exam.scopeSubcategory = exam.scopeSubcategory || '--';

        var ssTrustItExamAreaIds = ['1', '2', '3', '11', '12', '21', '22'],
          compCraExamAreaIds = ['4', '5', '13'];

        var examAreaIds = _.pluck(exam.examAreas, 'examAreaId');
        if (_.intersection(ssTrustItExamAreaIds, examAreaIds).length) {
          response.ssTrustItExams.push(exam);
        }
        if (_.intersection(compCraExamAreaIds, examAreaIds).length) {
          response.compCraExams.push(exam);
        }

        exam.dataCenter = exam.dataCenter || '--';
      });

      defer.resolve(response);
    })
    .fail(function(err) {
      defer.reject(err);
    });
  return defer.promise();
};
