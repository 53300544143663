/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(investmentId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetPortfolioByInvestment: {
        $xmlns: 'http://dobs.pa.gov/cf/investment/bpm/1.0',
        RecordID: investmentId
      }
    },
    blueprintArrays: [
      'GetPortfolioByInvestmentResponse.CF_Portfolio',
      'GetPortfolioByInvestmentResponse.Classes.CF_PortfolioClass'
    ],
    blueprint: {
      portfolio: [
        '$.GetPortfolioByInvestmentResponse.CF_Portfolio',
        {
          id: '$..ID',
          recordId: '$..RecordID',
          portfolioName: '$..PortfolioName',
          portfolioDate: '$..PortfolioDate'
        }
      ],
      classes: [
        'GetPortfolioByInvestmentResponse.Classes.CF_PortfolioClass',
        {
          id: '$..ID',
          portfolioId: '$..PortfolioID',
          className: '$..ClassName'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      _.each(response.portfolio, function(p) {
        p.portfolioClasses = _.reduce(
          response.classes,
          function(memo, portfolioClass) {
            if (portfolioClass.portfolioId === p.id) {
              memo.push({ value: portfolioClass.className });
            }
            return memo;
          },
          []
        );
      });
      deferred.resolve(response.portfolio);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
