/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var moment = require('moment');

var momentUtil = require('common/util/momentUtil');
var toggleForm = require('common/util/toggleForm');
var modelGet = require('common/util/modelGet');

var tmplSecInvInqGeneralFormView = require('../templates/SecInvInqGeneralFormView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplSecInvInqGeneralFormView,

  className: 'sec-inv-inq-general-form-view',

  ui: {
    datePickerField: '.date-picker',
    status: '.status',
    violationSelect: '.violation-select',
    otherViolation: '.other-violation',
    openedDate: '.opened-date',
    upgradedDate: '.upgraded-date',
    closedDate: '.closed-date',
    isVictimsSenior: '.is-victims-senior',
    seniorVictimCount: '.senior-victim-count'
  },

  events: {
    'change @ui.violationSelect': 'onChangeViolationSelect',
    'change @ui.status': 'onChangeStatus',
    'change @ui.isVictimsSenior': 'onchangeIsVictimsSenior'
  },

  templateHelpers: function() {
    var examiners = modelGet(this.model, 'examiners', []);
    var primaryExaminer = modelGet(this.model, 'invInq.primaryExaminer');
    var primaryExaminerFirstName = modelGet(this.model, 'invInq.primaryExaminerFirstName');
    var primaryExaminerLastName = modelGet(this.model, 'invInq.primaryExaminerLastName');

    /*
     * Current examiner could no longer have examiner role, add current examiner
     * with internal user data if username does not exist in examiners array
     */
    var currentExaminerHasRole = _.find(examiners, function(cm) {
      return primaryExaminer === cm.userId;
    });

    if (primaryExaminer && !currentExaminerHasRole) {
      var currentExaminer = {
        userId: primaryExaminer,
        name:
          !primaryExaminerFirstName && !primaryExaminerLastName
            ? ''
            : primaryExaminerFirstName + ' ' + primaryExaminerLastName
      };
      examiners.splice(_.sortedIndex(examiners, currentExaminer, 'name'), 0, currentExaminer);
    }

    return {
      examiners: examiners
    };
  },

  onBeforeShow: function() {
    var invInq = this.model.get('invInq') || {};

    toggleForm(this.ui.seniorVictimCount, invInq.isVictimsSenior);

    var isClosed = invInq.statusId === '3';
    toggleForm(this.ui.closedDate, isClosed);

    var isUpgraded = invInq.statusId === '2';
    toggleForm(this.ui.upgradedDate, isUpgraded);

    var violations = _.pluck(invInq.violations, 'violationTypeId');
    toggleForm(this.ui.otherViolation, invInq.hasOtherViolation);

    Syphon.deserialize(this, invInq);

    if (!invInq.invInqId) {
      this.ui.openedDate.val(moment().format('MM/DD/YYYY'));
    }

    this.ui.datePickerField.datepicker();

    this.ui.violationSelect.select2({
      allowClear: true,
      multiple: true,
      data: this.model.get('examInvInqViolationTypes'),
      templateResult: function(violation) {
        var addressMarkup = '<span>' + violation.violationType + '</span>';
        return $(addressMarkup);
      },
      templateSelection: function(violation) {
        if (violation.violationType) {
          return $('<span>' + violation.violationType + '</span>');
        }
      },
      matcher: function(params, data) {
        // If there are no search terms, return all of the data
        if (!params.term || params.term.trim() === '') {
          return data;
        }
        var searchTerm = params.term.toLowerCase();
        var target = data.violationType.toLowerCase();
        if (target.indexOf(searchTerm) > -1) {
          return data;
        }
        // Return `null` if the term should not be displayed
        return null;
      }
    });

    this.ui.violationSelect.val(violations).trigger('change');
  },

  onChangeViolationSelect: function() {
    var hasOtherViolation = _.contains(this.ui.violationSelect.val(), '33');
    toggleForm(this.ui.otherViolation, hasOtherViolation);
  },

  onChangeStatus: function() {
    var isUpgraded = this.ui.status.val() === '2';
    toggleForm(this.ui.upgradedDate, isUpgraded);

    var isClosed = this.ui.status.val() === '3';
    toggleForm(this.ui.closedDate, isClosed);
  },

  onchangeIsVictimsSenior: function() {
    var isVictimsSenior = this.ui.isVictimsSenior.is(':checked');
    toggleForm(this.ui.seniorVictimCount, isVictimsSenior);
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);

    formData.isVictimsSenior = +formData.isVictimsSenior;
    formData.openedDate = momentUtil.formatStr(formData.openedDate, momentUtil.defaultOutboundDateConfig);
    formData.closedDate = momentUtil.formatStr(formData.closedDate, momentUtil.defaultOutboundDateConfig);
    formData.upgradedDate = momentUtil.formatStr(formData.upgradedDate, momentUtil.defaultOutboundDateConfig);

    return formData;
  }
});
