var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "\r\n<div class=\"panel-heading\" role=\"tab\" id=\"heading-"
    + alias4(((helper = (helper = helpers.modelId || (depth0 != null ? depth0.modelId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"modelId","hash":{},"data":data}) : helper)))
    + "\">\r\n  <div class=\"panel-title\">\r\n    <a data-toggle=\"collapse\" data-parent=\"#accordion\" href=\"#collapse-"
    + alias4(((helper = (helper = helpers.modelId || (depth0 != null ? depth0.modelId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"modelId","hash":{},"data":data}) : helper)))
    + "\" aria-expanded=\"true\"\r\n      aria-controls=\"collapse-"
    + alias4(((helper = (helper = helpers.modelId || (depth0 != null ? depth0.modelId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"modelId","hash":{},"data":data}) : helper)))
    + "\">\r\n      Applicant\r\n    </a>\r\n    <button class=\"btn btn-danger btn-md remove-applicant pull-right\">Remove</button>\r\n  </div>\r\n</div>\r\n<div id=\"collapse-"
    + alias4(((helper = (helper = helpers.modelId || (depth0 != null ? depth0.modelId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"modelId","hash":{},"data":data}) : helper)))
    + "\" class=\"panel-collapse collapse in\" role=\"tabpanel\"\r\n  aria-labelledby=\"heading-"
    + alias4(((helper = (helper = helpers.modelId || (depth0 != null ? depth0.modelId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"modelId","hash":{},"data":data}) : helper)))
    + "\">\r\n  <div class=\"panel-body\">\r\n    <form>\r\n      <div class=\"row\">\r\n        <div class=\"col-md-6 col-sm-12\">\r\n          <div class=\"form-group required\">\r\n            <label class=\"control-label\">Name</label>\r\n            <input type=\"text\" name=\"name\" class=\"form-control\" placeholder=\"Name\" data-parsley-maxlength=\"200\"\r\n              data-parsley-required=\"true\" />\r\n          </div>\r\n        </div>\r\n        <div class=\"col-md-6 col-sm-12\">\r\n          <div class=\"form-group\">\r\n            <label class=\"control-label\">Business</label>\r\n            <input type=\"text\" name=\"business\" class=\"form-control\" placeholder=\"Business\" data-parsley-maxlength=\"500\"/>\r\n          </div>\r\n        </div>\r\n      </div>\r\n      <div class=\"address-container\"></div>\r\n      <div class=\"row\">\r\n        <div class=\"col-md-6 col-sm-12\">\r\n          <div class=\"form-group required\">\r\n            <label class=\"control-label\">Phone</label>\r\n            <input type=\"text\" name=\"phone\" class=\"form-control\" placeholder=\"Phone\" data-parsley-maxlength=\"100\"\r\n              data-parsley-required=\"true\" />\r\n          </div>\r\n        </div>\r\n        <div class=\"col-md-6 col-sm-12\">\r\n          <div class=\"form-group required\">\r\n            <label class=\"control-label\">Email</label>\r\n            <input type=\"text\" name=\"email\" class=\"form-control\" placeholder=\"Email\" data-parsley-maxlength=\"200\"\r\n              data-parsley-required=\"true\" data-parsley-type=\"email\" />\r\n          </div>\r\n        </div>\r\n      </div>\r\n    </form>\r\n  </div>\r\n</div>";
},"useData":true});