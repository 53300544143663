/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(data) {
  var defer = $.Deferred();

  $.soap({
    body: {
      GetExamInvInqRelatedSubject: {
        $xmlns: 'http://dobs.pa.gov/sc/examInvInq/bpm/1.0',
        ObjectID: data.objectId,
        ObjectTypeID: data.objectTypeId
      }
    },
    blueprintArrays: ['GetExamInvInqRelatedSubjectResponse.SC_ExamInvInqRelatedSubject'],
    blueprint: {
      relatedSubjects: [
        '$..SC_ExamInvInqRelatedSubject',
        {
          id: '$..ID',
          objectId: '$..ObjectID',
          subjectObjectTypeId: '$..SubjectObjectTypeID',
          subjectObjectId: '$..SubjectObjectID',
          organizationCRDNumber: '$..OrganizationCRDNumber',
          dbaName: '$..DBAName',
          individualCRDNumber: '$..IndividualCRDNumber',
          firstName: '$..FirstName',
          lastName: '$..LastName',
          middleInitial: '$..MiddleInitial'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      response.organizations = [];
      response.individuals = [];

      _.each(response.relatedSubjects, function(relatedSubject) {
        if (relatedSubject.subjectObjectTypeId === 'ORGANIZATION') {
          relatedSubject.crdNumber = relatedSubject.organizationCRDNumber;
          response.organizations.push(relatedSubject);
        } else if (relatedSubject.subjectObjectTypeId === 'INDIVIDUAL') {
          relatedSubject.crdNumber = relatedSubject.individualCRDNumber;
          relatedSubject.middleInitial = relatedSubject.middleInitial || '--';
          response.individuals.push(relatedSubject);
        }
      });
      defer.resolve(response);
    })
    .fail(function(err) {
      defer.reject(err);
    });

  return defer.promise();
};
