var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "\r\n<h1 class=\"page-title\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.task : depth0)) != null ? stack1.taskType : stack1)) != null ? stack1.description : stack1), depth0))
    + "</h1>\r\n<div class=\"header-bar\"></div>\r\n<div class=\"breadcrumb-container\"></div>\r\n\r\n<form class=\"form-horizontal followup-task-form\">\r\n  <div class=\"form-group\">\r\n    <label class=\"control-label col-md-3\">Examiner Feedback</label>\r\n    <div class=\"col-lg-7 col-md-9\">\r\n      <p class=\"form-control-static\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.exam : depth0)) != null ? stack1.reviewExaminerEntityFeedback : stack1), depth0))
    + "</p>\r\n    </div>\r\n  </div>\r\n  <div class=\"form-group\">\r\n    <label class=\"control-label col-md-3\">Examiner Supporting Documents</label>\r\n    <div class=\"col-lg-7 col-md-9\">\r\n      <div class=\"examiner-supporting-documents-container\"></div>\r\n      <div class=\"examiner-supporting-documents-empty-message collapse\">\r\n        <span class=\"text-muted\">No Attached Supporting Documents</span>\r\n      </div>\r\n    </div>\r\n  </div>\r\n  <div class=\"form-group required\">\r\n    <label class=\"control-label col-md-3\">Entity Response</label>\r\n    <div class=\"col-lg-7 col-md-9\">\r\n      <textarea class=\"form-control\" name=\"entityFeedbackResponse\" data-parsley-required=\"true\" rows=\"12\"\r\n        placeholder=\"Provide feedback here\"></textarea>\r\n    </div>\r\n  </div>\r\n  <div class=\"form-group\">\r\n    <label class=\"control-label col-md-3\">Entity Documents</label>\r\n    <div class=\"col-lg-7 col-md-9\">\r\n      <div class=\"entity-documents-container\"></div>\r\n    </div>\r\n  </div>\r\n</form>\r\n<div class=\"row bottom-spacer\">\r\n  <div class=\"col-md-3 col-md-offset-3\">\r\n    <a href=\"#ext/nondep/dashboard\" class=\"btn btn-default\" role=\"button\">Cancel</a>\r\n    <button class=\"btn btn-primary submit ladda-button\" data-style=\"expand-right\" type=\"button\">\r\n      <span class=\"ladda-label\">Submit</span>\r\n    </button>\r\n  </div>\r\n</div>";
},"useData":true});