/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var UIUtil = require('psa-core/util/ui');

var modelGet = require('common/util/modelGet');
var parsleyUtil = require('common/util/parsleyUtil');
var toggleForm = require('common/util/toggleForm');

var AddressDisplayView = require('common/views/AddressDisplayView');
var AddressView = require('common/views/AddressView');

var DepEntitySearchWithCategoryModalView = require('../../../entity-dashboard/views/DepEntitySearchWithCategoryModalView');

var tmplDepBankAcquisitionMergerApplicationInfoSectionView = require('./DepBankAcquisitionMergerApplicationInfoSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepBankAcquisitionMergerApplicationInfoSectionView,

  className: 'dep-bank-acquisition-merger-appplication-info-doc-section-view',

  behaviors: {
    ReadOnlyFormBehavior: {},
    RadioGroupToggleBehavior: {
      radios: [
        {
          target: '.address-col',
          radioGroup: '[name="isSameMailingAddress"]',
          valueToShow: '0'
        },
        {
          target: '.acquiring-hc',
          radioGroup: 'input[name="isHoldingCompany"]',
          valueToShow: '1'
        },
        {
          target: '.target-hc',
          radioGroup: 'input[name="isApplicable"]',
          valueToShow: '1'
        }
      ]
    }
  },

  ui: {
    appInfoForm: '.app-info-form',
    applicantAddressDisplay: '.applicant-address-display',
    acquiringAddress: '.acquiring-address',
    filingTypeCheckbox: '.filing-type-checkbox',
    filingTypeCheckboxErrorContainer: '.filing-type-checkbox-error-container',
    targetSearchButton: '.btn-target-search',
    targetAddress: '.target-address',
    targetHCAddress: '.target-hc-address',
    targetName: '.target-name',
    targetPhone: '.target-phone',
    federalReseveBankSelect: '.federal-reserve-bank-select',
    otherFederalReserveBank: '.other-fed-reserve-bank',
    stateCharterSelect: '.state-charter-select',
    charterTypeSelectContainer: '.charter-type-select-container',
    stateSelectContainer: '.state-select-container',
    isTrustPowersRadioContainer: '.is-trust-powers-radio-container',
    isHoldingCompany: 'input[name="isHoldingCompany"]'
  },

  regions: {
    applicantAddressDisplay: '@ui.applicantAddressDisplay',
    acquiringAddress: '@ui.acquiringAddress',
    targetHCAddress: '@ui.targetHCAddress',
    targetAddress: '@ui.targetAddress'
  },

  events: {
    'click @ui.targetSearchButton': 'onClickTargetSearchButton',
    'change @ui.stateCharterSelect': 'onChangeStateCharterSelect',
    'change @ui.federalReseveBankSelect': 'onChangeFederalReseveBankSelect'
  },

  templateHelpers: function() {
    return {
      isReadOnly: this.options.isReadOnly,
      isHC: modelGet(this.model, 'entityIdentifiers.entityTypeId') === '2'
    };
  },

  onBeforeShow: function() {
    var filingContent = this.model.get('filingContent') || {};
    var entityIdentifiers = this.model.get('entityIdentifiers') || {};
    var targetAddress = filingContent.targetAddress || {};
    var acquiringAddress = filingContent.acquiringAddress || {};
    var targetHCAddress = filingContent.targetHCAddress || {};

    var isHC = modelGet(this.model, 'entityIdentifiers.entityTypeId') === '2';
    if (!isHC) {
      this.showChildView(
        'applicantAddressDisplay',
        new AddressDisplayView({ model: new Backbone.Model(entityIdentifiers.ppob) })
      );
    }

    this.showChildView(
      'acquiringAddress',
      new AddressDisplayView({ model: new Backbone.Model(entityIdentifiers.acquiringAddress) })
    );

    this.targetHCAddress = new AddressView({
      model: new Backbone.Model(targetHCAddress),
      objectName: 'targetHCAddress',
      horizontal: false,
      isRequired: true,
      startingCountry: modelGet(this.model, 'filingContent.targetHCAddress.country'),
      startingPostalCode: modelGet(this.model, 'filingContent.targetHCAddress.postalCode'),
      startingCounty: modelGet(this.model, 'filingContent.targetHCAddress.county')
    });
    this.showChildView('targetHCAddress', this.targetHCAddress);


    this.acquiringAddress = new AddressView({
      model: new Backbone.Model(acquiringAddress),
      objectName: 'acquiringAddress',
      horizontal: false,
      isRequired: true,
      startingCountry: modelGet(this.model, 'filingContent.acquiringAddress.country'),
      startingPostalCode: modelGet(this.model, 'filingContent.acquiringAddress.postalCode'),
      startingCounty: modelGet(this.model, 'filingContent.acquiringAddress.county')
    });
    this.showChildView('acquiringAddress', this.acquiringAddress);

    this.targetAddress = new AddressView({
      model: new Backbone.Model(targetAddress),
      objectName: 'targetAddress',
      horizontal: false,
      isRequired: true,
      startingCountry: modelGet(this.model, 'filingContent.targetAddress.country'),
      startingPostalCode: modelGet(this.model, 'filingContent.targetAddress.postalCode'),
      startingCounty: modelGet(this.model, 'filingContent.targetAddress.county')
    });
    this.showChildView('targetAddress', this.targetAddress);

    Syphon.deserialize(this, filingContent);

    if (isHC) {
      this.onChangeFederalReseveBankSelect();
    }

    this.onChangeStateCharterSelect();

    if (!filingContent.isHoldingCompany) {
      this.ui.isHoldingCompany.filter('[value="0"]').prop('checked', true);
    }
  },

  onClickTargetSearchButton: function(e) {
    e.preventDefault();
    var self = this;

    // If HC, only return HCs in search. If any other type of bank, return all banks excluding HCs
    var bankTypeIds = _.pluck(this.model.get('bankTypes'), 'id');
    var isHC = modelGet(this.model, 'entityIdentifiers.entityTypeId') === '2';
    var filterEntityTypeIds = isHC ? ['2'] : _.without(bankTypeIds, '2');

    UIUtil.showModal({
      title: 'Search for an Institution',
      dialogClasses: 'modal-dialog-entity-search-modal-view',
      view: new DepEntitySearchWithCategoryModalView({
        model: this.model,
        categoryId: '1', // Searching for banks
        filterEntityTypeIds: filterEntityTypeIds
      })
    }).done(function(entity) {
      if (entity) {
        self.ui.targetName.val(entity.entityName);
        self.ui.targetPhone.val(entity.ppobPhone);
        var targetAddress = entity.entityAddress;
        self.targetAddress.setAddress(targetAddress);
      }
    });
  },

  onChangeStateCharterSelect: function() {
    var isPAStateCharter = this.ui.stateCharterSelect.val() === '1';
    var isOtherStateCharter = this.ui.stateCharterSelect.val() === '2';
    var isNationalCharter = this.ui.stateCharterSelect.val() === '3';

    toggleForm(this.ui.charterTypeSelectContainer, isPAStateCharter);
    toggleForm(this.ui.stateSelectContainer, isOtherStateCharter);
    toggleForm(this.ui.isTrustPowersRadioContainer, isOtherStateCharter || isNationalCharter);
  },

  onChangeFederalReseveBankSelect: function() {
    var isOtherFedRes = this.ui.federalReseveBankSelect.val() === '3';
    toggleForm(this.ui.otherFederalReserveBank, isOtherFedRes);
  },

  validate: function(config) {
    this.ui.appInfoForm.parsley().reset();
    this.ui.filingTypeCheckboxErrorContainer.hide();

    var defer = $.Deferred();

    if (config.type === 'save') {
      // Turn off required field validation for this form
      parsleyUtil.disableRequiredValidation(this.ui.appInfoForm);
    } else {
      var filingTypes = this.ui.filingTypeCheckbox.filter(':checked') || [];

      if (filingTypes.length < 1) {
        this.ui.filingTypeCheckbox.addClass('has-error');
        this.ui.filingTypeCheckboxErrorContainer.show();
        defer.reject();
      } else {
        defer.resolve();
      }

      var validationPromises = [this.ui.appInfoForm.parsley().whenValidate(), defer.promise()];
      // Turn on required field validation for this form
      parsleyUtil.enableRequiredValidation(this.ui.appInfoForm);
    }
    return $.when.apply($, validationPromises);
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);

    var checkboxes = ['isTrustMerger', 'isSavingsBankMerger'];
    _.each(checkboxes, function(attr) {
      formData[attr] = +formData[attr];
    });

    return formData;
  }
});
