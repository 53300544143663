/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');

var AppCardCollectionView = require('../../views/AppCardCollectionView');

require('../styles/ExternalApplicationSelectionView.css');
var tmplExternalApplicationSelectionView = require('../templates/ExternalApplicationSelectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplExternalApplicationSelectionView,

  className: 'external-application-selection-view container-fluid',

  ui: {
    appCardContainer: '.app-card-container'
  },

  regions: {
    appCardContainer: '@ui.appCardContainer'
  },

  onBeforeShow: function() {
    window.scrollTo(0, 0);
    this.showChildView(
      'appCardContainer',
      new AppCardCollectionView({
        collection: new Backbone.Collection([
          {
            image: 'img/checkbook.jpg',
            name: 'Non-Depository',
            description:
              'Licensing, examination, and compliance management of licensees and registrants in Mortgage, Automobile ' +
              'Finance, Consumer Loans, Credit Services, Debt Management/Settlement, Check Cashers, Pawnbrokers, Money ' +
              'Transmitters, etc. industries.',
            link: '#ext/nondep/dashboard'
          },
          {
            // https://pixabay.com/photos/atm-withdraw-cash-map-ec-card-1524870/
            image: 'img/atm.jpg',
            name: 'Depository',
            description: 'Banks, Credit Unions, and Trust Companies.',
            link: '#ext/dep/dashboard'
          },
          {
            image: 'img/stock-exchange.jpg',
            name: 'Securities',
            description:
              'Licensing, management, and compliance enforcement of broker-dealers, agents, investment advisers and representatives.',
            link: '#ext/sec/dashboard'
          },
          {
            // https://pixabay.com/photos/stock-exchange-trading-floor-738671/
            image: 'img/stock-graph.jpg',
            name: 'Corporation Finance',
            description: 'Submit Security Offerings and Filing Fees.',
            link: '#ext/corpfin/dashboard'
          }
        ])
      })
    );
  }
});
