/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var parsleyUtil = require('common/util/parsleyUtil');
var makeUploadCollection = require('common/util/makeUploadCollection');

var CommentsDocumentView = require('common/views/CommentsDocumentView');

var tmplDepNewBankCharterFeesExpensesSectionView = require('./DepNewBankCharterFeesExpensesSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepNewBankCharterFeesExpensesSectionView,

  className: 'dep-new-bank-charter-fees-expenses-section-view',

  behaviors: {
    ReadOnlyFormBehavior: {}
  },

  ui: {
    documentForm: '.document-form',
    feesAndExpensesContainer: '.fees-expenses-container'
  },

  regions: {
    feesAndExpensesContainer: '@ui.feesAndExpensesContainer'
  },

  templateHelpers: function() {
    return {
      isReadOnly: this.options.isReadOnly
    };
  },

  onBeforeShow: function() {
    var filingContent = this.model.get('filingContent') || {};
    var feesAndExpenses = filingContent.feesAndExpenses || {};

    Syphon.deserialize(this, this.model.get('filingContent'));

    this.feesAndExpenses = new CommentsDocumentView({
      isReadOnly: this.options.isReadOnly,
      allowMultipleFiles: false,
      isRequired: true,
      fileUploadLabel: 'Fees and Expenses',
      commentsLabel: 'Fees & Expenses Comments',
      commentsName: 'feesExpensesComments',
      collection: makeUploadCollection(feesAndExpenses.file),
      model: new Backbone.Model({ feesExpensesComments: feesAndExpenses.feesExpensesComments }),
      documentTypeId: 20001
    });
    this.showChildView('feesAndExpensesContainer', this.feesAndExpenses);
  },

  validate: function(config) {
    this.ui.documentForm.parsley().reset();
    this.feesAndExpenses.removeValidationErrors();

    if (config.type === 'save') {
      // Turn off required field validation for this form
      parsleyUtil.disableRequiredValidation(this.ui.documentForm);
    } else {
      var validationPromises = [this.ui.documentForm.parsley().whenValidate(), this.feesAndExpenses.validate()];

      // Turn on required field validation for this form
      parsleyUtil.enableRequiredValidation(this.ui.documentForm);
    }
    return $.when.apply($, validationPromises);
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    formData.docId = this.feesAndExpenses.getFirstFileId();
    return formData;
  }
});
