var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "\r\n<div class=\"row date-row sub-form-vertical\">\r\n  <div class=\"col-sm-6\">\r\n    <div class=\"form-group required\">\r\n      <label class=\"control-label\">207J Date</label>\r\n      <div class=\"input-group\">\r\n        <input type=\"text\" name=\"reg207JDate\" class=\"form-control datepicker date\" placeholder=\"207J Date\"\r\n          data-parsley-required=\"true\" data-parsley-date=\"\" data-parsley-trigger-after-failure=\"changeDate\"\r\n          data-parsley-errors-container=\"#dateError"
    + alias4(((helper = (helper = helpers.childIndex || (depth0 != null ? depth0.childIndex : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"childIndex","hash":{},"data":data}) : helper)))
    + "\" />\r\n        <div class=\"input-group-addon\">\r\n          <i class=\"fa fa-calendar\"></i>\r\n        </div>\r\n      </div>\r\n      <div id=\"dateError"
    + alias4(((helper = (helper = helpers.childIndex || (depth0 != null ? depth0.childIndex : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"childIndex","hash":{},"data":data}) : helper)))
    + "\"></div>\r\n    </div>\r\n  </div>\r\n  <div class=\"col-sm-6 m-b-n remove-container\">\r\n    <button class=\"btn btn-danger btn-sm remove-date\">\r\n      <i class=\"fa fa-times\" aria-hidden=\"true\"></i>\r\n    </button>\r\n  </div>\r\n</div>";
},"useData":true});