/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var NProgress = require('nprogress');

var NavUtils = require('psa-core/util/nav');

var Scaffold = require('common/layout/Scaffold');

var GetEntityIdentifiers = require('../../common/services/GetEntityIdentifiers');
var GetAllOfficerLookups = require('../../common/services/GetAllOfficerLookups');
var GetOfficersByEntity = require('../../common/services/GetOfficersByEntity');
var GetOfficer = require('../services/GetEntityOfficer');
var DepOfficerEditView = require('../views/DepOfficerEditView');

module.exports = function(entityId, officerId) {
  NProgress.start();
  NProgress.set(0.6);

  $.when(GetEntityIdentifiers(entityId), GetOfficer(officerId), GetOfficersByEntity(entityId))
    .done(function(entityIdentifiers, officer, entityofficers) {
      NProgress.set(0.7);
      GetAllOfficerLookups(entityIdentifiers.categoryId)
        .done(function(lookups) {
          NavUtils.navigate('dep/entity/' + entityId + '/officer/' + officerId + '/edit');
          Scaffold.content.show(
            new DepOfficerEditView({
              model: new Backbone.Model({
                entityIdentifiers: entityIdentifiers,
                entityofficers: entityofficers,
                officer: officer,
                officerDirectorStatus: lookups.officerDirectorStatus,
                officerType: lookups.officerType
              })
            })
          );
          NProgress.done();
        })
        .fail(function(err) {
          NProgress.done();
        });
    })
    .fail(function(err) {
      NProgress.done();
    });
};
