/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(irregularityId) {
  var defer = $.Deferred();

  $.soap({
    body: {
      GetIrregularity: {
        $xmlns: 'http://dobs.pa.gov/dp/irregularity/bpm/1.0',
        IrregularityID: irregularityId
      }
    },
    blueprint: {
      entityId: '$..EntityID',
      csFolderId: '$..CSFolderID',
      eventDate: '$..EventDate',
      categoryId: '$..CategoryID',
      subcategoryId: '$..SubCategoryID',
      categoryOtherDetails: '$..CategoryOtherDetails',
      firstName: '$..FirstName',
      middleName: '$..MiddleName',
      lastName: '$..LastName',
      dob: '$..DOB',
      addressId: '$..AddressID',
      relationToFinancialInstitution: '$..RelationToFinancialInstitution',
      isLawReferred: '$..IsLawReferred',
      isFedAction: '$..IsFedAction',
      isMonetaryPenalty: '$..IsMonetaryPenalty',
      isBanned: '$..IsBanned',
      category: '$..Category',
      subcategory: '$..SubCategory',
      comments: '$..Comments',
      isReviewedByExaminer: '$..IsReviewedByExaminer',
      isIncidentReportCompleted: '$..IsIncidentReportCompleted',
      address: {
        addressLine1: '$..AddressLine1',
        addressLine2: '$..AddressLine2',
        addressLine3: '$..AddressLine3',
        county: '$..County',
        city: '$..City',
        state: '$..State',
        postalCode: '$..PostalCode',
        country: '$..Country'
      }
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      if (response.eventDate) {
        response.eventDate = moment(response.eventDate, 'YYYY-MM-DD').format('MM/DD/YYYY');
      }
      if (response.dob) {
        response.dob = moment(response.dob, 'YYYY-MM-DD').format('MM/DD/YYYY');
      }
      defer.resolve(response);
    })
    .fail(function(err) {
      defer.reject(err);
    });

  return defer.promise();
};
