var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "        <div class=\"new-address-container form-control-static col-sm-9\"></div>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        <div class=\"col-sm-9\">\r\n          <select name=\"newLocationId\" class=\"form-control location-select\" data-placeholder=\"--\"\r\n            data-parsley-required=\"true\" data-parsley-not-equal=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.licenseDashboard : depth0)) != null ? stack1.licenseLocationID : stack1), depth0))
    + "\"\r\n            data-parsley-trigger-after-failure=\"change.select2\"\r\n            data-parsley-not-equal-message=\"License is currently assosciated with the selected location. Please choose a different location.\">\r\n          </select>\r\n        </div> ";
},"5":function(container,depth0,helpers,partials,data) {
    return "    <form class=\"affirmation-form\">\r\n      <div class=\"form-group required\">\r\n        <label class=\"control-label col-sm-3\">Affirmation</label>\r\n        <label class=\"control-label font-weight-normal text-left col-sm-9\">\r\n          <input type=\"checkbox\" name=\"isCertified\" value=\"1\" data-parsley-required=\"true\"\r\n            data-parsley-errors-container=\".affirmation-error-container\" />\r\n          I understand by submitting this License Address Change Application: I am agreeing to be bound by the\r\n          following\r\n          declaration: \"I declare that all of my answers on this License Application are complete, true and correct. I\r\n          make this declaration subject to the penalties of 18 PA.C.S. § 4904 relating to unsworn falsification to\r\n          authorities.\"\r\n        </label>\r\n        <div class=\"col-sm-offset-3 col-sm-9\">\r\n          <div class=\"affirmation-error-container\"></div>\r\n        </div>\r\n      </div>\r\n      <div class=\"form-group required\">\r\n        <div class=\"col-sm-offset-3 col-sm-9\">\r\n          <label><strong>Enter Name Below</strong></label>\r\n          <input type=\"text\" class=\"form-control\" name=\"certificationName\" data-parsley-required=\"true\"\r\n            data-parsley-maxlength=\"100\" />\r\n        </div>\r\n      </div>\r\n    </form>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "\r\n<div class=\"col-sm-8 col-sm-offset-1 m-b-lg\">\r\n  <div class=\"form-horizontal\">\r\n    <form class=\"change-license-address-form\">\r\n      <div class=\"form-group\">\r\n        <div class=\"org-type\">\r\n          <label class=\"control-label col-sm-3\">Current License Address</label>\r\n          <div class=\"current-address-container form-control-static col-sm-9\"></div>\r\n        </div>\r\n      </div>\r\n      <div class=\"form-group required new-location-form\">\r\n        <label class=\"control-label col-sm-3\">New Location</label>\r\n"
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias2).call(alias1,(depth0 != null ? depth0.viewMode : depth0),"===","int",{"name":"ifCond","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "\r\n      </div>\r\n      <form class=\"effective-change-date-form\" data-parsley-focus=\"none\">\r\n        <div class=\"form-group required\">\r\n          <label class=\"control-label col-sm-3\">Effective Date of Change</label>\r\n          <div class=\"col-sm-4\">\r\n            <div class=\"input-group effective-change-date-container\">\r\n              <input type=\"text\" name=\"effectiveChangeDate\" class=\"form-control datepicker effective-change-date\"\r\n                placeholder=\"Effective Date of Change\" value=\""
    + alias4(((helper = (helper = helpers.effectiveChangeDate || (depth0 != null ? depth0.effectiveChangeDate : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"effectiveChangeDate","hash":{},"data":data}) : helper)))
    + "\" data-parsley-required=\"true\"\r\n                data-parsley-date=\"\" data-parsley-trigger-after-failure=\"changeDate\"\r\n                data-parsley-errors-container=\"#effectiveChangeDateErrorsContainer\" />\r\n              <div class=\"input-group-addon\">\r\n                <i class=\"fa fa-calendar\"></i>\r\n              </div>\r\n            </div>\r\n            <div id=\"effectiveChangeDateErrorsContainer\"></div>\r\n          </div>\r\n        </div>\r\n      </form>\r\n    </form> \r\n    <div class=\"form-group\">\r\n      <div class=\"col-sm-offset-3 col-sm-9\">\r\n        <h3>Document Upload</h3>\r\n        <label class=\"control-label\">Upload any other relevant documents</label>\r\n        <div class=\"application-documents-container\"></div>\r\n      </div>\r\n    </div>\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showAffirmation : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    <div class=\"row\">\r\n      <div class=\"btn-container col-sm-9 col-sm-offset-3\">\r\n        <a href=\"#ext/nondep/entity/"
    + alias4(((helper = (helper = helpers.entityId || (depth0 != null ? depth0.entityId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"entityId","hash":{},"data":data}) : helper)))
    + "/license/"
    + alias4(((helper = (helper = helpers.licenseId || (depth0 != null ? depth0.licenseId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"licenseId","hash":{},"data":data}) : helper)))
    + "\" class=\"btn btn-default\" role=\"button\">Cancel</a>\r\n        <button class=\"btn btn-primary submit-button ladda-button\" data-style=\"expand-right\" type=\"button\"><span\r\n            class=\"ladda-label\">Submit</span></button>\r\n      </div>\r\n    </div>\r\n  </div>\r\n</div>";
},"useData":true});