/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Backbone = require('backbone');
var Marionette = require('backbone.marionette');

var GridView = require('psa-marionette/views/GridView');

var BooleanCell = require('common/backgrid/BooleanCell');
var MomentCell = require('common/backgrid/MomentCell');

var tmplCheckCasherRenewalBranchesSectionView = require('./CheckCasherRenewalBranchesSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplCheckCasherRenewalBranchesSectionView,

  className: 'check-casher-renewal-branches-section-view',

  ui: {
    branchLicensesContainer: '.branch-licenses-container',
    validationAlert: '.validation-alert'
  },

  regions: {
    branchLicensesContainer: '@ui.branchLicensesContainer'
  },

  onBeforeShow: function() {
    var licenses = this.model.get('applicationDetails').branchLicenses;
    var renewals = this.model.get('renewalData').branchLicenses;
    var licenseRenewals = _.map(licenses, function(license) {
      var renewal = _.findWhere(renewals, { branchLicenseId: license.id });
      if (renewal) {
        license.isRenewing = renewal.isRenewing;
      }
      return license;
    });

    this.branchLicensesGrid = new GridView({
      columns: [
        {
          name: 'licenseNumber',
          label: 'License #',
          cell: 'string',
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'locationName',
          label: 'Location',
          cell: 'string',
          headerCell: 'custom',
          width: 25,
          editable: false
        },
        {
          name: 'originalIssueDate',
          label: 'Original License Date',
          cell: MomentCell.MomentCell,
          formatter: MomentCell.MomentDateFormatter.extend({
            emptyValue: '--'
          }),
          sortValue: MomentCell.MomentSortValue,
          headerCell: 'custom',
          width: 15,
          editable: false
        },
        {
          name: 'isRenewing',
          label: 'Renew License?',
          cell: BooleanCell,
          headerCell: 'custom',
          width: 10,
          editable: true,
          sortable: false
        }
      ],
      emptyText: 'No Branch Licenses Available for Renewal',
      collection: new Backbone.Collection(licenseRenewals)
    });
    this.showChildView('branchLicensesContainer', this.branchLicensesGrid);

    this.$('.table').addClass('table-bordered');
  },

  getFormData: function() {
    return this.branchLicensesGrid.collection.toJSON();
  }
});
