/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(data) {
  var defer = $.Deferred();

  $.soap({
    body: {
      GetIndividualCRDNumberExists: {
        $xmlns: 'http://dobs.pa.gov/sc/individual/bpm/1.0',
        IndividualID: data.individualId,
        CRDNumber: data.crdNumber,
        FirstName: data.firstName,
        LastName: data.lastName
      }
    },
    blueprint: {
      individualCRDNumberExists: '$..IndividualCRDNumberExists'
    }
  })
    .done(function(response) {
      cleanUpResponse(response, { cleanUpTextKeys: true });

      var individualCRDNumberExists = response.individualCRDNumberExists === '1';

      defer.resolve(individualCRDNumberExists);
    })
    .fail(function(err) {
      defer.reject(err);
    });

  return defer.promise();
};
