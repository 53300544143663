/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var NProgress = require('nprogress');

var NavUtils = require('psa-core/util/nav');

var Scaffold = require('common/layout/Scaffold');

var GetEntityByIdAndDepartment = require('../../common/services/GetEntityByIdAndDepartment');
var GetContactById = require('../services/GetContactById');

var ConSerContactEditView = require('../views/ConSerContactEditView');
var GetAllComplaintLookups = require('../../complaint-dashboard/services/GetAllComplaintLookups');

module.exports = function(departmentId, entityId, contactId) {
  NProgress.start();
  NProgress.set(0.6);

  //   call GetContactById
  $.when(GetEntityByIdAndDepartment(entityId, departmentId), GetContactById(contactId), GetAllComplaintLookups())
    .done(function(entity, contact, complaintLookups) {
      NavUtils.navigate('conser/' + departmentId + '/entity/' + entityId + '/contact/' + contactId + '/edit');
      Scaffold.content.show(
        new ConSerContactEditView({
          model: new Backbone.Model({
            entity: entity,
            contact: contact,
            contactType: complaintLookups.contactType
          })
        })
      );
      NProgress.done();
    })
    .fail(function() {
      NProgress.done();
    });
};
