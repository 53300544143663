/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Ladda = require('ladda');

var GridView = require('psa-marionette/views/GridView');
var PageableCollection = require('psa-backbone/models/PageableCollection');

var SelectOneCell = require('common/backgrid/SelectOneCell');
var MomentCell = require('common/backgrid/MomentCell');

var GetExamsForSelection = require('../services/GetExamsForSelection');
var GetInvInqForSelection = require('../services/GetInvInqForSelection');

require('../styles/TimeTrkSecAllObjectsSelectionModalView.css');
var tmplTimeTrkSecAllObjectsSelectionModalView = require('../templates/TimeTrkSecAllObjectsSelectionModalView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplTimeTrkSecAllObjectsSelectionModalView,

  className: 'time-trk-sec-all-objects-selection-modal-view',

  ui: {
    search: '.search',
    resultsGrid: '.results-grid',
    searchResultMax: '.search-result-max',
    searchResultSummary: '.search-result-summary',
    hitCount: '.hit-count',
    submit: '.btn-submit'
  },

  regions: {
    resultsGrid: '@ui.resultsGrid'
  },

  behaviors: {
    RadioGroupToggleBehavior: {
      radios: [
        {
          target: '.exam-search',
          radioGroup: '[name="isExamSearch"]',
          valueToShow: '1'
        },
        {
          target: '.inv-inq-search',
          radioGroup: '[name="isExamSearch"]',
          valueToShow: '0'
        },
        {
          target: '.individual-search',
          radioGroup: '[name="isOrganization"]',
          valueToShow: '0'
        },
        {
          target: '.organization-search',
          radioGroup: '[name="isOrganization"]',
          valueToShow: '1'
        }
      ]
    }
  },

  events: {
    'click @ui.search': 'onClickSearch',
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeShow: function() {
    var gridColumns = [
      {
        name: 'selected',
        label: '',
        cell: SelectOneCell.SelectOneRowCell,
        headerCell: SelectOneCell.SelectOneHeaderCell,
        width: 8,
        editable: true,
        sortable: false
      },
      {
        name: 'name',
        label: 'Name',
        cell: 'string',
        headerCell: 'custom',
        width: 23,
        editable: false,
        sortable: false
      },
      {
        name: 'number',
        label: 'Exam/Case Number',
        cell: 'string',
        headerCell: 'custom',
        width: 23,
        editable: false,
        sortable: false
      },
      {
        name: 'licenseType',
        label: 'License Type',
        cell: 'string',
        headerCell: 'custom',
        width: 23,
        editable: false,
        sortable: false
      },
      {
        name: 'openedDate',
        label: 'Exam Opened Date',
        cell: MomentCell.MomentCell,
        formatter: MomentCell.MomentDateFormatter.extend({
          emptyValue: 'Not Started'
        }),
        sortValue: MomentCell.MomentSortValue,
        headerCell: 'custom',
        width: 23,
        editable: false
      },
      {
        name: 'objectType',
        label: 'Inq / Inv / E',
        cell: 'string',
        headerCell: 'custom',
        width: 23,
        editable: false
      }
    ];

    this.objectSearchResults = new GridView({
      columns: gridColumns,
      collection: new PageableCollection([], { pageSize: 10 }),
      emptyText: 'No exams, investigations, or inquiries found.'
    });

    this.showChildView('resultsGrid', this.objectSearchResults);
  },

  onClickSearch: function(e) {
    e.preventDefault();
    var self = this;
    var assignedToUsername = this.model.get('assignedToUsername');

    var searchData = Syphon.serialize(this);
    searchData.assignedToUsername = assignedToUsername;

    if (searchData.isExamSearch === '1') {
      var ladda1 = Ladda.create(this.ui.search[0]);
      ladda1.start();
      GetExamsForSelection(searchData)
        .done(function(data) {
          ladda1.stop();
          self.objectSearchResults.collection.fullCollection.reset(data.scExams);
          self.ui.hitCount.text(data.totalHits);
          self.ui.searchResultSummary.show();
          if (data.totalHits === 50) {
            self.ui.searchResultMax.show();
          }
        })
        .fail(function() {
          ladda1.stop();
        });
    } else {
      var ladda2 = Ladda.create(this.ui.search[1]);
      ladda2.start();
      GetInvInqForSelection(searchData)
        .done(function(data) {
          ladda2.stop();
          self.objectSearchResults.collection.fullCollection.reset(data.scInvInqs);
          self.ui.hitCount.text(data.totalHits);
          self.ui.searchResultSummary.show();
          if (data.totalHits === 50) {
            self.ui.searchResultMax.show();
          }
        })
        .fail(function() {
          ladda2.stop();
        });
    }
  },

  onClickSubmit: function(e) {
    e.preventDefault();

    var selectedModel = this.objectSearchResults.viewGrid.getSelectedModel();
    if (selectedModel) {
      this.triggerMethod('modal:close', selectedModel.toJSON());
    } else {
      this.triggerMethod('modal:close');
    }
  }
});
