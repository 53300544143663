/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(data) {
  var defer = $.Deferred();

  $.soap({
    body: {
      GetAttachments: {
        $xmlns: 'http://dobs.pa.gov/nd/attachment/bpm/1.0',
        ObjectID: data.objectId,
        ObjectTypeID: data.objectTypeId
      }
    },
    blueprintArrays: ['GetAttachmentsResponse.ND_Attachment'],
    blueprint: {
      attachments: [
        '$..ND_Attachment',
        {
          id: '$.ID',
          objectTypeId: '$.ObjectTypeID',
          objectId: '$.ObjectID',
          documentId: '$.DocumentID',
          documentTypeId: '$.DocumentTypeID',
          documentType: '$.DocumentType',
          documentName: '$.DocumentName',
          createdDate: '$.CreatedDate',
          createdBy: '$.CreatedBy'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      _.each(response.attachments, function(attachment) {
        attachment.createdDate = moment(attachment.createdDate, moment.ISO_8601).format('MM/DD/YYYY hh:mm a');
        attachment.file = {
          fileId: attachment.documentId,
          fileName: attachment.documentName
        };
      });
      defer.resolve(response.attachments);
    })
    .fail(function(err) {
      defer.reject(err);
    });

  return defer.promise();
};
