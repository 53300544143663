/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var parsleyUtil = require('common/util/parsleyUtil');
var FileUploadView = require('common/views/FileUploadView');
var makeUploadCollection = require('common/util/makeUploadCollection');

var FileUploadCollection = require('psa-backbone/models/FileUploadCollection');

require('./CheckCasherBranchGeneralDetailsSectionView.css');
var tmplCheckCasherBranchGeneralDetailsSectionView = require('./CheckCasherBranchGeneralDetailsSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplCheckCasherBranchGeneralDetailsSectionView,

  className: 'check-casher-branch-general-details-section-view',

  ui: {
    generalDetailsForm: '.general-details-form',
    foodEstablishmentContainer: '.food-establishment-container',
    publicEatingContainer: '.public-eating-container',
    missingFile: '.missing-file',
    applicationDocumentsFileContainer: '.application-documents-container',
    zoningLawsDocument: '.zoning-laws-document-container',
    municipalCountyOrdinanceDocument: '.municipal-county-ordinance-document-container',
    retailFoodRegistrationDocument: '.retail-food-registration-document-container'
  },

  regions: {
    foodEstablishmentContainer: '@ui.foodEstablishmentContainer',
    publicEatingContainer: '@ui.publicEatingContainer',
    applicationDocumentsFileContainer: '@ui.applicationDocumentsFileContainer',
    zoningLawsDocument: '@ui.zoningLawsDocument',
    municipalCountyOrdinanceDocument: '@ui.municipalCountyOrdinanceDocument',
    retailFoodRegistrationDocument: '@ui.retailFoodRegistrationDocument'
  },

  behaviors: {
    RadioGroupToggleBehavior: {
      radios: [
        {
          target: '#hasFoodEstablishmentArea',
          radioGroup: '[name="isFoodEstablishment"]',
          valueToShow: '1'
        },
        {
          target: '#isPublicEatingArea',
          radioGroup: '[name="isPublicEating"]',
          valueToShow: '1'
        }
      ]
    }
  },

  onBeforeShow: function() {
    var application = this.model.get('application');
    Syphon.deserialize(this, application);

    this.foodEstablishmentDocument = new FileUploadView({
      collection: makeUploadCollection(this.model.get('foodEstablishmentDocument')),
      isRequired: true,
      isReadOnly: this.options.isReadOnly,
      metadata: {
        documentTypeId: 10023
      }
    });
    this.showChildView('foodEstablishmentContainer', this.foodEstablishmentDocument);

    this.publicEatingDocument = new FileUploadView({
      collection: makeUploadCollection(this.model.get('publicEatingDocument')),
      isRequired: true,
      isReadOnly: this.options.isReadOnly,
      metadata: {
        documentTypeId: 10023
      }
    });
    this.showChildView('publicEatingContainer', this.publicEatingDocument);

    this.applicationDocumentsFile = new FileUploadView({
      isRequired: true,
      allowMultipleFiles: true,
      collection: new FileUploadCollection(application.applicationDocuments),
      isReadOnly: this.options.isReadOnly,
      metadata: {
        documentTypeId: 10023
      }
    });
    this.showChildView('applicationDocumentsFileContainer', this.applicationDocumentsFile);
    
    this.zoningLawsDocument = new FileUploadView({
      collection: makeUploadCollection(this.model.get('zoningLawsDocument')),
      isRequired: true,
      isReadOnly: this.options.isReadOnly,
      metadata: {
        documentTypeId: 10023
      }
    });
    this.showChildView('zoningLawsDocument', this.zoningLawsDocument);

    this.municipalCountyOrdinanceDocument = new FileUploadView({
      collection: makeUploadCollection(this.model.get('municipalCountyOrdinanceDocument')),
      isRequired: true,
      isReadOnly: this.options.isReadOnly,
      metadata: {
        documentTypeId: 10023
      }
    });
    this.showChildView('municipalCountyOrdinanceDocument', this.municipalCountyOrdinanceDocument);

    this.retailFoodRegistrationDocument = new FileUploadView({
      collection: makeUploadCollection(this.model.get('retailFoodRegistrationDocument')),
      isRequired: true,
      isReadOnly: this.options.isReadOnly,
      metadata: {
        documentTypeId: 10023
      }
    });
    this.showChildView('retailFoodRegistrationDocument', this.retailFoodRegistrationDocument);
  },

  validate: function(config) {
    this.ui.generalDetailsForm.parsley().reset();
    this.zoningLawsDocument.removeValidationErrors();
    this.municipalCountyOrdinanceDocument.removeValidationErrors();
    this.retailFoodRegistrationDocument.removeValidationErrors();
    var formData = Syphon.serialize(this);
    if (config.type === 'save') {
      // Turn off required field validation for this form
      parsleyUtil.disableRequiredValidation(this.ui.generalDetailsForm);
    } else {
      // Turn on required field validation for this form
      parsleyUtil.enableRequiredValidation(this.ui.generalDetailsForm);
    }
    var validationPromises = [this.ui.generalDetailsForm.parsley().whenValidate()];
    if (config.type !== 'save') {
      if (formData.isPublicEating === '1') {
        validationPromises.push(this.publicEatingDocument.validate());
      }
      if (formData.isFoodEstablishment === '1') {
        validationPromises.push(this.foodEstablishmentDocument.validate());
      }
      validationPromises.push(this.zoningLawsDocument.validate());
      validationPromises.push(this.municipalCountyOrdinanceDocument.validate());
      validationPromises.push(this.retailFoodRegistrationDocument.validate());
    }
    return $.when.apply($, validationPromises);
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    formData.applicationDocuments = this.applicationDocumentsFile.collection.pluck('fileId');
    formData.foodEstablishmentDocumentId =
      formData.isFoodEstablishment === '1' ? this.foodEstablishmentDocument.getFirstFileId() : null;
    formData.publicEatingDocumentId =
      formData.isPublicEating === '1' ? this.publicEatingDocument.getFirstFileId() : null;

    (formData.zoningLawsDocumentId = this.zoningLawsDocument.getFirstFileId()),
      (formData.municipalCountyOrdinanceDocumentId = this.municipalCountyOrdinanceDocument.getFirstFileId()),
      (formData.retailFoodRegistrationDocumentId = this.retailFoodRegistrationDocument.getFirstFileId());
    return formData;
  }
});
