/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(entityId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetIrregularitiesByEntity: {
        $xmlns: 'http://dobs.pa.gov/dp/irregularity/bpm/1.0',
        EntityID: entityId
      }
    },
    blueprintArrays: ['GetIrregularitiesByEntityResponse.DP_EntityIrregularities'],
    blueprint: {
      irregularities: [
        '$..DP_EntityIrregularities',
        {
          id: '$..ID',
          category: '$..Category',
          subcategory: '$..SubCategory',
          firstName: '$..FirstName',
          middleName: '$..MiddleName',
          lastName: '$..LastName',
          eventDate: '$..EventDate',
          entityId: '$..EntityID',
          comments: '$..Comments'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      _.each(response.irregularities, function(irregularity) {
        irregularity.name = (
          (irregularity.firstName || '') +
          ' ' +
          (irregularity.middleName || '') +
          ' ' +
          (irregularity.lastName || '')
        )
          .replace(/\s+/g, ' ')
          .trim();

        irregularity.name = irregularity.name || '--';

        if (irregularity.eventDate) {
          irregularity.eventDate = moment(irregularity.eventDate, 'YYYY-MM-DD');
        }

        var comments = irregularity.comments;
        irregularity.subcategory = irregularity.subcategory || '--';
        if (comments) {
          irregularity.comments = comments.length > 100 ? comments.substring(0, 100) + '...' : comments;
        } else {
          irregularity.comments = '--';
        }
      });
      deferred.resolve(response.irregularities);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
