/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Session = require('psa-core/Session');

var modelGet = require('common/util/modelGet');

var ConSerOpenCloseHistoryCollectionView = require('./ConSerOpenCloseHistoryCollectionView');

var tmplConSerComplaintSectionInformationView = require('../templates/ConSerComplaintSectionInformationView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplConSerComplaintSectionInformationView,

  className: 'con-ser-complaint-section-information-view',

  ui: {
    informationForm: '.information-form',
    historyForm: '.history-form',
    caseManager: '.case-manager',
    complaintHistoryContainer: '.complaint-history-container',
    status: '.status',
    closedDateLabel: '.closed-date-label',
    adminSections: '.admin-container',
    nonAdminSections: '.non-admin-container',
    regulationField: '.regulation-field div.form-group'
  },

  regions: {
    complaintHistoryContainer: '@ui.complaintHistoryContainer'
  },

  events: {
    'change @ui.status': 'onChangeStatus'
  },

  childEvents: {
    'close:complaint': 'closeComplaint'
  },

  behaviors: {
    RadioGroupToggleBehavior: {
      radios: [
        {
          target: '.regulation-field',
          radioGroup: 'input[name="isPotentialViolation"]',
          valueToShow: '1'
        }
      ]
    }
  },

  onBeforeShow: function() {
    var complaint = this.model.get('complaint') || {};
    Syphon.deserialize(this, complaint);

    var currentOpenCloseHistory = modelGet(this.model, 'openCloseHistory', []);

    this.currentOpenCloseHistoryLength = currentOpenCloseHistory.length;
    this.openCloseHistoryCollection = new Backbone.Collection(currentOpenCloseHistory);
    this.openCloseHistory = new ConSerOpenCloseHistoryCollectionView({
      collection: this.openCloseHistoryCollection
    });

    // Add new status history row on new complaint and set case manager to current user
    if (!modelGet(this.model, 'complaint.complaintId')) {
      this.openCloseHistory.addRow();

      var userName = Session.user.userName;
      var caseManagers = this.model.get('caseManagers');
      if (_.findWhere(caseManagers, { userId: userName })) {
        this.ui.caseManager.val(userName);
      }
    }
    this.showChildView('complaintHistoryContainer', this.openCloseHistory);

    var currentStatus = modelGet(this.model, 'complaint.statusId'),
      currentStatusIsClosed = currentStatus !== '1';
    this.openCloseHistory.setRequired(currentStatusIsClosed);

    var isNotMigrated = !modelGet(this.model, 'complaint.migrationId');
    this.ui.closedDateLabel.toggleClass('required', currentStatusIsClosed && isNotMigrated);

    // hide/show fields for CS - Administrator role
    if (this.model.get('isAdministrator')) {
      this.ui.nonAdminSections.hide();
    } else {
      this.ui.adminSections.hide();
      this.ui.regulationField.removeClass('required');
    }
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    formData.openCloseHistory = this.openCloseHistory.getFormData();
    return formData;
  },

  validate: function() {
    var validationArr = [this.ui.informationForm.parsley().whenValidate()];
    var isNotOpen = this.ui.status.val() !== '1';
    var isNotMigrated = !modelGet(this.model, 'complaint.migrationId');
    if (isNotOpen && isNotMigrated) {
      validationArr.push(this.ui.historyForm.parsley().whenValidate());
    }

    return $.when.apply($, validationArr);
  },

  onChangeStatus: function() {
    var isNewComplaint = !modelGet(this.model, 'complaint.complaintId'),
      newStatus = this.ui.status.val(),
      currentStatus = modelGet(this.model, 'complaint.statusId');

    // Opened Status
    if (newStatus === '1') {
      if (!isNewComplaint && newStatus !== currentStatus) {
        // Reopening existing closed compliance case
        this.openCloseHistory.addRow();
      }

      this.ui.closedDateLabel.removeClass('required');
      this.openCloseHistory.setRequired(false);
    }
    // Closed / Empty Statuses
    else {
      if (!isNewComplaint && this.openCloseHistoryCollection.length > this.currentOpenCloseHistoryLength) {
        // Previously flipped to open, remove last pushed row
        this.openCloseHistoryCollection.pop();
      }

      var isNotMigrated = !modelGet(this.model, 'complaint.migrationId');
      if (isNotMigrated) {
        this.ui.closedDateLabel.addClass('required');
      }
      this.openCloseHistory.setRequired(true);
    }
  }
});
