var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "  <div class=\"row\">\r\n    <div class=\"col-md-6 col-sm-12\">\r\n      <div class=\"form-group\">\r\n        <label class=\"control-label col-md-6 col-sm-3\">Accountant</label>\r\n        <div class=\"col-md-6 col-sm-9\">\r\n          <input type=\"text\" name=\"accountant\" class=\"form-control\" placeholder=\"Accountant\" />\r\n        </div>\r\n      </div>\r\n    </div>\r\n  </div>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "\r\n<div class=\"row\">\r\n  <div class=\"form-group\">\r\n    <label class=\"control-label col-sm-3\">First Name</label>\r\n    <div class=\"col-sm-9\">\r\n      <input type=\"text\" name=\"corrFirstName\" class=\"form-control\" placeholder=\"First Name\" data-parsley-maxlength=\"100\" />\r\n    </div>\r\n  </div>\r\n  <div class=\"form-group\">\r\n    <label class=\"control-label col-sm-3\">Last Name</label>\r\n    <div class=\"col-sm-9\">\r\n      <input type=\"text\" name=\"corrLastName\" class=\"form-control\" placeholder=\"Last Name\" data-parsley-maxlength=\"100\" />\r\n    </div>\r\n  </div>\r\n  <div class=\"row\">\r\n    <div class=\"col-md-6 col-sm-12\">\r\n      <div class=\"form-group\">\r\n        <label class=\"control-label col-md-6 col-sm-3\">Middle Initial</label>\r\n        <div class=\"col-sm-9 col-md-6\">\r\n          <input type=\"text\" name=\"corrMiddleInitial\" class=\"form-control\" placeholder=\"Middle Initial\"\r\n            data-parsley-maxlength=\"5\" />\r\n        </div>\r\n      </div>\r\n    </div>\r\n    <div class=\"col-md-6 col-sm-12\">\r\n      <div class=\"form-group\">\r\n        <label class=\"control-label col-sm-3\">Suffix</label>\r\n        <div class=\"col-sm-9\">\r\n          <input type=\"text\" name=\"corrSuffix\" class=\"form-control\" placeholder=\"Suffix\" data-parsley-maxlength=\"10\" />\r\n        </div>\r\n      </div>\r\n    </div>\r\n  </div>\r\n  <div class=\"form-group\">\r\n    <label class=\"control-label col-sm-3\">Law Firm</label>\r\n    <div class=\"col-sm-9\">\r\n      <input type=\"text\" name=\"corrLawFirm\" class=\"form-control\" placeholder=\"Law Firm\" data-parsley-maxlength=\"200\" />\r\n    </div>\r\n  </div>\r\n"
    + ((stack1 = helpers.unless.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isInvestment : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  <div class=\"row\">\r\n    <div class=\"col-md-6 col-sm-12\">\r\n      <div class=\"form-group\">\r\n        <label class=\"control-label col-sm-3 col-md-6\">Phone Number</label>\r\n        <div class=\"col-sm-9 col-md-6\">\r\n          <input type=\"text\" name=\"corrPhone\" class=\"form-control\" placeholder=\"Phone Number\"\r\n            data-parsley-maxlength=\"30\" />\r\n        </div>\r\n      </div>\r\n    </div>\r\n    <div class=\"col-md-6 col-sm-12\">\r\n      <div class=\"form-group\">\r\n        <label class=\"control-label col-sm-3\">Ext</label>\r\n        <div class=\"col-sm-9\">\r\n          <input type=\"text\" name=\"corrExt\" class=\"form-control\" placeholder=\"Ext\" data-parsley-maxlength=\"20\" />\r\n        </div>\r\n      </div>\r\n    </div>\r\n  </div>\r\n  <div class=\"form-group\">\r\n    <label class=\"control-label col-sm-3\">Email</label>\r\n    <div class=\"col-sm-9\">\r\n      <input type=\"text\" name=\"corrEmail\" class=\"form-control\" placeholder=\"Email\" data-parsley-type=\"email\"\r\n        data-parsley-maxlength=\"200\" />\r\n    </div>\r\n  </div>\r\n  <div class=\"address\"></div>\r\n</div>";
},"useData":true});