var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return "        <a href=\"#sec/investigation-inquiry/"
    + container.escapeExpression(((helper = (helper = helpers.objectId || (depth0 != null ? depth0.objectId : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"objectId","hash":{},"data":data}) : helper)))
    + "/dashboard\" class=\"btn btn-default\">Cancel</a>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper;

  return "        <a href=\"#sec/exam/"
    + container.escapeExpression(((helper = (helper = helpers.objectId || (depth0 != null ? depth0.objectId : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"objectId","hash":{},"data":data}) : helper)))
    + "/dashboard\" class=\"btn btn-default\">Cancel</a>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "\r\n<div class=\"page-title clearfix\">\r\n  <h1 class=\"pull-left m-n p-n\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.objectIdentifiers : depth0)) != null ? stack1.applicantLegalName : stack1), depth0))
    + "</h1>\r\n</div>\r\n<div class=\"header-bar\"></div>\r\n<div class=\"breadcrumb-container\"></div>\r\n<div class=\"row m-b-xs\">\r\n  <div class=\"col-sm-12 col-md-10 col-md-offset-1\">\r\n    <div class=\"form-group\">\r\n      <div class=\"col-sm-9 col-md-8 col-md-offset-2 col-sm-offset-3\">\r\n        <h3 class=\"m-t-n\">New Compliance Case</h3>\r\n      </div>\r\n    </div>\r\n  </div>\r\n</div>\r\n<form class=\"compliance-general-info-form-container\"></form>\r\n<div class=\"row m-b-lg\">\r\n  <div class=\"col-sm-12 col-md-10 col-md-offset-1\">\r\n    <div class=\"form-group\">\r\n      <div class=\"col-sm-9 col-md-8 col-md-offset-2 col-sm-offset-3\">\r\n"
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias2).call(alias1,(depth0 != null ? depth0.objectTypeId : depth0),"===","inv-inq",{"name":"ifCond","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias2).call(alias1,(depth0 != null ? depth0.objectTypeId : depth0),"===","exam",{"name":"ifCond","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        <button class=\"btn btn-primary btn-submit\">Submit</button>\r\n      </div>\r\n    </div>\r\n  </div>\r\n</div>";
},"useData":true});