/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Backbone = require('backbone');

var GetDocumentTypesByDepartment = require('common/services/GetDocumentTypesByDepartment');

var GetExamQuestionRespones = require('../services/GetExamQuestionRespones');
var GetExamSetupQuestionsByCode = require('../services/GetExamSetupQuestionsByCode');
var GetExamSetupQuestionParameters = require('../services/GetExamSetupQuestionParameters');

var ExamQuestionsCollectionView = require('../views/ExamQuestionsCollectionView');

module.exports = {
  event: 'build:exam-questions',

  channel: 'nd:exam',

  builder: function(data) {
    var defer = $.Deferred();

    var examId = data.examId;
    var examSetupCode = data.examSetupCode;

    /**
     * This builder accepts the following modes:
     * ----------------------------------------
     * entity-answers
     * record-results
     * review-results
     */
    var mode = data.mode;

    $.when(
      GetExamSetupQuestionsByCode(examSetupCode),
      GetExamSetupQuestionParameters(examId),
      GetExamQuestionRespones({ examId: examId, examSetupCode: examSetupCode }),
      GetDocumentTypesByDepartment('NON_DEPOSITORY')
    )
      .done(function(examQuestions, examQuestionParameters, examResponses, documentTypes) {
        _.each(examResponses, function(examResponse) {
          // Set the documentType attribute to be documentTypeId when users are entering data,
          // so that the FileUploadTable correctly sets the document type dropdown
          if (mode === 'entity-answers' && examResponse.documents.length) {
            _.each(examResponse.documents, function(document) {
              document.documentType = document.documentTypeId;
            });
          }
          var question = _.findWhere(examQuestions, { examSetupQuestionId: examResponse.examSetupQuestionId });
          question.response = examResponse;
        });
        defer.resolve(
          new ExamQuestionsCollectionView({
            documentTypes: documentTypes,
            mode: mode,
            examQuestionParameters: examQuestionParameters,
            collection: new Backbone.Collection(examQuestions)
          })
        );
      })
      .fail(function(err) {
        defer.reject(err);
      });

    return defer.promise();
  }
};
