var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "\r\n<div class=\"row underwriter-row sub-form-vertical\">\r\n  <div class=\"col-sm-5\">\r\n    <div class=\"form-group required\">\r\n      <label class=\"control-label\">BD/Underwriter Name</label>\r\n      <input type=\"text\" class=\"form-control\" name=\"BDUnderwriterName\" placeholder=\"BD/Underwriter Name\"\r\n        data-parsley-required=\"true\" data-parsley-maxlength=\"100\"\r\n        data-parsley-errors-container=\"#BDUnderwriterNameError"
    + alias4(((helper = (helper = helpers.childIndex || (depth0 != null ? depth0.childIndex : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"childIndex","hash":{},"data":data}) : helper)))
    + "\" />\r\n      <div id=\"BDUnderwriterNameError"
    + alias4(((helper = (helper = helpers.childIndex || (depth0 != null ? depth0.childIndex : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"childIndex","hash":{},"data":data}) : helper)))
    + "\"></div>\r\n    </div>\r\n  </div>\r\n  <div class=\"col-sm-5\">\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label\">BD/Underwriter CRD</label>\r\n      <input type=\"text\" class=\"form-control\" name=\"BDUnderwriterCRD\" placeholder=\"BD/Underwriter CRD\"\r\n        data-parsley-maxlength=\"100\"\r\n        data-parsley-errors-container=\"#BDUnderwriterCRDError"
    + alias4(((helper = (helper = helpers.childIndex || (depth0 != null ? depth0.childIndex : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"childIndex","hash":{},"data":data}) : helper)))
    + "\" />\r\n      <div id=\"BDUnderwriterCRDError"
    + alias4(((helper = (helper = helpers.childIndex || (depth0 != null ? depth0.childIndex : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"childIndex","hash":{},"data":data}) : helper)))
    + "\"></div>\r\n    </div>\r\n  </div>\r\n  <div class=\"col-sm-2 m-b-n remove-container\">\r\n    <button class=\"btn btn-danger btn-sm remove-underwriter\">\r\n      <i class=\"fa fa-times\" aria-hidden=\"true\"></i>\r\n    </button>\r\n  </div>\r\n</div>";
},"useData":true});