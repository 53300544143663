/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var cleanUpResponse = require('common/util/cleanUpResponse');
var momentUtil = require('common/util/momentUtil');

module.exports = function(sanctionId) {
  var defer = $.Deferred();

  $.soap({
    body: {
      GetComplianceSanction: {
        $xmlns: 'http://dobs.pa.gov/sc/compliance/bpm/1.0',
        SanctionID: sanctionId
      }
    },
    blueprint: {
      complianceSanctionId: '$..ID',
      complianceId: '$..ComplianceID',
      sanctionId: '$..SanctionID',
      sanction: '$..Sanction',
      note: '$..Note',
      isDeleted: '$..IsDeleted',
      createdDate: '$..CreatedDate',
      createdBy: '$..CreatedBy',
      modifiedDate: '$..ModifiedDate',
      modifiedBy: '$..ModifiedBy'
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      response.createdDate = momentUtil.formatStr(response.createdDate, momentUtil.defaultInboundDateConfig);
      response.modifiedDate = momentUtil.formatStr(response.modifiedDate, momentUtil.defaultInboundDateConfig);

      defer.resolve(response);
    })
    .fail(function(err) {
      defer.reject(err);
    });

  return defer.promise();
};
