/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(categoryId) {
  var defer = $.Deferred();

  $.soap({
    body: {
      GetAllFilingReviewLookups: {
        $xmlns: 'http://dobs.pa.gov/dp/lookup/bpm/1.0'
      }
    },
    blueprintArrays: [
      'GetAllFilingReviewLookupsResponse.ReviewRoles.DP_LU_FilingReviewRole',
      'GetAllFilingReviewLookupsResponse.InitialDecisions.DP_LU_FilingInitialDecision',
      'GetAllFilingReviewLookupsResponse.ReviewDecisions.DP_LU_FilingReviewDecision',
      'GetAllFilingReviewLookupsResponse.ReviewRecommendations.DP_LU_FilingReviewRecommendation'
    ],
    blueprint: {
      filingReviewRoles: [
        '$..DP_LU_FilingReviewRole',
        {
          id: '$.ID',
          role: '$.Role',
          roleCN: '$.RoleCN',
          categoryId: '$.CategoryID'
        }
      ],
      initialDecisions: [
        '$..DP_LU_FilingInitialDecision',
        {
          id: '$.ID',
          decision: '$.Decision'
        }
      ],
      filingReviewDecisions: [
        '$..DP_LU_FilingReviewDecision',
        {
          id: '$.ID',
          decision: '$.Decision'
        }
      ],
      reviewRecommendations: [
        '$..DP_LU_FilingReviewRecommendation',
        {
          id: '$.ID',
          recommendation: '$.Recommendation'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      if (categoryId) {
        response.filingReviewRoles = _.where(response.filingReviewRoles, { categoryId: categoryId });
      }

      defer.resolve(response);
    })
    .fail(function(err) {
      defer.reject(err);
    });

  return defer.promise();
};
