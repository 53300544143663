/**************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var _ = require('underscore');

var tmplLoginView = require('../templates/InstitutionSearchView.hbs');
var Syphon = require('backbone.syphon');
var GridView = require('psa-marionette/views/GridView');
var PageableCollection = require('psa-backbone/models/PageableCollection');

module.exports = Marionette.LayoutView.extend({
  template: tmplLoginView,
  className: 'inst-search-view',

  ui: {
    search: '.search',
    clearFilters: '.clear-filters',
    formInputs: ':input',
    searchResultsContainer: '.search-results-container',
    selectlicnesetype: '.select-licnese-type',
    selectinstitutiontype: '.select-institution-type'
  },

  regions: {
    searchResultsContainer: '@ui.searchResultsContainer'
  },

  events: {
    'click @ui.search': 'onClickSearch',
    'click @ui.clearFilters': 'onClickClearFilters',
    'click @ui.selectlicnesetype': 'onClickSelectLicenseType',
    'click @ui.selectinstitutiontype': 'onClickSelectinstitutiontype'
  },

  onBeforeShow: function() {
    var entities = this.model.get('entities');
    this.entities_grid = new GridView({
      columns: [
        {
          name: 'EntityName',
          label: 'Name',
          cell: 'string',
          headerCell: 'custom',
          width: 25,
          editable: false
        },
        {
          name: 'LicenseType',
          cell: 'string',
          headerCell: 'custom',
          width: 25,
          editable: false
        },
        {
          name: 'LicenseNumber',
          label: 'License Number',
          cell: 'string',
          headerCell: 'custom',
          width: 15,
          editable: false
        },
        {
          name: 'CharterType',
          label: 'Charter Type',
          cell: 'string',
          headerCell: 'custom',
          width: 15,
          editable: false
        },
        {
          name: 'Cert',
          label: 'Cert',
          cell: 'string',
          headerCell: 'custom',
          width: 15,
          editable: false
        },
        {
          name: 'Address',
          label: 'Address',
          cell: 'string',
          headerCell: 'custom',
          width: 30,
          editable: false
        },
        {
          name: 'PhoneNumber',
          label: 'Phone Number',
          cell: 'string',
          headerCell: 'custom',
          width: 15,
          editable: false
        },
        {
          name: 'DbaList',
          label: 'DBA',
          cell: 'string',
          headerCell: 'custom',
          width: 15,
          editable: false
        }
      ],
      collection: new PageableCollection(entities, { pageSize: 10 }),

      emptyText: 'No matching result. Please try narrowing your search.'
    });

    this.showChildView('searchResultsContainer', this.entities_grid);
  },

  onClickSelectLicenseType: function() {
    this.ui.selectinstitutiontype.val('');
  },
  onClickSelectinstitutiontype: function() {
    this.ui.selectlicnesetype.val('');
  },

  onClickSearch: function() {
    var search = [];
    var data = Syphon.serialize(this, {
      inputReaders: Syphon.SearchInputReaderSet
    });

    if (data.name) {
      search.push({
        name: data.name
      });
    }

    if (data.city) {
      search.push({
        city: data.city
      });
    }

    if (data.county) {
      search.push({
        county: data.county
      });
    }

    if (data.licensetypeId) {
      search.push({
        licensetypeId: data.licensetypeId
      });
    }
    if (data.institutionTypeId) {
      search.push({
        institutionTypeId: data.institutionTypeId
      });
    }

    this.entities_grid.collection.fullCollection.reset(this.search(search));
  },

  search: function(options) {
    var searchOptionsFiltered = this.model.get('entities');

    _.each(options, function(value, key, obj) {
      if (value.county) {
        if (value.county !== '*') {
          searchOptionsFiltered = _.filter(searchOptionsFiltered, function(entity) {
            return entity.CountyCode === value.county;
          });
        }
      }
    });
    _.each(options, function(value, key, obj) {
      if (value.name) {
        searchOptionsFiltered = _.filter(searchOptionsFiltered, function(entity) {
          return entity.EntityName.toLowerCase().indexOf(value.name.toLowerCase()) > -1;
        });
      }
    });

    _.each(options, function(value, key, obj) {
      if (value.city) {
        searchOptionsFiltered = _.filter(searchOptionsFiltered, function(entity) {
          return entity.City.toLowerCase().indexOf(value.city.toLowerCase()) > -1;
        });
      }
    });

    _.each(options, function(value, key, obj) {
      if (value.licensetypeId) {
        searchOptionsFiltered = _.filter(searchOptionsFiltered, function(entity) {
          if (_.contains(value.licensetypeId, entity.licenseTypeID) && entity.licenseTypeID === '1') {
            return _.contains(['5', '12'], entity.EntityOrganizationTypeID);
          } else {
            return _.contains(value.licensetypeId, entity.licenseTypeID);
          }
        });
      }
    });

    _.each(options, function(value, key, obj) {
      if (value.institutionTypeId) {
        searchOptionsFiltered = _.filter(searchOptionsFiltered, function(entity) {
          return _.contains(value.institutionTypeId, entity.institutionTypeCode);
        });
      }
    });

    return _.sortBy(searchOptionsFiltered, 'EntityName');
  },

  onClickClearFilters: function() {
    this.ui.formInputs.val('');
    this.entities_grid.collection.fullCollection.reset(this.model.get('entities'));
  }
});

