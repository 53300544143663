/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Ladda = require('ladda');
var Marionette = require('backbone.marionette');

var tmplConfirmationModalView = require('./ConfirmationModalView.hbs');

module.exports = Marionette.ItemView.extend({
  template: tmplConfirmationModalView,

  ui: {
    btnNo: '.btn-no',
    btnYes: '.btn-yes'
  },

  events: {
    'click @ui.btnNo': 'onClickNo',
    'click @ui.btnYes': 'onClickYes'
  },

  options: {
    rejectButtonClass: 'btn-default',
    confirmButtonClass: 'btn-danger',
    rejectText: 'Cancel',
    confirmText: 'Yes'
  },

  templateHelpers: function() {
    return {
      rejectButtonClass: this.options.rejectButtonClass,
      confirmButtonClass: this.options.confirmButtonClass,
      rejectText: this.options.rejectText,
      confirmText: this.options.confirmText
    };
  },

  onClickNo: function(e) {
    e.preventDefault();
    this.triggerMethod('modal:close', false);
  },

  onClickYes: function(e) {
    e.preventDefault();
    var self = this;
    var onConfirm = this.model.get('onConfirm');
    if (onConfirm && _.isFunction(onConfirm)) {
      var laddaContext = Ladda.create(this.ui.btnYes.get(0));
      laddaContext.start();
      onConfirm()
        .done(function(response) {
          var onConfirmDone = self.model.get('onConfirmDone');
          if (onConfirmDone && _.isFunction(onConfirmDone)) {
            onConfirmDone(arguments);
          }
          self.triggerMethod('modal:close', true);
        })
        .fail(function(err) {
          var onConfirmFail = self.model.get('onConfirmFail');
          if (onConfirmFail && _.isFunction(onConfirmFail)) {
            onConfirmFail(arguments);
          }
          self.triggerMethod('modal:close', true);
        });
    } else {
      this.triggerMethod('modal:close', true);
    }
  }
});
