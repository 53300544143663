/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var NProgress = require('nprogress');

var NavUtils = require('psa-core/util/nav');

var Scaffold = require('common/layout/Scaffold');

var GetApplicationDetails = require('../../common/services/GetApplicationDetails');
var GetApplicationStatus = require('../../common/services/GetApplicationStatus');
var GetAllApplicationStatus = require('../services/GetAllApplicationStatus');
var GetApplicationOwner = require('../services/GetApplicationOwner');

var NonDepInternalApplicationUpdateStatusView = require('../views/NonDepInternalApplicationUpdateStatusView');

module.exports = function(applicationId) {
  NProgress.start();
  NProgress.set(0.6);

  $.when(
    GetApplicationDetails(applicationId),
    GetApplicationStatus(applicationId),
    GetApplicationOwner(applicationId),
    GetAllApplicationStatus()
  )
    .done(function(applicationDetails, applicationStatus, owner, applicationStatusLookups) {
      NavUtils.navigate('nondep/application/' + applicationId + '/update-status');
      Scaffold.content.show(
        new NonDepInternalApplicationUpdateStatusView({
          model: new Backbone.Model({
            applicationId: applicationId,
            applicationDetails: applicationDetails,
            owner: owner,
            applicationStatus: applicationStatus,
            applicationStatusLookups: applicationStatusLookups
          })
        })
      );
      NProgress.done();
    })
    .fail(function() {
      NProgress.done();
    });
};
