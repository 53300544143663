/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');

var DepEnforcementDetailsSectionView = require('../views/DepEnforcementDetailsSectionView');
var PlaceholderView = require('common/views/PlaceholderView');

var GetEnforcement = require('../services/GetEnforcement');

module.exports = {
  channel: 'dp',

  event: 'build:enforcement-details-section-view',

  builder: function(enforcementId) {
    return new PlaceholderView({
      viewPromise: buildEnforcementDetailsSectionView(enforcementId)
    });
  }
};

function buildEnforcementDetailsSectionView(enforcementId) {
  var deferred = $.Deferred();
  GetEnforcement(enforcementId)
    .done(function(enforcement) {
      deferred.resolve(
        new DepEnforcementDetailsSectionView({
          model: new Backbone.Model({ enforcement: enforcement })
        })
      );
    })
    .fail(function(err) {
      deferred.reject(err);
    });
  return deferred.promise();
}
