/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var GetEntityTinExists = require('../services/GetEntityTinExists');

window.Parsley.addValidator('uniqueEin', {
  requirementType: {
    '': 'string',
    entity: 'string'
  },
  validateString: function(value, requirement, field) {
    var entityId = field.options.einEntity;
    var defer = $.Deferred();

    GetEntityTinExists({ tin: value, entityId: entityId })
      // GetEntityTinExists(value)
      .done(function(entityTINAlreadyExists) {
        if (entityTINAlreadyExists) {
          defer.reject();
        } else {
          defer.resolve();
        }
      })
      .fail(function() {
        defer.reject();
      });
    return defer.promise();
  },
  messages: {
    en: 'The provided TIN has already been registered with the Department of Banking and Securities.'
  }
});
