/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(investmentId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetInvestmentHistoryByInvestment: {
        $xmlns: 'http://dobs.pa.gov/cf/investment/bpm/1.0',
        InvestmentID: investmentId
      }
    },
    blueprintArrays: ['GetInvestmentHistoryByInvestmentResponse.CF_InvestmentHistory'],
    blueprint: {
      histories: [
        '$..CF_InvestmentHistory',
        {
          id: '$..ID',
          investmentId: '$..InvestmentID',
          filedDate: '$..FiledDate',
          filingNumber: '$..FilingNumber',
          transactionDescriptionID: '$..TransactionDescID',
          transactionDescription: '$..TransactionDesc',
          PADollarAmount: '$..PADollarAmount',
          feePaidAmount: '$..FeePaidAmount',
          dispositionDate: '$..DispositionDate',
          statusId: '$..StatusID',
          status: '$..Status',
          effectiveFromDate: '$..EffectiveFromDate',
          effectiveToDate: '$..EffectiveToDate',
          isDeleted: '$..IsDeleted',
          createdDate: '$..CreatedDate',
          createdBy: '$..CreatedBy',
          modifiedDate: '$..ModifiedDate',
          modifiedBy: '$..ModifiedBy'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      _.each(response.histories, function(history) {
        if (history.filedDate) {
          history.filedDate = moment(history.filedDate, 'YYYY-MM-DD');
        }
        if (history.dispositionDate) {
          history.dispositionDate = moment(history.dispositionDate, 'YYYY-MM-DD');
        }
        if (history.effectiveFromDate) {
          history.effectiveFromDate = moment(history.effectiveFromDate, 'YYYY-MM-DD');
        }
        if (history.effectiveToDate) {
          history.effectiveToDate = moment(history.effectiveToDate, 'YYYY-MM-DD');
        }

        history.feePaidAmountDisplay = history.feePaidAmount || '0';
      });

      deferred.resolve(response.histories);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
