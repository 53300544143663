var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "          <option value=\""
    + alias4(((helper = (helper = helpers.userId || (depth0 != null ? depth0.userId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"userId","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.userId || (depth0 != null ? depth0.userId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"userId","hash":{},"data":data}) : helper)))
    + "</option>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "\r\n<div class=\"side-bar-container pull-left\"></div>\r\n<div class=\"dashboard-container pull-right\">\r\n  <div class=\"breadcrumb-container\"></div>\r\n  <div class=\"dashboard-title\">\r\n    <h2>"
    + container.escapeExpression(((helper = (helper = helpers.examYear || (depth0 != null ? depth0.examYear : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"examYear","hash":{},"data":data}) : helper)))
    + " Exam Year - Assign Exams</h2>\r\n  </div>\r\n  <div class=\"alert alert-danger collapse validation-alert\" id=\"noExamsSelected\" role=\"alert\">\r\n    <i class=\"fa fa-exclamation-triangle\" aria-hidden=\"true\"></i>\r\n    <span class=\"validation-alert-text\">Must select at least one exam.</span>\r\n  </div>\r\n  <form class=\"form-horizontal choose-examiners-form\">\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-3\">Choose Primary Examiner</label>\r\n      <div class=\"col-sm-9\">\r\n        <select name=\"primaryExaminer\" id=\"primaryExaminer\" class=\"form-control\" data-placeholder=\"--\"\r\n          data-parsley-required=\"true\">\r\n          <option></option>\r\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.examiners : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </select>\r\n      </div>\r\n    </div>\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-3\">Choose Secondary Examiners</label>\r\n      <div class=\"col-sm-9\">\r\n        <select name=\"secondaryExaminers\" id=\"secondaryExaminers\" class=\"form-control\" data-placeholder=\"--\"\r\n          multiple=\"multiple\">\r\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.examiners : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </select>\r\n      </div>\r\n    </div>\r\n  </form>\r\n  <div class=\"exams-container\"></div>\r\n  <button class=\"btn btn-primary pull-left m-b-md m-r-md ladda-button\" id=\"assign\"><span class=\"ladda-label\">Assign to Selected Examiner(s)</span></button>\r\n</div>";
},"useData":true});