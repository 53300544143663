/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');
var Ladda = require('ladda');
var Syphon = require('backbone.syphon');
var Radio = require('backbone.radio');

var toggleForm = require('common/util/toggleForm');

var ApplicationPreviousDecisionView = require('../../views/ApplicationPreviousDecisionView');
var SubmitManagerApplicationReviewTask = require('../../services/SubmitManagerApplicationReviewTask');

var MTReviewInvestigation = require('../MTReviewApplicationTask/MTReviewInvestigation');
var MTReviewIndividualInvestigationCollectionView = require('../MTReviewApplicationTask/MTReviewIndividualInvestigationCollectionView');

var tmplLicensingChiefReviewApplicationWorkTabView = require('./LicensingChiefReviewApplicationWorkTabView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplLicensingChiefReviewApplicationWorkTabView,

  className: 'licensing-chief-review-application-work-tab-view container-fluid',

  ui: {
    entityInvestigationContainer: '.entity-investigation-container',
    individualInvestigationsContainer: '.individual-investigations-container',
    previousDecisionContainer: '.previous-decision-container',
    reviewForm: '.review-form',
    reviewDecision: '.review-decision',
    decisionReasonContainer: '.decision-reason-container',
    submit: '.submit'
  },

  regions: {
    entityInvestigationContainer: '@ui.entityInvestigationContainer',
    individualInvestigationsContainer: '@ui.individualInvestigationsContainer',
    previousDecisionContainer: '@ui.previousDecisionContainer'
  },

  events: {
    'change @ui.reviewDecision': 'onChangeReviewDecision',
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeShow: function() {
    this.showChildView(
      'previousDecisionContainer',
      new ApplicationPreviousDecisionView({
        model: new Backbone.Model(this.model.get('previousReviewDecision'))
      })
    );
    var investigation = this.model.get('applicationInvestigation');
    this.entityInvestigation = new MTReviewInvestigation({
      mode: 'entity',
      isReadOnly: true,
      model: new Backbone.Model({
        applicationInvestigationId: investigation.id,
        hasCriminal: investigation.entityHasCriminal,
        hasFinancial: investigation.entityHasFinancial,
        hasRegulatory: investigation.entityHasRegulatory,
        hasCivil: investigation.entityHasCivil,
        documents: investigation.entityDocuments
      })
    });
    this.showChildView('entityInvestigationContainer', this.entityInvestigation);

    this.individualInvestigations = new MTReviewIndividualInvestigationCollectionView({
      isReadOnly: true,
      collection: new Backbone.Collection(investigation.individuals)
    });
    this.showChildView('individualInvestigationsContainer', this.individualInvestigations);
    
    this.onChangeReviewDecision();
  },

  onChangeReviewDecision: function() {
    var reviewDecision = this.ui.reviewDecision.val();
    var isApproved = reviewDecision === '1401';
    toggleForm(this.ui.decisionReasonContainer, !isApproved && reviewDecision);
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;
    this.ui.reviewForm
      .parsley()
      .whenValidate()
      .done(function() {
        var l = Ladda.create(self.ui.submit[0]);
        l.start();
        var formData = Syphon.serialize(self);
        _.extend(formData, {
          taskId: self.model.get('taskId'),
          applicationId: self.model.get('applicationId')
        });
        SubmitManagerApplicationReviewTask(formData)
          .done(function() {
            Radio.channel('navigate').trigger('show:int:nd:dashboard');
          })
          .fail(function(err) {
            l.stop();
          });
      });
  }
});
