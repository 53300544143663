/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Ladda = require('ladda');
var Radio = require('backbone.radio');

var BreadcrumbView = require('common/views/BreadcrumbView');
var LicenseHeaderBarView = require('../../common/views/LicenseHeaderBarView');
var modelGet = require('common/util/modelGet');

var NonDepCreateComplianceCaseRequestFormView = require('../../common/views/NonDepCreateComplianceCaseRequestFormView');

var SubmitComplianceCaseRequest = require('../../compliance-dashboard/services/SubmitComplianceCaseRequest');

var tmplNonDepLicenseCreateComplianceCaseRequestView = require('../templates/NonDepLicenseCreateComplianceCaseRequestView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepLicenseCreateComplianceCaseRequestView,

  className: 'non-dep-license-create-compliance-case-request-view container-fluid',

  ui: {
    headerBarContainer: '.header-bar-container',
    breadcrumb: '.breadcrumb-container',
    createComplianceCaseForm: '.compliance-form-container',
    submit: '.btn-submit'
  },

  regions: {
    headerBarContainer: '@ui.headerBarContainer',
    breadcrumb: '@ui.breadcrumb',
    createComplianceCaseForm: '@ui.createComplianceCaseForm'
  },

  events: {
    'click @ui.entitySearchButton': 'onClickEntitySearchButton',
    'change @ui.existingLicenseCheckbox': 'onChangeExistingLicenseCheckbox',
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeShow: function() {
    var entityName = this.model.get('entityName');
    var entityId = this.model.get('entityId');
    var licenseId = this.model.get('licenseId');
    var licenseNumber = modelGet(this.model, 'licenseIdentifiers.licenseNumber');
    var licenseType = modelGet(this.model, 'licenseIdentifiers.licenseType');

    this.showChildView(
      'headerBarContainer',
      new LicenseHeaderBarView({
        model: new Backbone.Model(this.model.get('licenseIdentifiers'))
      })
    );

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#nondep/dashboard',
            text: 'Home'
          },
          {
            text: entityName,
            link: '#nondep/entity/' + entityId + '/dashboard'
          },
          {
            text: 'License #' + licenseNumber + ' - ' + licenseType,
            link: '#nondep/entity/' + entityId + '/license/' + licenseId
          },
          {
            text: 'Create Compliance Case'
          }
        ]
      })
    );

    this.complianceForm = new NonDepCreateComplianceCaseRequestFormView({
      individualType: this.options.individualType,
      model: this.model
    });
    this.showChildView('createComplianceCaseForm', this.complianceForm);
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    this.validate().done(
      function() {
        var laddaContext = Ladda.create(this.ui.submit.get(0));
        laddaContext.start();
        SubmitComplianceCaseRequest(Syphon.serialize(this))
          .done(function(complianceId) {
            Radio.channel('navigate').trigger('show:int:nd:compliance:dashboard', complianceId);
          })
          .fail(function() {
            laddaContext.stop();
          });
      }.bind(this)
    );
  },

  validate: function() {
    return this.complianceForm.validate();
  }
});
