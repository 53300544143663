/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var _get = require('lodash.get');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(formData) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      SubmitComplaint: {
        $xmlns: 'http://dobs.pa.gov/cs/complaint/bpm/1.0',
        Complaint: {
          ComplaintID: formData.complaintId,
          InitialOpenedDate: formData.initialOpenedDate,
          FinalClosedDate: formData.finalClosedDate,
          StatusID: formData.statusId,
          WeightID: formData.weightId,
          ContactMethodID: formData.contactMethodId,
          Synopsis: formData.synopsis,
          Outcome: formData.outcome,
          ComplainantFirstName: formData.complainantFirstName,
          ComplainantLastName: formData.complainantLastName,
          ComplainantPrefix: formData.complainantPrefix,
          ComplainantEmail: formData.complainantEmail,
          ComplainantPhoneNumber: formData.complainantPhoneNumber,
          ComplainantCellPhone: formData.complainantCellPhone,
          ComplainantFaxNumber: formData.complainantFaxNumber,
          ComplainantAddressID: formData.complainantAddressId,
          AddressLine1: formData.addressLine1,
          AddressLine2: formData.addressLine2,
          AddressLine3: formData.addressLine3,
          County: formData.county,
          City: formData.city,
          State: formData.state,
          PostalCode: formData.postalCode,
          Country: formData.country,
          ComplainantAccountNumber: formData.complainantAccountNumber,
          ReferredFromID: formData.referredFromId,
          ReferredToID: formData.referredToId,
          CaseManager: formData.caseManagerId,
          IsTimely: formData.isTimely,
          IsConsumerDisputed: formData.isConsumerDisputed,
          ProductTypeID: formData.productTypeId,
          ComplaintCode1ID: formData.complaintCode1Id,
          ComplaintCode1Other: formData.complaintCode1Other,
          ComplaintCode2ID: formData.complaintCode2Id,
          ComplaintCode2Other: formData.complaintCode2Other,
          RefundAmount: formData.refundAmount,
          RefundDate: formData.refundDate,
          FundedLoanAmount: formData.fundedLoanAmount,
          FundedLoanDate: formData.fundedLoanDate,
          DepartmentID: formData.departmentId,
          DepartmentID2: formData.departmentId2,
          IsEntityUnknown: formData.isEntityUnknown,
          EntityID: formData.entityId,
          IsIndividualUnknown: formData.isIndividualUnknown,
          IndividualTypeID: formData.individualTypeId,
          IndividualID: formData.individualId,
          EntityType2ID: formData.entityType2Id,
          ContactFirstName: formData.contact.firstName,
          ContactLastName: formData.contact.lastName,
          ContactTitle: formData.contact.title,
          ContactPhoneNumber: formData.contact.phoneNumber,
          ContactFax: formData.contact.fax,
          ContactEmail: formData.contact.email,
          ContactComments: formData.contact.comments,
          ContactAddressLine1: formData.contact.addressLine1,
          ContactAddressLine2: formData.contact.addressLine2,
          ContactAddressLine3: formData.contact.addressLine3,
          ContactCounty: formData.contact.county,
          ContactCity: formData.contact.city,
          ContactState: formData.contact.state,
          ContactPostalCode: formData.contact.postalCode,
          ContactCountry: formData.contact.country,
          IsDeleted: formData.isDeleted,
          IsContactUnknown: formData.contact.isContactUnknown,
          IsPotentialViolation: formData.isPotentialViolation,
          Regulation: formData.regulation,
          ContactID: formData.contact.contactId,
          IsContactArchived: formData.contact.isContactArchived,
          OpenCloseHistories: (function() {
            if (!_get(formData, 'openCloseHistory.length')) {
              return {};
            }
            return _.map(formData.openCloseHistory, function(openCloseHistory) {
              return {
                OpenCloseHistory: {
                  OpenedDate: openCloseHistory.openedDate,
                  ClosedDate: openCloseHistory.closedDate,
                  Comments: openCloseHistory.comments
                }
              };
            });
          })()
        }
      }
    },
    blueprint: {
      complaintId: '$..ComplaintID'
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      deferred.resolve(response.complaintId);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
