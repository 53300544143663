/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(formData) {
  var defer = $.Deferred();

  $.soap({
    body: {
      GetActiveLicensesByTypeAndEntity: {
        $xmlns: 'http://dobs.pa.gov/nd/license/bpm/1.0',
        LicenseTypeID: formData.licenseTypeId,
        EntityID: formData.entityId
      }
    },
    blueprintArrays: ['GetActiveLicensesByTypeAndEntityResponse.ND_License'],
    blueprint: {
      licenses: [
        '$..ND_License',
        {
          id: '$.ID',
          licenseNumber: '$.LicenseNumber',
          addressLine1: '$.AddressLine1',
          licenseType: '$.LicenseType'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      defer.resolve(response.licenses);
    })
    .fail(function(err) {
      defer.reject();
    });

  return defer.promise();
};
