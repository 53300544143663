var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "    <a role=\"button\" class=\"btn btn-primary\" href=\"#nondep/time-tracking/my-list\" target=\"_blank\">Open\r\n      Past Timesheets</a>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"dropdown-container\"></div>\r\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression;

  return "<div class=\"header-bar\"></div>\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showReopenButton : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showReopenButton : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "<div class=\"clearfix\">\r\n  <h3 class=\"pull-left m-t-sm\">Time Entries</h3>\r\n  <button class=\"btn btn-readonly add pull-right btn-primary add-entry m-t-sm\">Add Entry</button>\r\n</div>\r\n<div class=\"time-entries-section\">\r\n  <div class=\"labels row\">\r\n    <div class=\"col-time-category\">\r\n      <label class=\"control-label\">Category</label>\r\n    </div>\r\n    <div class=\"col-time-exam\">\r\n      <label class=\"control-label\">Exam</label>\r\n    </div>\r\n    <div class=\"col-time-hrs\">\r\n      <div class=\"row\">\r\n        <div class=\"col-hr-label\">\r\n          <div class=\"form-group\">\r\n            <label class=\"control-label\">Mon.</label>\r\n            <p>"
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.payPeriod : depth0)) != null ? stack1.startDayPlus1Display : stack1), depth0))
    + "</p>\r\n          </div>\r\n        </div>\r\n        <div class=\"col-hr-label\">\r\n          <div class=\"form-group\">\r\n            <label class=\"control-label\">Tue.</label>\r\n            <p>"
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.payPeriod : depth0)) != null ? stack1.startDayPlus2Display : stack1), depth0))
    + "</p>\r\n          </div>\r\n        </div>\r\n        <div class=\"col-hr-label\">\r\n          <div class=\"form-group\">\r\n            <label class=\"control-label\">Wed.</label>\r\n            <p>"
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.payPeriod : depth0)) != null ? stack1.startDayPlus3Display : stack1), depth0))
    + "</p>\r\n          </div>\r\n        </div>\r\n        <div class=\"col-hr-label\">\r\n          <div class=\"form-group\">\r\n            <label class=\"control-label\">Thurs.</label>\r\n            <p>"
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.payPeriod : depth0)) != null ? stack1.startDayPlus4Display : stack1), depth0))
    + "</p>\r\n          </div>\r\n        </div>\r\n        <div class=\"col-hr-label\">\r\n          <div class=\"form-group\">\r\n            <label class=\"control-label\">Fri.</label>\r\n            <p>"
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.payPeriod : depth0)) != null ? stack1.startDayPlus5Display : stack1), depth0))
    + "</p>\r\n          </div>\r\n        </div>\r\n        <div class=\"col-hr-label\">\r\n          <div class=\"form-group\">\r\n            <label class=\"control-label\">Mon.</label>\r\n            <p>"
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.payPeriod : depth0)) != null ? stack1.startDayPlus8Display : stack1), depth0))
    + "</p>\r\n          </div>\r\n        </div>\r\n        <div class=\"col-hr-label\">\r\n          <div class=\"form-group\">\r\n            <label class=\"control-label\">Tue.</label>\r\n            <p>"
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.payPeriod : depth0)) != null ? stack1.startDayPlus9Display : stack1), depth0))
    + "</p>\r\n          </div>\r\n        </div>\r\n        <div class=\"col-hr-label\">\r\n          <div class=\"form-group\">\r\n            <label class=\"control-label\">Wed.</label>\r\n            <p>"
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.payPeriod : depth0)) != null ? stack1.startDayPlus10Display : stack1), depth0))
    + "</p>\r\n          </div>\r\n        </div>\r\n        <div class=\"col-hr-label\">\r\n          <div class=\"form-group\">\r\n            <label class=\"control-label\">Thurs.</label>\r\n            <p>"
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.payPeriod : depth0)) != null ? stack1.startDayPlus11Display : stack1), depth0))
    + "</p>\r\n          </div>\r\n        </div>\r\n        <div class=\"col-hr-label\">\r\n          <div class=\"form-group\">\r\n            <label class=\"control-label\">Fri.</label>\r\n            <p>"
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.payPeriod : depth0)) != null ? stack1.startDayPlus12Display : stack1), depth0))
    + "</p>\r\n          </div>\r\n        </div>\r\n      </div>\r\n    </div>\r\n  </div>\r\n  <form class=\"time-entries-container\"></form>\r\n  <div class=\"time-totals-container\"></div>\r\n</div>\r\n<div class=\"row m-t-lg m-b-lg\">\r\n  <div class=\"col-expenses-section\">\r\n    <div class=\"clearfix\">\r\n      <h3 class=\"pull-left\">Expenses</h3>\r\n      <button class=\"btn btn-readonly add pull-right btn-primary add-expense\">Add Expense</button>\r\n    </div>\r\n    <div class=\"expenses-section\">\r\n      <div class=\"labels row\">\r\n        <div class=\"col-exp-exam\">\r\n          <label class=\"control-label\">Exam</label>\r\n        </div>\r\n        <div class=\"col-expenses\">\r\n          <label class=\"control-label\">Expenses</label>\r\n        </div>\r\n      </div>\r\n      <form class=\"expenses-container\"></form>\r\n    </div>\r\n  </div>\r\n  <div class=\"col-comments-section\">\r\n    <h3>Comments</h3>\r\n    <textarea class=\"form-control align-comments\" name=\"comments\" rows=\"7\" placeholder=\"Comments\"></textarea>\r\n    <div class=\"pull-right m-t-lg m-b-lg\">\r\n      <button class=\"btn btn-readonly btn-default save ladda-button\" data-style=\"expand-right\" type=\"button\">\r\n        <span class=\"ladda-label\"><i class=\"fa fa-save\"></i> Save</span>\r\n      </button>\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.reviewer : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.examiner : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </div>\r\n  </div>\r\n</div>\r\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "    <a class=\"btn btn-primary reopen ladda-button m-b-sm\" data-style=\"expand-right\">\r\n      <span class=\"ladda-label\">Reopen Timesheet</span>\r\n    </a>\r\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "<div class=\"alert alert-info\" role=\"alert\">\r\n  <p class=\"title\">\r\n    <i class=\"fa fa-check\"></i>\r\n    <span>This timesheet was submitted by "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.timesheet : depth0)) != null ? stack1.submittedByFirstName : stack1), depth0))
    + " "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.timesheet : depth0)) != null ? stack1.submittedByLastName : stack1), depth0))
    + "\r\n      ("
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.timesheet : depth0)) != null ? stack1.submittedByUsername : stack1), depth0))
    + ") on "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.timesheet : depth0)) != null ? stack1.submittedOn : stack1), depth0))
    + ".</span>\r\n  </p>\r\n</div>\r\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "      <button class=\"btn btn-readonly btn-primary submit ladda-button\" data-style=\"expand-right\" type=\"button\">\r\n        <span class=\"ladda-label\">Mark as Reviewed</span>\r\n      </button>\r\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "      <button class=\"btn btn-readonly btn-primary submit ladda-button\" data-style=\"expand-right\" type=\"button\">\r\n        <span class=\"ladda-label\">"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isResubmit : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.program(15, data, 0),"data":data})) != null ? stack1 : "")
    + "</span>\r\n      </button>\r\n";
},"13":function(container,depth0,helpers,partials,data) {
    return "Resubmit";
},"15":function(container,depth0,helpers,partials,data) {
    return "Submit";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "\r\n<div class=\"page-title clearfix\">\r\n  <h1 class=\"pull-left m-n p-n\">Time Tracking</h1>\r\n  <div class=\"page-title-buttons-container\">\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.examiner : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.reviewer : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  </div>\r\n</div>\r\n<div class=\"alert alert-info invalid-timesheet-container collapse\" role=\"alert\">\r\n  <p class=\"title\">\r\n    <span>User has no timesheet available. Please contact your administrator.</span>\r\n  </p>\r\n</div>\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.validTimesheet : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"useData":true});