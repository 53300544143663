/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var _get = require('lodash.get');

module.exports = function(formData) {
  var filingId = formData.filingId;
  var pointOfContact = formData.pointOfContact;
  var lawyerExternalUserId = _get(formData, 'creditUnionParitySubmissionLawyer.lawyerExternalUserId');
  var requiredInfoAndDoc = formData.requiredInfoAndDoc;
  var supportingDocuments = formData.supportingDocuments || [];
  var parityAgencyInterpretiveDocuments = requiredInfoAndDoc.parityAgencyInterpretiveDocuments || [];
  var certification = formData.creditUnionParityCertification || {};

  return $.soap({
    body: {
      SubmitCreditUnionParityFiling: {
        $xmlns: 'http://dobs.pa.gov/dp/filing/bpm/1.0',
        FilingID: filingId,
        CUParityFilingID: formData.cuParityFilingId,
        PointOfContact: {
          Name: pointOfContact.name,
          Title:pointOfContact.title,
          Phone: pointOfContact.phone,
          Email: pointOfContact.email,
          AddressLine1: pointOfContact.addressLine1,
          AddressLine2: pointOfContact.addressLine2,
          AddressLine3: pointOfContact.addressLine3,
          City: pointOfContact.city,
          State: pointOfContact.state,
          PostalCode: pointOfContact.postalCode,
          County: pointOfContact.county,
          Country: pointOfContact.country
        },
        LawyerExternalUserID: lawyerExternalUserId,
        ActivityTypeID: requiredInfoAndDoc.activityTypeId,
        ProposedActivity: requiredInfoAndDoc.proposedActivity,
        IsCitationUSC: requiredInfoAndDoc.isCitationUSC ? 1 : 0,
        CitationUSCComments: requiredInfoAndDoc.citationUSCComments,
        IsCitationCFR: requiredInfoAndDoc.isCitationCFR ? 1 : 0,
        CitationCFRComments: requiredInfoAndDoc.citationCFRComments,
        IsCitationOther: requiredInfoAndDoc.isCitationOther ? 1 : 0,
        CitationOtherComments: requiredInfoAndDoc.citationOtherComments,
        IsCitationInterpretiveLetter: requiredInfoAndDoc.isCitationInterpretiveLetter ? 1 : 0,
        CitationInterpretiveLetterComments: requiredInfoAndDoc.citationInterpretiveLetterComments,
        CitationBasisForBankingInstitution: requiredInfoAndDoc.citationBasisForBankingInstitution,
        RiskAnalysisDocID: requiredInfoAndDoc.riskAnalysisDocId,
        ContactInformationDocID: requiredInfoAndDoc.contactInformationDocId,
        ContactInformationComments: requiredInfoAndDoc.contactInformationComments,
        ImposedRestrictionsDocID: requiredInfoAndDoc.imposedRestrictionsDocId,
        IsCertified: certification.isCertified ? '1' : '0',
        CertificationName: certification.certificationName,
        CertificationDocID: certification.certificationDocId,
        ParityAgencyInterpretiveDocuments: (function() {
          if (!parityAgencyInterpretiveDocuments.length) {
            return {};
          }
          return {
            Document: _.map(parityAgencyInterpretiveDocuments, function(parityAgencyInterpretiveDocuments) {
              return {
                DocumentID: parityAgencyInterpretiveDocuments.fileId
              };
            })
          };
        })(),
        SupportingDocuments: (function() {
          if (!supportingDocuments.length) {
            return {};
          }
          return {
            Document: _.map(supportingDocuments, function(supportingDocument) {
              return {
                DocumentID: supportingDocument.fileId
              };
            })
          };
        })()
      }
    }
  });
};
