/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');

var PaymentInvoiceView = require('common/views/PaymentInvoiceView');

var tmplDraftLicensePaymentView = require('../templates/DraftLicensePaymentView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDraftLicensePaymentView,

  className: 'draft-license-payment-view',

  ui: {
    paymentInvoice: '.payment-invoice'
  },

  regions: {
    paymentInvoice: '@ui.paymentInvoice'
  },

  onBeforeShow: function() {
    var description =
      this.model.get('applicationDetails').licenseType + ' ' + this.model.get('applicationDetails').entityName;
    this.showChildView(
      'paymentInvoice',
      new PaymentInvoiceView({
        model: new Backbone.Model({
          department: 'NON-DEPOSITORY',
          paymentObjectType: 'APPLICATION',
          paymentObjectId: this.model.get('applicationDetails').applicationId,
          items: [
            {
              description: 'Application Fee' + ' ' + description,
              quantity: 1,
              unitPrice: this.model.get('applicationDetails').applicationFee
            }
          ]
        })
      })
    );
  }
});
