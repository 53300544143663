/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

module.exports = function(timesheets, unitPrice) {
  var userHours = timesheets.userHours || {};
  _.each(userHours, function(entry) {
    entry.description = entry.firstName + ' ' + entry.lastName + ' Billable Hours';
    entry.quantity = entry.totalHours;
    entry.unitPrice = unitPrice;
  });

  // add expenses at the end of the users array
  if (timesheets.expenses) {
    userHours.push({ description: 'Expenses', quantity: 1, unitPrice: timesheets.expenses });
  }
  return userHours;
};
