/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

var momentUtil = require('common/util/momentUtil');
var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(financialStatementId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetFinancialStatement: {
        $xmlns: 'http://dobs.pa.gov/sc/financialstatement/bpm/1.0',
        FinancialStatementID: financialStatementId
      }
    },
    blueprintArrays: ['GetFinancialStatementResponse.Documents.SC_FinancialStatementDocument'],
    blueprint: {
      csFolderId: '$..CSFolderID',
      financialStatementId: '$..ID',
      organizationId: '$..OrganizationID',
      objectTypeId: '$..ObjectTypeID',
      objectId: '$..ObjectID',
      reviewStatusId: '$..ReviewStatusID',
      reviewStatus: '$..ReviewStatus',
      workflowStatusId: '$..WorkflowStatusID',
      fiscalYearEndDate: '$..FYEndYearDate',
      externalComments: '$..ExternalComments',
      internalComments: '$..InternalComments',
      chiefAccountant: '$..ChiefAccountant',
      documents: [
        '$..SC_FinancialStatementDocument',
        {
          file: {
            fileId: '$.DocumentID',
            fileName: '$..DocumentName'
          },
          documentTypeId: '$.DocumentTypeID',
          documentType: '$.DocumentType',
          isInternal: '$.IsInternal'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      response.internalDocuments = _.where(response.documents, { isInternal: '1' });
      response.externalDocuments = _.where(response.documents, { isInternal: '0' });

      delete response.documents;

      var dateCfg = _.extend(momentUtil.defaultInboundDateConfig, { default: undefined });

      response.fiscalYearEndDate = momentUtil.formatStr(response.fiscalYearEndDate, dateCfg);

      deferred.resolve(response);
    })
    .fail(function() {
      deferred.reject();
    });

  return deferred.promise();
};
