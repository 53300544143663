/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Backbone = require('backbone');
var Marionette = require('backbone.marionette');

var callByNeed = require('common/util/callByNeed');
var ActivePageSection = require('common/util/ActivePageSection');

var SideNavContentCollectionView = require('./SideNavContentCollectionView');
var SideNavSectionButtonCollectionView = require('./SideNavSectionButtonCollectionView');
var SideNavLinkCollectionView = require('./SideNavLinkCollectionView');

require('./SideNavSectionView.css');
var tmplSideNavSectionView = require('./SideNavSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplSideNavSectionView,

  className: function() {
    var baseClass = 'side-nav-section-view';
    if (this.options.expanded) {
      return baseClass + ' side-nav-section-view-expanded';
    }
    return baseClass;
  },

  ui: {
    linksContainer: '.links-container',
    buttonsContainer: '.buttons-container',
    contentsContainer: '.contents-container',
    previousButton: '.previous-button',
    nextButton: '.next-button'
  },

  regions: {
    linksContainer: '@ui.linksContainer',
    buttonsContainer: '@ui.buttonsContainer',
    contentsContainer: '@ui.contentsContainer'
  },

  events: {
    'click @ui.previousButton': 'onClickPrevious',
    'click @ui.nextButton': 'onClickNext'
  },

  childEvents: {
    'navlink:clicked': 'onChildNavLinkClick'
  },

  templateHelpers: function() {
    return {
      useNavigationButtons: this.options.useNavigationButtons,
      expanded: this.options.expanded,
      contentWidth: this.options.contentWidth,
      useNavigationSavePrevButtons: this.options.useNavigationSavePrevButtons
    };
  },

  options: {
    expanded: false, // show the sections all at once without navigation
    contentWidth: 'full' // 'full' or 'medium'. Sets how much of the page the content should take up.
   },
  initialize: function(options) {
    if (!_.contains(['medium', 'full'], options.contentWidth)) {
      throw new Error('The contentWidth option of SideNavSectionView must be either "full" or "medium"');
    }
  },

  onRender: function() {
    var activeSectionId = ActivePageSection.get();

    // Remove all entries from collection where renderable is false
    var renderableModels = this.collection.filter(function(model) {
      if (model.has('renderable')) {
        return callByNeed(model.get('renderable'));
      } else {
        return true;
      }
    });
    this.collection.reset(renderableModels);

    if (!this.options.expanded) {
      this.linkCollectionView = new SideNavLinkCollectionView({
        collection: this.collection,
        activeSectionId: activeSectionId
      });
      this.showChildView('linksContainer', this.linkCollectionView);
    }

    if (this.options.buttons && _.isArray(this.options.buttons)) {
      this.showChildView(
        'buttonsContainer',
        new SideNavSectionButtonCollectionView({
          collection: new Backbone.Collection(this.options.buttons)
        })
      );
    }

    this.contentCollectionView = new SideNavContentCollectionView({
      collection: this.collection,
      sideNavView: this,
      activeSectionId: activeSectionId,
      expanded: this.options.expanded
    });
    this.showChildView('contentsContainer', this.contentCollectionView);

    this.setNavigationButtonVisiblity();

    var activeSectionExists = !!this.collection.get(activeSectionId);
    if (!activeSectionExists) {
      this.setActiveSectionInSession(this.collection.at(0).id);
    }
  },

  onClickNext: function() {
    var activeLinkIndex = parseInt(this.linkCollectionView.getActiveLinkIndex());
    var nextActiveLinkIndex = activeLinkIndex + 1;
    this.linkCollectionView.setActiveLinkByIndex(nextActiveLinkIndex);
    this.setActiveSectionInSession();
    this.setNavigationButtonVisiblity();
    window.scrollTo(0, 0);
  },

  onClickPrevious: function() {
    var activeLinkIndex = parseInt(this.linkCollectionView.getActiveLinkIndex());
    var nextActiveLinkIndex = activeLinkIndex - 1;
    this.linkCollectionView.setActiveLinkByIndex(nextActiveLinkIndex);
    this.setActiveSectionInSession();
    this.setNavigationButtonVisiblity();
    window.scrollTo(0, 0);
  },

  onChildNavLinkClick: function() {
    this.setActiveSectionInSession();
    this.setNavigationButtonVisiblity();
    window.scrollTo(0, 0);
  },

  setActiveSectionInSession: function(sectionId) {
    sectionId = sectionId || this.linkCollectionView.getActiveLinkId();
    ActivePageSection.set(sectionId);
  },

  setNavigationButtonVisiblity: function() {
    if (!this.linkCollectionView) {
      return;
    }
    var activeLinkIndex = parseInt(this.linkCollectionView.getActiveLinkIndex());
    if (activeLinkIndex >= this.collection.length - 1) {
      this.ui.nextButton.hide();
    }
    if (activeLinkIndex < this.collection.length - 1) {
      this.ui.nextButton.show();
    }
    if (activeLinkIndex === 0) {
      this.ui.previousButton.hide();
    }
    if (activeLinkIndex > 0) {
      this.ui.previousButton.show();
    }
  },

  /**
   * @summary will iterate over each section in the SideNav and validate each child view
   * @param {Object} config - The configuration object which tells each view how to validate
   * @param {string} config.type - The type of valdiation to perform. Should be 'save' or 'submit'
   */
  validateAll: function(config) {
    // If a config object is not passed in, set a default
    if (!config) {
      config = {
        type: 'submit'
      };
    }
    // If a config object is passed in, but type is not set, set its default value
    if (!config.type) {
      config.type = 'submit';
    }
    var arrayOfPromises = this.collection.reduce(function(memo, sectionModel) {
      var view = sectionModel.get('view');
      if (!view) {
        return memo;
      }
      if (view.validate && _.isFunction(view.validate)) {
        var validateView = view.validate.bind(view);
        var promise = validateView(config);
        promise
          .done(function() {
            sectionModel.trigger('no:errors', false);
          })
          .fail(function() {
            sectionModel.trigger('has:errors', true);
          });
        memo.push(promise);
        return memo;
      } else {
        return memo;
      }
    }, []);
    return $.when.apply($, arrayOfPromises);
  },

  getFormData: function() {
    return this.collection.reduce(function(memo, sectionModel) {
      var view = sectionModel.get('view');
      if (!view || !sectionModel.id) {
        return memo;
      }
      if (view.getFormData && _.isFunction(view.getFormData)) {
        var getFormDataResults = view.getFormData();
        if (getFormDataResults) {
          memo[sectionModel.id] = getFormDataResults;
        }
      }
      return memo;
    }, {});
  },

  getFormDataBySection: function(sectionId) {
    var sectionViewModel = this.collection.find(function(sectionModel) {
      if (sectionModel.id === sectionId) {
        return true;
      }
    });
    var sectionView = sectionViewModel.get('view') || {};
    if (sectionView.getFormData && _.isFunction(sectionView.getFormData)) {
      return sectionView.getFormData();
    }
  },

  getViewBySection: function(sectionId) {
    var sectionViewModel = this.collection.find(function(sectionModel) {
      if (sectionModel.id === sectionId) {
        return true;
      }
    });
    var sectionView = sectionViewModel.get('view') || {};
    return sectionView;
  }
});
