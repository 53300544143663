/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Ladda = require('ladda');

var SubmitComplianceStatus = require('../services/SubmitComplianceStatus');

var tmplNonDepComplianceEditStatusModalView = require('../templates/NonDepComplianceEditStatusModalView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepComplianceEditStatusModalView,

  className: 'non-dep-compliance-edit-status-modal-view',

  ui: {
    complianceStatusForm: '.compliance-status-form',
    submit: '.submit'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  initialize: function(options) {
    this.submitNoteService = options.submitNoteService;
    this.managerRole = options.managerRole;
  },

  onBeforeShow: function() {
    Syphon.deserialize(this, this.model.get('case'));
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;
    this.ui.complianceStatusForm
      .parsley()
      .whenValidate()
      .done(function() {
        var l = Ladda.create(self.ui.submit[0]);
        l.start();
        var formData = Syphon.serialize(self);
        formData.complianceId = self.model.get('complianceId');
        formData.isUserUpdate = '1';

        SubmitComplianceStatus(formData)
          .done(function() {
            self.triggerMethod('modal:close');
          })
          .fail(function() {
            l.stop();
          });
      });
  }
});
