/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var NProgress = require('nprogress');

var NavUtils = require('psa-core/util/nav');

var Scaffold = require('common/layout/Scaffold');

var DepProvisionEditView = require('../views/DepProvisionEditView');

var GetProvision = require('../services/GetProvision');
var GetAllEnforcementLookups = require('../services/GetAllEnforcementLookups');
var GetEnforcementIdentifiers = require('../services/GetEnforcementIdentifiers');

module.exports = function(entityId, enforcementId, provisionId) {
  NProgress.start();
  NProgress.set(0.6);
  $.when(GetEnforcementIdentifiers(enforcementId), GetProvision(provisionId))
    .done(function(enforcementIdentifiers, provision) {
      GetAllEnforcementLookups(enforcementIdentifiers.categoryId)
        .done(function(enforcementLookups) {
          NavUtils.navigate(
            'dep/entity/' + entityId + '/enforcement/' + enforcementId + '/provision/' + provisionId + '/edit'
          );
          Scaffold.content.show(
            new DepProvisionEditView({
              viewMode: 'int',
              model: new Backbone.Model({
                entityId: entityId,
                enforcementId: enforcementId,
                provisionId: provisionId,
                enforcementIdentifiers: enforcementIdentifiers,
                provision: provision,
                provisionCategories: enforcementLookups.provisionCategories,
                provisionStatuses: enforcementLookups.provisionStatuses
              })
            })
          );
          NProgress.done();
        })
        .fail(function(err) {
          NProgress.done();
        });
    })
    .fail(function(err) {
      NProgress.done();
    });
};
