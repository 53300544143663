/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var cleanUpResponse = require('../util/cleanUpResponse');

module.exports = function(documentId) {
  var defer = $.Deferred();

  $.soap({
    body: {
      GetDoesDocumentExist: {
        $xmlns: 'http://dobs.pa.gov/cm/documentmgmt/bpm/1.0',
        DocumentID: documentId
      }
    },
    blueprint: {
      documentExists: '$..DocumentExists.__text'
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      defer.resolve(response.documentExists === '1');
    })
    .fail(function(err) {
      defer.reject();
    });

  return defer.promise();
};
