var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression;

  return "\r\n<div class=\"page-title clearfix\">\r\n  <h1 class=\"pull-left m-n p-n\">"
    + alias1(container.lambda(((stack1 = (depth0 != null ? depth0.record : depth0)) != null ? stack1.recordName : stack1), depth0))
    + "</h1>\r\n  <div class=\"page-title-buttons-container\">\r\n    <div class=\"cs-link-container\"></div>\r\n  </div>\r\n</div>\r\n<div class=\"header-bar\"></div>\r\n<div class=\"breadcrumb-container\"></div>\r\n<div class=\"form-horizontal\">\r\n  <div class=\"row\">\r\n    <div class=\"col-sm-11 col-md-8 col-md-offset-1\">\r\n      <div class=\"row\">\r\n        <div class=\"form-group\">\r\n          <div class=\"col-sm-9 col-sm-offset-3\">\r\n            <h3 class=\"m-t-n\">New Investment History</h3>\r\n          </div>\r\n        </div>\r\n      </div>\r\n      <form class=\"inv-history-form-container\"></form>\r\n      <div class=\"row m-b-lg\">\r\n        <div class=\"form-group\">\r\n          <div class=\"col-sm-9 col-sm-offset-3\">\r\n            <a href=\"#corpfin/investment/"
    + alias1(((helper = (helper = helpers.recordId || (depth0 != null ? depth0.recordId : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"recordId","hash":{},"data":data}) : helper)))
    + "/dashboard\" class=\"btn btn-default\">Cancel</a>\r\n            <button class=\"btn btn-primary submit ladda-button\" data-style=\"expand-right\" type=\"button\"> <span\r\n                class=\"ladda-label\">Submit</span></button>\r\n            </button>\r\n          </div>\r\n        </div>\r\n      </div>\r\n    </div>\r\n  </div>\r\n</div>";
},"useData":true});