/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var NProgress = require('nprogress');
var Backbone = require('backbone');

var Scaffold = require('common/layout/Scaffold');
var NavUtils = require('psa-core/util/nav');

var GetLicenseIdentifiers = require('../services/GetLicenseIdentifiers');
var GetLicenseDetails = require('../services/GetLicenseDetails');
var GetExamPeriods = require('../../exam-dashboard/services/GetExamPeriods');
var GetAllRegions = require('../../common/services/GetAllRegions');
var GetUsersForRole = require('common/services/GetUsersForRole');

var NonDepLicenseInitiateExamView = require('../views/NonDepLicenseInitiateExamView');

module.exports = function(entityId, licenseId) {
  NProgress.start();
  NProgress.set(0.6);

  $.when(
    GetLicenseIdentifiers(licenseId),
    GetLicenseDetails(licenseId),
    GetExamPeriods(),
    GetAllRegions(),
    GetUsersForRole('ND - Examiner')
  )
    .done(function(licenseHeader, licenseDetails, examPeriods, examRegions, examiners) {
      NavUtils.navigate('nondep/entity/' + entityId + '/license/' + licenseId + '/initiate-exam');
      Scaffold.content.show(
        new NonDepLicenseInitiateExamView({
          model: new Backbone.Model({
            header: licenseHeader,
            entityId: entityId,
            licenseId: licenseId,
            examPeriods: examPeriods,
            examRegions: examRegions,
            examiners: examiners,
            previousExamDate: licenseDetails.previousExamDate,
            defaultRegionId: licenseDetails.nonDExamRegionId,
            licenseAddress: licenseDetails.licenseAddress
          })
        })
      );
      NProgress.done();
    })
    .fail(function() {
      NProgress.done();
    });
};
