/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Backbone = require('backbone');
var Backgrid = require('backgrid');
require('backgrid-paginator');

var isFalsyForSort = function(value) {
  if (_.isString(value)) {
    return value === '' || value === '--';
  }
  return _.isNull(value) || _.isUndefined(value) || _.isNaN(value);
};

Backgrid.Body.prototype.makeComparator = function(attr, order, func) {
  return function(left, right) {
    // extract the values from the models
    var l = func(left, attr);
    var r = func(right, attr);

    // if descending order, swap left and right
    var t;
    if (order === 1) {
      t = l;
      l = r;
      r = t;
    }

    // compare as usual, except always sort falsy values last.
    if (l === r) {
      return 0;
    } else if (isFalsyForSort(l)) {
      return 1;
    } else if (isFalsyForSort(r)) {
      return -1;
    } else if (l < r) {
      return -1;
    } else {
      return 1;
    }
  };
};

Backbone.PageableCollection.prototype._makeComparator = function(sortKey, order, sortValue) {
  var state = this.state;

  sortKey = sortKey || state.sortKey;
  order = order || state.order;

  if (!sortKey || !order) {
    return;
  }

  if (!sortValue) {
    sortValue = function(model, attr) {
      return model.get(attr);
    };
  }

  return function(left, right) {
    var l = sortValue(left, sortKey);
    var r = sortValue(right, sortKey);

    // if descending order, swap left and right
    var t;
    if (order === 1) {
      t = l;
      l = r;
      r = t;
    }

    // compare as usual, except always sort falsy values last.
    if (l === r) {
      return 0;
    } else if (isFalsyForSort(l)) {
      return 1;
    } else if (isFalsyForSort(r)) {
      return -1;
    } else if (l < r) {
      return -1;
    } else {
      return 1;
    }
  };
};
