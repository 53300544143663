/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(entityId) {
  var deferred = $.Deferred();
  $.soap({
    body: {
      GetLocationsByEntity: {
        $xmlns: 'http://dobs.pa.gov/dp/location/bpm/1.0',
        EntityID: entityId
      }
    },
    blueprintArrays: ['GetLocationsByEntityResponse.DP_EntityLocation'],
    blueprint: {
      locations: [
        '$..DP_EntityLocation',
        {
          id: '$..ID',
          openedDate: '$..OpenedDate',
          closedDate: '$..ClosedDate',
          entityId: '$..EntityID',
          activity: '$..Activity',
          status: '$..Status',
          country: '$..Country',
          officeType: '$..OfficeType',
          address: {
            addressLine1: '$..AddressLine1',
            addressLine2: '$..AddressLine2',
            city: '$..City',
            state: '$..State',
            postalCode: '$..PostalCode',
            county: '$..County'
          },
          county: '$..County'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      _.each(response.locations, function(location) {
        location.activity = location.activity || '--';
        location.officeType = location.officeType || '--';

        if (location.openedDate) {
          location.openedDate = moment(location.openedDate, 'YYYY-MM-DD');
        }
        if (location.closedDate) {
          location.closedDate = moment(location.closedDate, 'YYYY-MM-DD');
        }
      });
      deferred.resolve(response.locations);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
