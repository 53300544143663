/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');
var Ladda = require('ladda');

var UIUtil = require('psa-core/util/ui');

var BreadcrumbView = require('common/views/BreadcrumbView');

var ConSerIndividualFormView = require('./ConSerIndividualFormView');
var ConSerEntitySearchModalView = require('./ConSerEntitySearchModalView');

var SubmitIndividual = require('../services/SubmitIndividual');

var tmplConSerIndividualNewView = require('../templates/ConSerIndividualNewView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplConSerIndividualNewView,

  className: 'con-ser-individual-new-view container-fluid',

  ui: {
    breadcrumb: '.breadcrumb-container',
    individualContainer: '.individual-form-container',
    entitySection: '.entity-section',
    entitySelectionForm: '.entity-selection-form',
    entitySearchButton: '.btn-entity-search',
    entityClearButton: '.clear-entity',
    selectedEntityId: '.selected-entity-id',
    selectedEntityName: '.selected-entity-name',
    submit: '.submit'
  },

  regions: {
    breadcrumb: '@ui.breadcrumb',
    individualContainer: '@ui.individualContainer'
  },

  events: {
    'click @ui.submit': 'onClickSubmit',
    'click @ui.entitySearchButton': 'onClickEntitySearchButton',
    'click @ui.entityClearButton': 'onClearEntity'
  },

  onBeforeShow: function() {
    var entityId = this.model.get('entityId');
    var entityName = this.model.get('entityName');

    var breadcrumbs = [
      {
        link: '#conser/dashboard',
        text: 'Home'
      },
      {
        text: 'New Individual'
      }
    ];

    if (entityId) {
      breadcrumbs.splice(1, 0, {
        link: '#conser/4000/entity/' + entityId + '/dashboard',
        text: entityName
      });
      this.ui.entitySection.hide();
    }
    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: breadcrumbs
      })
    );

    this.individualForm = new ConSerIndividualFormView({
      model: new Backbone.Model()
    });
    this.showChildView('individualContainer', this.individualForm);
  },

  onClickEntitySearchButton: function(e) {
    e.preventDefault();
    var self = this;

    UIUtil.showModal({
      title: 'Search for an Entity',
      dialogClasses: 'modal-dialog-entity-search-modal-view modal-lg',
      view: new ConSerEntitySearchModalView({
        model: self.model
      })
    }).done(function(entity) {
      if (entity) {
        self.ui.selectedEntityId.val(entity.entityId);
        self.ui.selectedEntityName.val(entity.entityName);
        self.validate();
      } else {
        self.ui.selectedEntityId.val('');
        self.ui.selectedEntityName.val('');
      }
    });
  },

  onClearEntity: function(e) {
    e.preventDefault();
    this.ui.selectedEntityId.val('');
    this.ui.selectedEntityName.val('');
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;
    this.validate().done(function() {
      var l = Ladda.create(self.ui.submit[0]);
      l.start();
      var formData = self.getFormData();
      SubmitIndividual(formData)
        .done(function(individualId) {
          Radio.channel('navigate').trigger('show:int:cs:individual', individualId);
        })
        .fail(function() {
          l.stop();
        });
    });
  },

  getFormData: function() {
    var formData = this.individualForm.getFormData();
    var entityId = this.model.get('entityId') || this.ui.selectedEntityId.val();
    formData.entityIds = [entityId];

    return formData;
  },

  validate: function() {
    return this.ui.individualContainer.parsley().whenValidate();
  }
});
