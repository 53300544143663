var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "          <option value=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.fundAction || (depth0 != null ? depth0.fundAction : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"fundAction","hash":{},"data":data}) : helper)))
    + "</option>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda;

  return "\r\n<div class=\"panel-body\">\r\n  <div class=\"row location-info\">\r\n    <div class=\"col-sm-1\">\r\n      <div class=\"row\">\r\n        <div class=\"col-sm-6\">\r\n          <div class=\"form-group\">\r\n            <label class=\"control-label\">#</label>\r\n            <div class=\"form-control-static\">"
    + alias4(((helper = (helper = helpers.childIndex || (depth0 != null ? depth0.childIndex : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"childIndex","hash":{},"data":data}) : helper)))
    + "</div>\r\n          </div>\r\n        </div>\r\n      </div>\r\n    </div>\r\n    <div class=\"col-sm-5\">\r\n      <div class=\"row\">\r\n        <div class=\"col-sm-6\">\r\n          <div class=\"form-group\">\r\n            <label class=\"control-label\">Subfund Name</label>\r\n            <div class=\"form-control-static\">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</div>\r\n          </div>\r\n        </div>\r\n      </div>\r\n    </div>\r\n    <div class=\"col-sm-6\">\r\n      <div class=\"form-group\">\r\n        <label class=\"control-label\">Select Action</label>\r\n        <select name=\"subfundActionId\" class=\"form-control action-type-dropdown\">\r\n          <option value=\"\" selected>--</option>\r\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.subFundActions : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </select>\r\n      </div>\r\n    </div>\r\n  </div>\r\n  <div class=\"amend-action-form\">\r\n    <div class=\"form-group required\">\r\n      <label class=\"control-label\">Summary of Changes</label>\r\n      <textarea name=\"summaryOfChanges\" class=\"form-control\" id=\"summaryOfChanges"
    + alias4(alias5(((stack1 = (depth0 != null ? depth0.subFundFiling : depth0)) != null ? stack1.id : stack1), depth0))
    + "\" placeholder=\"Summary of Changes\" rows=\"4\"\r\n        data-parsley-required=\"true\"></textarea>\r\n    </div>\r\n    <div class=\"form-group print-view\" id=\"summaryOfChanges"
    + alias4(alias5(((stack1 = (depth0 != null ? depth0.subFundFiling : depth0)) != null ? stack1.id : stack1), depth0))
    + "PrintView\"></div>\r\n  </div>\r\n  <div class=\"terminate-action-form\">\r\n    <div class=\"form-group required\">\r\n      <label class=\"control-label\">Termination Date</label>\r\n      <div class=\"input-group\">\r\n        <input type=\"text\" name=\"subfundTerminationDate\" class=\"form-control datepicker termination-date\"\r\n          placeholder=\"Termination Date\" data-parsley-required=\"true\" data-parsley-date=\"\"\r\n          data-parsley-trigger-after-failure=\"changeDate\"\r\n          data-parsley-errors-container=\"#terminationDateErrorContainer"
    + alias4(((helper = (helper = helpers.childIndex || (depth0 != null ? depth0.childIndex : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"childIndex","hash":{},"data":data}) : helper)))
    + "\" />\r\n        <div class=\"input-group-addon\">\r\n          <i class=\"fa fa-calendar\"></i>\r\n        </div>\r\n      </div>\r\n      <div id=\"terminationDateErrorContainer"
    + alias4(((helper = (helper = helpers.childIndex || (depth0 != null ? depth0.childIndex : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"childIndex","hash":{},"data":data}) : helper)))
    + "\"></div>\r\n    </div>\r\n  </div>\r\n</div>";
},"useData":true});