/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var FileUploadCollection = require('psa-backbone/models/FileUploadCollection');
var Radio = require('backbone.radio');
var Ladda = require('ladda');

var modelGet = require('common/util/modelGet');
var parsleyUtil = require('common/util/parsleyUtil');
var toggleForm = require('common/util/toggleForm');
var FileUploadView = require('common/views/FileUploadView');
var SelectFieldView = require('common/views/SelectFieldView');

var SubmitSubmitAuditFiling = require('../../services/SubmitSubmitAuditFiling');

require('../../styles/DepFilingPrintView.css');
var tmplDepSubmitAuditSubmissionSectionView = require('./DepSubmitAuditSubmissionSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepSubmitAuditSubmissionSectionView,

  className: 'dep-submit-audit-submission-section-view',

  ui: {
    submissionForm: '.submission-form',
    auditType: '.audit-type',
    missingFile: '.missing-file',
    supportingDocumentsContainer: '.supporting-documents-container',
    datePickerFields: '.date-picker',
    otherAuditType: '.other-audit-type',
    fundAuditType: '.fund-audit-type',
    fundAuditTypeCheckbox: 'input[name="isFund"]',
    subfundSelectContainer: '.subfund-select-container',
    fundName: '.fund-name',
    subfundAuditContainer: '.subfund-audit-container',
    subfundFormGroup: 'subfund-form-group',
    auditFileDocument: '.audit-file-document-container',
    auditfileFormGroup: '.audit-file-form-group',
    auditTypeFormGroup: '.audit-type-form-group',
    submit: '.submit'
  },

  regions: {
    supportingDocumentsContainer: '@ui.supportingDocumentsContainer',
    subfundSelectContainer: '@ui.subfundSelectContainer',
    auditFileDocument: '@ui.auditFileDocument'
  },

  events: {
    'change @ui.auditType': 'onChangeAuditType',
    'change @ui.fundName': 'onChangeFundName',
    'click @ui.submit': 'onClickSubmit'
  },

  behaviors: {
    ReadOnlyFormBehavior: {},
    RadioGroupToggleBehavior: {
      radios: [
        {
          target: '.subfund-audit-container',
          radioGroup: 'input[name="isFund"]',
          valueToShow: '0'
        }
      ]
    }
  },

  templateHelpers: function() {
    var isReadOnly = this.options.isReadOnly;
    var auditTypes = this.model.get('auditTypes');
    var isCIF = modelGet(this.model, 'filingDetails.isCIF') === '1';
    //  For Institution doesn't has CIF indicator checked, Trust Audit options is not available
    if (!isCIF) {
      auditTypes = _.filter(auditTypes, function(auditType) {
        return auditType.id !== '23';
      });
    }
    return {
      isReadOnly: isReadOnly,
      auditTypes: auditTypes,
      mode: this.options.mode
    };
  },

  onBeforeShow: function() {
    var isCreditUnion = modelGet(this.model, 'filingDetails.entityType') === 'Credit Union';

    var supportingDocuments = _.pluck(modelGet(this.model, 'filingContent.supportingDocuments', []), 'file');
    var auditFileDocId = modelGet(this.model, 'filingContent.auditFileDocument.fileId');
    if (auditFileDocId) {
      var auditFileDocument = modelGet(this.model, 'filingContent.auditFileDocument');
    }

    Syphon.deserialize(this, this.model.get('filingContent'));
    this.ui.datePickerFields.datepicker();

    this.supportingDocumentsView = new FileUploadView({
      isReadOnly: this.options.isReadOnly,
      allowMultipleFiles: true,
      collection: new FileUploadCollection(supportingDocuments),
      metadata: {
        documentTypeId: 20001
      }
    });
    this.showChildView('supportingDocumentsContainer', this.supportingDocumentsView);

    this.auditFileDocument = new FileUploadView({
      isReadOnly: this.options.isReadOnly,
      isRequired: !isCreditUnion,
      collection: new FileUploadCollection(auditFileDocument),
      metadata: {
        documentTypeId: 20001
      }
    });
    this.showChildView('auditFileDocument', this.auditFileDocument);

    toggleForm(this.ui.auditTypeFormGroup, !isCreditUnion);

    this.subfundSelect = new SelectFieldView({
      isRequired: true,
      name: 'subFundId',
      selectClass: 'subfund-select',
      collection: new Backbone.Collection([])
    });
    this.showChildView('subfundSelectContainer', this.subfundSelect);

    this.onChangeAuditType();
    this.onChangeFundName();
    this.subfundSelect.setValue(modelGet(this.model, 'filingContent.subFundId'));
  },

  onAttach: function() {
    if (this.options.mode === 'print') {
      this.switchToPrint();
    }
  },

  switchToPrint: function() {
    var textAreas = $('.input-view');

    _.each(textAreas, function(textArea) {
      var textAreaVal = textArea.value;
      var textAreaName = textArea.name;
      var printElementId = '#'.concat(textAreaName, 'PrintView');
      $(printElementId).text(textAreaVal);
    });
    toggleForm(textAreas, false);
  },

  onChangeAuditType: function() {
    var isOtherAuditType = this.ui.auditType.val() === '21' || this.ui.auditType.val() === '22';
    var isFundAuditType = this.ui.auditType.val() === '23';

    toggleForm(this.ui.otherAuditType, isOtherAuditType);
    toggleForm(this.ui.fundAuditType, isFundAuditType);
  },

  onChangeFundName: function() {
    var fundId = this.ui.fundName.val();

    var trustSubfunds = modelGet(this.model, 'filingContent.trustSubfunds');
    if (fundId) {
      var filteredTrustSubfunds = _.filter(trustSubfunds, function(fund) {
        return fund.trustFundId === fundId;
      });
      var options = _.map(filteredTrustSubfunds, function(filteredTrustSubfund) {
        return {
          value: filteredTrustSubfund.id,
          text: filteredTrustSubfund.name
        };
      });
      this.subfundSelect.collection.reset(options);
    }
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;

    var l = Ladda.create(this.ui.submit[0]);

    this.sideNavView.validateAll({ type: 'submit' }).done(function() {
      l.start();

      var formData = self.sideNavView.getFormData();
      formData.filingId = self.model.get('filingId');
      formData.submitAuditFilingId = self.model.get('submitAuditFilingId');

      SubmitSubmitAuditFiling(formData)
        .done(function() {
          Radio.channel('dp:draft-filing').trigger('proceed-to-payment', l);
          Radio.channel('dp:draft-filing').trigger('submit', l);
          Radio.channel('dp:submit-filing').trigger('resubmit', l);
        })
        .fail(function() {
          l.stop();
        });
    });
  },

  validate: function(config) {
    this.ui.submissionForm.parsley().reset();
    this.auditFileDocument.removeValidationErrors();
    this.supportingDocumentsView.removeValidationErrors();

    if (config.type === 'save') {
      // Turn off required field validation for this form
      parsleyUtil.disableRequiredValidation(this.ui.submissionForm);
    } else {
      var validationPromises = [
        this.ui.submissionForm.parsley().whenValidate(),
        this.supportingDocumentsView.validate(),
        this.auditFileDocument.validate()
      ];

      // Turn on required field validation for this form
      parsleyUtil.enableRequiredValidation(this.ui.submissionForm);
    }
    return $.when.apply($, validationPromises);
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    formData.supportingDocuments = this.supportingDocumentsView.collection.map(function(file) {
      return {
        fileId: file.get('fileId'),
        documentTypeId: modelGet(file, 'metadata.documentTypeId')
      };
    });
    formData.auditFileDocId = this.auditFileDocument.getFirstFileId();
    return formData;
  }
});
