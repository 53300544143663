/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var DropdownView = require('common/views/DropdownView');
var modelGet = require('common/util/modelGet');

var Config = require('../../../config/Config');

require('../styles/ComplianceJumpToDropdownView.css');

module.exports = DropdownView.extend({
  className: 'dropdown dropdown-view compliance-jump-to-dropdown-view',

  initialize: function() {
    var examId = modelGet(this.model, 'compliance.examId');
    var complianceId = modelGet(this.model, 'compliance.complianceId');
    var complianceCSFolderId = modelGet(this.model, 'compliance.csFolderId');
    var examCSFolderId = modelGet(this.model, 'compliance.examCSFolderId');

    var dropdownOptions = [
      {
        text: 'Compliance Dashboard',
        iconClass: 'fa-list-alt',
        link: '#nondep/compliance/case/' + complianceId,
        newTab: true
      }
    ];

    if (complianceCSFolderId) {
      var complianceCSFolderUrl = Config.getOTCSSmartUIUrl(complianceCSFolderId);
      dropdownOptions.push({
        text: 'Compliance Folder',
        iconClass: 'fa-folder-open-o',
        link: complianceCSFolderUrl,
        newTab: true
      });
    }

    if (examId) {
      var examCSFolderUrl = Config.getOTCSSmartUIUrl(examCSFolderId);

      dropdownOptions = dropdownOptions.concat([
        {
          isDivider: true
        },
        {
          text: 'Exam Dashboard',
          iconClass: 'fa-list-alt',
          link: '#nondep/dashboard/exam/' + examId,
          newTab: true
        },
        {
          text: 'Exam Folder',
          iconClass: 'fa-folder-open-o',
          link: examCSFolderUrl,
          newTab: true
        }
      ]);
    }

    this.collection = new Backbone.Collection(dropdownOptions);
  },

  onBeforeRender: function() {
    this.model.set('name', 'Jump To');
  }
});
