/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(data) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetLicenseInfoDetailByObject: {
        $xmlns: 'http://dobs.pa.gov/sc/licenseinfodetail/bpm/1.0',
        ObjectTypeID: data.objectTypeId,
        ObjectID: data.objectId
      }
    },
    blueprintArrays: ['GetLicenseInfoDetailByObjectResponse.SC_LicenseInfoDetail'],
    blueprint: {
      licenses: [
        '$..SC_LicenseInfoDetail',
        {
          id: '$..ID',
          objectTypeId: '$..ObjectTypeID',
          objectId: '$..ObjectID',
          descriptionId: '$..DescriptionID',
          effectiveDate: '$..EffectiveDate',
          note: '$..Note',
          description: '$..Description'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      _.each(response.licenses, function(license) {
        if (license.effectiveDate) {
          license.effectiveDate = moment(license.effectiveDate, 'YYYY-MM-DD');
        }
        license.note = license.note || '--';
      });

      deferred.resolve(response.licenses);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
