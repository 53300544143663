/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var modelGet = require('common/util/modelGet');
var toggleForm = require('common/util/toggleForm');

var tmplCorpFinInvInvestmentDetailFormView = require('../templates/CorpFinInvInvestmentDetailFormView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplCorpFinInvInvestmentDetailFormView,

  className: 'corp-fin-inv-investment-detail-form-view',

  behaviors: {
    RadioGroupToggleBehavior: {
      radios: [
        {
          target: '.offering-amounts',
          radioGroup: 'input[name="isIndefiniteOffering"]',
          valueToShow: '0'
        }
      ]
    }
  },

  ui: {
    recordType: '.record-type',
    filedDate: '.filed-date',
    increaseDate: '.increase-date',
    dateSentToRecord: '.date-sent-to-record',
    dateDestroyed: '.date-destroyed',
    FYEOn: '.fye-on',
    fye: '.fye',
    totalOfferingAmount: '[name="totalOfferingAmount"]'
  },

  regions: {},

  events: {
    'change @ui.recordType': 'onChangeRecordType'
  },

  templateHelpers: function() {
    var isNewInvestment = this.options.isNewInvestment;

    return {
      isNewInvestment: isNewInvestment,
      recordType: modelGet(this.model, 'record.recordType')
    };
  },

  onBeforeShow: function() {
    var record = this.model.get('record') || {};
    var isMutualFund = record.recordTypeId === '6';

    Syphon.deserialize(this, record);
    this.ui.filedDate.datepicker();
    this.ui.increaseDate.datepicker();
    this.ui.dateSentToRecord.datepicker();
    this.ui.dateDestroyed.datepicker();
    toggleForm(this.ui.fye, isMutualFund);
    this.ui.FYEOn.datepicker({ format: 'mm/dd' });
  },

  onChangeRecordType: function() {
    var recordTypeId = this.ui.recordType.val();
    var isMutualFund = recordTypeId === '6';
    toggleForm(this.ui.fye, isMutualFund);
  },

  getFormData: function() {
    return Syphon.serialize(this);
  }
});
