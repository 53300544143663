/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');

var NonDepEntityLocationCollectionView = require('./NonDepEntityLocationCollectionView');

var tmplNonDepEntitySectionLocationWrapperView = require('../templates/NonDepEntitySectionLocationView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepEntitySectionLocationWrapperView,

  className: 'nondep-entity-location-wrapper-view',

  ui: {
    locationsContainer: '.locations-container'
  },

  regions: {
    locationsContainer: '@ui.locationsContainer'
  },

  onBeforeShow: function() {
    this.showChildView(
      'locationsContainer',
      new NonDepEntityLocationCollectionView({
        viewMode: this.options.viewMode,
        collection: new Backbone.Collection(this.model.get('locations')),
        model: new Backbone.Model({ entityId: this.model.get('entityId') })
      })
    );
  }
});
