var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"name","hash":{},"data":data}) : helper)));
},"3":function(container,depth0,helpers,partials,data) {
    return "--";
},"5":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.county || (depth0 != null ? depth0.county : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"county","hash":{},"data":data}) : helper)));
},"7":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.stateOfIncorporation || (depth0 != null ? depth0.stateOfIncorporation : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"stateOfIncorporation","hash":{},"data":data}) : helper)));
},"9":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.entityType || (depth0 != null ? depth0.entityType : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"entityType","hash":{},"data":data}) : helper)));
},"11":function(container,depth0,helpers,partials,data) {
    return " Yes\r\n          ";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isNonProfit : depth0),"===","0",{"name":"ifCond","hash":{},"fn":container.program(14, data, 0),"inverse":container.program(16, data, 0),"data":data})) != null ? stack1 : "");
},"14":function(container,depth0,helpers,partials,data) {
    return " No\r\n          ";
},"16":function(container,depth0,helpers,partials,data) {
    return " --\r\n          ";
},"18":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.website || (depth0 != null ? depth0.website : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"website","hash":{},"data":data}) : helper)));
},"20":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.employeeCount || (depth0 != null ? depth0.employeeCount : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"employeeCount","hash":{},"data":data}) : helper)));
},"22":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        <div class=\"form-control-static individual-type-list col-sm-8\">\r\n          <ul class=\"list-unstyled\">"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.segIndividualList : depth0),{"name":"each","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</ul>\r\n        </div>\r\n        ";
},"23":function(container,depth0,helpers,partials,data) {
    return "<li>"
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "</li>";
},"25":function(container,depth0,helpers,partials,data) {
    return "<div class=\"form-control-static col-sm-8\">--</div>";
},"27":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.status || (depth0 != null ? depth0.status : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"status","hash":{},"data":data}) : helper)));
},"29":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.approvalDate || (depth0 != null ? depth0.approvalDate : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"approvalDate","hash":{},"data":data}) : helper)));
},"31":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.terminationDate || (depth0 != null ? depth0.terminationDate : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"terminationDate","hash":{},"data":data}) : helper)));
},"33":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.comments || (depth0 != null ? depth0.comments : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"comments","hash":{},"data":data}) : helper)));
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "\r\n\r\n<div class=\"seg-form form-horizontal form-static m-t-md\">\r\n  <h3 class=\"col-sm-offset-3 col-md-offset-2\">Select Employee Group</h3>\r\n  <div class=\"row\">\r\n    <div class=\"col-md-6 col-sm-12\">\r\n      <div class=\"form-group\">\r\n        <label class=\"control-label col-sm-3 col-md-4\">Name</label>\r\n        <div class=\"form-control-static col-sm-9 col-md-8\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.name : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\r\n      </div>\r\n    </div>\r\n  </div>\r\n  <h3 class=\"col-sm-offset-3 col-md-offset-2\">Business Address</h3>\r\n  <div class=\"row\">\r\n    <div class=\"col-md-6 col-sm-12\">\r\n      <div class=\"form-group\">\r\n        <label class=\"control-label col-sm-3 col-md-4\">Address</label>\r\n        <div class=\"address-container col-sm-9 col-md-8 m-t-sm\"></div>\r\n      </div>\r\n    </div>\r\n    <div class=\"col-md-6 col-sm-12\">\r\n      <div class=\"form-group\">\r\n        <label class=\"control-label col-sm-3 col-md-4\">County</label>\r\n        <div class=\"form-control-static col-sm-9 col-md-8\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.county : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\r\n      </div>\r\n    </div>\r\n  </div>\r\n  <h3 class=\"col-sm-offset-3 col-md-offset-2\">State Incorporation/Business Registration</h3>\r\n  <div class=\"row\">\r\n    <div class=\"col-md-6 col-sm-12\">\r\n      <div class=\"form-group\">\r\n        <label class=\"control-label col-sm-3 col-md-4\">State of Incorporation/Business Registration</label>\r\n        <div class=\"form-control-static col-sm-9 col-md-8\">\r\n          "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.stateOfIncorporation : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\r\n      </div>\r\n    </div>\r\n  </div>\r\n  <div class=\"row\">\r\n    <div class=\"col-md-6 col-sm-12\">\r\n      <div class=\"form-group\">\r\n        <label class=\"control-label col-sm-3 col-md-4\">Type of Entity</label>\r\n        <div class=\"form-control-static col-sm-9 col-md-8\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.entityType : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\r\n      </div>\r\n    </div>\r\n    <div class=\"col-md-6 col-sm-12\">\r\n      <div class=\"form-group\">\r\n        <label class=\"control-label col-sm-3 col-md-4\">Non-Profit</label>\r\n        <div class=\"form-control-static col-sm-9 col-md-8\">\r\n          "
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || helpers.helperMissing).call(alias1,(depth0 != null ? depth0.isNonProfit : depth0),"===","1",{"name":"ifCond","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(13, data, 0),"data":data})) != null ? stack1 : "")
    + "        </div>\r\n      </div>\r\n    </div>\r\n  </div>\r\n  <div class=\"row\">\r\n    <div class=\"col-sm-12\">\r\n      <div class=\"form-group\">\r\n        <label class=\"control-label col-md-2 col-sm-3\">Website</label>\r\n        <p class=\"form-control-static col-md-9 col-sm-9\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.website : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "</p>\r\n      </div>\r\n    </div>\r\n  </div>\r\n  <div class=\"row\">\r\n    <div class=\"col-md-6 col-sm-12\">\r\n      <div class=\"form-group\">\r\n        <label class=\"control-label col-sm-3 col-md-4\">Number of Individuals</label>\r\n        <div class=\"form-control-static col-sm-9 col-md-8\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.employeeCount : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\r\n      </div>\r\n    </div>\r\n  </div>\r\n  <div class=\"row\">\r\n    <div class=\"col-md-6 col-sm-12\">\r\n      <div class=\"form-group\">\r\n        <label class=\"control-label col-sm-3 col-md-4\">Type of Individuals being Approved</label>\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.segIndividualList : depth0),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.program(25, data, 0),"data":data})) != null ? stack1 : "")
    + "\r\n      </div>\r\n    </div>\r\n    <div class=\"col-md-6 col-sm-12\">\r\n      <div class=\"form-group\">\r\n        <label class=\"control-label col-sm-3 col-md-4\">Status</label>\r\n        <div class=\"form-control-static col-sm-9 col-md-8\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.status : depth0),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\r\n      </div>\r\n    </div>\r\n  </div>\r\n  <div class=\"row\">\r\n    <div class=\"col-md-6 col-sm-12\">\r\n      <div class=\"form-group\">\r\n        <label class=\"control-label col-sm-3 col-md-4\">Approval Date</label>\r\n        <div class=\"form-control-static col-sm-9 col-md-8\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.approvalDate : depth0),{"name":"if","hash":{},"fn":container.program(29, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\r\n      </div>\r\n    </div>\r\n    <div class=\"col-md-6 col-sm-12\">\r\n      <div class=\"form-group\">\r\n        <label class=\"control-label col-sm-3 col-md-4\">Termination Date</label>\r\n        <div class=\"form-control-static col-sm-9 col-md-8\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.terminationDate : depth0),{"name":"if","hash":{},"fn":container.program(31, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "\r\n        </div>\r\n      </div>\r\n    </div>\r\n  </div>\r\n  <div class=\"row\">\r\n    <div class=\"col-sm-12\">\r\n      <div class=\"form-group\">\r\n        <label class=\"control-label col-md-2 col-sm-3\">Comments</label>\r\n        <p class=\"form-control-static col-md-9 col-sm-9\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.comments : depth0),{"name":"if","hash":{},"fn":container.program(33, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "</p>\r\n      </div>\r\n    </div>\r\n  </div>\r\n</div>";
},"useData":true});