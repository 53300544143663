/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var parsleyUtil = require('common/util/parsleyUtil');
var makeUploadCollection = require('common/util/makeUploadCollection');

var CommentsDocumentView = require('common/views/CommentsDocumentView');

var tmplDepCreditUnionLocationActivitiesBoardResSectionView = require('./DepCreditUnionLocationActivitiesBoardResSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepCreditUnionLocationActivitiesBoardResSectionView,

  className: 'dep-bank-location-activities-board-res-section-view',

  behaviors: {
    ReadOnlyFormBehavior: {}
  },

  ui: {
    documentForm: '.document-form',
    boardResolutionContainer: '.board-resolution-container'
  },

  regions: {
    boardResolutionContainer: '@ui.boardResolutionContainer'
  },

  templateHelpers: function() {
    return {
      isReadOnly: this.options.isReadOnly
    };
  },

  onBeforeShow: function() {
    var filingContent = this.model.get('filingContent') || {};
    var boardResolution = filingContent.boardResolution || {};

    Syphon.deserialize(this, this.model.get('filingContent'));

    this.boardResolution = new CommentsDocumentView({
      isReadOnly: this.options.isReadOnly,
      isRequired: true,
      allowMultipleFiles: false,
      fileUploadLabel: 'Board Resolution',
      commentsLabel: 'Board Resolution Comments',
      commentsName: 'boardResolutionComments',
      collection: makeUploadCollection(boardResolution.file),
      model: new Backbone.Model({ boardResolutionComments: boardResolution.boardResolutionComments }),
      documentTypeId: 20001
    });
    this.showChildView('boardResolutionContainer', this.boardResolution);
  },

  validate: function(config) {
    this.ui.documentForm.parsley().reset();
    this.boardResolution.removeValidationErrors();

    if (config.type === 'save') {
      // Turn off required field validation for this form
      parsleyUtil.disableRequiredValidation(this.ui.documentForm);
    } else {
      var validationPromises = [this.ui.documentForm.parsley().whenValidate(), this.boardResolution.validate()];

      // Turn on required field validation for this form
      parsleyUtil.enableRequiredValidation(this.ui.documentForm);
    }
    return $.when.apply($, validationPromises);
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    formData.docId = this.boardResolution.getFirstFileId();
    return formData;
  }
});
