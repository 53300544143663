/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Ladda = require('ladda');
var Radio = require('backbone.radio');

var BreadcrumbView = require('../../../common/views/BreadcrumbView');
var HeaderBarView = require('common/views/HeaderBarView');

var SubmitCompliancePaymentDetails = require('../services/SubmitCompliancePaymentDetails');

var tmplNonDepInternalComplianceEditPaymentDetailsView = require('../templates/NonDepInternalComplianceEditPaymentDetailsView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepInternalComplianceEditPaymentDetailsView,

  className: 'non-dep-compliance-edit-payment-details-view container-fluid',

  ui: {
    breadcrumb: '.breadcrumb-container',
    headerBar: '.header-bar',
    editPaymentDetailsForm: '.edit-payment-details-form',
    submit: '.btn-submit'
  },

  regions: {
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeShow: function() {
    Syphon.deserialize(this, this.model.get('case'));

    var headerData = {
      title: 'Compliance',
      caseNumber: this.model.get('case').caseNumber,
      tin: this.model.get('case').tin,
      licenseNumber: this.model.get('case').licenseNumber
    };

    this.showChildView(
      'headerBar',
      new HeaderBarView({
        title: 'Compliance',
        columns: new Backbone.Collection([
          {
            name: 'caseNumber',
            label: 'Case Number'
          },
          {
            name: 'tin',
            label: 'Federal ID Number'
          },
          {
            name: 'licenseNumber',
            label: 'License Number'
          }
        ]),
        model: new Backbone.Model(headerData)
      })
    );

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#nondep/dashboard',
            text: 'Home'
          },
          {
            text: this.model.get('case').entityName,
            link: '#nondep/entity/' + this.model.get('case').entityId + '/dashboard'
          },
          {
            text: 'Case #' + this.model.get('case').caseNumber,
            link: '#nondep/compliance/case/' + this.model.get('case').complianceId
          },
          {
            text: 'Edit Payment Details'
          }
        ]
      })
    );
  },

  onClickSubmit: function(e) {
    var self = this;
    var laddaContext = Ladda.create(this.ui.submit.get(0));
    laddaContext.start();
    e.preventDefault();
    this.ui.editPaymentDetailsForm
      .parsley()
      .whenValidate()
      .done(function() {
        var formData = self.getFormData();
        formData.complianceId = self.model.get('case').complianceId;
        SubmitCompliancePaymentDetails(formData)
          .done(function() {
            Radio.channel('navigate').trigger('show:int:nd:compliance:dashboard', self.model.get('case').complianceId);
          })
          .fail(function() {
            laddaContext.stop();
          });
      })
      .fail(function() {
        laddaContext.stop();
      });
  },

  getFormData: function() {
    return Syphon.serialize(this);
  }
});
