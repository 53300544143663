/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var NProgress = require('nprogress');
var Backbone = require('backbone');

var NavUtils = require('psa-core/util/nav');

var Scaffold = require('common/layout/Scaffold');

var GetAllEntityOfficerTypes = require('../services/GetAllEntityOfficerTypes');
var GetAllEntityOrganizationTypes = require('../services/GetAllEntityOrganizationTypes');

var NonDepEntityFormNewPageView = require('../views/NonDepEntityFormNewPageView');

module.exports = function() {
  NProgress.start();
  NProgress.set(0.6);
  $.when(GetAllEntityOfficerTypes(), GetAllEntityOrganizationTypes())
    .done(function(entityOfficerTypes, entityOrganizationTypes) {
      NavUtils.navigate('ext/nondep/entity/new');
      Scaffold.content.show(
        new NonDepEntityFormNewPageView({
          model: new Backbone.Model({
            entityOfficerTypes: entityOfficerTypes,
            entityOrganizationTypes: entityOrganizationTypes
          })
        })
      );
      NProgress.done();
    })
    .fail(function() {
      NProgress.done();
    });
};
