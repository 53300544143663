/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var _get = require('lodash.get');

module.exports = function(formData) {
  var supportingDocuments = formData.supportingDocuments || [];
  var publication = formData.publicationRequirements || {};
  var publicationProofDocuments = publication.publicationProofDocuments || [];
  var infoDocs = formData.requiredInformationDocumentation || {};
  var pointOfContact = formData.pointOfContact || {};
  var lawyerExternalUserId = _get(formData, 'creditUnionArticlesAmendmentSubmissionLawyer.lawyerExternalUserId');
  var certification = formData.certification || {};
  return $.soap({
    body: {
      SubmitCreditUnionArticlesAmendmentFiling: {
        $xmlns: 'http://dobs.pa.gov/dp/filing/bpm/1.0',
        CreditUnionArticlesAmendmentFilingID: formData.creditUnionArticlesAmendmentFilingId,
        FilingID: formData.filingId,
        PublicationReqsDocID: publication.publicationReqsDocId,
        GeneralPublicationProofComments: publication.generalPublicationProofComments,
        LegalPublicationProofComments: publication.legalPublicationProofComments,
        PublicationGeneralDate: publication.publicationGeneralDate,
        NewspaperGeneral: publication.newspaperGeneral,
        PublicationLegalDate: publication.publicationLegalDate,
        NewspaperLegal: publication.newspaperLegal,
        ArticlesOfAmendmentDocID: infoDocs.articlesOfAmendmentDocId,
        ArticlesOfAmendmentComments: infoDocs.articlesOfAmendmentComments,
        RequestedEffectiveDate: infoDocs.requestedEffectiveDate,
        IsCertified: certification.isCertified ? '1' : '0',
        CertificationName: certification.certificationName,
        CertificationDocID: certification.certificationDocId,
        PointOfContact: {
          Name: pointOfContact.name,
          Title: pointOfContact.title,
          Phone: pointOfContact.phone,
          Email: pointOfContact.email,
          AddressLine1: pointOfContact.addressLine1,
          AddressLine2: pointOfContact.addressLine2,
          AddressLine3: pointOfContact.addressLine3,
          City: pointOfContact.city,
          State: pointOfContact.state,
          PostalCode: pointOfContact.postalCode,
          County: pointOfContact.county,
          Country: pointOfContact.country
        },
        LawyerExternalUserID: lawyerExternalUserId,
        PublicationProofDocuments: (function() {
          if (!publicationProofDocuments.length) {
            return {};
          }
          return _.map(publicationProofDocuments, function(publicationProofDocument) {
            return {
              DocumentID: publicationProofDocument.fileId
            };
          });
        })(),
        GeneralPublicationProofDocuments: (function() {
          if (!_get(publication, 'generalPublicationProofDocIds.length')) {
            return {};
          }
          return _.map(publication.generalPublicationProofDocIds, function(docId) {
            return { DocumentID: docId };
          });
        })(),
        LegalPublicationProofDocuments: (function() {
          if (!_get(publication, 'legalPublicationProofDocIds.length')) {
            return {};
          }
          return _.map(publication.legalPublicationProofDocIds, function(docId) {
            return { DocumentID: docId };
          });
        })(),
        SupportingDocuments: (function() {
          if (!supportingDocuments.length) {
            return {};
          }
          return {
            Document: _.map(supportingDocuments, function(supportingDocument) {
              return {
                DocumentID: supportingDocument.fileId
              };
            })
          };
        })()
      }
    }
  });
};
