var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "      <label class=\"radio-inline\">\r\n        <input type=\"radio\" name=\"nameTypeId\" value=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" data-parsley-errors-container=\".name-type-error-container\">\r\n        "
    + alias4(((helper = (helper = helpers.nameType || (depth0 != null ? depth0.nameType : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"nameType","hash":{},"data":data}) : helper)))
    + "\r\n      </label>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "\r\n<form class=\"name-res-form\">\r\n  <label class=\"control-label\">Name Type</label>\r\n  <div class=\"form-group\">\r\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.nameTypes : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    <div class=\"name-type-error-container\"></div>\r\n  </div>\r\n  <div class=\"proposed-name\">\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label\">Proposed Name</label>\r\n      <input type=\"text\" name=\"proposedName\" class=\"form-control\" placeholder=\"Proposed Name\"\r\n        data-parsley-maxlength=\"200\" />\r\n    </div>\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label\">Proposed Name Comments</label>\r\n      <textarea name=\"proposedNameComments\" class=\"form-control input-view\" placeholder=\"Proposed Name Comments\"\r\n        rows=\"6\"></textarea>\r\n    </div>\r\n    <div class=\"form-group print-view\" id=\"proposedNameCommentsPrintView\"></div>\r\n    <div class=\"row\">\r\n      <div class=\"col-md-12 col-sm-12\">\r\n        <div class=\"form-group\">\r\n          <div class=\"proposed-name-container\"></div>\r\n        </div>\r\n      </div>\r\n    </div>\r\n  </div>\r\n  <div class=\"interim-name\">\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label\">Interim Name</label>\r\n      <input type=\"text\" name=\"interimName\" class=\"form-control\" placeholder=\"Interim Name\"\r\n        data-parsley-maxlength=\"200\" />\r\n    </div>\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label\">Interim Name Comments</label>\r\n      <textarea name=\"interimNameComments\" class=\"form-control input-view\" placeholder=\"Interim Name Comments\" rows=\"6\"></textarea>\r\n    </div>\r\n    <div class=\"form-group print-view\" id=\"interimNameCommentsPrintView\"></div>\r\n    <div class=\"row\">\r\n      <div class=\"col-md-12 col-sm-12\">\r\n        <div class=\"form-group\">\r\n          <div class=\"interim-name-container\"></div>\r\n        </div>\r\n      </div>\r\n    </div>\r\n  </div>\r\n</form>";
},"useData":true});