/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Backbone = require('backbone');
var Marionette = require('backbone.marionette');

var UIUtil = require('psa-core/util/ui');

var getUserIdFromDn = require('../../util/getUserIdFromDn');
var userHasRole = require('../../util/userHasRole');
var RevokeTask = require('../../services/RevokeTask');

var ConfirmationModalView = require('../ConfirmationModalView');
var TaskReassignModalView = require('../TaskReassignModalView');
var SetTaskAssignmentModalView = require('../TaskAssignModalView');

require('./TaskActionsView.css');
var tmplTaskActionsView = require('./TaskActionsView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplTaskActionsView,

  className: 'task-actions-view',

  ui: {
    reassign: '.reassign',
    release: '.release',
    assign: '.assign'
  },

  events: {
    'click @ui.reassign': 'onClickReassign',
    'click @ui.release': 'onClickRelease',
    'click @ui.assign': 'onClickAssign'
  },

  initialize: function() {
    var departmentName = this.options.departmentName;
    var targets = this.model.get('targets');
    var targetRole = _.findWhere(targets, { type: 'role' });
    var isDoBSDepartmentAdmin = userHasRole('DoBS Department Admin');
    var isTaskAssigned = this.model.get('isAssigned');

    this.targetRoleDn = null;
    this.targetRoleName = null;
    if (targetRole) {
      this.targetRoleDn = targetRole.targetDn;
      this.targetRoleName = getUserIdFromDn(this.targetRoleDn);
    }

    this.model.set({ adminAssignUnclaimedTask: isDoBSDepartmentAdmin && !isTaskAssigned });

    // Change label for 'Release' option based on department
    this.model.set({ releaseTaskLabel: 'Release Task' }); // Default, Nondep
    if (departmentName === 'DEPOSITORY') {
      this.model.set({ releaseTaskLabel: 'Pause Task' }); // Dep
    }
  },

  onClickReassign: function(e) {
    e.preventDefault();
    UIUtil.showModal({
      title: 'Reassign Task?',
      view: new TaskReassignModalView({
        model: new Backbone.Model({
          targetRole: this.targetRoleName,
          task: this.model.toJSON()
        })
      })
    });
  },

  onClickAssign: function(e) {
    e.preventDefault();
    UIUtil.showModal({
      title: 'Assign Task?',
      view: new SetTaskAssignmentModalView({
        model: new Backbone.Model({
          targetRole: this.targetRoleName,
          task: this.model.toJSON()
        })
      })
    });
  },

  onClickRelease: function(e) {
    e.preventDefault();
    var taskId = this.model.get('taskId');
    var departmentName = this.options.departmentName;

    // Change modal text based on department
    // Default, Nondep
    var message =
      'Are you sure you would like to release this task? This task will be removed from your assigned tasks, and will be available for others in the "' +
      this.targetRoleName +
      '" role to claim.';
    var title = 'Release Task?';
    var confirmText = 'Release This Task';

    if (departmentName === 'DEPOSITORY') {
      message =
        'Are you sure you would like to pause this task? This task will be removed from your assigned tasks, and will be available for others in the "' +
        this.targetRoleName +
        '" role to start.';
      title = 'Pause Task?';
      confirmText = 'Pause This Task';
    }

    UIUtil.showModal({
      title: title,
      view: new ConfirmationModalView({
        confirmText: confirmText,
        model: new Backbone.Model({
          message: message,
          onConfirm: function() {
            return RevokeTask(taskId);
          },
          onConfirmDone: function() {
            // Reload page after task has been successfully released.
            Backbone.history.loadUrl();
          }
        })
      })
    });
  }
});
