/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(examId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetExamFindingsDocuments: {
        $xmlns: 'http://dobs.pa.gov/dp/exam/bpm/1.0',
        ExamID: examId
      }
    },
    blueprintArrays: ['GetExamFindingsDocumentsResponse.DP_ExamDocument'],
    blueprint: {
      documents: [
        '$..DP_ExamDocument',
        {
          fileId: '$..ID',
          fileName: '$..Name',
          documentTypeId: '$..DocumentTypeID',
          documentType: '$.DocumentType'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      _.each(response.documents, function(documents) {
        documents.file = {
          fileId: documents.fileId,
          fileName: documents.fileName
        };
      });
      deferred.resolve(response.documents);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
