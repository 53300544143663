/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(data) {
  var defer = $.Deferred();

  $.soap({
    body: {
      SubmitLocation: {
        $xmlns: 'http://dobs.pa.gov/dp/location/bpm/1.0',
        EntityID: data.entityId,
        LocationID: data.locationId,
        StatusID: data.statusId,
        OfficeTypeID: data.officeTypeId,
        ActivityID: data.activityId,
        LocationTypeID: data.locationTypeId,
        OpenedDate: data.openedDate ? moment(data.openedDate, 'MM-DD-YYYY').format('YYYY-MM-DD') : null,
        ClosedDate: data.closedDate ? moment(data.closedDate, 'MM-DD-YYYY').format('YYYY-MM-DD') : null,
        Address: {
          AddressLine1: data.addressLine1,
          AddressLine2: data.addressLine2,
          City: data.city,
          State: data.state,
          PostalCode: data.postalCode,
          Country: data.country,
          County: data.county
        },
        RelocatedTo: data.relocatedTo,
        IsDeleted: data.isDeleted,
        Comments: data.comments
      }
    },
    blueprint: {
      id: '$..ID'
    }
  })
    .done(function(response) {
      cleanUpResponse(response, { cleanUpTextKeys: true });
      defer.resolve(response);
    })
    .fail(function(err) {
      defer.reject();
    });

  return defer.promise();
};
