/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var NProgress = require('nprogress');

var NavUtils = require('psa-core/util/nav');

var Scaffold = require('common/layout/Scaffold');

var GetEntityIdentifiers = require('../../common/services/GetEntityIdentifiers');
var GetEntityTracking = require('../services/GetEntityTracking');
var GetAllTrackingLookups = require('../services/GetAllTrackingLookups');

var DepTrackingEditView = require('../views/DepTrackingEditView');

module.exports = function(entityId, entityTrackingId) {
  NProgress.start();
  NProgress.set(0.6);
  $.when(GetEntityIdentifiers(entityId), GetEntityTracking(entityTrackingId))
    .done(function(entityIdentifiers, entityTracking) {
      GetAllTrackingLookups(entityIdentifiers.categoryId)
        .done(function(trackingLookups) {
          NavUtils.navigate('dep/entity/' + entityId + '/tracking/' + entityTrackingId + '/edit');

          Scaffold.content.show(
            new DepTrackingEditView({
              model: new Backbone.Model({
                entityId: entityId,
                entityName: entityIdentifiers.entityName,
                entityTrackingId: entityTrackingId,
                entityTypeId: entityIdentifiers.entityTypeId,
                entityCategoryId: entityIdentifiers.categoryId,
                trackingLookups: trackingLookups,
                entityTracking: entityTracking
              })
            })
          );
          NProgress.done();
        })
        .fail(function() {
          NProgress.done();
        });
    })
    .fail(function() {
      NProgress.done();
    });
};
