var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "\r\n<div class=\"form-horizontal form-static\">\r\n  <div class=\"form-group\">\r\n    <label class=\"control-label col-sm-3 col-md-2\">Fund Name</label>\r\n    <div class=\"form-control-static col-sm-9 col-md-10\">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</div>\r\n  </div>\r\n  <div class=\"row\">\r\n    <div class=\"col-sm-6\">\r\n      <div class=\"form-group\">\r\n        <label class=\"control-label col-sm-6 col-md-4\">Effective Date</label>\r\n        <div class=\"form-control-static col-sm-6 col-md-8\">"
    + alias4(((helper = (helper = helpers.effectiveDate || (depth0 != null ? depth0.effectiveDate : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"effectiveDate","hash":{},"data":data}) : helper)))
    + "</div>\r\n      </div>\r\n    </div>\r\n    <div class=\"col-sm-6\">\r\n      <div class=\"form-group\">\r\n        <label class=\"control-label col-sm-6 col-md-4\">Approval Date</label>\r\n        <div class=\"form-control-static col-sm-6 col-md-8\">"
    + alias4(((helper = (helper = helpers.approvalDate || (depth0 != null ? depth0.approvalDate : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"approvalDate","hash":{},"data":data}) : helper)))
    + "</div>\r\n      </div>\r\n    </div>\r\n  </div>\r\n  <div class=\"row\">\r\n    <div class=\"col-sm-6\">\r\n      <div class=\"form-group\">\r\n        <label class=\"control-label col-sm-6 col-md-4\">Last Amendment Date</label>\r\n        <div class=\"form-control-static col-sm-6 col-md-8\">"
    + alias4(((helper = (helper = helpers.effectiveAmendDate || (depth0 != null ? depth0.effectiveAmendDate : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"effectiveAmendDate","hash":{},"data":data}) : helper)))
    + "</div>\r\n      </div>\r\n    </div>\r\n    <div class=\"col-sm-6\">\r\n      <div class=\"form-group\">\r\n        <label class=\"control-label col-sm-6 col-md-4\">Last Amendment Approval Date</label>\r\n        <div class=\"form-control-static col-sm-6 col-md-8\">"
    + alias4(((helper = (helper = helpers.lastAmendmentApprovalDate || (depth0 != null ? depth0.lastAmendmentApprovalDate : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"lastAmendmentApprovalDate","hash":{},"data":data}) : helper)))
    + "</div>\r\n      </div>\r\n    </div>\r\n  </div>\r\n</div>";
},"useData":true});