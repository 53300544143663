var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "\r\n<h3 class=\"pay-period-title\">Pay Period from "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.payPeriod : depth0)) != null ? stack1.payPeriodDisplay : stack1), depth0))
    + "</h3>\r\n<div class=\"clearfix\">\r\n  <h3 class=\"pull-left m-t-sm\">Time Entries</h3>\r\n  <button class=\"btn add pull-right btn-primary add-entry m-t-sm\">Add Entry</button>\r\n</div>\r\n<div class=\"time-entries-section\">\r\n  <div class=\"labels row\">\r\n    <div class=\"col-time-category\">\r\n      <label class=\"control-label\">Category</label>\r\n    </div>\r\n    <div class=\"col-time-object\">\r\n      <label class=\"control-label\">Exam, Investigation, or Inquiry</label>\r\n    </div>\r\n    <div class=\"col-time-hrs\">\r\n      <div class=\"row\">\r\n        <div class=\"col-hr-label\">\r\n          <div class=\"form-group\">\r\n            <label class=\"control-label\">Mon.</label>\r\n            <p>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.payPeriod : depth0)) != null ? stack1.startDayPlus1Display : stack1), depth0))
    + "</p>\r\n          </div>\r\n        </div>\r\n        <div class=\"col-hr-label\">\r\n          <div class=\"form-group\">\r\n            <label class=\"control-label\">Tue.</label>\r\n            <p>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.payPeriod : depth0)) != null ? stack1.startDayPlus2Display : stack1), depth0))
    + "</p>\r\n          </div>\r\n        </div>\r\n        <div class=\"col-hr-label\">\r\n          <div class=\"form-group\">\r\n            <label class=\"control-label\">Wed.</label>\r\n            <p>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.payPeriod : depth0)) != null ? stack1.startDayPlus3Display : stack1), depth0))
    + "</p>\r\n          </div>\r\n        </div>\r\n        <div class=\"col-hr-label\">\r\n          <div class=\"form-group\">\r\n            <label class=\"control-label\">Thurs.</label>\r\n            <p>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.payPeriod : depth0)) != null ? stack1.startDayPlus4Display : stack1), depth0))
    + "</p>\r\n          </div>\r\n        </div>\r\n        <div class=\"col-hr-label\">\r\n          <div class=\"form-group\">\r\n            <label class=\"control-label\">Fri.</label>\r\n            <p>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.payPeriod : depth0)) != null ? stack1.startDayPlus5Display : stack1), depth0))
    + "</p>\r\n          </div>\r\n        </div>\r\n        <div class=\"col-hr-label\">\r\n          <div class=\"form-group\">\r\n            <label class=\"control-label\">Mon.</label>\r\n            <p>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.payPeriod : depth0)) != null ? stack1.startDayPlus8Display : stack1), depth0))
    + "</p>\r\n          </div>\r\n        </div>\r\n        <div class=\"col-hr-label\">\r\n          <div class=\"form-group\">\r\n            <label class=\"control-label\">Tue.</label>\r\n            <p>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.payPeriod : depth0)) != null ? stack1.startDayPlus9Display : stack1), depth0))
    + "</p>\r\n          </div>\r\n        </div>\r\n        <div class=\"col-hr-label\">\r\n          <div class=\"form-group\">\r\n            <label class=\"control-label\">Wed.</label>\r\n            <p>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.payPeriod : depth0)) != null ? stack1.startDayPlus10Display : stack1), depth0))
    + "</p>\r\n          </div>\r\n        </div>\r\n        <div class=\"col-hr-label\">\r\n          <div class=\"form-group\">\r\n            <label class=\"control-label\">Thurs.</label>\r\n            <p>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.payPeriod : depth0)) != null ? stack1.startDayPlus11Display : stack1), depth0))
    + "</p>\r\n          </div>\r\n        </div>\r\n        <div class=\"col-hr-label\">\r\n          <div class=\"form-group\">\r\n            <label class=\"control-label\">Fri.</label>\r\n            <p>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.payPeriod : depth0)) != null ? stack1.startDayPlus12Display : stack1), depth0))
    + "</p>\r\n          </div>\r\n        </div>\r\n      </div>\r\n    </div>\r\n  </div>\r\n  <form class=\"time-entries-container\"></form>\r\n  <div class=\"time-totals-container\"></div>\r\n</div>\r\n<div class=\"row m-t-lg\">\r\n  <div class=\"col-comments-section\">\r\n    <h3>Comments</h3>\r\n    <textarea class=\"form-control align-comments\" name=\"comments\" rows=\"7\" placeholder=\"Comments\"></textarea>\r\n  </div>\r\n</div>\r\n<button class=\"btn btn-primary save ladda-button pull-right m-b-lg\" data-style=\"expand-right\" type=\"button\">\r\n  <span class=\"ladda-label\"><i class=\"fa fa-save\"></i> Save</span>\r\n</button>";
},"useData":true});