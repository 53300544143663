/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function() {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetNonDepAllTimesheets: {
        $xmlns: 'http://dobs.pa.gov/tt/timesheet/bpm/1.0'
      }
    },
    blueprintArrays: ['GetNonDepAllTimesheetsResponse.TT_Timesheet'],
    blueprint: {
      timesheets: [
        '$..TT_Timesheet',
        {
          id: '$..ID',
          periodId: '$..PeriodID',
          periodStartDate: '$..PeriodStartDate',
          periodEndDate: '$..PeriodEndDate',
          periodYear: '$..PeriodYear',
          statusId: '$..StatusID',
          status: '$..Status',
          examinerUserId: '$..ExaminerUserID',
          examinerUsername: '$..ExaminerUsername',
          examinerFirstName: '$..ExaminerFirstName',
          examinerLastName: '$..ExaminerLastName'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      _.each(response.timesheets, function(timesheet) {
        var startMonday = moment(timesheet.periodStartDate, 'YYYY-MM-DD')
          .add(1, 'days')
          .format('MM/DD/YYYY');

        var endFriday = moment(timesheet.periodStartDate, 'YYYY-MM-DD')
          .add(12, 'days')
          .format('MM/DD/YYYY');

        timesheet.period = startMonday + ' - ' + endFriday;

        timesheet.examinerName =
          timesheet.examinerFirstName + ' ' + timesheet.examinerLastName + ' (' + timesheet.examinerUsername + ')';
      });

      deferred.resolve(response.timesheets);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
