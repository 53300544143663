/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var GridView = require('psa-marionette/views/GridView');

var MomentCell = require('common/backgrid/MomentCell');
var ButtonLinkCell = require('common/backgrid/ButtonLinkCell');

module.exports = GridView.extend({
  className: 'dep-ext-entity-fund-section-view',

  options: {
    columns: [
      {
        name: 'name',
        label: 'Fund Name',
        cell: 'string',
        editable: false,
        headerCell: 'custom',
        width: 15
      },
      {
        name: 'effectiveDate',
        label: 'Effective Date',
        cell: MomentCell.MomentCell,
        formatter: MomentCell.MomentDateFormatter.extend({
          emptyValue: '--'
        }),
        sortValue: MomentCell.MomentSortValue,
        editable: false,
        headerCell: 'custom',
        width: 15
      },
      {
        name: 'approvalDate',
        label: 'Approval Date',
        cell: MomentCell.MomentCell,
        formatter: MomentCell.MomentDateFormatter.extend({
          emptyValue: '--'
        }),
        sortValue: MomentCell.MomentSortValue,
        editable: false,
        headerCell: 'custom',
        width: 15
      },
      // “Last Amendment Effective Date” extered externally would populate the “Last Amendment Date” internally
      {
        name: 'effectiveAmendDate',
        label: 'Last Amendment Date',
        cell: MomentCell.MomentCell,
        formatter: MomentCell.MomentDateFormatter.extend({
          emptyValue: '--'
        }),
        sortValue: MomentCell.MomentSortValue,
        editable: false,
        headerCell: 'custom',
        width: 15
      },
      {
        name: 'lastAmendmentApprovalDate',
        label: 'Last Amendment Approval Date',
        cell: MomentCell.MomentCell,
        formatter: MomentCell.MomentDateFormatter.extend({
          emptyValue: '--'
        }),
        sortValue: MomentCell.MomentSortValue,
        editable: false,
        headerCell: 'custom',
        width: 15
      },
      {
        name: '',
        label: '',
        cell: ButtonLinkCell.extend({
          name: 'Open',
          href: function(model) {
            return '#ext/dep/entity/' + this.model.get('entityId') + '/fund/' + model.get('id') + '/dashboard';
          }
        }),
        editable: false,
        sortable: false,
        headerCell: 'custom',
        width: 10
      }
    ],
    emptyText: 'No Funds'
  }
});
