var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "Director";
},"3":function(container,depth0,helpers,partials,data) {
    return "Director-Trustee";
},"5":function(container,depth0,helpers,partials,data) {
    return "Edit Director";
},"7":function(container,depth0,helpers,partials,data) {
    return "Edit Director-Trustee";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return "\r\n<div class=\"page-title clearfix\">\r\n  <h1 class=\"pull-left m-n p-n\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.entityIdentifiers : depth0)) != null ? stack1.entityName : stack1), depth0))
    + "</h1>\r\n</div>\r\n<div class=\"page-subtitle\">\r\n  <h2>"
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.isCUTrustHC : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "</h2>\r\n  <div class=\"page-subtitle-details\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.director : depth0)) != null ? stack1.positionHeld : stack1), depth0))
    + " - "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.director : depth0)) != null ? stack1.firstName : stack1), depth0))
    + " "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.director : depth0)) != null ? stack1.lastName : stack1), depth0))
    + "</div>\r\n</div>\r\n<div class=\"breadcrumb-container\"></div>\r\n<div class=\"row\">\r\n  <div class=\"col-sm-12 col-md-10 col-md-offset-1\">\r\n    <div class=\"form-group\">\r\n      <div class=\"col-sm-9 col-md-8 col-md-offset-2 col-sm-offset-3\">\r\n        <h3 class=\"m-t-n\">"
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.isCUTrustHC : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + "</h3>\r\n      </div>\r\n    </div>\r\n  </div>\r\n</div>\r\n<form class=\"director-form-container\"></form>\r\n<div class=\"row m-b-lg\">\r\n  <div class=\"col-sm-12 col-md-10 col-md-offset-1\">\r\n    <div class=\"form-group\">\r\n      <div class=\"col-sm-9 col-md-10 col-md-offset-2 col-sm-offset-3\">\r\n        <a href=\"#dep/entity/"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.director : depth0)) != null ? stack1.entityId : stack1), depth0))
    + "/director/"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.director : depth0)) != null ? stack1.directorId : stack1), depth0))
    + "/dashboard\" class=\"btn btn-default\">Cancel</a>\r\n        <button class=\"btn btn-primary submit ladda-button\" data-style=\"expand-right\" type=\"button\">\r\n          <span class=\"ladda-label\">Submit</span>\r\n        </button>\r\n      </div>\r\n    </div>\r\n  </div>\r\n</div>";
},"useData":true});