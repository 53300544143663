/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Handlebars = require('handlebars');

Handlebars.registerHelper('default', function(value, def) {
  if (!value) {
    return def;
  }
  return value;
});

Handlebars.registerHelper('defaultCond', function(value, operator, value2, def) {
  var operators = {
    '===': function(v, v2, def) {
      return v === v2 ? v : def;
    },
    '!==': function(v, v2, def) {
      return v !== v2 ? v : def;
    },
    '<': function(v, v2, def) {
      return v < v2 ? v : def;
    },
    '<=': function(v, v2, def) {
      return v <= v2 ? v : def;
    },
    '>': function(v, v2, def) {
      return v > v2 ? v : def;
    },
    '>=': function(v, v2, def) {
      return v >= v2 ? v : def;
    }
  };

  if (operators[operator]) {
    return operators[operator](value, value2, def);
  }
  throw "'" + operator + "' does not indentify a defined operator.";
});
