/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');
var Syphon = require('backbone.syphon');
var _ = require('underscore');

var UIUtil = require('psa-core/util/ui');

var FileUploadCollection = require('psa-backbone/models/FileUploadCollection');
var modelGet = require('common/util/modelGet');
var toggleForm = require('common/util/toggleForm');
var ConfirmationModalView = require('common/views/ConfirmationModalView');

var CorpFinGenericSubmissionFormView = require('../../../common/views/CorpFinGenericSubmissionFormView');
var BreadcrumbView = require('common/views/BreadcrumbView');
var FileUploadView = require('common/views/FileUploadView');

var SubmitReviewFilingTask = require('../../services/SubmitReviewFilingTask');

var tmplCorpFinReviewFilingTaskView = require('./CorpFinReviewFilingTaskView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplCorpFinReviewFilingTaskView,

  className: 'sec-review-filing-task-view container-fluid',

  ui: {
    breadcrumb: '.breadcrumb-container',
    genericSubmissionFormContainer: '.generic-submission-form-container',
    reviewForm: '.review-form',
    isSendNotificationFormGroup: '.is-send-notification-form-group',
    isSendNotification: 'select[name="isSendNotification"]',
    internalDocumentFormGroup: '.internal-documents-form-group',
    internalDocumentsContainer: '.internal-documents-container',
    submit: '.submit'
  },

  regions: {
    breadcrumb: '@ui.breadcrumb',
    genericSubmissionFormContainer: '@ui.genericSubmissionFormContainer',
    internalDocumentsContainer: '@ui.internalDocumentsContainer'
  },

  behaviors: {
    TaskBehavior: {}
  },

  events: {
    'change @ui.isSendNotification': 'onChangeIsSendNotification',
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeShow: function() {
    var taskDescription = modelGet(this.model, 'task.taskType.description');

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#corpfin/dashboard',
            text: 'Home'
          },
          {
            text: taskDescription
          }
        ]
      })
    );

    this.showChildView(
      'genericSubmissionFormContainer',
      new CorpFinGenericSubmissionFormView({
        isReadOnly: true,
        model: this.model,
        viewMode: 'int'
      })
    );

    // Mapping of applicationTypeIds => documentTypeIds
    var docTypeMap = {
      '1': 50001,
      '2': 50002,
      '3': 50003
    };

    this.internalFileCollection = new FileUploadCollection([]);
    this.internalFileUpload = new FileUploadView({
      isReadOnly: false,
      allowMultipleFiles: true,
      collection: this.internalFileCollection,
      metadata: {
        documentTypeId: docTypeMap[modelGet(this.model, 'filing.applicationTypeId')]
      }
    });
    this.showChildView('internalDocumentsContainer', this.internalFileUpload);

    var filingTypeId = modelGet(this.model, 'filing.filingTypeId'),
      filingType = _.findWhere(modelGet(this.model, 'filingTypes', []), {
        id: filingTypeId
      });

    toggleForm(this.ui.isSendNotificationFormGroup, filingType.isNotification === '1');
  },

  onChangeIsSendNotification: function() {
    toggleForm(this.ui.internalDocumentFormGroup, this.ui.isSendNotification.val() === '1');
  },

  validate: function() {
    return this.ui.reviewForm.parsley().whenValidate();
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    formData.taskId = this.model.get('taskId');
    formData.filingId = this.model.get('filingId');
    formData.documents = this.internalFileCollection.pluck('fileId');
    return formData;
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;
    this.validate().done(function() {
      UIUtil.showModal({
        title: 'Submit Notification',
        view: new ConfirmationModalView({
          confirmText: 'Submit',
          model: new Backbone.Model({
            message: 'Are you sure you want to submit?',
            onConfirm: function() {
              return SubmitReviewFilingTask(self.getFormData());
            },
            onConfirmDone: function() {
              Radio.channel('navigate').trigger('show:int:cf:dashboard');
            }
          })
        })
      });
    });
  }
});
