/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var NProgress = require('nprogress');

var NavUtils = require('psa-core/util/nav');

var Scaffold = require('common/layout/Scaffold');
var GetUsersForRole = require('common/services/GetUsersForRole');

var GetAllExamInvInqLookups = require('../../common/services/GetAllExamInvInqLookups');
var GetAllInvInqLookups = require('../../common/services/GetAllInvInqLookups');
var GetIndividual = require('../../individual-dashboard/services/GetIndividual');
var SecInvInqIndividualNewView = require('../views/SecInvInqIndividualNewView');

module.exports = function(individualId) {
  NProgress.start();
  NProgress.set(0.6);

  $.when(
    GetIndividual(individualId),
    GetAllInvInqLookups(),
    GetAllExamInvInqLookups(),
    GetUsersForRole('SC - Examiner')
  )
    .done(function(identifiers, lookups, examInvInqLookups, examiners) {
      NavUtils.navigate('#sec/individual/' + individualId + '/new-investigation-inquiry');
      Scaffold.content.show(
        new SecInvInqIndividualNewView({
          model: new Backbone.Model({
            individualId: individualId,
            identifiers: identifiers,
            invInqTypes: lookups.invInqTypes,
            invInqStatuses: lookups.invInqStatuses,
            invInqCategories: lookups.invInqCategories,
            examInvInqOffices: examInvInqLookups.examInvInqOffices,
            examInvInqViolationTypes: examInvInqLookups.examInvInqViolationTypes,
            examiners: examiners
          })
        })
      );
      NProgress.done();
    })
    .fail(function() {
      NProgress.done();
    });
};
