/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */
var _ = require('underscore');
var momentUtil = require('common/util/momentUtil');
var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(filingId) {
  var deferred = $.Deferred();
  $.soap({
    body: {
      GetOathRosterFiling: {
        $xmlns: 'http://dobs.pa.gov/dp/filing/bpm/1.0',
        FilingID: filingId
      }
    },
    blueprintArrays: [
      'GetOathRosterFilingResponse.SupportingDocuments.DP_FilingSupportingDocument',
      'GetOathRosterFilingResponse.Directors.DP_FilOathRosterDirector',
      'GetOathRosterFilingResponse.Officers.DP_FilOathRosterOfficer'
    ],
    blueprint: {
      oathRosterId: '$..ID',
      filingId: '$..FilingID',
      typeId: '$..TypeID',
      officerReportDoc: {
        fileId: '$..OfficerReportDocID',
        fileName: '$..OfficerReportDocName'
      },
      oathBoardElectedDoc: {
        fileId: '$..OathBoardElectedDocID',
        fileName: '$..OathBoardElectedDocName'
      },
      oathShareholderElectedDoc: {
        fileId: '$..OathShareholderElectedDocID',
        fileName: '$..OathShareholderElectedDocName'
      },
      directors: [
        '$..DP_FilOathRosterDirector',
        {
          firstName: '$..FirstName',
          middleInitial: '$..MiddleInitial',
          lastName: '$..LastName',
          electionDate: '$..ElectionDate',
          electionReason: '$..ElectionReason',
          predecessorName: '$..PredecessorName',
          vacancyDate: '$..VacancyDate',
          vacancyCause: '$..VacancyCause',
          serviceEndDate: '$..ServiceEndDate',
          isChairman: '$..IsChairman',
          meetingType: '$..MeetingType'
        }
      ],
      officers: [
        '$..DP_FilOathRosterOfficer',
        {
          firstName: '$..FirstName',
          middleInitial: '$..MiddleInitial',
          lastName: '$..LastName',
          titleId: '$..TitleID',
          titleComments: '$..TitleComments',
          electionDate: '$..ElectionDate',
          serviceEndDate: '$..ServiceEndDate'
        }
      ],
      supportingDocuments: [
        '$..DP_FilingSupportingDocument',
        { file: { fileId: '$.DocumentID', fileName: '$.DocumentName' } }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      var formInboundDateConfig = {
        sourceFormat: 'YYYY-MM-DD',
        targetFormat: 'MM/DD/YYYY',
        default: undefined
      };
      _.each(response.directors, function(director) {
        director.vacancyDate = momentUtil.formatStr(director.vacancyDate, formInboundDateConfig);
        director.electionDate = momentUtil.formatStr(director.electionDate, formInboundDateConfig);
        director.serviceEndDate = momentUtil.formatStr(director.serviceEndDate, formInboundDateConfig);
      });
      _.each(response.officers, function(officer) {
        officer.electionDate = momentUtil.formatStr(officer.electionDate, formInboundDateConfig);
        officer.serviceEndDate = momentUtil.formatStr(officer.serviceEndDate, formInboundDateConfig);
      });
      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
