/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');

var SpinnerView = require('../SpinnerView');

var tmplPlaceholderViewContainer = require('./PlaceholderView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplPlaceholderViewContainer,

  className: 'placeholder-view',

  ui: {
    viewContainer: '.placeholder-view-container'
  },

  regions: {
    viewContainer: '@ui.viewContainer'
  },

  resolved: false,

  options: function() {
    return {
      placeholderView: new SpinnerView({
        spinner: {
          top: '0%'
        }
      })
    };
  },

  initialize: function() {
    this.options.viewPromise
      .done(
        function(view) {
          this.resolved = true;
          if (!this.isDestroyed) {
            this.showChildView('viewContainer', view);
          }
        }.bind(this)
      )
      .fail(function(err) {
        this.resolved = true;
        if (this.options && this.options.onError) {
          this.options.onError.apply(this, err);
        }
      });
  },

  onBeforeShow: function() {
    if (!this.resolved) {
      this.showChildView('viewContainer', this.options.placeholderView);
    }
  }
});
