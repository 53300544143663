/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Backbone = require('backbone');
var Marionette = require('backbone.marionette');

var BreadcrumbView = require('common/views/BreadcrumbView');
var BigTabsView = require('common/views/BigTabsView');
var ExamHeaderBarView = require('../../common/views/ExamHeaderBarView');

require('../styles/NonDepPreviewExamMaterialsView.css');
var tmplNonDepPreviewExamMaterialsView = require('../templates/NonDepPreviewExamMaterialsView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepPreviewExamMaterialsView,

  className: 'non-dep-preview-exam-materials-view container-fluid',

  ui: {
    breadcrumb: '.breadcrumb-container',
    headerBar: '.header-bar',
    tabs: '.tabs'
  },

  regions: {
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb',
    tabs: '@ui.tabs'
  },

  onBeforeShow: function() {
    var exam = this.model.get('exam') || {};
    var examId = exam.examId;
    var entityId = exam.entityId;
    var entityName = exam.entityName;
    var licenseId = exam.licenseId;
    var licenseNumber = exam.licenseNumber;
    var hasExamLoanLogs = exam.hasExamLoanLogs;
    var hasExamPolicyProcedures = exam.hasExamPolicyProcedures;

    this.showChildView(
      'headerBar',
      new ExamHeaderBarView({
        model: new Backbone.Model(_.extend(this.model.get('exam'), { title: 'Exam' }))
      })
    );

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#nondep/dashboard',
            text: 'Home'
          },
          {
            text: entityName,
            link: '#nondep/entity/' + entityId + '/dashboard'
          },
          {
            text: 'License #' + licenseNumber,
            link: '#nondep/entity/' + entityId + '/license/' + licenseId
          },
          {
            text: 'Exam #' + examId,
            link: '#nondep/dashboard/exam/' + examId
          },
          {
            text: 'Preview Exam Materials'
          }
        ]
      })
    );

    var tabs = [];

    // If exam has loan logs, add a tab for it
    if (hasExamLoanLogs) {
      tabs.push({
        id: 'loanLogs',
        title: 'Loan Logs',
        subtitle: 'Entity exam responses',
        faIconClass: 'fa-list',
        view: this.options.loanLogQuestionsView
      });
    }

    // If exam has exam materials, add a tab for it
    if (hasExamPolicyProcedures) {
      tabs.push({
        id: 'examMaterials',
        title: hasExamLoanLogs ? 'Policies/Procedures' : 'Exam Materials',
        subtitle: 'Entity exam responses',
        faIconClass: 'fa-folder-open-o',
        view: this.options.examMaterialsQuestionView
      });
    }

    this.showChildView(
      'tabs',
      new BigTabsView({
        collection: new Backbone.Collection(tabs)
      })
    );
  }
});
