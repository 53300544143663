/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var NProgress = require('nprogress');

var GetDocumentTypesByDepartment = require('common/services/GetDocumentTypesByDepartment');

var GetInternalApplicationReview = require('../../services/GetInternalApplicationReview');
var GetApplicationInvestigation = require('../../services/GetApplicationInvestigation');
var InternalApplicationReviewView = require('../../views/InternalApplicationReviewView');

var MTReviewApplicationWorkTabView = require('./MTReviewApplicationWorkTabView');

module.exports = {
  event: 'build:nd:mt-review-application',

  channel: 'task',

  builder: function(task) {
    var defer = $.Deferred();

    var applicationId = task.taskData.ND.applicationId;

    $.when(
      GetInternalApplicationReview(applicationId),
      GetApplicationInvestigation(applicationId),
      GetDocumentTypesByDepartment('NON_DEPOSITORY')
    )
      .done(function(results, applicationInvestigation, documentTypes) {
        NProgress.set(0.85);

        var applicationDetails = results.applicationDetails;
        var entityForm = results.entityForm;
        var applicationFormView = results.applicationFormView;
        var previousReviewDecision = results.previousReviewDecision;

        var workTabView = new MTReviewApplicationWorkTabView({
          documentTypes: documentTypes,
          model: new Backbone.Model({
            taskId: task.taskId,
            applicationId: applicationId,
            task: task,
            applicationDetails: applicationDetails,
            applicationInvestigation: applicationInvestigation,
            previousReviewDecision: previousReviewDecision
          })
        });

        defer.resolve(
          new InternalApplicationReviewView({
            entityFormView: entityForm,
            applicationFormView: applicationFormView,
            workTabView: workTabView,
            model: new Backbone.Model({
              applicationDetails: applicationDetails,
              task: task
            })
          })
        );
      })
      .fail(function(err) {
        defer.reject(err);
      });
    return defer.promise();
  }
};
