/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(examId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetEntityExamTurnaround: {
        $xmlns: 'http://dobs.pa.gov/dp/exam/bpm/1.0',
        ID: examId
      }
    },
    blueprint: {
      ss: {
        daysFromExamStart: '$..SS.DaysFromExamStart',
        daysFromExitMeetingDate: '$..SS.DaysFromExitMeetingDate',
        daysFromInitialProcessorReceived: '$..SS.DaysFromInitialProcessorReceived',
        eicCurrentDays: '$..SS.EICCurrentDays',
        eicFinalDays: '$..SS.EICFinalDays',
        initialProcessingCurrentDays: '$..SS.InitialProcessingCurrentDays',
        initialProcessingFinalDays: '$..SS.InitialProcessingFinalDays',
        caseManagerCurrentDays: '$..SS.CaseManagerCurrentDays',
        caseManagerFinalDays: '$..SS.CaseManagerFinalDays',
        finalProcessingCurrentDays: '$..SS.FinalProcessingCurrentDays',
        finalProcessingFinalDays: '$..SS.FinalProcessingFinalDays'
      },
      trust: {
        daysFromExamStart: '$..Trust.DaysFromExamStart',
        daysFromExitMeetingDate: '$..Trust.DaysFromExitMeetingDate',
        daysFromInitialProcessorReceived: '$..Trust.DaysFromInitialProcessorReceived',
        eicCurrentDays: '$..Trust.EICCurrentDays',
        eicFinalDays: '$..Trust.EICFinalDays',
        initialProcessingCurrentDays: '$..Trust.InitialProcessingCurrentDays',
        initialProcessingFinalDays: '$..Trust.InitialProcessingFinalDays',
        caseManagerCurrentDays: '$..Trust.CaseManagerCurrentDays',
        caseManagerFinalDays: '$..Trust.CaseManagerFinalDays',
        finalProcessingCurrentDays: '$..Trust.FinalProcessingCurrentDays',
        finalProcessingFinalDays: '$..Trust.FinalProcessingFinalDays'
      }
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
