/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var toggleForm = require('common/util/toggleForm');

var tmplDepTrackingFormView = require('../templates/DepTrackingFormView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepTrackingFormView,

  className: 'dep-tracking-form-view',

  ui: {
    trackingForm: 'form',
    trackingTypeSelect: '.tracking-type-select',
    ceclSection: '.cecl-container',
    mrbSection: '.mrb-container',
    subDebtSection: '.sub-debt-container',
    redFlagSection: '.red-flag-container',
    bankRedFlagSection: '.bank-red-flag-container',
    creditUnionTrustRedFlagSection: '.credit-union-trust-red-flag-container',
    keyThirdPartyVendorSection: '.key-third-party-vendor-container',

    enteredDate: '.entered-date',
    adoptionDeadline: '.adoption-deadline',
    expectedAdoptionDate: '.expected-adoption-date',
    adoptionDate: '.adoption-date',
    asOfDate: '.as-of-date',
    examStartDate: '.exam-start-date'
  },

  events: {
    'change @ui.trackingTypeSelect': 'onChangeTrackingType'
  },

  onBeforeShow: function() {
    var entityTracking = this.model.get('entityTracking') || {};

    if (entityTracking.trackingTypeId) {
      this.updateFormState(entityTracking.trackingTypeId);
    } else {
      toggleForm(this.ui.bankRedFlagSection, false);
      toggleForm(this.ui.creditUnionTrustRedFlagSection, false);
    }

    Syphon.deserialize(this, entityTracking);
    this.ui.enteredDate.datepicker();
    this.ui.adoptionDeadline.datepicker();
    this.ui.expectedAdoptionDate.datepicker();
    this.ui.adoptionDate.datepicker();
    this.ui.asOfDate.datepicker();
    this.ui.examStartDate.datepicker();
  },

  onChangeTrackingType: function(e) {
    e.preventDefault();

    this.updateFormState(this.ui.trackingTypeSelect.val());
  },

  updateFormState: function(trackingType) {
    this.ui.ceclSection.hide();
    this.ui.mrbSection.hide();
    this.ui.subDebtSection.hide();
    this.ui.redFlagSection.hide();
    this.ui.keyThirdPartyVendorSection.hide();

    var isBankRedFlag = trackingType === '4';
    toggleForm(this.ui.bankRedFlagSection, isBankRedFlag);

    var isCreditUnionTrustRedFlag = trackingType === '13' || trackingType === '23';
    toggleForm(this.ui.creditUnionTrustRedFlagSection, isCreditUnionTrustRedFlag);

    switch (trackingType) {
      // CECL
      case '1':
      case '11':
        this.ui.ceclSection.show();
        return;
      // MRB
      case '2':
      case '12':
      case '21':
        this.ui.mrbSection.show();
        return;
      // Sub-Debt
      case '3':
        this.ui.subDebtSection.show();
        return;
      // Red Flag
      case '4':
      case '13':
      case '23':
        this.ui.redFlagSection.show();
        return;
      // Key Third Party
      case '5':
      case '14':
      case '22':
        this.ui.keyThirdPartyVendorSection.show();
        return;
    }
  },

  validate: function() {
    return this.ui.trackingForm.parsley().whenValidate();
  },

  getFormData: function() {
    return Syphon.serialize(this);
  }
});
