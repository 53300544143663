/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var NProgress = require('nprogress');

var NavUtils = require('psa-core/util/nav');

var Scaffold = require('common/layout/Scaffold');

var GetEntityDocMgmtDetails = require('../../common/services/GetEntityDocMgmtDetails');
var DepSEGDashboardView = require('../views/DepSEGDashboardView');
var GetEntityIdentifiers = require('../../common/services/GetEntityIdentifiers');
var GetSEG = require('../services/GetSEG');

module.exports = function(entityId, segId) {
  NProgress.start();
  NProgress.set(0.6);
  $.when(GetSEG(segId), GetEntityIdentifiers(entityId), GetEntityDocMgmtDetails(entityId))
    .done(function(seg, entityIdentifiers, docMgmt) {
      NavUtils.navigate('dep/entity/' + entityId + '/seg/' + segId + '/dashboard');
      Scaffold.content.show(
        new DepSEGDashboardView({
          viewMode: 'int',
          model: new Backbone.Model({
            entityId: entityId,
            entityIdentifiers: entityIdentifiers,
            segId: segId,
            seg: seg,
            segCSFolderId: docMgmt.segCSFolderId
          })
        })
      );
      NProgress.done();
    })
    .fail(function(err) {
      NProgress.done();
    });
};
