var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "\r\n<div class=\"row m-b-lg\">\r\n  <div class=\"col-sm-12 form-static\">\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-3 col-md-3\">Referral Date</label>\r\n      <div class=\"form-control-static col-sm-9 col-md-9\">\r\n        "
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.AGReferralDate : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "\r\n      </div>\r\n    </div>\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-3 col-md-3\">Referred to AG Amount</label>\r\n      <div class=\"form-control-static col-sm-9 col-md-9\">\r\n        "
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.referredAmountFormatted : depth0),"$0.00",{"name":"default","hash":{},"data":data}))
    + "\r\n      </div>\r\n    </div>\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-3 col-md-3\">AG Payment Total</label>\r\n      <div class=\"form-control-static col-sm-9 col-md-9\">\r\n        "
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.AGPaidFormatted : depth0),"$0.00",{"name":"default","hash":{},"data":data}))
    + "\r\n      </div>\r\n    </div>\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-3 col-md-3\">AG Balance Due</label>\r\n      <div class=\"form-control-static col-sm-9 col-md-9\">\r\n        "
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.AGDueFormatted : depth0),"$0.00",{"name":"default","hash":{},"data":data}))
    + "\r\n      </div>\r\n    </div>\r\n  </div>\r\n</div>";
},"useData":true});