/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');
var _ = require('underscore');

var UIUtil = require('psa-core/util/ui');

var modelGet = require('common/util/modelGet');
var ConfirmationModalView = require('common/views/ConfirmationModalView');
var DropdownView = require('common/views/DropdownView');
var BreadcrumbView = require('common/views/BreadcrumbView');
var SideNavSectionView = require('common/views/SideNavSectionView');
var ContentServerLinkView = require('common/views/ContentServerLinkView');

var DepMrbaDetailsView = require('./DepMrbaDetailsView');

var SubmitMrba = require('../services/SubmitMrba');

var tmplDepMrbaDashboardView = require('../templates/DepMrbaDashboardView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepMrbaDashboardView,

  className: 'dep-mrba-dashboard-view container-fluid',

  ui: {
    breadcrumb: '.breadcrumb-container',
    mrbaActionDropDown: '.dropdown-container',
    mrbaContainer: '.mrba-dashboard-container',
    csLinkContainer: '.cs-link-container'
  },

  regions: {
    breadcrumb: '@ui.breadcrumb',
    mrbaActionDropDown: '@ui.mrbaActionDropDown',
    mrbaContainer: '@ui.mrbaContainer',
    csLinkContainer: '@ui.csLinkContainer'
  },

  templateHelpers: function() {
    return {
      isCU: _.contains(['11', '12', '13', '14'], modelGet(this.model, 'entityIdentifiers.entityTypeId'))
    };
  },

  onBeforeShow: function() {
    var mrba = this.model.get('mrba') || {};
    var mrbaId = this.model.get('mrbaId') || {};
    var entityId = this.model.get('entityId') || {};
    var entityName = modelGet(this.model, 'entityIdentifiers.entityName');
    var mrbaCSFolderId = this.model.get('mrbaCSFolderId');
    var isCU = _.contains(['11', '12', '13', '14'], modelGet(this.model, 'entityIdentifiers.entityTypeId'));

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#dep/dashboard',
            text: 'Home'
          },
          {
            link: '#dep/entity/' + entityId + '/dashboard',
            text: entityName
          },
          {
            text: mrba.category
          }
        ]
      })
    );

    var dropDownOptions = [
      {
        text: isCU ? 'Edit DOR' : 'Edit MRBA',
        link: '#dep/entity/' + entityId + '/mrba/' + mrbaId + '/edit'
      },
      {
        text: isCU ? 'Delete DOR' : 'Delete MRBA',
        onClick: this.onClickDelete.bind(this)
      }
    ];

    this.showChildView(
      'csLinkContainer',
      new ContentServerLinkView({
        model: new Backbone.Model({
          buttonText: isCU ? 'Open DOR Folder' : 'Open MRBA Folder',
          contentServerId: mrbaCSFolderId
        })
      })
    );

    this.showChildView(
      'mrbaActionDropDown',
      new DropdownView({
        rightAlign: true,
        model: new Backbone.Model({
          name: isCU ? 'DOR Actions' : 'MRBA Actions',
          options: dropDownOptions
        })
      })
    );

    this.mrba = new SideNavSectionView({
      collection: new Backbone.Collection([
        {
          id: 'details',
          title: 'Details',
          view: new DepMrbaDetailsView({
            model: this.model
          })
        },
        {
          id: 'notes',
          title: 'Notes',
          view: Radio.channel('dp').request('build:notes-view', {
            objectId: mrbaId,
            objectTypeId: 'MRBA'
          })
        },
        {
          id: 'auditLog',
          title: 'Audit Log',
          view: Radio.channel('dp').request('build:audit-log-view', {
            objectId: mrbaId,
            objectTypeId: 'MRBA'
          })
        }
      ])
    });
    this.showChildView('mrbaContainer', this.mrba);
  },

  onClickDelete: function() {
    var self = this;
    var isCU = _.contains(['11', '12', '13', '14'], modelGet(this.model, 'entityIdentifiers.entityTypeId'));
    var message = isCU ? 'Are you sure you want to delete this DOR?' : 'Are you sure you want to delete this MRBA?';

    UIUtil.showModal({
      title: isCU ? 'Confirm Deleting DOR' : 'Confirm Deleting MRBA',
      view: new ConfirmationModalView({
        confirmText: isCU ? 'Delete DOR' : 'Delete MRBA',
        model: new Backbone.Model({
          message: message,
          onConfirm: function() {
            return SubmitMrba({
              mrbaId: modelGet(self.model, 'mrba.mrbaId'),
              isDeleted: 1
            });
          },
          onConfirmDone: function() {
            Radio.channel('navigate').trigger('show:int:dp:entity', modelGet(self.model, 'mrba.entityId'));
          }
        })
      })
    });
  }
});
