/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function() {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetAllComplaintLookups: {
        $xmlns: 'http://dobs.pa.gov/cs/lookup/bpm/1.0'
      }
    },
    blueprintArrays: [
      'GetAllComplaintLookupsResponse.Lookups.CS_LU_CompanyResponse',
      'GetAllComplaintLookupsResponse.Lookups.CS_LU_ComplaintCode1',
      'GetAllComplaintLookupsResponse.Lookups.CS_LU_ComplaintCode2',
      'GetAllComplaintLookupsResponse.Lookups.CS_LU_ContactMethod',
      'GetAllComplaintLookupsResponse.Lookups.CS_LU_IndividualType',
      'GetAllComplaintLookupsResponse.Lookups.CS_LU_ProductType',
      'GetAllComplaintLookupsResponse.Lookups.CS_LU_Referral',
      'GetAllComplaintLookupsResponse.Lookups.CS_LU_Status',
      'GetAllComplaintLookupsResponse.Lookups.CS_LU_Weight',
      'GetAllComplaintLookupsResponse.Lookups.CS_LU_ContactType',
      'GetAllComplaintLookupsResponse.Lookups.CM_LU_Department'
    ],
    blueprint: {
      companyResponses: [
        '$..CS_LU_CompanyResponse',
        {
          id: '$.ID',
          name: '$.Name'
        }
      ],
      complaintCode1: [
        '$..CS_LU_ComplaintCode1',
        {
          id: '$.ID',
          code1: '$.Code1',
          productTypeId: '$.ProductTypeID',
          isOther: '$.IsOther'
        }
      ],
      complaintCode2: [
        '$..CS_LU_ComplaintCode2',
        {
          id: '$.ID',
          code1Id: '$.Code1ID',
          code2: '$.Code2',
          productTypeId: '$.ProductTypeID',
          isOther: '$.IsOther'
        }
      ],
      contactMethods: [
        '$..CS_LU_ContactMethod',
        {
          id: '$.ID',
          method: '$.Method'
        }
      ],
      individualTypes: [
        '$..CS_LU_IndividualType',
        {
          id: '$.ID',
          type: '$.Type'
        }
      ],
      productTypes: [
        '$..CS_LU_ProductType',
        {
          id: '$.ID',
          type: '$.Type'
        }
      ],
      referrals: [
        '$..CS_LU_Referral',
        {
          id: '$.ID',
          name: '$.Name'
        }
      ],
      complaintStatuses: [
        '$..CS_LU_Status',
        {
          id: '$.ID',
          status: '$.Status'
        }
      ],
      weights: [
        '$..CS_LU_Weight',
        {
          id: '$.ID',
          weight: '$.Weight'
        }
      ],
      contactType: [
        '$..CS_LU_ContactType',
        {
          id: '$.ID',
          type: '$.Type'
        }
      ],
      departments: [
        '$..CM_LU_Department',
        {
          id: '$.ID',
          name: '$.Name',
          description: '$.Description'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      response.departments = _.filter(response.departments, function(department) {
        return _.includes(['1000', '2000', '3000', '4000'], department.id);
      });

      response.departments2 = _.filter(response.departments, function(department) {
        return _.includes(['1000', '2000', '3000'], department.id);
      });

      _.each(response.departments, function(department) {
        if (department.id === '4000') {
          department.description = 'Unlicensed';
        }
      });

      response.departments2.push({
        id: '4000',
        description: 'Other',
        name: 'OTHER'
      });

      response.complaintCode1 = _.sortBy(response.complaintCode1, 'code1');
      response.complaintCode2 = _.sortBy(response.complaintCode2, 'code2');

      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject();
    });
  return deferred.promise();
};
