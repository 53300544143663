/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Ladda = require('ladda');

var tmplTimeTrkNonDepTimesheetStatusModalView = require('../templates/TimeTrkNonDepTimesheetStatusModalView.hbs');
var SubmitTimesheetStatus = require('../services/SubmitTimesheetStatus');

module.exports = Marionette.LayoutView.extend({
  template: tmplTimeTrkNonDepTimesheetStatusModalView,

  className: 'timetrk-nondep-timesheet-status-modal-view',

  ui: {
    timesheetStatusForm: '.timesheet-status-form',
    submit: '.submit'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  // initialize: function(options) {
  //   this.submitNoteService = options.submitNoteService;
  //   this.managerRole = options.managerRole;
  // },

  onBeforeShow: function() {
    var timesheet = this.model.get('timesheet') || {};
    if (timesheet) {
      Syphon.deserialize(this, timesheet);
    }
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;
    this.ui.timesheetStatusForm
      .parsley()
      .whenValidate()
      .done(function() {
        var l = Ladda.create(self.ui.submit[0]);
        l.start();
        var formData = Syphon.serialize(self);
        formData.timesheetId = self.model.get('timesheetId');
        SubmitTimesheetStatus(formData)
          .done(function() {
            self.triggerMethod('modal:close');
          })
          .fail(function() {
            l.stop();
          });
      });
  }
});
