/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');

var formatAsDollars = require('common/util/formatAsDollars');

var tmplSecCompliancePaymentTotalsView = require('../templates/SecCompliancePaymentTotalsView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplSecCompliancePaymentTotalsView,

  className: 'sec-compliance-payment-totals-view',

  onBeforeRender: function() {
    this.calculateAndFormatPayments();
  },

  calculateAndFormatPayments: function() {
    var self = this;
    var payments = this.model.get('payments') || {};

    // Create payment object of all set totals
    var totals = {
      adminAssessments: this.model.get('adminAssessments') || 0,
      investigativeCosts: this.model.get('investigativeCosts') || 0,
      legalCosts: this.model.get('legalCosts') || 0,
      civilPenalty: this.model.get('civilPenalty') || 0,
      globalSettlement: this.model.get('globalSettlement') || 0
    };

    // Get sum of payment totals
    totals.totalCosts = _.reduce(
      _.values(totals),
      function(memo, payment) {
        if (payment) {
          payment = parseFloat(payment);
        }
        return memo + payment;
      },
      0
    );

    totals.adminAssessmentsPaid = 0;
    totals.investigativePaid = 0;
    totals.legalPaid = 0;
    totals.civilPaid = 0;
    totals.globalPaid = 0;

    // Get total paid for each payment type
    _.each(payments, function(payment) {
      totals.adminAssessmentsPaid += parseFloat(payment.adminAssessmentsApplied);
      totals.investigativePaid += parseFloat(payment.investigativeCostsApplied);
      totals.legalPaid += parseFloat(payment.legalCostsApplied);
      totals.civilPaid += parseFloat(payment.civilPenaltyApplied);
      totals.globalPaid += parseFloat(payment.globalSettlementApplied);
    });

    // Get total due for each payment type
    totals.adminAssessmentsDue = totals.adminAssessments - totals.adminAssessmentsPaid;
    totals.investigativeDue = totals.investigativeCosts - totals.investigativePaid;
    totals.legalDue = totals.legalCosts - totals.legalPaid;
    totals.civilDue = totals.civilPenalty - totals.civilPaid;
    totals.globalDue = totals.globalSettlement - totals.globalPaid;

    // Get total paid and due
    totals.paid =
      totals.adminAssessmentsPaid + totals.investigativePaid + totals.legalPaid + totals.civilPaid + totals.globalPaid;
    totals.due =
      totals.adminAssessmentsDue + totals.investigativeDue + totals.legalDue + totals.civilDue + totals.globalDue;

    // Format all dollar amounts
    _.each(totals, function(payment, key) {
      var formattedCost = formatAsDollars(parseFloat(payment));
      self.model.set(key + 'Formatted', formattedCost);
    });

    var restitutionAgreedByOrder = this.model.get('restitutionAgreedByOrder');
    this.model.set('restitutionAgreedByOrderFormatted', formatAsDollars(parseFloat(restitutionAgreedByOrder)));
  }
});
