/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var GridView = require('psa-marionette/views/GridView');

var ButtonLinkCell = require('common/backgrid/ButtonLinkCell');
var MomentCell = require('common/backgrid/MomentCell');

module.exports = GridView.extend({
  className: 'con-ser-entity-complaints-section-view',

  options: {
    emptyText: 'No Complaints',
    columns: [
      {
        name: 'caseNumber',
        label: 'Case Number',
        cell: 'string',
        headerCell: 'custom',
        width: 15,
        editable: false
      },
      {
        name: 'weight',
        label: 'Level',
        cell: 'string',
        headerCell: 'custom',
        width: 15,
        editable: false
      },
      {
        name: 'caseManager',
        label: 'Case Manager',
        cell: 'string',
        headerCell: 'custom',
        width: 15,
        editable: false
      },
      {
        name: 'status',
        label: 'Status',
        cell: 'string',
        headerCell: 'custom',
        width: 15,
        editable: false
      },
      {
        name: 'dateOpenedClosed',
        label: 'Date Opened/Closed',
        cell: MomentCell.MomentCell,
        formatter: MomentCell.MomentDateFormatter.extend({
          emptyValue: '--'
        }),
        sortValue: MomentCell.MomentSortValue,
        headerCell: 'custom',
        width: 15,
        editable: false
      },
      {
        name: '',
        label: '',
        cell: ButtonLinkCell.extend({
          name: 'Open',
          buttonClass: 'btn-primary btn-sm',
          href: function(model) {
            return '#conser/complaint/' + model.get('complaintId') + '/dashboard';
          }
        }),
        headerCell: 'custom',
        width: 25,
        editable: false,
        sortable: false
      }
    ]
  }
});
