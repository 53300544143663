/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Backgrid = require('backgrid');

var formatAsDollars = require('../../util/formatAsDollars');

var DollarFormatter = function(options) {
  _.extend(this, this.defaults, options || {});

  if (this.decimals < 0 || this.decimals > 20) {
    throw new RangeError('decimals must be between 0 and 20');
  }
};
DollarFormatter.prototype = new Backgrid.CellFormatter();
_.extend(DollarFormatter.prototype, {
  defaults: {
    decimals: 2,
    decimalSeparator: '.',
    orderSeparator: ','
  },
  fromRaw: function(number, model) {
    if (_.isNull(number) || _.isUndefined(number) || _.isNaN(number) || number === '') {
      return '--';
    }
    number = parseFloat(number);
    return formatAsDollars(number, this.decimals);
  },
  toRaw: function(formattedData, model) {
    formattedData = formattedData.trim();

    if (formattedData === '' || formattedData === '--') {
      return null;
    }

    var isNegative = false;

    // if(formattedData.substring(0, 1) === '(') {
    //   formattedData = formattedData.substring(1);
    //   formattedData = formattedData.substring(0, formattedData.length - 2);
    //   isNegative = true;
    // }

    if (formattedData.substring(0, 1) === '-') {
      formattedData = formattedData.substring(1);
      isNegative = true;
    }

    var rawData = '';

    formattedData = formattedData.substring(1);

    var thousands = formattedData.split(this.orderSeparator);
    for (var i = 0; i < thousands.length; i++) {
      rawData += thousands[i];
    }

    var decimalParts = rawData.split(this.decimalSeparator);
    rawData = '';
    for (var j = 0; i < decimalParts.length; j++) {
      rawData = rawData + decimalParts[j] + '.';
    }

    if (rawData[rawData.length - 1] === '.') {
      rawData = rawData.slice(0, rawData.length - 1);
    }

    var result = (rawData * 1).toFixed(~~this.decimals) * 1;
    if (_.isNumber(result) && !_.isNaN(result)) {
      if (isNegative) {
        return -result;
      }
      return result;
    }
  }
});

module.exports = Backgrid.DollarCell = Backgrid.NumberCell.extend({
  className: 'dollar-cell',

  formatter: DollarFormatter
});
