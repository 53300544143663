/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(formData) {
  var defer = $.Deferred();

  $.soap({
    body: {
      SubmitLicenseAddress: {
        $xmlns: 'http://dobs.pa.gov/sc/licenseaddress/bpm/1.0',
        ObjectTypeID: formData.objectTypeId,
        ObjectID: formData.objectId,
        AddressID: formData.addressId,
        TypeID: formData.typeId,
        IsPrincipal: formData.isPrincipal,
        CRDBranchNumber: formData.CRDBranchNumber,
        IsDeleted: formData.isDeleted,
        ID: formData.id,
        Address: {
          AddressLine1: formData.addressLine1,
          AddressLine2: formData.addressLine2,
          AddressLine3: formData.addressLine3,
          City: formData.city,
          State: formData.state,
          PostalCode: formData.postalCode,
          Country: formData.country,
          County: formData.county
        }
      }
    },
    blueprint: {
      id: '$..ID',
      objectTypeId: '$..ObjectTypeID',
      objectId: '$..ObjectID',
      addressId: '$..AddressID',
      address: {
        addressLine1: '$..AddressLine1',
        addressLine2: '$..AddressLine2',
        addressLine3: '$..AddressLine3',
        county: '$..County',
        city: '$..City',
        state: '$..State',
        postalCode: '$..PostalCode',
        country: '$..Country'
      },
      county: '$..County',
      country: '$..Country',
      typeId: '$..TypeID',
      isPrincipal: '$..IsPrincipal',
      type: '$..Type'
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      defer.resolve(response);
    })
    .fail(function(err) {
      defer.reject(err);
    });

  return defer.promise();
};
