/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var AddressDisplayView = require('common/views/AddressDisplayView');

var tmplDepSEGDetailsSectionView = require('../templates/DepSEGDetailsSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepSEGDetailsSectionView,

  className: 'dep-seg-details-section-view',

  ui: {
    addressContainer: '.address-container'
  },

  regions: {
    addressContainer: '@ui.addressContainer'
  },

  onBeforeShow: function() {
    Syphon.deserialize(this, this.model.toJSON());

    this.showChildView(
      'addressContainer',
      new AddressDisplayView({
        model: this.model
      })
    );
  }
});
