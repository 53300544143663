/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var GridView = require('psa-marionette/views/GridView');

var BooleanCell = require('common/backgrid/BooleanCell');

var CorpFinRegUnderwritersCollectionView = require('./CorpFinRegUnderwritersCollectionView');

var tmplCorpFinRegSecuritiesDescriptionFormView = require('../templates/CorpFinRegSecuritiesDescriptionFormView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplCorpFinRegSecuritiesDescriptionFormView,

  className: 'corp-fin-reg-securities-description-form-view row',

  ui: {
    currentUnderwritersContainer: '.current-underwriters-container',
    newUnderwritersCollectionContainer: '.new-underwriters-collection-container',
    addUnderwriter: '.add-underwriter'
  },

  regions: {
    currentUnderwritersContainer: '@ui.currentUnderwritersContainer',
    newUnderwritersCollectionContainer: '@ui.newUnderwritersCollectionContainer'
  },

  events: {
    'click @ui.addUnderwriter': 'onClickAddUnderwriter'
  },

  childEvents: {
    'remove:underwriter': 'onChildRemoveUnderwriter'
  },

  onBeforeShow: function() {
    var record = this.model.get('record') || {};
    var currUnderwriters = record.regBDUnderwriters;

    this.currUnderwriters = new GridView({
      columns: [
        {
          name: 'BDUnderwriterName',
          label: 'BD/Underwriter Name',
          cell: 'string',
          headerCell: 'custom',
          width: 45,
          editable: false
        },
        {
          name: 'BDUnderwriterCRD',
          label: 'BD/Underwriter CRD',
          cell: 'string',
          headerCell: 'custom',
          width: 45,
          editable: false
        },
        {
          name: 'shouldDelete',
          label: 'Delete?',
          cell: BooleanCell,
          headerCell: 'custom',
          width: 10,
          editable: true,
          sortable: false
        }
      ],
      emptyText: 'No BD/Underwriters',
      collection: new Backbone.Collection(currUnderwriters)
    });
    this.showChildView('currentUnderwritersContainer', this.currUnderwriters);

    this.newUnderwriters = new CorpFinRegUnderwritersCollectionView({
      collection: new Backbone.Collection([])
    });
    this.showChildView('newUnderwritersCollectionContainer', this.newUnderwriters);

    Syphon.deserialize(this, record);
  },

  onClickAddUnderwriter: function(e) {
    e.preventDefault();
    this.newUnderwriters.collection.add({});
  },

  onChildRemoveUnderwriter: function(childView) {
    this.newUnderwriters.collection.remove(childView);
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    formData.registrationId = this.model.get('recordId');

    formData.existingBDUnderwriters = _.reduce(
      this.currUnderwriters.collection.toJSON(),
      function(memo, underwriter) {
        if (underwriter.shouldDelete) {
          memo.push(underwriter.id);
        }
        return memo;
      },
      []
    );
    formData.newBDUnderwriters = this.newUnderwriters.getFormData();
    return formData;
  }
});
