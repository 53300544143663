/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Radio = require('backbone.radio');
var Ladda = require('ladda');

var SubmitExamDirectorProcessExamResultsTask = require('../../services/SubmitExamDirectorProcessExamResultsTask');
var ExamRecordedResultsView = require('../../views/ExamRecordedResultsView');
var ExamPlacementView = require('../../views/ExamPlacementView');

require('./ExamDirectorProcessExamResultsWorkTabView.css');
var tmplExamDirectorProcessExamResultsWorkTabView = require('./ExamDirectorProcessExamResultsWorkTabView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplExamDirectorProcessExamResultsWorkTabView,

  className: 'exam-director-process-exam-results-work-tab-view container-fluid',

  ui: {
    formWorkTab: '.form-work-tab',
    examRecordedResultsContainer: '.exam-recorded-results-container',
    examPlacementContainer: '.exam-placement-container',
    examDirectorDecision: '.exam-director-decision',
    submit: '.submit'
  },

  regions: {
    examRecordedResultsContainer: '@ui.examRecordedResultsContainer',
    examPlacementContainer: '@ui.examPlacementContainer'
  },

  events: {
    'change @ui.reviewDecision': 'onChangeReviewDecision',
    'change @ui.examDirectorDecision': 'onChangeExamDirectorDecision',
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeShow: function() {
    this.examRecordedResultsView = new ExamRecordedResultsView({
      model: this.model,
      mode: 'exam-director',
      documentTypes: this.options.documentTypes
    });
    this.showChildView('examRecordedResultsContainer', this.examRecordedResultsView);

    this.examPlacementView = new ExamPlacementView({
      model: this.model,
      mode: 'exam-director'
    });
    this.showChildView('examPlacementContainer', this.examPlacementView);
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;
    this.ui.formWorkTab
      .parsley()
      .whenValidate()
      .done(function() {
        var l = Ladda.create(self.ui.submit[0]);
        l.start();
        var formData = Syphon.serialize(self);
        _.extend(formData, {
          taskId: self.model.get('taskId'),
          examId: self.model.get('examId')
        });
        formData.complianceViolationIds = formData.complianceViolationIds || [];
        SubmitExamDirectorProcessExamResultsTask(formData)
          .done(function() {
            Radio.channel('navigate').trigger('show:int:nd:dashboard');
          })
          .fail(function() {
            l.stop();
          });
      });
  }
});
