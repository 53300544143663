/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Ladda = require('ladda');

var SubmitNewFiling = require('../../filing-forms/services/SubmitNewFiling');

var tmplDepEntityConfirmCreateFilingModalView = require('../templates/DepEntityConfirmCreateFilingModalView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepEntityConfirmCreateFilingModalView,

  className: 'dep-ext-entity-confirm-create-filing-modal-view',

  ui: {
    cancelButton: '.btn-cancel',
    submitButton: '.btn-submit'
  },

  events: {
    'click @ui.submitButton': 'onClickSubmit'
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;
    var data = {
      entityId: self.options.entityId,
      isExternalFiling: self.options.isExternalFiling,
      filingTypeId: self.options.filingTypeId
    };
    var laddaContext = Ladda.create(self.ui.submitButton.get(0));
    laddaContext.start();
    SubmitNewFiling(data)
      .done(function(response) {
        self.triggerMethod('modal:close', response);
      })
      .fail(function() {
        laddaContext.stop();
      });
  }
});
