/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(data) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetExamsForSelection: {
        $xmlns: 'http://dobs.pa.gov/sc/exam/bpm/1.0',
        LicenseTypeID: data.orgLicenseTypeId,
        DBAName: data.dbaName,
        ExamNumber: data.examNumber,
        AssignedToUsername: data.assignedToUsername
      }
    },
    blueprintArrays: ['GetExamsForSelectionResponse.SC_Exam'],
    blueprint: {
      scExams: [
        '$..SC_Exam',
        {
          objectId: '$..ID',
          name: '$..DBAName',
          openedDate: '$..OpenedDate',
          number: '$..ExamNumber',
          licenseType: '$..LicenseType'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      _.each(response.scExams, function(scExam) {
        if (scExam.openedDate) {
          scExam.openedDate = moment(scExam.openedDate, 'YYYY-MM-DD');
        }
        var openedDateDisplay = 'Not Started';
        if (scExam.openedDate && moment.isMoment(scExam.openedDate) && scExam.openedDate.isValid()) {
          openedDateDisplay = scExam.openedDate.format('MM/DD/YYYY');
        }
        scExam.objectDisplayName = 'EXAM #' + scExam.number + ' - ' + scExam.name + ' - ' + openedDateDisplay;
        scExam.objectType = 'Exam';
        scExam.objectTypeId = 'EXAM';
      });

      response.totalHits = response.scExams.length;

      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
