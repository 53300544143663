var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return "<a class=\"btn btn-primary\" href=\"#conser/complaint/"
    + container.escapeExpression(((helper = (helper = helpers.complaintId || (depth0 != null ? depth0.complaintId : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"complaintId","hash":{},"data":data}) : helper)))
    + "/new-related-case\" role=\"button\">Add Related Case</a>\r\n<div class=\"d-inline-block alert alert-inline alert-danger\">\r\n  Be sure to save any changes to the complaint before adding a related case.\r\n</div>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.complaintId : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "<div class=\"related-complaint-list\"></div>";
},"useData":true});