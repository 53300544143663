/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(annualReportId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetAnnualReportFinancialStatements: {
        $xmlns: 'http://dobs.pa.gov/nd/annualreport/bpm/1.0',
        AnnualReportID: annualReportId
      }
    },
    blueprintArrays: ['GetAnnualReportFinancialStatementsResponse.ND_AnnualReportFinancialStatement'],
    blueprint: {
      financialStatements: [
        '$..ND_AnnualReportFinancialStatement',
        {
          annualReportFinancialStatementId: '$..ID',
          documentId: '$.DocumentID',
          documentTypeId: '$.DocumentTypeID',
          documentType: '$.DocumentType',
          file: {
            fileId: '$.DocumentID',
            fileName: '$.DocumentName'
          }
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      deferred.resolve(response.financialStatements);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
