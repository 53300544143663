var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "\r\n<div class=\"page-title clearfix\">\r\n  <h1 class=\"pull-left m-n p-n\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.entity : depth0)) != null ? stack1.entityName : stack1), depth0))
    + "</h1>\r\n</div>\r\n<div class=\"breadcrumb-container\"></div>\r\n<div class=\"row\">\r\n  <div class=\"col-sm-12 col-md-8 col-md-offset-1\">\r\n    <div class=\"row\">\r\n      <div class=\"form-group\">\r\n        <div class=\"col-sm-9 col-sm-offset-3\">\r\n          <h3 class=\"m-t-n\">Join Individual to Entity</h3>\r\n        </div>\r\n      </div>\r\n    </div>\r\n    <form class=\"individual-selection-form\">\r\n      <div class=\"individual-section\">\r\n        <div class=\"row\">\r\n          <h3 class=\"col-sm-offset-3 col-sm-9\">Individual</h3>\r\n        </div>\r\n        <div class=\"row form-group required\">\r\n          <div class=\"col-sm-offset-3 col-sm-6\">\r\n            <div class=\"input-group\">\r\n              <span class=\"input-group-btn\">\r\n                <button class=\"btn btn-primary btn-individual-search\">Search for Individual</button>\r\n              </span>\r\n              <input type=\"text\" class=\"selected-individual-name form-control\"\r\n                placeholder=\"Search for an Individual\" name=\"individualName\" readonly>\r\n              <span class=\"input-group-btn\">\r\n                <button class=\"btn btn-default clear-individual\">Clear</button>\r\n              </span>\r\n              <input type=\"number\" class=\"selected-individual-id\" name=\"individualId\" data-parsley-required=\"true\"\r\n                data-parsley-errors-container=\".individual-select-error-container\" hidden>\r\n            </div>\r\n            <div class=\"individual-select-error-container\"></div>\r\n          </div>\r\n        </div>\r\n      </div>\r\n    </form>\r\n    \r\n    <div class=\"row m-b-lg\">\r\n      <div class=\"form-group\">\r\n        <div class=\"col-sm-9 col-sm-offset-3\">\r\n          <a href=\"#conser/entity/"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.entity : depth0)) != null ? stack1.entityId : stack1), depth0))
    + "/dashboard\" class=\"btn btn-default\">Cancel</a>\r\n          <button class=\"btn btn-primary submit ladda-button\" data-style=\"expand-right\" type=\"button\">\r\n            <span class=\"ladda-label\">Submit</span>\r\n          </button>\r\n        </div>\r\n      </div>\r\n    </div>\r\n  </div>\r\n</div>";
},"useData":true});