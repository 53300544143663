/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var NProgress = require('nprogress');

var NavUtils = require('psa-core/util/nav');

var Scaffold = require('common/layout/Scaffold');
var GetUsersForRole = require('common/services/GetUsersForRole');

var GetEntityIdentifiers = require('../../common/services/GetEntityIdentifiers');
var GetEntityExam = require('../services/GetEntityExam');

var DepExamDashboardView = require('../views/DepExamDashboardView');

module.exports = function(entityId, examId) {
  NProgress.start();
  NProgress.set(0.6);

  $.when(GetEntityIdentifiers(entityId), GetEntityExam(examId))
    .done(function(entityIdentifiers, exam) {
      var roleString = '';
      if (entityIdentifiers.categoryId === '1') {
        roleString = 'DP - EIC - Bank';
      } else if (entityIdentifiers.categoryId === '2') {
        roleString = 'DP - EIC - Credit Union';
      } else if (entityIdentifiers.categoryId === '3') {
        roleString = 'DP - EIC - Trust';
      }
      GetUsersForRole(roleString)
        .done(function(examiners) {
          NavUtils.navigate('#dep/entity/' + entityId + '/exam/' + examId + '/dashboard');
          Scaffold.content.show(
            new DepExamDashboardView({
              model: new Backbone.Model({
                entityId: entityId,
                entityName: entityIdentifiers.entityName,
                examsCSFolderId: entityIdentifiers.examsCSFolderId,
                entityTypeId: entityIdentifiers.entityTypeId,
                entityCategoryId: entityIdentifiers.categoryId,
                entityCharterTypeId: entityIdentifiers.charterTypeId,
                entityHasLegalHold: entityIdentifiers.hasLegalHold,
                exam: exam,
                examiners: examiners
              })
            })
          );
          NProgress.done();
        })
        .fail(function(err) {
          NProgress.done();
        });
    })
    .fail(function() {
      NProgress.done();
    });
};
