/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

module.exports = function(formData) {
  return $.soap({
    body: {
      SubmitRegistrationSecuritiesDescription: {
        $xmlns: 'http://dobs.pa.gov/cf/registration/bpm/1.0',
        RegistrationID: formData.registrationId,
        SecuritiesDescription: formData.securitiesDescription,
        NewBDUnderwriters: (function() {
          if (!formData.newBDUnderwriters || !formData.newBDUnderwriters.length) {
            return {};
          } else {
            return {
              BDUnderwriter: _.map(formData.newBDUnderwriters, function(BDUnderwriter) {
                return {
                  BDUnderwriterName: BDUnderwriter.BDUnderwriterName,
                  BDUnderwriterCRD: BDUnderwriter.BDUnderwriterCRD
                };
              })
            };
          }
        })(),
        ExistingBDUnderwriters: (function() {
          if (!formData.existingBDUnderwriters || !formData.existingBDUnderwriters.length) {
            return {};
          }
          return { BDUnderwriterID: formData.existingBDUnderwriters };
        })()
      }
    }
  });
};
