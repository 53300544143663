/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');

var BreadcrumbView = require('common/views/BreadcrumbView');
var modelGet = require('common/util/modelGet');
var LicenseHeaderBarView = require('../../common/views/LicenseHeaderBarView');

var LicenseCollectorRepossessorEmployeeApplicationView = require('../forms/LicenseCollectorRepossessorEmployeeApplication');

var tmplLicenseApplyCollectorRepossessorEmployeeView = require('../templates/LicenseApplyCollectorRepossessorEmployeeView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplLicenseApplyCollectorRepossessorEmployeeView,

  className: 'license-apply-collector-repossessor-employee-view container-fluid',

  ui: {
    headerBarContainer: '.header-bar-container',
    breadCrumbContainer: '.breadcrumb-container',
    licenseApplyCollectorRepossessorEmployeeFormContainer:
      '.license-apply-collector-repossessor-employee-form-container'
  },

  regions: {
    headerBarContainer: '@ui.headerBarContainer',
    breadCrumbContainer: '@ui.breadCrumbContainer',
    licenseApplyCollectorRepossessorEmployeeFormContainer: '@ui.licenseApplyCollectorRepossessorEmployeeFormContainer'
  },

  onBeforeShow: function() {
    this.showChildView(
      'headerBarContainer',
      new LicenseHeaderBarView({
        model: new Backbone.Model(this.model.get('licenseIdentifier'))
      })
    );

    var entityId = this.model.get('entityId');
    var licenseId = this.model.get('licenseId');
    var entityName = modelGet(this.model, 'entityIdentifier.entityName');
    var licenseNumber = modelGet(this.model, 'licenseIdentifier.licenseNumber');
    var licenseType = modelGet(this.model, 'licenseIdentifier.licenseType');

    this.showChildView(
      'breadCrumbContainer',
      new BreadcrumbView({
        breadcrumbs: [
          {
            text: 'Home',
            link: '#ext/nondep/dashboard'
          },
          {
            text: entityName,
            link: '#ext/nondep/entity/' + entityId + '/dashboard'
          },
          {
            text: 'License #' + licenseNumber + ' - ' + licenseType,
            link: '#ext/nondep/entity/' + entityId + '/license/' + licenseId
          },
          {
            text: 'Apply for a Collector Repossessor Employee'
          }
        ]
      })
    );

    this.showChildView(
      'licenseApplyCollectorRepossessorEmployeeFormContainer',
      new LicenseCollectorRepossessorEmployeeApplicationView({
        isReadOnly: false,
        model: this.model
      })
    );
    Radio.channel('nd:submit-application').on(
      'submit',
      function() {
        Radio.channel('navigate').trigger(
          'show:ext:nd:license-dashboard',
          this.model.get('entityId'),
          this.model.get('licenseId')
        );
      }.bind(this)
    );
  }
});
