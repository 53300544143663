/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

module.exports = function(formData) {
  var defer = $.Deferred();

  $.soap({
    body: {
      SubmitNewLicenseApplication: {
        $xmlns: 'http://dobs.pa.gov/nd/application/bpm/1.0',
        LicenseTypeID: formData.licenseTypeId,
        ParentObjectID: formData.parentObjectId,
        ParentObjectType: formData.parentObjectType,
        LocationID: formData.locationId
      }
    },
    blueprint: {
      applicationId: '$..ApplicationID.__text'
    }
  })
    .done(function(response) {
      defer.resolve(response.applicationId);
    })
    .fail(function(err) {
      defer.reject();
    });

  return defer.promise();
};
