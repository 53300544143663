/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');

var GridView = require('psa-marionette/views/GridView');
var PageableCollection = require('psa-backbone/models/PageableCollection');
var MomentCell = require('common/backgrid/MomentCell');

var tmplNonDepLicenseBondHistoryView = require('../templates/NonDepLicenseBondHistoryView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepLicenseBondHistoryView,

  className: 'non-dep-license-bond-history-view',

  ui: {
    licenseBondHistoryGridContainer: '.license-bond-history-grid-container'
  },

  regions: {
    licenseBondHistoryGridContainer: '@ui.licenseBondHistoryGridContainer'
  },

  onBeforeShow: function() {
    // Fill in data with display values.
    var bonds = _.map(this.model.get('bondHistory'), function(bond) {
      bond.licenseRequirement = bond.licenseRequirement === '1' ? 'Yes' : 'No';
      bond.name = bond.name || '--';
      bond.bondNumber = bond.bondNumber || '--';
      bond.bondAmount = bond.bondAmount || '--';
      bond.type = bond.type || '--';
      bond.status = bond.status || '--';
      return bond;
    });

    this.bondHistoryGrid = new GridView({
      columns: [
        {
          name: 'modifiedDate',
          label: 'Date Changed',
          cell: MomentCell.MomentCell,
          formatter: MomentCell.MomentDateTimeFormatter.extend({
            emptyValue: '--'
          }),
          sortValue: MomentCell.MomentSortValue,
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'modifiedBy',
          label: 'Changed By',
          cell: 'string',
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'name',
          label: 'Bond Company',
          cell: 'string',
          headerCell: 'custom',
          width: 15,
          editable: false
        },
        {
          name: 'bondNumber',
          label: 'Bond #',
          cell: 'string',
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'type',
          label: 'Type',
          cell: 'string',
          headerCell: 'custom',
          width: 15,
          editable: false
        },
        {
          name: 'expiration',
          label: 'Expiration Date',
          cell: MomentCell.MomentCell,
          formatter: MomentCell.MomentDateFormatter.extend({
            emptyValue: '--'
          }),
          sortValue: MomentCell.MomentSortValue,
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'requiredTo',
          label: 'Required To',
          cell: MomentCell.MomentCell,
          formatter: MomentCell.MomentDateFormatter.extend({
            emptyValue: '--'
          }),
          sortValue: MomentCell.MomentSortValue,
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'status',
          label: 'Status',
          cell: 'string',
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'licenseRequirement',
          label: 'License Requirements',
          cell: 'string',
          headerCell: 'custom',
          width: 10,
          editable: false
        }
      ],
      collection: new PageableCollection(bonds, { pageSize: 25 }),
      emptyText: 'No License Bond History'
    });

    this.showChildView('licenseBondHistoryGridContainer', this.bondHistoryGrid);

    this.bondHistoryGrid.viewGrid.sort('modifiedDate', 'descending');
  }
});
