/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(applicationId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetApplicationDBAs: {
        $xmlns: 'http://dobs.pa.gov/nd/application/bpm/1.0',
        ApplicationID: applicationId
      }
    },
    blueprintArrays: [
      'GetApplicationDBAsResponse.AppDBAs.ND_ApplicationDBA',
      'GetApplicationDBAsResponse.EntityDBAs.ND_EntityDBA'
    ],
    blueprint: {
      appDBAs: [
        '$..ND_ApplicationDBA',
        {
          id: '$.ID',
          applicationId: '$.ApplicationID',
          entityDBAId: '$.EntityDBAID'
        }
      ],
      entityDBAs: [
        '$..ND_EntityDBA',
        {
          id: '$.ID',
          dba: '$.DBA',
          fictitiousNameDocument: {
            fileId: '$.FictitiousDocumentID',
            fileName: '$.FictitiousDocumentName'
          },
          registrationDate: '$.RegistrationDate',
          endDate: '$.EndDate'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject(err);
    });

  return deferred.promise();
};
