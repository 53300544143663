/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(licenseTypeIDs) {
  var defer = $.Deferred();

  $.soap({
    body: {
      GetIssueExamDashboard: {
        $xmlns: 'http://dobs.pa.gov/nd/exam/bpm/1.0',
        LicenseTypeIDs: {
          value: licenseTypeIDs
        }
      }
    },
    blueprintArrays: ['GetIssueExamDashboardResponse.ND_Exam'],
    blueprint: {
      licenses: [
        '$..ND_Exam',
        {
          licenseNumber: '$..LicenseNumber',
          licenseId: '$..LicenseID',
          licenseTypeId: '$..LicenseTypeID',
          originalIssueDate: '$..OriginalIssueDate',
          entityName: '$..EntityName',
          entityId: '$..EntityID',
          address: {
            addressLine1: '$..AddressLine1',
            addressLine2: '$..AddressLine2',
            addressLine3: '$..AddressLine3',
            city: '$..City',
            postalCode: '$..PostalCode',
            state: '$..State',
            country: '$..Country'
          },
          county: '$..County',
          numRegion: '$..NumRegion',
          isUnlicensed: '$..IsUnlicensed',
          previousExamCoverageStartDate: '$..PreviousExamCoverageStartDate',
          previousExamCoverageEndDate: '$..PreviousExamCoverageEndDate',
          sortByDate: '$..SortByDate',
          nonDExamRegion: '$..NonDExamRegion',
          nonDExamRegionId: '$..NonDExamRegionID'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      _.each(response.licenses, function(license) {
        if (license.originalIssueDate) {
          license.originalIssueDate = moment(license.originalIssueDate, 'YYYY-MM-DD');
        }
        if (license.previousExamCoverageStartDate) {
          license.previousExamCoverageStartDate = moment(license.previousExamCoverageStartDate, 'YYYY-MM-DD');
        }
        if (license.previousExamCoverageEndDate) {
          license.previousExamCoverageEndDate = moment(license.previousExamCoverageEndDate, 'YYYY-MM-DD');
        }
        if (license.sortByDate) {
          license.sortByDate = moment(license.sortByDate, 'YYYY-MM-DD');
        }

        if (license.address.state !== 'PA' || license.address.country !== 'US') {
          license.nonDExamRegion = null;
          license.nonDExamRegionId = null;
        }

        if (license.numRegion) {
          license.nonDExamRegion = null;
          license.nonDExamRegionId = null;
        }
      });
      defer.resolve(response.licenses);
    })
    .fail(function(err) {
      defer.reject(err);
    });

  return defer.promise();
};
