/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */
var _ = require('underscore');

var PageableCollection = require('psa-backbone/models/PageableCollection');
var GridView = require('psa-marionette/views/GridView');

var BooleanCell = require('common/backgrid/BooleanCell');
var AddressCell = require('common/backgrid/AddressCell');
var PlaceholderView = require('common/views/PlaceholderView');

var GetEntityLocationsByEntity = require('../services/GetEntityLocationsByEntity');

module.exports = {
  channel: 'dp',

  event: 'build:dep-location-picker-grid-view',

  builder: function(entityId) {
    return new PlaceholderView({
      viewPromise: buildLocationPickGridView(entityId)
    });
  }
};

function buildLocationPickGridView(entityId) {
  var deferred = $.Deferred();

  var gridColumns = [
    {
      name: 'shouldKeep',
      label: 'Include?',
      cell: BooleanCell,
      headerCell: 'custom',
      width: 10,
      editable: true,
      sortable: false
    },
    {
      name: 'address',
      label: 'Address',
      cell: AddressCell,
      headerCell: 'custom',
      width: 60,
      editable: false,
      sortable: false
    },
    {
      name: 'country',
      label: 'Country',
      cell: 'string',
      headerCell: 'custom',
      width: 30,
      editable: false
    }
  ];
    GetEntityLocationsByEntity(entityId)
    .done(function(entityLocations) {
          entityLocations = _.filter(entityLocations, function(location) {
            return location.status === "Active";
      });
      deferred.resolve(
        new GridView({
          columns: gridColumns,
          collection: new PageableCollection(entityLocations, { pageSize: 25 }),
          emptyText: 'No Locations Available.'
        })
      );
    })
    .fail(function(err) {
      deferred.reject(err);
    });
  return deferred.promise();
}
