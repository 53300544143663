/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Backbone = require('backbone');
var Radio = require('backbone.radio');
var Marionette = require('backbone.marionette');

var SelectFieldView = require('common/views/SelectFieldView');

var TimeTrkDepExaminerEntryView = require('../../dep-timesheet/views/TimeTrkDepExaminerEntryView');
var TimeTrkSecExaminerEntryView = require('../../sec-timesheet/views/TimeTrkSecExaminerEntryView');
var TimeTrkInvalidTimesheetView = require('./TimeTrkInvalidTimesheetView');

var tmplTimeTrkPeriodSelectionView = require('../templates/TimeTrkPeriodSelectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplTimeTrkPeriodSelectionView,

  className: 'time-trk-period-selection-view container-fluid',

  ui: {
    yearSelect: '.year-select',
    periodSelect: '.period-select',
    goToPeriod: '.go-to-period',
    timeTrackingForm: '.time-tracking-form'
  },

  regions: {
    periodSelect: '@ui.periodSelect',
    timeTrackingForm: '@ui.timeTrackingForm'
  },

  events: {
    'change @ui.yearSelect': 'onChangeYear',
    'click @ui.goToPeriod': 'onClickGoToPeriod'
  },

  onBeforeShow: function() {
    var timesheet = this.model.get('timesheet') || {};
    var timesheetId = timesheet.timesheetId;
    var timesheetEntries = timesheet.timesheetEntries || {};
    var currYear = timesheet.year;

    var isDep = this.model.get('dptId') === '2000';
    var isSec = this.model.get('dptId') === '3000';

    this.ui.yearSelect.val(currYear);

    this.periodSelect = new SelectFieldView({
      isDisabled: false,
      isRequired: false,
      name: 'periodId',
      collection: new Backbone.Collection([])
    });
    this.showChildView('periodSelect', this.periodSelect);

    this.onChangeYear();
    this.periodSelect.setValue(timesheet.periodId);

    // Set which dpt's timesheet will initially load below time period selector
    // Default to show current/most recent timesheet
    if (isDep && timesheetId) {
      this.timeTrackingForm = new TimeTrkDepExaminerEntryView({
        collection: new Backbone.Collection(timesheetEntries),
        model: this.model,
        isReadOnly: this.options.isReadOnly
      });
    } else if (isSec && timesheetId) {
      this.timeTrackingForm = new TimeTrkSecExaminerEntryView({
        collection: new Backbone.Collection(timesheetEntries),
        model: this.model,
        isReadOnly: this.options.isReadOnly
      });
    } else {
      this.timeTrackingForm = new TimeTrkInvalidTimesheetView();
    }

    this.showChildView('timeTrackingForm', this.timeTrackingForm);
  },

  onChangeYear: function() {
    var year = this.ui.yearSelect.val();
    var allPeriods = this.model.get('periods');
    this.periodSelect.collection.reset(
      _.chain(allPeriods)
        .where({ year: year })
        .map(function(filteredPeriod) {
          return { value: filteredPeriod.id, text: filteredPeriod.payPeriodDisplay };
        })
        .value()
    );
  },

  onClickGoToPeriod: function(e) {
    e.preventDefault();
    var self = this;

    var periodId = this.periodSelect.getValue();
    var departmentId = this.model.get('dptId');
    var isDep = departmentId === '2000';
    var buildStr = isDep ? 'build:time-trk-dep-timesheet-view' : 'build:time-trk-sec-timesheet-view';

    // Build new timesheet form for given pay period and current department
    self.showChildView('timeTrackingForm', Radio.channel('tt').request(buildStr, periodId, departmentId));
  }
});
