/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var momentUtil = require('common/util/momentUtil');
var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(officerId) {
  var deferred = $.Deferred();
  $.soap({
    body: {
      GetEntityOfficer: {
        $xmlns: 'http://dobs.pa.gov/nd/entity/bpm/1.0',
        OfficerID: officerId
      }
    },
    blueprintArrays: ['GetEntityOfficerResponse.AssociatedLicenses.ND_EntityOfficerAssociatedLicense'],
    blueprint: {
      id: '$..ID',
      entityOfficerId: '$..ID',
      entityId: '$..EntityID',
      individualId: '$..IndividualID',
      firstName: '$..FirstName',
      lastName: '$..LastName',
      title: '$..Title',
      ssn: '$..SSN',
      dob: '$..DOB',
      ein: '$..EIN',
      homePhoneNumber: '$..HomePhoneNumber',
      cellPhoneNumber: '$..CellPhoneNumber',
      email: '$..Email',
      addressId: '$..AddressID',
      addressLine1: '$..AddressLine1',
      addressLine2: '$..AddressLine2',
      addressLine3: '$..AddressLine3',
      city: '$..City',
      state: '$..State',
      postalCode: '$..PostalCode',
      country: '$..Country',
      officerType: '$..EntityOfficerTypeName',
      entityOfficerTypeId: '$..EntityOfficerTypeID',
      associatedLicenses: [
        '$..ND_EntityOfficerAssociatedLicense',
        {
          id: '$..ID',
          entityOfficerId: '$..EntityOfficerID',
          licenseNumber: '$..LicenseNumber',
          licenseType: '$..LicenseType',
          entityName: '$..EntityName'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      response.dob = momentUtil.formatStr(response.dob, {
        sourceFormat: 'YYYY-MM-DD',
        targetFormat: 'MM/DD/YYYY',
        default: ''
      });

      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject(err);
    });

  return deferred.promise();
};
