/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');

var GridView = require('psa-marionette/views/GridView');
var PageableCollection = require('psa-backbone/models/PageableCollection');

var tmplCorpFinRegSecuritiesDescriptionSectionView = require('../templates/CorpFinRegSecuritiesDescriptionSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplCorpFinRegSecuritiesDescriptionSectionView,

  className: 'corp-fin-reg-securities-description-section-view form-horizontal form-static',

  ui: {
    underwritersGrid: '.underwriters-grid'
  },

  regions: {
    underwritersGrid: '@ui.underwritersGrid'
  },

  onBeforeShow: function() {
    this.underwritersGrid = new GridView({
      columns: [
        {
          name: 'BDUnderwriterName',
          label: 'BD/Underwriter Name',
          cell: 'string',
          headerCell: 'custom',
          width: 50,
          editable: false
        },
        {
          name: 'BDUnderwriterCRD',
          label: 'BD/Underwriter CRD',
          cell: 'string',
          headerCell: 'custom',
          width: 50,
          editable: false
        }
      ],
      emptyText: 'No BD/Underwriters',
      collection: new PageableCollection(this.model.get('regBDUnderwriters'), { pageSize: 10 })
    });
    this.showChildView('underwritersGrid', this.underwritersGrid);
  }
});
