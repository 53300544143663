/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Syphon = require('backbone.syphon');
var Marionette = require('backbone.marionette');

require('../styles/TimeTrkHoursFormView.css');
var tmplTimeTrkHoursFormView = require('../templates/TimeTrkHoursFormView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplTimeTrkHoursFormView,

  className: 'time-trk-hours-form-view',

  events: {
    'blur @ui.timeInput': 'onBlurTimeInput'
  },

  behaviors: {
    ReadOnlyFormBehavior: {}
  },

  ui: {
    timeInput: '.time-input'
  },

  onBeforeShow: function() {
    Syphon.deserialize(this, this.model.get('timeEntries'));
  },

  onBlurTimeInput: function() {
    var timesObj = {};

    // Format times and create object containing all 10 entries
    _.each(this.ui.timeInput, function(time) {
      var name = time.name;
      if (time.value || time.value === '') {
        if (time.value) {
          time.value = parseFloat(time.value).toFixed(2);
          if (time.value > 24) {
             time.value = 24;
          }
          if (time.value < 0) {
            time.value = 0;
          }
        } else {
          time.value = '';
        }
        timesObj[name] = time.value;
      }
    });

    this.model.set('timeEntries', timesObj);
    this.triggerMethod('update:time-entries'); // TimeTrkNonDepEntryRowView
  }
});
