/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Coulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remai
 * the property of Counterpoint Coulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Coulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Coulting Incorporated.
 */

var _ = require('underscore');
var _get = require('lodash.get');

module.exports = function(formData) {
  var pointOfContact = formData.pointOfContact || {};
  var lawyerExternalUserId = _get(formData, 'bankAquisitionMergerSubmissionLawyer.lawyerExternalUserId');
  var applicationInfo = formData.applicationInfo || {};
  var targetAddress = applicationInfo.targetAddress || {};
  var targetHCAddress = applicationInfo.targetHCAddress || {};
  var acquiringAddress = applicationInfo.acquiringAddress || {};

  var pubReqs = formData.pubReqs || {};
  var nameRes = formData.nameRes || {};
  var fedApps = formData.fedApps || {};
  var reqDocs = formData.reqDocs || {};
  var supportingDocuments = formData.supportingDocuments || [];
  var certification = formData.certification || {};

  return $.soap({
    body: {
      SubmitBankMergerAcqFiling: {
        $xmlns: 'http://dobs.pa.gov/dp/filing/bpm/1.0',
        FilingID: formData.filingId,
        TargetAddress: {
          AddressLine1: targetAddress.addressLine1,
          AddressLine2: targetAddress.addressLine2,
          AddressLine3: targetAddress.addressLine3,
          County: targetAddress.county,
          City: targetAddress.city,
          State: targetAddress.state,
          PostalCode: targetAddress.postalCode,
          Country: targetAddress.country
        },
        TargetHCAddress:
        {
          AddressLine1: targetHCAddress.addressLine1,
          AddressLine2: targetHCAddress.addressLine2,
          AddressLine3: targetHCAddress.addressLine3,
          County: targetHCAddress.county,
          City: targetHCAddress.city,
          State: targetHCAddress.state,
          PostalCode: targetHCAddress.postalCode,
          Country: targetHCAddress.country

        },
        AcquiringAddress:
        {
          AddressLine1: acquiringAddress.addressLine1,
          AddressLine2: acquiringAddress.addressLine2,
          AddressLine3: acquiringAddress.addressLine3,
          County: acquiringAddress.county,
          City: acquiringAddress.city,
          State: acquiringAddress.state,
          PostalCode: acquiringAddress.postalCode,
          Country: acquiringAddress.country,
        },
        AcquiringName: applicationInfo.acquiringName,
        AcquiringfrbDistrict:  applicationInfo.acquiringfrbDistrict,
        TargetHCName: applicationInfo.targetHCName,
        TargetHCfrbDistrict: applicationInfo.targetHCfrbDistrict,
        IsApplicable: applicationInfo.isApplicable,
        BankMergerAcqFilingID: formData.bankMergerAcqFilingId,
        IsTrustMerger: applicationInfo.isTrustMerger,
        IsSavingsMerger: applicationInfo.isSavingsBankMerger,
        TargetHCFedReserveBankID: applicationInfo.targetHCFedReserveBankId,
        TargetHCFedReserveBankOther: applicationInfo.targetHCFedReserveBankOther,
        TargetName: applicationInfo.targetName,
        TargetBusiness: applicationInfo.targetBusiness,
        TargetPhone: applicationInfo.targetPhone,
        TargetEmail: applicationInfo.targetEmail,
        CharterStateCharterID: applicationInfo.charterStateCharterId,
        CharterCharterTypeID: applicationInfo.charterCharterTypeId,
        CharterState: applicationInfo.charterState,
        CharterIsTrustPowers: applicationInfo.charterIsTrustPowers,
        OwnershipStructureID: applicationInfo.ownershipStructureId,
        PrimaryRegulatorID: applicationInfo.primaryRegulatorId,
        IsHoldingCompany: applicationInfo.isHoldingCompany,
        PublicationReqsDocID: pubReqs.publicationReqsDocId,
        PublicationReqsComments: pubReqs.publicationReqsComments,
        NameTypeID: nameRes.nameTypeId,
        ProposedName: nameRes.proposedName,
        ProposedNameComments: nameRes.proposedNameComments,
        ProposedNameDocumentID: nameRes.proposedNameDocumentId,
        InterimName: nameRes.interimName,
        InterimNameComments: nameRes.interimNameComments,
        InterimNameDocumentID: nameRes.interimNameDocumentId,
        IsUploadingFedApp: fedApps.isUploadingFedApp,
        FederalApplicationDocID: fedApps.federalApplicationsDocId,
        FederalApplicationComments: fedApps.federalApplicationsComments,
        TransactionPurpose: fedApps.transactionPurpose,
        MergerIssues: fedApps.mergerIssues,
        NonconformingActivities: fedApps.nonconformingActivities,
        OfficalInformation: fedApps.officalInformation,
        CommunityConvenience: fedApps.communityConvenience,
        BalanceSheetDocID: fedApps.balanceSheetDocId,
        IncomeStatementDocID: fedApps.incomeStatementDocId,
        CapitalScheduleDocID: fedApps.capitalScheduleDocId,
        CompanyName: fedApps.companyName,
        InsuranceActivity: fedApps.insuranceActivity,
        LinesOfBusiness: fedApps.linesOfBusiness,
        ProposedTransaction: fedApps.proposedTransaction,
        ManagementRelationships: fedApps.managementRelationships,
        AdditionalComments: fedApps.additionalComments,
        ArticlesOfMergerDocID: reqDocs.articlesOfMergerDocId,
        ArticlesOfMergerComments: reqDocs.articlesOfMergerComments,
        PlanOfMergerDocID: reqDocs.planOfMergerDocId,
        PlanOfMergerComments: reqDocs.planOfMergerComments,
        MeetingNoticeDocID: reqDocs.meetingNoticeDocId,
        MeetingNoticeComments: reqDocs.meetingNoticeComments,
        NameReservationDocID: reqDocs.nameReservationDocId,
        NameReservationComments: reqDocs.nameReservationComments,
        BranchDocumentationDocID: reqDocs.branchDocumentationDocId,
        BranchDocumentationComments: reqDocs.branchDocumentationComments,
        SubsidiaryDocID: reqDocs.subsidiaryAffiliatesDocId,
        SubsidiaryComments: reqDocs.subsidiaryAffiliatesComments,
        QuestionnaireDocID: fedApps.questionnaireDocId,
        QuestionnaireComments: fedApps.questionnaireComments,
        IsCertified: certification.isCertified ? '1' : '0',
        CertificationName: certification.certificationName,
        CertificationDocID: certification.certificationDocId,
        PointOfContact: {
          Name: pointOfContact.name,
          Title: pointOfContact.title,
          Business: pointOfContact.business,
          Phone: pointOfContact.phone,
          Email: pointOfContact.email,
          AddressLine1: pointOfContact.addressLine1,
          AddressLine2: pointOfContact.addressLine2,
          AddressLine3: pointOfContact.addressLine3,
          City: pointOfContact.city,
          State: pointOfContact.state,
          PostalCode: pointOfContact.postalCode,
          County: pointOfContact.county,
          Country: pointOfContact.country
        },
        LawyerExternalUserID: lawyerExternalUserId,
        SupportingDocuments: (function() {
          if (!supportingDocuments.length) {
            return {};
          }
          return {
            Document: _.map(supportingDocuments, function(supportingDocument) {
              return {
                DocumentID: supportingDocument.fileId
              };
            })
          };
        })()
      }
    }
  });
};
