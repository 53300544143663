/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');

var SideNavSectionView = require('common/views/SideNavSectionView');
var BreadcrumbView = require('common/views/BreadcrumbView');

var DepExtFundDetailsSectionView = require('./DepExtFundDetailsSectionView');

var tmplDepExtFundDashboardView = require('../templates/DepExtFundDashboardView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepExtFundDashboardView,

  className: 'dep-ext-fund-dashboard-view container-fluid',

  ui: {
    fundWrapper: '.fund-wrapper',
    fundActionDropdown: '.dropdown-container',
    breadcrumb: '.breadcrumb-container'
  },

  regions: {
    fundWrapper: '@ui.fundWrapper',
    fundActionDropdown: '@ui.fundActionDropdown',
    breadcrumb: '@ui.breadcrumb'
  },

  onBeforeShow: function() {
    var fund = this.model.get('fund') || {},
      entityId = fund.entityId,
      entityName = fund.entityName,
      fundId = fund.fundId,
      fundName = fund.name;

    this.fund = new SideNavSectionView({
      collection: new Backbone.Collection([
        {
          id: 'fundDetails',
          title: 'Fund Details',
          view: new DepExtFundDetailsSectionView({
            model: new Backbone.Model(this.model.get('fund'))
          })
        },
        {
          id: 'subFunds',
          title: 'Sub-Funds',
          view: Radio.channel('dp').request('build:ext-fund-sub-fund-view', fundId)
        }
      ])
    });
    this.showChildView('fundWrapper', this.fund);

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#ext/dep/dashboard',
            text: 'Home'
          },
          {
            link: '#ext/dep/entity/' + entityId + '/dashboard',
            text: entityName
          },
          {
            text: fundName
          }
        ]
      })
    );
  }
});
