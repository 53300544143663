/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var GridView = require('psa-marionette/views/GridView');
var PageableCollection = require('psa-backbone/models/PageableCollection');

var modelGet = require('common/util/modelGet');
var SelectFieldView = require('common/views/SelectFieldView');
var ButtonLinkCell = require('common/backgrid/ButtonLinkCell');

var tmplTimeTrkNonDepExaminerEntriesView = require('../templates/TimeTrkNonDepExaminerEntriesView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplTimeTrkNonDepExaminerEntriesView,

  className: 'tt-nondep-examiner-entries-view container-fluid',

  ui: {
    searchResultsContainer: '.search-results-container',
    search: '.search',
    yearFilter: 'select[name="periodYear"]',
    periodSelectContainer: '.period-select-container',
    clearFilters: '.clear-filters',
    formInputs: ':input'
  },

  regions: {
    searchResultsContainer: '@ui.searchResultsContainer',
    periodSelectContainer: '@ui.periodSelectContainer'
  },

  events: {
    'change @ui.yearFilter': 'onChangeYear',
    'click @ui.search': 'onClickSearch',
    'keydown @ui.formInputs': 'onKeydownOfInputs'
  },

  onBeforeShow: function() {
    this.collection = new Backbone.Collection(this.model.get('timesheets'), { pageSize: 25 });
    this.shadowCollection = new PageableCollection(this.model.get('timesheets'), { pageSize: 25 });

    this.searchResults = new GridView({
      columns: [
        {
          name: 'examinerName',
          label: 'Examiner Name',
          cell: 'string',
          headerCell: 'custom',
          width: 34,
          editable: false
        },
        {
          name: 'period',
          label: 'Period',
          cell: 'string',
          headerCell: 'custom',
          width: 34,
          editable: false
        },
        {
          name: 'status',
          label: 'Status',
          cell: 'string',
          headerCell: 'custom',
          width: 20,
          editable: false
        },
        {
          name: '',
          label: '',
          cell: ButtonLinkCell.extend({
            name: 'Open',
            newTab: true,
            buttonClass: 'btn-primary btn-sm',
            href: function(model) {
              var timesheetId = model.get('id');
              return '#nondep/time-tracking/review/' + timesheetId;
            }
          }),
          headerCell: 'custom',
          width: 12,
          sortable: false,
          editable: false
        }
      ],
      collection: this.shadowCollection,
      emptyText: 'No Timesheets Found'
    });

    this.periodSelect = new SelectFieldView({
      isDisabled: false,
      isRequired: false,
      name: 'periodId',
      collection: new Backbone.Collection(this.getPeriodsForCurrentYear())
    });

    this.showChildView('periodSelectContainer', this.periodSelect);

    this.periodSelect.setValue(modelGet(this.model, 'timesheetPeriods[0].id'));

    this.applyFilters();

    this.showChildView('searchResultsContainer', this.searchResults);

    this.$('.backgrid.table').addClass('table-bordered');
  },

  onChangeYear: function(e) {
    e.preventDefault();

    this.periodSelect.collection.reset(this.getPeriodsForCurrentYear());
  },

  onKeydownOfInputs: function(e) {
    // Searches on press of enter
    if (e.keyCode === 13) {
      this.onClickSearch(e);
    }
  },

  onClickSearch: function(e) {
    e.preventDefault();
    this.applyFilters();
  },

  applyFilters: function() {
    var self = this;
    var filter = Syphon.serialize(this);
    filter = _.pick(filter, _.identity);
    this.shadowCollection.fullCollection.reset(this.collection.models);

    this.shadowCollection.fullCollection.reset(
      _.reduce(
        filter,
        function(collection, filterVal, filterType) {
          return _.filter(collection, function(model) {
            return self.filter(model, filterType, filterVal);
          });
        },
        this.collection.models
      )
    );
  },

  filter: function(shadow, filterType, filterVal) {
    return shadow.attributes[filterType] === filterVal;
  },

  getPeriodsForCurrentYear: function() {
    var currentYear = this.ui.yearFilter.val();

    return _.chain(this.model.get('timesheetPeriods'))
      .filter(function(period) {
        return period.year === currentYear;
      })
      .map(function(period) {
        return {
          value: period.id,
          text: period.payPeriodDisplay
        };
      })
      .value();
  }
});
