/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');

var BreadcrumbView = require('common/views/BreadcrumbView');
var BigTabsView = require('common/views/BigTabsView');
var modelGet = require('common/util/modelGet');

var ContentServerLinkView = require('common/views/ContentServerLinkView');
var ExamHeaderBarView = require('../../common/views/ExamHeaderBarView');

require('../styles/ExamResultsReviewView.css');
var tmplExamResultsReviewView = require('../templates/ExamResultsReviewView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplExamResultsReviewView,

  className: 'exam-results-review-view container-fluid',

  ui: {
    headerBar: '.header-bar',
    csLinkContainer: '.cs-link-container',
    breadcrumb: '.breadcrumb-container',
    taskSections: '.task-sections'
  },

  regions: {
    headerBar: '@ui.headerBar',
    csLinkContainer: '@ui.csLinkContainer',
    breadcrumb: '@ui.breadcrumb',
    taskSections: '@ui.taskSections'
  },

  behaviors: {
    TaskBehavior: {}
  },

  onBeforeShow: function() {
    var exam = this.model.get('exam') || {};
    this.showChildView(
      'headerBar',
      new ExamHeaderBarView({
        model: new Backbone.Model(this.model.get('exam'))
      })
    );

    this.showChildView(
      'csLinkContainer',
      new ContentServerLinkView({
        model: new Backbone.Model({
          buttonText: 'Open Exam Folder',
          contentServerId: exam.CSFolderId
        })
      })
    );

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#nondep/dashboard',
            text: 'Home'
          },
          {
            text: modelGet(this.model, 'task.taskType.description')
          }
        ]
      })
    );

    var taskSections = [
      {
        id: 'work',
        title: 'Work',
        subtitle: 'Review this exam',
        faIconClass: 'fa-pencil',
        view: this.options.workTabView
      }
    ];

    // If exam has exam materials, add a tab for it
    if (this.model.get('exam').examMaterialsCode) {
      var hasLoanLog = this.model.get('exam').loanLogCode;
      taskSections.unshift({
        id: 'examMaterials',
        title: hasLoanLog ? 'Policies/Procedures' : 'Exam Materials',
        subtitle: 'Entity exam responses',
        faIconClass: 'fa-folder-open-o',
        view: this.options.examMaterialsQuestionView
      });
    }

    // If exam has loan logs, add a tab for it
    if (this.model.get('exam').loanLogCode) {
      taskSections.unshift({
        id: 'loanLogs',
        title: 'Loan Logs',
        subtitle: 'Entity exam responses',
        faIconClass: 'fa-list',
        view: this.options.loanLogQuestionsView
      });
    }

    this.showChildView(
      'taskSections',
      new BigTabsView({
        collection: new Backbone.Collection(taskSections)
      })
    );
  }
});
