/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(organizationId, organizationLicenseId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetOrganizationLicenseFinancialsByOrganizationAndLicense: {
        $xmlns: 'http://dobs.pa.gov/sc/organizationlicense/bpm/1.0',
        OrganizationID: organizationId,
        OrganizationLicenseID: organizationLicenseId
      }
    },
    blueprintArrays: [
      'GetOrganizationLicenseFinancialsByOrganizationAndLicenseResponse.SC_OrganizationLicenseFinancials'
    ],
    blueprint: {
      financials: [
        '$..SC_OrganizationLicenseFinancials',
        {
          id: '$..ID',
          organizationId: '$..OrganizationID',
          organizationLicenseId: '$..OrganizationLicenseID',
          financialYear: '$..FinancialYear',
          fiscalYearEnd: '$..FiscalYearEnd',
          isFinancialReportReq: '$..IsFinancialReportReq',
          FSDueDate: '$..FSDueDate',
          FSFiledDate: '$..FSFiledDate',
          FSAssignedTo: '$..FSAssignedTo',
          FSClearedDate: '$..FSClearedDate',
          FSClearedBy: '$..FSClearedBy',
          FSAssignedToName: '$..FSAssignedToName',
          FSClearedByName: '$..FSClearedByName'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      _.each(response.financials, function(financial) {
        financial.financialYear = financial.financialYear || '--';
        financial.fiscalYearEnd = financial.fiscalYearEnd || '--';
        financial.FSAssignedToName = financial.FSAssignedToName || '--';
        financial.FSClearedByName = financial.FSClearedByName || '--';
        if (financial.isFinancialReportReq) {
          financial.isFinancialReportReq = financial.isFinancialReportReq === '1' ? 'Yes' : 'No';
        } else {
          financial.isFinancialReportReq = '--';
        }
        if (financial.FSDueDate) {
          financial.FSDueDate = moment(financial.FSDueDate, 'YYYY-MM-DD');
        }
        if (financial.FSFiledDate) {
          financial.FSFiledDate = moment(financial.FSFiledDate, 'YYYY-MM-DD');
        }
        if (financial.FSClearedDate) {
          financial.FSClearedDate = moment(financial.FSClearedDate, 'YYYY-MM-DD');
        }
      });
      deferred.resolve(response.financials);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
