/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Ladda = require('ladda');
var Syphon = require('backbone.syphon');
var Radio = require('backbone.radio');

var momentUtil = require('common/util/momentUtil');

var BreadcrumbView = require('common/views/BreadcrumbView');
var DepAuditFormView = require('./DepAuditFormView');

var tmplDepAuditEditView = require('../templates/DepAuditEditView.hbs');
var SubmitTrustFundAudit = require('../services/SubmitTrustFundAudit');
var SubmitTrustSubFundAudit = require('../services/SubmitTrustSubFundAudit');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepAuditEditView,

  className: 'dep-edit-audit-form-view container-fluid',

  ui: {
    breadcrumb: '.breadcrumb-container',
    auditFormContainer: '.edit-audit-form-container',
    submit: '.btn-submit'
  },

  regions: {
    breadcrumb: '@ui.breadcrumb',
    auditFormContainer: '@ui.auditFormContainer'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeShow: function() {
    var audit = this.model.get('audit') || {},
      entityId = audit.entityId,
      entityName = audit.entityName,
      fundId = audit.fundId,
      fundName = audit.fundName,
      subFundId = audit.subFundId,
      subFundName = audit.subFundName;

    var breadcrumbPath = [
      {
        link: '#dep/dashboard',
        text: 'Home'
      },
      {
        link: '#dep/entity/' + entityId + '/dashboard',
        text: entityName
      },
      {
        link: '#dep/entity/' + entityId + '/fund/' + fundId + '/dashboard',
        text: fundName
      },
      {
        link:
          '#dep/entity/' +
          entityId +
          '/fund/' +
          fundId +
          (subFundId ? '/sub-fund/' + subFundId : '') +
          '/audit/' +
          audit.id +
          '/dashboard',
        text: audit.auditDate
      },
      {
        text: 'Edit Audit'
      }
    ];

    if (subFundId) {
      breadcrumbPath.splice(3, 0, {
        link: '#dep/entity/' + entityId + '/fund/' + fundId + '/sub-fund/' + subFundId + '/dashboard',
        text: subFundName
      });
    }

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: breadcrumbPath
      })
    );

    this.auditForm = new DepAuditFormView({
      model: this.model
    });

    this.showChildView('auditFormContainer', this.auditForm);
  },

  onClickSubmit: function(e) {
    e.preventDefault();

    var entityId = this.model.get('entityId'),
      fundId = this.model.get('fundId'),
      subFundId = this.model.get('subFundId'),
      auditId = this.model.get('auditId');

    var laddaContext = Ladda.create(e.currentTarget);
    this.auditForm.validate().done(
      function() {
        laddaContext.start();

        var formData = Syphon.serialize(this);

        formData.id = auditId;
        formData.entityId = entityId;
        formData.fundId = fundId;

        formData.auditDate = momentUtil.formatStr(formData.auditDate, momentUtil.defaultOutboundDateConfig);
        formData.receivedDate = momentUtil.formatStr(formData.receivedDate, momentUtil.defaultOutboundDateConfig);

        var service;

        if (subFundId) {
          service = SubmitTrustSubFundAudit;
        } else {
          service = SubmitTrustFundAudit;
        }

        service(formData)
          .done(function(auditId) {
            if (subFundId) {
              Radio.channel('navigate').trigger('show:int:dp:sub-fund:audit', entityId, fundId, subFundId, auditId);
            } else {
              Radio.channel('navigate').trigger('show:int:dp:fund:audit', entityId, fundId, auditId);
            }
          })
          .fail(function(err) {
            laddaContext.stop();
          });
      }.bind(this)
    );
  }
});
