/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');
var Ladda = require('ladda');
var Radio = require('backbone.radio');

var BreadcrumbView = require('common/views/BreadcrumbView');

var CorpFinOfferingFormView = require('../../common/views/CorpFinOfferingFormView');
var CorpFinCorrespondentFormView = require('../../common/views/CorpFinCorrespondentFormView');
var SubmitRecord = require('../../common/services/SubmitRecord');
var CorpFinRegRegistrantFormView = require('./CorpFinRegRegistrantFormView');

var tmplCorpFinRegistrationNewView = require('../templates/CorpFinRegistrationNewView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplCorpFinRegistrationNewView,

  className: 'corp-fin-registration-new-view container-fluid',

  ui: {
    registrantForm: '.reg-form-container',
    offeringForm: '.offering-form-container',
    correspondentForm: '.correspondent-form-container',
    breadcrumb: '.breadcrumb-container',
    submit: '.submit'
  },

  regions: {
    registrantForm: '@ui.registrantForm',
    offeringForm: '@ui.offeringForm',
    correspondentForm: '@ui.correspondentForm',
    breadcrumb: '@ui.breadcrumb'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  childEvents: {
    'fileType:changed': 'onChangeFileType'
  },

  onBeforeShow: function() {
    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#corpfin/dashboard',
            text: 'Home'
          },
          {
            text: 'New Registration'
          }
        ]
      })
    );

    this.registrantForm = new CorpFinRegRegistrantFormView({
      model: this.model,
      isNewRegistration: true
    });

    this.offeringForm = new CorpFinOfferingFormView({
      model: this.model,
      isRegistration: true,
      isNewRegistration: true
    });

    this.correspondentForm = new CorpFinCorrespondentFormView({
      model: this.model
    });

    this.showChildView('registrantForm', this.registrantForm);
    this.showChildView('offeringForm', this.offeringForm);
    this.showChildView('correspondentForm', this.correspondentForm);
  },

  onChangeFileType: function(childView, fileTypeId) {
    this.offeringForm.onChangeFileType(fileTypeId);
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    this.validate().done(
      function() {
        var laddaContext = Ladda.create(this.ui.submit.get(0));
        laddaContext.start();
        var formData = this.getFormData();
        SubmitRecord(formData)
          .done(function(newRecordId) {
            Radio.channel('navigate').trigger('show:int:cf:reg-dashboard', newRecordId);
          })
          .fail(function() {
            laddaContext.stop();
          });
      }.bind(this)
    );
  },

  validate: function() {
    var validationArray = [
      this.ui.registrantForm.parsley().whenValidate(),
      this.ui.offeringForm.parsley().whenValidate(),
      this.ui.correspondentForm.parsley().whenValidate()
    ];

    return $.when.apply($, validationArray);
  },

  getFormData: function() {
    var record = this.model.get('record') || {};

    var recordDetails = {
      reecordId: this.model.get('recordId'),
      currentRecordName: record.recordName,
      recordCategoryId: '1' //registration
    };

    var formData = _.extend(
      recordDetails,
      this.registrantForm.getFormData(),
      this.offeringForm.getFormData(),
      this.correspondentForm.getFormData()
    );

    return formData;
  }
});
