/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

var cleanUpResponse = require('common/util/cleanUpResponse');
var momentUtil = require('common/util/momentUtil');

module.exports = function(officerId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetEntityOfficer: {
        $xmlns: 'http://dobs.pa.gov/dp/officer/bpm/1.0',
        OfficerID: officerId
      }
    },
    blueprint: {
      officerId: '$..ID',
      entityId: '$..EntityID',
      officerTypeId: '$..OfficerTypeID',
      officerType: '$..Type',
      firstName: '$..FirstName',
      lastName: '$..LastName',
      middleInitial: '$..MiddleInitial',
      title: '$..Title',
      statusId: '$..StatusID',
      status: '$..Status',
      termLength: '$..TermLength',
      termStartDate: '$..TermStartDate',
      termEndDate: '$..TermEndDate',
      isMainContact: '$..IsMainContact',
      comment: '$..Comment',
      isDeleted: '$..IsDeleted'
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      var indboundDateConfig = _.extend({}, momentUtil.defaultInboundDateConfig, { default: '' });

      response.termStartDate = momentUtil.formatStr(response.termStartDate, indboundDateConfig);
      response.termEndDate = momentUtil.formatStr(response.termEndDate, indboundDateConfig);

      if (response.isMainContact === '0') {
        response.isMainContactDisplay = 'No';
      } else if (response.isMainContact === '1') {
        response.isMainContactDisplay = 'Yes';
      }

      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
