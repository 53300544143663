/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var get = require('lodash.get');
var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');
var isNonDepAmendmentTask = require('../../common/util/isNonDepAmendmentTask');

module.exports = function(entityID) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetExternalTasksByEntity: {
        $xmlns: 'http://dobs.pa.gov/nd/task/bpm/1.0',
        EntityID: entityID
      }
    },
    blueprintArrays: [
      'GetExternalTasksByEntityResponse.Tasks.NOTF_TASK_INSTANCE',
      'GetExternalTasksByEntityResponse.TaskTypes.CM_LU_TaskType',
      'GetExternalTasksByEntityResponse.Applications.ND_Application',
      'GetExternalTasksByEntityResponse.Exams.ND_Exam',
      'GetExternalTasksByEntityResponse.Compliances.ND_Compliance',
      'GetExternalTasksByEntityResponse.Correspondences.ND_Correspondence'
    ],
    blueprint: {
      tasks: [
        '$..NOTF_TASK_INSTANCE',
        {
          taskId: '$..TaskId',
          deliveryDate: '$..DeliveryDate',
          applicationId: '$..ApplicationID',
          examId: '$..ExamID',
          complianceId: '$..ComplianceID',
          typeName: '$..TaskTypeName',
          dueDate: '$..DueDate',
          correspondenceId: '$..CorrespondenceID'
        }
      ],
      taskMetaData: {
        applications: [
          '$..ND_Application',
          {
            id: '$..ApplicationID',
            applicationCategory: '$..ApplicationCategory',
            applicationType: '$..ApplicationType',
            entityName: '$..EntityName',
            licenseType: '$..LicenseType',
            licenseNumber: '$..LicenseNumber',
            address: {
              addressLine1: '$..AddressLine1',
              addressLine2: '$..AddressLine2',
              addressLine3: '$..AddressLine3',
              city: '$..City',
              state: '$..State',
              postalCode: '$..PostalCode',
              country: '$..Country'
            }
          }
        ],
        exams: [
          '$..ND_Exam',
          {
            id: '$..ExamID',
            entityName: '$..EntityName',
            licenseType: '$..LicenseType',
            licenseNumber: '$..LicenseNumber',
            address: {
              addressLine1: '$..AddressLine1',
              addressLine2: '$..AddressLine2',
              addressLine3: '$..AddressLine3',
              city: '$..City',
              state: '$..State',
              postalCode: '$..PostalCode',
              country: '$..Country'
            }
          }
        ],
        compliances: [
          '$..ND_Compliance',
          {
            id: '$..ComplianceID',
            entityName: '$..EntityName',
            licenseType: '$..LicenseType',
            operatingAsLicenseType: '$..OperatingAsLicenseType',
            licenseNumber: '$..LicenseNumber',
            address: {
              addressLine1: '$..AddressLine1',
              addressLine2: '$..AddressLine2',
              addressLine3: '$..AddressLine3',
              city: '$..City',
              state: '$..State',
              postalCode: '$..PostalCode',
              country: '$..Country'
            }
          }
        ],
        correspondences: [
          '$..ND_Correspondence',
          {
            correspondenceId: '$..CorrespondenceID',
            entityName: '$..EntityName',
            licenseType: '$..LicenseType',
            licenseNumber: '$..LicenseNumber',
            licenseId: '$..LicenseID',
            applicationCategory: '$..ApplicationCategory',
            applicationType: '$..ApplicationType',
            address: {
              addressLine1: '$..AddressLine1',
              addressLine2: '$..AddressLine2',
              addressLine3: '$..AddressLine3',
              city: '$..City',
              state: '$..State',
              postalCode: '$..PostalCode',
              country: '$..Country'
            }
          }
        ],
        taskTypes: [
          '$..CM_LU_TaskType',
          {
            name: '$..Name',
            description: '$..Description'
          }
        ]
      }
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      
      _.each(response.tasks, function(task) {
        task.deliveryDate = moment.utc(task.deliveryDate, moment.ISO_8601).local();

        if (!moment(task.dueDate, moment.ISO_8601).isValid()) {
          task.dueDate = task.dueDate || '--';
        } else {
          task.dueDate = moment(task.dueDate, moment.ISO_8601).format('MM/DD/YYYY');
        }

        var type = _.find(response.taskMetaData.taskTypes, function(type) {
          return task.typeName === type.name;
        });

        task.typeName = get(type, 'description');
        if (task.applicationId) {
          var application = _.find(response.taskMetaData.applications, function(application) {
            return task.applicationId === application.id;
          });

          if (application) {
            task.applicationType = application.applicationType;
            task.applicationCategory = application.applicationCategory;
            task.entityName = application.entityName;
            task.licenseType = application.licenseType;
            task.licenseNumber = application.licenseNumber || '--';
            task.location = get(application, 'address.addressLine1', '--') || '--';
            var isAmendmentTask = isNonDepAmendmentTask(type.id);
            if (isAmendmentTask) {
              task.type += ' - ' + application.applicationType;
            }
          }
        } else if (task.examId) {
          /* Handle Exam Meta Data */

          var exam = _.find(response.taskMetaData.exams, function(exam) {
            return task.examId === exam.id;
          });

          if (exam) {
            task.applicationType = '--';
            task.entityName = exam.entityName;
            task.licenseType = exam.licenseType;
            task.licenseNumber = exam.licenseNumber || '--';
            task.location = get(exam, 'address.addressLine1', '--') || '--';
          }
        } else if (task.complianceId) {
          /* Handle Compliance Meta Data */

          var compliance = _.find(response.taskMetaData.compliances, function(compliance) {
            return task.complianceId === compliance.id;
          });

          if (compliance) {
            task.applicationType = '--';
            task.entityName = compliance.entityName;
            task.licenseType = compliance.licenseType || compliance.operatingAsType;
            task.licenseNumber = compliance.licenseNumber || '--';
            task.location = get(compliance, 'address.addressLine1', '--') || '--';
          }
        } else if (task.correspondenceId) {
          var correspondence = _.find(response.taskMetaData.correspondences, function(correspondence) {
            return task.correspondenceId === correspondence.correspondenceId;
          });

          if (correspondence) {
            task.applicationType = correspondence.applicationType || '--';
            task.entityName = correspondence.entityName;
            task.licenseType = correspondence.licenseType;
            task.licenseNumber = correspondence.licenseNumber || '--';
            task.location = get(correspondence, 'address.addressLine1', '--') || '--';
          }
        }
      });

      delete response.taskMetaData;

      deferred.resolve(response.tasks);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
