/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');

var PlaceholderView = require('common/views/PlaceholderView');

var ConSerEntityDisplayView = require('../views/ConSerEntityDisplayView');

var GetEntityByIdAndDepartment = require('../../common/services/GetEntityByIdAndDepartment');

module.exports = {
  channel: 'cs',

  event: 'build:entity-display-view',

  builder: function(entityId, departmentId) {
    return new PlaceholderView({
      viewPromise: buildEntityDisplayView(entityId, departmentId)
    });
  }
};

function buildEntityDisplayView(entityId, departmentId) {
  var deferred = $.Deferred();
  GetEntityByIdAndDepartment(entityId, departmentId)
    .done(function(entity) {
      deferred.resolve(
        new ConSerEntityDisplayView({
          model: new Backbone.Model(entity)
        })
      );
    })
    .fail(function(err) {
      deferred.reject(err);
    });

  return deferred.promise();
}
