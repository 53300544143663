/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');

var AssigneeCell = require('common/backgrid/AssigneeCell');
var MomentCell = require('common/backgrid/MomentCell');
var ButtonLinkCell = require('common/backgrid/ButtonLinkCell');
var GridSpinnerView = require('common/views/GridSpinnerView');

var DepInternalSidebarNavView = require('../../common/views/DepInternalSidebarNavView');

require('../styles/DepInternalAllTasksView.css');
var tmplDepInternalAllTasksView = require('../templates/DepInternalAllTasksView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepInternalAllTasksView,

  className: 'dep-internal-all-tasks-view container-fluid',

  ui: {
    sideBar: '.side-bar-container',
    allTasksContainer: '.all-tasks-container'
  },

  regions: {
    sideBar: '@ui.sideBar',
    allTasksContainer: '@ui.allTasksContainer'
  },

  onBeforeShow: function() {
    this.showChildView('sideBar', new DepInternalSidebarNavView());
    var taskGridColumns = [
      {
        name: 'type',
        label: 'Task Name',
        cell: 'string',
        headerCell: 'custom',
        width: 20,
        editable: false
      },
      {
        name: 'filingType',
        label: 'Type',
        cell: 'string',
        headerCell: 'custom',
        width: 15,
        editable: false
      },
      {
        name: 'entityName',
        label: 'Entity Name',
        cell: 'string',
        headerCell: 'custom',
        width: 15,
        editable: false
      },
      {
        name: 'dueDate',
        label: 'Due Date',
        cell: MomentCell.MomentCell,
        formatter: MomentCell.MomentDateFormatter.extend({
          emptyValue: '--'
        }),
        sortValue: MomentCell.MomentSortValue,
        headerCell: 'custom',
        width: 8,
        editable: false
      },
      {
        name: 'assigneeDetails',
        label: 'Assignee',
        cell: AssigneeCell.AssigneeCell,
        sortValue: AssigneeCell.AssigneeSortValue,
        headerCell: 'custom',
        width: 23,
        editable: false
      },
      {
        name: 'deliveryDate',
        label: 'Assigned On',
        cell: MomentCell.MomentCell,
        formatter: MomentCell.MomentDateTimeFormatter.extend({
          emptyValue: '--'
        }),
        sortValue: MomentCell.MomentSortValue,
        headerCell: 'custom',
        width: 13,
        editable: false
      },
      {
        name: '',
        label: '',
        cell: ButtonLinkCell.extend({
          name: 'Open',
          buttonClass: 'btn-primary btn-sm',
          href: function(model) {
            return '#dep/task/' + model.get('taskId');
          }
        }),
        editable: false,
        sortable: false,
        headerCell: 'custom',
        width: 6
      }
    ];

    this.allTasksView = new GridSpinnerView({
      columns: taskGridColumns,
      spinner: {
        scale: 0.75,
        length: 10
      },
      pageable: true
    });

    this.showChildView('allTasksContainer', this.allTasksView);

    Radio.channel('dp:internal-dashboard')
      .request('build:dep-internal-all-tasks-grid-view', taskGridColumns)
      .done(
        function(gridView) {
          if (!this.isDestroyed) {
            this.allTasksView = gridView;
            this.showChildView('allTasksContainer', gridView);
            gridView.viewGrid.sort('deliveryDate', 'descending');
          }
        }.bind(this)
      );
  }
});
