/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');

var GetBankMergerAcqFiling = require('../services/GetBankMergerAcqFiling');
var GetAllMergerAcqFilingLookups = require('../services/GetAllMergerAcqFilingLookups');
var GetAllFederalReserveBanks = require('../services/GetAllFederalReserveBanks');
var GetEntityIdentifiers = require('../../common/services/GetEntityIdentifiers');
var GetAllEntityLookups = require('../../common/services/GetAllEntityLookups');

var BankAcquisitionMergerFiling = require('../forms/BankAcquisitionMergerFiling');

module.exports = {
  event: 'build:filing:bank-acquisition-merger',

  channel: 'dp:filing',

  builder: function(config) {
    var defer = $.Deferred();
    var currFilingId = config.filingId;
    var bankEntityType = '1';

    $.when(
      GetAllMergerAcqFilingLookups(),
      GetBankMergerAcqFiling(currFilingId),
      GetAllEntityLookups(bankEntityType),
      GetAllFederalReserveBanks()
    )
      .done(function(filingLookups, filingContent, entityLookups, fedReserveBanks) {
        GetEntityIdentifiers(filingContent.entityId).done(function(entityIdentifiers) {
          defer.resolve(
            new BankAcquisitionMergerFiling({
              isReadOnly: config.isReadOnly,
              mode: config.mode || 'ext-new', // Possible values of mode: 'ext-new','resubmit', 'print', 'int-expand'
              model: new Backbone.Model({
                filingId: currFilingId,
                filingContent: filingContent,
                charterTypes: filingLookups.charterTypes,
                stateCharters: filingLookups.stateCharters,
                nameTypes: filingLookups.nameTypes,
                ownershipStructures: filingLookups.ownershipStructures,
                regulators: filingLookups.regulators,
                entityIdentifiers: entityIdentifiers,
                bankTypes: entityLookups.entityTypes,
                fedReserveBanks: fedReserveBanks
              })
            })
          );
        });
      })
      .fail(function(err) {
        defer.reject(err);
      });

    return defer.promise();
  }
};
