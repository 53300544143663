/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');

var GetCreditUnionSEGRequestFiling = require('../services/GetCreditUnionSEGRequestFiling');
var CreditUnionSEGRequestFiling = require('../forms/CreditUnionSEGRequestFiling');
var GetAllSEGLookups = require('../../seg-dashboard/services/GetAllSEGLookups');

module.exports = {
  event: 'build:filing:seg-request',

  channel: 'dp:filing',

  builder: function(config) {
    var defer = $.Deferred();
    var currFilingId = config.filingId;
    $.when(GetCreditUnionSEGRequestFiling(currFilingId), GetAllSEGLookups())
      .done(function(filingContent, lookups) {
        var creditUnionSEGRequestFiling = new CreditUnionSEGRequestFiling({
          isReadOnly: config.isReadOnly,
          // Possible values of mode: 'ext-new', 'int-new', 'resubmit', 'int-expand'
          mode: config.mode || 'ext-new',
          model: new Backbone.Model({
            filingId: currFilingId,
            filingContent: filingContent,
            individualTypes: lookups.individualTypes,
            entityTypes:lookups.entityTypes
          })
        });
        defer.resolve(creditUnionSEGRequestFiling);
      })
      .fail(function(err) {
        defer.reject(err);
      });

    return defer.promise();
  }
};
