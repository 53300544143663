/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var NProgress = require('nprogress');

var NavUtils = require('psa-core/util/nav');

var Scaffold = require('common/layout/Scaffold');
var GetUsersForRole = require('common/services/GetUsersForRole');
var GetDocumentTypesByDepartment = require('common/services/GetDocumentTypesByDepartment');

var GetAllExamInvInqLookups = require('../../common/services/GetAllExamInvInqLookups');
var GetAllExamLookups = require('../../common/services/GetAllExamLookups');
var GetOrganizationLicenseIdentifiers = require('../../organization-license-dashboard/services/GetOrganizationLicenseIdentifiers');
var SecExamNewView = require('../views/SecExamNewView');

module.exports = function(organizationId, licenseId) {
  NProgress.start();
  NProgress.set(0.6);
  $.when(
    GetOrganizationLicenseIdentifiers(licenseId),
    GetAllExamLookups(),
    GetAllExamInvInqLookups(),
    GetUsersForRole('SC - Examiner'),
    GetDocumentTypesByDepartment('SECURITIES')
  )
    .done(function(identifiers, lookups, examInvInqLookups, examiners, documentTypes) {
      NavUtils.navigate('#sec/organization/' + organizationId + '/license/' + licenseId + '/new-exam');
      Scaffold.content.show(
        new SecExamNewView({
          model: new Backbone.Model({
            licenseId: licenseId,
            organizationId: organizationId,
            identifiers: identifiers,
            examTypes: lookups.examTypes,
            examSubtypes: lookups.examSubtypes,
            examSources: lookups.examSources,
            examCategories: lookups.examCategories,
            examConductedLocations: lookups.examConductedLocations,
            examInvInqOffices: examInvInqLookups.examInvInqOffices,
            examiners: examiners,
            documentTypes: documentTypes
          })
        })
      );
      NProgress.done();
    })
    .fail(function() {
      NProgress.done();
    });
};
