/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');

var UIUtil = require('psa-core/util/ui');

var modelGet = require('common/util/modelGet');
var ContentServerLinkView = require('common/views/ContentServerLinkView');
var SideNavSectionView = require('common/views/SideNavSectionView');
var BreadcrumbView = require('common/views/BreadcrumbView');
var DropdownView = require('common/views/DropdownView');
var HeaderBarView = require('common/views/HeaderBarView');
var ConfirmationModalView = require('common/views/ConfirmationModalView');

var SubmitOrganizationLicenseFinancials = require('../services/SubmitOrganizationLicenseFinancials');
var SecOrganizationLicenseFinancialsDetailsSectionView = require('./SecOrganizationLicenseFinancialsDetailsSectionView');

var tmplSecOrganizationLicenseFinancialsDashboardView = require('../templates/SecOrganizationLicenseFinancialsDashboardView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplSecOrganizationLicenseFinancialsDashboardView,

  className: 'sec-organization-license-financials-dashboard-view container-fluid m-b-lg',

  ui: {
    organizationLicenseFinancialsWrapper: '.organization-license-financials-wrapper',
    csLinkContainer: '.cs-link-container',
    organizationActionDropdown: '.dropdown-container',
    breadcrumb: '.breadcrumb-container',
    headerBar: '.header-bar-container'
  },

  regions: {
    organizationLicenseFinancialsWrapper: '@ui.organizationLicenseFinancialsWrapper',
    csLinkContainer: '@ui.csLinkContainer',
    organizationActionDropdown: '@ui.organizationActionDropdown',
    breadcrumb: '@ui.breadcrumb',
    headerBar: '@ui.headerBar'
  },

  onBeforeShow: function() {
    var identifiers = this.model.get('identifiers') || {};
    var financials = this.model.get('financials') || {};
    var licenseId = this.model.get('licenseId') || {};
    var organizationId = modelGet(this.model, 'organizationId');
    var financialsId = financials.id;

    identifiers.financialYear = financials.financialYear || '--';
    identifiers.fiscalYearEnd = financials.fiscalYearEnd || '--';

    identifiers.title = 'Financials';
    var headerBarColumns = [
      {
        name: 'CRDNumber',
        label: 'CRD Number',
        minWidth: '160px',
        maxWidth: '200px'
      },
      {
        name: 'financialYear',
        label: 'Financial Year'
      },
      {
        name: 'fiscalYearEnd',
        label: 'Fiscal Year End'
      }
    ];

    this.showChildView(
      'headerBar',
      new HeaderBarView({
        columns: new Backbone.Collection(headerBarColumns),
        model: new Backbone.Model(identifiers)
      })
    );

    this.showChildView(
      'csLinkContainer',
      new ContentServerLinkView({
        model: new Backbone.Model({
          buttonText: 'Open Financials Folder',
          contentServerId: financials.CSFolderId
        })
      })
    );

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#sec/dashboard',
            text: 'Home'
          },
          {
            link: '#sec/organization/' + organizationId + '/dashboard',
            text: identifiers.applicantLegalName
          },
          {
            link: '#sec/organization/' + organizationId + '/license/' + licenseId + '/dashboard',
            text: identifiers.type
          },
          {
            text: 'Financials'
          }
        ]
      })
    );

    var dropDownOptions = [
      {
        text: 'Edit Financial Details',
        link: '#sec/organization/' + organizationId + '/license/' + licenseId + '/financials/' + financialsId + '/edit'
      },
      {
        isDivider: true
      },
      {
        text: 'Delete Financial Details',
        onClick: this.onClickDeleteFinancials.bind(this)
      }
    ];

    this.showChildView(
      'organizationActionDropdown',
      new DropdownView({
        rightAlign: true,
        model: new Backbone.Model({
          name: 'Financials Actions',
          options: dropDownOptions
        })
      })
    );

    var sideNavTabs = [
      {
        id: 'financial',
        title: 'Financials',
        view: new SecOrganizationLicenseFinancialsDetailsSectionView({
          model: new Backbone.Model(financials)
        })
      },
      {
        id: 'notes',
        title: 'Notes',
        view: Radio.channel('sc').request('build:notes-view', {
          objectId: financialsId,
          objectTypeId: 'FINANCIALS'
        })
      },
      {
        id: 'auditLog',
        title: 'Audit Log',
        view: Radio.channel('sc').request('build:audit-log-view', {
          objectId: financialsId,
          objectTypeId: 'FINANCIALS'
        })
      }
    ];

    this.organization = new SideNavSectionView({
      collection: new Backbone.Collection(sideNavTabs)
    });

    this.showChildView('organizationLicenseFinancialsWrapper', this.organization);
  },

  onClickDeleteFinancials: function() {
    var self = this;
    var message = 'Are you sure you want to delete these financials?';
    UIUtil.showModal({
      title: 'Confirm Deleting Financials',
      view: new ConfirmationModalView({
        confirmText: 'Delete Financials',
        model: new Backbone.Model({
          message: message,
          onConfirm: function() {
            var financialsId = modelGet(self.model, 'financials.id');
            return SubmitOrganizationLicenseFinancials({ financialsId: financialsId, isDeleted: '1' });
          },
          onConfirmDone: function() {
            var organizationId = self.model.get('organizationId');
            var licenseId = self.model.get('licenseId');
            Radio.channel('navigate').trigger('show:int:sc:organization:license', organizationId, licenseId);
          }
        })
      })
    });
  }
});
