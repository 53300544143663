/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Backbone = require('backbone');
var Marionette = require('backbone.marionette');

var modelGet = require('common/util/modelGet');
var BreadcrumbView = require('common/views/BreadcrumbView');
var NMLSLabelView = require('../../common/views/NMLSLabelView');

var ContentServerLinkView = require('common/views/ContentServerLinkView');

var NonDepIndividualGeneralInformationSectionView = require('../../individual-dashboard/views/NonDepIndividualGeneralInformationSectionView');

var tmplNonDepIndividualHistoryView = require('../templates/NonDepIndividualHistoryView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepIndividualHistoryView,

  className: 'non-dep-individual-history-view container-fluid',

  ui: {
    nmlsLabelContainer: '.nmls-label-container',
    csLinkContainer: '.cs-link-container',
    individualHistory: '.individual-history',
    breadcrumb: '.breadcrumb-container'
  },

  regions: {
    nmlsLabelContainer: '@ui.nmlsLabelContainer',
    csLinkContainer: '@ui.csLinkContainer',
    individualActionDropdown: '@ui.individualActionDropdown',
    individualHistory: '@ui.individualHistory',
    breadcrumb: '@ui.breadcrumb'
  },

  onBeforeShow: function() {
    var self = this,
      individual = this.model.get('individual'),
      individualType = individual.individualType,
      individualId = this.model.get('individualId'),
      entityId = this.model.get('entityId'),
      entityName = this.model.get('entityName'),
      licenseId = this.model.get('licenseId'),
      licenseNumber = this.model.get('licenseNumber'),
      licenseType = this.model.get('licenseType'),
      breadcrumbs;

    if (individualType === 'Entity Officer') {
      breadcrumbs = [
        {
          link: '#nondep/dashboard',
          text: 'Home'
        },
        {
          text: entityName,
          link: '#nondep/entity/' + entityId + '/dashboard'
        },
        {
          text: individualType + ' - ' + individual.firstName + ' ' + individual.lastName,
          link: '#nondep/individual/' + individualId + '/dashboard'
        },
        {
          text: 'History',
          link: '#nondep/individual/' + individualId + '/history'
        },
        {
          text: 'Individual Snapshot'
        }
      ];
    } else if (individualType === 'Office Manager') {
      breadcrumbs = [
        {
          link: '#nondep/dashboard',
          text: 'Home'
        },
        {
          text: entityName,
          link: '#nondep/entity/' + entityId + '/dashboard'
        },
        {
          text: 'License #' + licenseNumber + ' - ' + licenseType,
          link: '#nondep/entity/' + entityId + '/license/' + licenseId
        },
        {
          text: individualType + ' - ' + individual.firstName + ' ' + individual.lastName,
          link: '#nondep/individual/' + individualId + '/dashboard'
        },
        {
          text: 'History',
          link: '#nondep/individual/' + individualId + '/history'
        },
        {
          text: 'Individual Snapshot'
        }
      ];
    } else {
      breadcrumbs = [
        {
          link: '#nondep/dashboard',
          text: 'Home'
        },
        {
          text: individualType + ' - ' + individual.firstName + ' ' + individual.lastName,
          link: '#nondep/individual/' + individualId + '/dashboard'
        },
        {
          text: 'History',
          link: '#nondep/individual/' + individualId + '/history'
        },
        {
          text: 'Individual Snapshot'
        }
      ];
    }

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: breadcrumbs
      })
    );

    if (individual.isNMLSIndividual) {
      this.showChildView(
        'nmlsLabelContainer',
        new NMLSLabelView({
          model: new Backbone.Model({
            objectName: 'individual'
          })
        })
      );
    }
    
    this.showChildView(
      'csLinkContainer',
      new ContentServerLinkView({
        model: new Backbone.Model({
          buttonText: 'Open Individual Folder',
          contentServerId: individual.CSFolderId
        })
      })
    );

    this.individualHistory = new NonDepIndividualGeneralInformationSectionView({
      model: new Backbone.Model(
        _.extend(this.model.get('individualHistory'), {
          entityName: self.model.get('entityName'),
          individualId: self.model.get('individualId'),
          entityId: self.model.get('entityId')
        })
      ),
      isReadOnly: this.options.isReadOnly,
      individualType: modelGet(self.model, 'individual.individualType')
    })

    this.showChildView('individualHistory', this.individualHistory);
  }
});
