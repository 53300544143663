/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var _ = require('underscore');

var modelGet = require('common/util/modelGet');

var AddressView = require('common/views/AddressView');

var DepContactEmailAddressCollectionView = require('./DepContactEmailAddressCollectionView');
var DepContactAddtionalNumberCollectionView = require('./DepContactAdditionalNumberCollectionView');

var tmplDepContactFormView = require('../templates/DepContactFormView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepContactFormView,

  className: 'dep-contact-form-view',

  behaviors: {
    PhoneFormatBehavior: {}
  },

  ui: {
    addressForm: '.address-form-container',
    emailForm: '.email-form-container',
    additionalNumberForm: '.additional-number-form-container',
    contactTypeSelect: '.contact-type-select',
    addAdditionNumber: '.add-addition-number',
    addEmailAddress: '.add-email-address',
    submit: '.submit',
    copyAddressButton: '.copy-address'
  },

  regions: {
    addressForm: '@ui.addressForm',
    emailForm: '@ui.emailForm',
    additionalNumberForm: '@ui.additionalNumberForm'
  },

  events: {
    'click @ui.submit': 'onClickSubmit',
    'click @ui.addAdditionNumber': 'onClickAddAdditionNumber',
    'click @ui.addEmailAddress': 'onClickAddEmailAddress',
    'click @ui.copyAddressButton': 'onClickCopyAddress'
  },

  templateHelpers: function() {
    var categoryId = modelGet(this.model, 'entityIdentifiers.categoryId');
    return {
      isTrust: categoryId === '3'
    };
  },

  onBeforeShow: function() {
    var contact = this.model.get('contact') || {};
    var isTrust = modelGet(this.model, 'entityIdentifiers.categoryId') === '3';

    this.additionalNumberForm = new DepContactAddtionalNumberCollectionView({
      collection: new Backbone.Collection(contact.additionalNumbers),
      isTrust: isTrust
    });

    this.emailForm = new DepContactEmailAddressCollectionView({
      collection: new Backbone.Collection(contact.emailAddresses)
    });
    this.addressForm = new AddressView({
      model: new Backbone.Model(contact),
      horizontal: true,
      isRequired: false,
      startingCountry: contact.country,
      startingPostalCode: contact.postalCode,
      startingCounty: contact.county
    });

    this.showChildView('addressForm', this.addressForm);

    this.ui.contactTypeSelect.select2();
    var contactTypeIds = _.reduce(
      contact.entityContactTypes,
      function(memo, contactType) {
        memo.push(contactType.contactTypeID);
        return memo;
      },
      []
    );

    this.ui.contactTypeSelect.val(contactTypeIds).trigger('change');

    Syphon.deserialize(this, this.model.get('contact'));

    this.showChildView('emailForm', this.emailForm);
    this.showChildView('additionalNumberForm', this.additionalNumberForm);
  },

  onClickAddEmailAddress: function(e) {
    e.preventDefault();
    this.emailForm.collection.add({});
  },

  onClickAddAdditionNumber: function(e) {
    e.preventDefault();
    this.additionalNumberForm.collection.add({});
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);

    formData.emailAddresses = this.emailForm.getFormData();
    formData.additionalNumbers = this.additionalNumberForm.getFormData();

    return formData;
  },

  onClickCopyAddress: function() {
    var ppob = modelGet(this.model, 'entityIdentifiers.ppob', {});

    this.addressForm.setAddress(ppob);
  }
});
