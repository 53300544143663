/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var modelGet = require('common/util/modelGet');
var toggleForm = require('common/util/toggleForm');

require('../styles/ExamPlacementView.css');
var tmplExamPlacementView = require('../templates/ExamPlacementView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplExamPlacementView,

  className: 'exam-placement-view',

  ui: {
    examPlacementSelect: '.exam-placement-select',
    complianceReferralContainer: '.compliance-referral-container',
    complianceViolationsContainer: '.compliance-violations-container',
    complianceViolationSelect: '.compliance-violation-select'
  },

  regions: {
    complianceViolationSelect: '@ui.complianceViolationSelect'
  },

  events: {
    'change @ui.examPlacementSelect': 'onChangeExamPlacement'
  },

  onBeforeShow: function(e) {
    Syphon.deserialize(this, this.model.get('exam'));

    // Set the value of the Compliance Violations field manually because
    // Backbone.syphon cannot deserialize into a multiselect
    this.ui.complianceViolationSelect.select2();
    var complianceViolationIds = modelGet(this.model, 'exam.complianceViolationIds');
    this.ui.complianceViolationSelect.val(complianceViolationIds).trigger('change');

    this.onChangeExamPlacement();
  },

  onChangeExamPlacement: function() {
    var placement = this.ui.examPlacementSelect.val();

    // Show Compliance referral if placement === compliance
    toggleForm(this.ui.complianceReferralContainer, placement === '4');
  }
});
