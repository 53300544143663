var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"row\">\r\n  <div class=\"col-md-6\">\r\n    <div class=\"form-group required\">\r\n      <label class=\"control-label col-md-6\">File Year</label>\r\n      <div class=\"col-md-6\">\r\n        <input type=\"text\" name=\"recordYear\" class=\"form-control\" placeholder=\"YYYY\" data-parsley-pattern=\"^\\d{4}$\" data-parsley-required=\"true\" />\r\n      </div>\r\n    </div>\r\n  </div>\r\n  <div class=\"col-md-6\">\r\n  <div class=\"form-group required\">\r\n    <label class=\"control-label col-md-6\">File Month</label>\r\n    <div class=\"col-md-6\">\r\n      <input type=\"text\" name=\"recordMonth\" class=\"form-control\" placeholder=\"MM\" data-parsley-range=\"[1, 12]\" data-parsley-required=\"true\" />\r\n    </div>\r\n  </div>\r\n  </div>\r\n</div>\r\n<div class=\"form-group required\">\r\n  <label class=\"control-label col-sm-3\">Exemption Type</label>\r\n  <div class=\"col-md-6 col-sm-9\">\r\n    <select name=\"recordTypeId\" class=\"form-control file-type\" data-parsley-required=\"true\">\r\n      <option value=\"\">--</option>\r\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.fileTypes : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </select>\r\n  </div>\r\n</div>\r\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "      <option value=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.type || (depth0 != null ? depth0.type : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"type","hash":{},"data":data}) : helper)))
    + "</option>\r\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "<div class=\"row\">\r\n<label class=\"control-label col-sm-3\">Exemption Type</label>\r\n<div class=\"col-md-6 col-sm-9\">"
    + container.escapeExpression((helpers["default"] || (depth0 && depth0["default"]) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.recordType : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\r\n</div>\r\n";
},"6":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "      <option value=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.status || (depth0 != null ? depth0.status : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"status","hash":{},"data":data}) : helper)))
    + "</option>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isNewExemption : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.isNewExemption : depth0),{"name":"unless","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "<div class=\"form-group required\">\r\n  <label class=\"control-label col-sm-3\">Status</label>\r\n  <div class=\"col-md-6 col-sm-9\">\r\n    <select name=\"statusId\" class=\"form-control\" data-parsley-required=\"true\">\r\n      <option value=\"\">--</option>\r\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.statuses : depth0),{"name":"each","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </select>\r\n  </div>\r\n</div>\r\n<div class=\"form-group required\">\r\n  <label class=\"control-label col-sm-3\">Issuer Name</label>\r\n  <div class=\"col-md-6 col-sm-9\">\r\n    <input type=\"text\" name=\"recordName\" class=\"form-control\" placeholder=\"Issuer Name\" data-parsley-maxlength=\"200\"\r\n      data-parsley-required=\"true\" />\r\n  </div>\r\n</div>\r\n<div class=\"address\"></div>\r\n";
},"useData":true});