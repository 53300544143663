/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');

var UIUtil = require('psa-core/util/ui');

var modelGet = require('common/util/modelGet');
var AddressDisplayView = require('common/views/AddressDisplayView');
var ConfirmationModalView = require('common/views/ConfirmationModalView');
var DropdownView = require('common/views/DropdownView');
var BreadcrumbView = require('common/views/BreadcrumbView');

var SubmitEntityContact = require('../services/SubmitEntityContact');

var tmplDepContactDashboardView = require('../templates/DepContactDashboardView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepContactDashboardView,

  className: 'dep-contact-dashboard-view container-fluid',

  ui: {
    breadcrumb: '.breadcrumb-container',
    contactActionDropDown: '.contact-action-drop-down',
    contactContainer: '.contact-dashboard-container',
    addressContainer: '.address-container'
  },

  regions: {
    breadcrumb: '@ui.breadcrumb',
    contactActionDropDown: '@ui.contactActionDropDown',
    contactContainer: '@ui.contactContainer',
    addressContainer: '@ui.addressContainer'
  },

  onBeforeShow: function() {
    var contact = this.model.get('contact') || {};

    var contactId = contact.contactId;
    var entityId = contact.entityId;
    var entityName = modelGet(this.model, 'entityIdentifiers.entityName');

    this.showChildView(
      'contactActionDropDown',
      new DropdownView({
        rightAlign: true,
        model: new Backbone.Model({
          name: 'Contact Actions',
          options: [
            {
              text: 'Edit Contact',
              link: '#dep/entity/' + entityId + '/contact/' + contactId + '/edit'
            },
            {
              text: 'Delete Contact',
              onClick: this.onClickDelete.bind(this)
            }
          ]
        })
      })
    );

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#dep/dashboard',
            text: 'Home'
          },
          {
            link: '#dep/entity/' + entityId + '/dashboard',
            text: entityName
          },
          {
            text: contact.firstName + ' ' + contact.lastName + ' - ' + contact.entityContactTypesList
          }
        ]
      })
    );

    this.showChildView(
      'addressContainer',
      new AddressDisplayView({ model: new Backbone.Model(this.model.get('contact')) })
    );
  },

  onClickDelete: function() {
    var self = this;
    UIUtil.showModal({
      title: 'Confirm Deleting Contact',
      view: new ConfirmationModalView({
        confirmText: 'Delete Contact',
        model: new Backbone.Model({
          message: 'Are you sure you want to delete this contact?',
          onConfirm: function() {
            return SubmitEntityContact({
              contactId: modelGet(self.model, 'contact.contactId'),
              isDeleted: 1
            });
          },
          onConfirmDone: function() {
            Radio.channel('navigate').trigger('show:int:dp:entity', modelGet(self.model, 'contact.entityId'));
          }
        })
      })
    });
  }
});
