/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Syphon = require('backbone.syphon');
var Backbone = require('backbone');
var Marionette = require('backbone.marionette');

var UIUtil = require('psa-core/util/ui');

var TimeTrkOpenObjectSelectionModalView = require('../../common/views/TimeTrkOpenObjectSelectionModalView');
var TimeTrkHoursFormView = require('../../common/views/TimeTrkHoursFormView');
var TimeTrkNonDepAllExamsSelectionModalView = require('./TimeTrkNonDepAllExamsSelectionModalView');

require('../styles/TimeTrkNonDepEntryRowView.css');
var tmplTimeTrkNonDepEntryRowView = require('../templates/TimeTrkNonDepEntryRowView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplTimeTrkNonDepEntryRowView,

  className: 'time-trk-non-dep-entry-row-view panel panel-default',

  ui: {
    btnObjectSelection: '.btn-object-selection',
    selectedObjectName: '.selected-object-name',
    showClosedCheckbox: '.show-closed-checkbox',
    objectSelection: '.object-selection',
    btnClearObject: '.btn-clear-object',
    hoursContainer: '.hours-container',
    removeEntry: '.remove-entry'
  },

  regions: {
    hoursContainer: '@ui.hoursContainer'
  },

  triggers: {
    'click @ui.removeEntry': 'remove:entry'
  },

  events: {
    'click @ui.btnObjectSelection': 'onClickObjectSelection',
    'click @ui.btnClearObject': 'onClickClearObject'
  },

  childEvents: {
    'update:time-entries': 'onUpdateTimeEntries'
  },

  templateHelpers: function() {
    return {
      childIndex: this.options.childIndex + 1,
      isReadOnly: this.options.isReadOnly,
      categories: this.options.categories
    };
  },

  onBeforeShow: function() {
    Syphon.deserialize(this, this.model.toJSON());

    this.showChildView('hoursContainer', new TimeTrkHoursFormView({ options: this.options, model: this.model }));

    this.objectId = this.model.get('objectId');

    // To properly format object selection fields when RO
    if (this.options.isReadOnly) {
      this.ui.objectSelection.addClass('object-selection-read-only');
    }
  },

  onUpdateTimeEntries: function(childView) {
    this.model.set('timeEntries', childView.model.get('timeEntries'));
    this.triggerMethod('update:hour-totals'); // TimeTrkNonDepExaminerEntryView
  },

  onClickObjectSelection: function(e) {
    e.preventDefault();
    var self = this;

    var isShowClosedChecked = this.ui.showClosedCheckbox.prop('checked');

    var timesheet = this.options.timesheet || {};
    var departmentId = timesheet.departmentId;
    var objects = timesheet.ndOpenExams;
    var assignedToUserId = timesheet.assignedToUserId;
    var licenseTypes = this.options.licenseTypes;

    if (!isShowClosedChecked) {
      UIUtil.showModal({
        title: 'Select Exam',
        dialogClasses: 'modal-dialog-time-trk-object-selection-modal-view',
        view: new TimeTrkOpenObjectSelectionModalView({
          model: new Backbone.Model({
            objectId: this.objectId,
            departmentId: departmentId,
            objects: objects
          })
        })
      }).done(function(object) {
        if (object) {
          self.objectId = object.objectId;
          var objectDisplayName = _.findWhere(objects, { objectId: object.objectId }).objectDisplayName;
          self.ui.selectedObjectName.val(objectDisplayName);
        } else {
          self.objectId = '';
          self.ui.selectedObjectName.val('');
        }
      });
    }

    if (isShowClosedChecked) {
      UIUtil.showModal({
        title: 'Select Exam',
        dialogClasses: 'modal-dialog-time-trk-non-dep-all-exams-selection-modal-view',
        view: new TimeTrkNonDepAllExamsSelectionModalView({
          model: new Backbone.Model({
            objectId: this.objectId,
            departmentId: departmentId,
            objects: objects,
            assignedToUserId: assignedToUserId,
            licenseTypes: licenseTypes
          })
        })
      }).done(function(object) {
        if (object) {
          self.objectId = object.objectId;
          var objectDisplayName = object.objectDisplayName;
          self.ui.selectedObjectName.val(objectDisplayName);
        } else {
          self.objectId = '';
          self.ui.selectedObjectName.val('');
        }
      });
    }
  },

  onClickClearObject: function(e) {
    e.preventDefault();
    this.objectId = '';
    this.ui.selectedObjectName.val('');
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    formData.objectTypeId = 'EXAM';
    formData.objectId = this.objectId;
    return formData;
  }
});
