/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var SelectFieldView = require('common/views/SelectFieldView');
var _ = require('underscore');

var tmplDepExamOtherInformationConcentrationModalView = require('../templates/DepExamOtherInformationConcentrationModalView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepExamOtherInformationConcentrationModalView,

  className: 'exam-concentration-modal-view',

  ui: {
    form: 'form',

    typeSelect: '.type-select',
    subTypeSelectContainer: '.sub-type-select-container',
    submit: '.submit'
  },

  regions: {
    subTypeSelectContainer: '@ui.subTypeSelectContainer'
  },

  events: {
    'click @ui.submit': 'onClickSubmit',
    'change @ui.typeSelect': 'onChangeTypeSelect'
  },

  templateHelpers: function() {
    return {
      lookups: this.options.lookups
    };
  },

  onBeforeShow: function() {
    this.examConcentrationSubTypes = _.map(this.options.lookups.examConcentrationSubTypes, function(subType) {
      return {
        value: subType.id,
        text: subType.subType,
        typeId: subType.typeId
      };
    });

    this.subTypeSelectFieldView = new SelectFieldView({
      isDisabled: false,
      isRequired: false,
      name: 'subTypeId',
      collection: new Backbone.Collection(_.where(this.examConcentrationSubTypes, { typeId: this.model.get('typeId') }))
    });
    this.showChildView('subTypeSelectContainer', this.subTypeSelectFieldView);

    Syphon.deserialize(this, this.model.toJSON());
  },

  onChangeTypeSelect: function(e) {
    e.preventDefault();

    var typeId = this.ui.typeSelect.val();
    this.filterSubTypeOptions(typeId);
  },

  getFormData: function() {
    var formData = Syphon.serialize(this.ui.form);

    formData.typeDisplay = this.ui.typeSelect.find(':selected').text();
    formData.subTypeDisplay = this.ui.subTypeSelectContainer.find(':selected').text();

    formData.commentsDisplay = formData.comments || '--';

    return formData;
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;
    this.ui.form
      .parsley()
      .whenValidate()
      .done(
        function() {
          var formData = this.getFormData();

          self.triggerMethod('modal:close', formData);
        }.bind(this)
      );
  },

  filterSubTypeOptions: function(typeId) {
    this.subTypeSelectFieldView.collection.reset(_.where(this.examConcentrationSubTypes, { typeId: typeId }));
  }
});
