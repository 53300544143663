/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');
var Ladda = require('ladda');
var moment = require('moment');

var GridSpinnerView = require('common/views/GridSpinnerView');
var ButtonLinkCell = require('common/backgrid/ButtonLinkCell');
var ButtonCell = require('common/backgrid/ButtonCell');
var MomentCell = require('common/backgrid/MomentCell');
var LinkCell = require('common/backgrid/LinkCell');

var ClaimTask = require('common/services/ClaimTask');

var SecInternalSidebarNavView = require('./SecInternalSidebarNavView');

require('../styles/SecInternalDashboardView.css');
var tmplSecInternalDashboardView = require('../templates/SecInternalDashboardView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplSecInternalDashboardView,

  className: 'sec-internal-dashboard-view container-fluid',

  ui: {
    sideBarContainer: '.side-bar-container',
    assignedTasks: '.assigned-tasks-container',
    availableTasks: '.available-tasks-container'
  },

  regions: {
    sideBarContainer: '@ui.sideBarContainer',
    assignedTasks: '@ui.assignedTasks',
    availableTasks: '@ui.availableTasks'
  },

  onBeforeShow: function() {
    this.showChildView('sideBarContainer', new SecInternalSidebarNavView());

    var userTasksGridColumns = [
      {
        name: 'type',
        label: 'Task Name',
        cell: 'string',
        headerCell: 'custom',
        width: 18,
        editable: false
      },
      {
        name: 'applicantLegalName',
        label: 'Applicant Legal Name',
        cell: LinkCell.extend({
          emptyText: '--',
          href: function(model) {
            var organizationId = model.get('organizationId');
            var url = organizationId ? '#sec/organization/' + organizationId + '/dashboard' : '';
            return url;
          }
        }),
        headerCell: 'custom',
        width: 18,
        editable: false
      },
      {
        name: 'primaryExaminer',
        label: 'Primary Examiner',
        cell: 'string',
        headerCell: 'custom',
        width: 18,
        editable: false
      },
      {
        name: 'assignedClaimedDate',
        label: 'Assigned/Claimed On',
        cell: MomentCell.MomentCell,
        formatter: MomentCell.MomentDateTimeFormatter.extend({
          emptyValue: '--'
        }),
        sortValue: MomentCell.MomentSortValue,
        headerCell: 'custom',
        width: 36,
        editable: false
      },
      {
        name: '',
        label: '',
        cell: ButtonLinkCell.extend({
          name: 'Open',
          buttonClass: 'btn-primary btn-sm',
          href: function(model) {
            return '#sec/task/' + model.get('taskId');
          }
        }),
        headerCell: 'custom',
        width: 10,
        editable: false,
        sortable: false
      }
    ];

    this.assignedTasksView = new GridSpinnerView({
      columns: userTasksGridColumns,
      spinner: {
        scale: 0.75,
        length: 10
      },
      pageable: true
    });

    this.showChildView('assignedTasks', this.assignedTasksView);

    Radio.channel('sc:internal-dashboard')
      .request('build:assigned-tasks-grid-view', userTasksGridColumns)
      .done(
        function(gridView) {
          if (!this.isDestroyed) {
            this.assignedTasksView = gridView;
            this.showChildView('assignedTasks', gridView);
            gridView.viewGrid.sort('assignedClaimedDate', 'descending');
          }
        }.bind(this)
      );

    var availableTasksGridColumns = [
      {
        name: 'type',
        label: 'Task Name',
        cell: 'string',
        headerCell: 'custom',
        width: 18,
        editable: false
      },
      {
        name: 'applicantLegalName',
        label: 'Applicant Legal Name',
        cell: LinkCell.extend({
          emptyText: '--',
          href: function(model) {
            var organizationId = model.get('organizationId');
            var url = organizationId ? '#sec/organization/' + organizationId + '/dashboard' : '';
            return url;
          }
        }),
        headerCell: 'custom',
        width: 18,
        editable: false
      },
      {
        name: 'primaryExaminer',
        label: 'Primary Examiner',
        cell: 'string',
        headerCell: 'custom',
        width: 18,
        editable: false
      },
      {
        name: 'deliveryDate',
        label: 'Assigned/Claimed On',
        cell: MomentCell.MomentCell,
        formatter: MomentCell.MomentDateTimeFormatter.extend({
          emptyValue: '--'
        }),
        sortValue: MomentCell.MomentSortValue,
        headerCell: 'custom',
        width: 18,
        editable: false
      },
      {
        name: 'assignedRole',
        label: 'Assigned Role',
        cell: 'string',
        headerCell: 'custom',
        width: 18,
        editable: false
      },
      {
        name: 'claim',
        label: '',
        cell: ButtonCell.extend({
          name: 'Start',
          onClick: this.onClickClaimAvailableTask.bind(this),
          buttonAttrs: {
            'data-style': 'zoom-in',
            'data-color': '#EEEEEE',
            'data-spinner-color': '#EEEEEE'
          },
          buttonClass: 'btn-primary btn-sm ladda-button',
          labelClass: 'ladda-label'
        }),
        headerCell: 'custom',
        width: 10,
        editable: false,
        sortable: false
      }
    ];

    this.availableTasksView = new GridSpinnerView({
      columns: availableTasksGridColumns,
      spinner: {
        scale: 0.75,
        length: 10
      },
      pageable: true
    });

    this.showChildView('availableTasks', this.availableTasksView);

    Radio.channel('sc:internal-dashboard')
      .request('build:available-tasks-grid-view', availableTasksGridColumns)
      .done(
        function(gridView) {
          if (!this.isDestroyed) {
            this.availableTasksView = gridView;
            this.showChildView('availableTasks', gridView);
            gridView.viewGrid.sort('deliveryDate', 'descending');
          }
        }.bind(this)
      );
  },

  onClickClaimAvailableTask: function(e, model) {
    e.preventDefault();

    var laddaContext = Ladda.create(e.currentTarget);
    laddaContext.start();

    ClaimTask(model.get('taskId'))
      .done(
        function() {
          this.availableTasksView.collection.remove(model);

          model.set('assignedClaimedDate', moment());

          this.assignedTasksView.collection.add(model);
          this.assignedTasksView.viewGrid.sort('assignedClaimedDate', 'descending');
        }.bind(this)
      )
      .fail(function() {
        laddaContext.stop();
      });
  }
});
