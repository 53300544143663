/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(invInqId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetInvInqIdentifiers: {
        $xmlns: 'http://dobs.pa.gov/sc/investigationinquiry/bpm/1.0',
        InvInqID: invInqId
      }
    },
    blueprint: {
      invInqId: '$..ID',
      objectTypeId: '$..ObjectTypeID',
      isOrganization: '$..IsOrganization',
      objectId: '$..ObjectID',
      objectLicenseId: '$..ObjectLicenseID',
      caseNumber: '$..CaseNumber',
      category: '$..Category',
      organizationCRDNumber: '$..OrganizationCRDNumber',
      dbaName: '$..DBAName',
      applicantLegalName: '$..ApplicantLegalName',
      organizationLicenseType: '$..OrganizationLicenseType',
      individualCRDNumber: '$..IndividualCRDNumber',
      firstName: '$..FirstName',
      middleInitial: '$..MiddleInitial',
      lastName: '$..LastName',
      individualLicenseType: '$..IndividualLicenseType'
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      if (response.isOrganization === '1') {
        response.crdNumber = response.organizationCRDNumber;
        response.licenseType = response.organizationLicenseType;
      } else if (response.isOrganization === '0') {
        response.crdNumber = response.individualCRDNumber;
        response.middleInitial = response.middleInitial || '';
        response.firstName = response.firstName || '';

        response.applicantLegalName = '';
        if (response.firstName !== '') {
          response.applicantLegalName = response.firstName + ' ';
        }
        if (response.middleInitial !== '') {
          response.applicantLegalName += response.middleInitial + ' ';
        }
        response.applicantLegalName += response.lastName;

        response.licenseType = response.individualLicenseType;
      }

      // Format identifiers to be used in compliance breadcrumb view
      // response.objectId = response.invInqId;
      response.orgIndObjectId = response.objectId;
      response.licenseId = response.objectLicenseId;
      response.complianceObjectTypeDisplay = response.category;
      response.examCaseNumber = response.caseNumber;
      response.licenseType =
        response.isOrganization === '1' ? response.organizationLicenseType : response.individualLicenseType;

      // For headerbar title
      response.title = response.category;

      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
