/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');

var EmptySetView = require('common/views/EmptySetView');

var NoteEntryView = require('./NoteEntryView');

module.exports = Marionette.CollectionView.extend({
  className: 'note-entry-collection-view',

  childView: NoteEntryView,

  emptyView: EmptySetView,

  emptyViewOptions: function() {
    return {
      emptyText: 'No Notes Submitted'
    };
  },

  childViewOptions: function(model, index) {
    return {
      childIndex: index,
      managerRole: this.options.managerRole
    };
  }
});
