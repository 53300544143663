/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var NProgress = require('nprogress');

var NavUtils = require('psa-core/util/nav');

var Scaffold = require('common/layout/Scaffold');
var GetUsersForRole = require('common/services/GetUsersForRole');

var GetAllIndLicenseLookups = require('../../common/services/GetAllIndLicenseLookups');
var GetIndividual = require('../../individual-dashboard/services/GetIndividual');
var SecIndividualLicenseNewView = require('../views/SecIndividualLicenseNewView');

module.exports = function(individualId) {
  NProgress.start();
  NProgress.set(0.6);

  $.when(GetIndividual(individualId), GetUsersForRole('SC - Filing Examiner'), GetAllIndLicenseLookups())
    .done(function(identifiers, filingExaminerUsers, lookups) {
      NavUtils.navigate('#sec/individual/' + individualId + '/new-license');
      Scaffold.content.show(
        new SecIndividualLicenseNewView({
          viewMode: 'int',
          model: new Backbone.Model({
            individualId: individualId,
            identifiers: identifiers,
            filingExaminerUsers: filingExaminerUsers,
            indLicenseTypes: lookups.indLicenseTypes,
            indLicenseStatuses: lookups.indLicenseStatuses
          })
        })
      );
      NProgress.done();
    })
    .fail(function(err) {
      NProgress.done();
    });
};
