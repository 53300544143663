/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var _ = require('underscore');

var parsleyUtil = require('common/util/parsleyUtil');
var downloadDocument = require('common/util/downloadDocument');

require('../styles/DepFilingInstructionsSectionView.css');
var tmplDepFilingInstructionsSectionView = require('../templates/DepFilingInstructionsSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepFilingInstructionsSectionView,

  className: 'dep-filing-instructions-section-view',

  ui: {
    instructionsForm: '.instructions-form',
    downloadInstructions: '.download-instructions',
    downloadAdditionalInstructions: '.download-additional-instructions',
    downloadCertification: '.download-certification'
  },

  events: {
    'click @ui.downloadInstructions': 'onClickDownloadInstructions',
    'click @ui.downloadAdditionalInstructions': 'onClickDownloadAdditionalInstructions',
    'click @ui.downloadCertification': 'onClickDownloadCertification'
  },

  templateHelpers: function() {
    return {
      mode: this.options.mode
    };
  },

  onBeforeShow: function() {
    var instructionsDocument = this.model.get('instructionsDocument') || {};
    if (!instructionsDocument.fileId) {
      this.ui.downloadInstructions.hide();
    }
  },

  onClickDownloadCertification: function(e) {
    var certificationTemplateDocument = this.model.get('filingDetails').certificationTemplateDocument;
    e.preventDefault();
    downloadDocument({
      fileId: certificationTemplateDocument.fileId,
      fileName: certificationTemplateDocument.fileName,
      isStatic: true
    });
  },

  onClickDownloadInstructions: function(e) {
    var instructionsDocument = this.model.get('instructionsDocument') || {};
    e.preventDefault();
    downloadDocument({
      fileId: instructionsDocument.fileId,
      fileName: instructionsDocument.fileName,
      isStatic: true
    });
  },

  onClickDownloadAdditionalInstructions: function(e) {
    e.preventDefault();
    var csDocumentId = $(e.target).attr('csDocumentId');
    var additionalInstructionDocuments = this.model.get('additionalInstructionDocuments');
    var selectedDoc = _.find(additionalInstructionDocuments, function(doc) {
      return doc.csDocumentId === csDocumentId;
    });
    downloadDocument({
      csDocumentId: csDocumentId,
      fileName: selectedDoc.fileName,
      isStatic: true
    });
  },

  validate: function(config) {
    this.ui.instructionsForm.parsley().reset();
    if (config.type === 'save') {
      // Turn off required field validation for this form
      parsleyUtil.disableRequiredValidation(this.ui.instructionsForm);
    } else {
      // Turn on required field validation for this form
      parsleyUtil.enableRequiredValidation(this.ui.instructionsForm);
    }
    return this.ui.instructionsForm.parsley().whenValidate();
  },

  getFormData: function() {
    return Syphon.serialize(this);
  }
});
