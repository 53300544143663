/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var moment = require('moment');

module.exports = function(data) {
  data.dueDate = data.dueDate ? moment(data.dueDate, 'MM/DD/YYYY').format('YYYY-MM-DD') : '';

  return $.soap({
    body: {
      SubmitInitialReviewFilingTask: {
        $xmlns: 'http://dobs.pa.gov/dp/task/bpm/1.0',
        TaskID: data.taskId,
        FilingID: data.filingId,
        DecisionID: data.decisionId,
        IsPendingItems: data.isPendingItems,
        WithdrawalLetterDocID: data.withdrawalLetterDocId,
        AcknowledgementLetterDocID: data.acknowledgementLetterDocId,
        AcceptanceLetterDocID: data.acceptanceLetterDocId,
        DecisionDueDate: data.dueDate,
        IsNotifyEntity: data.isNotifyEntity,
        FederalDocuments: (function() {
          if (!data.federalDocuments.length) {
            return {};
          }
          return {
            Document: _.map(data.federalDocuments, function(document) {
              return {
                DocumentID: document.fileId,
                DocumentDesc: document.documentDesc
              };
            })
          };
        })(),
        ReviewerRoles: (function() {
          if (!data.reviewRoles.length) {
            return {};
          } else {
            return {
              ReviewerRoleID: _.map(data.reviewRoles, function(reviewRole) {
                return {
                  ReviewRoleID: reviewRole
                };
              })
            };
          }
        })()
      }
    }
  });
};
