var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "        <option value=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</option>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "\r\n<div class=\"modal-body\">\r\n  <form class=\"search-form\">\r\n    <div class=\"form-group m-b-sm\">\r\n      <label class=\"control-label\">Entity Name</label>\r\n      <input type=\"text\" class=\"form-control entity-name\" name=\"entityName\" placeholder=\"Entity Name\"/>\r\n    </div>\r\n    <div class=\"form-group m-b-sm\">\r\n      <label class=\"control-label\">D/B/A</label>\r\n      <input type=\"text\" class=\"form-control entity-name\" name=\"dba\" placeholder=\"D/B/A\"/>\r\n    </div>\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label\">License Type</label>\r\n      <select class=\"form-control\" name=\"licenseTypeId\">\r\n        <option value=\"\">--</option>\r\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.licenseTypes : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "      </select>\r\n    </div>\r\n    <div class=\"form-group m-b-sm\">\r\n      <div class=\"checkbox\">\r\n        <label class=\"control-label \">\r\n          <input type=\"checkbox\" name=\"includeInactiveLicenses\">\r\n          Include Inactive Licenses\r\n        </label>\r\n      </div>\r\n    </div>\r\n    <div class=\"search-help-block text-danger collapse m-b-sm\">Entity Name or DBA are required.</div>\r\n    <button class=\"btn btn-primary btn-search\">Search</button>\r\n    <span class=\"search-summary\"></span>\r\n  </form>\r\n  <div class=\"search-result-grid-container m-t-md\"></div>\r\n</div>\r\n<div class=\"modal-footer\">\r\n  <button class=\"btn btn-default\" data-dismiss=\"modal\">Cancel</button>\r\n  <button class=\"btn btn-primary btn-submit ladda-button\" data-style=\"expand-right\" type=\"button\">\r\n    <span class=\"ladda-label\">Select</span>\r\n  </button>\r\n</div>";
},"useData":true});