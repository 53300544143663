/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(individualId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetIndividual: {
        $xmlns: 'http://dobs.pa.gov/sc/individual/bpm/1.0',
        IndividualID: individualId
      }
    },
    blueprint: {
      id: '$..ID',
      CSFolderId: '$..CSFolderID',
      lastName: '$..LastName',
      firstName: '$..FirstName',
      middleInitial: '$..MiddleInitial',
      crdNumber: '$..CRDNumber',
      otherIdNumber: '$..OtherIDNumber'
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      response.firstNameDisplay = response.firstName || '--';
      response.lastNameDisplay = response.lastName || '--';
      response.middleInitialDisplay = response.middleInitial || '--';

      response.middleInitial = response.middleInitial || '';
      response.dbaName = response.lastName;
      if (response.firstName) {
        response.dbaName += ', ' + response.firstName;
      }
      if (response.middleInitial) {
        response.dbaName += ' ' + response.middleInitial;
      }

      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject();
    });
  return deferred.promise();
};
