/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');

var SideNavSectionView = require('common/views/SideNavSectionView');
var BreadcrumbView = require('common/views/BreadcrumbView');
var DropdownView = require('common/views/DropdownView');

var ConSerIndividualSectionDetailsView = require('./ConSerIndividualSectionDetailsView');
var tmplConSerIndividualDashboardView = require('../templates/ConSerIndividualDashboardView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplConSerIndividualDashboardView,

  className: 'con-ser-individual-dashboard-view container-fluid',

  ui: {
    headerBar: '.header-bar-container',
    breadcrumb: '.breadcrumb-container',
    individualDashboard: '.individual-dashboard-container',
    individualActionDropDown: '.individual-action-dropdown'
  },

  regions: {
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb',
    individualDashboard: '@ui.individualDashboard',
    individualActionDropDown: '@ui.individualActionDropDown'
  },

  onBeforeShow: function() {
    var individual = this.model.get('individual') || {};
    var individualId = individual.individualId;
    var entityId = individual.entityId;
    var entityName = individual.entityName;

    var breadcrumbs = [
      {
        link: '#conser/dashboard',
        text: 'Home'
      },
      {
        text: individual.firstName + ' ' + individual.lastName
      }
    ];

    if (individual.entityId) {
      breadcrumbs.splice(1, 0, {
        link: '#conser/entity/' + entityId + '/dashboard',
        text: entityName
      });
    }

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: breadcrumbs
      })
    );

    var dropDownOptions = [
      {
        text: 'Edit Individual',
        link: '#conser/individual/' + individualId + '/edit'
      },
      {
        isDivider: true
      },
      {
        text: 'Add Complaint',
        link: '#conser/new-complaint'
      }
    ];

    this.showChildView(
      'individualActionDropDown',
      new DropdownView({
        rightAlign: true,
        model: new Backbone.Model({
          name: 'Individual Actions',
          options: dropDownOptions
        })
      })
    );

    var sideNavTabs = [
      {
        id: 'individualDetails',
        title: 'Individual Details',
        view: new ConSerIndividualSectionDetailsView({ model: new Backbone.Model(individual) })
      },
      {
        id: 'complaints',
        title: 'Complaints',
        view: Radio.channel('cs').request('build:individual-complaints-section-view', individualId)
      }
    ];

    this.dashboard = new SideNavSectionView({
      collection: new Backbone.Collection(sideNavTabs),
      useNavigationButtons: false
    });

    this.showChildView('individualDashboard', this.dashboard);
  }
});
