/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');
var Ladda = require('ladda');

var BreadcrumbView = require('common/views/BreadcrumbView');

var SecInvInqHeaderBarView = require('./SecInvInqHeaderBarView');
var SecInvInqGeneralFormView = require('./SecInvInqGeneralFormView');
var SubmitInvestigationInquiry = require('../services/SubmitInvestigationInquiry');

var tmplSecInvInqGeneralEditView = require('../templates/SecInvInqGeneralEditView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplSecInvInqGeneralEditView,

  className: 'sec-inv-inq-general-edit-view container-fluid',

  ui: {
    headerBar: '.header-bar-container',
    breadcrumb: '.breadcrumb-container',
    invInqFormContainer: '.inv-inq-form-container',
    submit: '.submit'
  },

  regions: {
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb',
    invInqFormContainer: '@ui.invInqFormContainer'
  },

  events: { 'click @ui.submit': 'onClickSubmit' },

  onBeforeShow: function() {
    var invInq = this.model.get('invInq') || {};
    var identifiers = this.model.get('identifiers') || {};
    var invInqId = this.model.get('invInqId');

    var objectId = identifiers.objectId;
    var objectLicenseId = identifiers.objectLicenseId;

    var breadcrumbs = [
      {
        link: '#sec/dashboard',
        text: 'Home'
      }
    ];

    if (invInq.isOrganization === '1') {
      breadcrumbs.push({
        link: '#sec/organization/' + objectId + '/dashboard',
        text: identifiers.applicantLegalName
      });
      if (objectLicenseId) {
        breadcrumbs.push({
          link: '#sec/organization/' + objectId + '/license/' + objectLicenseId + '/dashboard',
          text: identifiers.licenseType
        });
      }
    } else {
      breadcrumbs.push({
        link: '#sec/individual/' + objectId + '/dashboard',
        text: identifiers.applicantLegalName
      });
      if (objectLicenseId) {
        breadcrumbs.push({
          link: '#sec/individual/' + objectId + '/license/' + objectLicenseId + '/dashboard',
          text: identifiers.licenseType
        });
      }
    }

    breadcrumbs = breadcrumbs.concat([
      {
        link: '#sec/investigation-inquiry/' + invInqId + '/dashboard',
        text: identifiers.category + ' (' + identifiers.caseNumber + ')'
      },
      {
        text: 'Edit General'
      }
    ]);

    this.showChildView(
      'headerBar',
      new SecInvInqHeaderBarView({
        model: new Backbone.Model(identifiers),
        isLicenseInvInq: !!identifiers.objectLicenseId
      })
    );

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: breadcrumbs
      })
    );

    this.generalFormView = new SecInvInqGeneralFormView({ model: this.model });
    this.showChildView('invInqFormContainer', this.generalFormView);
  },

  getFormData: function() {
    var formData = this.generalFormView.getFormData();
    formData.invInqId = this.model.get('invInqId');
    return formData;
  },

  validate: function() {
    return this.ui.invInqFormContainer.parsley().whenValidate();
  },

  onClickSubmit: function(event) {
    event.preventDefault();
    var self = this;
    this.validate().done(function() {
      var l = Ladda.create(self.ui.submit[0]);
      l.start();
      var formData = self.getFormData();
      SubmitInvestigationInquiry(formData)
        .done(function() {
          Radio.channel('navigate').trigger('show:int:sc:investigation-inquiry-dashboard', self.model.get('invInqId'));
        })
        .fail(function() {
          l.stop();
        });
    });
  }
});
