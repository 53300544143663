/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var NProgress = require('nprogress');

var NavUtils = require('psa-core/util/nav');

var Scaffold = require('common/layout/Scaffold');

var GetActiveCreditUnions = require('../services/GetActiveCreditUnions');
var GetAllQuarters = require('../services/GetAllQuarters');

var DepBulkUpdateEntityAssetsView = require('../views/DepBulkUpdateEntityAssetsView');

module.exports = function() {
  NProgress.start();
  NProgress.set(0.6);

  $.when(GetActiveCreditUnions(), GetAllQuarters())
    .done(function(entities, quarters) {
      Scaffold.content.show(
        new DepBulkUpdateEntityAssetsView({
          model: new Backbone.Model({
            entities: entities,
            quarters: quarters
          })
        })
      );
      NavUtils.navigate('dep/dashboard/bulk-entity-assets');
      NProgress.done();
    })
    .fail(function() {
      NProgress.done();
    });
};
