/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Ladda = require('ladda');
var _ = require('underscore');

var SideNavSectionView = require('common/views/SideNavSectionView');
var openInNewTab = require('common/util/openInNewTab');
var toggleForm = require('common/util/toggleForm');

var DepFilingInstructionsSectionView = require('../../views/DepFilingInstructionsSectionView');
var DepFilingPointOfContactSectionView = require('../../views/DepFilingPointOfContactSectionView');
var DepNewBankCharterSubmissionSectionView = require('./DepNewBankCharterSubmissionSectionView');
var DepNewBankCharterPubReqsSectionView = require('./DepNewBankCharterPubReqsSectionView');
var DepNewBankCharterFedAppsSectionView = require('./DepNewBankCharterFedAppsSectionView');
var DepNewBankCharterBioFinReportsSectionView = require('./DepNewBankCharterBioFinReportsSectionView');
var DepNewBankCharterArticlesOfIncorpSectionView = require('./DepNewBankCharterArticlesOfIncorpSectionView');
var DepNewBankCharterFeesExpensesSectionView = require('./DepNewBankCharterFeesExpensesSectionView');
var DepNewBankCharterAffidavit1003SectionView = require('./DepNewBankCharterAffidavit1003SectionView');
var DepNewBankCharterQuestionnaireSectionView = require('./DepNewBankCharterQuestionnaireSectionView');
var DepFilingSupportingDocumentsSectionView = require('../../views/DepFilingSupportingDocumentsSectionView');
var DepNewBankCharterCertificationSectionView = require('./DepNewBankCharterCertificationSectionView');

var SubmitNewBankCharterFiling = require('../../services/SubmitNewBankCharterFiling');

require('../../styles/DepFilingPrintView.css');
var tmplDepNewBankCharterFilingView = require('./DepNewBankCharterFilingView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepNewBankCharterFilingView,

  className: 'dep-new-bank-charter-filing-view m-b-md',

  behaviors: {
    ReadOnlyFormBehavior: {}
  },

  ui: {
    filingSections: '.filing-sections'
  },

  regions: {
    filingSections: '@ui.filingSections'
  },

  onBeforeShow: function() {
    var filingDetails = this.model.get('filingDetails') || {};
    var filingContent = this.model.get('filingContent') || {};
    var filingId = this.model.get('filingId');

    var newBankCharterFilingId = filingContent.id;
    var instructionsDocument = filingDetails.instructionsDocument;
    var additionalInstructionDocuments = filingDetails.instructionsDocuments;
    var instructions = filingDetails.instructions;
    var pointOfContact = filingDetails.pointOfContact;
    var mode = this.options.mode;
    var isNewExternal = mode === 'ext-new';
    var isForPrinting = mode === 'print';
    var isExpandInternal = mode === 'int-expand';
    var hasDepositUpload = filingContent.questionnaire.depositInsuranceUploaded;

    var sideNavValues = [
      {
        id: 'pointOfContact',
        title: 'Point of Contact',
        view: new DepFilingPointOfContactSectionView({
          mode: this.options.mode,
          isReadOnly: this.options.isReadOnly,
          hasAddress: true,
          model: new Backbone.Model(pointOfContact)
        })
      },
      {
        id: 'newBankCharterSubmission',
        title: 'General Filing Information',
        view: new DepNewBankCharterSubmissionSectionView({
          mode: this.options.mode,
          isReadOnly: this.options.isReadOnly,
          model: new Backbone.Model({
            bankCharterTypes: this.model.get('bankCharterTypes'),
            federalReserveBanks: this.model.get('federalReserveBanks'),
            filingDetails: filingDetails,
            filingContent: filingContent
          })
        })
      },
      {
        id: 'instructions',
        title: 'I - General Information and Instructions',
        view: new DepFilingInstructionsSectionView({
          mode: this.options.mode,
          isReadOnly: this.options.isReadOnly,
          model: new Backbone.Model({
            instructionsDocument: instructionsDocument,
            additionalInstructionDocuments: additionalInstructionDocuments,
            instructions: instructions
          })
        })
      },
      {
        id: 'publicationRequirements',
        title: 'II - Publication Requirements',
        view: new DepNewBankCharterPubReqsSectionView({
          mode: this.options.mode,
          isReadOnly: this.options.isReadOnly,
          model: new Backbone.Model({ filingContent: filingContent })
        })
      },
      {
        id: 'federalApplications',
        title: 'III - Federal Applications',
        view: new DepNewBankCharterFedAppsSectionView({
          mode: this.options.mode,
          isReadOnly: this.options.isReadOnly,
          model: new Backbone.Model({ filingContent: filingContent })
        })
      },
      {
        id: 'biographicalFinancialReports',
        title: 'IV - Biographical & Financial Reports',
        view: new DepNewBankCharterBioFinReportsSectionView({
          mode: this.options.mode,
          isReadOnly: this.options.isReadOnly,
          model: new Backbone.Model({ filingContent: filingContent })
        })
      },
      {
        id: 'articlesOfIncorporation',
        title: 'V - Articles of Incorporation & Bylaws',
        view: new DepNewBankCharterArticlesOfIncorpSectionView({
          mode: this.options.mode,
          isReadOnly: this.options.isReadOnly,
          model: new Backbone.Model({ filingContent: filingContent })
        })
      },
      {
        id: 'feesAndExpenses',
        title: 'VI - Organizational Fees & Expenses',
        view: new DepNewBankCharterFeesExpensesSectionView({
          mode: this.options.mode,
          isReadOnly: this.options.isReadOnly,
          model: new Backbone.Model({ filingContent: filingContent })
        })
      },
      {
        id: 'affidavit1003',
        title: 'VII - 1003 Affidavit',
        view: new DepNewBankCharterAffidavit1003SectionView({
          mode: this.options.mode,
          isReadOnly: this.options.isReadOnly,
          model: new Backbone.Model({ filingContent: filingContent })
        })
      },
      {
        id: 'questionnaire',
        title: 'VIII - Questionnaire',
        view: new DepNewBankCharterQuestionnaireSectionView({
          mode: this.options.mode,
          isReadOnly: this.options.isReadOnly,
          model: new Backbone.Model({ filingContent: filingContent })
        })
      },
      {
        id: 'supportingDocuments',
        title: 'Other Supporting Documents',
        view: new DepFilingSupportingDocumentsSectionView({
          mode: this.options.mode,
          isReadOnly: this.options.isReadOnly,
          model: new Backbone.Model({
            filingDetails: filingDetails,
            filingContent: filingContent
          })
        })
      },
      {
        id: 'newBankCharterCertification',
        title: 'Certification',
        view: new DepNewBankCharterCertificationSectionView({
          mode: this.options.mode,
          isReadOnly: this.options.isReadOnly,
          model: new Backbone.Model({
            filingId: filingId,
            newBankCharterFilingId: newBankCharterFilingId,
            filingDetails: filingDetails
          })
        })
      }
    ];

    if (this.options.isReadOnly && hasDepositUpload) {
      sideNavValues.splice(9, 1);
    }
    this.form = new SideNavSectionView({
      collection: new Backbone.Collection(sideNavValues),
      useNavigationButtons: !this.options.isReadOnly,
      expanded: isForPrinting || isExpandInternal,
      contentWidth: 'medium',
      buttons: [
        {
          buttonText: 'Save',
          buttonClass: 'btn-default ladda-button',
          buttonIconClass: 'fa-save',
          labelClass: 'ladda-label',
          buttonAttrs: {
            'data-style': 'expand-right'
          },
          onClick: this.onClickSave.bind(this)
        },
        {
          buttonText: 'Save & Print',
          buttonClass: 'btn-default ladda-button',
          buttonIconClass: 'fa-print',
          labelClass: 'ladda-label',
          buttonAttrs: {
            'data-style': 'expand-right'
          },
          onClick: this.onClickPrint.bind(this)
        }
      ]
    });

    if (this.options.isReadOnly) {
      this.form.collection.remove(this.form.collection.get('instructions'));
      this.form.collection.remove(this.form.collection.get('newBankCharterCertification'));
    }

    // If this isn't the new external form, or if the form is in print mode, remove the buttons
    if (!isNewExternal || isForPrinting) {
      this.form.options.buttons = [];
    }

    this.showChildView('filingSections', this.form);
  },

  onAttach: function() {
    if (this.options.mode === 'print') {
      this.switchToPrint();
    }
  },

  switchToPrint: function() {
    var textAreas = $('.input-view');

    _.each(textAreas, function(textArea) {
      var textAreaVal = textArea.value;
      var textAreaName = textArea.name;
      var printElementId = '#'.concat(textAreaName, 'PrintView');
      $(printElementId).text(textAreaVal);
    });
    toggleForm(textAreas, false);
  },

  onClickSave: function(e) {
    var l = Ladda.create(e.currentTarget);
    l.start();

    this.saveForm()
      .done(function() {
        l.stop();
      })
      .fail(function() {
        l.stop();
      });
  },

  onClickPrint: function(e) {
    var filingId = this.model.get('filingId');
    var l = Ladda.create(e.currentTarget);
    l.start();

    this.saveForm()
      .done(function() {
        openInNewTab('#ext/dep/filing/' + filingId + '/print');
        l.stop();
      })
      .fail(function() {
        l.stop();
      });
  },

  saveForm: function() {
    var deferred = $.Deferred();
    var self = this;
    var filingId = this.model.get('filingId');
    var filingContent = this.model.get('filingContent');

    this.form
      .validateAll({ type: 'save' })
      .done(function() {
        var formData = self.form.getFormData();
        formData.filingId = filingId;
        formData.newBankCharterFilingId = filingContent.id;

        SubmitNewBankCharterFiling(formData)
          .done(function() {
            deferred.resolve();
          })
          .fail(function() {
            deferred.reject();
          });
      })
      .fail(function() {
        deferred.reject();
      });
    return deferred.promise();
  }
});
