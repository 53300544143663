/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var moment = require('moment');

module.exports = function(data) {
  var examDate = moment(data.formData.examDate, 'MM/DD/YYYY').format('YYYY-MM-DD');
  var loanLogDueDate = null;
  if (data.formData.loanLogDueDate) {
    loanLogDueDate = moment(data.formData.loanLogDueDate, 'MM/DD/YYYY').format('YYYY-MM-DD');
  }
  var examMaterialsDueDate = null;
  if (data.formData.examMaterialsDueDate) {
    examMaterialsDueDate = moment(data.formData.examMaterialsDueDate, 'MM/DD/YYYY').format('YYYY-MM-DD');
  }

  return $.soap({
    body: {
      SubmitExaminerSetUpExamTask: {
        $xmlns: 'http://dobs.pa.gov/nd/task/bpm/1.0',
        TaskID: data.taskId,
        ExamID: data.examId,
        ExamContactID: data.formData.examContactId,
        ExamDate: examDate,
        IsOffsite: data.formData.isOffsite,
        LoanLogDueDate: loanLogDueDate,
        ExamMaterialsDueDate: examMaterialsDueDate,
        IsFullScope: data.formData.isFullScope,
        IsSetupLetterConsolidated: data.formData.isSetUpLetterConsolidated,
        IsContactLicensee: data.formData.isContactLicensee,
        ExamCoverageStartDate: moment(data.formData.examCoverageStartDate, 'MM/DD/YYYY').format('YYYY-MM-DD'),
        ExamCoverageEndDate: moment(data.formData.examCoverageEndDate, 'MM/DD/YYYY').format('YYYY-MM-DD'),
        SetUpLetter: (function() {
          if (data.formData.isSetUpLetterConsolidated === '1') {
            return {
              SetUpLetterDocumentID: data.consolidatedLetterId
            };
          } else {
            return;
          }
        })()
      }
    }
  });
};
