/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var parsleyUtil = require('common/util/parsleyUtil');

var FileUploadCollection = require('psa-backbone/models/FileUploadCollection');

var FileUploadView = require('common/views/FileUploadView');
var makeUploadCollection = require('common/util/makeUploadCollection');

var tmplCreditServicesLoanBrokerRenewalGeneralDetailsSectionView = require('./CreditServicesLoanBrokerRenewalGeneralDetailsSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplCreditServicesLoanBrokerRenewalGeneralDetailsSectionView,

  className: 'credit-services-loan-broker-renewal-general-details-section-view',

  ui: {
    entitesYouOfferedFileContainer: '.entities-you-offered-file-container',
    servicesOnYourBehalfFileContainer: '.services-on-your-behalf-file-container',
    generalDetailsForm: '.general-details-form',
    legalProceedingsFileContainer: '.legal-proceedings-file-container',
    applicationDocumentsFileContainer: '.application-documents-container',
    hasEntitiesYouOffered: '.hasEntitiesYouOffered'
  },

  regions: {
    entitesYouOfferedFileContainer: '@ui.entitesYouOfferedFileContainer',
    servicesOnYourBehalfFileContainer: '@ui.servicesOnYourBehalfFileContainer',
    legalProceedingsFileContainer: '@ui.legalProceedingsFileContainer',
    applicationDocumentsFileContainer: '@ui.applicationDocumentsFileContainer'
  },

  behaviors: {
    RadioGroupToggleBehavior: {
      radios: [
        {
          target: '#hasEntitiesYouOfferedExplainationArea',
          radioGroup: '[name="hasEntitiesYouOffered"]',
          valueToShow: '1'
        },
        {
          target: '#hasServicesOnYourBehalfExplainationArea',
          radioGroup: '[name="hasServicesOnYourBehalf"]',
          valueToShow: '1'
        },
        {
          target: '#hasLegalProceedingsExplainationArea',
          radioGroup: '[name="hasLegalProceedings"]',
          valueToShow: '1'
        }
      ]
    }
  },

  onBeforeShow: function() {
    this.entitesYouOfferedFile = new FileUploadView({
      isRequired: true,
      collection: makeUploadCollection(this.model.get('renewalData').hasEntitiesYouOfferedDocument),
      isReadOnly: this.options.isReadOnly,
      metadata: {
        documentTypeId: 10019
      }
    });
    this.showChildView('entitesYouOfferedFileContainer', this.entitesYouOfferedFile);

    var hasServicesOnYourBehalfDocument = this.model.get('renewalData').hasServicesOnYourBehalfDocument;

    this.servicesOnYourBehalfFile = new FileUploadView({
      isRequired: true,
      collection: makeUploadCollection(hasServicesOnYourBehalfDocument),
      isReadOnly: this.options.isReadOnly,
      metadata: {
        documentTypeId: 10019
      }
    });
    this.showChildView('servicesOnYourBehalfFileContainer', this.servicesOnYourBehalfFile);

    var hasLegalProceedingsDocument = this.model.get('renewalData').hasLegalProceedingsDocument;

    this.legalProceedingsFile = new FileUploadView({
      isRequired: true,
      collection: makeUploadCollection(hasLegalProceedingsDocument),
      isReadOnly: this.options.isReadOnly,
      metadata: {
        documentTypeId: 10023
      }
    });
    this.showChildView('legalProceedingsFileContainer', this.legalProceedingsFile);

    this.applicationDocumentsFile = new FileUploadView({
      isRequired: true,
      allowMultipleFiles: true,
      collection: new FileUploadCollection(this.model.get('renewalData').applicationDocuments),
      isReadOnly: this.options.isReadOnly,
      metadata: {
        documentTypeId: 10023
      }
    });
    this.showChildView('applicationDocumentsFileContainer', this.applicationDocumentsFile);

    Syphon.deserialize(this, this.model.get('renewalData'));
  },

  validate: function(config) {
    if (config.type === 'save') {
      // Turn off required field validation for this form
      parsleyUtil.disableRequiredValidation(this.ui.generalDetailsForm);
    } else {
      // Turn on required field validation for this form
      parsleyUtil.enableRequiredValidation(this.ui.generalDetailsForm);
    }

    var formData = Syphon.serialize(this);
    var validationPromises = [this.ui.generalDetailsForm.parsley().whenValidate()];
    if (formData.hasEntitiesYouOffered === '1') {
      validationPromises.push(this.entitesYouOfferedFile.validate(config));
    }

    if (formData.hasLegalProceedings === '1') {
      validationPromises.push(this.legalProceedingsFile.validate(config));
    }

    if (formData.hasServicesOnYourBehalf === '1') {
      validationPromises.push(this.servicesOnYourBehalfFile.validate(config));
    }

    return $.when.apply($, validationPromises);
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    _.extend(formData, {
      hasEntitiesYouOfferedDocumentId: this.entitesYouOfferedFile.getFirstFileId(),
      hasServicesOnYourBehalfDocumentId: this.servicesOnYourBehalfFile.getFirstFileId(),
      hasLegalProceedingsDocumentId: this.legalProceedingsFile.getFirstFileId(),
      applicationDocuments: this.applicationDocumentsFile.collection.pluck('fileId')
    });
    return formData;
  }
});
