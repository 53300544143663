var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "      <option value=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</option>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "        <option value=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</option>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return " \r\n<div class=\"form-group required\">\r\n  <label class=\"control-label col-md-3 required\">Examiner Placement</label>\r\n  <div class=\"col-md-5\">\r\n    <select class=\"form-control exam-placement-select\" name=\"examPlacementId\" data-parsley-required=\"true\">\r\n      <option value=\"\">--</option>\r\n"
    + ((stack1 = helpers.each.call(alias1,((stack1 = (depth0 != null ? depth0.examLookups : depth0)) != null ? stack1.examPlacements : stack1),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </select>\r\n  </div>\r\n</div>\r\n<div class=\"compliance-referral-container\">\r\n  <div class=\"compliance-violations-container\"></div>\r\n  <div class=\"form-group required\">\r\n    <label class=\"control-label col-md-3\">Compliance Violation(s)</label>\r\n    <div class=\"col-md-5\">\r\n      <select class=\"form-control compliance-violation-select\" name=\"complianceViolationIds\"\r\n        data-parsley-required=\"true\" multiple=\"multiple\" data-parsley-validate-if-empty=\"true\"\r\n        data-parsley-trigger-after-failure=\"change.select2\">\r\n"
    + ((stack1 = helpers.each.call(alias1,((stack1 = (depth0 != null ? depth0.examLookups : depth0)) != null ? stack1.complianceViolations : stack1),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "      </select>\r\n    </div>\r\n  </div>\r\n  <div class=\"form-group required\">\r\n    <label class=\"control-label col-md-3\">Repeat Violation?</label>\r\n    <div class=\"col-md-5\">\r\n      <select class=\"form-control\" name=\"isRepeatViolation\" data-parsley-required=\"true\">\r\n        <option value=\"\">--</option>\r\n        <option value=\"1\">Yes</option>\r\n        <option value=\"0\">No</option>\r\n      </select>\r\n    </div>\r\n  </div>\r\n  <div class=\"form-group required\">\r\n    <label class=\"control-label col-md-3\">Background</label>\r\n    <div class=\"col-md-5\">\r\n      <textarea class=\"form-control\" name=\"backgroundInfo\" data-parsley-required=\"true\" rows=\"6\"\r\n        placeholder=\"Background\"></textarea>\r\n    </div>\r\n  </div>\r\n</div>";
},"useData":true});