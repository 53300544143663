/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */
var _get = require('lodash.get');
var _ = require('underscore');

module.exports = function(formData) {
  var supportingDocuments = formData.supportingDocuments || [];
  var pointOfContact = formData.pointOfContact || {};
  var lawyerExternalUserId = _get(formData, 'oathRosterSubmissionLawyer.lawyerExternalUserId');
  var officers = formData.officers || [];
  var directorSection = formData.director || {};
  var directors = directorSection.directors || [];
  var certification = formData.certification || {};

  return $.soap({
    body: {
      SubmitOathRosterFiling: {
        $xmlns: 'http://dobs.pa.gov/dp/filing/bpm/1.0',
        OathRosterID: formData.oathRosterId,
        FilingID: formData.filingId,
        IsCertified: certification.isCertified ? '1' : '0',
        CertificationName: certification.certificationName,
        CertificationDocID: certification.certificationDocId,
        OathRosterFiling: {
          TypeID: directorSection.typeId,
          OfficerReportDocID: supportingDocuments.officerReportDocId,
          OathBoardElectedDocID: supportingDocuments.oathBoardElectedDocId,
          OathShareholderElectedDocID: supportingDocuments.oathShareholderElectedDocId
        },
        Directors: (function() {
          if (!directors.length) {
            return {};
          }
          return _.map(directors, function(director) {
            return {
              Director: {
                FilOathRosterID: formData.oathRosterId,
                FirstName: director.firstName,
                MiddleInitial: director.middleInitial,
                LastName: director.lastName,
                ElectionDate: director.electionDate,
                ElectionReason: director.electionReason,
                PredecessorName: director.predecessorName,
                VacancyDate: director.vacancyDate,
                VacancyCause: director.vacancyCause,
                ServiceEndDate: director.serviceEndDate,
                IsChairman: director.isChairman,
                MeetingType: director.meetingType
              }
            };
          });
        })(),
        Officers: (function() {
          if (!officers.length) {
            return {};
          }
          return _.map(officers, function(officer) {
            return {
              Officer: {
                FilOathRosterID: formData.oathRosterId,
                FirstName: officer.firstName,
                MiddleInitial: officer.middleInitial,
                LastName: officer.lastName,
                TitleID: officer.titleId,
                TitleComments: officer.titleComments,
                ElectionDate: officer.electionDate,
                ServiceEndDate: officer.serviceEndDate
              }
            };
          });
        })(),
        PointOfContact: {
          Name: pointOfContact.name,
          Title:pointOfContact.title,
          Phone: pointOfContact.phone,
          Email: pointOfContact.email,
          AddressLine1: pointOfContact.addressLine1,
          AddressLine2: pointOfContact.addressLine2,
          AddressLine3: pointOfContact.addressLine3,
          City: pointOfContact.city,
          State: pointOfContact.state,
          PostalCode: pointOfContact.postalCode,
          County: pointOfContact.county,
          Country: pointOfContact.country
        },
        LawyerExternalUserID: lawyerExternalUserId,
        SupportingDocuments: (function() {
          if (!_get(supportingDocuments, 'documents.length')) {
            return {};
          }
          return {
            Document: _.map(supportingDocuments.documents, function(supportingDocument) {
              return {
                DocumentID: supportingDocument.fileId
              };
            })
          };
        })()
      }
    }
  });
};
