/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var tmplDepContactAdditionalNumberView = require('../templates/DepContactAdditionalNumberView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepContactAdditionalNumberView,

  className: 'dep-contact-additional-number-view',

  behaviors: {
    PhoneFormatBehavior: {}
  },

  ui: {
    removeAdditionalNumber: '.remove-additional-number'
  },

  triggers: {
    'click @ui.removeAdditionalNumber': 'remove:additional-number'
  },

  templateHelpers: function() {
    return {
      isTrust: this.options.isTrust
    };
  },

  onBeforeShow: function() {
    Syphon.deserialize(this, this.model.toJSON());
  },

  getFormData: function() {
    return Syphon.serialize(this);
  }
});
