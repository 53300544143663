/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Syphon = require('backbone.syphon');
var Marionette = require('backbone.marionette');
var Ladda = require('ladda');

var WithdrawApplication = require('../services/WithdrawApplication');

var tmplNonDepEntityWithdrawApplicationModalView = require('../templates/NonDepEntityWithdrawApplicationModalView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepEntityWithdrawApplicationModalView,

  className: 'nondep-withdraw-application-modal-view',
  ui: {
    cancelButton: '.btn-cancel',
    submitButton: '.btn-submit',
    WithdrawApplicationForm: '.withdraw-application-form',
    formBody: '.form-body',
    resultMessage: '.result-message',
    resultInstructions: '.result-instructions',
    resultBody: '.result-body'
  },

  events: {
    'click @ui.submitButton': 'onClickSubmit'
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;
    var appId = self.model.get('id');
    this.ui.WithdrawApplicationForm.parsley()
      .whenValidate()
      .done(function() {
        var formData = Syphon.serialize(self);
        formData.applicationId = appId;

        var laddaContext = Ladda.create(self.ui.submitButton.get(0));
        laddaContext.start();
        WithdrawApplication(formData).done(function() {
          self.triggerMethod('modal:close');
        });
      });
  }
});
