/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Ladda = require('ladda');
var moment = require('moment');

var SubmitCompliancePayment = require('../services/SubmitCompliancePayment');

var tmplSecCompliancePaymentModalView = require('../templates/SecCompliancePaymentModalView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplSecCompliancePaymentModalView,

  className: 'sec-compliance-payment-modal-view',

  ui: {
    modalBody: '.modal-body',
    paymentForm: '.payment-form',
    paymentDate: '.payment-date',
    submit: '.submit'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeShow: function() {
    var formData = this.model.toJSON();
    var paymentDate = this.model.get('paymentDate');
    if (paymentDate && moment.isMoment(paymentDate) && paymentDate.isValid()) {
      formData.paymentDate = paymentDate.format('MM/DD/YYYY');
    }
    Syphon.deserialize(this, formData);

    this.ui.paymentDate.datepicker();
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;
    this.ui.paymentForm
      .parsley()
      .whenValidate()
      .done(function() {
        var l = Ladda.create(self.ui.submit[0]);
        l.start();

        SubmitCompliancePayment(self.getFormData())
          .done(function(payment) {
            self.triggerMethod('modal:close', payment);
          })
          .fail(function() {
            l.stop();
          });
      });
  },

  getFormData: function() {
    var data = Syphon.serialize(this);
    data.complianceId = this.model.get('complianceId');
    data.compliancePaymentId = this.model.get('compliancePaymentId');

    data.adminAssessmentsApplied = parseFloat(data.adminAssessmentsApplied || 0).toFixed(2);
    data.investigativeCostsApplied = parseFloat(data.investigativeCostsApplied || 0).toFixed(2);
    data.legalCostsApplied = parseFloat(data.legalCostsApplied || 0).toFixed(2);
    data.civilPenaltyApplied = parseFloat(data.civilPenaltyApplied || 0).toFixed(2);
    data.globalSettlementApplied = parseFloat(data.globalSettlementApplied || 0).toFixed(2);

    return data;
  }
});
