/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(applicationId) {
  var defer = $.Deferred();
  $.soap({
    body: {
      GetApplicationInvestigation: {
        $xmlns: 'http://dobs.pa.gov/nd/application/bpm/1.0',
        ApplicationID: applicationId
      }
    },
    blueprintArrays: [
      'GetApplicationInvestigationResponse.ApplicationInvestigationIndividuals.ND_ApplicationInvestigationIndividual',
      'GetApplicationInvestigationResponse.ApplicationInvestigationDocuments.ND_ApplicationInvestigationDocument'
    ],
    blueprint: {
      id: '$..ID',
      applicationId: '$..ApplicationID',
      entityHasCriminal: '$..EntityHasCriminal',
      entityHasFinancial: '$..EntityHasFinancial',
      entityHasRegulatory: '$..EntityHasRegulatory',
      entityHasCivil: '$..EntityHasCivil',
      createdDate: '$..CreatedDate',
      createdBy: '$..CreatedBy',
      individuals: [
        '$..ND_ApplicationInvestigationIndividual',
        {
          id: '$..ID',
          applicationInvestigationId: '$..ApplicationInvestigationID',
          individualId: '$..IndividualID',
          hasCriminal: '$..HasCriminal',
          hasFinancial: '$..HasFinancial',
          hasRegulatory: '$..HasRegulatory',
          hasCivil: '$..HasCivil',
          firstName: '$..FirstName',
          lastName: '$..LastName'
        }
      ],
      investigationDocuments: [
        '$..ND_ApplicationInvestigationDocument',
        {
          id: '$..ID',
          applicationInvestigationId: '$..ApplicationInvestigationID',
          individualId: '$..IndividualID',
          documentId: '$..DocumentID',
          documentType: '$..DocumentType',
          documentTypeId: '$..DocumentTypeID',
          documentName: '$..DocumentName',
          createdDate: '$..CreatedDate',
          createdBy: '$..CreatedBy'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      response.entityHasCriminal = response.entityHasCriminal === '1';
      response.entityHasFinancial = response.entityHasFinancial === '1';
      response.entityHasRegulatory = response.entityHasRegulatory === '1';
      response.entityHasCivil = response.entityHasCivil === '1';
      response.createdDate = response.createdDate ? moment(response.createdDate, moment.ISO_8601) : '';
      _.each(response.investigationDocuments, function(document) {
        document.createdDate = moment(document.createdDate, moment.ISO_8601).format('MM/DD/YYYY hh:mm a');
        document.file = {
          fileId: document.documentId,
          fileName: document.documentName
        };
      });
      _.each(response.individuals, function(individual) {
        individual.hasCriminal = individual.hasCriminal === '1';
        individual.hasFinancial = individual.hasFinancial === '1';
        individual.hasRegulatory = individual.hasRegulatory === '1';
        individual.hasCivil = individual.hasCivil === '1';
        individual.documents = _.where(response.investigationDocuments, {
          individualId: individual.individualId
        });
      });
      response.entityDocuments = _.filter(response.investigationDocuments, function(document) {
        return !document.individualId;
      });
      defer.resolve(response);
    })
    .fail(function(err) {
      defer.reject();
    });

  return defer.promise();
};
