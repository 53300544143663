/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var tmplDepConditionFormView = require('../templates/DepConditionFormView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepConditionFormView,

  className: 'dep-condition-form-view',

  ui: {
    dueDate: '.due-date',
    receivedDate: '.received-date'
  },

  onBeforeShow: function() {
    var condition = this.model.get('condition');

    Syphon.deserialize(this, condition);

    this.ui.dueDate.datepicker({});
    this.ui.receivedDate.datepicker({});
  },

  getFormData: function() {
    return Syphon.serialize(this);
  }
});
