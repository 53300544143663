/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var modelGet = require('common/util/modelGet');
var AddressView = require('common/views/AddressView');

var tmplDepCreditUnionSEGRequestGroupLocationModalView = require('./DepCreditUnionSEGRequestGroupLocationModalView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepCreditUnionSEGRequestGroupLocationModalView,

  className: 'dep-credit-union-seg-request-group-location-modal-view',

  ui: {
    licenseAddressForm: '.license-address-form',
    submit: '.submit',
    address: '.address'
  },

  regions: {
    address: '@ui.address'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeShow: function() {
    this.address = new AddressView({
      objectName: 'address',
      horizontal: false,
      isRequired: true,
      startingCountry: modelGet(this.model, 'country'),
      startingPostalCode: modelGet(this.model, 'postalCode'),
      startingCounty: modelGet(this.model, 'county')
    });

    this.showChildView('address', this.address);

    this.address.ui.countryGroup.remove();

    Syphon.deserialize(this, this.model.attributes);
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;
    this.ui.licenseAddressForm
      .parsley()
      .whenValidate()
      .done(function() {
        var formData = Syphon.serialize(self);
        formData.addressLine = [formData.address.addressLine1, formData.address.addressLine2, formData.address.addressLine3].join(' ');
        formData.addressLine1 = formData.address.addressLine1
        formData.addressLine2 = formData.address.addressLine2
        formData.addressLine3 = formData.address.addressLine3
        formData.postalCode = formData.address.postalCode
        formData.city = formData.address.city
        formData.state = formData.address.state
        formData.county = formData.address.county
        self.triggerMethod('modal:close', formData);
      });
  }
});
