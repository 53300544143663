/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var bodybuilder = require('bodybuilder');
var Ladda = require('ladda');

var GridView = require('psa-marionette/views/GridView');
var PageableCollection = require('psa-backbone/models/PageableCollection');

var LinkCell = require('common/backgrid/LinkCell');
var MomentCell = require('common/backgrid/MomentCell');
var ButtonLinkCell = require('common/backgrid/ButtonLinkCell');
var SearchHighlightCell = require('common/backgrid/SearchHighlightCell');

var ElasticSearchSummaryView = require('common/views/ElasticSearchSummaryView');
var toHash = require('common/util/toHash');
var toggleForm = require('common/util/toggleForm');

var DepInternalSidebarNavView = require('../../common/views/DepInternalSidebarNavView');
var SearchDepFilings = require('../services/SearchDepFilings');

require('../styles/DepFilingSearchView.css');
var tmplDepFilingSearchView = require('../templates/DepFilingSearchView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepFilingSearchView,

  className: 'dep-filing-search-view container-fluid',

  ui: {
    sideBar: '.side-bar-container',
    newEntity: '.btn-new-entity',
    filingType: 'select[name="filingTypeIds"]',
    genericSubmissionTypeFilter: '.generic-submission-type-filter',
    searchSummary: '.search-summary',
    searchResultsContainer: '.search-results-container',
    search: '.search',
    clearFilters: '.clear-filters',
    formInputs: ':input'
  },

  regions: {
    sideBar: '@ui.sideBar',
    searchResultsContainer: '@ui.searchResultsContainer',
    searchSummary: '@ui.searchSummary'
  },

  events: {
    'change @ui.filingType': 'onChangeFilingType',
    'click @ui.search': 'onClickSearch',
    'click @ui.clearFilters': 'onClickClearFilters',
    'keydown @ui.formInputs': 'onKeydownOfInputs'
  },

  templateHelpers: function() {
    return {
      entityTypes: _.chain(this.model.get('entityTypes'))
        .groupBy('type')
        .map(function(type, typeName) {
          return {
            ids: _.pluck(type, 'id'),
            name: typeName
          };
        })
        .value(),
      filingTypes: _.chain(this.model.get('filingTypes'))
        .groupBy('name')
        .map(function(type, typeName) {
          return {
            ids: _.pluck(type, 'id'),
            name: typeName
          };
        })
        .value(),
      filingRecordStatuses: _.reject(this.model.get('filingRecordStatuses'), function(status) {
        var hiddenStatusIds = ['1'];
        return _.contains(hiddenStatusIds, status.id);
      })
    };
  },

  initialize: function() {
    this.lookupHashes = {
      entityTypes: toHash(this.model.get('entityTypes'), 'id', 'type'),
      filingTypes: toHash(this.model.get('filingTypes'), 'id', 'name'),
      filingRecordStatuses: toHash(this.model.get('filingRecordStatuses'), 'id', 'status'),
      filingWorkflowStatuses: toHash(this.model.get('filingWorkflowStatuses'), 'id', 'status'),
      genericSubmissionTypes: toHash(this.model.get('genericSubmissionTypes'), 'id', 'name')
    };
  },

  onBeforeShow: function() {
    this.showChildView('sideBar', new DepInternalSidebarNavView());

    this.searchSummaryView = new ElasticSearchSummaryView({
      model: new Backbone.Model({
        searchLimit: this.model.get('searchLimit')
      })
    });
    this.showChildView('searchSummary', this.searchSummaryView);

    this.searchResults = new GridView({
      columns: [
        {
          name: 'entityName',
          label: 'Entity Name',
          cell: LinkCell.extend({
            emptyText: '--',
            newTab: true,
            href: function(model) {
              var entityId = model.get('entityId');
              var url = entityId ? '#dep/entity/' + entityId + '/dashboard' : '';
              return url;
            }
          }),
          headerCell: 'custom',
          width: 20,
          editable: false
        },
        {
          name: 'filingType',
          label: 'Filing Type',
          cell: 'string',
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'genericSubmissionType',
          label: 'Submission Type',
          cell: 'string',
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'filingRecordStatus',
          label: 'Record Status',
          cell: 'string',
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'filingWorkflowStatus',
          label: 'Workflow Status',
          cell: 'string',
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'actionDate',
          label: 'Action Date',
          cell: MomentCell.MomentCell,
          formatter: MomentCell.MomentDateFormatter.extend({
            emptyValue: '--'
          }),
          sortValue: MomentCell.MomentSortValue,
          headerCell: 'custom',
          width: 15,
          editable: false
        },
        {
          name: 'highlight',
          label: 'Matches',
          cell: SearchHighlightCell.extend({
            highlightLabels: {
              entityName: 'Entity Name',
              entityType: 'Entity Type',
              'dbas.dba': 'D/B/A',
              filingType: 'Filing Type',
              genericSubmissionType: 'Submission Type',
              filingRecordStatus: 'Status'
            }
          }),
          headerCell: 'custom',
          width: 20,
          sortable: false,
          editable: false
        },
        {
          name: '',
          label: '',
          cell: ButtonLinkCell.extend({
            name: 'Open',
            newTab: true,
            buttonClass: 'btn-primary btn-sm',
            href: function(model) {
              var entityId = model.get('entityId');
              var filingId = model.get('filingId');
              return '#dep/entity/' + entityId + '/filing/' + filingId + '/dashboard';
            }
          }),
          headerCell: 'custom',
          width: 5,
          sortable: false,
          editable: false
        }
      ],
      collection: new PageableCollection([], { pageSize: 25 }),
      emptyText: 'No Filings Found'
    });

    this.showChildView('searchResultsContainer', this.searchResults);

    this.$('.backgrid.table').addClass('table-bordered');

    toggleForm(this.ui.genericSubmissionTypeFilter, false);
  },

  onChangeFilingType: function() {
    // Show Generic Submission Type filter when Filing Type is 'Generic Submission'
    var selectedTypeIds = this.ui.filingType.val(),
      genericSubmissionTypeIds = [100, 200, 300];

    if (selectedTypeIds) {
      selectedTypeIds = JSON.parse(selectedTypeIds);
    }
    toggleForm(this.ui.genericSubmissionTypeFilter, !!_.intersection(genericSubmissionTypeIds, selectedTypeIds).length);
  },

  onKeydownOfInputs: function(e) {
    // Searches on press of enter
    if (e.keyCode === 13) {
      this.onClickSearch(e);
    }
  },

  onClickSearch: function() {
    var self = this,
      data = Syphon.serialize(this, {
        inputReaders: Syphon.SearchInputReaderSet
      });

    if (data.entityTypeIds) {
      data.entityTypeIds = JSON.parse(data.entityTypeIds);
    }

    if (data.filingTypeIds) {
      data.filingTypeIds = JSON.parse(data.filingTypeIds);
    }

    // If no inputs were given, do not perform a search.
    if (
      !data.entityName &&
      !data.entityTypeIds &&
      !data.dba &&
      !data.filingTypeIds &&
      !data.recordStatusId &&
      !data.genericSubmissionTypeIds
    ) {
      return;
    }
    this.searchSummaryView.reset();

    var l = Ladda.create(this.ui.search[0]);
    l.start();
    var body = bodybuilder();

    if (data.entityName) {
      body.query('bool', function(b) {
        b.orQuery('match', 'entityName', {
          query: data.entityName,
          fuzziness: 'AUTO',
          analyzer: 'standard'
        });
        b.orQuery('wildcard', 'entityName', data.entityName.toLowerCase() + '*');
        b.orQuery('match', 'entityName.phonetic', data.entityName);
        return b;
      });
    }

    if (data.entityTypeIds) {
      if (data.entityTypeIds.length > 1) {
        body.query('bool', function(b) {
          _.each(data.entityTypeIds, function(entityTypeId) {
            b.orQuery('match', 'entityTypeId', entityTypeId);
          });
          return b;
        });
      } else {
        body.query('match', 'entityTypeId', data.entityTypeIds[0]);
      }
    }

    if (data.dba !== '') {
      body.query(
        'nested',
        'path',
        'dbas',
        {
          inner_hits: {
            _source: false,
            highlight: {
              fields: {
                'dbas.dba': {
                  matched_fields: ['dbas.dba', 'dbas.dba.phonetic'],
                  type: 'fvh'
                }
              }
            }
          }
        },
        function(b) {
          b.query('bool', function(b2) {
            b2.orQuery('match', 'dbas.dba', {
              query: data.dba,
              fuzziness: 'AUTO',
              analyzer: 'standard'
            });
            b2.orQuery('match', 'dbas.dba.phonetic', data.dba);
            b2.orQuery('wildcard', 'dbas.dba', data.dba.toLowerCase() + '*');
            return b2;
          });
          return b;
        }
      );
    }

    if (data.recordStatusId) {
      body.query('match', 'recordStatusId', data.recordStatusId);
    } else {
      body.notQuery('term', 'recordStatusId', '1');
    }

    if (data.filingTypeIds) {
      if (data.filingTypeIds.length === 1) {
        body.query('match', 'filingTypeId', data.filingTypeIds[0]);
      } else {
        body.query('bool', function(innerBody) {
          _.each(data.filingTypeIds, function(filingTypeId) {
            innerBody.orQuery('match', 'filingTypeId', filingTypeId);
          });
          return innerBody;
        });
      }
    }

    if (data.genericSubmissionTypeId) {
      body.query('match', 'genericSubmissionTypeId', data.genericSubmissionTypeId);
    }

    body.notQuery('term', 'isDeleted', '1');

    body.size(this.model.get('searchLimit'));
    body.rawOption('highlight', require('./dep-filing-search-highlights.json'));
    var bodyObj = body.build();
    // Keep for testing
    // console.log(JSON.stringify(bodyObj, null, 2));
    // console.log('formData:', data);
    SearchDepFilings(bodyObj, data, this.lookupHashes)
      .done(function(data) {
        l.stop();
        self.searchResults.collection.fullCollection.reset(data.hits);
        self.searchSummaryView.display(data);
      })
      .fail(function() {
        l.stop();
      });
  },

  onClickClearFilters: function() {
    this.ui.formInputs.val('');
  }
});

