/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var _ = require('underscore');
var _remove = require('lodash.remove');

var GridView = require('psa-marionette/views/GridView');
var PageableCollection = require('psa-backbone/models/PageableCollection');

var ListCell = require('common/backgrid/ListCell');
var MomentCell = require('common/backgrid/MomentCell');
var ButtonLinkCell = require('common/backgrid/ButtonLinkCell');

var tmplDepEntityExamSectionView = require('../templates/DepEntityExamSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepEntityExamSectionView,

  ui: {
    exam: '.exam-container',
    ssTrustItHeader: '.ss-trust-it-header',
    trustItHeader: '.trust-it-header',
    ssItHeader: '.ss-it-header',
    tspHeader: '.tsp-header',
    examGrid: '.exam-grid-container',
    compCraExam: '.comp-cra-exam-container',
    compCraExamGrid: '.comp-cra-exam-grid-container',
    compCraExamLabel: '.comp-cra-header',
    bhcExam: '.bhc-exam-container',
    bhcExamGrid: '.bhc-exam-grid-container'
  },

  regions: {
    examGrid: '@ui.examGrid',
    compCraExamGrid: '@ui.compCraExamGrid',
    bhcExamGrid: '@ui.bhcExamGrid'
  },

  onBeforeShow: function() {
    var entityId = this.model.get('entityId');
    var exams = this.model.get('exams');
    var bhcExams = this.model.get('bhcExams');
    var entityTypeId = this.options.entityTypeId;
    var isTSP = entityTypeId === '3' || entityTypeId === '12' || entityTypeId === '23';
    var entityTypeIsTrust = ['21', '22', '23', '24', '25'];
    var isTrust = _.contains(entityTypeIsTrust, entityTypeId);
    var entityTypeIsCU = ['11', '12', '13', '14'];
    var isCreditUnion = _.contains(entityTypeIsCU, entityTypeId);
    var entityTypeIsBank = ['1', '2', '3', '4', '5', '7', '8', '9', '10'];
    var isBank = _.contains(entityTypeIsBank, entityTypeId);
    
    if (this.options.entityTypeId === '2') {
      this.ui.bhcExam.show();
      this.ui.compCraExam.hide();
      this.ui.exam.hide();

      var bhcExamGridView = new GridView({
        columns: [
          {
            name: 'bhcAsOfDate',
            label: 'As Of Date',
            cell: MomentCell.MomentCell,
            formatter: MomentCell.MomentDateFormatter.extend({
              emptyValue: '--'
            }),
            sortValue: MomentCell.MomentSortValue,
            editable: false,
            headerCell: 'custom',
            width: 20
          },
          {
            name: 'conductedBy',
            label: 'Conducted By',
            cell: ListCell,
            editable: false,
            headerCell: 'custom',
            width: 20
          },
          {
            name: 'riskManagement',
            label: 'Risk Management',
            cell: 'string',
            editable: false,
            headerCell: 'custom',
            width: 20
          },
          {
            name: 'hcComposite',
            label: 'Composite',
            cell: 'string',
            editable: false,
            sortable: false,
            headerCell: 'custom',
            width: 20
          },
          {
            name: '',
            label: '',
            cell: ButtonLinkCell.extend({
              name: 'Open',
              buttonClass: 'btn-primary btn-sm',
              href: function(model) {
                return '#dep/entity/' + entityId + '/exam/' + model.get('id') + '/dashboard';
              }
            }),
            editable: false,
            sortable: false,
            headerCell: 'custom',
            width: 20
          }
        ],
        emptyText: 'Entity Has No Holding Company Exams',
        collection: new PageableCollection(bhcExams, { pageSize: 15 })
      });

      this.showChildView('bhcExamGrid', bhcExamGridView);

      bhcExamGridView.getViewGrid().sort('bhcAsOfDate', 'descending');
    }

    var ssTrustItGridColumns = [
      {
        name: 'asOfDate',
        label: 'As Of Date',
        cell: MomentCell.MomentCell,
        formatter: MomentCell.MomentDateFormatter.extend({
          emptyValue: '--'
        }),
        sortValue: MomentCell.MomentSortValue,
        editable: false,
        headerCell: 'custom',
        width: 10
      },
      {
        name: 'examScope',
        label: 'Scope',
        cell: 'string',
        editable: false,
        headerCell: 'custom',
        width: 10
      },
      {
        name: 'scopeSubcategory',
        label: 'Scope Subcategory',
        cell: 'string',
        editable: false,
        headerCell: 'custom',
        width: 10,
        renderable: isBank
      },
      {
        name: 'examType',
        label: 'Type',
        cell: 'string',
        editable: false,
        headerCell: 'custom',
        width: 10
      },
      {
        name: 'leadAgency',
        label: 'Lead',
        cell: 'string',
        editable: false,
        headerCell: 'custom',
        width: 10
      },
      {
        name: 'conductedBy',
        label: 'Conducted By',
        cell: ListCell,
        editable: false,
        headerCell: 'custom',
        width: 10
      },
      {
        name: 'ssRatings',
        label: 'CAMELS/C',
        cell: 'string',
        editable: false,
        headerCell: 'custom',
        width: 10
      },
      {
        name: 'itRatings',
        label: 'AMDS/C',
        cell: 'string',
        editable: false,
        headerCell: 'custom',
        width: 10
      },
      {
        name: 'trustRatings',
        label: 'MOECA/C',
        cell: 'string',
        editable: false,
        headerCell: 'custom',
        width: 10,
        renderable: !isCreditUnion
      },
      {
        name: '',
        label: '',
        cell: ButtonLinkCell.extend({
          name: 'Open',
          buttonClass: 'btn-primary btn-sm',
          href: function(model) {
            return '#dep/entity/' + entityId + '/exam/' + model.get('id') + '/dashboard';
          }
        }),
        editable: false,
        headerCell: 'custom',
        width: 5
      }
    ];
    
    if (isCreditUnion) {
      this.ui.ssItHeader.show();
      this.ui.ssTrustItHeader.hide();
    }
    if (isTrust) {
      this.ui.ssTrustItHeader.hide();
      this.ui.trustItHeader.show();

      _remove(ssTrustItGridColumns, function(column) {
        return column.name === 'ssRatings' || column.name === 'itRatings';
      });

      ssTrustItGridColumns.splice(6, 0, {
        name: 'itRatings',
        label: 'AMDS/C',
        cell: 'string',
        editable: false,
        headerCell: 'custom',
        width: 10
      });
    }

    var ssTrustItGridView = new GridView({
      columns: ssTrustItGridColumns,
      emptyText: 'Entity Has No S&S, Trust or IT Exams',
      collection: new PageableCollection(exams.ssTrustItExams, { pageSize: 15 })
    });

    if (isTSP) {
      var tspGridColumns = [
        {
          name: 'TSPAsOfDate',
          label: 'As Of Date',
          cell: MomentCell.MomentCell,
          formatter: MomentCell.MomentDateFormatter.extend({
            emptyValue: '--'
          }),
          sortValue: MomentCell.MomentSortValue,
          editable: false,
          headerCell: 'custom',
          width: 10
        },
        {
          name: 'examScope',
          label: 'Scope',
          cell: 'string',
          editable: false,
          headerCell: 'custom',
          width: 14
        },
        {
          name: 'leadAgency',
          label: 'Agency-in-Charge',
          cell: 'string',
          editable: false,
          headerCell: 'custom',
          width: 10
        },
        {
          name: 'ursitRatings',
          label: 'URSIT',
          cell: 'string',
          editable: false,
          sortable: false,
          headerCell: 'custom',
          width: 6
        },
        {
          name: 'dataCenter',
          label: 'Data Center',
          cell: 'string',
          editable: false,
          sortable: false,
          headerCell: 'custom',
          width: 10
        },
        {
          name: '',
          label: '',
          cell: ButtonLinkCell.extend({
            name: 'Open',
            buttonClass: 'btn-primary btn-sm',
            href: function(model) {
              return '#dep/entity/' + entityId + '/exam/' + model.get('id') + '/dashboard';
            }
          }),
          editable: false,
          headerCell: 'custom',
          width: 5
        }
      ];
      var tspGridView = new GridView({
        columns: tspGridColumns,
        emptyText: 'Entity Has No TSP Exams',
        collection: new PageableCollection(exams.exams, { pageSize: 15 })
      });
      this.showChildView('examGrid', tspGridView);
      this.ui.ssTrustItHeader.hide();
      this.ui.tspHeader.show();
    } else {
      this.showChildView('examGrid', ssTrustItGridView);
      ssTrustItGridView
        .getViewGrid()
        .sort('examType', 'ascending')
        .sort('asOfDate', 'descending');
     }

    var compCraGridView = new GridView({
      columns: [
        {
          name: 'asOfDate',
          label: 'As Of Date',
          cell: MomentCell.MomentCell,
          formatter: MomentCell.MomentDateFormatter.extend({
            emptyValue: '--'
          }),
          sortValue: MomentCell.MomentSortValue,
          editable: false,
          headerCell: 'custom',
          width: 10
        },
        {
          name: 'examScope',
          label: 'Scope',
          cell: 'string',
          editable: false,
          headerCell: 'custom',
          width: 10
        },
        {
          name: 'scopeSubcategory',
          label: 'Scope Subcategory',
          cell: 'string',
          editable: false,
          headerCell: 'custom',
          width: 10,
          renderable: isBank
        },
        {
          name: 'examType',
          label: 'Type',
          cell: 'string',
          editable: false,
          headerCell: 'custom',
          width: 10,
          sort: 'ascending'
        },
        {
          name: 'conductedBy',
          label: 'Conducted By',
          cell: ListCell,
          editable: false,
          headerCell: 'custom',
          width: 10
        },
        {
          name: 'compositeRating',
          label: 'Composite',
          cell: 'string',
          editable: false,
          headerCell: 'custom',
          width: 10
        },
        {
          name: 'craRating',
          label: 'CRA',
          cell: 'string',
          editable: false,
          headerCell: 'custom',
          width: 10
        },
        {
          name: '',
          label: '',
          cell: ButtonLinkCell.extend({
            name: 'Open',
            buttonClass: 'btn-primary btn-sm',
            href: function(model) {
              return '#dep/entity/' + entityId + '/exam/' + model.get('id') + '/dashboard';
            }
          }),
          editable: false,
          headerCell: 'custom',
          width: 5
        }
      ],
      emptyText: 'Entity Has No Compliance or CRA Exams',
      collection: new PageableCollection(exams.compCraExams, { pageSize: 15 })
    });
    
    if (!isTrust && !isTSP) {
      this.showChildView('compCraExamGrid', compCraGridView);

      compCraGridView
        .getViewGrid()
        .sort('examType', 'ascending')
        .sort('asOfDate', 'descending');
      this.ui.compCraExamLabel.show();
    }
  }
});
