/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var NProgress = require('nprogress');
var Radio = require('backbone.radio');

var NavUtils = require('psa-core/util/nav');

var Scaffold = require('common/layout/Scaffold');

var GetExamDetailsForEntity = require('../../common/services/GetExamDetailsForEntity');

var NonDepPreviewExamMaterialsView = require('../views/NonDepPreviewExamMaterialsView');

module.exports = function(examId) {
  NProgress.start();
  NProgress.set(0.6);

  $.when(GetExamDetailsForEntity(examId)).done(function(exam) {
    NProgress.set(0.85);

    var LoanLogPromise;
    if (exam.loanLogCode) {
      LoanLogPromise = Radio.channel('nd:exam').request('build:exam-questions', {
        examId: examId,
        examSetupCode: exam.loanLogCode,
        mode: 'review-results'
      });
    }

    var ExamMaterialsPromise;
    if (exam.examMaterialsCode) {
      ExamMaterialsPromise = Radio.channel('nd:exam').request('build:exam-questions', {
        examId: examId,
        examSetupCode: exam.examMaterialsCode,
        mode: 'review-results'
      });
    }

    $.when(LoanLogPromise, ExamMaterialsPromise).done(function(loanLogQuestionsView, examMaterialsQuestionView) {
      NavUtils.navigate('nondep/exam/' + examId + '/exam-materials');
      Scaffold.content.show(
        new NonDepPreviewExamMaterialsView({
          loanLogQuestionsView: loanLogQuestionsView,
          examMaterialsQuestionView: examMaterialsQuestionView,
          model: new Backbone.Model({
            examId: exam.examId,
            exam: exam
          })
        })
      );
      NProgress.done();
    });
  });
};
