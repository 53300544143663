/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Ladda = require('ladda');
var Radio = require('backbone.radio');
var FileUploadCollection = require('psa-backbone/models/FileUploadCollection');

var modelGet = require('common/util/modelGet');
var parsleyUtil = require('common/util/parsleyUtil');
var toggleForm = require('common/util/toggleForm');
var downloadDocument = require('common/util/downloadDocument');
var FileUploadView = require('common/views/FileUploadView');

var SubmitGenericSubmissionFiling = require('../../services/SubmitGenericSubmissionFiling');

require('../../styles/DepFilingPrintView.css');
var tmplDepGenericSubmissionSubmissionSectionView = require('./DepGenericSubmissionSubmissionSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepGenericSubmissionSubmissionSectionView,

  className: 'dep-generic-submission-submission-section-view',

  ui: {
    submissionForm: '.submission-form',
    submissionType: '.submission-type',
    submissionInstructionsContainer: '.submission-instructions-container',
    submissionInstructionsTextContainer: '.submission-instructions-text-container',
    selectedFilingInstructions: '.selected-filing-instructions',
    downloadSubmissionInstructions: '.download-submission-instructions',
    missingFile: '.missing-file',
    supportingDocumentsContainer: '.supporting-documents-container',
    submit: '.submit',
    externalsubmissiontype: 'select[name="submissionTypeId"]'
  },

  regions: {
    supportingDocumentsContainer: '@ui.supportingDocumentsContainer'
   
  },

  events: {
    'change @ui.submissionType': 'onChangeSubmissionType',
    'click @ui.downloadSubmissionInstructions': 'onClickDownloadSubmissionInstructions',
    'click @ui.submit': 'onClickSubmit'
  },

  behaviors: {
    ReadOnlyFormBehavior: {}
  },

  templateHelpers: function() {
    var isReadOnly = this.options.isReadOnly;
    var isInternalFiling = this.options.mode === 'int-new';
    var submissionTypes = this.model.get('submissionTypes');
    var entityCategoryId = modelGet(this.model, 'filingDetails.entityCategoryId');
    var filteredSubmissionTypes = _.where(submissionTypes, { entityCategoryId: entityCategoryId });
    if (!isInternalFiling && !isReadOnly && this.options.mode !== 'resubmit') {
      filteredSubmissionTypes = _.where(filteredSubmissionTypes, { isExternallyVisible: '1' });
    }
    var internalSubmissionTypes = _.filter(submissionTypes, function(type) {
      return !type.entityCategoryId && type.id !== '500';
    });

    return {
      isReadOnly: isReadOnly,
      isInternalFiling: isInternalFiling,
      submissionTypes: filteredSubmissionTypes,
      internalSubmissionTypes: internalSubmissionTypes,
      showAllSubmissionTypes: isInternalFiling || isReadOnly,
      mode: this.options.mode
    };
  },

  onBeforeShow: function() {
    var resubmission = this.options.mode === 'resubmit';
    var supportingDocuments = _.pluck(modelGet(this.model, 'filingContent.supportingDocuments', []), 'file');
    var filingContent = this.model.get('filingContent');
    var submissionTypes = this.model.get('submissionTypes');
    if (resubmission) {
      var submissionTypeId = filingContent.submissionTypeId;
      this.ui.submissionType.val(submissionTypeId)
      this.ui.externalsubmissiontype.prop('disabled', 'true');
    }
    var hasInstructionDocument = false;
    if (filingContent.submissionTypeId) {
      var instructionsDocument = _.find(submissionTypes, { id: filingContent.submissionTypeId }).instructionsDocument;
      hasInstructionDocument = instructionsDocument.fileId ? true : false;
    }

    Syphon.deserialize(this, this.model.get('filingContent'));
    this.handleInstructionDisplay(hasInstructionDocument);

    this.fileUploadView = new FileUploadView({
      isReadOnly: this.options.isReadOnly,
      allowMultipleFiles: true,
      isRequired: true,
      collection: new FileUploadCollection(supportingDocuments),
      metadata: {
        documentTypeId: 20001
      }
    });
    this.showChildView('supportingDocumentsContainer', this.fileUploadView);
  },

  onAttach: function() {
    if (this.options.mode === 'print') {
      this.switchToPrint();
    }
  },

  switchToPrint: function() {
    var textAreas = $('.input-view');

    _.each(textAreas, function(textArea) {
      var textAreaVal = textArea.value;
      var textAreaName = textArea.name;
      var printElementId = '#'.concat(textAreaName, 'PrintView');
      $(printElementId).text(textAreaVal);
    });
    toggleForm(textAreas, false);
  },

  onChangeSubmissionType: function() {
    var submissionTypeId = this.ui.submissionType.val();
    var submissionTypes = this.model.get('submissionTypes');
    var selectedSubmissionType = _.find(submissionTypes, function(submissionType) {
      return submissionType.id === submissionTypeId;
    });
    var instruction = selectedSubmissionType ? selectedSubmissionType.instructions : null;
    toggleForm(this.ui.submissionInstructionsTextContainer, instruction);
    this.ui.selectedFilingInstructions.html(instruction);

    var hasInstructionDocument = selectedSubmissionType.instructionsDocument.fileId;
    this.handleInstructionDisplay(hasInstructionDocument);
    },

  onClickDownloadSubmissionInstructions: function(e) {
    e.preventDefault();
    var selectedSubmissionTypeId = this.ui.submissionType.val();
    var submissionTypes = this.model.get('submissionTypes');
    var selectedSubmissionType = _.findWhere(submissionTypes, { id: selectedSubmissionTypeId });
    var submissionTypeDocument = selectedSubmissionType.instructionsDocument;
    downloadDocument({
      fileId: submissionTypeDocument.fileId,
      fileName: submissionTypeDocument.fileName,
      isStatic: true
    });
  },

  handleInstructionDisplay: function(hasInstructionDocument) {
    toggleForm(this.ui.submissionInstructionsContainer, hasInstructionDocument);
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;

    var l = Ladda.create(this.ui.submit[0]);

    this.sideNavView.validateAll({ type: 'submit' }).done(function() {
      l.start();

      var formData = self.sideNavView.getFormData();
      formData.filingId = self.model.get('filingId');

      if (!formData.genericSubmissionSubmission.submissionTypeId )
      {
      formData.genericSubmissionSubmission.submissionTypeId = self.model.get('filingContent').submissionTypeId;
      }
      
      SubmitGenericSubmissionFiling(formData)
        .done(function() {
          Radio.channel('dp:draft-filing').trigger('proceed-to-payment', l);
          Radio.channel('dp:draft-filing').trigger('submit', l);
          Radio.channel('dp:submit-filing').trigger('resubmit', l);
        })
        .fail(function() {
          l.stop();
        });
    });
  },

  validate: function(config) {
    this.ui.submissionForm.parsley().reset();
    this.ui.missingFile.hide();

    if (config.type === 'save') {
      // Turn off required field validation for this form
      parsleyUtil.disableRequiredValidation(this.ui.submissionForm);
    } else {
      // Turn on required field validation for this form
      parsleyUtil.enableRequiredValidation(this.ui.submissionForm);

      var validationPromises = [this.ui.submissionForm.parsley().whenValidate(), this.fileUploadView.validate()];
    }

    return $.when.apply($, validationPromises);
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    formData.supportingDocuments = this.fileUploadView.collection.map(function(file) {
      return {
        fileId: file.get('fileId'),
        documentTypeId: modelGet(file, 'metadata.documentTypeId')
      };
    });

    return formData;
  }
});
