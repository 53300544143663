var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "required";
},"3":function(container,depth0,helpers,partials,data) {
    var helper;

  return "  <div class=\"m-b-md\"> \r\n    "
    + container.escapeExpression(((helper = (helper = helpers.instructions || (depth0 != null ? depth0.instructions : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"instructions","hash":{},"data":data}) : helper)))
    + "\r\n  </div>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "\r\n<div class=\"form-group "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isRequired : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\r\n  <label class=\"control-label\">"
    + alias4(((helper = (helper = helpers.fileUploadLabel || (depth0 != null ? depth0.fileUploadLabel : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"fileUploadLabel","hash":{},"data":data}) : helper)))
    + "</label>\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.hasInstructions : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  <div class=\"alert alert-danger collapse missing-file\" role=\"alert\">\r\n    <i class=\"fa fa-exclamation-triangle\" aria-hidden=\"true\"></i>\r\n    At least one document or comment must be provided.\r\n  </div>\r\n  <div class=\"document-container\"></div>\r\n</div>\r\n<div class=\"form-group\">\r\n  <label class=\"control-label\">"
    + alias4(((helper = (helper = helpers.commentsLabel || (depth0 != null ? depth0.commentsLabel : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"commentsLabel","hash":{},"data":data}) : helper)))
    + "</label>\r\n  <textarea type=\"text\" name=\""
    + alias4(((helper = (helper = helpers.commentsName || (depth0 != null ? depth0.commentsName : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"commentsName","hash":{},"data":data}) : helper)))
    + "\" class=\"form-control comments input-view\" id=\""
    + alias4(((helper = (helper = helpers.commentsName || (depth0 != null ? depth0.commentsName : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"commentsName","hash":{},"data":data}) : helper)))
    + alias4(((helper = (helper = helpers.uniqueId || (depth0 != null ? depth0.uniqueId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"uniqueId","hash":{},"data":data}) : helper)))
    + "\"\r\n    placeholder=\"If no file is being uploaded, please explain.\" rows=\"5\"></textarea>\r\n</div>\r\n<div class=\"form-group print-view\" id=\""
    + alias4(((helper = (helper = helpers.commentsName || (depth0 != null ? depth0.commentsName : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"commentsName","hash":{},"data":data}) : helper)))
    + alias4(((helper = (helper = helpers.uniqueId || (depth0 != null ? depth0.uniqueId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"uniqueId","hash":{},"data":data}) : helper)))
    + "PrintView\"></div>";
},"useData":true});