/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Backgrid = require('backgrid');

require('./SearchHighlightCell.css');
var tmplSearchHighlightCell = require('./SearchHighlightCell.hbs');

module.exports = Backgrid.SearchHighlightCell = Backgrid.Cell.extend({
  className: 'search-highlight-cell',

  render: function() {
    var highlights = this.model.get(this.column.get('name'));
    var highlightLabels = this.highlightLabels;
    var searchMatches = [];
    _.each(highlights, function(matches, key) {
      _.each(matches, function(match) {
        searchMatches.push({
          label: highlightLabels[key],
          value: match
        });
      });
    });
    this.$el.addClass(this.column.get('name'));
    this.$el.empty().append(
      tmplSearchHighlightCell({
        searchMatches: searchMatches
      })
    );
    this.delegateEvents();
    return this;
  }
});
