/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Backbone = require('backbone');

var GridView = require('psa-marionette/views/GridView');
var UIUtil = require('psa-core/util/ui');

var MultiButtonCell = require('common/backgrid/MultiButtonCell');
var MomentCell = require('common/backgrid/MomentCell');
var ConfirmationModalView = require('common/views/ConfirmationModalView');

var SubmitExamInvInqStaff = require('../services/SubmitExamInvInqStaff');

module.exports = GridView.extend({
  className: 'sec-exam-inv-inq-section-staff-member-view',

  initialize: function(options) {
    _.extend(options, {
      columns: [
        {
          name: 'staffMember',
          label: 'Staff Member',
          cell: 'string',
          headerCell: 'custom',
          width: 20,
          editable: false
        },
        {
          name: 'function',
          label: 'Function',
          cell: 'string',
          headerCell: 'custom',
          width: 20,
          editable: false
        },
        {
          name: 'assignedDate',
          label: 'Assigned Date',
          cell: MomentCell.MomentCell,
          formatter: MomentCell.MomentDateFormatter.extend({
            emptyValue: '--'
          }),
          sortValue: MomentCell.MomentSortValue,
          headerCell: 'custom',
          width: 20,
          editable: false
        },
        {
          name: 'removedDate',
          label: 'Removed Date',
          cell: MomentCell.MomentCell,
          formatter: MomentCell.MomentDateFormatter.extend({
            emptyValue: '--'
          }),
          sortValue: MomentCell.MomentSortValue,
          headerCell: 'custom',
          width: 20,
          editable: false
        },
        {
          name: 'editDeleteMultiButtonCell',
          label: '',
          cell: MultiButtonCell.extend({
            buttons: [
              {
                name: '',
                labelClass: 'fa fa-edit',
                buttonClass: 'btn-primary btn-sm edit-processing-response',
                href: function(model) {
                  return (
                    '#sec/' +
                    model.get('objectType') +
                    '/' +
                    model.get('objectId') +
                    '/staff-member/' +
                    model.get('id') +
                    '/edit'
                  );
                }
              },
              {
                name: '',
                labelClass: 'fa fa-trash',
                buttonClass: 'btn-danger btn-sm delete-processing-response',
                onClick: this.onClickDelete.bind(this)
              }
            ]
          }),
          editable: false,
          sortable: false,
          width: 20
        }
      ],
      emptyText: 'No Staff Members'
    });

    if (GridView.prototype.initialize) {
      GridView.prototype.initialize.apply(options);
    }
  },

  onClickDelete: function(e, model) {
    e.preventDefault();

    UIUtil.showModal({
      title: 'Confirm Deleting Staff Member',
      view: new ConfirmationModalView({
        confirmText: 'Delete Staff Member',
        model: new Backbone.Model({
          message: 'Are you sure you want to delete this staff member?',
          onConfirm: function() {
            return SubmitExamInvInqStaff({
              staffId: model.get('id'),
              isDeleted: '1'
            });
          },
          onConfirmDone: function() {
            this.collection.remove(model);
          }.bind(this)
        })
      })
    });
  }
});
