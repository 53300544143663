var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "\r\n<h1 class=\"page-title\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.exam : depth0)) != null ? stack1.entityName : stack1), depth0))
    + "</h1>\r\n<div class=\"header-bar\"></div>\r\n<div class=\"breadcrumb-container\"></div>\r\n\r\n<form class=\"form-horizontal update-billing-details-form\">\r\n  <div class=\"form-group\">\r\n    <label class=\"control-label col-md-3\">Refund Amount</label>\r\n    <div class=\"col-md-3\">\r\n      <div class=\"input-group\">\r\n        <span class=\"input-group-addon\">$</span>\r\n        <input type=\"number\" step=\"any\" name=\"refundAmountOverride\" class=\"form-control\"\r\n          placeholder=\"Override Refund Amount\" data-parsley-dollar-amount=\"true\"\r\n          data-parsley-errors-container=\".refund-amount-error-container\" data-parsley-strict-minimum=\"0\" />\r\n      </div>\r\n      <div class=\"refund-amount-error-container\"></div>\r\n    </div>\r\n  </div>\r\n  <div class=\"form-group\">\r\n    <label class=\"control-label col-md-3\">Refund Comments</label>\r\n    <div class=\"col-md-8\">\r\n      <textarea class=\"form-control\" name=\"billingComments\" rows=\"8\"\r\n        placeholder=\"Documentation of Correspondence\"></textarea>\r\n    </div>\r\n  </div>\r\n</form>\r\n\r\n<div class=\"row m-b-lg\">\r\n  <div class=\"col-md-6 col-md-offset-3\">\r\n    <a href=\"#nondep/dashboard/exam/"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.exam : depth0)) != null ? stack1.examId : stack1), depth0))
    + "\" class=\"btn btn-default\" role=\"button\">Cancel</a>\r\n    <button class=\"btn btn-primary submit ladda-button\" data-style=\"expand-right\" type=\"button\">\r\n      <span class=\"ladda-label\">Save Refund Details</span>\r\n    </button>\r\n  </div>\r\n</div>";
},"useData":true});