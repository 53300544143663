/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(auditId) {
  var defer = $.Deferred();

  $.soap({
    body: {
      GetEntityAudit: {
        $xmlns: 'http://dobs.pa.gov/dp/audit/bpm/1.0',
        AuditID: auditId
      }
    },
    blueprint: {
      entityId: '$..EntityID',
      auditDate: '$..AuditDate',
      receivedDate: '$..ReceivedDate',
      auditType: '$..AuditType',
      auditTypeId: '$..AuditTypeID',
      auditTrustType: '$..AuditTrustType',
      auditTrustTypeId: '$..AuditTrustTypeID',
      opinion: '$..Opinion',
      opinionId: '$..OpinionID',
      opinionDetails: '$..OpinionDetails',
      auditFirm: '$..AuditFirm',
      auditFirmId: '$..AuditFirmID',
      internalAuditFirm: '$..InternalAuditFirm',
      internalAuditFirmId: '$..InternalAuditFirmID',
      approvedDate: '$..ApprovedDate',
      lastRevisedDate: '$..LastRevisedDate',
      letterReceivedDate: '$..LetterReceivedDate',
      responseReceivedDate: '$..ResponseReceivedDate',
      comments: '$..Comments',
      acknowledgedDate: '$..AcknowledgedDate',
      csFolderId: '$..CSFolderID'
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      if (response.receivedDate) {
        response.receivedDate = moment(response.receivedDate, 'YYYY-MM-DD').format('MM/DD/YYYY');
      }
      if (response.auditDate) {
        response.auditDate = moment(response.auditDate, 'YYYY-MM-DD').format('MM/DD/YYYY');
      }
      if (response.approvedDate) {
        response.approvedDate = moment(response.approvedDate, 'YYYY-MM-DD').format('MM/DD/YYYY');
      }
      if (response.lastRevisedDate) {
        response.lastRevisedDate = moment(response.lastRevisedDate, 'YYYY-MM-DD').format('MM/DD/YYYY');
      }
      if (response.letterReceivedDate) {
        response.letterReceivedDate = moment(response.letterReceivedDate, 'YYYY-MM-DD').format('MM/DD/YYYY');
      }
      if (response.responseReceivedDate) {
        response.responseReceivedDate = moment(response.responseReceivedDate, 'YYYY-MM-DD').format('MM/DD/YYYY');
      }
      if (response.acknowledgedDate) {
        response.acknowledgedDate = moment(response.acknowledgedDate, 'YYYY-MM-DD').format('MM/DD/YYYY');
      }
      defer.resolve(response);
    })
    .fail(function(err) {
      defer.reject(err);
    });

  return defer.promise();
};
