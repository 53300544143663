/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var FileUploadView = require('common/views/FileUploadView');
var modelGet = require('common/util/modelGet');

var tmplCommentsDocumentView = require('./CommentsDocumentView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplCommentsDocumentView,

  className: 'comments-document-view',

  ui: {
    documentContainer: '.document-container',
    comments: '.comments',
    missingFile: '.missing-file'
  },

  regions: {
    documentContainer: '@ui.documentContainer'
  },

  events: {
    'change @ui.comments': 'onChangeComments'
  },

  templateHelpers: function() {
    return {
      fileUploadLabel: this.options.fileUploadLabel,
      commentsLabel: this.options.commentsLabel,
      commentsName: this.options.commentsName,
      uniqueId: this.options.uniqueId,
      isRequired: this.options.isRequired,
      hasInstructions: this.options.hasInstructions,
      instructions: this.options.instructions
    };
  },

  initialize: function() {
    if (!this.model) {
      this.model = new Backbone.Model();
    }
  },

  onRender: function() {
    this.fileUploadView = new FileUploadView({
      isReadOnly: this.options.isReadOnly,
      allowMultipleFiles: this.options.allowMultipleFiles,
      collection: this.collection,
      metadata: {
        documentTypeId: this.options.documentTypeId
      }
    });
    this.showChildView('documentContainer', this.fileUploadView);

    Syphon.deserialize(this, this.model.toJSON());
  },

  onChangeComments: function(e) {
    var commentsName = this.options.commentsName;
    if (!commentsName) {
      return;
    }
    // Anytime a user updates the value of the comments, update the model with
    // the new value entered by the user.
    this.model.set(commentsName, e.target.value);
  },

  removeValidationErrors: function() {
    this.ui.missingFile.hide();
  },

  validate: function() {
    var defer = $.Deferred();
    this.removeValidationErrors();

    var hasOneSuccessfulFileOrComment = true;
    var hasComments = !!this.ui.comments.val();
    var uploads = this.fileUploadView.collection;

    if (this.options.isRequired) {
      if (!uploads.length && !hasComments) {
        hasOneSuccessfulFileOrComment = false;
      } else {
        var hasSuccessfulFile;
        if (uploads) {
          hasSuccessfulFile = uploads.some(function(model) {
            return model.get('fileId');
          });
        }
        hasOneSuccessfulFileOrComment = hasComments || hasSuccessfulFile;
      }

      if (!hasOneSuccessfulFileOrComment) {
        this.ui.missingFile.show();
        defer.reject();
      } else {
        defer.resolve();
      }
    } else {
      defer.resolve();
    }

    return defer.promise();
  },

  getFirstFileId: function() {
    return this.fileUploadView.getFirstFileId();
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    formData.files = this.fileUploadView.collection.map(function(file) {
      return {
        fileId: file.get('fileId'),
        documentTypeId: modelGet(file, 'metadata.documentTypeId')
      };
    });

    return formData;
  }
});
