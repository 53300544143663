/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Ladda = require('ladda');
var Radio = require('backbone.radio');

var modelGet = require('common/util/modelGet');
var BreadcrumbView = require('common/views/BreadcrumbView');
var momentUtil = require('common/util/momentUtil');

var SubmitFilingCondition = require('../services/SubmitFilingCondition');

var DepConditionFormView = require('./DepConditionFormView');

var tmplDepConditionNewView = require('../templates/DepConditionNewView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepConditionNewView,

  className: 'dep-condition-new-view container-fluid',

  ui: {
    breadcrumb: '.breadcrumb-container',
    conditionFormContainer: '.condition-form-container',
    submit: '.submit'
  },

  regions: {
    breadcrumb: '@ui.breadcrumb',
    conditionFormContainer: '@ui.conditionFormContainer'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeShow: function() {
    this.filingId = this.model.get('filingId');
    this.entityId = this.model.get('entityId');
    var entityName = modelGet(this.model, 'entityIdentifiers.entityName');
    var filingType = modelGet(this.model, 'filingIdentifiers.filingType');

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#dep/dashboard',
            text: 'Home'
          },
          {
            link: '#dep/entity/' + this.entityId + '/dashboard',
            text: entityName
          },
          {
            link: '#dep/entity/' + this.entityId + '/filing/' + this.filingId + '/dashboard',
            text: filingType + ' Filing'
          },
          {
            text: 'New Condition'
          }
        ]
      })
    );
    this.conditionForm = new DepConditionFormView({
      model: this.model
    });
    this.showChildView('conditionFormContainer', this.conditionForm);
  },

  onClickSubmit: function(event) {
    event.preventDefault();
    var self = this;
    var l = Ladda.create(this.ui.submit[0]);
    l.start();
    this.validate()
      .done(function() {
        var formData = self.getFormData();
        SubmitFilingCondition(formData)
          .done(function(filingConditionId) {
            Radio.channel('navigate').trigger('show:int:dp:condition', self.entityId, self.filingId, filingConditionId);
          })
          .fail(function() {
            l.stop();
          });
      })
      .fail(function() {
        l.stop();
      });
  },

  getFormData: function() {
    var formData = this.conditionForm.getFormData();
    formData.filingId = this.filingId;

    formData.dueDate = momentUtil.formatStr(formData.dueDate, momentUtil.defaultOutboundDateConfig);
    formData.receivedDate = momentUtil.formatStr(formData.receivedDate, momentUtil.defaultOutboundDateConfig);
    return formData;
  },

  validate: function() {
    return this.ui.conditionFormContainer.parsley().whenValidate();
  }
});
