/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

module.exports = function(formData) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      SubmitEntityExamRating: {
        $xmlns: 'http://dobs.pa.gov/dp/exam/bpm/1.0',
        ID: formData.id,
        SSRatingC: formData.ssRatingC,
        SSRatingA: formData.ssRatingA,
        SSRatingM: formData.ssRatingM,
        SSRatingE: formData.ssRatingE,
        SSRatingL: formData.ssRatingL,
        SSRatingS: formData.ssRatingS,
        SSRatingComposite: formData.ssRatingComposite,
        SSRatingBSAID: formData.ssRatingBSAId,
        ITRatingA: formData.itRatingA,
        ITRatingM: formData.itRatingM,
        ITRatingD: formData.itRatingD,
        ITRatingS: formData.itRatingS,
        ITRatingComposite: formData.itRatingComposite,
        TrustRatingM: formData.trustRatingM,
        TrustRatingO: formData.trustRatingO,
        TrustRatingE: formData.trustRatingE,
        TrustRatingC: formData.trustRatingC,
        TrustRatingA: formData.trustRatingA,
        TrustRatingComposite: formData.trustRatingComposite,
        ComplianceRatingComposite: formData.complianceRatingComposite,
        CRARatingID: formData.craRatingId,
        HCRatingComposite: formData.hcRatingComposite,
        HCRatingRisk: formData.hcRatingRisk,
        URSITRatingComposite: formData.ursitRatingComposite,
        URSITRatingITAudit: formData.ursitRatingITAudit,
        URSITRatingManagement: formData.ursitRatingManagement,
        URSITRatingDevAcq: formData.ursitRatingDevAcq,
        URSITRatingSupportDelivery: formData.ursitRatingSupportDelivery
      }
    },
    blueprint: {
      id: '$..EntityExamID'
    }
  })
    .done(function(response) {
      deferred.resolve(response.id);
    })
    .fail(function(err) {
      deferred.reject(err);
    });

  return deferred.promise();
};
