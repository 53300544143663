/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');

var Session = require('psa-core/Session');

var userHasRole = require('../../util/userHasRole');

require('./NoteEntryView.css');
var tmplNoteEntryView = require('./NoteEntryView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNoteEntryView,

  className: 'note-entry-view panel panel-default panel-row-view',

  ui: {
    editNote: '.edit-note',
    deleteNote: '.delete-note'
  },

  triggers: {
    'click @ui.editNote': 'edit:note',
    'click @ui.deleteNote': 'delete:note'
  },

  initialize: function(options) {
    this.managerRole = options.managerRole;
  },

  onBeforeShow: function() {
    if (userHasRole(this.managerRole)) {
      this.ui.editNote.show();
      this.ui.deleteNote.show();
    }
    if (this.model.get('createdBy') === Session.user.userName) {
      this.ui.editNote.show();
    }
  }
});
