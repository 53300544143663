/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Radio = require('backbone.radio');
var NProgress = require('nprogress');

var GetFilingDetails = require('../../common/services/GetFilingDetails');
var GetFilingInternalDetails = require('./GetFilingInternalDetails');
var GetAllFilingReviewLookups = require('./GetAllFilingReviewLookups');

module.exports = function(filingId) {
  var defer = $.Deferred();
  $.when(GetFilingDetails(filingId), GetFilingInternalDetails(filingId))
    .done(function(filingDetails, internalDetails) {
      NProgress.set(0.5);

      var filingFormIdentifier = filingDetails.formIdentifier;
      var filingTypeId = filingDetails.filingTypeId;
      var categoryId = filingDetails.entityCategoryId;

      filingDetails = _.extend(filingDetails, internalDetails);

      var promises = [
        // Filing Form Builder Promise
        Radio.channel('dp:filing').request('build:' + filingFormIdentifier, {
          filingId: filingId,
          filingTypeId: filingTypeId,
          entityCategoryId: filingDetails.entityCategoryId,
          isReadOnly: true
        }),
        GetAllFilingReviewLookups(categoryId)
      ];

      $.when
        .apply($, promises)
        .done(function(filingFormView, lookups) {
          filingFormView.model.set('filingDetails', filingDetails);
          filingFormView.model.set('lookups', lookups);
          var filingContent = filingFormView.model.get('filingContent');

          defer.resolve({
            filingDetails: filingDetails,
            filingFormView: filingFormView,
            filingContent: filingContent,
            lookups: lookups
          });
        })
        .fail(function(err) {
          defer.reject(err);
        });
    })
    .fail(function(err) {
      defer.reject(err);
    });
  return defer.promise();
};
