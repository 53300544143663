var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "    <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-3\">External Reply</label>\r\n      <div class=\"col-sm-3\">\r\n        <p class=\"form-control-static\">"
    + ((stack1 = (helpers["default"] || (depth0 && depth0["default"]) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.exam : depth0)) != null ? stack1.externalReplyComments : stack1),"--",{"name":"default","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</p>\r\n      </div>\r\n    </div>\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-3\">External Attachments</label>\r\n      <div class=\" col-sm-6\">\r\n        <div class=\"ext-attachments-container\"></div>\r\n      </div>\r\n    </div>\r\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "";
},"4":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "          <option value=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.action || (depth0 != null ? depth0.action : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"action","hash":{},"data":data}) : helper)))
    + "</option>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {});

  return "\r\n<div class=\"page-title clearfix\">\r\n  <h1 class=\"pull-left m-n p-n\">"
    + alias1(container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.task : depth0)) != null ? stack1.taskType : stack1)) != null ? stack1.description : stack1), depth0))
    + "</h1>\r\n</div>\r\n<div class=\"header-bar\"></div>\r\n<div class=\"breadcrumb-container\"></div>\r\n<div class=\"task-status\"></div>\r\n<div class=\"form-horizontal\">\r\n  <form class=\"resolve-exam-form\">\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-3\">Link to Exam Dashboard</label>\r\n      <div class=\"col-sm-9\">\r\n        <a href=\"#sec/exam/"
    + alias1(((helper = (helper = helpers.examId || (depth0 != null ? depth0.examId : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias2,{"name":"examId","hash":{},"data":data}) : helper)))
    + "/dashboard\" target=\"_blank\" class=\"btn btn-primary\">Open Exam\r\n          Dashboard</a>\r\n      </div>\r\n    </div>\r\n"
    + ((stack1 = helpers["if"].call(alias2,(depth0 != null ? depth0.showExternalReply : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    <div class=\"form-group required\">\r\n      <label class=\"control-label col-sm-3\">Action</label>\r\n      <div class=\"col-sm-3\">\r\n        <select name=\"actionId\" class=\"form-control action\" data-parsley-required=\"true\">\r\n          <option value=\"\">--</option>\r\n"
    + ((stack1 = helpers.each.call(alias2,(depth0 != null ? depth0.actions : depth0),{"name":"each","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </select>\r\n      </div>\r\n    </div>\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-3\">Internal Comments</label>\r\n      <div class=\"col-sm-6\">\r\n        <textarea class=\"form-control\" rows=\"5\" name=\"internalComments\" placeholder=\"Internal Comments\"></textarea>\r\n      </div>\r\n    </div>\r\n    <div class=\"need-additional-info collapse\">\r\n      <div class=\"form-group collapse missing-file\">\r\n        <div class=\"col-sm-offset-3 col-sm-6\">\r\n          <div class=\"alert alert-danger\" role=\"alert\">\r\n            <i class=\"fa fa-exclamation-triangle\" aria-hidden=\"true\"></i>\r\n            At least one document or comment must be provided.\r\n          </div>\r\n        </div>\r\n      </div>\r\n      <div class=\"form-group\">\r\n        <label class=\"control-label col-sm-3\">External Comments</label>\r\n        <div class=\"col-sm-6\">\r\n          <textarea class=\"form-control\" rows=\"5\" name=\"externalComments\" placeholder=\"External Comments\"></textarea>\r\n        </div>\r\n      </div>\r\n      <div class=\"form-group\">\r\n        <label class=\"control-label col-sm-3\">Subsequent Request Attachment</label>\r\n        <div class=\"col-sm-6\">\r\n          <div class=\"subsequent-request-container\"></div>\r\n        </div>\r\n      </div>\r\n    </div>\r\n  </form>\r\n  <div class=\"form-group\">\r\n    <div class=\"col-sm-9 col-sm-offset-3\">\r\n      <a href=\"#sec/dashboard\" class=\"btn btn-default\" role=\"button\">Cancel</a>\r\n      <button type=\"button\" class=\"btn btn-primary submit\">Submit</button>\r\n      <div class=\"task-actions\"></div>\r\n    </div>\r\n  </div>\r\n</div>";
},"useData":true});