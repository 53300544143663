/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var NProgress = require('nprogress');

var NavUtils = require('psa-core/util/nav');

var Scaffold = require('common/layout/Scaffold');

var GetTimesheet = require('../../common/services/GetTimesheet');
var GetTimeTrackingLookupsByDepartmentName = require('../../common/services/GetTimeTrackingLookupsByDepartmentName');
var GetAllOrgLicenseLookups = require('../../../securities/common/services/GetAllOrgLicenseLookups');
var GetAllIndLicenseLookups = require('../../../securities/common/services/GetAllIndLicenseLookups');

var TimeTrkPeriodSelectionView = require('../../common/views/TimeTrkPeriodSelectionView');

module.exports = function() {
  NProgress.start();
  NProgress.set(0.6);

  //Possible values: 2000 (Dep), 3000 (Sec)
  var secDptId = '3000';

  $.when(
    GetTimesheet({ departmentId: secDptId }),
    GetTimeTrackingLookupsByDepartmentName('SECURITIES'),
    GetAllIndLicenseLookups(),
    GetAllOrgLicenseLookups()
  ).done(function(timesheet, lookups, indLicenseLookups, orgLicenseLookups) {
    NavUtils.navigate('#sec/time-tracking');
    Scaffold.content.show(
      new TimeTrkPeriodSelectionView({
        model: new Backbone.Model({
          timesheetId: timesheet.timesheetId,
          payPeriod: timesheet.payPeriod,
          categories: lookups.timesheetCategories,
          timesheet: timesheet,
          periods: lookups.timesheetPeriods,
          years: lookups.timesheetYears,
          indLicenseTypes: indLicenseLookups.indLicenseTypes,
          orgLicenseTypes: orgLicenseLookups.orgLicenseType,
          dptId: secDptId
        })
      })
    );
    NProgress.done();
  });
};
