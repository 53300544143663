/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var modelGet = require('common/util/modelGet');

var tmplDepCallReportFormView = require('../templates/DepCallReportFormView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepCallReportFormView,

  className: 'dep-call-report-form-view',

  ui: {
    callReportYear: '.call-report-year',
    callReportDocument: '.call-report-document-container',
    assetInput: '.asset-input',
    totalManagedAssets: '.total-managed-assets',
    totalNonManagedAssets: '.total-non-managed-assets',
    custodySafekeepingAccounts: '.custody-safekeeping-accounts',
    totalAssetsUnderManagement: '.total-assets-under-management',
    totalTrustCompanyAssets: '.total-trust-company-assets',
    responseArea: '.response-area'
  },

  events: {
    'change @ui.assetInput': 'onChangeAssetInput'
  },

  onBeforeShow: function() {
    var totalAssetsUnderManagement = modelGet(this.model, 'filingContent.totalAssetsUnderManagement');
    this.ui.totalAssetsUnderManagement.val(totalAssetsUnderManagement);

    Syphon.deserialize(this, this.model.get('callReport'));

    this.onChangeAssetInput();
    this.ui.callReportYear.datepicker({
      endDate: '+0d',
      viewMode: 'years',
      changeYear: true,
      minViewMode: 'years',
      format: 'yyyy'
    });
  },

  onChangeAssetInput: function() {
    var totalAssets =
      Number(this.ui.totalManagedAssets.val()) +
      Number(this.ui.totalNonManagedAssets.val()) +
      Number(this.ui.custodySafekeepingAccounts.val());
    this.ui.totalAssetsUnderManagement.val(totalAssets);
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    formData.entityId = this.model.get('entityId');
    formData.callReportId = this.model.get('callReportId');

    var quarters = this.model.get('quarters');
    var reportQuarter =
      _.find(quarters, function(q) {
        return q.quarter === formData.reportQuarterEndId;
      }) || {};
    if (reportQuarter.quarterEnd && formData.reportYear) {
      formData.reportDate = reportQuarter.quarterEnd + '/' + formData.reportYear;
    }

    formData.totalAssetsUnderManagement =
      Number(this.ui.totalManagedAssets.val()) +
      Number(this.ui.totalNonManagedAssets.val()) +
      Number(this.ui.custodySafekeepingAccounts.val());

    return formData;
  }
});
