/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var parsleyUtil = require('common/util/parsleyUtil');
var makeUploadCollection = require('common/util/makeUploadCollection');
var momentUtil = require('common/util/momentUtil');

var CommentsDocumentView = require('common/views/CommentsDocumentView');

var tmplDepCreditUnionArticlesAmendmentRequiredInformationDocView = require('./DepCreditUnionArticlesAmendmentRequiredInformationDocView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepCreditUnionArticlesAmendmentRequiredInformationDocView,

  className: 'dep-credit-union-articles-amendment-required-information-doc-view',

  ui: {
    documentForm: '.document-form',
    articlesOfAmendment: '.articles-of-amendment-container',
    requestedEffectiveDate: '.requested-effective-date'
  },

  regions: {
    articlesOfAmendment: '@ui.articlesOfAmendment'
  },

  templateHelpers: function() {
    return {
      isReadOnly: this.options.isReadOnly
    };
  },

  onBeforeShow: function() {
    var filingContent = this.model.get('filingContent') || {};
    var articlesOfAmendment = filingContent.articlesOfAmendment || {};

    this.ui.requestedEffectiveDate.datepicker({
      startDate: '0d'
    });

    Syphon.deserialize(this, filingContent);

    // Articles of Amendment Document
    this.articlesOfAmendment = new CommentsDocumentView({
      isReadOnly: this.options.isReadOnly,
      allowMultipleFiles: false,
      isRequired: true,
      fileUploadLabel: 'Section III - 1. Articles of Amendment',
      commentsLabel: 'Section III - 1. Articles of Amendment Comments',
      commentsName: 'articlesOfAmendmentComments',
      collection: makeUploadCollection(articlesOfAmendment.file),
      model: new Backbone.Model({ articlesOfAmendmentComments: articlesOfAmendment.comments }),
      documentTypeId: 20001
    });
    this.showChildView('articlesOfAmendment', this.articlesOfAmendment);
  },

  validate: function(config) {
    this.ui.documentForm.parsley().reset();
    this.articlesOfAmendment.removeValidationErrors();

    if (config.type === 'save') {
      // Turn off required field validation for this form
      parsleyUtil.disableRequiredValidation(this.ui.documentForm);
    } else {
      var validationPromises = [this.ui.documentForm.parsley().whenValidate(), this.articlesOfAmendment.validate()];

      // Turn on required field validation for this form
      parsleyUtil.enableRequiredValidation(this.ui.documentForm);
    }
    return $.when.apply($, validationPromises);
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    formData.articlesOfAmendmentDocId = this.articlesOfAmendment.getFirstFileId();
    formData.requestedEffectiveDate = momentUtil.formatStr(
      formData.requestedEffectiveDate,
      momentUtil.defaultOutboundDateConfig
    );
    return formData;
  }
});
