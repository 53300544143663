/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');

var GetLicenseContactTypes = require('../services/GetLicenseContactTypes');
var GetInstallmentSellerApplication = require('../services/GetInstallmentSellerApplication');
var GetLicenseContactTypesByApplicationType = require('../services/GetLicenseContactTypesByApplicationType');
var GetLicenseContactsAndOfficersByEntity = require('../../common/services/GetLicenseContactsAndOfficersByEntity');
var GetApplicationDBAs = require('../services/GetApplicationDBAs');

var InstallmentSellerLicenseApplication = require('../forms/InstallmentSellerLicenseApplication');

module.exports = {
  event: 'build:license-new:installment-seller',

  channel: 'nd:application',

  builder: function(config) {
    var defer = $.Deferred();

    $.when(
      GetLicenseContactTypes(),
      GetInstallmentSellerApplication(config.applicationId),
      GetLicenseContactTypesByApplicationType(config.applicationTypeId),
      GetLicenseContactsAndOfficersByEntity(config.entityId),
      GetApplicationDBAs(config.applicationId)
    ).done(function(licenseContactTypes, applicationContent, requiredLicenseContactTypes, existingContacts, dbas) {
      var installmentSellerApplicationView = new InstallmentSellerLicenseApplication({
        isReadOnly: config.isReadOnly,
        mode: config.mode || 'new', // new, resubmit, print or int-expand
        identoGoLink: config.identoGoLink,
        model: new Backbone.Model({
          applicationId: config.applicationId,
          licenseContactTypes: licenseContactTypes,
          applicationContent: applicationContent,
          existingContacts: existingContacts,
          requiredLicenseContactTypes: requiredLicenseContactTypes,
          entityDBAs: dbas.entityDBAs,
          applicationDBAs: dbas.appDBAs
        })
      });
      defer.resolve(installmentSellerApplicationView);
    });
    return defer.promise();
  }
};
