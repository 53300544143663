/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backgrid = require('backgrid');

var tmplLinkCell = require('./LinkCell.hbs');

module.exports = Backgrid.LinkCell = Backgrid.Cell.extend({
  className: 'link-cell',

  render: function() {
    var href;
    if (this.href) {
      href = Backgrid.callByNeed(this.href, this, this.model);
    }

    this.$el.addClass(this.column.get('name'));
    var emptyText = this.column.get('emptyText') ? this.column.get('emptyText') : '--';
    this.$el.empty().append(
      tmplLinkCell({
        value: this.model.get(this.column.get('name')),
        href: href,
        newTab: this.newTab,
        emptyText: emptyText
      })
    );

    this.delegateEvents();
    return this;
  }
});
