var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=helpers.helperMissing, alias5="function";

  return "\r\n<div class=\"title-bar clearfix\">\r\n  <h1 class=\"page-title\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.enforcementIdentifiers : depth0)) != null ? stack1.entityName : stack1), depth0))
    + "</h1>\r\n</div>\r\n<div class=\"page-subtitle\">\r\n  <h2>Enforcement Action</h2>\r\n  <div class=\"page-subtitle-details\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.enforcementIdentifiers : depth0)) != null ? stack1.action : stack1), depth0))
    + " - Effective as of "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.enforcementIdentifiers : depth0)) != null ? stack1.effectiveDate : stack1), depth0))
    + "</div>\r\n</div>\r\n<div class=\"breadcrumb-container\"></div>\r\n<div class=\"row m-b-xs\">\r\n  <div class=\"col-sm-12 col-md-10 col-md-offset-1\">\r\n    <div class=\"form-group\">\r\n      <div class=\"col-sm-9 col-md-8 col-md-offset-2 col-sm-offset-3\">\r\n        <h3 class=\"m-t-n\">New Provision Details</h3>\r\n      </div>\r\n    </div>\r\n  </div>\r\n</div>\r\n<form class=\"provision-form-container\"></form>\r\n<div class=\"row m-b-lg\">\r\n  <div class=\"col-sm-12 col-md-10 col-md-offset-1\">\r\n    <div class=\"form-group\">\r\n      <div class=\"col-sm-9 col-md-8 col-md-offset-2 col-sm-offset-3\">\r\n        <a href=\"#dep/entity/"
    + alias2(((helper = (helper = helpers.entityId || (depth0 != null ? depth0.entityId : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"entityId","hash":{},"data":data}) : helper)))
    + "/enforcement/"
    + alias2(((helper = (helper = helpers.enforcementId || (depth0 != null ? depth0.enforcementId : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"enforcementId","hash":{},"data":data}) : helper)))
    + "/dashboard\" class=\"btn btn-default\">Cancel</a>\r\n        <button class=\"btn btn-primary btn-submit\">Submit</button>\r\n      </div>\r\n    </div>\r\n  </div>\r\n</div>";
},"useData":true});