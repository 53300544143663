/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Radio = require('backbone.radio');
var Ladda = require('ladda');

var BreadcrumbView = require('common/views/BreadcrumbView');
var HeaderBarView = require('common/views/HeaderBarView');
var AddressView = require('common/views/AddressView');

var SubmitIndividualAddress = require('../services/SubmitIndividualAddress');

var tmplSecIndividualAddressEditView = require('../templates/SecIndividualAddressEditView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplSecIndividualAddressEditView,

  className: 'sec-individual-address-edit-view container-fluid',

  ui: {
    headerBar: '.header-bar-container',
    breadcrumb: '.breadcrumb-container',
    addressForm: '.address-form',
    addresContainer: '.address-container',
    submit: '.submit'
  },

  regions: {
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb',
    addresContainer: '@ui.addresContainer'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  initialize: function() {
    var addressType = this.model.get('addressType') || [];

    addressType = _.filter(addressType, function(type) {
      return type.id !== '5';
    });

    this.model.set('addressType', addressType);
  },

  onBeforeShow: function() {
    var individual = this.model.get('individual') || {};
    var address = this.model.get('address') || {};

    var individualId = this.model.get('individualId');

    individual.title = 'Individual';
    var headerBarColumns = [
      {
        name: 'crdNumber',
        label: 'CRD Number',
        minWidth: '160px',
        maxWidth: '200px'
      },
      {
        name: 'lastName',
        label: 'Last Name',
        minWidth: '160px',
        maxWidth: '200px'
      },
      {
        name: 'firstName',
        label: 'First Name',
        minWidth: '160px',
        maxWidth: '200px'
      },
      {
        name: 'middleInitial',
        label: 'Middle Initial',
        minWidth: '160px',
        maxWidth: '200px'
      }
    ];

    this.showChildView(
      'headerBar',
      new HeaderBarView({
        columns: new Backbone.Collection(headerBarColumns),
        model: new Backbone.Model(individual)
      })
    );

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#sec/dashboard',
            text: 'Home'
          },
          {
            link: '#sec/individual/' + individualId + '/dashboard',
            text: individual.dbaName
          },
          {
            text: 'Address'
          }
        ]
      })
    );

    this.addressForm = new AddressView({
      model: new Backbone.Model(address),
      horizontal: true,
      isRequired: true,
      startingCountry: address.country,
      startingPostalCode: address.postalCode,
      startingCounty: address.county
    });
    this.showChildView('addresContainer', this.addressForm);

    Syphon.deserialize(this, address);
  },

  onClickSubmit: function(event) {
    event.preventDefault();
    var self = this;
    this.validate().done(function() {
      var l = Ladda.create(self.ui.submit[0]);
      l.start();
      var formData = self.getFormData();
      SubmitIndividualAddress(formData)
        .done(function() {
          Radio.channel('navigate').trigger('show:int:sc:individual-dashboard', self.model.get('individualId'));
        })
        .fail(function() {
          l.stop();
        });
    });
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    formData.individualId = this.model.get('individualId');
    formData.individualAddressId = this.model.get('individualAddressId');
    return formData;
  },

  validate: function() {
    return this.ui.addressForm.parsley().whenValidate();
  }
});
