var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return "      <a href=\"#nondep/entity/"
    + container.escapeExpression(((helper = (helper = helpers.entityId || (depth0 != null ? depth0.entityId : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"entityId","hash":{},"data":data}) : helper)))
    + "/dashboard\" class=\"btn btn-default\" role=\"button\">Cancel</a>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper;

  return "      <a href=\"#ext/nondep/entity/"
    + container.escapeExpression(((helper = (helper = helpers.entityId || (depth0 != null ? depth0.entityId : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"entityId","hash":{},"data":data}) : helper)))
    + "/dashboard\" class=\"btn btn-default\" role=\"button\">Cancel</a>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "\r\n<div class=\"title-bar clearfix\">\r\n  <h1 class=\"page-title\">"
    + container.escapeExpression(((helper = (helper = helpers.entityName || (depth0 != null ? depth0.entityName : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"entityName","hash":{},"data":data}) : helper)))
    + "</h1>\r\n</div>\r\n<div class=\"header-bar-container\"></div>\r\n<div class=\"breadcrumb-container\"></div>\r\n\r\n<h2 class=\"m-t-n\">Add New Location</h2>\r\n\r\n<div class=\"col-sm-8 col-sm-offset-1\">\r\n  <form class=\"new-location-container\"></form>\r\n  <div class=\"btn-container col-sm-9 col-sm-offset-3 m-b-lg\">\r\n"
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias2).call(alias1,(depth0 != null ? depth0.viewMode : depth0),"===","int",{"name":"ifCond","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "    <button class=\"btn btn-primary submit-button ladda-button\" data-style=\"expand-right\">\r\n      <span class=\"ladda-label\">Submit</span>\r\n    </button>\r\n  </div>\r\n</div>";
},"useData":true});