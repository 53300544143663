/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function() {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetNextStepsByAssignee: {
        $xmlns: 'http://dobs.pa.gov/sc/nextstep/bpm/1.0'
      }
    },
    blueprintArrays: ['GetNextStepsByAssigneeResponse.SC_NextStep'],
    blueprint: {
      nextSteps: [
        '$..SC_NextStep',
        {
          id: '$.ID',
          isOrganization: '$.IsOrganization',
          objectId: '$.ObjectID',
          nextStepDescription: '$.NextStepDescription',
          nextStepType: '$.NextStepType',
          nextStepProjectedDate: '$.NextStepProjectedDate',
          modifiedDate: '$.ModifiedDate',
          nextStepAssignedTo: '$.NextStepAssignedTo'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      _.each(response.nextSteps, function(step) {
        if (step.nextStepProjectedDate) {
          step.nextStepProjectedDate = moment(step.nextStepProjectedDate, 'YYYY-MM-DD');
        }
        if (step.modifiedDate) {
          step.modifiedDate = moment(step.modifiedDate, moment.ISO_8601);
        }
      });

      deferred.resolve(response.nextSteps);
    })
    .fail(function(err) {
      deferred.reject(err);
    });

  return deferred.promise();
};
