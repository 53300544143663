/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(licenseId) {
  var defer = $.Deferred();

  $.soap({
    body: {
      GetLicenseHistory: {
        $xmlns: 'http://dobs.pa.gov/nd/license/bpm/1.0',
        LicenseID: licenseId
      }
    },
    blueprintArrays: [
      'GetLicenseHistoryResponse.ContactHistory.ND_LicenseContactHistory',
      'GetLicenseHistoryResponse.ContactHistoryMigration.ND_LicenseContactHistoryMigration',
      'GetLicenseHistoryResponse.AddressHistory.ND_LicenseAddressHistory',
      'GetLicenseHistoryResponse.OfficeManagerHistory.ND_LicenseOfficeManagerHistory',
      'GetLicenseHistoryResponse.BondHistory.ND_LicenseBond'
    ],
    blueprint: {
      contactHistory: [
        '$..ND_LicenseContactHistory',
        {
          id: '$..ID',
          licenseId: '$..LicenseID',
          createdDate: '$..CreatedDate',
          createdBy: '$..CreatedBy'
        }
      ],
      contactHistoryMigration: [
        '$..ND_LicenseContactHistoryMigration',
        {
          id: '$..ID',
          licenseId: '$..LicenseID',
          firstName: '$..FirstName',
          lastName: '$..LastName',
          title: '$..Title',
          licenseContactTypeId: '$..LicenseContactTypeID',
          licenseContactType: '$..LicenseContactType',
          address: {
            addressId: '$..AddressID',
            addressLine1: '$..AddressLine1',
            addressLine2: '$..AddressLine2',
            addressLine3: '$..AddressLine3',
            county: '$..County',
            city: '$..City',
            state: '$..State',
            postalCode: '$..PostalCode',
            country: '$..Country'
          },
          company: '$..Company',
          workPhoneNumber: '$..WorkPhoneNumber',
          fax: '$..Fax',
          createdBy: '$..CreatedBy',
          createdDate: '$..CreatedDate'
        }
      ],
      addressHistory: [
        '$..ND_LicenseAddressHistory',
        {
          id: '$..ID',
          licenseId: '$..LicenseID',
          address: {
            addressId: '$..AddressID',
            addressLine1: '$..AddressLine1',
            addressLine2: '$..AddressLine2',
            addressLine3: '$..AddressLine3',
            county: '$..County',
            city: '$..City',
            state: '$..State',
            postalCode: '$..PostalCode',
            country: '$..Country'
          },
          createdDate: '$..CreatedDate',
          createdBy: '$..CreatedBy'
        }
      ],
      bondHistory: [
        '$..ND_LicenseBond',
        {
          id: '$..BondID',
          entityId: '$..EntityID',
          licenseId: '$..LicenseID',
          bondCompanyId: '$..BondCompanyID',
          bondNumber: '$..BondNumber',
          bondAmount: '$..BondAmount',
          expiration: '$..Expiration',
          requiredTo: '$..RequiredTo',
          licenseRequirement: '$..LicenseRequirement',
          typeId: '$..TypeID',
          statusId: '$..StatusID',
          type: '$..Type',
          status: '$..Status',
          name: '$..Name',
          modifiedBy: '$..ModifiedBy',
          modifiedDate: '$..ModifiedDate'
        }
      ],
      officeManagerHistory: [
        '$..ND_LicenseOfficeManagerHistory',
        {
          id: '$..ID',
          licenseId: '$..LicenseID',
          officeManagerIndividualId: '$..OfficeManagerIndividualID',
          createdDate: '$..CreatedDate',
          createdBy: '$..CreatedBy',
          firstName: '$..FirstName',
          lastName: '$..LastName',
          email: '$..Email',
          address: {
            addressId: '$..AddressID',
            addressLine1: '$..AddressLine1',
            addressLine2: '$..AddressLine2',
            addressLine3: '$..AddressLine3',
            county: '$..County',
            city: '$..City',
            state: '$..State',
            postalCode: '$..PostalCode',
            country: '$..Country'
          }
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      defer.resolve(response);
    })
    .fail(function(err) {
      defer.reject();
    });

  return defer;
};
