/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var _get = require('lodash.get');
var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function() {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetSelfServiceDashboard: {
        $xmlns: 'http://dobs.pa.gov/sc/dashboard/bpm/1.0'
      }
    },
    blueprintArrays: [
      'GetSelfServiceDashboardResponse.Organizations.SC_Organization',
      'GetSelfServiceDashboardResponse.Tasks.Tasks.NOTF_TASK_INSTANCE',
      'GetSelfServiceDashboardResponse.Tasks.TaskTypes.CM_LU_TaskType'
    ],
    blueprint: {
      organizations: [
        '$..SC_Organization',
        {
          id: '$.ID',
          applicantLegalName: '$.ApplicantLegalName',
          CRDNumber: '$.CRDNumber'
        }
      ],
      tasks: [
        '$..NOTF_TASK_INSTANCE',
        {
          taskId: '$..TaskId',
          deliveryDate: '$..DeliveryDate',
          oganizationId: '$..SCOrganizationID',
          organizationLicenseId: '$..SCOrganizationLicenseID',
          taskTypeName: '$..TaskTypeName',
          dueDate: '$..DueDate'
        }
      ],
      taskMetaData: {
        taskTypes: [
          '$..CM_LU_TaskType',
          {
            id: '$.ID',
            name: '$.Name',
            description: '$.Description'
          }
        ]
      }
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      _.each(response.tasks, function(task) {
        var oganizationId = _get(task, 'oganizationId', {});
        var taskTypeName = _get(task, 'taskTypeName', {});

        if (taskTypeName.__text) {
          taskTypeName = taskTypeName.__text;
        }

        if (oganizationId.__text) {
          oganizationId = oganizationId.__text;
        }

        task.deliveryDate = moment.utc(task.deliveryDate, moment.ISO_8601).local();

        var type =
          _.find(response.taskMetaData.taskTypes, function(type) {
            return taskTypeName === type.name;
          }) || {};

        var org = _.find(response.organizations, function(org) {
          return oganizationId === org.id;
        });
        if (org) {
          task.applicantLegalName = org.applicantLegalName;
        }

        type = type ? type : '';
        task.type = type.description;

        task.applicantLegalName = task.applicantLegalName || '--';
        task.dueDate = task.dueDate || '--';
      });

      delete response.taskMetaData;
      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
