var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "\r\n<div class=\"panel-body\">\r\n  <div class=\"row\">\r\n    <div class=\"col-sm-9\">\r\n      <p><strong>"
    + alias4(((helper = (helper = helpers.modifiedBy || (depth0 != null ? depth0.modifiedBy : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"modifiedBy","hash":{},"data":data}) : helper)))
    + "</strong>, last edited on "
    + alias4(((helper = (helper = helpers.editDateTime || (depth0 != null ? depth0.editDateTime : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"editDateTime","hash":{},"data":data}) : helper)))
    + "</p>\r\n      <p><strong>"
    + alias4(((helper = (helper = helpers.type || (depth0 != null ? depth0.type : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"type","hash":{},"data":data}) : helper)))
    + "</strong></p>\r\n      <p class=\"note-content\">"
    + alias4(((helper = (helper = helpers.content || (depth0 != null ? depth0.content : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"content","hash":{},"data":data}) : helper)))
    + "</p>\r\n    </div>\r\n    <div class=\"col-sm-3 text-right\">\r\n      <button class=\"btn btn-primary edit-note collapse\">Edit</button>\r\n      <button class=\"btn btn-danger delete-note collapse\">Delete</button>\r\n    </div>\r\n  </div>\r\n</div>\r\n";
},"useData":true});