/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Radio = require('backbone.radio');
var Ladda = require('ladda');

var UIUtil = require('psa-core/util/ui');

var ContentServerLinkView = require('common/views/ContentServerLinkView');
var BreadcrumbView = require('common/views/BreadcrumbView');
var HeaderBarView = require('common/views/HeaderBarView');
var ConfirmationModalView = require('common/views/ConfirmationModalView');

var GetIndividualCRDNumberExists = require('../services/GetIndividualCRDNumberExists');
var SubmitIndividual = require('../services/SubmitIndividual');
var SecIndividualGeneralFormView = require('./SecIndividualGeneralFormView');

var tmplSecIndividualGeneralEditView = require('../templates/SecIndividualGeneralEditView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplSecIndividualGeneralEditView,

  className: 'sec-individual-general-edit-view container-fluid',

  ui: {
    headerBar: '.header-bar-container',
    breadcrumb: '.breadcrumb-container',
    individualGeneralForm: '.individual-general-form',
    submit: '.submit',
    csLinkContainer: '.cs-link-container'
  },

  regions: {
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb',
    individualGeneralForm: '@ui.individualGeneralForm',
    csLinkContainer: '@ui.csLinkContainer'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeShow: function() {
    var individual = this.model.get('individual') || {};
    var individualId = this.model.get('individualId');

    individual.title = 'Individual';
    var headerBarColumns = [
      {
        name: 'crdNumber',
        label: 'CRD Number',
        minWidth: '160px',
        maxWidth: '200px'
      },
      {
        name: 'lastName',
        label: 'Last Name',
        minWidth: '160px',
        maxWidth: '200px'
      },
      {
        name: 'firstName',
        label: 'First Name',
        minWidth: '160px',
        maxWidth: '200px'
      },
      {
        name: 'middleInitial',
        label: 'Middle Initial',
        minWidth: '160px',
        maxWidth: '200px'
      }
    ];

    this.showChildView(
      'headerBar',
      new HeaderBarView({
        columns: new Backbone.Collection(headerBarColumns),
        model: new Backbone.Model(individual)
      })
    );

    this.showChildView(
      'csLinkContainer',
      new ContentServerLinkView({
        model: new Backbone.Model({
          buttonText: 'Open Individual Folder',
          contentServerId: individual.CSFolderId
        })
      })
    );

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#sec/dashboard',
            text: 'Home'
          },
          {
            link: '#sec/individual/' + individualId + '/dashboard',
            text: individual.dbaName
          },
          {
            text: 'Edit Individual'
          }
        ]
      })
    );

    this.showChildView(
      'individualGeneralForm',
      new SecIndividualGeneralFormView({ model: new Backbone.Model(individual), mode: 'edit' })
    );
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;
    var individualId = this.model.get('individualId');
    var formData = this.getFormData();

    this.validate().done(function() {
      var spinner = Ladda.create(self.ui.submit.get(0));
      spinner.start();

      GetIndividualCRDNumberExists(formData)
        .done(function(individualCRDNumberExists) {
          if (individualCRDNumberExists) {
            spinner.stop();
            self.showDuplicateCRDConfirmationModal();
          } else {
            SubmitIndividual(formData)
              .done(function() {
                Radio.channel('navigate').trigger('show:int:sc:individual-dashboard', individualId);
              })
              .fail(function() {
                spinner.stop();
              });
          }
        })
        .fail(function() {
          spinner.stop();
        });
    });
  },

  validate: function() {
    return this.ui.individualGeneralForm.parsley().whenValidate();
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    formData.individualId = this.model.get('individualId');
    return formData;
  },

  showDuplicateCRDConfirmationModal: function() {
    var formData = this.getFormData();
    var crdNumber = formData.crdNumber;
    var name = formData.firstName + ' ' + formData.lastName;

    return UIUtil.showModal({
      title: 'Duplicate CRD Number ' + crdNumber,
      view: new ConfirmationModalView({
        confirmButtonClass: 'collapse',
        rejectText: 'Close',
        model: new Backbone.Model({
          message:
            'Another individual already exists with CRD Number ' +
            crdNumber +
            ' and applicant name ' +
            name +
            '. Please ensure that the individual you are attempting to give a new CRD Number to does not already exist before proceeding.'
        })
      })
    });
  }
});
