/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');

var ContentServerLinkView = require('common/views/ContentServerLinkView');
var SideNavSectionView = require('common/views/SideNavSectionView');
var BreadcrumbView = require('common/views/BreadcrumbView');
var modelGet = require('common/util/modelGet');

var NonDepEntityNameHistoryView = require('./NonDepEntityNameHistoryView');
var NonDepEntityDBAHistoryView = require('./NonDepEntityDBAHistoryView');
var NonDepEntityOfficerHistoryView = require('./NonDepEntityOfficerHistoryView');
var NonDepEntitySiteRecordkeepingHistoryView = require('./NonDepEntitySiteRecordkeepingHistoryView');

var EntityHeaderBarView = require('../../common/views/EntityHeaderBarView');

var tmplNonDepEntityHistoryDashboardView = require('../templates/NonDepEntityHistoryDashboardView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepEntityHistoryDashboardView,

  className: 'nondep-entity-dashboard-view container-fluid',

  ui: {
    headerBar: '.header-bar-container',
    breadcrumb: '.breadcrumb-container',
    csLinkContainer: '.cs-link-container',
    entityHistoryContainer: '.entity-history-container'
  },

  regions: {
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb',
    csLinkContainer: '@ui.csLinkContainer',
    entityHistoryContainer: '@ui.entityHistoryContainer'
  },

  onBeforeShow: function() {
    var entityIdentifiers = this.model.get('entityIdentifiers') || {};

    this.showChildView(
      'headerBar',
      new EntityHeaderBarView({
        model: new Backbone.Model(entityIdentifiers)
      })
    );

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#nondep/dashboard',
            text: 'Home'
          },
          {
            link: '#nondep/entity/' + entityIdentifiers.id + '/dashboard',
            text: entityIdentifiers.entityName
          },
          {
            text: 'History'
          }
        ]
      })
    );

    this.showChildView(
      'csLinkContainer',
      new ContentServerLinkView({
        model: new Backbone.Model({
          buttonText: 'Open Entity Folder',
          contentServerId: entityIdentifiers.csFolderId
        })
      })
    );

    var sideNavTabs = [
      {
        id: 'nameHistory',
        title: 'Name History',
        view: new NonDepEntityNameHistoryView({
          model: new Backbone.Model({
            nameHistory: modelGet(this.model, 'entityHistory.nameHistory')
          })
        })
      },
      {
        id: 'dbaHistory',
        title: 'DBA History',
        view: new NonDepEntityDBAHistoryView({
          model: new Backbone.Model({
            dbaHistory: modelGet(this.model, 'entityHistory.dbaHistory')
          })
        })
      },
      {
        id: 'officerHistory',
        title: 'Officer History',
        view: new NonDepEntityOfficerHistoryView({
          model: new Backbone.Model({
            officerHistory: modelGet(this.model, 'entityHistory.officerHistory')
          })
        })
      },
      {
        id: 'siteRecordkeepingHistory',
        title: 'Books and Records History',
        view: new NonDepEntitySiteRecordkeepingHistoryView({
          model: new Backbone.Model({
            siteRecordkeepingHistory: modelGet(this.model, 'entityHistory.siteRecordkeepingHistory')
          })
        })
      }
    ];

    this.dashboard = new SideNavSectionView({
      collection: new Backbone.Collection(sideNavTabs)
    });
    this.showChildView('entityHistoryContainer', this.dashboard);
  }
});
