/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var NProgress = require('nprogress');

var NavUtils = require('psa-core/util/nav');

var Scaffold = require('common/layout/Scaffold');

var GetEntityIdentifiers = require('../../common/services/GetEntityIdentifiers');
var GetFilingIdentifiers = require('../services/GetFilingIdentifiers');
var GetFilingCondition = require('../services/GetFilingCondition');

var DepConditionDashboardView = require('../views/DepConditionDashboardView');

module.exports = function(entityId, filingId, conditionId) {
  NProgress.start();
  NProgress.set(0.6);
  $.when(GetEntityIdentifiers(entityId), GetFilingCondition(conditionId), GetFilingIdentifiers(filingId))
    .done(function(entityIdentifiers, condition, filingIdentifiers) {
      NavUtils.navigate('dep/entity/' + entityId + '/filing/' + filingId + '/condition/' + conditionId + '/dashboard');
      Scaffold.content.show(
        new DepConditionDashboardView({
          model: new Backbone.Model({
            condition: condition,
            entityIdentifiers: entityIdentifiers,
            filingIdentifiers: filingIdentifiers
          })
        })
      );
      NProgress.done();
    })
    .fail(function() {
      NProgress.done();
    });
};
