/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(categoryId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetAllLocationActivitiesFilingLookups: {
        $xmlns: 'http://dobs.pa.gov/dp/lookup/bpm/1.0'
      }
    },
    blueprintArrays: [
      'GetAllLocationActivitiesFilingLookups.ActionTypes.DP_LU_LocationActionType',
      'GetAllLocationActivitiesFilingLookups.LocationTypes.DP_LU_LocationType'
    ],
    blueprint: {
      actionTypes: [
        '$..DP_LU_LocationActionType',
        {
          id: '$..ID',
          locationAction: '$..LocationAction'
        }
      ],
      locationTypes: [
        '$..DP_LU_LocationType',
        {
          id: '$..ID',
          locationType: '$..LocationType',
          categoryId: '$..CategoryID'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      response.locationTypes = _.filter(response.locationTypes, function(locationType) {
        return locationType.categoryId === categoryId;
      });

      // Don't include option to convert a location if credit union
      // (action type id = 3)
      var isCU = categoryId === '2';
      if (isCU) {
        response.actionTypes = _.filter(response.actionTypes, function(actionType) {
          return actionType.id !== '3';
        });
      }

      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
