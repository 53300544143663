/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var NProgress = require('nprogress');

var NavUtils = require('psa-core/util/nav');

var Scaffold = require('common/layout/Scaffold');

var GetNonDepAllTimesheets = require('../services/GetNonDepAllTimesheets');
var GetTimeTrackingLookupsByDepartmentName = require('../../common/services/GetTimeTrackingLookupsByDepartmentName');
var TimeTrkNonDepMTEntriesView = require('../views/TimeTrkNonDepMTEntriesView');

module.exports = function() {
  NProgress.start();
  NProgress.set(0.6);
  $.when(GetNonDepAllTimesheets(), GetTimeTrackingLookupsByDepartmentName('NON_DEPOSITORY'))
    .done(function(timesheets, timeTrackingLookups) {
      Scaffold.content.show(
        new TimeTrkNonDepMTEntriesView({
          model: new Backbone.Model({
            timesheets: timesheets,
            timesheetYears: timeTrackingLookups.timesheetYears,
            timesheetPeriods: timeTrackingLookups.timesheetPeriods,
            timesheetStatuses: timeTrackingLookups.timesheetStatuses
          })
        })
      );
      NavUtils.navigate('nondep/time-tracking/view');
      NProgress.done();
    })
    .fail(function() {
      NProgress.done();
    });
};
