/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Ladda = require('ladda');
var Radio = require('backbone.radio');

var modelGet = require('common/util/modelGet');
var momentUtil = require('common/util/momentUtil');
var BreadcrumbView = require('common/views/BreadcrumbView');

var SubmitAnnualMeeting = require('../services/SubmitAnnualMeeting');
var DepAnnualMeetingFormView = require('./DepAnnualMeetingFormView');

var tmplDepAnnualMeetingEditView = require('../templates/DepAnnualMeetingEditView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepAnnualMeetingEditView,

  className: 'dep-annual-meeting-edit-view container-fluid',

  ui: {
    breadcrumb: '.breadcrumb-container',
    annualMeetingContainer: '.annual-meeting-form-container',
    submit: '.submit'
  },

  regions: {
    breadcrumb: '@ui.breadcrumb',
    annualMeetingContainer: '@ui.annualMeetingContainer'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeShow: function() {
    var annualMeeting = this.model.get('annualMeeting') || {};
    this.annualMeetingId = annualMeeting.annualMeetingId;
    this.entityId = annualMeeting.entityId;
    var entityName = modelGet(this.model, 'entityIdentifiers.entityName');

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#dep/dashboard',
            text: 'Home'
          },
          {
            link: '#dep/entity/' + this.entityId + '/dashboard',
            text: entityName
          },
          {
            text: 'Edit Governance'
          }
        ]
      })
    );

    this.annualMeetingForm = new DepAnnualMeetingFormView({
      model: this.model
    });
    this.showChildView('annualMeetingContainer', this.annualMeetingForm);
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;
    this.validate().done(function() {
      var l = Ladda.create(self.ui.submit[0]);
      l.start();
      var formData = self.getFormData();
      SubmitAnnualMeeting(formData)
        .done(function() {
          Radio.channel('navigate').trigger('show:int:dp:entity', self.entityId);
        })
        .fail(function() {
          l.stop();
        });
    });
  },

  getFormData: function() {
    var formData = this.annualMeetingForm.getFormData();

    formData.annualMeetingId = this.annualMeetingId;
    formData.entityId = this.entityId;

    formData.meetingDate = momentUtil.formatStr(formData.meetingDate, momentUtil.defaultOutboundDateConfig);
    formData.receivedDate = momentUtil.formatStr(formData.receivedDate, momentUtil.defaultOutboundDateConfig);
    formData.acceptedDate = momentUtil.formatStr(formData.acceptedDate, momentUtil.defaultOutboundDateConfig);

    return formData;
  },

  validate: function() {
    return this.ui.annualMeetingContainer.parsley().whenValidate();
  }
});
