/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');

var callByNeed = require('common/util/callByNeed');

var tmplBreadcrumbView = require('./BreadcrumbView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplBreadcrumbView,

  tagName: 'ol',

  className: 'breadcrumb-view breadcrumb',

  templateHelpers: function() {
    var breadcrumbs = callByNeed(this.options.breadcrumbs);
    _.each(breadcrumbs, function(breadcrumb) {
      if (!_.has(breadcrumb, 'renderable')) {
        breadcrumb.renderable = true;
      } else {
        breadcrumb.renderable = callByNeed(breadcrumb.renderable);
      }
    });
    return {
      breadcrumbs: breadcrumbs
    };
  }
});
