var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"well well-sm review-comments-container\">\r\n  <h4 class=\"m-t-n\">\r\n    <i class=\"fa fa-university\" aria-hidden=\"true\"></i> Application Review Comments\r\n  </h4>\r\n  <p class=\"review-comments m-n\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.applicationDetails : depth0)) != null ? stack1.externalReviewComments : stack1), depth0))
    + "</p>\r\n</div>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "\r\n<h1 class=\"page-title\">Submit "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.applicationDetails : depth0)) != null ? stack1.applicationTypeDescription : stack1), depth0))
    + "</h1>\r\n<div class=\"header-bar\"></div>\r\n<div class=\"breadcrumb-container\"></div>\r\n\r\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.applicationDetails : depth0)) != null ? stack1.externalReviewComments : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "<div class=\"application-container\"></div>\r\n";
},"useData":true});