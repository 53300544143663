var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=helpers.helperMissing, alias5="function";

  return "\r\n<div class=\"title-bar clearfix\">\r\n  <h1 class=\"page-title\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.entityIdentifiers : depth0)) != null ? stack1.entityName : stack1), depth0))
    + "</h1>\r\n</div>\r\n<div class=\"page-subtitle\">\r\n  <h2>Location</h2>\r\n  <div class=\"page-subtitle-details\">Opened on "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.location : depth0)) != null ? stack1.openedDate : stack1), depth0))
    + " - "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.location : depth0)) != null ? stack1.status : stack1), depth0))
    + "</div>\r\n</div>\r\n<div class=\"breadcrumb-container\"></div>\r\n<div class=\"col-sm-9 col-sm-offset-1 m-b-md\">\r\n  <div class=\"row\">\r\n    <div class=\"col-sm-9 col-sm-offset-3\">\r\n      <h3 class=\"m-t-n\">Edit Location</h3>\r\n    </div>\r\n  </div>\r\n  <form class=\"location-form-container\"></form>\r\n  <div class=\"row\">\r\n    <div class=\"col-sm-9 col-sm-offset-3\">\r\n      <a href=\"#dep/entity/"
    + alias2(((helper = (helper = helpers.entityId || (depth0 != null ? depth0.entityId : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"entityId","hash":{},"data":data}) : helper)))
    + "/location/"
    + alias2(((helper = (helper = helpers.locationId || (depth0 != null ? depth0.locationId : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"locationId","hash":{},"data":data}) : helper)))
    + "\" class=\"btn btn-default\">Cancel</a>\r\n      <button class=\"btn btn-primary btn-submit ladda-button\" data-style=\"expand-right\" type=\"button\">\r\n        <span class=\"ladda-label\">Submit</span>\r\n      </button>\r\n    </div>\r\n  </div>\r\n</div>";
},"useData":true});