/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Config = require('./config/Config');

var isProduction = ENVIRONMENT === 'prod.ext';

if (isProduction) {
  var host = window.location.origin;
  var internalHost = Config.getInternalHost();
  if (host === internalHost) {
    var externalUrl = Config.getExternalUrl();
    window.location = externalUrl;
  }
}

require('./app.common');

var _ = require('underscore');
var Cookie = require('js-cookie');
var Backbone = require('backbone');
var Radio = require('backbone.radio');
var NProgress = require('nprogress');

var NavUtils = require('psa-core/util/nav');
var Session = require('psa-core/Session');
var clearAuthenticationCookies = require('psa-authentication/ClearAuthenticationCookies');

var GetExternalUserDetails = require('common/services/GetExternalUserDetails');
var clearExternalUserCookies = require('./common/services/ClearExternalUserCookies');
var GetConfigurations = require('./common/services/GetConfigurations');
var InactivityTimer = require('./common/util/InactivityTimer');
var Scaffold = require('./common/layout/Scaffold');
var HandleKeyStoneAuthentication = require('common/services/HandleKeyStoneAuthentication');
var showKeyStoneLogin = require('./common/login/commands/ShowKeyStoneLogin');

var getURLParams = function() {
  return _.chain(location.search.slice(1).split('&'))
    .map(function(item) {
      if (item) {
        return item.split('=');
      }
    })
    .compact()
    .object()
    .value();
};

if (ENVIRONMENT === 'local.ext') {
  var keystoneLoginCookieURLParam = getURLParams().KeystoneLogin;
  if (!_.isUndefined(keystoneLoginCookieURLParam) && keystoneLoginCookieURLParam !== '') {
    Cookie.set('KeystoneLogin', keystoneLoginCookieURLParam);
    window.history.pushState('', '', '/#ext/applications');
  }
}

if (!_.isUndefined(Cookie.get('KeystoneLogin')) && Cookie.get('KeystoneLogin') !== '') {
  HandleKeyStoneAuthentication()
    .done(function() {
      if (Cookie.get(Session.preLoginInfo.SamlArtifactCookieName) && Cookie.get(Session.preLoginInfo.CheckName)) {
        $.when(GetExternalUserDetails(), GetConfigurations())
          .done(function(userProfile, config) {
            Session.user = userProfile;
            Session.config = config;
            finishLogin();
          })
          .fail(function() {
            Radio.channel('navigate').trigger('show:keystonelogin');
          });
      } else {
        Radio.channel('navigate').trigger('show:keystonelogin');
      }
    })
    .fail(function() {
      Messenger().error({
        message:
          'There was an issue contacting the server. Please ensure the server is running and service containers are started.',
        showCloseButton: true
      });
    });
} else {
  showKeyStoneLogin();
}

var setupInactivityTimer = function() {
  var inactivityMinutes = parseInt(Session.config.CM_EXTERNAL_INACTIVITY_LOGOUT_MINUTES);
  var warningMinutes = parseInt(Session.config.CM_EXTERNAL_INACTIVITY_WARNING_MINUTES);
  var inactivityMilliseconds = inactivityMinutes * 60 * 1000;
  var warningMilliseconds = warningMinutes * 60 * 1000;

  var warningMessage;

  var timer = new InactivityTimer({
    inactivityMilliseconds: inactivityMilliseconds,
    warningMilliseconds: warningMilliseconds,
    onInactivity: function() {
      finishLogout();
      if (warningMessage && _.isFunction(warningMessage.hide)) {
        warningMessage.hide();
      }
      Messenger().post({
        message: 'You have been logged out due to inactivity.',
        hideAfter: false,
        showCloseButton: true
      });
    },
    onWarning: function() {
      warningMessage = Messenger().post({
        message:
          'You will be automatically logged out if no action is taken during the next ' + warningMinutes + ' minutes.',
        showCloseButton: true,
        hideAfter: false,
        actions: {
          active: {
            label: 'Keep Session Active',
            action: function() {
              warningMessage.hide();
              timer.restart();
            }
          }
        }
      });
    },
    onTimerReset: function() {
      if (warningMessage && _.isFunction(warningMessage.hide)) {
        warningMessage.hide();
      }
    }
  });
  return timer;
};

var finishLogin = function() {
  Scaffold.content.empty();
  Messenger().hideAll();
  if (Backbone.history) {
    if (!Backbone.History.started) {
      Backbone.history.start();
    }
    Radio.channel('header').trigger('login:external');
    Radio.channel('footer').trigger('show:external');
    if (NavUtils.getCurrentRoute() === '') {
      Radio.channel('header').trigger('show:external');
      Radio.channel('navigate').trigger(Config.getDefaultNavigationTrigger());
    }
  }
  window.inactivityTimer = setupInactivityTimer();
  window.inactivityTimer.start();
};

var finishLogout = function() {
  NProgress.start();
  NProgress.set(0.5);
  // Destory authentication cookies
  clearAuthenticationCookies();
  // Destroy DoBS Session, KeystoneLogin cookies
  clearExternalUserCookies();
  // Empty all session values;
  Session.auth = undefined;
  Session.user = undefined;
  Session.config = undefined;
  // Stop inactivity timer.
  window.inactivityTimer.stop();

  Backbone.history.stop();
  NavUtils.navigate('');
  NProgress.done();
  // Load Keystone Logout with redirect
  window.location = Config.getKeystoneLogoutRedirectURL();
};

/**
 * Handle the completion of the authentication, regardless of source. Assumption
 * at this point is that Session.(etc) data is populated
 */
Radio.channel('global').on('login:finish', function() {
  finishLogin();
});

Radio.channel('global').on('logout:finish', function() {
  finishLogout();
});

module.exports = {};
