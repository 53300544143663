var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "\r\n<a class=\"nmls-link\" href=\"http://mortgage.nationwidelicensingsystem.org/Pages/Default.aspx\" target=\"_blank\">Managed By NMLS</a>\r\n<span class=\"fa fa-question-circle nmls-tooltip\" data-toggle=\"tooltip\" data-placement=\"bottom\" title=\"This "
    + alias4(((helper = (helper = helpers.objectName || (depth0 != null ? depth0.objectName : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"objectName","hash":{},"data":data}) : helper)))
    + " was synced from NMLS. Please visit the NMLS site in order to manage the data associated with this "
    + alias4(((helper = (helper = helpers.objectName || (depth0 != null ? depth0.objectName : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"objectName","hash":{},"data":data}) : helper)))
    + ".\"></span>";
},"useData":true});