/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var NProgress = require('nprogress');

var NavUtils = require('psa-core/util/nav');

var Scaffold = require('common/layout/Scaffold');
var GetEntityIdentifiers = require('../../common/services/GetEntityIdentifiers');
var GetAllDirectorLookups = require('../../common/services/GetAllDirectorLookups');

var GetDirector = require('../services/GetDirector');
var DepDirectorEditView = require('../views/DepDirectorEditView');

module.exports = function(entityId, directorId) {
  NProgress.start();
  NProgress.set(0.6);

  $.when(GetEntityIdentifiers(entityId), GetDirector(directorId))
    .done(function(entityIdentifiers, director) {
      NProgress.set(0.7);
      GetAllDirectorLookups(entityIdentifiers.categoryId).done(function(lookups) {
        NavUtils.navigate('dep/entity/' + entityId + '/director/' + directorId + '/edit');
        Scaffold.content.show(
          new DepDirectorEditView({
            model: new Backbone.Model({
              entityIdentifiers: entityIdentifiers,
              director: director,
              directorElectionBasis: lookups.directorElectionBasis,
              officerDirectorStatus: lookups.officerDirectorStatus,
              directorReason: lookups.directorReason,
              directorPositionHelds: lookups.directorPositionHelds
            })
          })
        );
        NProgress.done();
      });
    })
    .fail(function() {
      NProgress.done();
    });
};
