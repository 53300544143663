/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _get = require('lodash.get');
var NProgress = require('nprogress');

var NavUtils = require('psa-core/util/nav');

var Scaffold = require('common/layout/Scaffold');

var getFilingValidFields = require('../util/getFilingValidFields');

var GetEntityIdentifiers = require('../../common/services/GetEntityIdentifiers');
var GetAllFilingLookups = require('../../common/services/GetAllFilingLookups');

var GetFiling = require('../services/GetFiling');
var DepFilingEditView = require('../views/DepFilingEditView');

module.exports = function(entityId, filingId) {
  NProgress.start();
  NProgress.set(0.6);
  $.when(GetEntityIdentifiers(entityId), GetFiling(filingId), GetAllFilingLookups())
    .done(function(entityIdentifiers, filing, lookups) {
      var filingTypeId = filing.filingTypeId;
      var genericSubmissionTypeId = _get(filing, 'genericSubmissionDetails.submissionTypeId');
      var validFields = getFilingValidFields({
        categoryId: entityIdentifiers.categoryId,
        filingTypeId: filingTypeId,
        genericSubmissionTypeId: genericSubmissionTypeId
      });
      NavUtils.navigate('dep/entity/' + entityId + '/filing/' + filingId + '/edit');
      Scaffold.content.show(
        new DepFilingEditView({
          model: new Backbone.Model({
            entityIdentifiers: entityIdentifiers,
            filing: filing,
            filingTypes: lookups.filingTypes,
            filingRecordStatus: lookups.filingRecordStatus,
            filingProcessingOptions: lookups.filingProcessingOptions,
            filingProcessingActions: lookups.filingProcessingActions,
            filingSubsidiaryStatus: lookups.filingSubsidiaryStatus,
            validFields: validFields
          })
        })
      );
      NProgress.done();
    })
    .fail(function() {
      NProgress.done();
    });
};
