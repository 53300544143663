/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Ladda = require('ladda');
var Radio = require('backbone.radio');

var modelGet = require('common/util/modelGet');
var BreadcrumbView = require('common/views/BreadcrumbView');

var DepCallReportFormView = require('./DepCallReportFormView');
var SubmitEntityCallReport = require('../services/SubmitEntityCallReport');

var tmplDepCallReportEditView = require('../templates/DepCallReportEditView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepCallReportEditView,

  className: 'dep-call-report-edit-view container-fluid',

  ui: {
    callReportForm: '.call-report-form-container',
    breadcrumb: '.breadcrumb-container',
    submit: '.btn-submit'
  },

  regions: {
    callReportForm: '@ui.callReportForm',
    breadcrumb: '@ui.breadcrumb'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeShow: function() {
    var callReport = this.model.get('callReport') || {};
    var callReportId = this.model.get('callReportId');
    var entityId = this.model.get('entityId');
    var entityName = modelGet(this.model, 'entityIdentifiers.entityName');

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#dep/dashboard',
            text: 'Home'
          },
          {
            link: '#dep/entity/' + entityId + '/dashboard',
            text: entityName
          },
          {
            text: callReport.reportYear + ' - ' + callReport.quarterEnd,
            link: '#dep/entity/' + entityId + '/call-report/' + callReportId + '/dashboard'
          },
          {
            text: 'Edit Call Report'
          }
        ]
      })
    );

    this.callReportForm = new DepCallReportFormView({
      model: this.model
    });

    this.showChildView('callReportForm', this.callReportForm);
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    this.validate().done(
      function() {
        var laddaContext = Ladda.create(this.ui.submit.get(0));
        laddaContext.start();
        var formData = this.getFormData();
        SubmitEntityCallReport(formData)
          .done(function() {
            Radio.channel('navigate').trigger(
              'show:int:dp:entity:call-report',
              formData.entityId,
              formData.callReportId
            );
          })
          .fail(function() {
            laddaContext.stop();
          });
      }.bind(this)
    );
  },

  validate: function() {
    return this.ui.callReportForm.parsley().whenValidate();
  },

  getFormData: function() {
    return this.callReportForm.getFormData();
  }
});
