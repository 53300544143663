/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var FileUploadTableView = require('common/views/FileUploadTableView');

require('./MTReviewInvestigation.css');
var tmplMTReviewInvestigation = require('./MTReviewInvestigation.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplMTReviewInvestigation,

  className: 'mt-review-investigation panel panel-default',

  ui: {
    fileUploadTableContainer: '.file-upload-grid-container',
    indicator: '.indicator'
  },

  regions: {
    fileUploadTableContainer: '@ui.fileUploadTableContainer'
  },

  templateHelpers: function() {
    return {
      mode: this.options.mode
    };
  },

  onBeforeShow: function() {
    var documents = this.model.get('documents');
    if (!this.options.isReadOnly) {
      _.each(documents, function(document) {
        if (document.documentTypeId) {
          document.documentType = document.documentTypeId;
        }
      });
    }
    Syphon.deserialize(this, this.model.toJSON());
    this.ui.indicator.prop('disabled', true);
    this.fileUploadTable = new FileUploadTableView({
      isReadOnly: this.options.isReadOnly,
      documentTypes: this.options.documentTypes,
      emptyText: 'No Investigation Documents Provided',
      collection: new Backbone.Collection(documents)
    });
    this.showChildView('fileUploadTableContainer', this.fileUploadTable);

    if (this.options.isReadOnly) {
      this.ui.fileUploadTableContainer.addClass('read-only');
    }
  },

  validate: function() {
    return this.fileUploadTable.validate();
  },

  getFormData: function() {
    var self = this;
    var documents = this.fileUploadTable.getFormData();
    return _.map(documents, function(document) {
      document.documentId = document.fileId;
      document.applicationInvestigationId = self.model.get('applicationInvestigationId');
      document.individualId = self.model.get('individualId');
      return document;
    });
  }
});
