/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');
var formatAsDollars = require('common/util/formatAsDollars');

module.exports = function(callReportId) {
  var defer = $.Deferred();

  $.soap({
    body: {
      GetEntityCallReport: {
        $xmlns: 'http://dobs.pa.gov/dp/callreport/bpm/1.0',
        CallReportID: callReportId
      }
    },
    blueprint: {
      entityId: '$..EntityID',
      csFolderId: '$..CSFolderID',
      reportQuarterEndId: '$..ReportQuarterEndID',
      reportYear: '$..ReportYear',
      totalManagedAssets: '$..TotalManagedAssets',
      totalNonManagedAssets: '$..TotalNonManagedAssets',
      custodySafekeepingAccounts: '$..CustodySafekeepingAccounts',
      totalAssetsUnderManagement: '$..TotalAssetsUnderManagement',
      totalTrustCompanyAssets: '$..TotalTrustCompanyAssets',
      totalCapital: '$..TotalCapital',
      reportDate: '$..ReportDate',
      comments: '$..Comments',
      isAmended: '$..IsAmended',
      quarterEnd: '$..QuarterEnd'
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      if (response.reportDate) {
        response.reportDate = moment(response.reportDate, 'YYYY-MM-DD').format('MM/DD/YYYY');
      }

      response.totalManagedAssetsDisplay = formatAsDollars(parseFloat(response.totalManagedAssets), 0);
      response.totalNonManagedAssetsDisplay = formatAsDollars(parseFloat(response.totalNonManagedAssets), 0);
      response.custodySafekeepingAccountsDisplay = formatAsDollars(parseFloat(response.custodySafekeepingAccounts), 0);
      response.totalAssetsUnderManagementDisplay = formatAsDollars(parseFloat(response.totalAssetsUnderManagement), 0);
      response.totalTrustCompanyAssetsDisplay = formatAsDollars(parseFloat(response.totalTrustCompanyAssets), 0);
      response.totalCapitalDisplay = formatAsDollars(parseFloat(response.totalCapital), 0);
      response.isAmended = parseInt(response.isAmended);

      defer.resolve(response);
    })
    .fail(function(err) {
      defer.reject(err);
    });

  return defer.promise();
};
