/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var FileUploadCollection = require('psa-backbone/models/FileUploadCollection');
var Radio = require('backbone.radio');
var Ladda = require('ladda');

var modelGet = require('common/util/modelGet');
var parsleyUtil = require('common/util/parsleyUtil');
var FileUploadView = require('common/views/FileUploadView');
var toggleForm = require('common/util/toggleForm');

var SubmitSubmitCallReportFiling = require('../../services/SubmitSubmitCallReportFiling');

require('../../styles/DepFilingPrintView.css');
var tmplDepSubmitCallReportSubmissionSectionView = require('./DepSubmitCallReportSubmissionSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepSubmitCallReportSubmissionSectionView,

  className: 'dep-submit-call-report-submission-section-view',

  ui: {
    submissionForm: '.submission-form',
    callReportYear: '.call-report-year',
    callReportDocument: '.call-report-document-container',
    assetInput: '.asset-input',
    totalManagedAssets: '.total-managed-assets',
    totalNonManagedAssets: '.total-non-managed-assets',
    custodySafekeepingAccounts: '.custody-safekeeping-accounts',
    totalAssetsUnderManagement: '.total-assets-under-management',
    totalTrustCompanyAssets: '.total-trust-company-assets',
    submit: '.submit'
  },

  regions: {
    callReportDocument: '@ui.callReportDocument'
  },

  events: {
    'change @ui.assetInput': 'onChangeAssetInput',
    'click @ui.submit': 'onClickSubmit'
  },

  templateHelpers: function() {
    var isReadOnly = this.options.isReadOnly;
    var quarters = this.model.get('quarters');
    return {
      isReadOnly: isReadOnly,
      quarters: quarters,
      mode: this.options.mode
    };
  },

  onBeforeShow: function() {
    var reportDocId = modelGet(this.model, 'filingContent.reportDocument.fileId');
    var totalAssetsUnderManagement = modelGet(this.model, 'filingContent.totalAssetsUnderManagement');
    this.ui.totalAssetsUnderManagement.val(totalAssetsUnderManagement);

    if (reportDocId) {
      var callReportDocument = modelGet(this.model, 'filingContent.reportDocument');
    }

    Syphon.deserialize(this, this.model.get('filingContent'));
    this.ui.callReportYear.datepicker({
      endDate: '+0d',
      viewMode: 'years',
      changeYear: true,
      minViewMode: 'years',
      format: 'yyyy'
    });

    this.callReportDocument = new FileUploadView({
      isReadOnly: this.options.isReadOnly,
      isRequired: true,
      collection: new FileUploadCollection(callReportDocument),
      metadata: {
        documentTypeId: 20001
      }
    });
    this.showChildView('callReportDocument', this.callReportDocument);
  },

  onAttach: function() {
    if (this.options.mode === 'print') {
      this.switchToPrint();
    }
  },

  switchToPrint: function() {
    var textAreas = $('.input-view');

    _.each(textAreas, function(textArea) {
      var textAreaVal = textArea.value;
      var textAreaName = textArea.name;
      var printElementId = '#'.concat(textAreaName, 'PrintView');
      $(printElementId).text(textAreaVal);
    });
    toggleForm(textAreas, false);
  },

  onChangeAssetInput: function() {
    var totalAssets =
      Number(this.ui.totalManagedAssets.val()) +
      Number(this.ui.totalNonManagedAssets.val()) +
      Number(this.ui.custodySafekeepingAccounts.val());
    this.ui.totalAssetsUnderManagement.val(totalAssets);
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;

    var l = Ladda.create(this.ui.submit[0]);

    this.sideNavView.validateAll({ type: 'submit' }).done(function() {
      l.start();

      var formData = self.sideNavView.getFormData();
      formData.filingId = self.model.get('filingId');
      formData.submitCallReportFilingId = self.model.get('submitCallReportFilingId');
      SubmitSubmitCallReportFiling(formData)
        .done(function() {
          Radio.channel('dp:draft-filing').trigger('proceed-to-payment', l);
          Radio.channel('dp:draft-filing').trigger('submit', l);
          Radio.channel('dp:submit-filing').trigger('resubmit', l);
        })
        .fail(function() {
          l.stop();
        });
    });
  },

  validate: function(config) {
    this.ui.submissionForm.parsley().reset();
    this.callReportDocument.removeValidationErrors();

    if (config.type === 'save') {
      // Turn off required field validation for this form
      parsleyUtil.disableRequiredValidation(this.ui.submissionForm);
    } else {
      var validationPromises = [this.ui.submissionForm.parsley().whenValidate(), this.callReportDocument.validate()];

      // Turn on required field validation for this form
      parsleyUtil.enableRequiredValidation(this.ui.submissionForm);
    }
    return $.when.apply($, validationPromises);
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);

    var quarters = this.model.get('quarters');
    var reportQuarter =
      _.find(quarters, function(q) {
        return q.quarter === formData.reportQuarterEndId;
      }) || {};
    if (reportQuarter.quarterEnd && formData.reportYear) {
      formData.reportDate = reportQuarter.quarterEnd + '/' + formData.reportYear;
    }

    formData.reportDocId = this.callReportDocument.getFirstFileId();
    formData.totalAssetsUnderManagement =
      Number(this.ui.totalManagedAssets.val()) +
      Number(this.ui.totalNonManagedAssets.val()) +
      Number(this.ui.custodySafekeepingAccounts.val());
    return formData;
  }
});
