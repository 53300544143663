/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var _get = require('lodash.get');

var momentUtil = require('common/util/momentUtil');
var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function() {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetSelfServiceDashboard: {
        $xmlns: 'http://dobs.pa.gov/cf/dashboard/bpm/1.0'
      }
    },
    blueprintArrays: [
      'GetSelfServiceDashboardResponse.Tasks.Tasks.NOTF_TASK_INSTANCE',
      'GetSelfServiceDashboardResponse.Tasks.TaskTypes.CM_LU_TaskType'
    ],
    blueprint: {
      tasks: [
        '$..NOTF_TASK_INSTANCE',
        {
          taskId: '$..TaskId',
          deliveryDate: '$..DeliveryDate',
          taskTypeName: '$..TaskTypeName'
        }
      ],
      taskMetaData: {
        taskTypes: [
          '$..CM_LU_TaskType',
          {
            id: '$.ID',
            name: '$.Name',
            description: '$.Description'
          }
        ]
      }
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      _.each(response.tasks, function(task) {
        if (task.taskTypeName.__text) {
          task.taskTypeName = task.taskTypeName.__text;
        }

        var taskTypeName = _get(task, 'taskTypeName', {});

        task.deliveryDate = momentUtil.formatStr(task.deliveryDate, momentUtil.defaultInboundDateConfig);

        task.type = _get(
          _.findWhere(response.taskMetaData.taskTypes, {
            name: taskTypeName
          }),
          'description',
          '--'
        );
      });

      delete response.taskMetaData;
      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
