/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');
var Ladda = require('ladda');
var Radio = require('backbone.radio');

var modelGet = require('common/util/modelGet');
var momentUtil = require('common/util/momentUtil');
var BreadcrumbView = require('common/views/BreadcrumbView');

var SubmitMrba = require('../services/SubmitMrba');
var DepMrbaFormView = require('./DepMrbaFormView');

var tmplDepMrbaEditView = require('../templates/DepMrbaEditView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepMrbaEditView,

  className: 'dep-edit-mrba-view container-fluid',

  ui: {
    breadcrumb: '.breadcrumb-container',
    mrbaFormContainer: '.mrba-form-container',
    submit: '.submit'
  },

  regions: {
    breadcrumb: '@ui.breadcrumb',
    mrbaFormContainer: '@ui.mrbaFormContainer'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  templateHelpers: function() {
    return {
      isCU: _.contains(['11', '12', '13', '14'], modelGet(this.model, 'entityIdentifiers.entityTypeId'))
    };
  },

  onBeforeShow: function() {
    var mrba = this.model.get('mrba') || {};
    this.mrbaId = mrba.mrbaId;
    this.entityId = mrba.entityId;
    var entityName = modelGet(this.model, 'entityIdentifiers.entityName');
    var isCU = _.contains(['11', '12', '13', '14'], modelGet(this.model, 'entityIdentifiers.entityTypeId'));

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#dep/dashboard',
            text: 'Home'
          },
          {
            link: '#dep/entity/' + this.entityId + '/dashboard',
            text: entityName
          },
          {
            link: '#dep/entity/' + this.entityId + '/mrba/' + this.mrbaId + '/dashboard',
            text: mrba.category
          },
          {
            text: isCU ? 'Edit DOR' : 'Edit MRBA'
          }
        ]
      })
    );

    this.mrbaForm = new DepMrbaFormView({
      model: this.model
    });

    this.showChildView('mrbaFormContainer', this.mrbaForm);
  },

  onClickSubmit: function(event) {
    event.preventDefault();
    var self = this;
    this.validate().done(function() {
      var l = Ladda.create(self.ui.submit[0]);
      l.start();
      var formData = self.getFormData();
      SubmitMrba(formData)
        .done(function(mrbaId) {
          Radio.channel('navigate').trigger('show:int:dp:mrba', self.entityId, mrbaId);
        })
        .fail(function() {
          l.stop();
        });
    });
  },

  getFormData: function() {
    var formData = this.mrbaForm.getFormData();
    var dateAttributes = [
      'examAsOfDate',
      'dueDate',
      'latestResponseDueDate',
      'latestReceivedDate',
      'latestExtensionDate'
    ];

    momentUtil.formatDateAttributes(formData, dateAttributes, 'MM/DD/YYYY', 'YYYY-MM-DD');

    formData.entityId = this.entityId;
    formData.mrbaId = modelGet(this.model, 'mrba.mrbaId');

    return formData;
  },

  validate: function() {
    return this.ui.mrbaFormContainer.parsley().whenValidate();
  }
});
