/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(entityId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetDraftFilingsByEntity: {
        $xmlns: 'http://dobs.pa.gov/dp/filing/bpm/1.0',
        EntityID: entityId
      }
    },
    blueprintArrays: ['GetDraftFilingsByEntityResponse.GenericSubmissionFilings.DP_Filing'],
    blueprint: {
      filings: [
        '$..DP_Filing',
        {
          filingId: '$..FilingID',
          filGenericSubmissionTypeId: '$..FilGenericSubmissionTypeId',
          filGenericSubmissionType: '$..FilGenericSubmissionType',
          createdBy: '$..CreatedBy',
          modifiedDate: '$..ModifiedDate'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      _.each(response.filings, function(filing) {
        filing.filGenericSubmissionType = filing.filGenericSubmissionType || '--';
      });

      deferred.resolve(response.filings);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
