/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Radio = require('backbone.radio');
var Ladda = require('ladda');

var momentUtil = require('common/util/momentUtil');
var modelGet = require('common/util/modelGet');
var makeUploadCollection = require('common/util/makeUploadCollection');

var HeaderBarView = require('common/views/HeaderBarView');
var BreadcrumbView = require('common/views/BreadcrumbView');
var FileUploadTableView = require('common/views/FileUploadTableView');
var FileUploadView = require('common/views/FileUploadView');

var SubmitRespondCorrespondenceTask = require('../../services/SubmitRespondCorrespondenceTask');

var tmplSecRespondCorrespondenceTaskView = require('./SecRespondCorrespondenceTaskView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplSecRespondCorrespondenceTaskView,

  className: 'sec-respond-correspondence-task-view container-fluid',

  ui: {
    headerBar: '.header-bar',
    breadcrumb: '.breadcrumb-container',
    correspondenceForm: '.correspondence-form',
    extFileUploadTableContainer: '.ext-file-upload-table-container',
    missingResponse: '.missing-response',
    hasFinDocs: '.has-fin-docs',
    extFinancialUploadTableContainer: '.ext-financial-upload-table-container',
    intFileUploadTableContainer: '.int-file-upload-table-container',
    datePickerFields: '.date-picker',
    submit: '.submit'
  },

  regions: {
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb',
    extFileUploadTableContainer: '@ui.extFileUploadTableContainer',
    extFinancialUploadTableContainer: '@ui.extFinancialUploadTableContainer',
    intFileUploadTableContainer: '@ui.intFileUploadTableContainer'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  behaviors: {
    RadioGroupToggleBehavior: {
      radios: [
        {
          target: '.financial-documents-section',
          radioGroup: 'input[name="hasFinDocs"]',
          valueToShow: '1'
        }
      ]
    }
  },

  onBeforeShow: function() {
    var correspondence = this.model.get('correspondence') || {};
    var intDocuments = modelGet(this.model, 'correspondence.intDocuments', []);

    var headerBarColumns = [
      {
        name: 'orgLegalName',
        label: 'Applicant Legal Name',
        minWidth: '160px',
        maxWidth: '300px'
      },
      {
        name: 'crdNumber',
        label: 'CRD Number',
        minWidth: '160px',
        maxWidth: '300px'
      }
    ];

    this.showChildView(
      'headerBar',
      new HeaderBarView({
        columns: new Backbone.Collection(headerBarColumns),
        model: new Backbone.Model(correspondence)
      })
    );

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#ext/sec/dashboard',
            text: 'Home'
          },
          {
            text: 'Respond to Correspondence'
          }
        ]
      })
    );

    Syphon.deserialize(this, correspondence);
    this.ui.datePickerFields.datepicker();

    this.intFileUploadTableView = new FileUploadTableView({
      collection: new Backbone.Collection(intDocuments),
      emptyText: 'No Internal Documents',
      isReadOnly: true
    });

    this.showChildView('intFileUploadTableContainer', this.intFileUploadTableView);

    this.extFileUploadTableView = new FileUploadView({
      collection: makeUploadCollection([]),
      isReadOnly: false,
      allowMultipleFiles: true,
      metadata: {
        documentTypeId: 30013
      }
    });

    this.showChildView('extFileUploadTableContainer', this.extFileUploadTableView);

    this.extFinancialUploadTableView = new FileUploadView({
      collection: makeUploadCollection([]),
      isReadOnly: false,
      allowMultipleFiles: true,
      isRequired: true,
      metadata: {
        documentTypeId: 30012
      }
    });

    this.showChildView('extFinancialUploadTableContainer', this.extFinancialUploadTableView);
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;

    this.validate().done(function() {
      var l = Ladda.create(self.ui.submit[0]);
      l.start();
      SubmitRespondCorrespondenceTask(self.getFormData())
        .done(function() {
          Radio.channel('navigate').trigger('show:ext:sec:dashboard');
        })
        .fail(function() {
          l.stop();
        });
    });
  },

  validate: function() {
    var validationArray = [
      this.ui.correspondenceForm.parsley().whenValidate(),
      this.extFileUploadTableView.validate(),
      this.validateCommentsAttachments()
    ];

    if (this.ui.hasFinDocs.filter(':checked').val() === '1') {
      validationArray.push(this.extFinancialUploadTableView.validate());
    }

    return $.when.apply($, validationArray);
  },

  validateCommentsAttachments: function() {
    var defer = $.Deferred();

    var formData = this.getFormData();
    var hasNoComment = formData.replyComments.length === 0;
    var hasNoAttachment = formData.documents.length === 0;

    if (hasNoComment && hasNoAttachment) {
      this.ui.missingResponse.show();
      defer.reject();
    } else {
      this.ui.missingResponse.hide();
      defer.resolve();
    }

    return defer.promise();
  },


  getFormData: function() {
    var formData = Syphon.serialize(this);
    formData.fiscalYearEndDate = momentUtil.formatStr(formData.fiscalYearEndDate, momentUtil.defaultOutboundDateConfig);

    _.extend(formData, {
      taskId: this.model.get('taskId'),
      correspondenceId: this.model.get('correspondenceId')
    });
    formData.documents = this.extFileUploadTableView.collection.toJSON();

    if (formData.hasFinDocs === '1') {
      formData.documents = formData.documents.concat(this.extFinancialUploadTableView.collection.toJSON());
    }
    return formData;
  }
});
