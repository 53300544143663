/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');
var momentUtil = require('common/util/momentUtil');

module.exports = function(data) {
  var defer = $.Deferred();

  data.paymentDate = momentUtil.formatStr(data.paymentDate, momentUtil.defaultOutboundDateConfig);

  $.soap({
    body: {
      SubmitCompliancePayment: {
        $xmlns: 'http://dobs.pa.gov/sc/compliance/bpm/1.0',
        CompliancePaymentID: data.compliancePaymentId,
        ComplianceID: data.complianceId,
        PaymentDate: data.paymentDate,
        AdminAssessmentsApplied: data.adminAssessmentsApplied,
        InvestigativeCostsApplied: data.investigativeCostsApplied,
        LegalCostsApplied: data.legalCostsApplied,
        CivilPenaltyApplied: data.civilPenaltyApplied,
        GlobalSettlementApplied: data.globalSettlementApplied,
        CheckNumber: data.checkNumber,
        TransmittalNumber: data.transmittalNumber,
        IsDeleted: data.isDeleted
      }
    },
    blueprint: {
      compliancePaymentId: '$..ID',
      paymentDate: '$..PaymentDate',
      amount: '$..Amount',
      adminAssessmentsApplied: '$..AdminAssessmentsApplied',
      investigativeCostsApplied: '$..InvestigativeCostsApplied',
      legalCostsApplied: '$..LegalCostsApplied',
      civilPenaltyApplied: '$..CivilPenaltyApplied',
      globalSettlementApplied: '$..GlobalSettlementApplied',
      checkNumber: '$..CheckNumber',
      transmittalNumber: '$..TransmittalNumber'
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      if (response.paymentDate) {
        response.paymentDate = moment(response.paymentDate, 'YYYY-MM-DD');
      }
      response.checkNumberDisplay = response.checkNumber ? response.checkNumber : '--';
      response.transmittalNumberDisplay = response.transmittalNumber ? response.transmittalNumber : '--';

      defer.resolve(response);
    })
    .fail(function(err) {
      defer.reject(err);
    });

  return defer.promise();
};
