/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var NProgress = require('nprogress');

var NavUtils = require('psa-core/util/nav');

var Scaffold = require('common/layout/Scaffold');
var GetUsersForRole = require('common/services/GetUsersForRole');

var GetExamInvInqStaffById = require('../../common/services/GetExamInvInqStaffById');
var GetAllExamInvInqLookups = require('../../common/services/GetAllExamInvInqLookups');
var SecExamInvInqStaffMemberEditView = require('../../common/views/SecExamInvInqStaffMemberEditView');
var GetInvInqIdentifiers = require('../services/GetInvInqIdentifiers');

module.exports = function(invInqId, staffId) {
  NProgress.start();
  NProgress.set(0.6);
  $.when(
    GetAllExamInvInqLookups(),
    GetInvInqIdentifiers(invInqId),
    GetUsersForRole('SC - User'),
    GetExamInvInqStaffById(staffId)
  )
    .done(function(lookups, identifiers, staffMembers, staffMember) {
      NavUtils.navigate('#sec/investigation-inquiry/' + invInqId + '/staff-member/' + staffId + '/edit');
      Scaffold.content.show(
        new SecExamInvInqStaffMemberEditView({
          model: new Backbone.Model({
            identifiers: identifiers,
            examInvInqStaffFunctions: lookups.examInvInqStaffFunctions,
            objectTypeId: 'INV-INQ',
            objectId: invInqId,
            staffId: staffId,
            staffMembers: staffMembers,
            staffMember: staffMember,
            isInvInq: true
          })
        })
      );
      NProgress.done();
    })
    .fail(function() {
      NProgress.done();
    });
};
