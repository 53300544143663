/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var NProgress = require('nprogress');

var getComplianceCommonViews = require('../../util/getComplianceCommonViews');

var GetComplianceDetails = require('../../../common/services/GetComplianceDetails');
var GetUsersForRole = require('common/services/GetUsersForRole');
var ComplianceManagerReviewCaseRequestView = require('./ComplianceManagerReviewCaseRequestView');

module.exports = {
  event: 'build:nd:compliance-manager-review-case-request',

  channel: 'task',

  builder: function(task) {
    var defer = $.Deferred();

    var complianceId = task.taskData.ND.complianceId;

    $.when(GetComplianceDetails(complianceId), GetUsersForRole('ND - Compliance Admin'))
      .done(function(complianceDetails, complianceAdmins) {
        NProgress.set(0.85);

        var commonViews = getComplianceCommonViews({
          viewMode: 'int',
          task: task,
          complianceDetails: complianceDetails
        });

        defer.resolve(
          new ComplianceManagerReviewCaseRequestView({
            complianceHeaderBar: commonViews.complianceHeaderBar,
            complianceBreadcrumbs: commonViews.complianceBreadcrumbs,
            complianceSummary: commonViews.complianceSummary,
            model: new Backbone.Model({
              task: task,
              complianceDetails: complianceDetails,
              complianceAdmins: complianceAdmins
            })
          })
        );
      })
      .fail(function(err) {
        defer.reject(err);
      });
    return defer.promise();
  }
};
