/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var NProgress = require('nprogress');

var NavUtils = require('psa-core/util/nav');

var Scaffold = require('common/layout/Scaffold');

var GetAllCategories = require('../../common/services/GetAllCategories');
var GetTimesheet = require('../../common/services/GetTimesheet');
var GetAllLicenseTypes = require('../../../non-depository/common/services/GetAllLicenseTypes');
var TimeTrkNonDepExaminerEntryView = require('../views/TimeTrkNonDepExaminerEntryView');

module.exports = function() {
  NProgress.start();
  NProgress.set(0.6);

  var nonDepDptId = '1000';
  $.when(GetAllCategories(nonDepDptId), GetTimesheet({ departmentId: nonDepDptId }), GetAllLicenseTypes()).done(
    function(categories, timesheet, licenseTypes) {
      var isSubmitted = timesheet.statusId !== '1';
      NavUtils.navigate('#nondep/time-tracking');
      Scaffold.content.show(
        new TimeTrkNonDepExaminerEntryView({
          viewMode: 'examiner', // Possible values of viewMode: 'examiner', 'reviewer', 'mt'
          model: new Backbone.Model({
            timesheetId: timesheet.timesheetId,
            payPeriod: timesheet.payPeriod,
            categories: categories,
            timesheet: timesheet,
            licenseTypes: licenseTypes
          }),
          isReadOnly: isSubmitted
        })
      );
      NProgress.done();
    }
  );
};
