/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Ladda = require('ladda');
var Radio = require('backbone.radio');

var SecInvInqHeaderBarView = require('../../investigation-inquiry-dashboard/views/SecInvInqHeaderBarView');
var SecExamHeaderBarView = require('../../exam-dashboard/views/SecExamHeaderBarView');
var SubmitCompliance = require('../services/SubmitCompliance');
var SecComplianceBreadcrumbView = require('./SecComplianceBreadcrumbView');
var SecComplianceGeneralInfoFormView = require('./SecComplianceGeneralInfoFormView');

var tmplSecComplianceNewView = require('../templates/SecComplianceNewView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplSecComplianceNewView,

  className: 'sec-compliance-new-view container-fluid',

  ui: {
    headerBar: '.header-bar',
    breadcrumb: '.breadcrumb-container',
    complianceGeneralInfoForm: '.compliance-general-info-form-container',
    submit: '.btn-submit'
  },

  regions: {
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb',
    complianceGeneralInfoForm: '@ui.complianceGeneralInfoForm'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  templateHelpers: function() {
    return {
      objectTypeId: this.model.get('objectTypeId')
    };
  },

  onBeforeShow: function() {
    var objectTypeId = this.model.get('objectTypeId');
    var identifiers = this.model.get('objectIdentifiers') || {};

    if (objectTypeId === 'exam') {
      this.showChildView(
        'headerBar',
        new SecExamHeaderBarView({
          model: new Backbone.Model(identifiers)
        })
      );
    } else {
      this.showChildView(
        'headerBar',
        new SecInvInqHeaderBarView({
          model: new Backbone.Model(identifiers),
          isLicenseInvInq: !!identifiers.licenseId
        })
      );
    }

    this.showChildView(
      'breadcrumb',
      new SecComplianceBreadcrumbView({
        identifiers: identifiers,
        breadcrumbs: [
          {
            text: 'New Compliance'
          }
        ]
      })
    );

    this.complianceGeneralInfoForm = new SecComplianceGeneralInfoFormView({
      model: this.model
    });

    this.showChildView('complianceGeneralInfoForm', this.complianceGeneralInfoForm);
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    this.validate().done(
      function() {
        var laddaContext = Ladda.create(this.ui.submit.get(0));
        laddaContext.start();
        var formData = this.getFormData();
        SubmitCompliance(formData)
          .done(function(newComplianceId) {
            Radio.channel('navigate').trigger('show:int:sc:compliance', newComplianceId);
          })
          .fail(function() {
            laddaContext.stop();
          });
      }.bind(this)
    );
  },

  validate: function() {
    return this.ui.complianceGeneralInfoForm.parsley().whenValidate();
  },

  getFormData: function() {
    var data = this.complianceGeneralInfoForm.getFormData();
    data.objectTypeId = this.model.get('objectTypeId').toUpperCase();
    data.objectId = this.model.get('objectId');
    return data;
  }
});
