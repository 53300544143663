/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Ladda = require('ladda');

var modelGet = require('common/util/modelGet');

var SubmitNonFINRAPaymentMemberDetail = require('../services/SubmitNonFINRAPaymentMemberDetail');

var tmplSecNonFINRAPaymentMemberDetailModalView = require('../templates/SecNonFINRAPaymentMemberDetailModalView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplSecNonFINRAPaymentMemberDetailModalView,

  className: 'sec-payment-modal-view',

  ui: {
    licensePaymentForm: '.license-payment-form',
    submit: '.submit',
    paidDate: '.paid-date'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeShow: function() {
    var payment = this.model.get('payment');
    Syphon.deserialize(this, payment);
    this.ui.paidDate.datepicker();
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;
    this.ui.licensePaymentForm
      .parsley()
      .whenValidate()
      .done(function() {
        var l = Ladda.create(self.ui.submit[0]);
        l.start();
        var formData = Syphon.serialize(self);
        var id = modelGet(self.model, 'payment.id');
        var objectId = modelGet(self.model, 'licenseId');
        formData.objectTypeId = self.options.objectTypeId;
        formData.objectId = objectId;
        formData.id = id;
        SubmitNonFINRAPaymentMemberDetail(formData)
          .done(function(newPayment) {
            self.triggerMethod('modal:close', newPayment);
          })
          .fail(function() {
            l.stop();
          });
      });
  }
});
