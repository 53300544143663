/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(enforcementId) {
  var defer = $.Deferred();

  $.soap({
    body: {
      GetEnforcementIdentifiers: {
        $xmlns: 'http://dobs.pa.gov/dp/enforcement/bpm/1.0',
        EnforcementID: enforcementId
      }
    },
    blueprint: {
      enforcementIdentifiers: {
        entityName: '$..EntityName',
        entityTypeId: '$..EntityTypeID',
        categoryId: '$..CategoryID',
        entityType: '$..EntityType',
        action: '$..Action',
        effectiveDate: '$..EffectiveDate'
      }
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      if (response.enforcementIdentifiers.effectiveDate) {
        response.enforcementIdentifiers.effectiveDate = moment(
          response.enforcementIdentifiers.effectiveDate,
          'YYYY-MM-DD'
        ).format('MM/DD/YYYY');
      }

      defer.resolve(response.enforcementIdentifiers);
    })
    .fail(function(err) {
      defer.reject(err);
    });

  return defer.promise();
};
