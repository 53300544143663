/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var NProgress = require('nprogress');

var NavUtils = require('psa-core/util/nav');

var Scaffold = require('common/layout/Scaffold');

var GetEntityIdentifiers = require('../../common/services/GetEntityIdentifiers');
var GetExternalUserAccessLevel = require('../../common/services/GetExternalUserAccessLevel');
var GetExternalTasksByEntity = require('../services/GetExternalTasksByEntity');
var DepExtEntityDashboardView = require('../views/DepExtEntityDashboardView');

module.exports = function(entityId) {
  NProgress.start();
  NProgress.set(0.6);

  $.when(GetEntityIdentifiers(entityId), GetExternalUserAccessLevel(entityId), GetExternalTasksByEntity(entityId))
    .done(function(entityIdentifiers, accessLevelId, tasks) {
      // use Session.user.id to see if ExternalUser of the correspondence task matches
      NavUtils.navigate('#ext/dep/entity/' + entityId + '/dashboard');
      Scaffold.content.show(
        new DepExtEntityDashboardView({
          viewMode: 'ext',
          model: new Backbone.Model({
            entityId: entityId,
            entityIdentifiers: entityIdentifiers,
            accessLevelId: accessLevelId.accessLevelId,
            tasks: tasks
          })
        })
      );
      NProgress.done();
    })
    .fail(function(err) {
      NProgress.done();
    });
};
