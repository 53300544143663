/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var cleanUpResponse = require('common/util/cleanUpResponse');
var moment = require('moment');
var _ = require('underscore');

module.exports = function(applicationId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetChangeStatusCertificationApplication: {
        $xmlns: 'http://dobs.pa.gov/nd/application/bpm/1.0',
        ApplicationID: applicationId
      }
    },
    blueprintArrays: [
      'GetChangeStatusCertificationApplicationResponse.AppChangeStatusCertification.ND_DebtManagementCreditCounselor',
      'GetChangeStatusCertificationApplicationResponse.AppChangeStatusCertification.ND_LU_CreditCounselorStatus',
      'GetChangeStatusCertificationApplicationResponse.ApplicationDocuments.ND_ApplicationDocument'
    ],
    blueprint: {
      applicationId: '$..ApplicationID.__text',
      firstName: '$..FirstName.__text',
      lastName: '$..LastName.__text',
      hireDate: '$..EmployeeHireDate',
      additionalInformation: '$..AdditionalInformation.__text',
      certificateNumber: '$..CertificateNumber.__text',
      certifyingOrganization: '$..CertifyingOrganization.__text',
      expirationDate: '$..CertificateExpirationDate',
      entityId: '$..EntityID.__text',
      licenseId: '$..LicenseID.__text',
      title: '$..EmployeeTitle.__text',
      addressLine1: '$..AddressLine1.__text',
      addressLine2: '$..AddressLine2.__text',
      addressLine3: '$..AddressLine3.__text',
      city: '$..City.__text',
      country: '$..Country.__text',
      county: '$..County.__text',
      state: '$..State.__text',
      postalCode: '$..PostalCode.__text',
      effectiveChangeDate: '$..EffectiveChangeDate.__text',
      certificationStatusId: '$..StatusID.__text',
      creditCounselorId: '$..DebtManagementCreditCounselorID.__text',
      certificationName: '$..CertificationName.__text',
      isCertified: '$..IsCertified.__text',
      currentCreditCounselors: [
        '$..ND_DebtManagementCreditCounselor',
        {
          id: '$..ID',
          licenseId: '$..LicenseID',
          firstName: '$..FirstName',
          lastName: '$..LastName',
          hireDate: '$..EmployeeHireDate',
          certifyingOrganization: '$..CertifyingOrganization',
          certificateNumber: '$..CertificateNumber',
          expirationDate: '$..CertificateExpirationDate',
          additionalInformation: '$..AdditionalInformation',
          status: '$..Status',
          statusId: '$..StatusID',
          title: '$..EmployeeTitle',
          addressLine1: '$..AddressLine1',
          addressLine2: '$..AddressLine2',
          addressLine3: '$..AddressLine3',
          city: '$..City',
          state: '$..State',
          postalCode: '$..PostalCode',
          country: '$..Country'
        }
      ],
      creditCounselorStatuses: [
        '$..ND_LU_CreditCounselorStatus',
        {
          id: '$..ID',
          name: '$..Status'
        }
      ],
      applicationDocuments: [
        '$..ND_ApplicationDocument',
        {
          fileId: '$.DocumentID',
          fileName: '$..Name'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      response.isCertified = response.isCertified === '1';
      response.effectiveChangeDate = moment(response.effectiveChangeDate, 'YYYY-MM-DD').format('MM/DD/YYYY');
      response.hireDate = moment(response.hireDate, 'YYYY-MM-DD').format('MM/DD/YYYY');
      response.expirationDate = moment(response.expirationDate, 'YYYY-MM-DD').isValid()
        ? moment(response.expirationDate, 'YYYY-MM-DD').format('MM/DD/YYYY')
        : undefined;

      _.each(response.currentCreditCounselors, function(counselor) {
        counselor.expirationDate = moment(counselor.expirationDate, 'YYYY-MM-DD').format('MM/DD/YYYY');
        counselor.hireDate = moment(counselor.hireDate, 'YYYY-MM-DD').format('MM/DD/YYYY');
      });
      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
