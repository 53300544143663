var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "          <option value="
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + ">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</option>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "\r\n<div class=\"side-bar-container pull-left\"></div>\r\n<div class=\"dashboard-container pull-right\">\r\n  <div class=\"breadcrumb-container\"></div>\r\n  <h2>Create a New Entity</h2>\r\n  <form class=\"form-horizontal new-entity-form\">\r\n    <div class=\"form-group required\">\r\n      <label class=\"control-label col-sm-3\">Entity Name</label>\r\n      <div class=\"col-sm-6\">\r\n        <input type=\"text\" name=\"entityName\" class=\"form-control\" placeholder=\"Entity Name\" data-parsley-required=\"true\"\r\n          data-parsley-errors-container=\".entity-name-error-container\" />\r\n        <div class=\"entity-name-error-container\"></div>\r\n      </div>\r\n    </div>\r\n    <div class=\"form-group required\">\r\n      <label class=\"control-label col-sm-3\">Organization Type</label>\r\n      <div class=\"col-sm-6\">\r\n        <select name=\"organizationTypeId\" class=\"form-control\" data-parsley-required=\"true\"\r\n          data-parsley-errors-container=\".entity-organization-type-error-container\">\r\n          <option value=\"\" selected>--</option>\r\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.entityOrganizationTypes : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </select>\r\n        <div class=\"entity-organization-type-error-container\"></div>\r\n      </div>\r\n    </div>\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-3\">Federal ID Number</label>\r\n      <div class=\"col-sm-6\">\r\n        <input type=\"text\" name=\"tin\" class=\"form-control col-sm-9\" placeholder=\"Federal ID Number\"\r\n          data-parsley-ein=\"true\" data-parsley-errors-container=\".entity-tin-error-container\" />\r\n        <div class=\"entity-tin-error-container\"></div>\r\n      </div>\r\n    </div>\r\n    <div class=\"row\">\r\n      <div class=\"col-sm-6 col-sm-offset-3\">\r\n        <a class=\"btn btn-default\" href=\"#nondep/dashboard/entities\">Cancel</a>\r\n        <button class=\"btn btn-primary btn-submit\" type=\"button\">Submit</button>\r\n      </div>\r\n    </div>\r\n  </form>\r\n</div>";
},"useData":true});