/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var moment = require('moment');

require('../styles/CorpFinReg207JDateFormView.css');
var tmplCorpFinReg207JDateFormView = require('../templates/CorpFinReg207JDateFormView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplCorpFinReg207JDateFormView,

  className: 'corp-fin-reg-207J-date-form-view',

  ui: {
    date: '.date',
    removeDate: '.remove-date'
  },

  triggers: {
    'click @ui.removeDate': 'remove:date'
  },

  templateHelpers: function() {
    return {
      childIndex: this.options.childIndex + 1
    };
  },

  onBeforeShow: function() {
    var reg207JDate = this.model.get('reg207JDate');

    if (reg207JDate && moment.isMoment(reg207JDate) && reg207JDate.isValid()) {
      this.model.set('reg207JDate', reg207JDate.format('MM/DD/YYYY'));
    }

    Syphon.deserialize(this, this.model.toJSON());

    this.ui.date.datepicker();
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    return formData;
  }
});
