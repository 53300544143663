var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"form-group\">\r\n      <button class=\"btn btn-primary download-instructions\">\r\n        <i class=\"fa fa-download\"></i>\r\n        Click Here to Download Application Instructions\r\n      </button>\r\n    </div>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "    <form class=\"affirmation-form\">\r\n      <div class=\"form-group\">\r\n        <label class=\"control-label\">Affirmation</label>\r\n        <label class=\"control-label font-weight-normal\">\r\n          <input type=\"checkbox\" name=\"isCertified\" value=\"1\" data-parsley-required=\"true\" />\r\n          I understand by submitting this Entity Books and Records Locations Change Application: I am agreeing to be\r\n          bound by the\r\n          following\r\n          declaration: \"I declare that all of my answers on this License Application are complete, true and correct.\r\n          I\r\n          make this declaration subject to the penalties of 18 PA.C.S. § 4904 relating to unsworn falsification to\r\n          authorities.\"\r\n        </label>\r\n        <div class=\"affirmation-error-container\"></div>\r\n      </div>\r\n      <div class=\"form-group required\">\r\n        <label><strong>Enter Name Below</strong></label>\r\n        <input type=\"text\" class=\"form-control\" name=\"certificationName\" data-parsley-required=\"true\" data-parsley-maxlength=\"100\" />\r\n      </div>\r\n    </form>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "\r\n<div class=\"col-sm-8 col-sm-offset-2 m-b-lg\">\r\n  <div class=\"row\">\r\n"
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.hideInstructionDoc : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    <form class=\"entity-section-records-location-container\"></form>\r\n    <div>\r\n      <h3>Document Upload</h3>\r\n      <div class=\"form-group\">\r\n        <label class=\"control-label\">Upload any other relevant documents</label>\r\n        <div class=\"application-documents-container\"></div>\r\n      </div>\r\n    </div>\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showAffirmation : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    <div class=\"row\">\r\n      <div class=\"col-sm-12\">\r\n        <a href=\"#ext/nondep/entity/"
    + container.escapeExpression(((helper = (helper = helpers.entityId || (depth0 != null ? depth0.entityId : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"entityId","hash":{},"data":data}) : helper)))
    + "/dashboard\" class=\"btn btn-default cancel-button\"\r\n          role=\"button\">Cancel</a>\r\n        <button class=\"btn btn-primary submit-button ladda-button\" data-style=\"expand-right\" type=\"button\"><span\r\n            class=\"ladda-label\">Submit</span></button>\r\n      </div>\r\n    </div>\r\n  </div>\r\n</div>";
},"useData":true});