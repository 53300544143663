/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Backbone = require('backbone');
var Marionette = require('backbone.marionette');

var UIUtil = require('psa-core/util/ui');
var GridView = require('psa-marionette/views/GridView');

var DollarCell = require('common/backgrid/DollarCell');
var MomentCell = require('common/backgrid/MomentCell');
var MultiButtonCell = require('common/backgrid/MultiButtonCell');
var ConfirmationModalView = require('common/views/ConfirmationModalView');

var SubmitCompliancePayment = require('../services/SubmitCompliancePayment');
var SecCompliancePaymentTotalsView = require('./SecCompliancePaymentTotalsView');
var SecComplianceCostEditModalView = require('./SecComplianceCostEditModalView');
var SecCompliancePaymentModalView = require('./SecCompliancePaymentModalView');

var tmplSecCompliancePaymentsSectionView = require('../templates/SecCompliancePaymentsSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplSecCompliancePaymentsSectionView,

  className: 'sec-compliance-payments-section-view',

  ui: {
    editCosts: '.edit-costs',
    paymentTotals: '.payment-totals',
    addPayment: '.add-payment',
    paymentGrid: '.payment-grid'
  },

  regions: {
    paymentTotals: '@ui.paymentTotals',
    paymentGrid: '@ui.paymentGrid'
  },

  events: {
    'click @ui.editCosts': 'onClickEditCosts',
    'click @ui.addPayment': 'onClickAddPayment'
  },

  onBeforeShow: function() {
    var payments = this.model.get('payments') || {};

    this.paymentTotals = new SecCompliancePaymentTotalsView({
      model: this.model
    });
    this.showChildView('paymentTotals', this.paymentTotals);

    this.paymentGrid = new GridView({
      columns: [
        {
          name: 'paymentDate',
          label: 'Payment Date',
          cell: MomentCell.MomentCell,
          formatter: MomentCell.MomentDateFormatter.extend({
            emptyValue: '--'
          }),
          sortValue: MomentCell.MomentSortValue,
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'amount',
          label: 'Payment Amount',
          cell: DollarCell,
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'adminAssessmentsApplied',
          label: 'Applied to Admin Assessments',
          cell: DollarCell,
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'investigativeCostsApplied',
          label: 'Applied to Investigative Costs',
          cell: DollarCell,
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'legalCostsApplied',
          label: 'Applied to Legal Costs',
          cell: DollarCell,
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'civilPenaltyApplied',
          label: 'Applied to Civil Penalty',
          cell: DollarCell,
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'globalSettlementApplied',
          label: 'Applied to Global Settlement',
          cell: DollarCell,
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'checkNumberDisplay',
          label: 'Check Number',
          cell: 'string',
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'transmittalNumberDisplay',
          label: 'Transmittal Number',
          cell: 'string',
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'editDeleteMultiButtonCell',
          label: '',
          width: 10,
          cell: MultiButtonCell.extend({
            buttons: [
              {
                name: '',
                labelClass: 'fa fa-edit',
                buttonClass: 'btn-sm btn-primary edit-payment',
                onClick: this.onClickEditPayment.bind(this)
              },
              {
                name: '',
                labelClass: 'fa fa-trash',
                buttonClass: 'btn-danger btn-sm delete-payment',
                onClick: this.onClickDelete.bind(this)
              }
            ]
          }),
          editable: false,
          sortable: false
        }
      ],
      emptyText: 'No Payments',
      collection: new Backbone.Collection(payments)
    });
    this.showChildView('paymentGrid', this.paymentGrid);
  },

  onClickEditCosts: function(e) {
    e.preventDefault();

    UIUtil.showModal({
      title: 'Edit Costs',
      view: new SecComplianceCostEditModalView({ model: this.model })
    }).done(
      function(totals) {
        this.model.set('adminAssessments', totals.adminAssessments);
        this.model.set('investigativeCosts', totals.investigativeCosts);
        this.model.set('legalCosts', totals.legalCosts);
        this.model.set('civilPenalty', totals.civilPenalty);
        this.model.set('restitutionAgreedByOrder', totals.restitutionAgreedByOrder);
        this.model.set('globalSettlement', totals.globalSettlement);

        this.paymentTotals = new SecCompliancePaymentTotalsView({
          model: this.model
        });

        this.showChildView('paymentTotals', this.paymentTotals);
      }.bind(this)
    );
  },

  onClickAddPayment: function(e) {
    e.preventDefault();

    UIUtil.showModal({
      title: 'Add Payment',
      view: new SecCompliancePaymentModalView({
        model: new Backbone.Model({
          complianceId: this.model.get('complianceId')
        })
      })
    }).done(
      function(payment) {
        this.paymentGrid.collection.push(payment);

        this.model.set('payments', this.paymentGrid.collection.toJSON());

        this.paymentTotals = new SecCompliancePaymentTotalsView({
          model: this.model
        });
        this.showChildView('paymentTotals', this.paymentTotals);
      }.bind(this)
    );
  },

  onClickEditPayment: function(e, originalPayment) {
    e.preventDefault();

    UIUtil.showModal({
      title: 'Edit Payment',
      view: new SecCompliancePaymentModalView({
        model: originalPayment
      })
    }).done(
      function(updatedPayment) {
        originalPayment.set(updatedPayment);

        this.model.set('payments', this.paymentGrid.collection.toJSON());

        this.paymentTotals = new SecCompliancePaymentTotalsView({
          model: this.model
        });
        this.showChildView('paymentTotals', this.paymentTotals);
      }.bind(this)
    );
  },

  onClickDelete: function(e, payment) {
    e.preventDefault();
    var self = this;
    var compliancePaymentId = payment.get('compliancePaymentId');

    UIUtil.showModal({
      title: 'Confirm Deleting Payment',
      view: new ConfirmationModalView({
        confirmText: 'Delete Payment',
        model: new Backbone.Model({
          message: 'Are you sure you would like to delete this payment?',
          onConfirm: function() {
            var data = {
              compliancePaymentId: compliancePaymentId,
              isDeleted: '1'
            };

            return SubmitCompliancePayment(data);
          },
          onConfirmDone: function() {
            self.paymentGrid.collection.remove(payment);

            var payments = self.model.get('payments');
            payments = _.without(payments, _.findWhere(payments, { compliancePaymentId: compliancePaymentId }));
            self.model.set('payments', payments);

            self.paymentTotals = new SecCompliancePaymentTotalsView({
              model: self.model
            });
            self.showChildView('paymentTotals', self.paymentTotals);
          }
        })
      })
    });
  }
});
