/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function() {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetUnverifiedEntities: {
        $xmlns: 'http://dobs.pa.gov/nd/entity/db/1.0'
      }
    },
    blueprintArrays: ['GetUnverifiedEntitiesResponse.tuple'],
    blueprint: {
      unverifiedEntities: [
        '$..tuple',
        {
          id: '$..ID',
          entityName: '$..EntityName',
          dba: '$..DBA'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      _.each(response.unverifiedEntities, function(unverifiedEntity) {
        unverifiedEntity.entityName = unverifiedEntity.entityName || '--';
        unverifiedEntity.dba = unverifiedEntity.dba || '--';
      });

      deferred.resolve(response.unverifiedEntities);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
