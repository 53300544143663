var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "          <option value=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.type || (depth0 != null ? depth0.type : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"type","hash":{},"data":data}) : helper)))
    + "</option>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "\r\n<form class=\"applicant-form\">\r\n  <div class=\"row\">\r\n    <div class=\"col-sm-12\">\r\n      <div class=\"form-group required\">\r\n        <label class=\"control-label\">Name of Depository Institution/Holding Company whose shares are to be acquired.</label>\r\n        <input type=\"text\" name=\"acquiredEntityName\" class=\"form-control\" placeholder=\"Name\"\r\n          data-parsley-maxlength=\"200\" data-parsley-required=\"true\" />\r\n      </div>\r\n    </div>\r\n  </div>\r\n  <div class=\"row\">\r\n    <div class=\"col-md-6 col-sm-12\">\r\n      <div class=\"form-group required\">\r\n        <label class=\"control-label\">Type of Applicant</label>\r\n        <select name=\"applicantTypeId\" class=\"form-control\" data-parsley-required=\"true\">\r\n          <option value=\"\" selected>--</option>\r\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.applicantTypes : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </select>\r\n      </div>\r\n    </div>\r\n  </div>\r\n  <div class=\"row\">\r\n    <div class=\"col-md-6 col-sm-12\">\r\n      <div class=\"form-group required\">\r\n        <label class=\"control-label\">Type of Acquisition</label>\r\n        <select name=\"acquisitionTypeId\" class=\"form-control\" data-parsley-required=\"true\">\r\n          <option value=\"\" selected>--</option>\r\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.acquisitionTypes : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </select>\r\n      </div>\r\n    </div>\r\n  </div>\r\n  <div class=\"row applicant-count-section\">\r\n    <div class=\"col-md-6 col-sm-12\">\r\n      <div class=\"form-group\">\r\n        <label class=\"control-label\"># of Individuals</label>\r\n        <input type=\"text\" name=\"applicantCount\" class=\"form-control\" disabled />\r\n        <p class=\"input-label\">Populates automatically based on each additional applicant added</p>\r\n      </div>\r\n    </div>\r\n  </div>\r\n  <div class=\"applicants-container\"></div>\r\n  <p>\r\n    <button class=\"btn btn-primary btn-md add-applicant\">Add Additional Applicant</button>\r\n  </p>\r\n</form>";
},"useData":true});