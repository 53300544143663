/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');
var Ladda = require('ladda');

var BreadcrumbView = require('common/views/BreadcrumbView');

var ConSerIndividualFormView = require('./ConSerIndividualFormView');

var SubmitIndividual = require('../services/SubmitIndividual');

var tmplConSerIndividualEditView = require('../templates/ConSerIndividualEditView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplConSerIndividualEditView,

  className: 'con-ser-individual-edit-view container-fluid',

  ui: {
    breadcrumb: '.breadcrumb-container',
    individualContainer: '.individual-form-container',
    submit: '.submit'
  },

  regions: {
    breadcrumb: '@ui.breadcrumb',
    individualContainer: '@ui.individualContainer'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeShow: function() {
    var individual = this.model.get('individual') || {};
    var individualId = individual.individualId;

    var breadcrumbs = [
      {
        link: '#conser/dashboard',
        text: 'Home'
      },
      {
        link: '#conser/individual/' + individualId + '/dashboard',
        text: individual.firstName + ' ' + individual.lastName
      },
      {
        text: 'Edit Individual Details'
      }
    ];

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: breadcrumbs
      })
    );

    this.individualForm = new ConSerIndividualFormView({
      model: new Backbone.Model(this.model.get('individual'))
    });
    this.showChildView('individualContainer', this.individualForm);
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;
    this.validate().done(function() {
      var l = Ladda.create(self.ui.submit[0]);
      l.start();
      var formData = self.getFormData();
      SubmitIndividual(formData)
        .done(function() {
          Radio.channel('navigate').trigger('show:int:cs:individual', self.model.get('individualId'));
        })
        .fail(function() {
          l.stop();
        });
    });
  },

  getFormData: function() {
    var individual = this.model.get('individual') || {};
    var individualId = individual.individualId;

    var formData = this.individualForm.getFormData();

    formData.individualId = individualId;

    return formData;
  },

  validate: function() {
    return this.ui.individualContainer.parsley().whenValidate();
  }
});
