/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Backbone = require('backbone');
var _get = require('lodash.get');

var GetExamDetailsForInternal = require('../../../common/services/GetExamDetailsForInternal');
var GetExamDetailsForEntity = require('../../../common/services/GetExamDetailsForEntity');
var GetLicenseContactByContactType = require('../../../common/services/GetLicenseContactByContactType');

var ExaminerSetUpExamTaskView = require('./ExaminerSetUpExamTaskView');

module.exports = {
  event: 'build:nd:examiner-setup-exam',

  channel: 'task',

  builder: function(task) {
    var defer = $.Deferred();

    var examId = _get(task, 'taskData.ND.examId');
    var taskTypeName = _get(task, 'taskType.name');

    if (!examId || !taskTypeName) {
      defer.reject('Task missing required data');
      return defer.promise();
    }

    $.when(GetExamDetailsForInternal(examId), GetExamDetailsForEntity(examId)).done(function(interalExam, entityExam) {
      var data = { licenseId: entityExam.licenseId, licenseContactTypeId: 2 };
      GetLicenseContactByContactType(data).done(function(examContacts) {
        defer.resolve(
          new ExaminerSetUpExamTaskView({
            model: new Backbone.Model({
              task: task,
              exam: _.extend(interalExam, entityExam),
              examContacts: examContacts
            })
          })
        );
      });
    });
    return defer.promise();
  }
};
