/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var PageableCollection = require('psa-backbone/models/PageableCollection');
var GridView = require('psa-marionette/views/GridView');

var BreadcrumbView = require('common/views/BreadcrumbView');
var userHasRole = require('common/util/userHasRole');
var ButtonLinkCell = require('common/backgrid/ButtonLinkCell');
var MomentCell = require('common/backgrid/MomentCell');
var LinkCell = require('common/backgrid/LinkCell');

var NonDepInternalSidebarNavView = require('../../common/views/NonDepInternalSidebarNavView');

require('../styles/NonDepInternalCurrentExamsView.css');
var tmplNonDepInternalCurrentExamsView = require('../templates/NonDepInternalCurrentExamsView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepInternalCurrentExamsView,

  className: 'non-dep-internal-current-exams-view container-fluid',

  ui: {
    sideBar: '.side-bar-container',
    exams: '.exams-container',
    breadcrumb: '.breadcrumb-container',
    scheduleDate: '.schedule-date',
    applyButton: '#apply'
  },

  regions: {
    sideBar: '@ui.sideBar',
    exams: '@ui.exams',
    breadcrumb: '@ui.breadcrumb'
  },

  events: {
    'click @ui.assignExaminers': 'onClickAssignExaminers',
    'click @ui.issueExams': 'onClickIssueExams',
    'click @ui.applyButton': 'onClickApply'
  },

  templateHelpers: function() {
    var showIssueExamsBtn = false;
    var showAssignExaminersBtn = false;
    if (userHasRole('ND - Exam Director')) {
      showIssueExamsBtn = true;
    }
    if (
      userHasRole('ND - Regional Director West') ||
      userHasRole('ND - Regional Director East') ||
      userHasRole('ND - Regional Director Harrisburg')
    ) {
      showAssignExaminersBtn = true;
    }
    return {
      showAssignExaminersBtn: showAssignExaminersBtn,
      showIssueExamsBtn: showIssueExamsBtn
    };
  },

  onBeforeShow: function() {
    var examYear = this.model.get('examYear');

    this.showChildView('sideBar', new NonDepInternalSidebarNavView());

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#nondep/dashboard/exams',
            text: 'Exams'
          },
          {
            text: examYear + ' Exam Year'
          }
        ]
      })
    );

    // initialize schedule date input field
    this.ui.scheduleDate.datepicker();

    // .clone() didn't work as needed for pageable collection
    this.collection = new PageableCollection(this.model.get('exams'), { pageSize: 25 });
    this.shadowCollection = new PageableCollection(this.model.get('exams'), { pageSize: 25 });

    var examYearGridView = new GridView({
      columns: [
        {
          name: 'entityName',
          label: 'Entity Name',
          cell: LinkCell.extend({
            emptyText: '--',
            href: function(model) {
              var entityId = model.get('entityId');
              var url = entityId ? '#nondep/entity/' + entityId + '/dashboard' : '';
              return url;
            }
          }),
          headerCell: 'custom',
          width: 18,
          editable: false
        },
        {
          name: 'examiners',
          label: 'Examiner(s)',
          cell: 'string',
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'licenseNumber',
          label: 'License Number',
          cell: 'string',
          headerCell: 'custom',
          width: 8,
          editable: false
        },
        {
          name: 'licenseType',
          label: 'License Type',
          cell: LinkCell.extend({
            emptyText: '--',
            href: function(model) {
              var entityId = model.get('entityId');
              var licenseId = model.get('licenseId');
              var url = entityId && licenseId ? '#nondep/entity/' + entityId + '/license/' + licenseId : '';
              return url;
            }
          }),
          headerCell: 'custom',
          width: 12,
          editable: false
        },
        {
          name: 'licenseTypeId',
          label: 'License Type ID',
          cell: 'string',
          headerCell: 'custom',
          width: 1,
          editable: false,
          renderable: false
        },
        {
          name: 'region',
          label: 'Region',
          cell: 'string',
          headerCell: 'custom',
          width: 8,
          editable: false
        },
        {
          name: 'examStartDate',
          label: 'Exam Start Date',
          cell: MomentCell.MomentCell,
          formatter: MomentCell.MomentDateFormatter.extend({
            emptyValue: '--'
          }),
          sortValue: MomentCell.MomentSortValue,
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'examEndDate',
          label: 'Exam End Date',
          cell: MomentCell.MomentCell,
          formatter: MomentCell.MomentDateFormatter.extend({
            emptyValue: '--'
          }),
          sortValue: MomentCell.MomentSortValue,
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'examDate',
          label: 'Schedule Date',
          cell: MomentCell.MomentCell,
          formatter: MomentCell.MomentDateFormatter.extend({
            emptyValue: '--'
          }),
          sortValue: MomentCell.MomentSortValue,
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'status',
          label: 'Status',
          cell: 'string',
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'statusId',
          label: 'StatusId',
          cell: 'string',
          headerCell: 'custom',
          width: 1,
          editable: false,
          renderable: false
        },
        {
          name: 'primaryExaminerUserId',
          label: '',
          cell: 'string',
          headerCell: 'custom',
          width: 1,
          editable: false,
          renderable: false
        },
        {
          name: '',
          label: '',
          cell: ButtonLinkCell.extend({
            name: 'Open',
            newTab: true,
            buttonClass: 'btn-primary btn-sm pull-right',
            href: function(model) {
              return '#nondep/dashboard/exam/' + model.get('examId');
            }
          }),
          headerCell: 'custom',
          sortable: false,
          width: 8,
          editable: false
        }
      ],
      collection: this.shadowCollection,
      emptyText: 'No Exams for Selected Period'
    });
    this.showChildView('exams', examYearGridView);
    examYearGridView.getViewGrid().sort('examDate', 'ascending');
  },

  onClickApply: function(e) {
    e.preventDefault();
    var self = this;
    var filter = Syphon.serialize(this);
    filter = _.pick(filter, _.identity);
    this.shadowCollection.fullCollection.reset(this.collection.fullCollection.models);
    var models;
    _.each(filter, function(filterVal, filterType, filter) {
      models = self.shadowCollection.fullCollection.filter(function(model) {
        return self.filter(model, filterType, filterVal);
      });
      self.shadowCollection.fullCollection.reset(models);
    });
  },

  filter: function(shadow, filterType, filterVal) {
    if (filterType === 'licenseTypeId' || filterType === 'statusId' || filterType === 'primaryExaminerUserId') {
      return shadow.attributes[filterType] === filterVal;
    } else if (filterType === 'examDate') {
      if (shadow.attributes[filterType]) {
        return shadow.attributes[filterType].isSame(filterVal);
      }
    } else {
      var temp = isNaN(shadow.attributes[filterType])
        ? shadow.attributes[filterType].toLowerCase()
        : shadow.attributes[filterType];
      filterVal = isNaN(filterVal) ? filterVal.toLowerCase() : filterVal;
      return typeof temp === 'string' && temp.indexOf(filterVal) > -1;
    }
  }
});
