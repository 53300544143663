/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var NProgress = require('nprogress');

var NavUtils = require('psa-core/util/nav');

var Scaffold = require('common/layout/Scaffold');

var NonDepIndividualDashboardView = require('../views/NonDepIndividualDashboardView');

var GetIndividualGeneralInformation = require('../services/GetIndividualGeneralInformation');

module.exports = function(individualId) {
  NProgress.start();
  NProgress.set(0.6);
  GetIndividualGeneralInformation(individualId).done(function(individualDetails) {
    NavUtils.navigate('#nondep/individual/' + individualId + '/dashboard');
    Scaffold.content.show(
      new NonDepIndividualDashboardView({
        viewMode: 'int',
        model: new Backbone.Model({
          entityId: individualDetails.entityId,
          entityName: individualDetails.entityName,
          licenseId: individualDetails.licenseId,
          licenseNumber: individualDetails.licenseNumber,
          licenseType: individualDetails.licenseType,
          individualId: individualId,
          individual: individualDetails
        }),
        isReadOnly: false
      })
    );
    NProgress.done();
  });
};
