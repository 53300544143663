/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');

var EmptyView = require('../EmptyView');

require('./BigTabsContentView.css');
var tmplBigTabsContentView = require('./BigTabsContentView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplBigTabsContentView,

  id: function() {
    return this.model.get('id');
  },

  attributes: {
    role: 'tabpanel'
  },

  className: function() {
    var baseClass = 'big-tabs-content-view tab-pane';
    if (this.options.childIndex === 0) {
      return baseClass + ' active';
    }
    return baseClass;
  },

  ui: {
    contentContainer: '.content-container'
  },

  regions: {
    contentContainer: '@ui.contentContainer'
  },

  onBeforeShow: function() {
    var viewToRender = this.model.get('view');
    if (!viewToRender) {
      viewToRender = new EmptyView();
    }
    this.showChildView('contentContainer', viewToRender);
  }
});
