/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var cleanUpResponse = require('common/util/cleanUpResponse');

var userHasRole = require('common/util/userHasRole');

module.exports = function(examPeriodId) {
  var defer = $.Deferred();

  var regionIds = [];
  if (userHasRole('ND - Regional Director Harrisburg')) {
    regionIds.push(3);
  }
  if (userHasRole('ND - Regional Director West')) {
    regionIds.push(2);
  }
  if (userHasRole('ND - Regional Director East')) {
    regionIds.push(1);
  }
  $.soap({
    body: {
      GetAssignExamDashboard: {
        $xmlns: 'http://dobs.pa.gov/nd/exam/bpm/1.0',
        ExamPeriodID: examPeriodId,
        RegionIDs: {
          value: regionIds
        }
      }
    },
    blueprintArrays: ['GetAssignExamDashboardResponse.ND_Exam'],
    blueprint: {
      exams: [
        '$..ND_Exam',
        {
          examId: '$.ExamID',
          entityName: '$.EntityName',
          entityId: '$.EntityID',
          licenseId: '$.LicenseID',
          licenseNumber: '$.LicenseNumber',
          licenseType: '$.LicenseType',
          region: '$.Region',
          county: '$.County',
          address: {
            addressLine1: '$.AddressLine1',
            addressLine2: '$.AddressLine2',
            addressLine3: '$.AddressLine3',
            city: '$.City',
            postalCode: '$.PostalCode',
            state: '$.State',
            country: '$.Country'
          }
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      defer.resolve(response.exams);
    })
    .fail(function(err) {
      defer.reject(err);
    });

  return defer.promise();
};
