/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var toHash = require('common/util/toHash');
var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(categoryId) {
  var defer = $.Deferred();

  $.soap({
    body: {
      GetAllEntityLookups: {
        $xmlns: 'http://dobs.pa.gov/dp/lookup/bpm/1.0'
      }
    },
    blueprintArrays: [
      'GetAllEntityLookups.CharterTypes.DP_LU_EntityCharterType',
      'GetAllEntityLookups.CorporateStructures.DP_LU_EntityCorporateStructure',
      'GetAllEntityLookups.Divisions.DP_LU_EntityDivision',
      'GetAllEntityLookups.FederalRegulators.DP_LU_EntityFedRegulator',
      'GetAllEntityLookups.FOMTypes.DP_LU_EntityFOMType',
      'GetAllEntityLookups.OwnershipStructures.DP_LU_EntityOwnershipStructure',
      'GetAllEntityLookups.Statuses.DP_LU_EntityStatus',
      'GetAllEntityLookups.Types.DP_LU_EntityType',
      'GetAllEntityLookups.Categories.DP_LU_EntityCategory',
      'GetAllEntityLookups.FFDeptActions.DP_LU_FFDepartmentAction',
      'GetAllEntityLookups.FieldSupervisors.DP_LU_EntityFieldSupervisor',
      'GetAllEntityLookups.NextExamConductedBys.DP_LU_EntityNextExamConductedBy',
      'GetAllEntityLookups.NextExamTypes.DP_LU_EntityNextExamType'
    ],
    blueprint: {
      charterTypes: [
        '$..DP_LU_EntityCharterType',
        {
          id: '$.ID',
          type: '$.Type',
          categoryId: '$.CategoryID'
        }
      ],
      corporateStructures: [
        '$..DP_LU_EntityCorporateStructure',
        {
          id: '$.ID',
          type: '$.Type'
        }
      ],
      divisions: [
        '$..DP_LU_EntityDivision',
        {
          id: '$.ID',
          division: '$.Division'
        }
      ],
      federalRegulators: [
        '$..DP_LU_EntityFedRegulator',
        {
          id: '$.ID',
          regulator: '$.Regulator'
        }
      ],
      FOMTypes: [
        '$..DP_LU_EntityFOMType',
        {
          id: '$.ID',
          type: '$.Type'
        }
      ],
      ownershipStructures: [
        '$..DP_LU_EntityOwnershipStructure',
        {
          id: '$.ID',
          structure: '$.Structure'
        }
      ],
      statuses: [
        '$..DP_LU_EntityStatus',
        {
          id: '$.ID',
          status: '$.Status'
        }
      ],
      entityTypes: [
        '$..DP_LU_EntityType',
        {
          id: '$.ID',
          type: '$.Type',
          categoryId: '$.CategoryID'
        }
      ],
      entityCategories: [
        '$..DP_LU_EntityCategory',
        {
          id: '$.ID',
          category: '$.Category'
        }
      ],
      FFDeptActions: [
        '$..DP_LU_FFDepartmentAction',
        {
          id: '$.ID',
          action: '$.Action'
        }
      ],
      fieldSupervisors: [
        '$..DP_LU_EntityFieldSupervisor',
        {
          id: '$.ID',
          name: '$.Name',
          division_cd: '$.Division_cd'
        }
      ],
      nextExamConductedBys: [
        '$..DP_LU_EntityNextExamConductedBy',
        {
          id: '$.ID',
          conductedBy: '$.ConductedBy',
          categoryId: '$.CategoryID'
        }
      ],
      nextExamTypes: [
        '$..DP_LU_EntityNextExamType',
        {
          id: '$.ID',
          type: '$.Type',
          categoryId: '$.CategoryID'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      if (categoryId) {
        response.charterTypes = _.where(response.charterTypes, { categoryId: categoryId });
        response.entityTypes = _.where(response.entityTypes, { categoryId: categoryId });
        response.nextExamConductedBys = _.where(response.nextExamConductedBys, { categoryId: categoryId });
        response.nextExamTypes = _.where(response.nextExamTypes, { categoryId: categoryId });
      }

      // Create key value pairs object of [categoryId]: categoryName
      var categoryMap = toHash(response.entityCategories, 'id', 'category');

      _.each(response.entityTypes, function(entityType) {
        entityType.categoryName = categoryMap[entityType.categoryId];
        entityType.typeWithCategory = entityType.type;
        if (entityType.categoryName) {
          entityType.typeWithCategory = entityType.categoryName + ' - ' + entityType.type;
        }
      });

      defer.resolve(response);
    })
    .fail(function(err) {
      defer.reject(err);
    });

  return defer.promise();
};
