/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var NProgress = require('nprogress');
var Radio = require('backbone.radio');
var NavUtils = require('psa-core/util/nav');

var Scaffold = require('common/layout/Scaffold');

var GetFilingDetails = require('../../common/services/GetFilingDetails');

var DepExtActiveFilingView = require('../views/DepExtActiveFilingView');

module.exports = function(filingId) {
  NProgress.start();
  NProgress.set(0.6);

  GetFilingDetails(filingId)
    .done(function(filingDetails) {
      NProgress.set(0.6);
      var formIdentifier = filingDetails.formIdentifier;
      var BuildFilingPromise = Radio.channel('dp:filing').request('build:' + formIdentifier, {
        filingId: filingId,
        entityCategoryId: filingDetails.entityCategoryId,
        mode: 'ext-new',
        isReadOnly: true
      });
      BuildFilingPromise.done(function(filingFormView) {
        NavUtils.navigate('ext/dep/filing/' + filingId + '/view');
        filingFormView.model.set({
          filingId: filingId,
          filingDetails: filingDetails
        });
        Scaffold.content.show(
          new DepExtActiveFilingView({
            viewMode: 'ext',
            filingFormView: filingFormView,
            model: new Backbone.Model({
              filingId: filingId,
              filingDetails: filingDetails
            })
          })
        );
        NProgress.done();
      }).fail(function(err) {
        NProgress.done();
      });
    })
    .fail(function(err) {
      NProgress.done();
    });
};
