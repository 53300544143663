/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Syphon = require('backbone.syphon');
var Marionette = require('backbone.marionette');

var parsleyUtil = require('common/util/parsleyUtil');
var makeUploadCollection = require('common/util/makeUploadCollection');

var toggleForm = require('common/util/toggleForm');
var modelGet = require('common/util/modelGet');

var AddressView = require('common/views/AddressView');
var CommentsDocumentView = require('common/views/CommentsDocumentView');

var tmplDepBankLocationActivitiesConvertSubFormView = require('./DepBankLocationActivitiesConvertSubFormView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepBankLocationActivitiesConvertSubFormView,

  className: 'dep-bank-location-activities-convert-sub-form-view form-divider',

  ui: {
    actionForm: '.action-form',
    newLocationType: '.new-location-type',
    useCurrentContactCheckbox: '.use-current-contact-checkbox',
    LPOContact: '.lpo-contact',
    contactAddress: '.contact-address',
    questionnaireContainer: '.questionnaire-container',
    fixedAssetsContainer: '.fixed-assets-container',
    // LPO Contact fields to auto-fill on checkbox
    contactFirstName: '.contact-first-name',
    conactLastName: '.contact-last-name',
    contactMiddleInitial: '.contact-middle-initial',
    contactTitle: '.contact-title',
    contactPhone: '.contact-phone',
    contactEmail: '.contact-email'
  },

  regions: {
    contactAddress: '@ui.contactAddress',
    questionnaireContainer: '@ui.questionnaireContainer',
    fixedAssetsContainer: '@ui.fixedAssetsContainer'
  },

  events: {
    'change @ui.newLocationType': 'onChangeNewLocationType',
    'change @ui.useCurrentContactCheckbox': 'onChangeUseCurrentContactCheckbox'
  },

  templateHelpers: function() {
    return {
      locationTypes: this.options.locationTypes,
      childIndex: this.options.childIndex,
      currentlyLPO: this.model.get('locationTypeId') === '5'
    };
  },

  onBeforeShow: function() {
    var locationAction = this.model.get('locationAction') || {};

    var contactAddress = locationAction.contactAddress || {};

    var questionnaire = locationAction.questionnaire || {};
    var fixedAssets = locationAction.fixedAssets || {};

    this.contactAddress = new AddressView({
      model: new Backbone.Model(contactAddress),
      objectName: 'contactAddress',
      horizontal: false,
      isRequired: false,
      startingCountry: contactAddress.country,
      startingPostalCode: contactAddress.postalCode,
      startingCounty: contactAddress.county
    });
    this.showChildView('contactAddress', this.contactAddress);

    Syphon.deserialize(this, this.model.get('locationAction'));
    this.onChangeNewLocationType();

    this.questionnaire = new CommentsDocumentView({
      isReadOnly: this.options.isReadOnly,
      allowMultipleFiles: false,
      isRequired: true,
      fileUploadLabel: 'Questionnaire',
      commentsLabel: 'Questionnaire Comments',
      commentsName: 'questionnaireComments',
      uniqueId: modelGet(this.model, 'locationAction.locationActivityId'),
      collection: makeUploadCollection(questionnaire.file),
      model: new Backbone.Model({ questionnaireComments: questionnaire.questionnaireComments }),
      documentTypeId: 20001
    });
    this.showChildView('questionnaireContainer', this.questionnaire);

    this.fixedAssets = new CommentsDocumentView({
      isReadOnly: this.options.isReadOnly,
      allowMultipleFiles: false,
      isRequired: true,
      fileUploadLabel: 'Location Fixed Assets Investments Ratio Calculation',
      commentsLabel: 'Location Fixed Assets Investments Ratio Calculation Comments',
      commentsName: 'fixedAssetsComments',
      uniqueId: modelGet(this.model, 'locationAction.locationActivityId'),
      collection: makeUploadCollection(fixedAssets.file),
      model: new Backbone.Model({ fixedAssetsComments: fixedAssets.fixedAssetsComments }),
      documentTypeId: 20001
    });
    this.showChildView('fixedAssetsContainer', this.fixedAssets);
  },

  onChangeNewLocationType: function() {
    var newLocationTypeId = this.ui.newLocationType.val();
    var isLPO = newLocationTypeId === '4' || newLocationTypeId === '25';

    toggleForm(this.ui.LPOContact, isLPO);
  },

  onChangeUseCurrentContactCheckbox: function() {
    if (this.ui.useCurrentContactCheckbox.prop('checked')) {
      this.ui.contactFirstName.val(this.model.get('contactFirstName'));
      this.ui.conactLastName.val(this.model.get('contactLastName'));
      this.ui.contactMiddleInitial.val(this.model.get('contactMiddleInitial'));
      this.ui.contactTitle.val(this.model.get('contactTitle'));
      this.ui.contactPhone.val(this.model.get('contactPhone'));
      this.ui.contactEmail.val(this.model.get('contactEmail'));

      var currContactAddress = this.model.get('currentContactAddress');
      this.contactAddress.setAddress(currContactAddress);
    }
  },

  validate: function(config) {
    this.ui.actionForm.parsley().reset();
    this.questionnaire.removeValidationErrors();
    this.fixedAssets.removeValidationErrors();

    if (config.type === 'save') {
      // Turn off required field validation for this form
      parsleyUtil.disableRequiredValidation(this.ui.actionForm);
    } else {
      var validationPromises = [
        this.ui.actionForm.parsley().whenValidate(),
        this.questionnaire.validate(),
        this.fixedAssets.validate()
      ];

      // Turn on required field validation for this form
      parsleyUtil.enableRequiredValidation(this.ui.actionForm);
    }
    return $.when.apply($, validationPromises);
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    formData.fixedAssetsDocId = this.fixedAssets.getFirstFileId();
    formData.questionnaireDocId = this.questionnaire.getFirstFileId();
    return formData;
  }
});
