/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var moment = require('moment');

module.exports = function(searchBody, dob) {
  var deferred = $.Deferred();
  $.soap({
    body: {
      PerformElasticSearch: {
        $xmlns: 'http://dobs.pa.gov/cm/search/bpm/1.0',
        IndexName: 'nd-individual',
        SearchBody: JSON.stringify(searchBody)
      }
    },
    blueprint: {
      searchResults: '$..Response'
    }
  })
    .done(function(result) {
      var searchResult = JSON.parse(result.searchResults);
      // For Testing
      // console.log(JSON.stringify(searchResult, null, 2));
      var hits = _.map(searchResult.hits.hits, function(hit) {
        var result = hit._source;
        result.ssn = result.ssn || 'N/A';
        result.nmlsId = result.nmlsId || '--';
        result.title = result.title || '--';
        result.homePhoneNumber = result.homePhoneNumber || '--';
        result.address = {
          addressLine1: result.addressLine1,
          addressLine2: result.addressLine2,
          addressLine3: result.addressLine3,
          city: result.city,
          state: result.state,
          postalCode: result.postalCode,
          country: result.country
        };
        result.highlight = hit.highlight || {};
        if (dob && result.dob) {
          var queryBirthday = moment(dob, 'MM/DD/YYYY');
          var resultBirthday = moment(result.dob, moment.ISO_8601);
          if (queryBirthday.isSame(resultBirthday, 'date')) {
            result.highlight.dob = ['<em>' + resultBirthday.format('MM/DD/YYYY') + '</em>'];
          }
        }
        return result;
      });

      var results = {
        hits: hits,
        totalHits: searchResult.hits.total.value,
        took: searchResult.took
      };
      deferred.resolve(results);
    })
    .fail(function(err) {
      deferred.reject(err);
    });

  return deferred.promise();
};
