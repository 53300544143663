/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var NProgress = require('nprogress');
var _ = require('underscore');

var NavUtils = require('psa-core/util/nav');

var Scaffold = require('common/layout/Scaffold');

var GetExamDetailsForEntity = require('../../common/services/GetExamDetailsForEntity');
var GetExamDetailsForInternal = require('../../common/services/GetExamDetailsForInternal');
var GetUsersForRole = require('common/services/GetUsersForRole');

var NonDepInternalExamModifyExaminerView = require('../views/NonDepInternalExamModifyExaminerView');

module.exports = function(examId) {
  NProgress.start();
  NProgress.set(0.6);

  $.when(GetExamDetailsForEntity(examId), GetExamDetailsForInternal(examId), GetUsersForRole('ND - Examiner')).done(
    function(externalDetails, internalDetails, examiners) {
      NavUtils.navigate('nondep/dashboard/exam/' + examId + '/modify-examiner');
      Scaffold.content.show(
        new NonDepInternalExamModifyExaminerView({
          model: new Backbone.Model({
            examId: examId,
            exam: _.extend(externalDetails, internalDetails),
            examiners: examiners
          })
        })
      );
      NProgress.done();
    }
  );
};
