/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Syphon = require('backbone.syphon');
var Marionette = require('backbone.marionette');

var counties = require('./counties.json');

require('./AddressView.css');
var tmplAddressView = require('./AddressView.hbs');

module.exports = Marionette.ItemView.extend({
  template: tmplAddressView,

  options: {
    horizontal: true,
    objectName: null,
    startingCountry: 'US',
    startingPostalCode: null,
    startingCounty: null,
    isRequired: false,
    requiredFields: {
      addressLine1: false
    }
  },

  className: function() {
    var baseClass = 'address-view';
    if (this.options.horizontal) {
      return baseClass + ' form-horizontal';
    }
    return baseClass;
  },

  templateHelpers: function() {
    return {
      horizontal: this.options.horizontal,
      objectName: this.options.objectName,
      isRequired: this.options.isRequired,
      addressLine1Required: this.options.isRequired || this.options.requiredFields.addressLine1
    };
  },

  ui: {
    countryGroup: '.country-group',
    country: '.country',
    stateTextInput: '.state-text-input',
    usStateSelect: '.us-states-select',
    countyContainer: '.county-container',
    addressLine3: '.address-line-3',
    postalCodeFormGroup: '.postal-code-form-group',
    postalCodeInput: '.zip-code-input',
    stateFormGroup: '.state-form-group',
    paCountySelect: '.pa-counties-select',
  },

  events: {
    'change @ui.country': 'onChangeCountry',
    'change @ui.postalCodeInput': 'onChangePostalCode'
  },

  onBeforeShow: function() {
    this.hideCountyOptions();
    if (this.options.startingCountry && this.options.startingCountry !== 'US') {
      this.setInternationalView();
    } else {
      this.setDomesticView();
    }
    if (this.options.startingPostalCode) {
      this.setStateAndCounty(this.options.startingPostalCode, this.options.startingCounty);
    }
  },

  onChangePostalCode: function() {
    this.setStateAndCounty(this.ui.postalCodeInput.val());
    // clear validation errors on state select after the county has changed.
    if (this.options.isRequired) {
      this.ui.usStateSelect.parsley().reset();
    }
  },

  hideCountyOptions: function() {
    this.ui.paCountySelect
      .children('option')
      .filter('[value!=""][value!="Out of State"]')
      .wrap('<span>')
      .parent()
      .hide();
  },

  setState: function(state) {
    var self = this;
    self.ui.usStateSelect.val(state);
  },

  setStateAndCounty: function(postalCode, county) {
    var self = this;
    this.hideCountyOptions();

    if (postalCode) {
      var fiveDigitZip = parseInt(postalCode.substring(0, 5));

      var countiesForZip = _.chain(counties)
        .where({ zipCode: fiveDigitZip })
        .pluck('county')
        .value();

      if (countiesForZip.length) {
        _.each(countiesForZip, function(element) {
          var option = self.ui.paCountySelect.find('option[value="' + element + '"]');
          option.parent().replaceWith(option);
          self.ui.usStateSelect.val('PA');
        });
        if (county && _.contains(countiesForZip, county)) {
          this.ui.paCountySelect.val(county);
        } else {
          this.ui.paCountySelect.val(countiesForZip[0]);
        }
      } else {
        this.ui.paCountySelect.val('Out of State');
      }
    } else {
      this.ui.paCountySelect.val('');
    }
  },

  onChangeCountry: function() {
    var activeCountry = this.ui.country.val();
    if (activeCountry === 'US') {
      this.setDomesticView();
    } else {
      this.setInternationalView();
    }
  },

  setDomesticView: function() {
    this.ui.countyContainer.show();
    this.ui.stateTextInput
      .val('')
      .prop('disabled', true)
      .hide();
    this.ui.addressLine3
      .val('')
      .prop('disabled', true)
      .hide();
    this.ui.usStateSelect.prop('disabled', false).show();
    if (this.options.isRequired) {
      this.ui.postalCodeFormGroup.addClass('required');
      this.ui.stateFormGroup.addClass('required');
      this.ui.postalCodeInput.attr('data-parsley-required', 'true');
    }
    this.ui.postalCodeInput.parsley().reset();
  },

  setInternationalView: function() {
    this.ui.addressLine3.prop('disabled', false).show();
    this.ui.stateTextInput.prop('disabled', false).show();
    this.ui.countyContainer.hide();
    this.ui.usStateSelect
      .val('')
      .prop('disabled', true)
      .hide();
    if (this.options.isRequired) {
      this.ui.postalCodeFormGroup.removeClass('required');
      this.ui.stateFormGroup.removeClass('required');
      this.ui.postalCodeInput.removeAttr('data-parsley-required');
    }
    this.ui.postalCodeInput.parsley().reset();
  },

  // Use only when dynamically populating address within a form
  setAddress: function(address) {
    if (this.options.objectName) {
      var objName = this.options.objectName;
      var addressObj = {};
      addressObj[objName] = address;

      Syphon.deserialize(this, addressObj);
      this.setInitialFormState(addressObj[objName]);
    } else {
      Syphon.deserialize(this, address);
      this.setInitialFormState(address);
    }
  },

  setInitialFormState: function(address) {
    this.hideCountyOptions();
    if (address.country && address.country !== 'US') {
      this.setInternationalView();
    }
    if (address.postalCode) {
      this.setStateAndCounty(address.postalCode, address.county);
    }
  }
});
