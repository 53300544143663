/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

module.exports = function(searchBody, formData, lookupHashes) {
  var deferred = $.Deferred();

  formData = formData || {};
  var entityTypeIds = formData.entityTypeIds;
  var charterTypeId = formData.charterTypeId;
  var ownershipStructureId = formData.ownershipStructureId;
  var corporateStructureId = formData.corporateStructureId;
  var federalRegulatorId = formData.federalRegulatorId;
  var statusId = formData.statusId;

  var entityTypes = lookupHashes.entityTypes || {};
  var charterTypes = lookupHashes.charterTypes || {};
  var ownershipStructures = lookupHashes.ownershipStructures || {};
  var corporateStructures = lookupHashes.corporateStructures || {};
  var federalRegulators = lookupHashes.federalRegulators || {};
  var statuses = lookupHashes.statuses || {};

  $.soap({
    body: {
      PerformElasticSearch: {
        $xmlns: 'http://dobs.pa.gov/cm/search/bpm/1.0',
        IndexName: 'dp-entity',
        SearchBody: JSON.stringify(searchBody)
      }
    },
    blueprint: {
      searchResults: '$..Response'
    }
  })
    .done(function(result) {
      var searchResult = JSON.parse(result.searchResults);
      // For Testing
      // console.log(JSON.stringify(searchResult, null, 2));
      var hits = _.map(searchResult.hits.hits, function(hit) {
        var result = hit._source;
        result.dbas = _.chain(result.dbas)
          .filter(function(dba) {
            return {
              value: dba.dba
            };
          })
          .pluck('dba')
          .value();
        result.charterType = '';
        result.ownershipStructure = '';
        result.corporateStructure = '';
        result.federalRegulator = '';
        result.status = '';
        // Set text result of lookup value results
        if (result.entityTypeId) {
          result.entityType = entityTypes[result.entityTypeId];
        }
        if (result.charterTypeId) {
          result.charterType = charterTypes[result.charterTypeId];
        }
        if (result.ownershipStructureId) {
          result.ownershipStructure = ownershipStructures[result.ownershipStructureId];
        }
        if (result.federalRegulatorId) {
          result.federalRegulator = federalRegulators[result.federalRegulatorId];
        }

        // Set highlights for lookup values
        result.highlight = hit.highlight || {};
        if (entityTypeIds) {
          var entityType = entityTypes[entityTypeIds[0]];
          if (entityType) {
            result.highlight.entityType = ['<em>' + entityType + '</em>'];
          }
        }
        if (charterTypeId) {
          var charterType = charterTypes[charterTypeId];
          if (charterType) {
            result.highlight.charterType = ['<em>' + charterType + '</em>'];
          }
        }
        if (ownershipStructureId) {
          var ownershipStructure = ownershipStructures[ownershipStructureId];
          if (ownershipStructure) {
            result.highlight.ownershipStructure = ['<em>' + ownershipStructure + '</em>'];
          }
        }
        if (corporateStructureId) {
          var corporateStructure = corporateStructures[corporateStructureId];
          if (corporateStructure) {
            result.highlight.corporateStructure = ['<em>' + corporateStructure + '</em>'];
          }
        }
        if (federalRegulatorId) {
          var federalRegulator = federalRegulators[federalRegulatorId];
          if (federalRegulator) {
            result.highlight.federalRegulator = ['<em>' + federalRegulator + '</em>'];
          }
        }
        if (statusId) {
          var status = statuses[statusId];
          if (status) {
            result.highlight.status = ['<em>' + status + '</em>'];
          }
        }
        return result;
      });
      var results = {
        hits: hits,
        totalHits: searchResult.hits.total.value,
        took: searchResult.took
      };
      deferred.resolve(results);
    })
    .fail(function(err) {
      deferred.reject(err);
    });

  return deferred.promise();
};
