/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

/**
 * @param {Object} options - Values that control how inactivity timer works
 * @param {number} options.inactivityMilliseconds -How many milliseconds before inactivity timer expires to trigger warning
 * @param {function} options.onInactivity - Callback when inactivity timer expires
 * @param {number} [options.warningMilliseconds] - How many milliseconds before auto-logout to show warning
 * @param {function} [options.onWarning] - Callback when warning timer expires
 * @param {function} [options.onTimerReset] - Callback whenever the inactivity timer resets
 */
function InactivityTimer(options) {
  this.inactivityMilliseconds = options.inactivityMilliseconds;
  this.onInactivity = options.onInactivity;
  this.inactivityTimer = null;

  this.warningMilliseconds = options.warningMilliseconds;
  this.onWarning = options.onWarning;
  this.warningTimer = null;

  this.onTimerReset = options.onTimerReset;

  this.restartRef = null;
}

InactivityTimer.prototype.start = function() {
  // A reference to the restart method has to be kept so it can be cleaned up
  // later.
  this.restartRef = this.restart.bind(this);
  document.addEventListener('click', this.restartRef);
  this.restart();
};

InactivityTimer.prototype.restart = function() {
  this._clearTimers();

  this.inactivityTimer = setTimeout(
    function() {
      this.onInactivity();
      this.stop();
    }.bind(this),
    this.inactivityMilliseconds
  );

  if (this.warningMilliseconds && this.onWarning) {
    this.warningTimer = setTimeout(
      function() {
        this.onWarning();
      }.bind(this),
      this.inactivityMilliseconds - this.warningMilliseconds
    );
  }

  if (this.onTimerReset && _.isFunction(this.onTimerReset)) {
    this.onTimerReset();
  }
};

InactivityTimer.prototype.stop = function() {
  document.removeEventListener('click', this.restartRef);
  this._clearTimers();
};

// Meant to be a private method, do not call directly.
InactivityTimer.prototype._clearTimers = function() {
  if (this.inactivityTimer) {
    clearTimeout(this.inactivityTimer);
  }
  if (this.warningTimer) {
    clearTimeout(this.warningTimer);
  }
};

module.exports = InactivityTimer;
