var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "      <span class=\"glyphicon glyphicon-remove\" aria-hidden=\"true\"></span> Deleted Entity Officer\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "      Entity Officer\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "    <button class=\"btn btn-danger btn-md remove-officer pull-right\">Remove</button>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "        <div class=\"col-sm-6\">\n          <div class=\"form-group required\">\n            <label class=\"control-label\">Effective Date of Change</label>\n            <div class=\"input-group\">\n              <input type=\"text\" name=\"effectiveChangeDate\" class=\"form-control datepicker effective-change-date\"\n                placeholder=\"Effective Date of Change\" data-parsley-required=\"true\" data-parsley-date=\"\"\n                data-parsley-trigger-after-failure=\"changeDate\"\n                data-parsley-errors-container=\"#effectiveChangeDateErrorsContainer\" />\n              <div class=\"input-group-addon\">\n                <i class=\"fa fa-calendar\"></i>\n              </div>\n            </div>\n            <div id=\"effectiveChangeDateErrorsContainer\"></div>\n          </div>\n        </div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "        <div class=\"col-sm-6\">\n          <div class=\"form-check m-t-lg\">\n            <input type=\"checkbox\" class=\"form-check-input remove-officer-checkbox\" name=\"isDeleted\" value=\"1\" />\n            <label class=\"form-check-label remove-officer-label\">Officer is being removed</label>\n          </div>\n        </div>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "              <option value=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</option>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <div class=\"input-label red-text\"><b>Previously: </b>"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.oldOfficer : depth0)) != null ? stack1.officerType : stack1), depth0))
    + "</div>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <div class=\"input-label red-text\"><b>Previously: </b>"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.oldOfficer : depth0)) != null ? stack1.title : stack1), depth0))
    + "</div>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <div class=\"input-label red-text\"><b>Previously: </b>"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.oldOfficer : depth0)) != null ? stack1.firstName : stack1), depth0))
    + "</div>\n";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <div class=\"input-label red-text\"><b>Previously: </b>"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.oldOfficer : depth0)) != null ? stack1.lastName : stack1), depth0))
    + "</div>\n";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <div class=\"input-label red-text\"><b>Previously: </b>"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.oldOfficer : depth0)) != null ? stack1.middleName : stack1), depth0))
    + "</div>\n";
},"23":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <div class=\"input-label red-text\"><b>Previously: </b>"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.oldOfficer : depth0)) != null ? stack1.ssn : stack1), depth0))
    + "</div>\n";
},"25":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <div class=\"input-label red-text\"><b>Previously: </b>"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.oldOfficer : depth0)) != null ? stack1.dob : stack1), depth0))
    + "</div>\n";
},"27":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <div class=\"input-label red-text\"><b>Previously: </b>"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.oldOfficer : depth0)) != null ? stack1.homePhoneNumber : stack1), depth0))
    + "</div>\n";
},"29":function(container,depth0,helpers,partials,data) {
    return " required";
},"31":function(container,depth0,helpers,partials,data) {
    return " data-parsley-required=\"true\" ";
},"33":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <div class=\"input-label red-text\"><b>Previously: </b>"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.oldOfficer : depth0)) != null ? stack1.cellPhoneNumber : stack1), depth0))
    + "</div>\n";
},"35":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        <div class=\"input-label red-text\"><b>Previously: </b>"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.oldOfficer : depth0)) != null ? stack1.email : stack1), depth0))
    + "</div>\n";
},"37":function(container,depth0,helpers,partials,data) {
    return "      <div class=\"row\">\n        <div class=\"col-sm-12 -m-t-sm\">\n          <div class=\"input-label red-text address-margin-bottom\"><b>Previously: </b>\n            <div class=\"old-address\"></div>\n          </div>\n        </div>\n      </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "\n<div class=\"panel-heading\" role=\"tab\" id=\"heading-"
    + alias4(((helper = (helper = helpers.modelId || (depth0 != null ? depth0.modelId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"modelId","hash":{},"data":data}) : helper)))
    + "\">\n  <div class=\"panel-title\">\n    <a data-toggle=\"collapse\" data-parent=\"#accordion\" href=\"#collapse-"
    + alias4(((helper = (helper = helpers.modelId || (depth0 != null ? depth0.modelId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"modelId","hash":{},"data":data}) : helper)))
    + "\" aria-expanded=\"true\"\n      aria-controls=\"collapse-"
    + alias4(((helper = (helper = helpers.modelId || (depth0 != null ? depth0.modelId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"modelId","hash":{},"data":data}) : helper)))
    + "\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isDeleted : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "    </a>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showDeleteOfficerButton : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  </div>\n</div>\n<div id=\"collapse-"
    + alias4(((helper = (helper = helpers.modelId || (depth0 != null ? depth0.modelId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"modelId","hash":{},"data":data}) : helper)))
    + "\" class=\"panel-collapse collapse in\" role=\"tabpanel\" aria-labelledby=\"heading-"
    + alias4(((helper = (helper = helpers.modelId || (depth0 != null ? depth0.modelId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"modelId","hash":{},"data":data}) : helper)))
    + "\">\n  <div class=\"panel-body\">\n    <form>\n      <div class=\"row\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isAmendment : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.entityOfficerId : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "      </div>\n      <div class=\"row\">\n        <div class=\"col-sm-6\">\n          <div class=\"form-group required\">\n            <label class=\"control-label\">Officer Type</label>\n            <select name=\"entityOfficerTypeId\" class=\"form-control\" data-parsley-required=\"true\">\n              <option value=\"\">--</option>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.officerTypes : depth0),{"name":"each","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            </select>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.amendmentChanges : depth0)) != null ? stack1.officerType : stack1),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "          </div>\n        </div>\n        <div class=\"col-sm-6\">\n          <div class=\"form-group required\">\n            <label class=\"control-label\">Title</label>\n            <input type=\"text\" name=\"title\" class=\"form-control\" placeholder=\"Title\" data-parsley-required=\"true\"\n              data-parsley-maxlength=\"100\" />\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.amendmentChanges : depth0)) != null ? stack1.title : stack1),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "          </div>\n        </div>\n      </div>\n      <div class=\"row\">\n        <div class=\"col-sm-6\">\n          <div class=\"form-group\">\n            <label class=\"control-label\">EIN/Fed ID</label>\n            <input type=\"text\" name=\"ein\" class=\"form-control ein\" placeholder=\"EIN/Fed ID\"\n              data-parsley-maxlength=\"20\" />\n          </div>\n        </div>\n        <div class=\"col-sm-6\">\n          <div class=\"form-group required\">\n            <label id=\"first-name-label\" class=\"control-label\">First Name</label>\n            <input type=\"text\" name=\"firstName\" class=\"form-control first-name\" placeholder=\"First Name\"\n              data-parsley-required=\"true\" data-parsley-maxlength=\"100\" />\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.amendmentChanges : depth0)) != null ? stack1.firstName : stack1),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "          </div>\n        </div>\n        <div class=\"col-sm-6\">\n          <div class=\"form-group required last-name-section\">\n            <label class=\"control-label\">Last Name</label>\n            <input type=\"text\" name=\"lastName\" class=\"form-control last-name\" placeholder=\"Last Name\"\n              data-parsley-required=\"true\" data-parsley-maxlength=\"100\" />\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.amendmentChanges : depth0)) != null ? stack1.lastName : stack1),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "          </div>\n        </div>\n        <div class=\"col-sm-6\">\n          <div class=\"form-group middle-name-section\">\n            <label class=\"control-label\">Middle Name</label>\n            <input type=\"text\" name=\"middleName\" class=\"form-control\" placeholder=\"Middle Name\"\n              data-parsley-maxlength=\"100\" />\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.amendmentChanges : depth0)) != null ? stack1.middleName : stack1),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "          </div>\n        </div>\n      </div>\n      <div class=\"row\">\n        <div class=\"col-sm-6\">\n          <div class=\"form-group required ssn-section\">\n            <label class=\"control-label\">Social Security Number</label>\n            <input type=\"text\" name=\"ssn\" class=\"form-control ssn\" placeholder=\"Social Security Number\"\n              data-parsley-required=\"true\" data-parsley-ssn=\"true\" />\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.amendmentChanges : depth0)) != null ? stack1.ssn : stack1),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "          </div>\n        </div>\n        <div class=\"col-sm-6\">\n          <div class=\"form-group required date-of-birth-section\">\n            <label class=\"control-label\">Date of Birth</label>\n            <div class=\"input-group\">\n              <input type=\"text\" name=\"dob\" class=\"form-control datepicker date-of-birth\" placeholder=\"Date Of Birth\"\n                data-parsley-required=\"true\" data-parsley-date=\"\" data-parsley-trigger-after-failure=\"changeDate\"\n                data-parsley-errors-container=\"#dobErrorsContainer\" />\n              <div class=\"input-group-addon\">\n                <i class=\"fa fa-calendar\"></i>\n              </div>\n            </div>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.amendmentChanges : depth0)) != null ? stack1.dob : stack1),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            <div id=\"dobErrorsContainer\"></div>\n          </div>\n        </div>\n      </div>\n      <div class=\"row\">\n        <div class=\"col-sm-6\">\n          <div class=\"form-group\">\n            <label id=\"home-phone-label\" class=\"control-label\">Home Phone Number</label>\n            <input type=\"text\" name=\"homePhoneNumber\" class=\"form-control phone-format home-phone-number\"\n              placeholder=\"Home Phone Number\" data-parsley-maxlength=\"20\" />\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.amendmentChanges : depth0)) != null ? stack1.homePhoneNumber : stack1),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "          </div>\n        </div>\n        <div class=\"col-sm-6\">\n          <div class=\"form-group "
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias2).call(alias1,(depth0 != null ? depth0.viewMode : depth0),"===","ext",{"name":"ifCond","hash":{},"fn":container.program(29, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n            <label class=\"control-label\">Cell Phone Number</label>\n            <input type=\"text\" name=\"cellPhoneNumber\" class=\"form-control phone-format\" placeholder=\"Cell Phone Number\"\n              "
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias2).call(alias1,(depth0 != null ? depth0.viewMode : depth0),"===","ext",{"name":"ifCond","hash":{},"fn":container.program(31, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " data-parsley-maxlength=\"20\" />\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.amendmentChanges : depth0)) != null ? stack1.cellPhoneNumber : stack1),{"name":"if","hash":{},"fn":container.program(33, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "          </div>\n        </div>\n      </div>\n      <div class=\"form-group required\">\n        <label class=\"control-label\">Email Address</label>\n        <input type=\"text\" name=\"email\" class=\"form-control\" placeholder=\"Email\" data-parsley-maxlength=\"254\"\n          data-parsley-required=\"true\" data-parsley-type=\"email\" />\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.amendmentChanges : depth0)) != null ? stack1.email : stack1),{"name":"if","hash":{},"fn":container.program(35, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "      </div>\n      <div class=\"address\"></div>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.amendmentChanges : depth0)) != null ? stack1.address : stack1),{"name":"if","hash":{},"fn":container.program(37, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "      <div class=\"associated-licenses-instructions\">\n        <strong>Other Associated Department of Banking and Securities Licenses</strong>\n        <div class=\"row\">\n          <div class=\"col-sm-8\">\n            Please list below all other entities that have been licensed or are currently licensed by the Department,\n            for which this individual has ever been involved:\n          </div>\n          <div class=\"col-sm-4\">\n            <button class=\"btn btn-default btn-md add-associated-license\">Add Associated License</button>\n          </div>\n        </div>\n      </div>\n      <div class=\"row\">\n        <div class=\"col-sm-4\">\n          <label class=\"control-label\">Business Name</label>\n        </div>\n        <div class=\"col-sm-4\">\n          <label class=\"control-label\">License Type</label>\n        </div>\n        <div class=\"col-sm-3\">\n          <label class=\"control-label\">License Number</label>\n        </div>\n      </div>\n      <div class=\"associated-licenses-collection-container\"></div>\n    </form>\n  </div>\n</div>";
},"useData":true});