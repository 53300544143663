/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var momentUtil = require('common/util/momentUtil');
var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(filingId) {
  var deferred = $.Deferred();
  $.soap({
    body: {
      GetCreditUnionArticlesAmendmentFiling: {
        $xmlns: 'http://dobs.pa.gov/dp/filing/bpm/1.0',
        FilingID: filingId
      }
    },
    blueprintArrays: [
      'GetCreditUnionArticlesAmendmentFilingResponse.SupportingDocuments.DP_FilingSupportingDocument',
      'GetCreditUnionArticlesAmendmentFilingResponse.PublicationProofDocuments.DP_FilCUArticlesOfAmendmentPublicationProofDocument',
      'GetCreditUnionArticlesAmendmentFilingResponse.GeneralPublicationProofDocs.DP_FilCUArticlesOfAmendmentGeneralPublicationProofDocument',
      'GetCreditUnionArticlesAmendmentFilingResponse.LegalPublicationProofDocs.DP_FilCUArticlesOfAmendmentLegalPublicationProofDocument'
    ],
    blueprint: {
      creditUnionArticlesAmendmentFilingId: '$..ID',
      filingId: '$..FilingID',
      entityId: '$..EntityID',
      publicationGeneralDate: '$..PublicationGeneralDate',
      newspaperGeneral: '$..NewspaperGeneral',
      publicationLegalDate: '$..PublicationLegalDate',
      newspaperLegal: '$..NewspaperLegal',
      requestedEffectiveDate: '$..RequestedEffectiveDate',
      generalPublicationProofComments: '$..GeneralPublicationProofComments',
      legalPublicationProofComments: '$..LegalPublicationProofComments',
      articlesOfAmendment: {
        file: {
          fileId: '$..ArticlesOfAmendmentDocID',
          fileName: '$..ArticlesOfAmendmentName'
        },
        comments: '$..ArticlesOfAmendmentComments'
      },
      supportingDocuments: [
        '$..DP_FilingSupportingDocument',
        { file: { fileId: '$..DocumentID', fileName: '$..DocumentName' } }
      ],
      publicationProofDocuments: [
        '$..DP_FilCUArticlesOfAmendmentPublicationProofDocument',
        { file: { fileId: '$..DocumentID', fileName: '$..FileName' } }
      ],
      generalPublicationProofDocs: [
        '$..DP_FilCUArticlesOfAmendmentGeneralPublicationProofDocument',
        { fileId: '$.DocumentID', fileName: '$.FileName' }
      ],
      legalPublicationProofDocs: [
        '$..DP_FilCUArticlesOfAmendmentLegalPublicationProofDocument',
        { fileId: '$.DocumentID', fileName: '$.FileName' }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      var formInboundDateConfig = {
        sourceFormat: 'YYYY-MM-DD',
        targetFormat: 'MM/DD/YYYY',
        default: undefined
      };
      response.publicationGeneralDate = momentUtil.formatStr(response.publicationGeneralDate, formInboundDateConfig);
      response.publicationLegalDate = momentUtil.formatStr(response.publicationLegalDate, formInboundDateConfig);
      response.requestedEffectiveDate = momentUtil.formatStr(response.requestedEffectiveDate, formInboundDateConfig);
      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
