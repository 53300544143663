/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(data) {
  var defer = $.Deferred();

  $.soap({
    body: {
      GetAuditLog: {
        $xmlns: 'http://dobs.pa.gov/cf/auditlog/bpm/1.0',
        ObjectID: data.objectId,
        ObjectTypeID: data.objectTypeId
      }
    },
    blueprintArrays: ['GetAuditLogResponse.CF_AuditLog'],
    blueprint: {
      auditLog: [
        '$..CF_AuditLog',
        {
          message: '$.Message',
          description: '$.Description',
          createdDate: '$.CreatedDate',
          createdBy: '$.CreatedBy'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      _.each(response.auditLog, function(auditLog) {
        if (auditLog.createdDate) {
          auditLog.createdDate = moment(auditLog.createdDate, moment.ISO_8601);
        }
      });
      defer.resolve(response.auditLog);
    })
    .fail(function(err) {
      defer.reject(err);
    });

  return defer.promise();
};
