/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var FileUploadCollection = require('psa-backbone/models/FileUploadCollection');

var parsleyUtil = require('common/util/parsleyUtil');
var momentUtil = require('common/util/momentUtil');

var CommentsDocumentView = require('common/views/CommentsDocumentView');

var tmplDepCreditUnionArticlesAmendmentPubReqsSectionView = require('./DepCreditUnionArticlesAmendmentPubReqsSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepCreditUnionArticlesAmendmentPubReqsSectionView,

  className: 'dep-credit-union-articles-amendment-pub-reqs-section-view',

  behaviors: {
    ReadOnlyFormBehavior: {}
  },

  ui: {
    documentForm: '.document-form',
    generalPublicationProofsContainer: '.general-publication-proofs-container',
    datePickerFields: '.date-picker',
    legalPublicationProofsContainer: '.legal-publication-proofs-container'
  },

  regions: {
    generalPublicationProofsContainer: '@ui.generalPublicationProofsContainer',
    legalPublicationProofsContainer: '@ui.legalPublicationProofsContainer'
  },

  templateHelpers: function() {
    return {
      isReadOnly: this.options.isReadOnly
    };
  },

  onBeforeShow: function() {
    var filingContent = this.model.get('filingContent') || {};
    var generalPublicationProofDocs = filingContent.generalPublicationProofDocs || [];
    var legalPublicationProofDocs = filingContent.legalPublicationProofDocs || [];
    Syphon.deserialize(this, this.model.get('filingContent'));

    this.generalPublicationProofs = new CommentsDocumentView({
      isReadOnly: this.options.isReadOnly,
      allowMultipleFiles: true,
      isRequired: true,
      fileUploadLabel: 'Section II - Newspaper of General Circulation Proof of Publication',
      commentsLabel: 'Section II - Newspaper of General Circulation Proof of Publication',
      commentsName: 'generalPublicationProofComments',
      collection: new FileUploadCollection(generalPublicationProofDocs),
      model: new Backbone.Model({ generalPublicationProofComments: filingContent.generalPublicationProofComments }),
      documentTypeId: 20001
    });
    this.showChildView('generalPublicationProofsContainer', this.generalPublicationProofs);

    this.legalPublicationProofs = new CommentsDocumentView({
      isReadOnly: this.options.isReadOnly,
      allowMultipleFiles: true,
      isRequired: true,
      fileUploadLabel: 'Section II – Legal Newspaper Proof of Publication',
      commentsLabel: 'Section II – Legal Newspaper Proof of Publication',
      commentsName: 'legalPublicationProofComments',
      collection: new FileUploadCollection(legalPublicationProofDocs),
      model: new Backbone.Model({ legalPublicationProofComments: filingContent.legalPublicationProofComments }),
      documentTypeId: 20001
    });
    this.showChildView('legalPublicationProofsContainer', this.legalPublicationProofs);

    // Valid date only and in current year, previous year, or next year
    var startDate = new Date(new Date().getFullYear() - 1, 0, 1);
    var endDate = new Date(new Date().getFullYear() + 1, 11, 31);

    this.ui.datePickerFields.datepicker({ startDate: startDate, endDate: endDate });
  },

  validate: function(config) {
    this.ui.documentForm.parsley().reset();
    this.generalPublicationProofs.removeValidationErrors();
    this.legalPublicationProofs.removeValidationErrors();

    if (config.type === 'save') {
      // Turn off required field validation for this form
      parsleyUtil.disableRequiredValidation(this.ui.documentForm);
    } else {
      var validationPromises = [
        this.ui.documentForm.parsley().whenValidate(),
        this.generalPublicationProofs.validate(),
        this.legalPublicationProofs.validate()
      ];

      // Turn on required field validation for this form
      parsleyUtil.enableRequiredValidation(this.ui.documentForm);
    }
    return $.when.apply($, validationPromises);
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    formData.publicationGeneralDate = momentUtil.formatStr(
      formData.publicationGeneralDate,
      momentUtil.defaultOutboundDateConfig
    );
    formData.publicationLegalDate = momentUtil.formatStr(
      formData.publicationLegalDate,
      momentUtil.defaultOutboundDateConfig
    );
    formData.generalPublicationProofDocIds = _.pluck(
      this.generalPublicationProofs.fileUploadView.collection.toJSON(),
      'fileId'
    );
    formData.legalPublicationProofDocIds = _.pluck(
      this.legalPublicationProofs.fileUploadView.collection.toJSON(),
      'fileId'
    );

    return formData;
  }
});
