/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var HeaderBarView = require('common/views/HeaderBarView');

module.exports = HeaderBarView.extend({
  initialize: function() {
    var recordCategoryId = this.model.get('recordCategoryId');
    var isRegistration = recordCategoryId === '1';
    var isExemption = recordCategoryId === '2';
    var isInvestment = recordCategoryId === '3';

    var dispositionDateDisplay = this.model.get('dispositionDate') || '--';
    this.model.set('dispositionDateDisplay', dispositionDateDisplay);

    var recordNameLabel;
    if (isRegistration) {
      recordNameLabel = 'Issuer Name';
    } else if (isExemption) {
      recordNameLabel = 'Issuer Name';
    } else {
      recordNameLabel = 'Fund Name';
    }

    var columns = [
      {
        name: 'recordName',
        label: recordNameLabel
      },
      {
        name: 'fileNumber',
        label: 'File Number'
      },
      {
        name: 'fileNumberSuffix',
        label: 'File Suffix'
      },
      {
        name: 'filedDate',
        label: 'Date Filed'
      }
    ];

    if (isRegistration) {
      this.model.set('title', 'Registration');
      this.options.columns = new Backbone.Collection(
        columns.concat([
          {
            name: 'dispositionDateDisplay',
            label: 'Disposition Date'
          },
          {
            name: 'status',
            label: 'Status'
          }
        ])
      );
    }

    if (isExemption) {
      this.model.set('title', 'Exemption');
      this.options.columns = new Backbone.Collection(
        columns.concat([
          {
            name: 'dispositionDateDisplay',
            label: 'Disposition Date'
          },
          {
            name: 'status',
            label: 'Status'
          }
        ])
      );
    }

    if (isInvestment) {
      this.model.set('title', 'Investment');
      this.options.columns = new Backbone.Collection(columns);
    }

    if (HeaderBarView.prototype.initialize) {
      HeaderBarView.prototype.initialize.apply(this.options);
    }
  }
});
