/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var momentUtil = require('common/util/momentUtil');
var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(investmentHistoryId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetInvestmentHistory: {
        $xmlns: 'http://dobs.pa.gov/cf/investment/bpm/1.0',
        InvestmentHistoryID: investmentHistoryId
      }
    },
    blueprint: {
      id: '$..ID',
      investmentId: '$..InvestmentID',
      filedDate: '$..FiledDate',
      filingNumber: '$..FilingNumber',
      transactionDescId: '$..TransactionDescID',
      transactionDesc: '$..TransactionDesc',
      PADollarAmount: '$..PADollarAmount',
      feePaidAmount: '$..FeePaidAmount',
      dispositionDate: '$..DispositionDate',
      statusId: '$..StatusID',
      status: '$..Status',
      effectiveFromDate: '$..EffectiveFromDate',
      effectiveToDate: '$..EffectiveToDate',
      isDeleted: '$..IsDeleted',
      createdDate: '$..CreatedDate',
      createdBy: '$..CreatedBy',
      modifiedDate: '$..ModifiedDate',
      modifiedBy: '$..ModifiedBy'
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      var formInboundDateConfig = {
        sourceFormat: 'YYYY-MM-DD',
        targetFormat: 'MM/DD/YYYY',
        default: undefined
      };

      response.filedDate = momentUtil.formatStr(response.filedDate, formInboundDateConfig);
      response.dispositionDate = momentUtil.formatStr(response.dispositionDate, formInboundDateConfig);
      response.effectiveFromDate = momentUtil.formatStr(response.effectiveFromDate, formInboundDateConfig);
      response.effectiveToDate = momentUtil.formatStr(response.effectiveToDate, formInboundDateConfig);

      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
