var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0["case"] : depth0)) != null ? stack1.fineAmountOrdered : stack1), depth0));
},"3":function(container,depth0,helpers,partials,data) {
    return "--";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0["case"] : depth0)) != null ? stack1.totalFineAmountPaid : stack1), depth0));
},"7":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0["case"] : depth0)) != null ? stack1.totalPaymentScheduled : stack1), depth0));
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "\r\n<div class=\"row\">\r\n  <div class=\"col-sm-6 form-horizontal form-static\">\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-6\">Payment Amount</label>\r\n      <div class=\"form-control-static col-sm-6\">\r\n        "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0["case"] : depth0)) != null ? stack1.fineAmountOrdered : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "\r\n      </div>\r\n    </div>\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-6\">Amount Paid</label>\r\n      <div class=\"form-control-static col-sm-6 payment-paid\">\r\n        "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0["case"] : depth0)) != null ? stack1.totalFineAmountPaid : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\r\n    </div>\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-6\">Balance Due</label>\r\n      <div class=\"form-control-static col-sm-6 payment-scheduled\">\r\n        "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0["case"] : depth0)) != null ? stack1.totalPaymentScheduled : stack1),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\r\n    </div>\r\n  </div>\r\n</div>\r\n<div class=\"row\">\r\n  <div class=\"col-sm-12\">\r\n    <div class=\"payment-grid\"></div>\r\n  </div>\r\n</div>";
},"useData":true});