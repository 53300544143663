var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "      <div class=\"form-group\">\r\n        <label class=\"control-label col-sm-3\">Selected Review Roles</label>\r\n        <div class=\"col-sm-9 col-md-6\">\r\n          <div class=\"row\">\r\n            <div class=\"col-sm-12\">\r\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.allReviewRoles : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            </div>\r\n          </div>\r\n        </div>\r\n      </div>\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-3\">Current Review Role</label>\r\n      <div class=\"col-sm-9 col-md-6\">\r\n        <p class=\"form-control-static filing-reviewer\">"
    + container.escapeExpression((helpers["default"] || (depth0 && depth0["default"]) || helpers.helperMissing).call(alias1,((stack1 = (depth0 != null ? depth0.examDetails : depth0)) != null ? stack1.currentReviewerRole : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</p>\r\n      </div>\r\n    </div>\r\n    <div class=\"row\">\r\n      <div class=\"col-sm-12\">\r\n        <div class=\"form-group required\">\r\n          <label class=\"control-label col-sm-3\">Action</label>\r\n          <div class=\"col-sm-9 col-md-6\">\r\n            <select name=\"actionId\" class=\"form-control action-dropdown\" data-parsley-required=\"true\">\r\n              <option value=\"\" selected>--</option>\r\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.examActions : depth0),{"name":"each","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            </select>\r\n          </div>\r\n        </div>\r\n      </div>\r\n    </div>\r\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "              <label class=\"checkbox-inline\">\r\n                <input type=\"checkbox\" class=\"review-roles\" name=\"reviewRoles\" value=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" disabled>"
    + alias4(((helper = (helper = helpers.role || (depth0 != null ? depth0.role : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"role","hash":{},"data":data}) : helper)))
    + "\r\n              </label>\r\n";
},"4":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "              <option value=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.action || (depth0 != null ? depth0.action : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"action","hash":{},"data":data}) : helper)))
    + "</option>\r\n";
},"6":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "              <option value=\""
    + alias4(((helper = (helper = helpers.userId || (depth0 != null ? depth0.userId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"userId","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + " - ("
    + alias4(((helper = (helper = helpers.userId || (depth0 != null ? depth0.userId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"userId","hash":{},"data":data}) : helper)))
    + ")</option>\r\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"row collapse action-comments-required-row\">\r\n      <div class=\"col-sm-12\">\r\n        <div class=\"form-group required\">\r\n          <label class=\"control-label col-sm-3\">Comments</label>\r\n          <div class=\"col-sm-9 col-md-6\">\r\n            <textarea type=\"text\" class=\"form-control \" name=\"actionComments\" placeholder=\"Comments\"\r\n              rows=\"10\"></textarea>\r\n          </div>\r\n        </div>\r\n      </div>\r\n    </div>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=helpers.helperMissing;

  return "\r\n<div class=\"page-title clearfix\">\r\n  <h1 class=\"pull-left m-n p-n\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.task : depth0)) != null ? stack1.taskType : stack1)) != null ? stack1.description : stack1), depth0))
    + " for "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.examDetails : depth0)) != null ? stack1.entityName : stack1), depth0))
    + "</h1>\r\n  <div class=\"page-title-buttons-container\">\r\n    <a role=\"button\" class=\"btn btn-primary\" href=\"#dep/entity/"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.examDetails : depth0)) != null ? stack1.entityId : stack1), depth0))
    + "/exam/"
    + alias2(((helper = (helper = helpers.examId || (depth0 != null ? depth0.examId : depth0)) != null ? helper : alias4),(typeof helper === "function" ? helper.call(alias3,{"name":"examId","hash":{},"data":data}) : helper)))
    + "/dashboard\"\r\n      target=\"_blank\">Open\r\n      Exam</a>\r\n  </div>\r\n</div>\r\n<div class=\"header-bar\"></div>\r\n<div class=\"breadcrumb-container\"></div>\r\n<div class=\"task-status\"></div>\r\n<div class=\"form-horizontal\">\r\n  <form class=\"review-exam-form\">\r\n"
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.examDetails : depth0)) != null ? stack1.isCurrentReviewerTemp : stack1),"!=","1",{"name":"ifCond","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    <div class=\"row reviewer-row\">\r\n      <div class=\"col-sm-12\">\r\n        <div class=\"form-group required\">\r\n          <label class=\"control-label col-sm-3\">Reviewer</label>\r\n          <div class=\"col-sm-9 col-md-6\">\r\n            <select name=\"tempReviewer\" class=\"form-control\" data-parsley-required=\"true\">\r\n              <option value=\"\" selected>--</option>\r\n"
    + ((stack1 = helpers.each.call(alias3,(depth0 != null ? depth0.examReviewers : depth0),{"name":"each","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            </select>\r\n          </div>\r\n        </div>\r\n      </div>\r\n    </div>\r\n    <div class=\"row action-comments-optional-row\">\r\n      <div class=\"col-sm-12\">\r\n        <div class=\"form-group comment-form-group\">\r\n          <label class=\"control-label col-sm-3\">Comments</label>\r\n          <div class=\"col-sm-9 col-md-6\">\r\n            <textarea type=\"text\" class=\"form-control \" name=\"actionComments\" placeholder=\"Comments\"\r\n              rows=\"10\"></textarea>\r\n          </div>\r\n        </div>\r\n      </div>\r\n    </div>\r\n"
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.examDetails : depth0)) != null ? stack1.isCurrentReviewerTemp : stack1),"!=","1",{"name":"ifCond","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  </form>\r\n  <div class=\"form-group\">\r\n    <div class=\"col-sm-9 col-lg-7 col-sm-offset-3\">\r\n      <a href=\"#dep/dashboard\" class=\"btn btn-default\" role=\"button\">Cancel</a>\r\n      <button type=\"button\" class=\"btn btn-primary submit\">Submit</button>\r\n      <div class=\"task-actions\"></div>\r\n    </div>\r\n  </div>\r\n</div>";
},"useData":true});