/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var moment = require('moment');

var PERSIST_DATE_FMT = 'YYYY-MM-DD';
var PERSIST_DATETIME_FMT = 'YYYY-MM-DDTHH:mm:ss';

var DISPLAY_DATE_FMT = 'MM/DD/YYYY';
var DISPLAY_DATETIME_FMT = 'MM/DD/YYYY hh:mm a';

var momentUtil = {
  formatStr: function(dateStr, config) {
    if (dateStr) {
      var date = moment(dateStr, config.sourceFormat);
      if (date.isValid()) {
        return date.format(config.targetFormat);
      }
    }
    return config.default ? config.default : undefined;
  },

  formatDateAttributes: function(obj, paths, format, targetFormat, def) {
    _.each(paths, function(path) {
      if (typeof path === 'string') {
        path = path.split('.');
      }

      if (path.length === 1) {
        var date = moment(obj[path], format);
        if (date.isValid()) {
          obj[path[0]] = date.format(targetFormat);
        } else {
          obj[path[0]] = def;
        }
        return;
      }
      var context = obj;
      _.every(path, function(part, index) {
        if (index === path.length - 1) {
          var date = moment(context[part], format);
          if (date.isValid()) {
            context[part] = date.format(targetFormat);
          } else {
            context[part] = def;
          }
        } else {
          context = context[part];
          if (_.isUndefined(context)) {
            return false;
          }
        }
        return true;
      });
    });
  },

  PERSIST_DATE_FMT: PERSIST_DATE_FMT,
  PERSIST_DATETIME_FMT: PERSIST_DATETIME_FMT,

  DISPLAY_DATE_FMT: DISPLAY_DATE_FMT,
  DISPLAY_DATETIME_FMT: DISPLAY_DATETIME_FMT,

  defaultInboundDateConfig: {
    sourceFormat: PERSIST_DATE_FMT,
    targetFormat: DISPLAY_DATE_FMT,
    default: '--'
  },

  defaultOutboundDateConfig: {
    sourceFormat: DISPLAY_DATE_FMT,
    targetFormat: PERSIST_DATE_FMT,
    default: undefined
  },

  defaultInboundDateTimeConfig: {
    sourceFormat: PERSIST_DATETIME_FMT,
    targetFormat: DISPLAY_DATETIME_FMT,
    default: '--'
  },

  defaultOutboundDateTimeConfig: {
    sourceFormat: DISPLAY_DATETIME_FMT,
    targetFormat: PERSIST_DATETIME_FMT,
    default: undefined
  }
};

module.exports = momentUtil;
