var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "        <option value=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.firstName || (depth0 != null ? depth0.firstName : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"firstName","hash":{},"data":data}) : helper)))
    + " "
    + alias4(((helper = (helper = helpers.lastName || (depth0 != null ? depth0.lastName : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"lastName","hash":{},"data":data}) : helper)))
    + "</option>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "\r\n<div class=\"col-sm-4\">\r\n  <div class=\"form-group\">\r\n    <select class=\"form-control\" name=\"individualId\" data-parsley-required=\"true\" data-parsley-group='[\"investigation\"]'>\r\n      <option value=\"\">--</option>\r\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.individuals : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </select>\r\n  </div>\r\n</div>\r\n<div class=\"col-sm-5\">\r\n  <div class=\"form-group\">\r\n    <label class=\"checkbox-inline\">\r\n      <input type=\"checkbox\" name=\"hasCriminal\" /> Criminal\r\n    </label>\r\n    <label class=\"checkbox-inline\">\r\n      <input type=\"checkbox\" name=\"hasFinancial\" /> Financial\r\n    </label>\r\n    <label class=\"checkbox-inline\">\r\n      <input type=\"checkbox\" name=\"hasRegulatory\" /> Regulatory\r\n    </label>\r\n    <label class=\"checkbox-inline\">\r\n      <input type=\"checkbox\" name=\"hasCivil\" /> Civil\r\n    </label>\r\n  </div>\r\n</div>\r\n<div class=\"col-sm-1\">\r\n  <button class=\"btn btn-danger btn-sm remove-child\">\r\n    <i class=\"fa fa-times\" aria-hidden=\"true\"></i>\r\n    Remove\r\n  </button>\r\n</div>";
},"useData":true});