var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "    <li>\r\n      <a class=\"menu-item\" href=\"#sec/examiner-dashboard\">\r\n        <i class=\"menu-item-icon fa fa-window-maximize fa-fw\"></i>\r\n        <span class=\"menu-item-label\">Examiner Dashboard</span>\r\n      </a>\r\n    </li>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "\r\n<div class=\"on-deck-view\">\r\n  <p>ON DECK</p>\r\n  <ul class=\"nav nav-pills nav-stacked\">\r\n    <li>\r\n      <a class=\"menu-item\" href=\"#sec/dashboard\">\r\n        <i class=\"menu-item-icon fa fa-home fa-fw\"></i>\r\n        <span class=\"menu-item-label\">My Tasks</span>\r\n      </a>\r\n    </li>\r\n    <li>\r\n      <a class=\"menu-item\" href=\"#sec/dashboard/all-tasks\">\r\n        <i class=\"menu-item-icon fa fa-globe fa-fw\"></i>\r\n        <span class=\"menu-item-label\">All Tasks</span>\r\n      </a>\r\n    </li>\r\n  </ul>\r\n</div>\r\n<div class=\"menu-view\">\r\n  <p>MENU</p>\r\n  <ul class=\"nav nav-pills nav-stacked\">\r\n    <li>\r\n      <a class=\"menu-item\" href=\"#sec/organization-search\">\r\n        <i class=\"menu-item-icon fa fa-building fa-fw\"></i>\r\n        <span class=\"menu-item-label\">Organization</span>\r\n      </a>\r\n    </li>\r\n    <li>\r\n      <a class=\"menu-item\" href=\"#sec/individual-search\">\r\n        <i class=\"menu-item-icon fa fa-user fa-fw\"></i>\r\n        <span class=\"menu-item-label\">Individual</span>\r\n      </a>\r\n    </li>\r\n    <li>\r\n      <a class=\"menu-item\" href=\"#sec/application-search\">\r\n        <i class=\"menu-item-icon fa fa-file fa-fw\"></i>\r\n        <span class=\"menu-item-label\">Applications</span>\r\n      </a>\r\n    </li>\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isExaminer : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    <li>\r\n      <a class=\"menu-item\" href=\"#sec/exam-search\">\r\n        <i class=\"menu-item-icon fa fa-list-ol fa-fw\"></i>\r\n        <span class=\"menu-item-label\">Exams</span>\r\n      </a>\r\n    </li>\r\n    <li>\r\n      <a class=\"menu-item\" href=\"#sec/investigation-inquiry-search\">\r\n        <i class=\"menu-item-icon fa fa-clipboard fa-fw\"></i>\r\n        <span class=\"menu-item-label\">Investigations/Inquiries</span>\r\n      </a>\r\n    </li>\r\n    <li>\r\n      <a class=\"menu-item\" href=\"#sec/next-step-search\">\r\n        <i class=\"menu-item-icon fa fa-forward fa-fw\"></i>\r\n        <span class=\"menu-item-label\">Next Steps</span>\r\n      </a>\r\n    </li>\r\n    <li>\r\n      <a class=\"menu-item\" href=\""
    + container.escapeExpression(((helper = (helper = helpers.iHubFolderLink || (depth0 != null ? depth0.iHubFolderLink : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"iHubFolderLink","hash":{},"data":data}) : helper)))
    + "\" target=\"_blank\">\r\n        <i class=\"menu-item-icon fa fa-bar-chart fa-fw\"></i>\r\n        <span class=\"menu-item-label\">Reports</span>\r\n        <i class=\"fa fa-external-link external-link\"></i>\r\n      </a>\r\n    </li>\r\n  </ul>\r\n</div>";
},"useData":true});