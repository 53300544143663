/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(formData) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      SubmitEntity: {
        $xmlns: 'http://dobs.pa.gov/cs/entity/bpm/1.0',
        Entity: {
          EntityID: formData.entityId,
          EntityName: formData.entityName,
          DBA: formData.dba,
          Phone: formData.phone,
          Fax: formData.fax,
          Manager: formData.manager,
          Website: formData.website,
          LicenseNumber: formData.licenseNumber,
          AddressLine1: formData.addressLine1,
          AddressLine2: formData.addressLine2,
          AddressLine3: formData.addressLine3,
          County: formData.county,
          City: formData.city,
          State: formData.state,
          PostalCode: formData.postalCode,
          Country: formData.country
        }
      }
    },
    blueprint: {
      entityId: '$..EntityID'
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      deferred.resolve(response.entityId);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
