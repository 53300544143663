/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');

var Syphon = require('backbone.syphon');
var Ladda = require('ladda');
var Radio = require('backbone.radio');

var parsleyUtil = require('common/util/parsleyUtil');
var downloadDocument = require('common/util/downloadDocument');
var makeUploadCollection = require('common/util/makeUploadCollection');
var modelGet = require('common/util/modelGet');

var SubmitOathRosterFiling = require('../../services/SubmitOathRosterFiling');
var FileUploadView = require('common/views/FileUploadView');

var tmplDepOathRosterConfirmationSectionView = require('./DepOathRosterConfirmationSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepOathRosterConfirmationSectionView,

  className: 'dep-oath-roster-confirmation-section-view',

  ui: {
    certificationForm: '.certification-form',
    downloadCertification: '.download-certification',
    uploadCertification: '.upload-certification',
    submit: '.submit'
  },

  regions: {
    uploadCertification: '@ui.uploadCertification'
  },

  events: {
    'click @ui.submit': 'onClickSubmit',
    'click @ui.downloadCertification': 'onClickDownloadCertification'
  },


  templateHelpers: function() {
    return {
      mode: this.options.mode
    };
  },

  onBeforeShow: function(e) {
    var certificationDocument = modelGet(this.model, 'filingDetails.certificationDocument');
    
    this.certificationDocument = new FileUploadView({
      isReadOnly: this.options.isReadOnly,
      allowMultipleFiles: false,
      isRequired: false,
      collection: makeUploadCollection(certificationDocument),
      metadata: {
        documentTypeId: 20001
      }
    });
    this.showChildView('uploadCertification', this.certificationDocument);

    if (this.options.mode === 'print') {
      Syphon.deserialize(this, this.model.get('filingDetails'));
    }
  },

  onClickDownloadCertification: function(e) {
    var certificationTemplateDocument = modelGet(this.model, 'filingDetails.certificationTemplateDocument');
    
    e.preventDefault();
    downloadDocument({
      fileId: certificationTemplateDocument.fileId,
      fileName: certificationTemplateDocument.fileName,
      isStatic: true
    });
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;

    var l = Ladda.create(this.ui.submit[0]);

    this.sideNavView.validateAll({ type: 'submit' }).done(function() {
      l.start();

      var formData = self.sideNavView.getFormData();
      formData.filingId = self.model.get('filingId');
      formData.oathRosterId = self.model.get('oathRosterId');

      SubmitOathRosterFiling(formData)
        .done(function() {
          Radio.channel('dp:draft-filing').trigger('proceed-to-payment', l);
          Radio.channel('dp:draft-filing').trigger('submit', l);
          Radio.channel('dp:submit-filing').trigger('resubmit', l);
        })
        .fail(function() {
          l.stop();
        });
    });
  },

  validate: function(config) {
    this.ui.certificationForm.parsley().reset();

    if (config.type === 'save') {
      // Turn off required field validation for this form
      parsleyUtil.disableRequiredValidation(this.ui.certificationForm);
    } else {
      // Turn on required field validation for this form
      parsleyUtil.enableRequiredValidation(this.ui.certificationForm);

      var validationPromises = [
        this.ui.certificationForm.parsley().whenValidate(),
        this.certificationDocument.validate()
      ]
    }
    
    return $.when.apply($, validationPromises);
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    formData.certificationDocId = this.certificationDocument.getFirstFileId();
    return formData;
  }
});
