var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "\r\n<div class=\"form-horizontal form-static\">\r\n  <div class=\"row m-b-lg\">\r\n    <div class=\"col-md-6 col-sm-12 form-static\">\r\n      <div class=\"form-group\">\r\n        <label class=\"control-label col-sm-3 col-md-6\">Action Type</label>\r\n        <div class=\"form-control-static col-sm-9 col-md-6\">\r\n          "
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.actionType : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "\r\n        </div>\r\n      </div>\r\n      <div class=\"form-group\">\r\n        <label class=\"control-label col-sm-3 col-md-6\">Registered</label>\r\n        <div class=\"form-control-static col-sm-9 col-md-6\">\r\n          "
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.isRegisteredDisplay : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "\r\n        </div>\r\n      </div>\r\n      <div class=\"form-group\">\r\n        <label class=\"control-label col-sm-3 col-md-6\">Date Issued</label>\r\n        <div class=\"form-control-static col-sm-9 col-md-6\">\r\n          "
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.issuedDate : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "\r\n        </div>\r\n      </div>\r\n      <div class=\"form-group\">\r\n        <label class=\"control-label col-sm-3 col-md-6\">Criminal Authority Action</label>\r\n        <div class=\"form-control-static col-sm-9 col-md-6\">\r\n          "
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.criminalAuthAction : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "\r\n        </div>\r\n      </div>\r\n    </div>\r\n    <div class=\"col-md-6 col-sm-12 form-static\">\r\n      <div class=\"form-group\">\r\n        <label class=\"control-label col-sm-3 col-md-6\">Other Action Type</label>\r\n        <div class=\"form-control-static col-sm-9 col-md-6\">\r\n          "
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.otherActionType : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "\r\n        </div>\r\n      </div>\r\n      <div class=\"form-group\">\r\n        <label class=\"control-label col-sm-3 col-md-6\">OGC Docket Number</label>\r\n        <div class=\"form-control-static col-sm-9 col-md-6\">\r\n          "
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.ogcDocketNumber : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "\r\n        </div>\r\n      </div>\r\n      <div class=\"form-group\">\r\n        <label class=\"control-label col-sm-3 col-md-6\">Order Status</label>\r\n        <div class=\"form-control-static col-sm-9 col-md-6\">\r\n          "
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.orderStatus : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "\r\n        </div>\r\n      </div>\r\n      <div class=\"form-group\">\r\n        <label class=\"control-label col-sm-3 col-md-6\">Civil Authority Action</label>\r\n        <div class=\"form-control-static col-sm-9 col-md-6\">\r\n          "
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.civilAuthAction : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "\r\n        </div>\r\n      </div>\r\n    </div>\r\n  </div>\r\n</div>";
},"useData":true});