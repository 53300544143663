/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var callByNeed = require('common/util/callByNeed');
var Marionette = require('backbone.marionette');

module.exports = Marionette.ItemView.extend({
  template: false,

  tagName: function() {
    if (this.model.get('href')) {
      return 'a';
    }
    return 'button';
  },

  className: function() {
    var baseClass = 'buttons-panel-cell-button-view btn';
    if (this.model.get('buttonClass')) {
      return baseClass + ' ' + callByNeed(this.model.get('buttonClass'), this, this.options.dataModel);
    } else {
      return baseClass + ' btn-primary';
    }
  },

  attributes: function() {
    if (this.model.get('href')) {
      return {
        role: 'button',
        href: callByNeed(this.model.get('href'), this, this.options.dataModel)
      };
    }
    return {};
  },

  events: {
    click: 'onClickButton'
  },

  initialize: function(options) {
    this.dataModel = options.dataModel;
  },

  render: function() {
    this.$el.text(this.model.get('buttonText'));
  },

  onClickButton: function(e) {
    if (!this.model.get('href')) {
      e.preventDefault();
      var onClick = this.model.get('onClick');
      if (onClick && _.isFunction(onClick)) {
        onClick(e, this.dataModel);
      }
    }
  }
});
