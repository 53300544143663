/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');

var modelGet = require('common/util/modelGet');

var FileUploadTableGridView = require('./FileUploadTableGridView');

require('./FileUploadTableView.css');
var tmplFileUploadTableView = require('./FileUploadTableView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplFileUploadTableView,

  className: 'file-upload-table-view',

  ui: {
    addFileRow: '.add-file-row',
    fileGridContainer: '.file-grid-container',
    validationAlert: '.validation-alert',
    validationAlertText: '.validation-alert-text'
  },

  regions: {
    fileGridContainer: '@ui.fileGridContainer'
  },

  events: {
    'click @ui.addFileRow': 'onClickAddFileRow'
  },

  options: {
    displayAddButton: true,
    addButtonClass: 'btn-primary'
  },

  templateHelpers: function() {
    return {
      addButtonClass: this.options.addButtonClass
    };
  },

  onRender: function() {
    if (!this.collection) {
      this.collection = new Backbone.Collection();
    }
    this.fileUploadTableGridView = new FileUploadTableGridView({
      isReadOnly: this.options.isReadOnly,
      documentTypes: this.options.documentTypes,
      emptyText: this.options.emptyText,
      collection: this.collection
    });
    if (this.options.isReadOnly || !this.options.displayAddButton) {
      this.ui.addFileRow.hide();
    }
    this.showChildView('fileGridContainer', this.fileUploadTableGridView);

    if (!this.options.isReadOnly) {
      this.$('.table').addClass('table-bordered');
    }
  },

  onClickAddFileRow: function(e) {
    e.preventDefault();
    this.collection.add({});
  },

  hasNumberOfFiles: function(numberOfFilesRequired) {
    var result = this.getFormData();
    return result.length >= numberOfFilesRequired;
  },

  validate: function(validationConfig, minNumberOfFiles) {
    var defer = $.Deferred();
    this.ui.validationAlertText.empty();
    this.ui.validationAlert.hide();
    var missingDocuments = false;
    var missingDocumentTypes = false;
    var missingRequiredNumberOfFiles = false;
    this.collection.each(function(model) {
      var document = model.toJSON();
      if (!document.file) {
        missingDocuments = true;
      } else if (!document.file[0]) {
        missingDocuments = true;
      } else if (!document.file[0].fileId) {
        missingDocuments = true;
      }
      if (!document.documentType) {
        missingDocumentTypes = true;
      }
    });
    if (validationConfig && minNumberOfFiles) {
      if (validationConfig.type === 'submit') {
        var hasRequiredNumberOfFiles = this.hasNumberOfFiles(minNumberOfFiles);
        if (!hasRequiredNumberOfFiles) {
          missingRequiredNumberOfFiles = true;
        }
      }
    }
    if (missingDocuments) {
      this.ui.validationAlertText.append('Some rows are missing documents, each must have a document uploaded. ');
    }
    if (missingDocumentTypes) {
      this.ui.validationAlertText.append('Some rows are missing document types, each must have a document type set. ');
    }
    if (missingRequiredNumberOfFiles) {
      this.ui.validationAlertText.append('At least one document must be provided. ');
    }
    if (missingDocuments || missingDocumentTypes || missingRequiredNumberOfFiles) {
      this.ui.validationAlert.show();
      defer.reject();
    } else {
      defer.resolve();
    }
    return defer.promise();
  },

  getFormData: function() {
    return this.collection.reduce(function(memo, model) {
      var fileId = modelGet(model, 'file[0].fileId');
      var fileName = modelGet(model, 'file[0].fileName');
      var documentTypeId = model.get('documentType');
      if (fileId && fileName) {
        memo.push({
          fileId: fileId,
          fileName: fileName,
          documentTypeId: documentTypeId
        });
      }
      return memo;
    }, []);
  }
});
