/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var _ = require('underscore');

var PageableCollection = require('psa-backbone/models/PageableCollection');
var GridView = require('psa-marionette/views/GridView');

var SelectOneCell = require('common/backgrid/SelectOneCell');
var modelGet = require('common/util/modelGet');

require('./DepSelectFundModalView.css');
var tmplDepSelectFundModalView = require('./DepSelectFundModalView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepSelectFundModalView,

  className: 'dep-select-fund-modal-view',

  ui: {
    fundsGrid: '.fund-grid-container',
    subfundGrid: '.subfund-grid-container',
    submit: '.btn-submit'
  },

  regions: {
    fundsGrid: '@ui.fundsGrid',
    subfundGrid: '@ui.subfundGrid'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  templateHelpers: function() {
    return {
      searchLimit: this.searchLimit
    };
  },

  onBeforeShow: function() {
    var trustFunds = modelGet(this.model, 'filingContent.trustFunds');
    this.fundCollection = new PageableCollection(trustFunds, { pageSize: 5 });

    this.allFunds = new GridView({
      columns: [
        {
          name: 'selected',
          label: '',
          cell: SelectOneCell.SelectOneRowCell,
          headerCell: SelectOneCell.SelectOneHeaderCell,
          width: 10,
          editable: true,
          sortable: false
        },
        {
          name: 'name',
          label: 'Fund Name',
          cell: 'string',
          headerCell: 'custom',
          width: 90,
          editable: false
        }
      ],
      collection: this.fundCollection,
      emptyText: 'No Exisiting Funds Associated with the Entity.'
    });

    this.showChildView('fundsGrid', this.allFunds);

    this.subfundCollection = new PageableCollection([], { pageSize: 5 });
    this.allSubFunds = new GridView({
      columns: [
        {
          name: 'name',
          label: 'Associated Subfunds',
          cell: 'string',
          headerCell: 'custom',
          width: 100,
          editable: false
        }
      ],
      collection: this.subfundCollection,
      emptyText: 'No Subfunds Associated with the Fund.'
    });

    this.showChildView('subfundGrid', this.allSubFunds);

    this.listenTo(this.fundCollection, 'backgrid:selected', this.onSelectFund);
  },

  onSelectFund: function(model, e) {
    var self = this;
    var selectedFundId = model.get('id');
    var trustSubfunds = modelGet(self.model, 'filingContent.trustSubfunds');
    var filteredTrustSubunds = _.filter(trustSubfunds, function(fund) {
      return fund.trustFundId === selectedFundId;
    });
    self.allSubFunds.collection.set(filteredTrustSubunds);
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var selectedModel = this.allFunds.viewGrid.getSelectedModel();
    if (selectedModel) {
      this.triggerMethod('modal:close', selectedModel.toJSON());
    } else {
      this.triggerMethod('modal:close');
    }
  },

  getFilteredSubfunds: function(selectedFundId) {
    var trustSubfunds = modelGet(this.model, 'filingContent.trustSubfunds');
    var filteredTrustSubunds = _.filter(trustSubfunds, function(fund) {
      return fund.trustFundId === selectedFundId;
    });
    return filteredTrustSubunds;
  }
});
