/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var AddressView = require('common/views/AddressView');

var tmplConSerIndividualFormView = require('../templates/ConSerIndividualFormView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplConSerIndividualFormView,

  className: 'con-ser-individual-form-view',

  ui: {
    addressFormContainer: '.address-form-container',
    datePickerField: '.datepicker'
  },

  regions: {
    addressFormContainer: '@ui.addressFormContainer'
  },

  onBeforeShow: function() {
    var individual = this.model.toJSON();

    this.addressForm = new AddressView({
      model: new Backbone.Model(individual),
      horizontal: true,
      isRequired: false,
      startingCountry: individual.country,
      startingPostalCode: individual.postalCode,
      startingCounty: individual.county
    });

    this.showChildView('addressFormContainer', this.addressForm);
    Syphon.deserialize(this, individual);
    this.ui.datePickerField.datepicker();
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    return formData;
  }
});
