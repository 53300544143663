/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');
var Syphon = require('backbone.syphon');

var Ladda = require('ladda');

var BreadcrumbView = require('common/views/BreadcrumbView');
var HeaderBarView = require('common/views/HeaderBarView');
var NMLSLabelView = require('../../common/views/NMLSLabelView');

var SubmitUpdateApplicationStatus = require('../services/SubmitUpdateApplicationStatus');

var tmplNonDepInternalApplicationUpdateStatusView = require('../templates/NonDepInternalApplicationUpdateStatusView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepInternalApplicationUpdateStatusView,

  className: 'non-dep-internal-application-update-status container-fluid',

  behaviors: {
    ReadOnlyFormBehavior: {}
  },

  ui: {
    nmlsLabelContainer: '.nmls-label-container',
    headerBar: '.header-bar',
    breadcrumb: '.breadcrumb-container',
    applicationUpdateStatusForm: '.application-update-status-form',
    submitButton: '.submit-button'
  },

  regions: {
    nmlsLabelContainer: '@ui.nmlsLabelContainer',
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb'
  },

  events: {
    'click @ui.submitButton': 'onClickSubmit'
  },

  onBeforeShow: function() {
    var applicationDetails = this.model.get('applicationDetails') || [];
    var owner = this.model.get('owner') || [];
    var applicationStatus = this.model.get('applicationStatus') || [];

    var isNMLSEntity = applicationDetails.isNMLSEntity;
    var applicationId = applicationDetails.applicationId;

    if (isNMLSEntity) {
      this.showChildView(
        'nmlsLabelContainer',
        new NMLSLabelView({
          model: new Backbone.Model({
            objectName: 'entity'
          })
        })
      );
    }

    var headerData = {
      title: 'Application',
      applicationType: applicationDetails.applicationType,
      status: applicationStatus.internalStatus,
      owner: owner.owner
    };

    this.showChildView(
      'headerBar',
      new HeaderBarView({
        columns: new Backbone.Collection([
          {
            name: 'applicationType',
            label: 'Application Type',
            minWidth: '160px',
            maxWidth: '300px'
          },
          {
            name: 'owner',
            label: 'Owner',
            minWidth: '160px'
          },
          {
            name: 'status',
            label: 'Status',
            minWidth: '160px',
            maxWidth: '350px',
            overflow: 'truncate'
          }
        ]),
        model: new Backbone.Model(headerData)
      })
    );

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#nondep/dashboard',
            text: 'Home'
          },
          {
            link: '#nondep/entity/' + applicationDetails.entityId + '/dashboard',
            text: applicationDetails.entityName
          },
          {
            link: '#nondep/application/' + applicationId + '/dashboard',
            text: 'Application #' + applicationId + ' - ' + applicationDetails.applicationType
          },
          { text: 'Update Application Status' }
        ]
      })
    );

    Syphon.deserialize(this, applicationDetails);
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;
    var applicationId = this.model.get('applicationId');

    this.validate().done(function() {
      var laddaContext = Ladda.create(self.ui.submitButton.get(0));
      laddaContext.start();
      SubmitUpdateApplicationStatus(self.getFormData())
        .done(function() {
          Radio.channel('navigate').trigger('show:int:nd:application-dashboard', applicationId);
        })
        .fail(function(err) {
          laddaContext.stop();
        });
    });
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    var applicationId = this.model.get('applicationId');
    formData.applicationId = applicationId;
    return formData;
  },

  validate: function() {
    return this.ui.applicationUpdateStatusForm.parsley().whenValidate();
  }
});
