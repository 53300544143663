/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

/**
 * Converts an address of object into a string.
 * @param {Object} address The address object to be converted to a string.
 * @param {Object} options Values that control how the address should be converted to a string.
 * @param {boolean} [options.separator=', '] The string to use to join the various address parts.
 * @returns {string} The address formatted as a string
 * @example
 *
 * addressToString({
 *  addressLine1: '123 Main St',
 *  city: 'Vienna',
 *  state: 'VA',
 *  postalCode: '22182'
 * });
 * // => '123 Main St, Vienna, VA 22182'
 */
module.exports = window.addressToString = function addressToString(address, options) {
  if (!address) {
    return '';
  }

  // Default separator is ', '
  var separator = ', ';
  if (options && _.isString(options.separator)) {
    separator = options.separator;
  }

  var addressLine1 = address.addressLine1;
  var addressLine2 = address.addressLine2;
  var addressLine3 = address.addressLine3;
  var city = address.city;
  var state = address.state;
  var postalCode = address.postalCode;
  var country = address.country;

  var cleanAddress = _.compact([addressLine1, addressLine2, addressLine3, city, state]).join(separator);

  if (postalCode) {
    cleanAddress += ' ' + postalCode;
  }

  if (country) {
    cleanAddress += separator + country;
  }

  return cleanAddress;
};
