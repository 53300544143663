/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var PlaceholderView = require('common/views/PlaceholderView');

var GetContactsByEntityAndDepartment = require('../../common/services/GetContactsByEntityAndDepartment');
var ConSerContactDisplayView = require('../views/ConSerComplaintContactInformationDisplayView');

module.exports = {
  channel: 'cs',

  event: 'build:contact-information-display',

  builder: function(entityData, contact) {
    return new PlaceholderView({
      viewPromise: buildContactsDropdown(entityData, contact)
    });
  }
};

function buildContactsDropdown(entityData, contact) {
  var deferred = $.Deferred();
  // Fetch contacts for this complaint
  GetContactsByEntityAndDepartment(entityData)
    .done(function(contacts) {
      deferred.resolve(
        new ConSerContactDisplayView({
          model: new Backbone.Model({ contacts: contacts, contact: contact })
        })
      );
    })
    .fail(function(err) {
      deferred.reject(err);
    });
  return deferred.promise();
}
