/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Backbone = require('backbone');
var NProgress = require('nprogress');

var GetFilingDetails = require('../../../common/services/GetFilingDetails');
var GetFilingInternalDetails = require('../../services/GetFilingInternalDetails');
var GetAllFilingLookups = require('../../../common/services/GetAllFilingLookups');
var DepFinalizeCloseOutTaskView = require('./DepFinalizeCloseOutTaskView');

module.exports = {
  event: 'build:dp:finalize-close-out',

  channel: 'task',

  builder: function(task) {
    var defer = $.Deferred();

    var filingId = task.taskData.DP.dpFilingId;

    $.when(GetFilingDetails(filingId), GetFilingInternalDetails(filingId), GetAllFilingLookups())
      .done(function(details, internalDetails, lookups) {
        NProgress.set(0.85);

        var filingDetails = _.extend(details, internalDetails);

        defer.resolve(
          new DepFinalizeCloseOutTaskView({
            model: new Backbone.Model({
              filingDetails: filingDetails,
              task: task,
              taskId: task.taskId,
              filingId: filingId, 
              filingWorkflowDecisionTypes: lookups.filingWorkflowDecisionTypes
            })
          })
        );
      })
      .fail(function(err) {
        defer.reject(err);
      });
    return defer.promise();
  }
};
