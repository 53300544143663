/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var parsleyUtil = require('common/util/parsleyUtil');

var tmplDepNewBankCharterSubmissionSectionView = require('./DepNewBankCharterSubmissionSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepNewBankCharterSubmissionSectionView,

  className: 'dep-new-bank-charter-submission-section-view',

  behaviors: {
    ReadOnlyFormBehavior: {},
    RadioGroupToggleBehavior: {
      radios: [
        {
          target: '.federal-reserve-details',
          radioGroup: 'input[name="isFedReserveMember"]',
          valueToShow: '1'
        },
        {
          target: '.holding-company-details',
          radioGroup: 'input[name="isBankHolding"]',
          valueToShow: '1'
        }
      ]
    }
  },

  ui: {
    submissionForm: '.submission-form',
    datePickerFields: '.date-picker'
  },

  regions: {
    datePickerFields: '@ui.datePickerFields'
  },

  templateHelpers: function() {
    return {
      isReadOnly: this.options.isReadOnly
    };
  },

  onBeforeShow: function() {
    var filingContent = this.model.get('filingContent') || {};

    Syphon.deserialize(this, filingContent);

    this.ui.datePickerFields.datepicker();
  },

  validate: function(config) {
    this.ui.submissionForm.parsley().reset();

    if (config.type === 'save') {
      // Turn off required field validation for this form
      parsleyUtil.disableRequiredValidation(this.ui.submissionForm);
    } else {
      // Turn on required field validation for this form
      parsleyUtil.enableRequiredValidation(this.ui.submissionForm);
    }
    return this.ui.submissionForm.parsley().whenValidate();
  },

  getFormData: function() {
    return Syphon.serialize(this);
  }
});
