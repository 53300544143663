/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Radio = require('backbone.radio');
var Ladda = require('ladda');
var FileUploadCollection = require('psa-backbone/models/FileUploadCollection');
var modelGet = require('common/util/modelGet');

var toggleForm = require('common/util/toggleForm');

var SubmitRegionalDirectorReviewExamResultsTask = require('../../services/SubmitRegionalDirectorReviewExamResultsTask');
var ExamRecordedResultsView = require('../../views/ExamRecordedResultsView');
var ExamPlacementView = require('../../views/ExamPlacementView');
var FileUploadView = require('common/views/FileUploadView');

require('./RegionalDirectorReviewExamResultsWorkTabView.css');
var tmplRegionalDirectorReviewExamResultsWorkTabView = require('./RegionalDirectorReviewExamResultsWorkTabView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplRegionalDirectorReviewExamResultsWorkTabView,

  className: 'regional-director-review-exam-results-work-tab-view container-fluid',

  ui: {
    formWorkTab: '.form-work-tab',
    examRecordedResultsContainer: '.exam-recorded-results-container',
    reviewCommentsContainer: '.review-comments-container',
    reviewDecision: '.review-decision',
    examPlacementContainer: '.exam-placement-container',
    reviewDocumentContainer: '.review-document-container',
    attachmentContainer: '.attachment-container',
    submit: '.submit'
  },

  regions: {
    examRecordedResultsContainer: '@ui.examRecordedResultsContainer',
    examPlacementContainer: '@ui.examPlacementContainer',
    reviewDocumentContainer: '@ui.reviewDocumentContainer',
    attachmentContainer: '@ui.attachmentContainer'
 },

  events: {
    'change @ui.reviewDecision': 'onChangeReviewDecision',
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeShow: function() {
    this.examRecordedResultsView = new ExamRecordedResultsView({
      model: this.model,
      mode: 'regional-director',
      documentTypes: this.options.documentTypes
    });
    this.showChildView('examRecordedResultsContainer', this.examRecordedResultsView);

    this.examPlacementView = new ExamPlacementView({
      model: this.model,
      mode: 'regional-director'
    });
    this.showChildView('examPlacementContainer', this.examPlacementView);

    this.onChangeReviewDecision();
  },

  onChangeReviewDecision: function() {
    var reviewDecisionId = this.ui.reviewDecision.val();

    // Decision === 'Revision Required'
    toggleForm(this.ui.reviewCommentsContainer, reviewDecisionId === '3201');
    toggleForm(this.ui.attachmentContainer, reviewDecisionId === '3201');
    toggleForm(this.ui.supportDocumentContainer, reviewDecisionId === '3201');
    toggleForm(this.ui.reviewDocumentContainer, reviewDecisionId === '3201');
    if (reviewDecisionId === '3201') {
      var regionalDirectorReviewDocumentId = modelGet(this.model, 'exam.regionalDirectorReviewDocument.fileId');
      if (regionalDirectorReviewDocumentId) {
        var regionalDirectorReviewDocument = modelGet(this.model, 'exam.regionalDirectorReviewDocument');
      }
      this.fileUploadView = new FileUploadView({
        isReadOnly: false,
        allowMultipleFiles: false,
        isRequired: true,
        collection: new FileUploadCollection(regionalDirectorReviewDocument),
        metadata: {
          documentTypeId: 10008
        }
      });

      this.showChildView('attachmentContainer', this.fileUploadView);
    }

    // Decision === 'Approve & Finalize Exam Document'
    toggleForm(this.ui.examPlacementContainer, reviewDecisionId === '3202');
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;
    this.ui.formWorkTab
      .parsley()
      .whenValidate()
      .done(function() {
        var l = Ladda.create(self.ui.submit[0]);
        l.start();
        var formData = Syphon.serialize(self);
        var reviewDecisionId = formData.reviewDecisionId;
        formData.taskId = self.model.get('taskId');
        formData.examId = self.model.get('examId');
        if (reviewDecisionId === '3201') {
          formData.regionalDirectorReviewDocumentId = self.fileUploadView.getFirstFileId();
        }
        SubmitRegionalDirectorReviewExamResultsTask(formData)
          .done(function() {
            Radio.channel('navigate').trigger('show:int:nd:dashboard');
          })
          .fail(function() {
            l.stop();
          });
      });
  }
});
