/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

// var _ = require('underscore');
var Backbone = require('backbone');
var Marionette = require('backbone.marionette');

var modelGet = require('common/util/modelGet');

var BreadcrumbView = require('common/views/BreadcrumbView');

var NMLSLabelView = require('../../common/views/NMLSLabelView');
var ContentServerLinkView = require('common/views/ContentServerLinkView');
var LicenseHeaderBarView = require('../../common/views/LicenseHeaderBarView');

var NonDepLicenseContactsView = require('../../common/views/NonDepLicenseContactsView.js');

require('../styles/NonDepLicenseContactHistorySnapshotView.css');
var tmplNonDepLicenseContactHistorySnapshotView = require('../templates/NonDepLicenseContactHistorySnapshotView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepLicenseContactHistorySnapshotView,

  className: 'non-dep-license-contact-history-snapshot-view container-fluid',

  ui: {
    nmlsLabelContainer: '.nmls-label-container',
    csLinkContainer: '.cs-link-container',
    headerBar: '.header-bar-container',
    breadcrumb: '.breadcrumb-container',
    licenseContactHistorySnapshotContainer: '.license-contact-history-snapshot-container'
  },

  regions: {
    nmlsLabelContainer: '@ui.nmlsLabelContainer',
    csLinkContainer: '@ui.csLinkContainer',
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb',
    licenseContactHistorySnapshotContainer: '@ui.licenseContactHistorySnapshotContainer'
  },

  onBeforeShow: function() {
    var licenseDetails = modelGet(this.model, 'licenseDetails', {});
    var entityId = modelGet(this.model, 'entityId');
    var entityName = licenseDetails.entityName;
    var licenseId = modelGet(this.model, 'licenseId');
    var licenseType = licenseDetails.licenseType;
    var licenseStatus = licenseDetails.licenseStatus;
    var licenseNumber = licenseDetails.licenseNumber;
    var isNMLSLicense = licenseDetails.isNMLSLicense;
    var originalIssueDate = licenseDetails.originalIssueDate;

    if (isNMLSLicense) {
      this.showChildView(
        'nmlsLabelContainer',
        new NMLSLabelView({
          model: new Backbone.Model({
            objectName: 'license'
          })
        })
      );
    }

    this.showChildView(
      'headerBar',
      new LicenseHeaderBarView({
        model: new Backbone.Model({
          title: 'License History',
          licenseType: licenseType,
          licenseStatus: licenseStatus,
          licenseNumber: licenseNumber,
          originalIssueDate: originalIssueDate
        })
      })
    );

    this.showChildView(
      'csLinkContainer',
      new ContentServerLinkView({
        model: new Backbone.Model({
          buttonText: 'Open License Folder',
          contentServerId: modelGet(this.model, 'CSFolderId')
        })
      })
    );

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#nondep/dashboard',
            text: 'Home'
          },
          {
            link: '#nondep/entity/' + entityId + '/dashboard',
            text: entityName
          },
          {
            link: '#nondep/entity/' + entityId + '/license/' + licenseId,
            text: 'License #' + licenseNumber + ' - ' + licenseType
          },
          {
            link: '#nondep/entity/' + entityId + '/license/' + licenseId + '/history',
            text: 'License'
          },
          {
            text: 'Contact Snapshot'
          }
        ]
      })
    );

    this.licenseContactHistorySnapshot = new NonDepLicenseContactsView({
      viewMode: this.options.viewMode,
      collection: new Backbone.Collection(this.model.get('licenseContactHistorySnapshot'))
    });

    this.showChildView('licenseContactHistorySnapshotContainer', this.licenseContactHistorySnapshot);
  }
});
