/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Radio = require('backbone.radio');

var UIUtil = require('psa-core/util/ui');

var HeaderBarView = require('common/views/HeaderBarView');
var HeaderBarAddressCellView = require('common/views/HeaderBarView/HeaderBarAddressCellView');
var BreadcrumbView = require('common/views/BreadcrumbView');
var FileUploadView = require('common/views/FileUploadView');
var FileUploadCollection = require('psa-backbone/models/FileUploadCollection');
var ConfirmationModalView = require('common/views/ConfirmationModalView');

var SubmitEntityExamInfoTask = require('../../services/SubmitEntityExamInfoTask');

var tmplDepEntityViewExamDocsTaskView = require('./DepEntityViewExamDocsTaskView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepEntityViewExamDocsTaskView,

  className: ' dep-provide-additional-exam-info-view container-fluid',

  ui: {
    headerBar: '.header-bar',
    breadcrumb: '.breadcrumb-container',
    reviewExamForm: '.review-exam-form',
    fileUploadTableContainer: '.file-upload-table-container',
    submit: '.submit'
  },

  regions: {
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb',
    fileUploadTableContainer: '@ui.fileUploadTableContainer'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeShow: function() {
    var examDetails = this.model.get('examDetails') || {};
    var ppobAddress = examDetails.ppobAddress || {};
    var documents = this.model.get('documents');

    var headerBarData = {
      filingType: examDetails.filingType,
      entityName: examDetails.entityName,
      entityType: examDetails.entityType,
      address: {
        addressLine1: ppobAddress.addressLine1,
        addressLine2: ppobAddress.addressLine2,
        city: ppobAddress.city,
        state: ppobAddress.state,
        postalCode: ppobAddress.postalCode,
        country: ppobAddress.country,
        county: ppobAddress.county
      }
    };

    var headerBarColumns = [
      {
        name: 'entityName',
        label: 'Entity Name',
        minWidth: '160px',
        maxWidth: '300px'
      },
      {
        name: 'entityType',
        label: 'Entity Type',
        minWidth: '160px',
        maxWidth: '300px'
      },
      {
        name: 'address',
        label: 'Principal Place of Business Address',
        minWidth: '160px',
        headerCell: HeaderBarAddressCellView
      }
    ];

    this.showChildView(
      'headerBar',
      new HeaderBarView({
        columns: new Backbone.Collection(headerBarColumns),
        model: new Backbone.Model(headerBarData)
      })
    );

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#ext/dep/dashboard',
            text: 'Home'
          },
          {
            text: 'View Exam Documents'
          }
        ]
      })
    );

    this.fileUploadTableView = new FileUploadView({
      collection: new FileUploadCollection(documents),
      emptyText: 'No Exam Documents for Entity',
      isReadOnly: true
    });
    this.showChildView('fileUploadTableContainer', this.fileUploadTableView);
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;

    UIUtil.showModal({
      title: 'Submit Notification',
      view: new ConfirmationModalView({
        confirmText: 'Submit',
        model: new Backbone.Model({
          message:
            'Do not acknowledge and submit until you have downloaded and saved the attachments. Once this notification is submitted, access to the notification and its attachments will be revoked.',
          onConfirm: function() {
            return SubmitEntityExamInfoTask(self.getFormData());
          },
          onConfirmDone: function() {
          Radio.channel('navigate').trigger('show:ext:dp:dashboard');
         }
        })
      })
    });
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);

    _.extend(formData, {
      taskId: this.model.get('taskId'),
      examId: this.model.get('examId')
    });
    return formData;
  }
});
