/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');
var _get = require('lodash.get');
var Ladda = require('ladda');

var BreadcrumbView = require('common/views/BreadcrumbView');

var DepLocationFormView = require('./DepLocationFormView');

var SubmitLocation = require('../services/SubmitLocation');

var tmplDepAddLocationView = require('../templates/DepLocationNewView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepAddLocationView,

  className: 'dep-add-location-view container-fluid',

  ui: {
    breadCrumbContainer: '.breadcrumb-container',
    locationFormContainer: '.location-form-container',
    submitButton: '.btn-submit'
  },

  regions: {
    breadCrumbContainer: '@ui.breadCrumbContainer',
    locationFormContainer: '@ui.locationFormContainer'
  },

  events: {
    'click @ui.categoryDropdown': 'onChangeCategory',
    'click @ui.submitButton': 'onClickSubmit'
  },

  onBeforeShow: function() {
    var entityId = this.model.get('entityId');
    var entityName = _get(this.model.get('entityIdentifiers'), 'entityName');

    this.showChildView(
      'breadCrumbContainer',
      new BreadcrumbView({
        breadcrumbs: [
          {
            text: 'Home',
            link: '#dep/dashboard'
          },
          {
            text: entityName,
            link: '#dep/entity/' + entityId + '/dashboard'
          },
          {
            text: 'Add Location'
          }
        ]
      })
    );

    this.locationForm = new DepLocationFormView({
      model: this.model
    });

    this.showChildView('locationFormContainer', this.locationForm);
  },

  onClickSubmit: function(event) {
    event.preventDefault();
    var self = this;
    this.validate().done(function() {
      var laddaContext = Ladda.create(self.ui.submitButton.get(0));
      laddaContext.start();

      var formData = self.getFormData();
      formData.isDeleted = 0;
      SubmitLocation(formData)
        .done(function(response) {
          Radio.channel('navigate').trigger('show:int:dp:entity:location', formData.entityId, response.id);
        })
        .fail(function(err) {
          laddaContext.stop();
        });
    });
  },

  validate: function() {
    return this.ui.locationFormContainer.parsley().whenValidate();
  },

  getFormData: function() {
    return this.locationForm.getFormData();
  }
});
