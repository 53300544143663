/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var NProgress = require('nprogress');
var NavUtils = require('psa-core/util/nav');
var Scaffold = require('common/layout/Scaffold');

var GetLicenseDetails = require('../../license-dashboard/services/GetLicenseDetails');
var GetLicenseContactByHistorySnapshot = require('../services/GetLicenseContactByHistorySnapshot');

var NonDepLicenseContactHistorySnapshotView = require('../views/NonDepLicenseContactHistorySnapshotView');

module.exports = function(entityId, licenseId, contactHistorySnapshotId) {
  NProgress.start();
  NProgress.set(0.6);

  $.when(GetLicenseDetails(licenseId), GetLicenseContactByHistorySnapshot(contactHistorySnapshotId))
    .done(function(licenseDetails, licenseContactHistorySnapshot) {
      NavUtils.navigate(
        'nondep/entity/' + entityId + '/license/' + licenseId + '/contact-history/' + contactHistorySnapshotId
      );
      Scaffold.content.show(
        new NonDepLicenseContactHistorySnapshotView({
          model: new Backbone.Model({
            entityId: entityId,
            licenseId: licenseId,
            contactHistorySnapshotId: contactHistorySnapshotId,
            licenseDetails: licenseDetails,
            licenseContactHistorySnapshot: licenseContactHistorySnapshot
          })
        })
      );
      NProgress.done();
    })

    .fail(function() {
      NProgress.done();
    });
};
