var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "      <option value=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</option>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        <option value=\"\" disabled=\"disabled\">────────────────────────────────────────────────────────────</option>\r\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.internalSubmissionTypes : depth0),{"name":"each","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "        <option value=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</option>\r\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.unless.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isInternalFiling : depth0),{"name":"unless","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"form-group submission-instructions-text-container collapse\">\r\n      <label class=\"control-label\">Instructions</label>\r\n      <div class=\"selected-filing-instructions\"></div>\r\n    </div>\r\n    <div class=\"form-group submission-instructions-container collapse\">\r\n      <label class=\"control-label\">Link to Submission Instructions</label>\r\n      <div>\r\n        <button class=\"btn btn-primary download-submission-instructions\">\r\n          <i class=\"fa fa-download\"></i>\r\n          Click Here to Download Submission Instructions\r\n        </button>\r\n      </div>\r\n    </div>\r\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "    <div class=\"row\">\r\n      <div class=\"col-sm-12\">\r\n        <button class=\"btn btn-primary submit ladda-button\" data-style=\"expand-right\" type=\"button\">\r\n"
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.mode : depth0),"===","ext-new",{"name":"ifCond","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(15, data, 0),"data":data})) != null ? stack1 : "")
    + "        </button>\r\n      </div>\r\n    </div>\r\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.filingDetails : depth0)) != null ? stack1.filingFee : stack1),">",0,{"name":"ifCond","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(13, data, 0),"data":data})) != null ? stack1 : "")
    + "          \r\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "            <span class=\"ladda-label\">Finalize Filing and Proceed to Cart</span>\r\n";
},"13":function(container,depth0,helpers,partials,data) {
    return "              <span class=\"ladda-label\">Finalize Filing and Submit</span>\r\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.mode : depth0),"===","int-new",{"name":"ifCond","hash":{},"fn":container.program(16, data, 0),"inverse":container.program(18, data, 0),"data":data})) != null ? stack1 : "");
},"16":function(container,depth0,helpers,partials,data) {
    return "            <span class=\"ladda-label\">Submit Filing</span>\r\n";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.mode : depth0),"===","resubmit",{"name":"ifCond","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"19":function(container,depth0,helpers,partials,data) {
    return "            <span class=\"ladda-label\">Finalize Filing and Submit</span>\r\n          ";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "\r\n<form class=\"submission-form\">\r\n  <div class=\"form-group required\">\r\n    <label class=\"control-label\">Submission Type</label>\r\n    <select name=\"submissionTypeId\" class=\"form-control submission-type\" data-parsley-required=\"true\">\r\n      <option value=\"\">--</option>\r\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.submissionTypes : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showAllSubmissionTypes : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </select>\r\n  </div>\r\n"
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.isReadOnly : depth0),{"name":"unless","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  <div class=\"form-group required\">\r\n    <label class=\"control-label\">Supporting Files</label>\r\n    <div class=\"alert alert-danger collapse missing-file\" role=\"alert\">\r\n      <i class=\"fa fa-exclamation-triangle\" aria-hidden=\"true\"></i>\r\n      At least one supporting document must be provided.\r\n    </div>\r\n    <div class=\"supporting-documents-container\"></div>\r\n  </div>\r\n  <div class=\"form-group\">\r\n    <label class=\"control-label\">Comments</label>\r\n    <textarea type=\"text\" name=\"comments\" class=\"form-control input-view\" placeholder=\"Comments\" rows=\"5\"></textarea>\r\n  </div>\r\n  <div class=\"form-group print-view\" id=\"commentsPrintView\"></div>\r\n"
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || helpers.helperMissing).call(alias1,(depth0 != null ? depth0.mode : depth0),"!=","print",{"name":"ifCond","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</form>";
},"useData":true});