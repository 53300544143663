/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var NProgress = require('nprogress');

var NavUtils = require('psa-core/util/nav');
var Scaffold = require('common/layout/Scaffold');

var GetApplicationStatus = require('../../common/services/GetApplicationStatus');
var GetApplicationDetails = require('../../common/services/GetApplicationDetails');
var GetApplicationOwner = require('../services/GetApplicationOwner');

var NonDepInternalApplicationDelinquencyDocGenView = require('../views/NonDepInternalApplicationDelinquencyDocGenView');

module.exports = function(applicationId) {
  NProgress.start();
  NProgress.set(0.6);

  $.when(GetApplicationDetails(applicationId), GetApplicationOwner(applicationId), GetApplicationStatus(applicationId))
    .done(function(applicationDetails, owner, applicationStatus) {
      applicationDetails.applicationStatus = applicationStatus.internalStatus;

      NavUtils.navigate('nondep/application/' + applicationId + '/delinquency-letter');

      Scaffold.content.show(
        new NonDepInternalApplicationDelinquencyDocGenView({
          viewMode: 'int',
          model: new Backbone.Model({ applicationDetails: applicationDetails, owner: owner.owner })
        })
      );
      NProgress.done();
    })
    .fail(function(err) {
      NProgress.done();
    });
};
