/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(complianceId) {
  var defer = $.Deferred();

  $.soap({
    body: {
      GetCompliancePaymentsByComplianceId: {
        $xmlns: 'http://dobs.pa.gov/nd/compliance/bpm/1.0',
        ComplianceID: complianceId
      }
    },
    blueprintArrays: ['GetCompliancePaymentsByComplianceIdResponse.ND_CompliancePayment'],
    blueprint: {
      compliancePayment: [
        '$..ND_CompliancePayment',
        {
          id: '$.ID',
          processInstanceId: '$.ProcessInstanceID',
          complianceId: '$.ComplianceID',
          description: '$.Description',
          dueDate: '$.DueDate',
          amount: '$.AmountDue',
          isDeleted: '$.IsDeleted',
          statusId: '$.StatusID',
          paidOnDate: '$.PaidOnDate',
          paidBy: '$.PaidBy',
          modifiedBy: '$.ModifiedBy',
          modifiedDate: '$.ModifiedDate',
          createdBy: '$.CreatedBy',
          createdDate: '$.CreatedDate',
          paymentStatus: '$.PaymentStatus'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      _.each(response.compliancePayment, function(payment) {
        payment.dueDate = moment(payment.dueDate, 'YYYY-MM-DD');

        if (payment.paidOnDate) {
          payment.paidOnDate = moment(payment.paidOnDate, 'YYYY-MM-DD');
        }
      });
      defer.resolve(response.compliancePayment);
    })
    .fail(function(err) {
      defer.reject(err);
    });

  return defer.promise();
};
