/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(formData) {
  var defer = $.Deferred();

  formData.FSDueDate = formData.FSDueDate ? moment(formData.FSDueDate, 'MM/DD/YYYY').format('YYYY-MM-DD') : '';
  formData.FSFiledDate = formData.FSFiledDate ? moment(formData.FSFiledDate, 'MM/DD/YYYY').format('YYYY-MM-DD') : '';
  formData.FSClearedDate = formData.FSClearedDate
    ? moment(formData.FSClearedDate, 'MM/DD/YYYY').format('YYYY-MM-DD')
    : '';
  formData.custodyDate = formData.custodyDate ? moment(formData.custodyDate, 'MM/DD/YYYY').format('YYYY-MM-DD') : '';
  formData.discretionDate = formData.discretionDate
    ? moment(formData.discretionDate, 'MM/DD/YYYY').format('YYYY-MM-DD')
    : '';
  formData.financialYear = formData.fiscalYearEnd ? moment(formData.fiscalYearEnd, 'MM/DD/YYYY').format ('YYYY'): '';
  formData.fiscalYearEnd = formData.fiscalYearEnd ? moment(formData.fiscalYearEnd, 'MM/DD/YYYY').format ('MM/DD'): '';

  $.soap({
    body: {
      SubmitOrganizationLicenseFinancials: {
        $xmlns: 'http://dobs.pa.gov/sc/organizationlicense/bpm/1.0',
        OrganizationID: formData.organizationId,
        LicenseID: formData.licenseId,
        FinancialsID: formData.financialsId,
        FinancialYear: formData.financialYear,
        FiscalYearEnd: formData.fiscalYearEnd,
        IsFinancialReportReq: formData.isFinancialReportReq,
        FSDueDate: formData.FSDueDate,
        FSFiledDate: formData.FSFiledDate,
        FSAssignedTo: formData.FSAssignedTo,
        FSClearedDate: formData.FSClearedDate,
        FSClearedBy: formData.FSClearedBy,
        IsCustody: formData.isCustody,
        CustodyDate: formData.custodyDate,
        IsAuditReq: formData.isAuditReq,
        IsDiscretion: formData.isDiscretion,
        DiscretionDate: formData.discretionDate,
        IsSuretyBondOnFile: formData.isSuretyBondOnFile,
        SuretyBondAmount: formData.suretyBondAmount,
        NetWorthReqAmount: formData.netWorthReqAmount,
        NetWorthCalculated: formData.netWorthCalculated,
        IsDeleted: formData.isDeleted
      }
    },
    blueprint: {
      id: '$..ID'
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      defer.resolve(response);
    })
    .fail(function(err) {
      defer.reject(err);
    });

  return defer.promise();
};
