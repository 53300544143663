/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(data) {
  var deferred = $.Deferred();
  $.soap({
    body: {
      GetExamsByOrganizationID: {
        $xmlns: 'http://dobs.pa.gov/sc/exam/bpm/1.0',
        OrganizationID: data.organizationId
        
      }
    },
    blueprintArrays: ['GetExamsByOrganizationIDResponse.SC_Exam'],
    blueprint: {
      exams: [
        '$..SC_Exam',
        {
          id: '$..ID',
          openedDate: '$..OpenedDate',
          closedDate: '$..ClosedDate',
          examNumber: '$..ExamNumber',
          primaryExaminer: '$..PrimaryExaminer',
          status: '$..Status',
          type: '$..Type'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      _.each(response.exams, function(exam) {
        exam.status = exam.status || '--';
        exam.type = exam.type || '--';
      });
      deferred.resolve(response.exams);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
