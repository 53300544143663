/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(enforcementActionId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetProgressReportByEnforcement: {
        $xmlns: 'http://dobs.pa.gov/dp/enforcement/bpm/1.0',
        EnforcementActionID: enforcementActionId
      }
    },
    blueprintArrays: ['GetProgressReportByEnforcementResponse.DP_EnforcementActionProgressReport'],
    blueprint: {
      progressReports: [
        '$..DP_EnforcementActionProgressReport',
        {
          id: '$..ID',
          enforcementActionId: '$..EnforcementActionID',
          entityId: '$..EntityID',
          year: '$..Year',
          quarterId: '$..QuarterID',
          quarter: '$..Quarter',
          dueDate: '$..DueDate',
          submissionDate: '$..SubmissionDate',
          acknowledgementDate: '$..AcknowledgementDate',
          statusId: '$..StatusID',
          status: '$..Status',
          comments: '$..Comments',
          isDeleted: '$..IsDeleted'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      _.each(response.progressReports, function(progressReport) {
        progressReport.year = progressReport.year || '--';
        progressReport.quarter = progressReport.quarter || '--';
        progressReport.status = progressReport.status || '--';
        progressReport.comments = progressReport.comments || '--';

        if (progressReport.dueDate) {
          progressReport.dueDate = moment(progressReport.dueDate, 'YYYY-MM-DD');
        }
        if (progressReport.submissionDate) {
          progressReport.submissionDate = moment(progressReport.submissionDate, 'YYYY-MM-DD');
        }
        if (progressReport.acknowledgementDate) {
          progressReport.acknowledgementDate = moment(progressReport.acknowledgementDate, 'YYYY-MM-DD');
        }
      });
      deferred.resolve(response.progressReports);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
