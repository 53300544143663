/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Syphon = require('backbone.syphon');
var Marionette = require('backbone.marionette');
var Ladda = require('ladda');

var WithdrawCorrespondence = require('../services/WithdrawCorrespondence');

var tmplConSerComplaintWithdrawCorrespondenceModalView = require('../templates/ConSerComplaintWithdrawCorrespondenceModalView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplConSerComplaintWithdrawCorrespondenceModalView,

  className: 'con-ser-complaint-withdraw-correspondence-modal-view',

  ui: {
    cancelButton: '.btn-cancel',
    submitButton: '.btn-submit',
    withdrawCorrespondenceForm: '.withdraw-correspondence-form',
    formBody: '.form-body',
    resultMessage: '.result-message',
    resultInstructions: '.result-instructions',
    resultBody: '.result-body'
  },

  events: {
    'click @ui.submitButton': 'onClickSubmit'
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;
    var correspondenceId = self.model.get('correspondenceId');
    this.ui.withdrawCorrespondenceForm
      .parsley()
      .whenValidate()
      .done(function() {
        var formData = Syphon.serialize(self);
        formData.correspondenceId = correspondenceId;
        var laddaContext = Ladda.create(self.ui.submitButton.get(0));
        laddaContext.start();
        WithdrawCorrespondence(formData).done(function() {
          self.triggerMethod('modal:close');
        });
      });
  }
});
