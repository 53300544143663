/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Ladda = require('ladda');

var GridView = require('psa-marionette/views/GridView');
var PageableCollection = require('psa-backbone/models/PageableCollection');

var SelectOneCell = require('common/backgrid/SelectOneCell');
var MomentCell = require('common/backgrid/MomentCell');

var GetExamsForSelection = require('../services/GetExamsForSelection');

require('../styles/TimeTrkNonDepAllExamsSelectionModalView.css');
var tmplTimeTrkNonDepAllExamsSelectionModalView = require('../templates/TimeTrkNonDepAllExamsSelectionModalView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplTimeTrkNonDepAllExamsSelectionModalView,

  className: 'time-trk-non-dep-all-exams-selection-modal-view',

  ui: {
    search: '.search',
    resultsGrid: '.results-grid',
    searchResultMax: '.search-result-max',
    searchResultSummary: '.search-result-summary',
    hitCount: '.hit-count',
    submit: '.btn-submit'
  },

  regions: {
    resultsGrid: '@ui.resultsGrid'
  },

  events: {
    'click @ui.search': 'onClickSearch',
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeShow: function() {
    var gridColumns = [
      {
        name: 'selected',
        label: '',
        cell: SelectOneCell.SelectOneRowCell,
        headerCell: SelectOneCell.SelectOneHeaderCell,
        width: 5,
        editable: true,
        sortable: false
      },
      {
        name: 'objectId',
        label: 'Exam Number',
        cell: 'string',
        headerCell: 'custom',
        width: 10,
        editable: false
      },
      {
        name: 'licenseType',
        label: 'License Type',
        cell: 'string',
        headerCell: 'custom',
        width: 23,
        editable: false
      },
      {
        name: 'licenseNumber',
        label: 'License Number',
        cell: 'string',
        headerCell: 'custom',
        width: 15,
        editable: false
      },
      {
        name: 'entityName',
        label: 'Entity Name',
        cell: 'string',
        headerCell: 'custom',
        width: 22,
        editable: false,
        sortable: false
      },
      {
        name: 'examDate',
        label: 'Exam Date',
        cell: MomentCell.MomentCell,
        formatter: MomentCell.MomentDateFormatter.extend({
          emptyValue: 'Not Started'
        }),
        sortValue: MomentCell.MomentSortValue,
        headerCell: 'custom',
        width: 15,
        editable: false
      },
      {
        name: 'yearPeriod',
        label: 'Exam Period',
        cell: 'string',
        headerCell: 'custom',
        width: 10,
        editable: false
      }
    ];

    this.objectSearchResults = new GridView({
      columns: gridColumns,
      collection: new PageableCollection([], { pageSize: 10 }),
      emptyText: 'No exams found.'
    });

    this.showChildView('resultsGrid', this.objectSearchResults);
  },

  onClickSearch: function(e) {
    e.preventDefault();
    var self = this;
    var assignedToUserId = this.model.get('assignedToUserId');

    var searchData = Syphon.serialize(this);
    searchData.assignedToUserId = assignedToUserId;
    var l = Ladda.create(this.ui.search[0]);
    l.start();

    GetExamsForSelection(searchData)
      .done(function(data) {
        l.stop();
        self.objectSearchResults.collection.fullCollection.reset(data.ndExams);
        self.ui.hitCount.text(data.totalHits);
        self.ui.searchResultSummary.show();
        if (data.totalHits === 50) {
          self.ui.searchResultMax.show();
        }
      })
      .fail(function() {
        l.stop();
      });
  },

  onClickSubmit: function(e) {
    e.preventDefault();

    var selectedModel = this.objectSearchResults.viewGrid.getSelectedModel();
    if (selectedModel) {
      this.triggerMethod('modal:close', selectedModel.toJSON());
    } else {
      this.triggerMethod('modal:close');
    }
  }
});
