/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');
var Ladda = require('ladda');
var Radio = require('backbone.radio');
var Syphon = require('backbone.syphon');

var modelGet = require('common/util/modelGet');
var momentUtil = require('common/util/momentUtil');
var BreadcrumbView = require('common/views/BreadcrumbView');
var EntityHeaderBarView = require('../../common/views/EntityHeaderBarView');

var SubmitRicsCompliance = require('../services/SubmitRicsCompliance');

var tmplNonDepRicsComplianceFormView = require('../templates/NonDepRicsComplianceFormView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepRicsComplianceFormView,

  className: 'nondep-rics-compliance-form-view container-fluid',

  ui: {
    headerBar: '.header-bar-container',
    breadcrumb: '.breadcrumb-container',
    datePickerFields: '.date-picker',
    ricsComplianceForm: '.rics-compliance-form',
    submit: '.btn-submit'
  },

  regions: {
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb',
    ricsComplianceForm: '@ui.ricsComplianceForm'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeShow: function() {
    this.showChildView(
      'headerBar',
      new EntityHeaderBarView({
        model: new Backbone.Model(this.model.get('identifiers'))
      })
    );

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#nondep/dashboard',
            text: 'Home'
          },
          {
            link: '#nondep/entity/' + this.model.get('entityId') + '/dashboard',
            text: modelGet(this.model, 'identifiers.entityName')
          },
          {
            text: 'Edit RICS Information'
          }
        ]
      })
    );

    Syphon.deserialize(this, this.model.get('ricsCompliance'));
    this.ui.datePickerFields.datepicker();
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;
    this.validate().done(
      function() {
        var laddaContext = Ladda.create(self.ui.submit.get(0));
        laddaContext.start();

        var formData = self.getFormData();

        formData.entityId = this.model.get('entityId');
        SubmitRicsCompliance(formData)
          .done(function() {
            Radio.channel('navigate').trigger('show:int:nd:entity-dashboard', self.model.get('entityId'));
          })
          .fail(function() {
            laddaContext.stop();
          });
      }.bind(this)
    );
  },

  validate: function() {
    return this.ui.ricsComplianceForm.parsley().whenValidate();
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    formData.tin = modelGet(this.model, 'identifiers.tin');
    formData.clearanceDecisionDate = momentUtil.formatStr(
      formData.clearanceDecisionDate,
      _.extend({}, momentUtil.defaultOutboundDateTimeConfig, { sourceFormat: 'MM/DD/YYYY' })
    );

    formData.ricsComplianceId = modelGet(this.model, 'ricsCompliance.ricsComplianceId');
    return formData;
  }
});
