/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var cleanUpResponse = require('common/util/cleanUpResponse');

var moment = require('moment');

module.exports = function(licenseId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetOfficeManagerByLicense: {
        $xmlns: 'http://dobs.pa.gov/nd/license/bpm/1.0',
        LicenseID: licenseId
      }
    },
    blueprint: {
      id: '$..ID',
      officeManagerIndividualId: '$..OfficeManagerIndividualID',
      firstName: '$..FirstName',
      middleName: '$..MiddleName',
      lastName: '$..LastName',
      title: '$..Title',
      suffix: '$..Suffix',
      ssn: '$..SSN',
      dob: '$..DOB',
      cellPhoneNumber: '$..CellPhoneNumber',
      homePhoneNumber: '$..HomePhoneNumber',
      email: '$..Email',
      addressId: '$..AddressID',
      addressLine1: '$..AddressLine1',
      addressLine2: '$..AddressLine2',
      addressLine3: '$..AddressLine3',
      city: '$..City',
      country: '$..Country',
      postalCode: '$..PostalCode',
      state: '$..State'
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      response.dobDisplay = moment(response.dob, 'YYYY-MM-DD').format('MM/DD/YYYY');
      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
