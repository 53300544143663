var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "  <p>For all applications filed by counsel, New Charter Applications, and mergers, you must download,\r\n    complete and upload the certification found at the link below, in addition to the electronic certification.\r\n    Other applications filed directly by the credit union without counsel need only use the electronic certification.</p>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "  <p>For all applications filed by counsel, New Charter Applications, Section 112 Share Acquisitions of more than one person,\r\n    and mergers"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isBank : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ", you must download, complete and upload the certification found at the link below,\r\n    in addition to the electronic certification.\r\n    Other applications filed directly by the institution without counsel need only use the electronic certification.</p>\r\n";
},"4":function(container,depth0,helpers,partials,data) {
    return " (bank and holding company)";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "    <div class=\"row\">\r\n      <div class=\"col-sm-12\">\r\n        <button class=\"btn btn-primary submit ladda-button\" data-style=\"expand-right\" type=\"button\">\r\n"
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.mode : depth0),"===","ext-new",{"name":"ifCond","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(12, data, 0),"data":data})) != null ? stack1 : "")
    + "        </button>\r\n      </div>\r\n    </div>\r\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.filingDetails : depth0)) != null ? stack1.filingFee : stack1),">",0,{"name":"ifCond","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    return "            <span class=\"ladda-label\">Finalize Filing and Proceed to Cart</span>\r\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "              <span class=\"ladda-label\">Finalize Filing and Submit</span>\r\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.mode : depth0),"===","int-new",{"name":"ifCond","hash":{},"fn":container.program(13, data, 0),"inverse":container.program(15, data, 0),"data":data})) != null ? stack1 : "");
},"13":function(container,depth0,helpers,partials,data) {
    return "          <span class=\"ladda-label\">Submit Filing</span>\r\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.mode : depth0),"===","resubmit",{"name":"ifCond","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"16":function(container,depth0,helpers,partials,data) {
    return "          <span class=\"ladda-label\">Finalize Filing and Submit</span>\r\n          ";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "\r\n<form class=\"certification-form\">\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isCU : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "  <div class=\"form-group\">\r\n    <button class=\"btn btn-primary download-certification\">\r\n      <i class=\"fa fa-download\"></i> \r\n      Click Here to Download Certification Form\r\n    </button>\r\n  </div>\r\n  <div class=\"form-group required\">\r\n    <label class=\"control-label\">Certification Form</label>\r\n    <div class=\"upload-certification\"></div>\r\n  </div>\r\n  <div class=\"form-group required\">\r\n    <label class=\"control-label font-weight-normal certify-label\">\r\n      <input type=\"checkbox\" class=\"certify-checkbox\" name=\"isCertified\" value=\"1\" data-parsley-required=\"true\" />\r\n      By checking this box, I hereby certify that the information contained herein and contained in any attachments or\r\n      exhibits hereto are complete, true and correct. I make this declaration subject to the penalties of 18 PA.C.S S\r\n      4904 relating to unsworn falsification to authorities.\r\n    </label>\r\n  </div>\r\n  <div class=\"form-group required\">\r\n    <label><strong>Enter Name Below</strong></label>\r\n    <input type=\"text\" class=\"form-control\" name=\"certificationName\" data-parsley-required=\"true\" data-parsley-maxlength=\"100\" />\r\n  </div>\r\n"
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || helpers.helperMissing).call(alias1,(depth0 != null ? depth0.mode : depth0),"!=","print",{"name":"ifCond","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</form>";
},"useData":true});