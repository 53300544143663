/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var modelGet = require('common/util/modelGet');
var parsleyUtil = require('common/util/parsleyUtil');
var makeUploadCollection = require('common/util/makeUploadCollection');
var toggleForm = require('common/util/toggleForm');
var momentUtil = require('common/util/momentUtil');

var CommentsDocumentView = require('common/views/CommentsDocumentView');
var AddressView = require('common/views/AddressView');

var tmplDepBankArticlesAmendmentRequiredInformationDocView = require('./DepBankArticlesAmendmentRequiredInformationDocView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepBankArticlesAmendmentRequiredInformationDocView,

  className: 'dep-bank-articles-amendment-required-information-doc-view',

  behaviors: {
    ReadOnlyFormBehavior: {},
    RadioGroupToggleBehavior: {
      radios: [
        {
          target: '.address-col',
          radioGroup: '[name="isSameMailingAddress"]',
          valueToShow: '0'
        }
      ]
    }
  },

  ui: {
    documentForm: '.document-form',
    articlesOfAmendment: '.articles-of-amendment-container',
    boardResolution: '.board-resolution-container',
    shareholderNotice: '.shareholder-notice-container',
    shareholderApproval: '.shareholder-approval-container',
    addressContainer: '.address-container',
    addressCol: '.address-col',
    isSameMailingAddress: '.is-same-mailing-address',
    newPlaceOfBusiness: '.new-place-of-business',
    articlesOfAmendmentTypeSelect: '.articles-of-amendment-type-select',
    newNameContainer: '.new-name-container',
    otherPurposeContainer: '.other-purpose-container',
    nameReservationCol: '.name-reservation-col',
    nameReservation: '.name-reservation',
    missingNameReservationFile: '.missing-name-reservation-file',
    requestedEffectiveDate: '.requested-effective-date'
  },

  events: {
    'change @ui.articlesOfAmendmentTypeSelect': 'onChangeArticlesOfAmendmentType'
  },

  regions: {
    articlesOfAmendment: '@ui.articlesOfAmendment',
    boardResolution: '@ui.boardResolution',
    shareholderNotice: '@ui.shareholderNotice',
    shareholderApproval: '@ui.shareholderApproval',
    addressContainer: '@ui.addressContainer',
    nameReservation: '@ui.nameReservation'
  },

  templateHelpers: function() {
    // 102 for bank amendment and 301 for trust amendment
    var filingTypeId = modelGet(this.model, 'filingDetails.filingTypeId');
    var isTrust = filingTypeId === '301';

    return {
      isReadOnly: this.options.isReadOnly,
      articlesOfAmendmentTypes: this.model.get('articlesOfAmendmentTypes'),
      isTrust: isTrust
    };
  },

  onBeforeShow: function() {
    var filingContent = this.model.get('filingContent') || {};
    var articlesOfAmendment = filingContent.articlesOfAmendment || {};
    var boardResolution = filingContent.boardResolution || {};
    var shareholderNotice = filingContent.shareholderNotice || {};
    var shareholderApproval = filingContent.shareholderApproval || {};
    var nameReservation = filingContent.nameReservation || {};

    this.ui.requestedEffectiveDate.datepicker({
      startDate: '0d'
    });

    // address container
    this.showChildView(
      'addressContainer',
      new AddressView({
        horizontal: false,
        objectName: 'newMailingAddress',
        startingCountry: modelGet(this.model, 'filingContent.newMailingAddress.country'),
        startingPostalCode: modelGet(this.model, 'filingContent.newMailingAddress.postalCode'),
        startingCounty: modelGet(this.model, 'filingContent.newMailingAddress.county')
      })
    );

    Syphon.deserialize(this, filingContent);

    this.onChangeArticlesOfAmendmentType();

    // Articles of Amendment Document
    this.articlesOfAmendment = new CommentsDocumentView({
      isReadOnly: this.options.isReadOnly,
      allowMultipleFiles: false,
      isRequired: true,
      fileUploadLabel: 'Section III - 3. Articles of Amendment',
      commentsLabel: 'Section III - 3. Articles of Amendment Comments',
      commentsName: 'articlesOfAmendmentComments',
      collection: makeUploadCollection(articlesOfAmendment.file),
      model: new Backbone.Model({ articlesOfAmendmentComments: articlesOfAmendment.comments }),
      documentTypeId: 20001
    });
    this.showChildView('articlesOfAmendment', this.articlesOfAmendment);

    // Board Resolution Document
    this.boardResolution = new CommentsDocumentView({
      isReadOnly: this.options.isReadOnly,
      allowMultipleFiles: false,
      isRequired: true,
      fileUploadLabel: 'Section III - 4. Board of Directors/Trustees Resolution',
      commentsLabel: 'Section III - 4. Board of Directors/Trustees Resolution Comments',
      commentsName: 'boardResolutionComments',
      collection: makeUploadCollection(boardResolution.file),
      model: new Backbone.Model({ boardResolutionComments: boardResolution.comments }),
      documentTypeId: 20001
    });
    this.showChildView('boardResolution', this.boardResolution);

    // Share Holder Notice Document
    this.shareholderNotice = new CommentsDocumentView({
      isReadOnly: this.options.isReadOnly,
      allowMultipleFiles: false,
      isRequired: true,
      fileUploadLabel: 'Section III - 5. Shareholder Notice',
      commentsLabel: 'Section III - 5. Shareholder Notice Comments',
      commentsName: 'shareholderNoticeComments',
      collection: makeUploadCollection(shareholderNotice.file),
      model: new Backbone.Model({ shareholderNoticeComments: shareholderNotice.comments }),
      documentTypeId: 20001
    });
    this.showChildView('shareholderNotice', this.shareholderNotice);

    // Share Holder Approval Document
    this.shareholderApproval = new CommentsDocumentView({
      isReadOnly: this.options.isReadOnly,
      allowMultipleFiles: false,
      isRequired: true,
      fileUploadLabel: 'Section III - 6. Shareholder Approval',
      commentsLabel: 'Section III - 6. Shareholder Approval Comments',
      commentsName: 'shareholderApprovalComments',
      collection: makeUploadCollection(shareholderApproval.file),
      model: new Backbone.Model({ shareholderApprovalComments: shareholderApproval.comments }),
      documentTypeId: 20001
    });
    this.showChildView('shareholderApproval', this.shareholderApproval);

    this.nameReservation = new CommentsDocumentView({
      isReadOnly: this.options.isReadOnly,
      allowMultipleFiles: false,
      isRequired: true,
      fileUploadLabel: 'Section III - 7. Name Reservation',
      commentsLabel: 'Section III - 7. Name Reservation Comments',
      commentsName: 'nameReservationComments',
      collection: makeUploadCollection(shareholderApproval.file),
      model: new Backbone.Model({ nameReservationComments: nameReservation.comments }),
      documentTypeId: 20001
    });
    this.showChildView('nameReservation', this.nameReservation);

    toggleForm(this.ui.addressCol, !filingContent.isSameMailingAddress);
  },

  onChangeArticlesOfAmendmentType: function() {
    var isPrinciplePlaceOfBusinessPurpose = this.ui.articlesOfAmendmentTypeSelect.val() === '1';
    var isNameChangePurpose = this.ui.articlesOfAmendmentTypeSelect.val() === '2';
    var isOtherPurpose = this.ui.articlesOfAmendmentTypeSelect.val() === '3';

    toggleForm(this.ui.newPlaceOfBusiness, isPrinciplePlaceOfBusinessPurpose);
    toggleForm(this.ui.newNameContainer, isNameChangePurpose);
    toggleForm(this.ui.nameReservationCol, isNameChangePurpose);
    toggleForm(this.ui.otherPurposeContainer, isOtherPurpose);
  },

  validate: function(config) {
    this.ui.documentForm.parsley().reset();
    this.articlesOfAmendment.removeValidationErrors();
    this.boardResolution.removeValidationErrors();
    this.shareholderNotice.removeValidationErrors();
    this.shareholderApproval.removeValidationErrors();
    this.ui.missingNameReservationFile.hide();

    if (config.type === 'save') {
      // Turn off required field validation for this form
      parsleyUtil.disableRequiredValidation(this.ui.documentForm);
    } else {
      var validationPromises = [
        this.ui.documentForm.parsley().whenValidate(),
        this.articlesOfAmendment.validate(),
        this.boardResolution.validate(),
        this.shareholderNotice.validate(),
        this.shareholderApproval.validate()
      ];

      var isNameChangePurpose = this.ui.articlesOfAmendmentTypeSelect.val() === '2';
      if (isNameChangePurpose) {
        validationPromises.push(this.nameReservation.validate());
      }

      // Turn on required field validation for this form
      parsleyUtil.enableRequiredValidation(this.ui.documentForm);
    }
    return $.when.apply($, validationPromises);
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    formData.articlesOfAmendmentDocId = this.articlesOfAmendment.getFirstFileId();
    formData.boardResolutionDocId = this.boardResolution.getFirstFileId();
    formData.shareholderNoticeDocId = this.shareholderNotice.getFirstFileId();
    formData.shareholderApprovalDocId = this.shareholderApproval.getFirstFileId();
    formData.nameReservationDocId = this.nameReservation.getFirstFileId();
    formData.requestedEffectiveDate = momentUtil.formatStr(
      formData.requestedEffectiveDate,
      momentUtil.defaultOutboundDateConfig
    );
    return formData;
  }
});
