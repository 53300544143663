/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');

var GridView = require('psa-marionette/views/GridView');
var PageableCollection = require('psa-backbone/models/PageableCollection');

var MomentCell = require('common/backgrid/MomentCell');
var AddressCell = require('common/backgrid/AddressCell');

var tmplNonDepLicenseContactHistoryMigrationView = require('../templates/NonDepLicenseContactHistoryMigrationView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepLicenseContactHistoryMigrationView,

  className: 'non-dep-license-contact-history-migration-view',

  ui: {
    licenseContactHistoryMigrationContainer: '.license-contact-history-migration-grid-container'
  },

  regions: {
    licenseContactHistoryMigrationContainer: '@ui.licenseContactHistoryMigrationContainer'
  },

  onBeforeShow: function() {
    // Fill in data with display values.
    var contacts = _.map(this.model.get('contactHistoryMigration'), function(contact) {
      contact.firstName = contact.firstName || '--';
      contact.lastName = contact.lastName || '--';
      contact.title = contact.title || '--';
      contact.company = contact.company || '--';
      contact.workPhoneNumber = contact.workPhoneNumber || '--';
      contact.fax = contact.fax || '--';
      return contact;
    });

    this.contactHistoryMigrationGrid = new GridView({
      columns: [
        {
          name: 'createdDate',
          label: 'Date Changed',
          cell: MomentCell.MomentCell,
          formatter: MomentCell.MomentDateTimeFormatter.extend({
            emptyValue: '--'
          }),
          sortValue: MomentCell.MomentSortValue,
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'createdBy',
          label: 'Changed By',
          cell: 'string',
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'firstName',
          label: 'First Name',
          cell: 'string',
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'licenseContactType',
          label: 'Contact Type',
          cell: 'string',
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'lastName',
          label: 'Last Name',
          cell: 'string',
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'title',
          label: 'Title',
          cell: 'string',
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'company',
          label: 'Company',
          cell: 'string',
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'workPhoneNumber',
          label: 'Work Phone',
          cell: 'string',
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'fax',
          label: 'Fax',
          cell: 'string',
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'address',
          label: 'Address',
          cell: AddressCell,
          headerCell: 'custom',
          width: 10,
          editable: false
        }
      ],
      collection: new PageableCollection(contacts, { pageSize: 25 }),
      emptyText: 'No Migrated License Contact History'
    });

    this.showChildView('licenseContactHistoryMigrationContainer', this.contactHistoryMigrationGrid);

    this.contactHistoryMigrationGrid.viewGrid.sort('createdDate', 'descending');
  }
});
