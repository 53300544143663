/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(complaintId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetRelatedComplaint: {
        $xmlns: 'http://dobs.pa.gov/cs/complaint/bpm/1.0',
        ComplaintID: complaintId
      }
    },
    blueprintArrays: ['GetRelatedComplaintResponse.CS_ComplaintRelated'],
    blueprint: {
      complaints: [
        '$..CS_ComplaintRelated',
        {
          complaintRelatedId: '$..RelatedComplaintID',
          complaintId: '$..ID',
          caseNumber: '$..CaseNumber',
          caseManager: '$..CaseManager',
          entityName: '$..EntityName',
          firstName: '$..FirstName',
          lastName: '$..LastName',
          dateOpened: '$..InitialOpenedDate'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      _.each(response.complaints, function(complaint) {
        if (complaint.firstName || complaint.lastName) {
          complaint.individualName = complaint.firstName + ' ' + complaint.lastName;
        }
        _.each(['caseNumber', 'entityName', 'caseManager', 'individualName'], function(attr) {
          if (!complaint[attr]) {
            complaint[attr] = '--';
          }
        });
        if (complaint.dateOpened) {
          complaint.dateOpened = moment(complaint.dateOpened, 'YYYY-MM-DD');
        }
      });
      deferred.resolve(response.complaints);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
