/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');

var NonDepEntityFormLocationView = require('./NonDepEntityFormLocationView');

require('../styles/NonDepEntityFormLocationView.css');
var tmplNonDepEntityFormLocationPanelView = require('../templates/NonDepEntityFormLocationPanelView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepEntityFormLocationPanelView,

  className: 'nondep-entity-form-location-view panel panel-info',

  ui: {
    removeLocation: '.remove-location',
    locationViewContainer: '.location-view-container'
  },

  regions: {
    locationViewContainer: '@ui.locationViewContainer'
  },

  triggers: {
    'click @ui.removeLocation': 'remove:location'
  },

  childEvents: {
    'selected:primary-place-of-business': 'onChangePrimaryPlaceOfBusiness'
  },

  onBeforeShow: function() {
    this.locationFormView = new NonDepEntityFormLocationView({
      model: this.model
    });
    this.showChildView('locationViewContainer', this.locationFormView);
  },

  templateHelpers: function() {
    return {
      childIndex: this.options.childIndex
    };
  },

  onChangePrimaryPlaceOfBusiness: function() {
    this.triggerMethod('selected:primary-place-of-business');
  },

  setPrimaryPlaceOfBusiness: function(val) {
    this.locationFormView.setPrimaryPlaceOfBusiness(val);
  },

  isPrimaryPlaceOfBusiness: function() {
    return this.locationFormView.isPrimaryPlaceOfBusiness();
  },

  getFormData: function() {
    return this.locationFormView.getFormData();
  }
});
