/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _get = require('lodash.get');
var _ = require('underscore');

var Session = require('psa-core/Session');
var Config = require('../../../config/Config');

var SidebarNavView = require('common/views/SidebarNavView');

var tmplDepInternalSidebarNavView = require('../templates/DepInternalSidebarNavView.hbs');

module.exports = SidebarNavView.extend({
  template: tmplDepInternalSidebarNavView,

  templateHelpers: function() {
    // Get link to bureau resources center
    var bureauResourceCenterCSFolderId = _get(Session, 'config.DP_BUREAU_RESOURCE_CENTER_FOLDER_CSID');
    var bureauResourceCenterLink = Config.getOTCSSmartUIUrl(bureauResourceCenterCSFolderId);

    // Get link to ihub folder with reports
    var iHubReportDirectory = _get(Session, 'departments.DEPOSITORY.iHubReportDirectory');
    var iHubFolderLink = Config.getIHubFolderLink(iHubReportDirectory);

    // Only show left nav SEG Search to CU users - any role that has CU on it
    var userRoles = Session.user.userMgmtRoles;
    var CURoles = [
      'DP - AO - Credit Union',
      'DP - Case Manager - Credit Union',
      'DP - CE - Credit Union',
      'DP - Chief Examiner - Credit Union',
      'DP - Deputy Secretary - Credit Union',
      'DP - Director - Credit Union',
      'DP - EA - Credit Union',
      'DP - EA1 - Credit Union',
      'DP - EA2 - Credit Union',
      'DP - EIC - Credit Union',
      'DP - Exam Final Processor - Credit Union',
      'DP - Exam Initial Processor - Credit Union',
      'DP - Exam Processor - Credit Union',
      'DP - OELB Supervisor - Credit Union',
      'DP - PM - Credit Union',
      'DP - Program Manager - Credit Union'
    ];
    var hasCURole = false;
    _.each(CURoles, function(role) {
      hasCURole = !!_.findWhere(userRoles, { role: role }) || hasCURole;
    });
    return {
      bureauResourceCenterLink: bureauResourceCenterLink,
      iHubFolderLink: iHubFolderLink,
      hasCURole: hasCURole
    };
  }
});
