/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var momentUtil = require('common/util/momentUtil');
var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(feeId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetFee: {
        $xmlns: 'http://dobs.pa.gov/cf/record/bpm/1.0',
        FeeID: feeId
      }
    },
    blueprint: {
      id: '$..ID',
      recordId: '$..RecordID',
      paidDate: '$..PaidDate',
      paidAmount: '$..PaidAmount',
      typeId: '$..TypeID',
      checkNumber: '$..Number',
      transmittalNumber: '$..TransmittalNumber',
      transmittalReference: '$..TransmittalReference',
      transactionDescription: '$..TransactionDescription',
      sapDocNum: '$..SAPDocNum',
      isDeleted: '$..IsDeleted',
      createdDate: '$..CreatedDate',
      createdBy: '$..CreatedBy',
      modifiedDate: '$..ModifiedDate',
      modifiedBy: '$..ModifiedBy'
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      var emptyDefaultInboundDateConfig = {
        sourceFormat: 'YYYY-MM-DD',
        targetFormat: 'MM/DD/YYYY',
        default: ''
      };
      response.paidDate = momentUtil.formatStr(response.paidDate, emptyDefaultInboundDateConfig);

      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
