/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var momentUtil = require('common/util/momentUtil');
var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(referralId) {
  var defer = $.Deferred();

  $.soap({
    body: {
      GetExamInvInqReferralById: {
        $xmlns: 'http://dobs.pa.gov/sc/examInvInq/bpm/1.0',
        ReferralID: referralId
      }
    },
    blueprint: {
      referralId: '$..ID',
      objectTypeId: '$..ObjectTypeID',
      objectId: '$..ObjectID',
      comment: '$..Comment',
      isIncoming: '$..IsIncoming',
      referralOn: '$..ReferralOn',
      referralOptionId: '$..ReferralOptionID',
      isDeleted: '$..IsDeleted'
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      var formInboundDateConfig = {
        sourceFormat: 'YYYY-MM-DD',
        targetFormat: 'MM/DD/YYYY',
        default: undefined
      };

      response.referralOn = momentUtil.formatStr(response.referralOn, formInboundDateConfig);

      defer.resolve(response);
    })
    .fail(function(err) {
      defer.reject(err);
    });

  return defer.promise();
};
