/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Ladda = require('ladda');
var Radio = require('backbone.radio');

var BreadcrumbView = require('common/views/BreadcrumbView');

var CorpFinRecordHeaderBarView = require('../../common/views/CorpFinRecordHeaderBarView');
var SubmitRegistrationSecuritiesDescription = require('../services/SubmitRegistrationSecuritiesDescription');
var CorpFinRegSecuritiesDescriptionFormView = require('./CorpFinRegSecuritiesDescriptionFormView');

var tmplCorpFinRegSecuritiesDescriptionEditView = require('../templates/CorpFinRegSecuritiesDescriptionEditView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplCorpFinRegSecuritiesDescriptionEditView,

  className: 'corp-fin-reg-securities-description-edit-view container-fluid',

  ui: {
    secDescForm: '.sec-desc-form-container',
    headerBar: '.header-bar',
    breadcrumb: '.breadcrumb-container',
    submit: '.submit'
  },

  regions: {
    secDescForm: '@ui.secDescForm',
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeShow: function() {
    var record = this.model.get('record') || {};
    var recordId = this.model.get('recordId');
    var fileNumber = record.fileNumber;

    this.showChildView(
      'headerBar',
      new CorpFinRecordHeaderBarView({
        model: new Backbone.Model(record)
      })
    );

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#corpfin/dashboard',
            text: 'Home'
          },
          {
            link: '#corpfin/registration/' + recordId + '/dashboard',
            text: 'Registration (' + fileNumber + ')'
          },
          {
            text: 'Edit Securities Description'
          }
        ]
      })
    );

    this.secDescForm = new CorpFinRegSecuritiesDescriptionFormView({
      model: this.model
    });

    this.showChildView('secDescForm', this.secDescForm);
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;
    this.validate().done(
      function() {
        var laddaContext = Ladda.create(this.ui.submit.get(0));
        laddaContext.start();
        var formData = this.getFormData();
        SubmitRegistrationSecuritiesDescription(formData)
          .done(function() {
            Radio.channel('navigate').trigger('show:int:cf:reg-dashboard', self.model.get('recordId'));
          })
          .fail(function() {
            laddaContext.stop();
          });
      }.bind(this)
    );
  },

  validate: function() {
    return this.ui.secDescForm.parsley().whenValidate();
  },

  getFormData: function() {
    var formData = this.secDescForm.getFormData();
    return formData;
  }
});
