/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');

var EmptySetView = require('common/views/EmptySetView');

var NonDepEntityFormBooksAndRecordsView = require('./NonDepEntityFormBooksAndRecordsView');

module.exports = Marionette.CollectionView.extend({
  className: 'nondep-entity-form-books-and-records-collection-view',

  childView: NonDepEntityFormBooksAndRecordsView,

  childViewOptions: function(model, index) {
    // Pass the index to the collection view
    return {
      childIndex: index,
      viewMode: this.options.viewMode,
      mode: this.options.mode,
      isReadOnly: this.options.isReadOnly
    };
  },

  childEvents: {
    'remove:books-and-records': 'onChildClickRemoveBooksAndRecords'
  },

  emptyView: EmptySetView,

  emptyViewOptions: {
    emptyText: 'No Books and Records Addresses Provided'
  },

  onBeforeShow: function() {
    if (this.options.isAmendment) {
      this.deletedRecords = [];
    }
  },

  onChildClickRemoveBooksAndRecords: function(childView) {
    // Keep track of deletions to display on internal amendment review page
    var deletedRecord = childView.model;
    if (this.options.isAmendment) {
      var deletedRecordId = deletedRecord.get('id');
      if (deletedRecordId) {
        deletedRecord.set({ isDeleted: '1' });
        this.deletedRecords.push(deletedRecord.toJSON());
      } else {
        deletedRecord.set({ isDeleted: '0' });
      }
    }

    this.collection.remove(deletedRecord);
  },

  getFormData: function() {
    var isAmendment = this.options.isAmendment;

    var deletedBooksAndRecords = [];
    if (this.model) {
      deletedBooksAndRecords = this.model.get('deletedBooksAndRecords') || [];
    }

    var formData = this.children.reduce(function(memo, child) {
      if (child.getFormData && _.isFunction(child.getFormData)) {
        memo.push(child.getFormData());
      }
      return memo;
    }, []);

    // Include newly deleted books and records in formData
    if (isAmendment && this.deletedRecords.length) {
      formData = formData.concat(this.deletedRecords);
    }

    // Include previously deleted books and records in formData (resubmissions)
    if (isAmendment && deletedBooksAndRecords.length) {
      formData = formData.concat(deletedBooksAndRecords);
    }
    return formData;
  }
});
