/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var parsleyUtil = require('common/util/parsleyUtil');
var FileUploadView = require('common/views/FileUploadView');

var FileUploadCollection = require('psa-backbone/models/FileUploadCollection');

require('./InstallmentSellerGeneralDetailsSectionView.css');
var tmplInstallmentSellerGeneralDetailsSectionView = require('./InstallmentSellerGeneralDetailsSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplInstallmentSellerGeneralDetailsSectionView,

  className: 'installment-seller-general-details-section-view',

  ui: {
    generalDetailsForm: '.general-details-form',
    applicationDocumentsFileContainer: '.application-documents-container',
    hasCriminalOfficer: 'input[name="hasCriminalOfficer"]',
    hasOfficerOperatedWithoutLicense: 'input[name="hasOfficerOperatedWithoutLicense"]',
    hasManufacturedHomesFinancing:  'input[name="HasManufacturedHomesFinancing"]',
    otherManufacturedHomesFinancing:  'input[name="otherManufacturedHomesFinancing"]'
  },

  regions: {
    applicationDocumentsFileContainer: '@ui.applicationDocumentsFileContainer'
  },

  behaviors: {
    RadioGroupToggleBehavior: {
      radios: [
        {
          target: '#other-manufactured-homes-financing-question',
          radioGroup: '[name="hasManufacturedHomesFinancing"]',
          valueToShow: '1'
        },
        {
          target: '#other-manufactured-homes-financing-explanation-area',
          radioGroup: '[name="otherManufacturedHomesFinancing"]',
          valueToShow: '1'
        }
      ]
    }
  },

  templateHelpers: function() {
    return {
      identoGoLink: this.options.identoGoLink
    };
  },

  onBeforeShow: function() {
    var application = this.model.get('application');

    this.applicationDocumentsFile = new FileUploadView({
      isRequired: true,
      allowMultipleFiles: true,
      collection: new FileUploadCollection(application.applicationDocuments),
      isReadOnly: this.options.isReadOnly,
      metadata: {
        documentTypeId: 10023
      }
    });
    this.showChildView('applicationDocumentsFileContainer', this.applicationDocumentsFile);

    Syphon.deserialize(this, application);
  },

  validate: function(config) {
    this.ui.generalDetailsForm.parsley().reset();
    if (config.type === 'save') {
      // Turn off required field validation for this form
      parsleyUtil.disableRequiredValidation(this.ui.generalDetailsForm);
    } else {
      // Turn on required field validation for this form
      parsleyUtil.enableRequiredValidation(this.ui.generalDetailsForm);
    }
    return this.ui.generalDetailsForm.parsley().whenValidate();
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    formData.applicationDocuments = this.applicationDocumentsFile.collection.pluck('fileId');
    if (this.ui.hasManufacturedHomesFinancing === '0') {
      formData.otherManufacturedHomesFinancing = null;
      formData.otherManufacturedHomesFinancingExplanation = null;
   }
   if (this.ui.otherManufacturedHomesFinancing === '0') {
      formData.otherManufacturedHomesFinancingExplanation = null;
   }   
    return formData;
  }
});
