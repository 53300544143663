/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var NProgress = require('nprogress');

var NavUtils = require('psa-core/util/nav');
var Session = require('psa-core/Session');

var Scaffold = require('common/layout/Scaffold');

var GetAllSEGLookups = require('../../seg-dashboard/services/GetAllSEGLookups');

var DepSEGSearchView = require('../views/DepSEGSearchView');

module.exports = function() {
  NProgress.start();
  NProgress.set(0.6);

  $.when(GetAllSEGLookups())
    .done(function(segLookups) {
      var searchLimit = Session.config.CM_ELASTICSEARCH_MAX_RESULTS || 1000;
      Scaffold.content.show(
        new DepSEGSearchView({
          model: new Backbone.Model({
            searchLimit: searchLimit,
            individualTypes: segLookups.individualTypes,
            segStatuses: segLookups.status
          })
        })
      );
      NavUtils.navigate('dep/dashboard/seg-search');
      NProgress.done();
    })
    .fail(function() {
      NProgress.done();
    });
};
