var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "\r\n<div class=\"form-horizontal form-static\">\r\n  <div class=\"form-group\">\r\n    <label class=\"control-label col-sm-3 col-md-2\">Fund Name</label>\r\n    <div class=\"form-control-static col-sm-9 col-md-10\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.name : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\r\n  </div>\r\n  <div class=\"row\">\r\n    <div class=\"col-sm-6\">\r\n      <div class=\"form-group\">\r\n        <label class=\"control-label col-sm-6 col-md-4\">Effective Date</label>\r\n        <div class=\"form-control-static col-sm-6 col-md-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.effectiveDate : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\r\n      </div>\r\n    </div>\r\n    <div class=\"col-sm-6\">\r\n      <div class=\"form-group\">\r\n        <label class=\"control-label col-sm-6 col-md-4\">Approval Date</label>\r\n        <div class=\"form-control-static col-sm-6 col-md-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.approvalDate : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\r\n      </div>\r\n    </div>\r\n  </div>\r\n  <div class=\"row\">\r\n    <div class=\"col-sm-6\">\r\n      <div class=\"form-group\">\r\n        <label class=\"control-label col-sm-6 col-md-4\">Last Amendment Date</label>\r\n        <div class=\"form-control-static col-sm-6 col-md-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.effectiveAmendDate : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\r\n      </div>\r\n    </div>\r\n    <div class=\"col-sm-6\">\r\n      <div class=\"form-group\">\r\n        <label class=\"control-label col-sm-6 col-md-4\">Last Amendment Approval Date</label>\r\n        <div class=\"form-control-static col-sm-6 col-md-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.lastAmendmentApprovalDate : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\r\n      </div>\r\n    </div>\r\n  </div>\r\n  <div class=\"row\">\r\n    <div class=\"col-sm-6\">\r\n      <div class=\"form-group\">\r\n        <label class=\"control-label col-sm-6 col-md-4\">Last Audit Date</label>\r\n        <div class=\"form-control-static col-sm-6 col-md-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.lastAuditDate : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\r\n      </div>\r\n    </div>\r\n    <div class=\"col-sm-6\">\r\n      <div class=\"form-group\">\r\n        <label class=\"control-label col-sm-6 col-md-4\">Last Audit Opinion</label>\r\n        <p class=\"form-control-static col-sm-6 col-md-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.lastAuditOpinion : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</p>\r\n      </div>\r\n    </div>\r\n  </div>\r\n   <div class=\"row\">\r\n    <div class=\"col-sm-6\">\r\n      <div class=\"form-group\">\r\n        <label class=\"control-label col-sm-6 col-md-4\">Terminated Date</label>\r\n        <div class=\"form-control-static col-sm-6 col-md-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.terminatedDate : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\r\n      </div>\r\n    </div>\r\n  </div>\r\n  <div class=\"row\">\r\n    <div class=\"col-sm-12\">\r\n      <div class=\"form-group\">\r\n        <label class=\"control-label col-sm-3 col-md-2\">Comments</label>\r\n        <p class=\"form-control-static col-sm-9 col-md-10\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.comments : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</p>\r\n      </div>\r\n    </div>\r\n  </div>\r\n</div>";
},"useData":true});