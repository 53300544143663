/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var _ = require('underscore');
var parsleyUtil = require('common/util/parsleyUtil');
var makeUploadCollection = require('common/util/makeUploadCollection');
var FileUploadCollection = require('psa-backbone/models/FileUploadCollection');
var modelGet = require('common/util/modelGet');

var FileUploadView = require('common/views/FileUploadView');

var tmplDepCreditUnionSEGRequestDocumentationView = require('./DepCreditUnionSEGRequestDocumentationView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepCreditUnionSEGRequestDocumentationView,

  className: 'dep-credit-union-seg-request-documentation-section-view',

  behaviors: {
    ReadOnlyFormBehavior: {}
  },

  ui: {
    documentationForm: '.documentation-form',
    cuLetterContainer: '.cu-letter-container',
    byLaws: '.by-laws',
    otherSupportingDocsContainer: '.other-supporting-docs-container'
  },

  regions: {
    cuLetterContainer: '@ui.cuLetterContainer',
    otherSupportingDocsContainer: '@ui.otherSupportingDocsContainer',
  },

  templateHelpers: function() {
    return {
      isReadOnly: this.options.isReadOnly
    };
  },

  onBeforeShow: function() {
    var filingContent = this.model.get('filingContent') || {};
    
   
    var creditUnionRequestLetter = filingContent.creditUnionRequestLetter || {};

    Syphon.deserialize(this, filingContent);

       this.cuLetter = new FileUploadView({
      isReadOnly: this.options.isReadOnly,
      allowMultipleFiles: false,
      isRequired: true,
      collection: makeUploadCollection(creditUnionRequestLetter.file),
      metadata: {
        documentTypeId: 20001
      }
    });

    this.showChildView('cuLetterContainer', this.cuLetter);

    var supportingDocuments = _.pluck(modelGet(this.model, 'filingContent.supportingDocuments', []), 'file');

    this.otherSupportingDocsContainer = new FileUploadView({
      isReadOnly: this.options.isReadOnly,
      allowMultipleFiles: true,
      isRequired: false,
      collection: new FileUploadCollection(supportingDocuments),
      metadata: {
        documentTypeId: 20001
      }
    });
  
    this.showChildView('otherSupportingDocsContainer', this.otherSupportingDocsContainer);
   
  },

  validate: function(config) {
    this.ui.documentationForm.parsley().reset();
    this.cuLetter.removeValidationErrors();

    if (config.type === 'save') {
      // Turn off required field validation for this form
      parsleyUtil.disableRequiredValidation(this.ui.documentationForm);
    } else {
      var validationPromises = [
        this.ui.documentationForm.parsley().whenValidate(),
        this.cuLetter.validate(),
      ];
      // Turn on required field validation for this form
      parsleyUtil.enableRequiredValidation(this.ui.documentationForm);
    }
    return $.when.apply($, validationPromises);
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);

    formData.otherSupportingDocs = this.otherSupportingDocsContainer.collection.map(function(file) {
      return {
        fileId: file.get('fileId'),
        documentTypeId: modelGet(file, 'metadata.documentTypeId')
      };
    });
     formData.creditUnionRequestLetterDocId = this.cuLetter.getFirstFileId();
   
    return formData;
  },

 
});
