/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');
var Backbone = require('backbone');
var Syphon = require('backbone.syphon');
var toggleForm = require('common/util/toggleForm');

var AddressView = require('common/views/AddressView');
var AddressDisplayView = require('common/views/AddressDisplayView');

var NonDepEntityFormOfficerOtherLicenseCollectionView = require('./NonDepEntityFormOfficerOtherLicenseCollectionView');

require('../styles/NonDepEntityFormOfficerView.css');
var tmplNonDepEntityFormOfficerView = require('../templates/NonDepEntityFormOfficerView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepEntityFormOfficerView,

  className: function() {
    var baseClass = 'nondep-entity-form-officer-view panel';
    if (this.options.isReadOnly && this.options.viewMode !== 'ext' && this.model.get('isDeleted') === true) {
      return baseClass + ' panel-danger';
    } else {
      return baseClass + ' panel-info';
    }
  },

  ui: {
    address: '.address',
    oldAddress: '.old-address',
    removeOfficer: '.remove-officer',
    ein: '.ein',
    firstNameLabel: '#first-name-label',
    firstName: '.first-name',
    lastName: '.last-name',
    lastNameSection: '.last-name-section',
    middleNameSection: '.middle-name-section',
    ssn: '.ssn',
    ssnSection: '.ssn-section',
    dateOfBirth: '.date-of-birth',
    dateOfBirthSection: '.date-of-birth-section',
    homePhoneLabel: '#home-phone-label',
    homePhone: '.home-phone-number',
    effectiveChangeDate: '.effective-change-date',
    addAssociatedLicense: '.add-associated-license',
    associatedLicenseCollectionContainer: '.associated-licenses-collection-container',
    assocatiedLicenseReviewGrid: '.assocatied-licenses-review-grid',
    country: '.country',
    removeOfficerCheckbox: '.remove-officer-checkbox',
    removeOfficerLabel: '.remove-officer-label'
  },

  regions: {
    address: '@ui.address',
    oldAddress: '@ui.oldAddress',
    associatedLicenseCollectionContainer: '@ui.associatedLicenseCollectionContainer',
    assocatiedLicenseReviewGrid: '@ui.assocatiedLicenseReviewGrid'
  },

  triggers: {
    'click @ui.removeOfficer': 'remove:officer'
  },

  events: {
    'change @ui.ein': 'onChangeEIN',
    'click @ui.addAssociatedLicense': 'onClickAddAssociatedLicense',
    'change @ui.country': 'onChangeCountry'
  },

  behaviors: {
    PhoneFormatBehavior: {}
  },

  templateHelpers: function() {
    var amendmentChanges;
    var showChanges =
      this.options.isReadOnly && this.options.viewMode !== 'ext' && this.model.get('isDeleted') !== true;
    if (showChanges) {
      amendmentChanges = this.getAmendmentChanges();
    }
    var changingOneOfficer = this.options.viewMode === 'int' || this.options.isAmendment;

    return {
      isAmendment: this.options.isAmendment,
      showDeleteOfficerButton: !changingOneOfficer,
      isDeleted: this.options.isReadOnly && this.options.viewMode !== 'ext' && this.model.get('isDeleted') === true,
      officerTypes: this.options.officerTypes,
      modelId: this.model.cid,
      amendmentChanges: amendmentChanges,
      showChanges: showChanges,
      viewMode: this.options.viewMode
    };
  },

  childEvents: {
    'remove:associated-license': 'onChildRemoveAssociatedLicense'
  },

  onBeforeShow: function() {
    var isNewOfficer = _.isUndefined(this.model.get('entityOfficerId'));
    var isOfficerDeleted = this.model.get('isDeleted') === true;
    var amendmentChanges = {};
    var oldOfficer = this.model.get('oldOfficer') || {};
    var showChanges = this.options.isReadOnly && this.options.viewMode !== 'ext' && !isOfficerDeleted;
    if (showChanges) {
      amendmentChanges = this.getAmendmentChanges();
    }

    this.showChildView(
      'address',
      new AddressView({
        horizontal: false,
        startingCountry: this.model.get('country'),
        startingPostalCode: this.model.get('postalCode'),
        startingCounty: this.model.get('county'),
        isRequired: true
      })
    );

    if (amendmentChanges.address) {
      var oldAddress = {
        addressLine1: oldOfficer.addressLine1,
        addressLine2: oldOfficer.addressLine2,
        addressLine3: oldOfficer.addressLine3,
        city: oldOfficer.city,
        state: oldOfficer.state,
        postalCode: oldOfficer.postalCode,
        country: oldOfficer.country
      };

      this.showChildView('oldAddress', new AddressDisplayView({ model: new Backbone.Model(oldAddress) }));
    }

    Syphon.deserialize(this, this.model.toJSON());

    if (this.options.viewMode === 'int' || isNewOfficer === true) {
      this.ui.dateOfBirth.datepicker({
        endDate: '0d',
        startView: 'years'
      });
    }
    this.ui.effectiveChangeDate.datepicker({
      startView: 'days',
      startDate: '0d'
    });

    // Match old and new Associated Licenses in order to show changes when reviewing amendments
    var appAssociatedLicenses = this.model.get('associatedLicenses') || [];
    var deletedAssociatedLicenses = [];
    var associatedLicensesToShow = [];
    if (this.options.isReadOnly && this.options.viewMode !== 'ext' && !isOfficerDeleted) {
      // Flag updated and new Associated Licenses to display changes for internal reviewer
      associatedLicensesToShow = _.map(appAssociatedLicenses, function(appAssociatedLicense) {
        if (appAssociatedLicense.id) {
          var oldAssociatedLicense = _.findWhere(oldOfficer.associatedLicenses, {
            id: appAssociatedLicense.id
          });
          appAssociatedLicense.oldAssociatedLicense = oldAssociatedLicense;
        }
        return appAssociatedLicense;
      });
    } else if (isOfficerDeleted) {
      // If user is deleting an officer, always show the original set of associated licenses for that officer
      associatedLicensesToShow = this.model.get('entityAssociatedLicenses');
    } else {
      // Show form without changes on the external 'View Application' page or if application is returned to entity
      associatedLicensesToShow = _.filter(appAssociatedLicenses, function(appAssociatedLicense) {
        return appAssociatedLicense.isDeleted !== '1';
      });

      // For resubmissions, store previously deleted associated licenses to send back to internal reviewer with the rest of the form updates
      deletedAssociatedLicenses = _.where(appAssociatedLicenses, { isDeleted: '1' });
    }

    this.officerOtherLicenses = new NonDepEntityFormOfficerOtherLicenseCollectionView({
      collection: new Backbone.Collection(associatedLicensesToShow),
      model: new Backbone.Model({ deletedAssociatedLicenses: deletedAssociatedLicenses }),
      isAmendment: true,
      viewMode: this.options.viewMode,
      isReadOnly: this.options.isReadOnly
    });

    if (this.options.viewMode === 'ext' && isNewOfficer === false) {
      this.ui.ssn.attr('disabled', 'true');
      this.ui.dateOfBirth.attr('disabled', 'true');
      var allOfficers = this.model.get('allEntityOfficers');
      if (!_.isUndefined(allOfficers)) {
        if (allOfficers.length === 1) {
          toggleForm(this.ui.removeOfficerCheckbox, false);
          toggleForm(this.ui.removeOfficerLabel, false);
        }
      }
    }

    this.showChildView('associatedLicenseCollectionContainer', this.officerOtherLicenses);

    this.onChangeEIN();
    this.onChangeCountry();
  },

  onChangeEIN: function() {
    var needIndividualInfo = !this.ui.ein.val();
    if (!needIndividualInfo) {
      this.ui.lastName.removeAttr('data-parsley-required');
      this.ui.ssn.removeAttr('data-parsley-required');
      this.ui.dateOfBirth.removeAttr('data-parsley-required');
      this.ui.firstNameLabel[0].innerHTML = 'Company Name';
      this.ui.firstName.attr('placeholder', 'Company Name');
      this.ui.homePhoneLabel[0].innerHTML = 'Office Phone Number';
      this.ui.homePhone.attr('placeholder', 'Office Phone Number');

      toggleForm(this.ui.lastNameSection, false);
      toggleForm(this.ui.ssnSection, false);
      toggleForm(this.ui.dateOfBirthSection, false);
      toggleForm(this.ui.middleNameSection, false);
    } else {
      this.ui.lastName.attr('data-parsley-required', needIndividualInfo);
      this.ui.ssn.attr('data-parsley-required', needIndividualInfo);
      this.ui.dateOfBirth.attr('data-parsley-required', needIndividualInfo);
      this.ui.firstNameLabel[0].innerHTML = 'First Name';
      this.ui.firstName.attr('placeholder', 'First Name');
      this.ui.homePhoneLabel[0].innerHTML = 'Home Phone Number';
      this.ui.homePhone.attr('placeholder', 'Home Phone Number');

      this.ui.lastNameSection.toggleClass('required', needIndividualInfo);
      this.ui.ssnSection.toggleClass('required', needIndividualInfo);
      this.ui.dateOfBirthSection.toggleClass('required', needIndividualInfo);

      toggleForm(this.ui.lastNameSection, true);
      toggleForm(this.ui.ssnSection, true);
      toggleForm(this.ui.dateOfBirthSection, true);
      toggleForm(this.ui.middleNameSection, true);
    }
    this.onChangeCountry();
  },

  onChangeCountry: function() {
    var formData = this.getFormData();
    var countrySelection = formData.country;
    if (countrySelection !== 'US') {
      this.ui.ssn.attr('data-parsley-required', false);
      this.ui.ssnSection.toggleClass('required', false);
    } else {
      this.ui.ssn.attr('data-parsley-required', true);
      this.ui.ssnSection.toggleClass('required', true);
    }
  },

  onClickAddAssociatedLicense: function(e) {
    e.preventDefault();
    this.officerOtherLicenses.collection.add({});
  },

  onChildRemoveAssociatedLicense: function(childView) {
    this.officerOtherLicenses.collection.remove(childView.model);
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    var officerOtherLicenses = this.officerOtherLicenses.getFormData();
    var isNewOfficer = _.isUndefined(this.model.get('entityOfficerId'));
    if (this.options.viewMode === 'ext' && isNewOfficer === false) {
      formData.ssn = this.model.get('ssn');
      formData.dob = this.model.get('dob');
    }

    formData = _.extend(formData, {
      associatedLicenses: officerOtherLicenses,
      entityOfficerId: this.model.get('id')
    });
    return formData;
  },

  getAmendmentChanges: function() {
    // Check that an old value exists for each Officer property and that new value is different
    var oldOfficer = this.model.get('oldOfficer') || {};
    var oldAddress;

    if (!_.isEmpty(oldOfficer)) {
      oldAddress = {
        addressLine1: oldOfficer.addressLine1,
        addressLine2: oldOfficer.addressLine2,
        addressLine3: oldOfficer.addressLine3,
        city: oldOfficer.city,
        state: oldOfficer.state,
        postalCode: oldOfficer.postalCode,
        country: oldOfficer.country
      };
    }

    var appAddress = {
      addressLine1: this.model.get('addressLine1') || null,
      addressLine2: this.model.get('addressLine2') || null,
      addressLine3: this.model.get('addressLine3') || null,
      city: this.model.get('city') || null,
      state: this.model.get('state') || null,
      postalCode: this.model.get('postalCode') || null,
      country: this.model.get('country') || null
    };

    return {
      officerType:
        oldOfficer.entityOfficerTypeId && this.model.get('entityOfficerTypeId') !== oldOfficer.entityOfficerTypeId,
      title: oldOfficer.title && this.model.get('title') !== oldOfficer.title,
      firstName: oldOfficer.firstName && this.model.get('firstName') !== oldOfficer.firstName,
      lastName: oldOfficer.lastName && this.model.get('lastName') !== oldOfficer.lastName,
      middleName: oldOfficer.middleName && this.model.get('middleName') !== oldOfficer.middleName,
      ssn: oldOfficer.ssn && this.model.get('ssn') !== oldOfficer.ssn,
      dob: oldOfficer.dob && this.model.get('dob') !== oldOfficer.dob,
      homePhone: oldOfficer.homePhoneNumber && this.model.get('homePhoneNumber') !== oldOfficer.homePhoneNumber,
      cellPhone: oldOfficer.cellPhoneNumber && this.model.get('cellPhoneNumber') !== oldOfficer.cellPhoneNumber,
      email: oldOfficer.email && this.model.get('email') !== oldOfficer.email,
      address: oldAddress && !_.isEqual(oldAddress, appAddress)
    };
  }
});
