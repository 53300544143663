/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');

var MomentCell = require('common/backgrid/MomentCell');
var GridSpinnerView = require('common/views/GridSpinnerView');

var DepInternalSidebarNavView = require('../../common/views/DepInternalSidebarNavView');

require('../styles/DepExternalTasksView.css');
var tmplDepExternalTasksView = require('../templates/DepExternalTasksView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepExternalTasksView,

  className: 'dep-external-tasks-view container-fluid',

  ui: {
    sideBar: '.side-bar-container',
    externalTasksContainer: '.external-tasks-container'
  },

  regions: {
    sideBar: '@ui.sideBar',
    externalTasksContainer: '@ui.externalTasksContainer'
  },

  onBeforeShow: function() {
    this.showChildView('sideBar', new DepInternalSidebarNavView());
    var taskGridColumns = [
      {
        name: 'type',
        label: 'Task Name',
        cell: 'string',
        headerCell: 'custom',
        width: 33,
        editable: false
      },
      {
        name: 'entityName',
        label: 'Entity Name',
        cell: 'string',
        headerCell: 'custom',
        width: 33,
        editable: false
      },
      {
        name: 'deliveryDate',
        label: 'Assigned On',
        cell: MomentCell.MomentCell,
        formatter: MomentCell.MomentDateTimeFormatter.extend({
          emptyValue: '--'
        }),
        sortValue: MomentCell.MomentSortValue,
        headerCell: 'custom',
        width: 33,
        editable: false
      }
    ];

    this.externalTasksView = new GridSpinnerView({
      columns: taskGridColumns,
      spinner: {
        scale: 0.75,
        length: 10
      },
      pageable: true
    });

    this.showChildView('externalTasksContainer', this.externalTasksView);

    Radio.channel('dp:internal-dashboard')
      .request('build:dep-external-tasks-grid-view', taskGridColumns)
      .done(
        function(gridView) {
          if (!this.isDestroyed) {
            this.externalTasksView = gridView;
            this.showChildView('externalTasksContainer', gridView);
            gridView.viewGrid.sort('deliveryDate', 'descending');
          }
        }.bind(this)
      );
  }
});
