var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "\r\n<div class=\"page-title clearfix\">\r\n  <h1 class=\"pull-left m-n p-n\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.task : depth0)) != null ? stack1.taskType : stack1)) != null ? stack1.description : stack1), depth0))
    + " - "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.examPaymentDetails : depth0)) != null ? stack1.daysPastDue : stack1), depth0))
    + " Days Past Due</h1>\r\n  <div class=\"page-title-buttons-container\">\r\n    <div class=\"cs-link-container\"></div>\r\n  </div>\r\n</div>\r\n<div class=\"header-bar\"></div>\r\n<div class=\"breadcrumb-container\"></div>\r\n<div class=\"task-status\"></div>\r\n\r\n<div class=\"row\">\r\n  <form class=\"form-horizontal col-sm-12 col-md-10 col-md-offset-1 payment-past-due-task-form\">\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-2\">Link to Exam Dashboard</label>\r\n      <div class=\"col-sm-8\">\r\n        <a role=\"button\" class=\"btn btn-primary\" href=\"#nondep/dashboard/exam/"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.examDetails : depth0)) != null ? stack1.examId : stack1), depth0))
    + "\"\r\n          target=\"_blank\">Open Exam</a>\r\n      </div>\r\n    </div>\r\n    <div class=\"payment-details-container m-b-lg\"></div>\r\n    <div class=\"row\">\r\n      <div class=\"col-sm-8 col-sm-offset-2 task-instructions\">All correspondence whether via email, telephone call or\r\n        USPS will be documented. Any correspondence by telephone will be manually entered, complete with:\r\n        <ul class=\"unstyled\">\r\n          <li>Date &amp; time of telephone call</li>\r\n          <li>Staff member(s) who participated in the call</li>\r\n          <li>Who was spoken to</li>\r\n          <li>A synopsis of the conversation</li>\r\n        </ul>\r\n      </div>\r\n    </div>\r\n\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-2\">Billing Comments</label>\r\n      <div class=\"col-sm-8\">\r\n        <textarea class=\"form-control\" rows=\"10\" name=\"billingComments\" placeholder=\"Billing Comments\"></textarea>\r\n      </div>\r\n    </div>\r\n    <div class=\"row bottom-spacer\">\r\n      <div class=\"col-sm-8 col-sm-offset-2\">\r\n        <a href=\"#nondep/dashboard\" class=\"btn btn-default\" role=\"button\">Cancel</a>\r\n        <button class=\"btn btn-primary submit ladda-button\" data-style=\"expand-right\" type=\"button\">\r\n          <span class=\"ladda-label\">Submit</span>\r\n        </button>\r\n        <div class=\"task-actions\"></div>\r\n      </div>\r\n    </div>\r\n  </form>\r\n</div>";
},"useData":true});