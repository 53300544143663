/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(data) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetLicenseContactsByEntityAndLicenseType: {
        $xmlns: 'http://dobs.pa.gov/nd/license/bpm/1.0',
        EntityID: data.entityId,
        LicenseTypeID: data.licenseTypeId
      }
    },
    blueprintArrays: ['GetLicenseContactsByEntityAndLicenseTypeResponse.ND_LicenseContact'],
    blueprint: {
      contacts: [
        '$..ND_LicenseContact',
        {
          contactId: '$..ID',
          entityId: '$..EntityID',
          firstName: '$..FirstName',
          lastName: '$..LastName',
          title: '$..Title',
          phoneNumber: '$..CellPhoneNumber',
          fax: '$..Fax',
          email: '$..Email',
          licenseNumber: '$..LicenseNumber',
          addressLine1: '$..AddressLine1',
          addressLine2: '$..AddressLine2',
          addressLine3: '$..AddressLine3',
          county: '$..County',
          city: '$..City',
          state: '$..State',
          postalCode: '$..PostalCode',
          country: '$..Country',
          licenseTypeId: '$..LicenseTypeID'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      _.each(response.contacts, function(contact) {
        // Add a UUID and isNonDep flag to each contact so these contacts can
        // be merged with contacts from other departments in Consumer Services.
        contact.uuid = contact.contactId;
        contact.isNonDep = true; // department ID for nondep
      });
      deferred.resolve(response.contacts);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
