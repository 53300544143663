/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var NProgress = require('nprogress');

var NavUtils = require('psa-core/util/nav');

var Scaffold = require('common/layout/Scaffold');

var GetExamInvInqStaff = require('../../common/services/GetExamInvInqStaff');
var GetInvInqIdentifiers = require('../services/GetInvInqIdentifiers');
var GetInvestigationInquiry = require('../services/GetInvestigationInquiry');
var SecInvInqDashboardView = require('../views/SecInvInqDashboardView');

module.exports = function(invInqId) {
  NProgress.start();
  NProgress.set(0.6);
  $.when(
    GetInvInqIdentifiers(invInqId),
    GetInvestigationInquiry(invInqId),
    GetExamInvInqStaff({ objectTypeId: 'INV-INQ', objectId: invInqId })
  )
    .done(function(identifiers, invInq, staffMembers) {
      NavUtils.navigate('#sec/investigation-inquiry/' + invInqId + '/dashboard');
      Scaffold.content.show(
        new SecInvInqDashboardView({
          model: new Backbone.Model({
            invInqId: invInqId,
            identifiers: identifiers,
            invInq: invInq,
            staffMembers: staffMembers
          })
        })
      );
      NProgress.done();
    })
    .fail(function() {
      NProgress.done();
    });
};
