/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');

var formatAsDollars = require('common/util/formatAsDollars');

var tmplNonDepCompliancePaymentDetailsSectionView = require('../templates/NonDepCompliancePaymentDetailsSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepCompliancePaymentDetailsSectionView,

  className: 'non-dep-compliance-payment-details-section-view',

  onBeforeRender: function() {
    var caseObj = this.model.get('case');
    var anticipatedFineNotIssuedStr = caseObj.anticipatedFineNotIssued;
    if (anticipatedFineNotIssuedStr) {
      var anticipatedFineNotIssued = formatAsDollars(parseFloat(anticipatedFineNotIssuedStr));
      caseObj.anticipatedFineNotIssued = anticipatedFineNotIssued;
    } else {
      caseObj.anticipatedFineNotIssued = '$0.00';
    }

    var uncollectedDollarAmountStr = caseObj.uncollectedDollarAmount;
    if (uncollectedDollarAmountStr) {
      var uncollectedDollarAmount = formatAsDollars(parseFloat(uncollectedDollarAmountStr));
      caseObj.uncollectedDollarAmount = uncollectedDollarAmount;
    } else {
      caseObj.uncollectedDollarAmount = '$0.00';
    }

    var restitutionPaidAmountStr = caseObj.restitutionPaid;
    if (restitutionPaidAmountStr) {
      var restitutionPaid = formatAsDollars(parseFloat(restitutionPaidAmountStr));
      caseObj.restitutionPaid = restitutionPaid;
    } else {
      caseObj.restitutionPaid = '$0.00';
    }

    var underAppealAmountStr = caseObj.underAppealAmount;
    if (underAppealAmountStr) {
      var underAppealAmount = formatAsDollars(parseFloat(underAppealAmountStr));
      caseObj.underAppealAmount = underAppealAmount;
    } else {
      caseObj.underAppealAmount = '$0.00';
    }

    var waivedDollarAmountStr = caseObj.waivedDollarAmount;
    if (waivedDollarAmountStr) {
      var waivedDollarAmount = formatAsDollars(parseFloat(waivedDollarAmountStr));
      caseObj.waivedDollarAmount = waivedDollarAmount;
    } else {
      caseObj.waivedDollarAmount = '$0.00';
    }

    this.model.set('case', caseObj);
  }
});
