/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

module.exports = function(searchBody) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      PerformElasticSearch: {
        $xmlns: 'http://dobs.pa.gov/cm/search/bpm/1.0',
        IndexName: 'sc-individual',
        SearchBody: JSON.stringify(searchBody)
      }
    },
    blueprint: {
      searchResults: '$..Response'
    }
  })
    .done(function(result) {
      var searchResult = JSON.parse(result.searchResults);
      // For Testing
      // console.log(JSON.stringify(searchResult, null, 2));
      var hits = _.map(searchResult.hits.hits, function(hit) {
        var result = hit._source;
        result.lastName = result.lastName || '--';
        result.firstName = result.firstName || '--';
        result.middleInitial = result.middleInitial || '--';
        result.crdNumber = result.crdNumber || '--';
        return result;
      });
      var results = {
        hits: hits,
        totalHits: searchResult.hits.total.value,
        took: searchResult.took
      };
      deferred.resolve(results);
    })
    .fail(function(err) {
      deferred.reject(err);
    });

  return deferred.promise();
};
