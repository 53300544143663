var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "          <option value=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.category || (depth0 != null ? depth0.category : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"category","hash":{},"data":data}) : helper)))
    + "</option>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "\r\n<div class=\"panel-body\">\r\n  <div class=\"row\">\r\n    <div class=\"col-time-category\">\r\n      <div class=\"form-group\">\r\n        <select class=\"form-control category-select m-t-sm\" name=\"categoryId\" data-parsley-required=\"true\">\r\n          <option value=\"\">--</option>\r\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.categories : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </select>\r\n      </div>\r\n    </div>\r\n    <div class=\"col-time-object\">\r\n      <div class=\"form-group\">\r\n        <div class=\"input-group object-selection\">\r\n          <span class=\"input-group-btn\">\r\n            <button class=\"btn btn-primary btn-object-selection\">Select</button>\r\n          </span>\r\n          <input type=\"text\" class=\"selected-object-name form-control\"\r\n            placeholder=\"Select Exam, Investigation, or Inquiry\" name=\"objectDisplayName\" readonly>\r\n          <span class=\"input-group-btn\">\r\n            <button class=\"btn btn-default btn-clear-object\"><i class=\"fa fa-times\" aria-hidden=\"true\"></i></button>\r\n          </span>\r\n          <input type=\"number\" class=\"selected-object-id\" name=\"objectId\"\r\n            data-parsley-errors-container=\"#timeObjErrorContainer"
    + alias4(((helper = (helper = helpers.childIndex || (depth0 != null ? depth0.childIndex : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"childIndex","hash":{},"data":data}) : helper)))
    + "\" hidden>\r\n        </div>\r\n        <span id=\"timeObjErrorContainer"
    + alias4(((helper = (helper = helpers.childIndex || (depth0 != null ? depth0.childIndex : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"childIndex","hash":{},"data":data}) : helper)))
    + "\"></span>\r\n        <div class=\"m-t-xs\">\r\n          <label class=\"m-b-n control-label font-weight-normal\">\r\n            <input type=\"checkbox\" class=\"show-closed-checkbox\" value=\"1\" />\r\n            Include billed exams, investigations, and inquiries\r\n          </label>\r\n        </div>\r\n      </div>\r\n    </div>\r\n    <div class=\"col-time-hrs\">\r\n      <div class=\"hours-container\"></div>\r\n    </div>\r\n    <div class=\"col-time-remove\">\r\n      <button class=\"btn btn-danger pull-right btn-sm remove-entry\">\r\n        <i class=\"fa fa-times\" aria-hidden=\"true\"></i>\r\n      </button>\r\n    </div>\r\n  </div>\r\n</div>";
},"useData":true});