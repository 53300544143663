/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Ladda = require('ladda');
var Radio = require('backbone.radio');

var BreadcrumbView = require('common/views/BreadcrumbView');

var CorpFinRecordHeaderBarView = require('../../common/views/CorpFinRecordHeaderBarView');
var SubmitFee = require('../services/SubmitFee');
var CorpFinFeeFormView = require('./CorpFinFeeFormView');

var tmplCorpFinFeeNewView = require('../templates/CorpFinFeeNewView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplCorpFinFeeNewView,

  className: 'corp-fin-fee-new-view container-fluid',

  ui: {
    feeForm: '.fee-form-container',
    headerBar: '.header-bar',
    breadcrumb: '.breadcrumb-container',
    submit: '.submit'
  },

  regions: {
    feeForm: '@ui.feeForm',
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  templateHelpers: function() {
    var record = this.model.get('record') || {};
    var recordCategoryId = record.recordCategoryId;

    return {
      isRegistration: recordCategoryId === '1',
      isExemption: recordCategoryId === '2',
      isInvestment: recordCategoryId === '3'
    };
  },

  onBeforeShow: function() {
    var record = this.model.get('record') || {};
    var recordId = this.model.get('recordId');

    var recordCategoryId = record.recordCategoryId;
    var fileNumber = record.fileNumber;

    var isRegistration = recordCategoryId === '1';
    var isExemption = recordCategoryId === '2';
    var isInvestment = recordCategoryId === '3';

    var breadcrumbLink;
    var breadcrumbText;

    if (isRegistration) {
      breadcrumbLink = '#corpfin/registration/' + recordId + '/dashboard';
      breadcrumbText = 'Registration (' + fileNumber + ')';
    }

    if (isExemption) {
      breadcrumbLink = '#corpfin/exemption/' + recordId + '/dashboard';
      breadcrumbText = 'Exemption (' + fileNumber + ')';
    }

    if (isInvestment) {
      breadcrumbLink = '#corpfin/investment/' + recordId + '/dashboard';
      breadcrumbText = 'Investment (' + fileNumber + ')';
    }

    this.showChildView(
      'headerBar',
      new CorpFinRecordHeaderBarView({
        model: new Backbone.Model(record)
      })
    );

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#corpfin/dashboard',
            text: 'Home'
          },
          {
            link: breadcrumbLink,
            text: breadcrumbText
          },
          {
            text: 'New Fee'
          }
        ]
      })
    );

    this.feeForm = new CorpFinFeeFormView({
      model: this.model
    });

    this.showChildView('feeForm', this.feeForm);
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var record = this.model.get('record') || {};

    var recordCategoryId = record.recordCategoryId;

    var isRegistration = recordCategoryId === '1';
    var isExemption = recordCategoryId === '2';
    var isInvestment = recordCategoryId === '3';

    this.validate().done(
      function() {
        var laddaContext = Ladda.create(this.ui.submit.get(0));
        laddaContext.start();
        var formData = this.getFormData();
        SubmitFee(formData)
          .done(function() {
            if (isRegistration) {
              Radio.channel('navigate').trigger('show:int:cf:reg-dashboard', formData.recordId);
            }
            if (isExemption) {
              Radio.channel('navigate').trigger('show:int:cf:exemption-dashboard', formData.recordId);
            }
            if (isInvestment) {
              Radio.channel('navigate').trigger('show:int:cf:investment-dashboard', formData.recordId);
            }
          })
          .fail(function() {
            laddaContext.stop();
          });
      }.bind(this)
    );
  },

  validate: function() {
    return this.ui.feeForm.parsley().whenValidate();
  },

  getFormData: function() {
    var formData = this.feeForm.getFormData();
    formData.recordId = this.model.get('recordId');
    return formData;
  }
});
