var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.showEditButton : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "        <a href=\"#ext/nondep/entity/"
    + alias4(((helper = (helper = helpers.entityId || (depth0 != null ? depth0.entityId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"entityId","hash":{},"data":data}) : helper)))
    + "/officer-change/"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" role=\"button\" class=\"btn btn-primary btn-md\">\r\n          <span class=\"fa fa-edit\" aria-hidden=\"true\"></span>\r\n          <span>Apply To Edit</span>\r\n        </a>\r\n";
},"4":function(container,depth0,helpers,partials,data) {
    var helper;

  return "    <a href=\"#nondep/individual/"
    + container.escapeExpression(((helper = (helper = helpers.individualId || (depth0 != null ? depth0.individualId : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"individualId","hash":{},"data":data}) : helper)))
    + "/dashboard\" target=\"_blank\" role=\"button\" class=\"btn btn-primary btn-md\">\r\n      <span class=\"fa fa-external-link\" aria-hidden=\"true\"></span>\r\n      <span>View</span>\r\n    </a>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "\r\n<div class=\"panel-body row\">\r\n  <div class=\"col-sm-9\">\r\n    <div class=\"row row-top\">\r\n      <div class=\"col-sm-3\">\r\n        <label class=\"control-label\">First Name</label>\r\n        <div class=\"form-control-static\">"
    + alias4(((helper = (helper = helpers.firstName || (depth0 != null ? depth0.firstName : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"firstName","hash":{},"data":data}) : helper)))
    + "</div>\r\n      </div>\r\n      <div class=\"col-sm-3\">\r\n        <label class=\"control-label\">Last Name</label>\r\n        <div class=\"form-control-static\">"
    + alias4((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.lastName : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\r\n      </div>\r\n      <div class=\"col-sm-3\">\r\n        <label class=\"control-label\">Email</label>\r\n        <div class=\"form-control-static truncate\">"
    + alias4(((helper = (helper = helpers.email || (depth0 != null ? depth0.email : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"email","hash":{},"data":data}) : helper)))
    + "</div>\r\n      </div>\r\n      <div class=\"col-sm-3\">\r\n        <label class=\"control-label address\">Residential Address</label>\r\n        <div class=\"officer-address\"></div>\r\n      </div>\r\n    </div>\r\n    <div class=\"row row-bottom m-t-sm\">\r\n      <div class=\"col-sm-3\">\r\n        <label class=\"control-label\">Home Phone Number</label>\r\n        <div class=\"form-control-static\">"
    + alias4(((helper = (helper = helpers.homePhoneNumber || (depth0 != null ? depth0.homePhoneNumber : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"homePhoneNumber","hash":{},"data":data}) : helper)))
    + "</div>\r\n      </div>\r\n      <div class=\"col-sm-3\">\r\n        <label class=\"control-label\">Cell Phone Number</label>\r\n        <div class=\"form-control-static\">"
    + alias4(((helper = (helper = helpers.cellPhoneNumber || (depth0 != null ? depth0.cellPhoneNumber : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cellPhoneNumber","hash":{},"data":data}) : helper)))
    + "</div>\r\n      </div>\r\n      <div class=\"col-sm-3\">\r\n        <label class=\"control-label\">Title</label>\r\n        <div class=\"form-control-static\">"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</div>\r\n      </div>\r\n      <div class=\"col-sm-3\">\r\n        <label class=\"control-label\">Officer Type</label>\r\n        <div class=\"form-control-static\">"
    + alias4(((helper = (helper = helpers.officerType || (depth0 != null ? depth0.officerType : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"officerType","hash":{},"data":data}) : helper)))
    + "</div>\r\n      </div>\r\n    </div>\r\n  </div>\r\n  <div class=\"col-sm-3 button-container\">\r\n"
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias2).call(alias1,(depth0 != null ? depth0.viewMode : depth0),"===","ext",{"name":"ifCond","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + "  </div>\r\n</div>";
},"useData":true});