/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');
var Ladda = require('ladda');

var BreadcrumbView = require('common/views/BreadcrumbView');

var ConSerContactFormView = require('../views/ConSerContactFormView');

var SubmitContact = require('../../common/services/SubmitContact');

var tmplConSerContacEditView = require('../templates/ConSerContactEditView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplConSerContacEditView,

  className: 'con-ser-contact-edit-view container-fluid',

  ui: {
    breadcrumb: '.breadcrumb-container',
    contactContainer: '.contact-form-container',
    submit: '.submit'
  },

  regions: {
    breadcrumb: '@ui.breadcrumb',
    contactContainer: '@ui.contactContainer'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeShow: function() {
    var entity = this.model.get('entity');
    var contact = this.model.get('contact');
    var contactType = this.model.get('contactType');
    var breadcrumbs = [
      {
        link: '#conser/dashboard',
        text: 'Home'
      },
      {
        link: '#conser/' + entity.departmentId + '/entity/' + entity.entityId + '/dashboard',
        text: entity.entityName
      },
      {
        text: 'Edit Contact Details'
      }
    ];

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: breadcrumbs
      })
    );

    this.contactForm = new ConSerContactFormView({
      model: new Backbone.Model({ contact: contact, contactType: contactType })
    });
    this.showChildView('contactContainer', this.contactForm);
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;
    this.validate().done(function() {
      var entity = self.model.get('entity');
      var contactId = self.model.get('contact').id;

      var l = Ladda.create(self.ui.submit[0]);
      l.start();

      var formData = self.getFormData(entity, contactId);
      SubmitContact(formData)
        .done(function() {
          Radio.channel('navigate').trigger('show:int:cs:entity', entity.departmentId, entity.entityId);
        })
        .fail(function() {
          l.stop();
        });
    });
  },

  getFormData: function(entity, contactId) {
    var formData = this.contactForm.getFormData();
    formData.contactId = contactId;
    formData.entityId = entity.entityId;
    formData.departmentId = entity.departmentId;

    return formData;
  },

  validate: function() {
    return this.ui.contactContainer.parsley().whenValidate();
  }
});
