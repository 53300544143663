/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var _get = require('lodash.get');

module.exports = function(formData) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      SubmitExam: {
        $xmlns: 'http://dobs.pa.gov/sc/exam/bpm/1.0',
        ExamID: formData.examId,
        StatusID: formData.statusId,
        OpenedDate: formData.openedDate,
        ClosedDate: formData.closedDate,
        Description: formData.description,
        TypeID: formData.typeId,
        SubtypeID: formData.subtypeId,
        CategoryID: formData.categoryId,
        ConductedLocationID: formData.conductedLocationId,
        LocationForExam: formData.locationForExam,
        OfficeAssignedID: formData.officeAssignedId,
        SourceID: formData.sourceId,
        OtherViolation: formData.otherViolation,
        PrimaryExaminer: formData.primaryExaminer,
        IsDeleted: formData.isDeleted,
        BranchAddressID: formData.branchAddressId,
        BranchCRDNumber: formData.branchCRDNumber,
        PersonRequesting: formData.personRequesting,
        IsDeficienciesFound: formData.isDeficienciesFound,
        DeficienciesLetterSent: formData.deficienciesLetterSent,
        Violations: (function() {
          if (!_get(formData, 'violations.length')) {
            return {};
          }
          return _.map(formData.violations, function(violationId) {
            return {
              ViolationTypeID: violationId
            };
          });
        })()
      }
    }
  })
    .done(function() {
      deferred.resolve();
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
