/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var _get = require('lodash.get');

module.exports = function(formData) {
  var supportingDocuments = formData.supportingDocuments || [];
  var applicant = formData.applicant || {};
  var applicants = applicant.applicants || [];
  var pointOfContact = formData.pointOfContact || {};
  var lawyerExternalUserId = _get(formData, 'section112SubmissionLawyer.lawyerExternalUserId');
  var publicationReqsDoc = formData.publicationReqsDoc || {};

  var bioFinReportsDoc = formData.bioFinReportsDoc || {};
  var federalApplicationDoc = formData.federalApplicationDoc || {};
  var acquisitionRequestDocuments = federalApplicationDoc.acquisitionRequestDocuments || {};
  var pertinentDocuments = federalApplicationDoc.pertinentDocuments || {};
  var tenderOfferDocuments = federalApplicationDoc.tenderOfferDocuments || {};
  var fundDocuments = federalApplicationDoc.fundDocuments || {};
  var sharesRegistered = federalApplicationDoc.sharesRegistered || {};
  var purchasePrices = federalApplicationDoc.purchasePrices || {};
  var shareInfos = federalApplicationDoc.shareInfos || {};
  var acquirerPositions = federalApplicationDoc.acquirerPositions || {};

  var certification = formData.certification || {};

  return $.soap({
    body: {
      SubmitSection112Filing: {
        $xmlns: 'http://dobs.pa.gov/dp/filing/bpm/1.0',
        Section112ID: formData.section112Id,
        FilingID: formData.filingId,
        IsCertified: certification.isCertified ? '1' : '0',
        CertificationName: certification.certificationName,
        CertificationDocID: certification.certificationDocId,
        FilSection112: {
          ApplicantTypeID: applicant.applicantTypeId,
          AcquisitionTypeID: applicant.acquisitionTypeId,
          AcquiredEntityName: applicant.acquiredEntityName,
          PublicationReqsDocID: publicationReqsDoc.docId,
          PublicationReqsComments: publicationReqsDoc.pubReqcomments,
          BioFinReportsComments: bioFinReportsDoc.bioFincomments,
          HasFederalApplication: federalApplicationDoc.hasFederalApplication,
          FederalApplicationComments: federalApplicationDoc.fedAppComments,
          QuestionnaireADocID: federalApplicationDoc.questionnaireADocId,
          QuestionnaireAComments: federalApplicationDoc.questionaireAComments,
          QuestionnaireBDocID: federalApplicationDoc.questionnaireBDocId,
          QuestionnaireBComments: federalApplicationDoc.questionaireBComments,
          IsPriorNotice: federalApplicationDoc.isPriorNotice,
          NoPriorNoticeExplanation: federalApplicationDoc.noPriorNoticeExplanation,
          IsSubjectNotified: federalApplicationDoc.isSubjectNotified,
          ApplicationNoticeDocumentID: federalApplicationDoc.applicationNoticeDocumentId,
          NumberOfShares: federalApplicationDoc.numberOfShares,
          AllTransactions: federalApplicationDoc.allTransactions,
          RelatedPerson: federalApplicationDoc.relatedPerson,
          HasIntendedPlans: federalApplicationDoc.hasIntendedPlans,
          IntendedPlans: federalApplicationDoc.intendedPlans,
          VotingShares: federalApplicationDoc.votingShares,
          TotalNowOwned: federalApplicationDoc.totalNowOwned,
          TotalToBeOwned: federalApplicationDoc.totalToBeOwned,
          TotalAfterAcquisition: federalApplicationDoc.totalAfterAcquisition,
          TotalPercentNowOwned: federalApplicationDoc.totalPercentNowOwned,
          TotalPercentToBeOwned: federalApplicationDoc.totalPercentToBeOwned,
          TotalPercentAfterAcquisition: federalApplicationDoc.totalPercentAfterAcquisition,
          ValueInformation: federalApplicationDoc.valueInformation,
          AcquisitionProposal: federalApplicationDoc.acquisitionProposal,
          TotalPurchasePrice: federalApplicationDoc.totalPurchasePrice,
          TotalSourceAmount: federalApplicationDoc.totalSourceAmount,
          FundsInformation: federalApplicationDoc.fundsInformation,
          TotalShareNowHold: federalApplicationDoc.totalShareNowHold,
          TotalShareToBeSold: federalApplicationDoc.totalShareToBeSold,
          TotalShareAfterCompletion: federalApplicationDoc.totalShareAfterCompletion,
          TotalShareNowHoldPercent: federalApplicationDoc.totalShareNowHoldPercent,
          TotalShareToBeSoldPercent: federalApplicationDoc.totalShareToBeSoldPercent,
          TotalShareAfterCompletionPercent: federalApplicationDoc.totalShareAfterCompletionPercent,
          AssistantPerson: federalApplicationDoc.assistantPerson,
          TenderOffer: federalApplicationDoc.tenderOffer,
          RelatedOfficers: federalApplicationDoc.relatedOfficers,
          NameAndLocation: federalApplicationDoc.nameAndLocation,
          AssistantPersonDocumentID: federalApplicationDoc.assistantPersonDocumentId,
          AcquisitionRequestComments: federalApplicationDoc.acquisitionRequestComments,
          BioFinReportsDocuments: (function() {
            if (!bioFinReportsDoc.files.length) {
              return {};
            }
            return {
              Document: _.map(bioFinReportsDoc.files, function(bioFinReportsDocument) {
                return {
                  DocumentID: bioFinReportsDocument.fileId
                };
              })
            };
          })(),
          FederalApplicationDocuments: (function() {
            if (!federalApplicationDoc.files || !federalApplicationDoc.files.length) {
              return {};
            }
            return {
              Document: _.map(federalApplicationDoc.files, function(federalApplicationDocument) {
                return {
                  DocumentID: federalApplicationDocument.fileId
                };
              })
            };
          })(),
          AcquisitionRequestDocuments: (function() {
            if (!acquisitionRequestDocuments || !acquisitionRequestDocuments.length) {
              return {};
            }
            return {
              Document: _.map(acquisitionRequestDocuments, function(acquisitionRequestDocument) {
                return {
                  DocumentID: acquisitionRequestDocument.fileId
                };
              })
            };
          })(),
          PertinentDocuments: (function() {
            if (!pertinentDocuments || !pertinentDocuments.length) {
              return {};
            }
            return {
              Document: _.map(pertinentDocuments, function(pertinentDocument) {
                return {
                  DocumentID: pertinentDocument.fileId
                };
              })
            };
          })(),
          TenderOfferDocuments: (function() {
            if (!tenderOfferDocuments || !tenderOfferDocuments.length) {
              return {};
            }
            return {
              Document: _.map(tenderOfferDocuments, function(tenderOfferDocument) {
                return {
                  DocumentID: tenderOfferDocument.fileId
                };
              })
            };
          })(),
          FundDocuments: (function() {
            if (!fundDocuments || !fundDocuments.length) {
              return {};
            }
            return {
              Document: _.map(fundDocuments, function(fundDocument) {
                return {
                  DocumentID: fundDocument.fileId
                };
              })
            };
          })(),
          SharesRegistered: (function() {
            if (!sharesRegistered.length) {
              return {};
            }
            return _.map(sharesRegistered, function(shareRegistered) {
              return {
                ShareRegistered: {
                  Name: shareRegistered.name,
                  NowOwned: shareRegistered.nowOwned,
                  ToBeOwned: shareRegistered.toBeOwned,
                  AfterAcquisition: shareRegistered.afterAcquisition
                }
              };
            });
          })(),
          PurchasePrices: (function() {
            if (!purchasePrices.length) {
              return {};
            }
            return _.map(purchasePrices, function(purchasePrice) {
              return {
                PurchasePrice: {
                  Name: purchasePrice.name,
                  PurchasePrice: purchasePrice.purchasePrice,
                  SourceAmount: purchasePrice.sourceAmount
                }
              };
            });
          })(),
          ShareInfos: (function() {
            if (!shareInfos.length) {
              return {};
            }
            return _.map(shareInfos, function(shareInfo) {
              return {
                ShareInfo: {
                  Name: shareInfo.name,
                  ShareNowHold: shareInfo.shareNowHold,
                  ShareToBeSold: shareInfo.shareToBeSold,
                  ShareAfterCompletion: shareInfo.shareAfterCompletion
                }
              };
            });
          })(),
          AquirerPositions: (function() {
            if (!acquirerPositions.length) {
              return {};
            }
            return _.map(acquirerPositions, function(acquirerPosition) {
              return {
                AcquirerPosition: {
                  Name: acquirerPosition.name,
                  CompanyNameAddress: acquirerPosition.companyNameAddress,
                  PositionDateAppointed: acquirerPosition.positionDateAppointed,
                  OwnershipPercent: acquirerPosition.ownershipPercent
                }
              };
            });
          })()
        },
        Applicants: (function() {
          if (!applicants.length) {
            return {};
          }
          return _.map(applicants, function(app) {
            return {
              Applicant: {
                Name: app.name,
                Business: app.business,
                AddressLine1: app.addressLine1,
                AddressLine2: app.addressLine2,
                AddressLine3: app.addressLine3,
                County: app.county,
                City: app.city,
                State: app.state,
                PostalCode: app.postalCode,
                Country: app.country,
                Phone: app.phone,
                Email: app.email
              }
            };
          });
        })(),
        PointOfContact: {
          Name: pointOfContact.name,
          Phone: pointOfContact.phone,
          Email: pointOfContact.email,
          AddressLine1: pointOfContact.addressLine1,
          AddressLine2: pointOfContact.addressLine2,
          AddressLine3: pointOfContact.addressLine3,
          City: pointOfContact.city,
          State: pointOfContact.state,
          PostalCode: pointOfContact.postalCode,
          County: pointOfContact.county,
          Country: pointOfContact.country,
          Title: pointOfContact.title
        },
        LawyerExternalUserID: lawyerExternalUserId,
        SupportingDocuments: (function() {
          if (!supportingDocuments.length) {
            return {};
          }
          return {
            Document: _.map(supportingDocuments, function(supportingDocument) {
              return {
                DocumentID: supportingDocument.fileId
              };
            })
          };
        })()
      }
    }
  });
};
