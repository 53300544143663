/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var moment = require('moment');

module.exports = function(data) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      SubmitPortfolio: {
        $xmlns: 'http://dobs.pa.gov/cf/investment/bpm/1.0',
        PortfolioID: data.portfolioId,
        RecordID: data.recordId,
        PortfolioName: data.portfolioName,
        PortfolioDate: data.portfolioDate ? moment(data.portfolioDate, 'MM/DD/YYYY').format('YYYY-MM-DD') : null,
        IsDeleted: data.isDeleted === 1 ? 1 : 0,
        NewPortfolioClasses: (function() {
          if (_.isEmpty(data.newClasses)) {
            return {};
          } else {
            return {
              PortfolioClass: _.map(data.newClasses, function(PortfolioClass) {
                return {
                  ClassName: PortfolioClass.ClassName
                };
              })
            };
          }
        })(),
        ExistingPortfolioClasses: (function() {
          if (_.isEmpty(data.existingPortfolioClasses)) {
            return {};
          }
          return { PortfolioClassID: data.existingPortfolioClasses };
        })()
      }
    }
  })
    .done(function(response) {
      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject();
    });
  return deferred.promise();
};
