/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');

var GridView = require('psa-marionette/views/GridView');
var PageableCollection = require('psa-backbone/models/PageableCollection');

var ButtonLinkCell = require('common/backgrid/ButtonLinkCell');
var ListCell = require('common/backgrid/ListCell');

var tmplDepEntityContactsSectionView = require('../templates/DepEntityContactsSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepEntityContactsSectionView,

  className: 'dep-entity-contacts-section-view',

  ui: {
    contactGrid: '.contacts-list-container'
  },

  regions: {
    contactGrid: '@ui.contactGrid'
  },

  onBeforeShow: function() {
    var contact = this.model.get('contacts');

    this.ui.contactGrid.show();

    this.showChildView(
      'contactGrid',
      new GridView({
        columns: [
          {
            name: 'contactTypes',
            label: 'Type',
            cell: ListCell,
            headerCell: 'custom',
            width: 15,
            editable: false,
            sortable: false
          },
          {
            name: 'firstName',
            label: 'First Name',
            cell: 'string',
            headerCell: 'custom',
            width: 10,
            editable: false
          },
          {
            name: 'middleInitial',
            label: 'Middle Initial',
            cell: 'string',
            headerCell: 'custom',
            width: 5,
            editable: false
          },
          {
            name: 'lastName',
            label: 'Last Name',
            cell: 'string',
            headerCell: 'custom',
            width: 10,
            editable: false
          },
          {
            name: 'title',
            label: 'Title',
            cell: 'string',
            headerCell: 'custom',
            width: 10,
            editable: false
          },
          {
            name: 'phoneNumber',
            label: 'Phone Number',
            cell: 'string',
            headerCell: 'custom',
            width: 10,
            editable: false
          },
          {
            name: 'additionalNumbers',
            label: 'Additional Numbers',
            cell: ListCell,
            headerCell: 'custom',
            width: 10,
            editable: false
          },
          {
            name: 'emailAdresses',
            label: 'Email Addresses',
            cell: ListCell,
            headerCell: 'custom',
            width: 20,
            editable: false,
            sortable: false
          },
          {
            name: '',
            label: '',
            cell: ButtonLinkCell.extend({
              name: 'Open',
              buttonClass: 'btn-primary btn-sm',
              href: function(model) {
                return '#dep/entity/' + model.get('entityId') + '/contact/' + model.get('contactId') + '/dashboard';
              }
            }),
            headerCell: 'custom',
            width: 5,
            editable: false,
            sortable: false
          }
        ],
        emptyText: 'No Contacts',
        collection: new PageableCollection(contact, { pageSize: 25 })
      })
    );
  }
});
