/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Backgrid = require('backgrid');

require('./ButtonCell.css');
var tmplButtonCell = require('./ButtonCell.hbs');

module.exports = Backgrid.ButtonCell = Backgrid.Cell.extend({
  className: 'button-cell td-grid-cell-btn',

  events: {
    'click button': 'onClickOfButton'
  },

  render: function() {
    var buttonClass = 'btn-primary btn-xs';
    if (this.buttonClass) {
      buttonClass = Backgrid.callByNeed(this.buttonClass, this, this.model);
    }
    this.$el.addClass(this.column.get('name'));
    this.$el.empty().append(
      tmplButtonCell({
        name: this.name,
        buttonClass: buttonClass,
        labelClass: this.labelClass
      })
    );

    if (this.buttonAttrs) {
      this.$el.find('button').attr(this.buttonAttrs);
    }

    this.delegateEvents();
    return this;
  },

  onClickOfButton: function(e) {
    e.preventDefault();
    if (this.onClick && _.isFunction(this.onClick)) {
      this.onClick(e, this.model);
    }
  }
});
