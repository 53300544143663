/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var modelGet = require('common/util/modelGet');
var toggleForm = require('common/util/toggleForm');
var AddressView = require('common/views/AddressView');
var SelectFieldView = require('common/views/SelectFieldView');

var tmplDepLocationFormView = require('../templates/DepLocationFormView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepLocationFormView,

  className: 'dep-location-form-view',

  ui: {
    closedDate: '.closed-Date',
    openedDate: '.opened-Date',
    address: '.address',
    officeTypeDropDown: '.office-type-select',
    activityTypeSelect: '.activity-type-select',
    activityType: '.activity-type',
    locationStatusSelect: '.location-status-select',
    relocatedTo: '.relocated-to'
  },

  regions: {
    address: '@ui.address',
    activityTypeSelect: '@ui.activityTypeSelect',
    officeTypeDropDown: '@ui.officeTypeDropDown'
  },

  events: {
    'change @ui.officeTypeDropDown': 'onChangeCategory',
    'change @ui.locationStatusSelect': 'onChangeStatus'
  },

  onBeforeShow: function() {
    var location = this.model.get('location') || {};

    var entityIdentifiers = this.model.get('entityIdentifiers') || {};

    this.activityTypeSelect = new SelectFieldView({
      isDisabled: false,
      isRequired: false,
      name: 'activityId',
      collection: new Backbone.Collection([])
    });

    this.showChildView('activityTypeSelect', this.activityTypeSelect);

    toggleForm(this.ui.activityType, entityIdentifiers.categoryId === '1');

    this.activityTypeSelect.collection.reset(
      _.map(this.model.get('locationActivityType'), function(location) {
        return { value: location.activityId, text: location.activity };
      })
    );

    this.showChildView(
      'address',
      new AddressView({
        horizontal: true,
        isRequired: false,
        startingCountry: modelGet(this.model, 'location.country'),
        startingPostalCode: modelGet(this.model, 'location.postalCode'),
        startingCounty: modelGet(this.model, 'location.county')
      })
    );

    Syphon.deserialize(this, location);

    this.ui.closedDate.datepicker({
      startView: 'days'
    });

    this.ui.openedDate.datepicker({
      startView: 'days'
    });

    this.setRelocatedToVisible(this.ui.locationStatusSelect.val());

    var activityTypes = this.setActivityTypes(location.officeTypeId);
    this.activityTypeSelect.collection.reset(activityTypes);

    this.activityTypeSelect.setValue(location.activityId);
  },

  onChangeCategory: function(e) {
    e.preventDefault();
    var types = this.setActivityTypes(this.ui.officeTypeDropDown.val());
    this.activityTypeSelect.collection.reset(types);
  },

  onChangeStatus: function(e) {
    e.preventDefault();
    this.setRelocatedToVisible(this.ui.locationStatusSelect.val());
  },

  setRelocatedToVisible: function() {
    var isRelocated = this.ui.locationStatusSelect.val() === '7';
    toggleForm(this.ui.relocatedTo, isRelocated);
  },

  setActivityTypes: function(officeTypeId) {
    var allLocations = this.model.get('locationActivityType');

    return _.chain(allLocations)
      .where({ officeTypeId: officeTypeId })
      .map(function(activityTypeSelect) {
        return { value: activityTypeSelect.activityId, text: activityTypeSelect.activity };
      })
      .value();
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);

    formData.entityId = this.model.get('entityId');
    formData.locationId = this.model.get('locationId');
    return formData;
  }
});
