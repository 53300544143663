/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');

var PageableCollection = require('psa-backbone/models/PageableCollection');
var GridView = require('psa-marionette/views/GridView');
var FileUploadCell = require('common/backgrid/FileUploadCell');
var MomentCell = require('common/backgrid/MomentCell');

var tmplNonDepLicenseCertificateHistoryView = require('../templates/NonDepLicenseCertificateHistoryView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepLicenseCertificateHistoryView,

  className: 'license-certificate-history-view',

  ui: {
    certificateHistoryContainer: '.certificate-history-container'
  },

  regions: {
    certificateHistoryContainer: '@ui.certificateHistoryContainer'
  },

  onBeforeShow: function() {
    var certificateHistoryGrid = new GridView({
      columns: [
        {
          name: 'file',
          label: 'File',
          cell: FileUploadCell.extend({
            allowMultipleFiles: false,
            isReadOnly: true
          }),
          headerCell: 'custom',
          editable: false,
          sortable: false,
          width: 50
        },
        {
          name: 'effectiveStartDate',
          label: 'Effective Start Date',
          cell: MomentCell.MomentCell,
          formatter: MomentCell.MomentDateFormatter.extend({
            emptyValue: '--'
          }),
          sortValue: MomentCell.MomentSortValue,
          headerCell: 'custom',
          editable: false,
          width: 25
        },
        {
          name: 'effectiveEndDate',
          label: 'Effective End Date',
          cell: MomentCell.MomentCell,
          formatter: MomentCell.MomentDateFormatter.extend({
            emptyValue: '--'
          }),
          sortValue: MomentCell.MomentSortValue,
          headerCell: 'custom',
          editable: false,
          width: 25
        }
      ],
      collection: new PageableCollection(this.model.get('licenseCertificateHistory', { pageSize: 10 })),
      emptyText: 'No Certificate Status History'
    });
    this.showChildView('certificateHistoryContainer', certificateHistoryGrid);
  }
});
