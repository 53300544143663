/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Humanize = require('humanize-plus');

var showErrorMessage = require('../util/showErrorMessage');

var tmplFileUpload = require('./FileUploadTmpl.hbs');
var tmplFileDownload = require('./FileDownloadTmpl.hbs');

var MAX_FILE_SIZE = 314572800; // 300 MB

module.exports = {
  pathToFileId: 'html.head.script.Envelope.Body.UploadDocumentResponse.FileID.__text',
  downloadXml: tmplFileDownload,
  uploadXml: tmplFileUpload,
  timeout: 3000000,
  sizeLimit: MAX_FILE_SIZE,
  searchPath: '/FilePath',
  failedAdd: function(data) {
    // Handle showing error message for file too large error.
    var fileSizeHuman = Humanize.fileSize(data.file.size);
    var maxFileSizeHuman = Humanize.fileSize(MAX_FILE_SIZE);

    showErrorMessage({
      errorCode: 'max.file-size.exceeded',
      errorTitle: 'File Too Large',
      errorMessage:
        'The file selected, which has size ' +
        fileSizeHuman +
        ', exceeds the maximum file size of ' +
        maxFileSizeHuman +
        '.'
    });
  }
};
