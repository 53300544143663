/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var modelGet = require('common/util/modelGet');
var toggleForm = require('common/util/toggleForm');
var AddressView = require('common/views/AddressView');

var tmplDepSEGFormView = require('../templates/DepSEGFormView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepSEGFormView,

  className: 'dep-seg-form-view',

  ui: {
    approvalDate: '.approval-date',
    categoryDropdown: 'select[name="categoryId"]',
    otherCategoryArea: '.otherCategoryArea',
    terminationDate: '.termination-date',
    address: '.address',
    individualTypes: '.individualTypes'
  },

  regions: {
    address: '@ui.address'
  },

  events: {
    'click @ui.categoryDropdown': 'onChangeCategory'
  },

  onBeforeShow: function() {
    var categoryId = modelGet(this.model, 'seg.categoryId');
    toggleForm(this.ui.otherCategoryArea, categoryId === '30');

    this.showChildView(
      'address',
      new AddressView({
        horizontal: true,
        isRequired: false,
        startingCountry: modelGet(this.model, 'seg.country'),
        startingPostalCode: modelGet(this.model, 'seg.postalCode'),
        startingCounty: modelGet(this.model, 'seg.county')
      })
    );

    Syphon.deserialize(this, this.model.get('seg'));

    this.ui.approvalDate.datepicker({
      startView: 'days'
    });

    this.ui.terminationDate.datepicker({
      startView: 'days'
    });
  },

  onChangeCategory: function(e) {
    var formData = Syphon.serialize(this);
    toggleForm(this.ui.otherCategoryArea, formData.categoryId === '30');
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    var checkedBoxes = this.ui.individualTypes.filter(':checked');
    formData.individualTypes = _.map(checkedBoxes, function(checkedBox) {
      return checkedBox.value;
    });
    formData.entityId = this.model.get('entityId');
    formData.segId = this.model.get('segId');
    return formData;
  }
});
