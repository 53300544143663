/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var GridView = require('psa-marionette/views/GridView');
var PageableCollection = require('psa-backbone/models/PageableCollection');

require('../styles/NonDepInternalComplianceClosedView.css');
var tmplNonDepInternalComplianceClosedView = require('../templates/NonDepInternalComplianceClosedView.hbs');

var NonDepInternalSidebarNavView = require('../../common/views/NonDepInternalSidebarNavView');
var BreadcrumbView = require('common/views/BreadcrumbView');
var ButtonLinkCell = require('common/backgrid/ButtonLinkCell');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepInternalComplianceClosedView,

  className: 'non-dep-compliance-closed-view container-fluid',

  ui: {
    sideBar: '.side-bar-container',
    closedCases: '.closed-cases-container',
    breadcrumb: '.breadcrumb-container'
  },

  regions: {
    sideBar: '@ui.sideBar',
    closedCases: '@ui.closedCases',
    breadcrumb: '@ui.breadcrumb'
  },

  onBeforeShow: function() {
    this.showChildView('sideBar', new NonDepInternalSidebarNavView());
    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#nondep/dashboard',
            text: 'Home'
          },
          {
            link: '#nondep/compliance/dashboard',
            text: 'Compliance Dashboard'
          },
          {
            text: 'Closed Compliance Cases'
          }
        ]
      })
    );

    var closedCasesGrid = new GridView({
      columns: [
        {
          name: 'caseNumber',
          label: 'Case Number',
          cell: 'string',
          headerCell: 'custom',
          width: 12,
          editable: false
        },
        {
          name: 'entityName',
          label: 'Entity Name',
          cell: 'string',
          headerCell: 'custom',
          width: 20,
          editable: false
        },
        {
          name: 'licenseNumber',
          label: 'License Number',
          cell: 'string',
          headerCell: 'custom',
          width: 13,
          editable: false
        },
        {
          name: 'licenseCode',
          label: 'License Type',
          cell: 'string',
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'administrator',
          label: 'Administrator',
          cell: 'string',
          headerCell: 'custom',
          width: 15,
          editable: false
        },
        {
          name: 'complianceStatus',
          label: 'Status',
          cell: 'string',
          headerCell: 'custom',
          width: 20,
          editable: false
        },
        {
          name: '',
          label: '',
          cell: ButtonLinkCell.extend({
            name: 'Open',
            buttonClass: 'btn-primary btn-sm',
            href: function(model) {
              return '#nondep/compliance/case/' + model.get('id');
            }
          }),
          headerCell: 'custom',
          width: 10,
          editable: false,
          sortable: false
        }
      ],

      collection: new PageableCollection(this.model.get('complianceCases'), { pageSize: 25 }),
      emptyText: 'No Closed Cases'
    });
    this.showChildView('closedCases', closedCasesGrid);
  }
});
