/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');
var Backbone = require('backbone');
var Syphon = require('backbone.syphon');
var Radio = require('backbone.radio');
var Ladda = require('ladda');

var modelGet = require('common/util/modelGet');
var toggleForm = require('common/util/toggleForm');

var HeaderBarView = require('common/views/HeaderBarView');
var BreadcrumbView = require('common/views/BreadcrumbView');
var FileUploadView = require('common/views/FileUploadView');
var HeaderBarAddressCellView = require('common/views/HeaderBarView/HeaderBarAddressCellView');
var FileUploadCollection = require('psa-backbone/models/FileUploadCollection');

var SubmitFinalizeExamFindingsTask = require('../../services/SubmitFinalizeExamFindingsTask');

var tmplDepReviewAndFinalizeExamFindingsView = require('./DepReviewAndFinalizeExamFindingsView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepReviewAndFinalizeExamFindingsView,

  className: 'dep-review-and-finalize-exam-findings-view container-fluid',

  ui: {
    headerBar: '.header-bar',
    breadcrumb: '.breadcrumb-container',
    reviewExamForm: '.review-exam-form',
    actionId: '.action-id',
    examDocsFormContainer: '.exam-docs-form-container',
    entityFileUploadContainer: '.entity-file-upload-container',
    fileUploadContainer: '.file-upload-container',
    notifyEntityFormContainer: '.notify-entity-form-container',
    isNotifyEntityRadio: 'input[name="isNotifyEntity"]',
    submit: '.submit'
  },

  regions: {
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb',
    entityFileUploadContainer: '@ui.entityFileUploadContainer',
    fileUploadContainer: '@ui.fileUploadContainer'
  },

  behaviors: {
    TaskBehavior: {},
    RadioGroupToggleBehavior: {
      radios: [
        {
          target: '.exam-docs-form-container',
          radioGroup: 'input[name="isNotifyEntity"]',
          valueToShow: '1'
        }
      ]
    }
  },

  events: {
    'change @ui.actionId': 'onChangeActionId',
    'click @ui.submit': 'onClickSubmit'
  },

  templateHelpers: function() {
    var isHoldingCompanyExam = modelGet(this.model, 'examDetails.isHoldingCompanyExam') === '1';
    var noExtDocuments = modelGet(this.model, 'extDocuments').length < 1;
    return {
      isHoldingCompanyExam: isHoldingCompanyExam,
      noExtDocuments: noExtDocuments
    };
  },

  onBeforeRender: function() {
    var examActions = _.filter(this.model.get('examActions'), function(action) {
      return _.includes(['4', '5'], action.id);
    });
    this.model.set('examActions', examActions);
  },

  onBeforeShow: function() {
    var examDetails = this.model.get('examDetails') || {};
    var ppobAddress = examDetails.ppobAddress || {};
    var intDocuments = this.model.get('intDocuments');
    var extDocuments = _.filter(this.model.get('extDocuments'), function(document) {
      return document.isCurrent === '1';
    });

    var headerBarData = {
      filingType: examDetails.filingType,
      entityName: examDetails.entityName,
      entityType: examDetails.entityType,
      address: {
        addressLine1: ppobAddress.addressLine1,
        addressLine2: ppobAddress.addressLine2,
        city: ppobAddress.city,
        state: ppobAddress.state,
        postalCode: ppobAddress.postalCode,
        country: ppobAddress.country,
        county: ppobAddress.county
      }
    };

    var headerBarColumns = [
      {
        name: 'entityName',
        label: 'Entity Name',
        minWidth: '160px',
        maxWidth: '300px'
      },
      {
        name: 'entityType',
        label: 'Entity Type',
        minWidth: '160px',
        maxWidth: '300px'
      },
      {
        name: 'address',
        label: 'Principal Place of Business Address',
        minWidth: '160px',
        headerCell: HeaderBarAddressCellView
      }
    ];

    this.showChildView(
      'headerBar',
      new HeaderBarView({
        columns: new Backbone.Collection(headerBarColumns),
        model: new Backbone.Model(headerBarData)
      })
    );

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#dep/dashboard',
            text: 'Home'
          },
          {
            text: 'Review And Finalize Exam Findings'
          }
        ]
      })
    );
    this.prevFileUploadView = new FileUploadView({
      collection: new FileUploadCollection(intDocuments),
      emptyText: 'No Exam Document(s)',
      isReadOnly: true
    });
    // this.showChildView('prevFileUploadContainer', this.prevFileUploadView);

    this.entityFileUploadView = new FileUploadView({
      collection: new FileUploadCollection(extDocuments),
      emptyText: 'No Exam Response(s)',
      isReadOnly: true
    });
    this.showChildView('entityFileUploadContainer', this.entityFileUploadView);

    this.fileUploadView = new FileUploadView({
      collection: new Backbone.Collection([]),
      isRequired: true,
      isReadOnly: false,
      allowMultipleFiles: true
    });
    this.showChildView('fileUploadContainer', this.fileUploadView);

    this.onChangeActionId();
  },

  onChangeActionId: function() {
    var actionId = this.ui.actionId.val();
    var isSendForRes = actionId === '4';
    var isSendAndClose = actionId === '5';
    var isNotifyEntity = this.ui.isNotifyEntityRadio.filter(':checked').val() === '1';

    toggleForm(this.ui.examDocsFormContainer, isSendForRes || (isSendAndClose && isNotifyEntity));
    toggleForm(this.ui.notifyEntityFormContainer, isSendAndClose);
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;
    this.validate().done(function() {
      var l = Ladda.create(self.ui.submit[0]);
      l.start();
      SubmitFinalizeExamFindingsTask(self.getFormData())
        .done(function() {
          Radio.channel('navigate').trigger('show:int:dp:dashboard');
        })
        .fail(function() {
          l.stop();
        });
    });
  },

  validate: function() {
    this.fileUploadView.removeValidationErrors();
    var validationArray = [this.ui.reviewExamForm.parsley().whenValidate()];

    var actionId = this.ui.actionId.val();
    var isSendForRes = actionId === '4';
    var isSendAndClose = actionId === '5';
    var isNotifyEntity = this.ui.isNotifyEntityRadio.filter(':checked').val() === '1';

    if (isSendForRes || (isSendAndClose && isNotifyEntity)) {
      validationArray.push(this.fileUploadView.validate({ type: 'submit' }));
    }

    return $.when.apply($, validationArray);
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);

    _.extend(formData, {
      taskId: this.model.get('taskId'),
      examId: this.model.get('examId')
    });

    var actionId = formData.actionId;
    var isSendForRes = actionId === '4';
    var isSendAndClose = actionId === '5';

    if (isSendForRes || (isSendAndClose && formData.isNotifyEntity === '1')) {
      formData.documents = this.fileUploadView.collection.toJSON();
    }

    return formData;
  }
});
