/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Radio = require('backbone.radio');
var Ladda = require('ladda');
var moment = require('moment');
var UIUtil = require('psa-core/util/ui');
var modelGet = require('common/util/modelGet');
var ConfirmationModalView = require('common/views/ConfirmationModalView');
var BreadcrumbView = require('common/views/BreadcrumbView');
var InvoiceGridView = require('common/views/InvoiceGridView');
var ContentServerLinkView = require('common/views/ContentServerLinkView');
var ExamHeaderBarView = require('../../../common/views/ExamHeaderBarView');
var PaymentScheduleGridView = require('../../../common/views/PaymentScheduleGridView');

var SubmitExamMTReviewBillingTask = require('../../services/SubmitExamMTReviewBillingTask');

require('./ExamMTReviewBillingTaskView.css');
var tmplExamMTReviewBillingTaskView = require('./ExamMTReviewBillingTaskView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplExamMTReviewBillingTaskView,

  className: 'exam-mt-review-billing-task-view container-fluid',

  ui: {
    headerBar: '.header-bar',
    csLinkContainer: '.cs-link-container',
    breadcrumb: '.breadcrumb-container',
    billingTaskForm: '.billing-task-form',
    billingDetailsContainer: '.billing-details-container',
    paymentAmount: 'input[name="totalPaymentDue"]',
    paymentGridContainer: '.payment-plan-container',
    paymentAmountErrorAlert: '.payment-amount-error',
    submit: '.submit',
    isCloseWoutBilling: 'input[name="isCloseWoutBilling"]'
  },

  regions: {
    headerBar: '@ui.headerBar',
    csLinkContainer: '@ui.csLinkContainer',
    breadcrumb: '@ui.breadcrumb',
    billingDetailsContainer: '@ui.billingDetailsContainer',
    paymentGridContainer: '@ui.paymentGridContainer'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  behaviors: {
    TaskBehavior: {},
    RadioGroupToggleBehavior: {
      radios: [
        {
          target: '.billing',
          radioGroup: 'input[name="isCloseWoutBilling"]',
          valueToShow: '0'
        }
      ]
    }
  },

  onBeforeShow: function() {
    var exam = this.model.get('exam') || {};

    this.showChildView(
      'headerBar',
      new ExamHeaderBarView({
        model: new Backbone.Model(this.model.get('exam'))
      })
    );

    this.showChildView(
      'csLinkContainer',
      new ContentServerLinkView({
        model: new Backbone.Model({
          buttonText: 'Open Exam Folder',
          contentServerId: exam.CSFolderId
        })
      })
    );

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#nondep/dashboard',
            text: 'Home'
          },
          {
            text: modelGet(this.model, 'task.taskType.description')
          }
        ]
      })
    );

    this.invoiceGrid = new InvoiceGridView({
      emptyText: 'No Exam Billing Items Found',
      collection: new Backbone.Collection(this.model.get('examInvoiceItems'))
    });
    this.showChildView('billingDetailsContainer', this.invoiceGrid);
    var amount = this.invoiceGrid.ui.amount[0].innerText;
    this.$('.total-payment-due').val(amount);

    this.paymentGrid = new PaymentScheduleGridView({
      emptyText: 'No Payment(s) Scheduled',
      collection: new Backbone.Collection(this.model.get('payment')),
      isReadOnly: false,
      mode: 'task',
      defaultDueDate: moment().add(30, 'days')
    });
    this.showChildView('paymentGridContainer', this.paymentGrid);
  },

  validatePaymentGrid: function() {
    var defer = $.Deferred();
    this.ui.paymentAmountErrorAlert.hide();

    var payments = this.paymentGrid.collection.models;
    var totalPayments = _.reduce(
      payments,
      function(total, payment) {
        return total + parseFloat(modelGet(payment, 'amount', 0));
      },
      0
    );
    if (totalPayments === parseFloat(this.ui.paymentAmount.val())) {
      defer.resolve();
    } else {
      this.ui.paymentAmountErrorAlert.show();
      defer.reject();
    }

    return defer.promise();
  },

  validate: function() {
    return $.when(this.ui.billingTaskForm.parsley().whenValidate(), this.validatePaymentGrid());
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;
    this.validate().done(function() {
      var l = Ladda.create(self.ui.submit[0]);
      l.start();
      var formData = Syphon.serialize(self);

      formData.paymentSchedule = _.map(self.paymentGrid.collection.toJSON(), function(payment) {
        return payment;
      });

      _.extend(formData, {
        taskId: self.model.get('taskId'),
        examId: self.model.get('examId')
      });
      
      if (formData.isCloseWoutBilling === '1') {
        var message = 'Are you sure you would like to close this exam without billing? This action cannot be undone.';
        UIUtil.showModal({
          title: 'Confirm Marking Exam as Closed',
          view: new ConfirmationModalView({
            confirmText: 'Mark Exam as Closed',
            model: new Backbone.Model({
              message: message,
              onConfirm: function() {
                return SubmitExamMTReviewBillingTask(formData)
                  .done(function() {
                    Radio.channel('navigate').trigger('show:int:nd:dashboard');
                  })
                  .fail(function() {
                    l.stop();
                  });
              }
            })
          })
        });
      }
        else {
        SubmitExamMTReviewBillingTask(formData)
          .done(function() {
            Radio.channel('navigate').trigger('show:int:nd:dashboard');
          })
          .fail(function() {
            l.stop();
          });
      }
    });
  }
});
