var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return "\"#ext/nondep/entity/"
    + container.escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"id","hash":{},"data":data}) : helper)))
    + "/dashboard\"";
},"3":function(container,depth0,helpers,partials,data) {
    return "\"#ext/nondep/dashboard\"";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "\r\n<form class=\"entity-affirmation-form\">\r\n  <div class=\"form-group required\">\r\n    <label class=\"control-label normal-font-weight\" id=\"EntityAffirmationCheckBoxLabel\">\r\n      <input type=\"checkbox\" name=\"isAffirmed\" value=\"1\" id=\"EntityAffirmationCheckBox\" data-parsley-required=\"true\" />\r\n      I understand by submitting this form, I am agreeing to be bound by the following declaration</label>\r\n  </div>\r\n\r\n  <p><strong>\"I declare that all of my answers on this form are complete, true and correct. I make this declaration\r\n      subject to the penalties of 18 PA.C.S. § 4904 relating to unsworn falsification to authorities.\"</strong></p>\r\n\r\n  <div class=\"alert alert-danger collapse validation-errors\" role=\"alert\">\r\n    <i class=\"fa fa-exclamation-triangle\" aria-hidden=\"true\"></i>\r\n    Validation errors where found while attempting to submit the form. Please fix these issues and re-submit.\r\n  </div>\r\n\r\n  <p>\r\n    <a href="
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isExtEdit : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + " class=\"btn btn-default btn-md\" role=\"button\">Cancel</a>\r\n    <button class=\"btn btn-primary btn-md submit-new-entity ladda-button\" data-style=\"expand-right\" type=\"button\">\r\n      <span class=\"ladda-label\">Submit</span>\r\n    </button>\r\n  </p>\r\n</form>";
},"useData":true});