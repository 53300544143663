var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "          <option value="
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + ">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</option>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "\r\n<div class=\"modal-body\">\r\n  <div class=\"row\">\r\n    <div class=\"col-sm-3\">\r\n      <div class=\"form-group\">\r\n        <label>License Type</label>\r\n         <select name=\"licenseTypeId\" class=\"form-control\">\r\n          <option value=\"\" selected>--</option>\r\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.licenseTypes : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </select>\r\n      </div>\r\n    </div>\r\n    <div class=\"col-sm-3\">\r\n      <div class=\"form-group\">\r\n        <label>License Number</label>\r\n        <input type=\"text\" class=\"form-control\" name=\"licenseNumber\" placeholder=\"License Number\">\r\n      </div>\r\n    </div>\r\n    <div class=\"col-sm-3\">\r\n      <div class=\"form-group\">\r\n        <label>Entity Name</label>\r\n        <input type=\"text\" class=\"form-control\" name=\"entityName\" placeholder=\"Entity Name\">\r\n      </div>\r\n    </div>\r\n    <div class=\"col-sm-3\">\r\n      <button class=\"btn btn-primary search\">Search</button>\r\n    </div>\r\n  </div>\r\n  <div class=\"search-result-summary collapse\">Found <span class=\"hit-count\"></span> results.</div>\r\n  <div class=\"results-grid\"></div>\r\n  <div class=\"search-result-max alert alert-inline alert-warning collapse\">More results match the search criteria than\r\n    shown. Please refine your search criteria.\r\n    <span class=\"fa fa-question-circle fa-lg refine-seach-tip\" data-toggle=\"tooltip\" data-placement=\"bottom\"\r\n      title=\"Only the top "
    + container.escapeExpression(((helper = (helper = helpers.searchLimit || (depth0 != null ? depth0.searchLimit : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"searchLimit","hash":{},"data":data}) : helper)))
    + " results, ordered by the strength of the match, are returned by this search. Provide additional criteria to help refine the search.\"></span>\r\n  </div>\r\n</div>\r\n<div class=\"modal-footer\">\r\n  <button class=\"btn btn-default\" data-dismiss=\"modal\">Cancel</button>\r\n  <button class=\"btn btn-primary btn-submit ladda-button\" data-style=\"expand-right\" type=\"button\">\r\n    <span class=\"ladda-label\">Select</span>\r\n  </button>\r\n</div>";
},"useData":true});