/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');

var toggleForm = require('common/util/toggleForm');

var AddressDisplayView = require('common/views/AddressDisplayView');

var tmplSecExamSectionGeneralView = require('../templates/SecExamSectionGeneralView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplSecExamSectionGeneralView,

  className: 'sec-exam-section-general-view',

  ui: {
    branchSection: '.branch-section',
    branchAddressContainer: '.branch-address-container'
  },

  regions: {
    branchAddressContainer: '@ui.branchAddressContainer'
  },

  onBeforeShow: function() {
    this.showChildView('branchAddressContainer', new AddressDisplayView({ model: this.model }));

    // Show branch section when exam subType is 'Branch'
    toggleForm(this.ui.branchSection, this.model.get('subtypeId') === '2');
  }
});
