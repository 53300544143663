/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var cleanUpResponse = require('common/util/cleanUpResponse');
var buildExternalUserDisplay = require('../util/buildExternalUserDisplay');

module.exports = function(filingId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetFilingDetails: {
        $xmlns: 'http://dobs.pa.gov/dp/filing/bpm/1.0',
        FilingID: filingId
      }
    },
    blueprintArrays: ['GetFilingDetailsResponse.InstructionsDocuments.InstructionsDocument'],
    blueprint: {
      filingId: '$..ID',
      filingTypeId: '$..FilingTypeID',
      filingType: '$..FilingTypeName',
      recordStatusId: '$..RecordStatusID',
      recordStatus: '$..RecordStatus',
      instructions: '$..Instructions',
      instructionsDocument: {
        fileId: '$..InstructionsDocID',
        fileName: '$..InstructionsDocName'
      },
      filingTypeEntityCategoryId: '$..FilingTypeEntityCategoryID',
      formIdentifier: '$..FormIdentifier',
      filingFee: '$..FilingFee',
      certificationTemplateDocument: {
        fileId: '$..CertificationTemplateDocID',
        fileName: '$..CertificationTemplateDocName'
      },
      certificationDocument: {
        fileId: '$..CertificationDocID',
        fileName: '$..CertificationDocName'
      },
      acknowledgementLetterDocument: {
        fileId: '$..AcknowledgementLetterID',
        fileName: '$..AcknowledgementLetterName'
      },
      entityId: '$..EntityID',
      entityName: '$..EntityName',
      entityTypeId: '$..EntityTypeID',
      entityType: '$..EntityType',
      entityCategoryId: '$..EntityCategoryID',
      entityCategory: '$..EntityCategory',
      isCIF: '$..IsCIF',
      isCertified: '$..IsCertified',
      certificationName: '$..CertificationName',
      reworkComments: '$..ReworkComments',
      pointOfContact: {
        pointOfContactId: '$..PointOfContactID',
        name: '$..PointOfContactName',
        title: '$..PointOfContactTitle',
        business: '$..PointOfContactBusiness',
        phone: '$..PointOfContactPhone',
        email: '$..PointOfContactEmail',
        addressId: '$..PointOfContactAddressID',
        addressLine1: '$..PointOfContactAddressLine1',
        addressLine2: '$..PointOfContactAddressLine2',
        addressLine3: '$..PointOfContactAddressLine3',
        city: '$..PointOfContactCity',
        state: '$..PointOfContactState',
        postalCode: '$..PointOfContactPostalCode',
        country: '$..PointOfContactCountry',
        county: '$..PointOfContactCounty'
      },
      ppobAddress: {
        addressId: '$..PPOBAddressID',
        addressLine1: '$..PPOBAddressLine1',
        addressLine2: '$..PPOBAddressLine2',
        addressLine3: '$..PPOBAddressLine3',
        city: '$..PPOBCity',
        state: '$..PPOBState',
        postalCode: '$..PPOBPostalCode',
        country: '$..PPOBCountry',
        county: '$..PPOBCounty'
      },
      instructionsDocuments: [
        '$..InstructionsDocument',
        {
          csDocumentId: '$..CSDocumentID',
          fileName: '$..Name'
        }
      ],
      lawyer: {
        externalUserId: '$..LawyerExternalUserID', // int example: 2003
        firstName: '$..LawyerFirstName',
        lastName: '$..LawyerLastName',
        userId: '$..LawyerUserID' // string example: 'jdoe'
      },
      withdraw: {
        withdrawReasonExt: '$..WithdrawalReasonExt',
        file: {
          fileId: '$..WithdrawalReasonDocID',
          fileName: '$..WithdrawalReasonDocName'
        }
      }
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      response.filingFee = parseFloat(response.filingFee);
      response.isCertified = response.isCertified === '1';

      if (response.instructionsDocument.fileName) {
        response.instructionsDocument.fileLabel = response.instructionsDocument.fileName.split('.', 1)[0];
      }

      var lawyer = response.lawyer;
      response.lawyer.displayName = buildExternalUserDisplay(lawyer.userId, lawyer.firstName, lawyer.lastName);
      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject(err);
    });

  return deferred.promise();
};
