/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var momentUtil = require('common/util/momentUtil');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(auditId) {
  var defer = $.Deferred();

  $.soap({
    body: {
      GetTrustFundAudit: {
        $xmlns: 'http://dobs.pa.gov/dp/fund/bpm/1.0',
        ID: auditId
      }
    },
    blueprint: {
      id: '$..ID',
      entityId: '$..EntityID',
      entityName: '$..EntityName',
      fundId: '$..TrustFundID',
      fundName: '$..TrustFundName',
      auditDate: '$..AuditDate',
      receivedDate: '$..ReceivedDate',
      auditTrustTypeId: '$..AuditTrustTypeID',
      auditTrustType: '$..AuditTrustType',
      opinionId: '$..OpinionID',
      opinion: '$..Opinion',
      opinionDetails: '$..OpinionDetails',
      auditFirmId: '$..AuditFirmID',
      auditFirm: '$..AuditFirm',
      internalAuditFirmId: '$..InternalAuditFirmID',
      internalAuditFirm: '$..InternalAuditFirm',
      approvedDate: '$..ApprovedDate',
      lastRevisedDate: '$..LastRevisedDate',
      letterReceivedDate: '$..LetterReceivedDate',
      responseReceivedDate: '$..ResponseReceivedDate',
      comments: '$..Comments',
      cSFolderId: '$..CSFolderID'
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      var formatCfg = _.extend({}, momentUtil.defaultInboundDateConfig, { default: undefined });

      response.auditDate = momentUtil.formatStr(response.auditDate, formatCfg);
      response.receivedDate = momentUtil.formatStr(response.receivedDate, formatCfg);
      response.approvedDate = momentUtil.formatStr(response.approvedDate, formatCfg);
      response.lastRevisedDate = momentUtil.formatStr(response.lastRevisedDate, formatCfg);
      response.letterReceivedDate = momentUtil.formatStr(response.letterReceivedDate, formatCfg);
      response.responseReceivedDate = momentUtil.formatStr(response.responseReceivedDate, formatCfg);

      defer.resolve(response);
    })
    .fail(function(err) {
      defer.reject(err);
    });

  return defer.promise();
};
