/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Ladda = require('ladda');

var PageableCollection = require('psa-backbone/models/PageableCollection');
var GridView = require('psa-marionette/views/GridView');

var AddressCell = require('common/backgrid/AddressCell');
var SelectOneCell = require('common/backgrid/SelectOneCell');

var GetEntityByEntityNameAndCategory = require('../services/GetEntityByEntityNameAndCategory');

var tmplDepEntitySearchWithCategoryModalView = require('../templates/DepEntitySearchWithCategoryModalView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepEntitySearchWithCategoryModalView,

  className: 'entity-search-with-category-modal-view',

  ui: {
    searchResultsGrid: '.search-result-grid-container',
    search: '.btn-search',
    searchResultSummary: '.search-result-summary',
    searchResultMax: '.search-result-max',
    hitCount: '.hit-count',
    searchTime: '.search-time',
    refineSeachTip: '.refine-seach-tip',
    submit: '.btn-submit'
  },

  regions: {
    searchResultsGrid: '@ui.searchResultsGrid'
  },

  events: {
    'click @ui.search': 'onClickSearchEntities',
    'click @ui.submit': 'onClickSubmit'
  },

  templateHelpers: function() {
    return {
      searchLimit: this.searchLimit
    };
  },

  onBeforeShow: function() {
    this.entitySearchResults = new GridView({
      columns: [
        {
          name: 'selected',
          label: '',
          cell: SelectOneCell.SelectOneRowCell,
          headerCell: SelectOneCell.SelectOneHeaderCell,
          width: 4,
          editable: true,
          sortable: false
        },
        {
          name: 'entityName',
          label: 'Entity Name',
          cell: 'string',
          headerCell: 'custom',
          width: 39,
          editable: false
        },
        {
          name: 'entityAddress',
          label: 'Address',
          cell: AddressCell,
          headerCell: 'custom',
          width: 38,
          editable: false,
          sortable: false
        },
        {
          name: 'entityType',
          label: 'Type',
          cell: 'string',
          headerCell: 'custom',
          width: 19,
          editable: false
        }
      ],
      collection: new PageableCollection([], { pageSize: 5 }),
      emptyText: 'Search returned no entities.'
    });

    this.showChildView('searchResultsGrid', this.entitySearchResults);
  },

  onClickSearchEntities: function(e) {
    e.preventDefault();
    var self = this;
    this.ui.searchResultSummary.hide();
    this.ui.searchResultMax.hide();

    var data = Syphon.serialize(this, {
      inputReaders: Syphon.SearchInputReaderSet
    });
    data.categoryId = this.options.categoryId;
    data.filterEntityTypeIds = this.options.filterEntityTypeIds;

    var l = Ladda.create(this.ui.search[0]);
    l.start();

    GetEntityByEntityNameAndCategory(data)
      .done(function(result) {
        l.stop();
        self.entitySearchResults.collection.fullCollection.reset(result.entities);
        self.ui.hitCount.text(result.totalHits);
        self.ui.searchResultSummary.show();
        if (result.totalHits === 50) {
          self.ui.searchResultMax.show();
        }
      })
      .fail(function(err) {
        l.stop();
      });
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var selectedModel = this.entitySearchResults.viewGrid.getSelectedModel();
    if (selectedModel) {
      this.triggerMethod('modal:close', selectedModel.toJSON());
    } else {
      this.triggerMethod('modal:close');
    }
  }
});
