/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Radio = require('backbone.radio');
var Ladda = require('ladda');
var moment = require('moment');

var Session = require('psa-core/Session');

var modelGet = require('common/util/modelGet');

var BreadcrumbView = require('common/views/BreadcrumbView');
var ExamHeaderBarView = require('../../../common/views/ExamHeaderBarView');
var ContentServerLinkView = require('common/views/ContentServerLinkView');
var ExamFollowupView = require('../../views/ExamFollowupView');

var SubmitReviewExaminerVerifyFollowupTask = require('../../services/SubmitReviewExaminerVerifyFollowupTask');

require('./ReviewExaminerVerifyFollowupTaskView.css');
var tmplReviewExaminerVerifyFollowupTaskView = require('./ReviewExaminerVerifyFollowupTaskView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplReviewExaminerVerifyFollowupTaskView,

  className: 'review-examiner-verify-followup-task-view container-fluid',

  ui: {
    headerBar: '.header-bar',
    csLinkContainer: '.cs-link-container',
    breadcrumb: '.breadcrumb-container',
    followupTaskForm: '.followup-task-form',
    entityFollowupContainer: '.entity-followup-container',
    examinerFollowupContainer: '.examiner-followup-container',
    reviewDecision: '.review-decision',
    submit: '.submit'
  },

  regions: {
    headerBar: '@ui.headerBar',
    csLinkContainer: '@ui.csLinkContainer',
    breadcrumb: '@ui.breadcrumb',
    entityFollowupContainer: '@ui.entityFollowupContainer',
    examinerFollowupContainer: '@ui.examinerFollowupContainer'
  },

  events: {
    'change @ui.reviewDecision': 'onChangeReviewDecision',
    'click @ui.submit': 'onClickSubmit'
  },

  behaviors: {
    TaskBehavior: {}
  },

  onBeforeShow: function() {
    var exam = this.model.get('exam') || {};

    this.showChildView(
      'csLinkContainer',
      new ContentServerLinkView({
        model: new Backbone.Model({
          buttonText: 'Open Exam Folder',
          contentServerId: exam.CSFolderId
        })
      })
    );

    this.showChildView(
      'headerBar',
      new ExamHeaderBarView({
        model: new Backbone.Model(this.model.get('exam'))
      })
    );

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#nondep/dashboard',
            text: 'Home'
          },
          {
            text: modelGet(this.model, 'task.taskType.description')
          }
        ]
      })
    );

    var followupDocuments = this.model.get('exam').entityFollowupDocuments,
      feedbackResponse = this.model.get('exam').entityFeedbackResponse;

    if (feedbackResponse || followupDocuments.length) {
      this.entityFollowupView = new ExamFollowupView({
        isReadOnly: true,
        model: new Backbone.Model({
          supportingDocuments: this.model.get('exam').entityFollowupDocuments,
          feedback: this.model.get('exam').entityFeedbackResponse
        }),
        mode: 'entity',
        documentTypes: this.options.documentTypes
      });
      this.showChildView('entityFollowupContainer', this.entityFollowupView);
    }

    this.reviewExaminerFollowupView = new ExamFollowupView({
      model: new Backbone.Model({
        supportingDocuments: this.model.get('exam').reviewExaminerFollowupDocuments,
        feedback: this.model.get('exam').reviewExaminerEntityFeedback
      }),
      mode: 'review-examiner',
      documentTypes: this.options.documentTypes
    });
    this.showChildView('examinerFollowupContainer', this.reviewExaminerFollowupView);
  },

  onChangeReviewDecision: function() {
    if (this.isRequestForMoreInfo()) {
      this.ui.examinerFollowupContainer.find(':input').prop('disabled', false);
      this.ui.examinerFollowupContainer.show();
    } else {
      this.ui.examinerFollowupContainer.hide();
      this.ui.examinerFollowupContainer.find(':input').prop('disabled', true);
    }
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;
    $.when(this.ui.followupTaskForm.parsley().whenValidate(), this.reviewExaminerFollowupView.validate()).done(
      function() {
        var l = Ladda.create(self.ui.submit[0]);
        l.start();
        var formData = Syphon.serialize(self);

        if (self.isRequestForMoreInfo() && formData.feedback) {
          var timestamp = moment().format('MM/DD/YYYY h:mm A');
          var userName = Session.user.description;
          formData.feedback = '[' + userName + ' - ' + timestamp + '] \n' + formData.feedback;
        }

        var supportingDocuments = self.reviewExaminerFollowupView.getFormData();

        var data = {
          taskId: self.model.get('taskId'),
          examId: self.model.get('examId'),
          reviewDecisionId: formData.reviewDecisionId,
          feedbackForEntity: formData.feedback,
          supportingDocuments: supportingDocuments
        };
        SubmitReviewExaminerVerifyFollowupTask(data)
          .done(function() {
            Radio.channel('navigate').trigger('show:int:nd:dashboard');
          })
          .fail(function() {
            l.stop();
          });
      }
    );
  },

  isRequestForMoreInfo: function() {
    var reviewDecisionId = this.ui.reviewDecision.val();
    return reviewDecisionId === '3402';
  }
});
