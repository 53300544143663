var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "  <div class=\"row\">\r\n    <div class=\"col-sm-6 col-sm-offset-3\">\r\n      <div class=\"alert alert-info payment-alert\" role=\"alert\">\r\n        <p class=\"title\">\r\n          <i class=\"fa fa-exclamation-triangle\" aria-hidden=\"true\"></i>\r\n          <span>"
    + alias4(((helper = (helper = helpers.PAYMENT_DELETED_TITLE || (depth0 != null ? depth0.PAYMENT_DELETED_TITLE : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"PAYMENT_DELETED_TITLE","hash":{},"data":data}) : helper)))
    + "</span>\r\n        </p>\r\n        <p>"
    + alias4(((helper = (helper = helpers.PAYMENT_DELETED_MESSAGE || (depth0 != null ? depth0.PAYMENT_DELETED_MESSAGE : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"PAYMENT_DELETED_MESSAGE","hash":{},"data":data}) : helper)))
    + "</p>\r\n        <a href=\"#ext/nondep/dashboard\" role=\"button\" class=\"btn btn-primary m-t-sm\">Return Home</a>\r\n      </div>\r\n    </div>\r\n  </div>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "\r\n<h1 class=\"page-title\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.task : depth0)) != null ? stack1.taskType : stack1)) != null ? stack1.description : stack1), depth0))
    + "</h1>\r\n<div class=\"header-bar\"></div>\r\n<div class=\"breadcrumb-container\"></div>\r\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.examPayment : depth0)) != null ? stack1.isDeleted : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "<div class=\"form-horizontal\">\r\n  <div class=\"form-group\">\r\n    <label class=\"control-label col-sm-3\">Total Scheduled Exam Payments</label>\r\n    <div class=\"form-control-static col-sm-6\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.exam : depth0)) != null ? stack1.paymentAmount : stack1), depth0))
    + "</div>\r\n  </div>\r\n  <div class=\"form-group\">\r\n    <label class=\"control-label col-sm-3\">Payments Due</label>\r\n    <div class=\"payment-invoice col-sm-6\"></div>\r\n  </div>\r\n</div>";
},"useData":true});