/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */
var _ = require('underscore');
var moment = require('moment');
var Syphon = require('backbone.syphon');
var Marionette = require('backbone.marionette');

var parsleyUtil = require('common/util/parsleyUtil');
var makeUploadCollection = require('common/util/makeUploadCollection');
var AddressDisplayView = require('common/views/AddressDisplayView');
var addressToString = require('common/util/addressToString');
var modelGet = require('common/util/modelGet');

var CommentsDocumentView = require('common/views/CommentsDocumentView');

var tmplDepCreditUnionLocationActivitiesDiscontinueSubFormView = require('./DepCreditUnionLocationActivitiesDiscontinueSubFormView.hbs');
module.exports = Marionette.LayoutView.extend({
  template: tmplDepCreditUnionLocationActivitiesDiscontinueSubFormView,

  className: 'dep-bank-location-activities-discontinue-sub-form-view form-divider',

  behaviors: {
    RadioGroupToggleBehavior: {
      radios: [
        {
          target: '.location-list-section',
          radioGroup: 'input[name="isOperationTransferred"]',
          valueToShow: '1'
        }
      ]
    }
  },

  ui: {
    actionForm: '.action-form',
    datepickerFields: '.datepicker',
    questionnaireContainer: '.questionnaire-container',
    closingNoticeContainer: '.closing-cert-container',
    transferredToLocationId: 'select[name="transferredToLocationId"]',
    locationListSection: '.location-list-section',
    locationSelect: '.location-select'
  },

  regions: {
    questionnaireContainer: '@ui.questionnaireContainer',
    closingNoticeContainer: '@ui.closingNoticeContainer'
  },

  events: {
    'click @ui.btnLocationSelection': 'onClickLocationSelection'
  },

  templateHelpers: function() {
    var isCU = this.options.entityCategoryId === '2';
    return {
      childIndex: this.options.childIndex,
      isCU: isCU
    };
  },

  onBeforeShow: function() {
    var locationAction = this.model.get('locationAction') || {};
    var questionnaire = locationAction.questionnaire || {};
    var closingNotice = locationAction.closingNotice || {};
    var isCU = this.options.entityCategoryId === '2';

    Syphon.deserialize(this, this.model.get('locationAction'));
    this.ui.datepickerFields.datepicker({});

    this.questionnaire = new CommentsDocumentView({
      isReadOnly: this.options.isReadOnly,
      allowMultipleFiles: false,
      isRequired: true,
      fileUploadLabel: 'Questionnaire',
      commentsLabel: 'Questionnaire Comments',
      commentsName: 'questionnaireComments',
      uniqueId: modelGet(this.model, 'locationAction.locationActivityId'),
      collection: makeUploadCollection(questionnaire.file),
      model: new Backbone.Model({ questionnaireComments: questionnaire.questionnaireComments }),
      documentTypeId: 20001
    });
    this.showChildView('questionnaireContainer', this.questionnaire);

    if (!isCU) {
      this.closingNotice = new CommentsDocumentView({
        isReadOnly: this.options.isReadOnly,
        allowMultipleFiles: false,
        isRequired: false,
        fileUploadLabel: 'Upload Proposed Notice',
        commentsLabel: 'Proposed Notice Comments',
        commentsName: 'closingNoticeComments',
        uniqueId: modelGet(this.model, 'locationAction.locationActivityId'),
        collection: makeUploadCollection(closingNotice.file),
        model: new Backbone.Model({ closingNoticeComments: closingNotice.closingNoticeComments }),
        documentTypeId: 20001
      });
      this.showChildView('closingNoticeContainer', this.closingNotice);
    }

    var transferrableLocationAddresses = this.options.locations.pluck('currentLocationAddress');
    var currentEntityLocationId = this.model.get('entityLocationId');
    transferrableLocationAddresses = _.reject(transferrableLocationAddresses, function(transferrableLocationAddresses) {
      return transferrableLocationAddresses.id === currentEntityLocationId;
    });
    transferrableLocationAddresses.push({ addressLine1: 'New Location', id: 'new-location' });

    this.ui.locationSelect.select2({
      allowClear: true,
      data: transferrableLocationAddresses,
      dropdownCssClass: 'select2-options-bordered',
      templateResult: function(location) {
        var addressMarkup = new AddressDisplayView({
          model: new Backbone.Model(location)
        }).render().el.outerHTML;
        if (location.openedDate) {
          addressMarkup +=
            '<span class="label label-primary"> Date Opened: ' +
            moment(location.openedDate).format('MM/DD/YYYY') +
            '</span>';
        }
        return $(addressMarkup);
      },
      templateSelection: function(location) {
        if (location.addressLine1) {
          return $('<span>' + location.addressLine1 + '</span>');
        } else {
          return $('<span>--</span>');
        }
      },
      matcher: function(params, data) {
        // If there are no search terms, return all of the data
        if (!params.term || params.term.trim() === '') {
          return data;
        }
        // Reduce address to single string for matching.
        var addressStr = addressToString(data, { separator: ' ' }) || '';
        var searchTerm = params.term.toLowerCase();
        var target = addressStr.toLowerCase();
        if (target.indexOf(searchTerm) > -1) {
          return data;
        }
        // Return `null` if the term should not be displayed
        return null;
      }
    });

    if (locationAction.isTransferredToNewLocation === '1') {
      this.ui.locationSelect.val('new-location').trigger('change');
    } else {
      this.ui.locationSelect.val(locationAction.transferredToLocationId).trigger('change');
    }
  },

  validate: function(config) {
    var isCU = this.options.entityCategoryId === '2';

    this.ui.actionForm.parsley().reset();
    if (!isCU) {
      this.closingNotice.removeValidationErrors();
    }
    if (config.type === 'save') {
      // Turn off required field validation for this form
      parsleyUtil.disableRequiredValidation(this.ui.actionForm);
    } else {
      var validationPromises = [this.ui.actionForm.parsley().whenValidate(), this.questionnaire.validate()];
      if (!isCU) {
        validationPromises.push(this.closingNotice.validate());
      }

      // Turn on required field validation for this form
      parsleyUtil.enableRequiredValidation(this.ui.actionForm);
    }
    return $.when.apply($, validationPromises);
  },

  getFormData: function() {
    var isCU = this.options.entityCategoryId === '2';

    var formData = Syphon.serialize(this);
    if (!isCU) {
      formData.noticeCertClosingBranchDocId = this.closingNotice.getFirstFileId();
    }
    formData.questionnaireDocId = this.questionnaire.getFirstFileId();
    formData.isTransferredToNewLocation = '0';
    if (formData.transferredToLocationId === 'new-location') {
      formData.transferredToLocationId = null;
      formData.isTransferredToNewLocation = '1';
    }
    return formData;
  }
});
