/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

var momentUtil = require('common/util/momentUtil');
var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(complaintId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetComplaint: {
        $xmlns: 'http://dobs.pa.gov/cs/complaint/bpm/1.0',
        ComplaintID: complaintId
      }
    },
    blueprintArrays: [
      'GetComplaintResponse.OpenCloseHistory.CS_ComplaintOpenCloseHistory',
      'GetComplaintResponse.NonDepLicenses.ND_License',
      'GetComplaintResponse.SecuritiesLicenses.SC_OrganizationLicense'
    ],
    blueprint: {
      complaintId: '$..ID',
      caseNumber: '$..CaseNumber',
      CSFolderId: '$..CSFolderID',
      initialOpenedDate: '$..InitialOpenedDate',
      finalClosedDate: '$..FinalClosedDate',
      statusId: '$..StatusID',
      weightId: '$..WeightID',
      contactMethodId: '$..ContactMethodID',
      synopsis: '$..Synopsis',
      outcome: '$..Outcome',
      complainantFirstName: '$..ComplainantFirstName',
      complainantLastName: '$..ComplainantLastName',
      complainantPrefix: '$..ComplainantPrefix',
      complainantEmail: '$..ComplainantEmail',
      complainantPhoneNumber: '$..ComplainantPhoneNumber',
      complainantCellPhone: '$..ComplainantCellPhone',
      complainantFaxNumber: '$..ComplainantFaxNumber',
      complainantAddressId: '$..ComplainantAddressID',
      addressLine1: '$..AddressLine1',
      addressLine2: '$..AddressLine2',
      addressLine3: '$..AddressLine3',
      county: '$..County',
      city: '$..City',
      state: '$..State',
      postalCode: '$..PostalCode',
      country: '$..Country',
      complainantAccountNumber: '$..ComplainantAccountNumber',
      referredFromId: '$..ReferredFromID',
      referredToId: '$..ReferredToID',
      caseManagerId: '$..CaseManager',
      isTimely: '$..IsTimely',
      isConsumerDisputed: '$..IsConsumerDisputed',
      isPotentialViolation: '$..IsPotentialViolation',
      regulation: '$..Regulation',
      productTypeId: '$..ProductTypeID',
      complaintCode1Id: '$..ComplaintCode1ID',
      complaintCode1Other: '$..ComplaintCode1Other',
      complaintCode2Id: '$..ComplaintCode2ID',
      complaintCode2Other: '$..ComplaintCode2Other',
      oldProductType: '$..OldProductType',
      oldComplaintCode1: '$..OldComplaintCode1',
      oldComplaintCode1Other: '$..OldComplaintCode1Other',
      oldComplaintCode2: '$..OldComplaintCode2',
      oldComplaintCode2Other: '$..OldComplaintCode2Other',
      oldComplaintCode3: '$..OldComplaintCode3',
      oldComplaintCode3Other: '$..OldComplaintCode3Other',
      refundAmount: '$..RefundAmount',
      refundDate: '$..RefundDate',
      fundedLoanAmount: '$..FundedLoanAmount',
      fundedLoanDate: '$..FundedLoanDate',
      departmentId: '$..DepartmentID',
      departmentId2: '$..DepartmentID2',
      departmentName: '$..DepartmentName',
      departmentDescription: '$..DepartmentDescription',
      entityId: '$..EntityID',
      isEntityUnknown: '$..IsEntityUnknown',
      individualTypeId: '$..IndividualTypeID',
      individualId: '$..IndividualID',
      isIndividualUnknown: '$..IsIndividualUnknown',
      entityType2Id: '$..EntityType2ID',
      complainantName: '$..ComplainantName',
      migrationId: '$..MigrationID',
      contact: {
        firstName: '$..ContactFirstName',
        lastName: '$..ContactLastName',
        title: '$..ContactTitle',
        phoneNumber: '$..ContactPhoneNumber',
        fax: '$..ContactFax',
        email: '$..ContactEmail',
        comments: '$..ContactComments',
        addressLine1: '$..ContactAddressLine1',
        addressLine2: '$..ContactAddressLine2',
        addressLine3: '$..ContactAddressLine3',
        county: '$..ContactCounty',
        city: '$..ContactCity',
        state: '$..ContactState',
        postalCode: '$..ContactPostalCode',
        country: '$..ContactCountry',
        isContactUnknown: '$..IsContactUnknown',
        contactId: '$..ContactID',
        isContactArchived: '$..IsContactArchived'
      },
      openCloseHistory: [
        '$..CS_ComplaintOpenCloseHistory',
        {
          openedDate: '$..OpenedDate',
          closedDate: '$..ClosedDate',
          comments: '$..Comments'
        }
      ],
      ndLicenses: [
        '$..ND_License',
        {
          licenseId: '$..LicenseID',
          locationId: '$..LocationID',
          licenseStatusId: '$..LicenseStatusID',
          licenseTypeId: '$..LicenseTypeID',
          entityId: '$..EntityID'
        }
      ],
      dpEntityDetails: {
        entityName: '$.GetComplaintResponse.DepEntityDetails.DP_Entity.EntityName',
        entityTypeId: '$.GetComplaintResponse.DepEntityDetails.DP_Entity.EntityTypeID',
        charterTypeId: '$.GetComplaintResponse.DepEntityDetails.DP_Entity.CharterTypeID',
        categoryId: '$.GetComplaintResponse.DepEntityDetails.DP_Entity.CategoryID',
        type: '$.GetComplaintResponse.DepEntityDetails.DP_Entity.Type',
        isCollectiveInvestmentFund: '$.GetComplaintResponse.DepEntityDetails.DP_Entity.IsCollectiveInvestmentFund '
      },
      scLicenses: [
        '$..SC_OrganizationLicense',
        {
          licenseId: '$..ID',
          licenseStatusId: '$..LicenseStatusID',
          licenseTypeId: '$..LicenseTypeID',
          organizationId: '$..OrganizationID'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      var formInboundDateConfig = {
        sourceFormat: 'YYYY-MM-DD',
        targetFormat: 'MM/DD/YYYY',
        default: undefined
      };
      response.refundDate = momentUtil.formatStr(response.refundDate, formInboundDateConfig);
      response.fundedLoanDate = momentUtil.formatStr(response.fundedLoanDate, formInboundDateConfig);
      _.each(response.openCloseHistory, function(history) {
        history.openedDate = momentUtil.formatStr(history.openedDate, formInboundDateConfig);
        history.closedDate = momentUtil.formatStr(history.closedDate, formInboundDateConfig);
      });
      response.isPotentialViolationDisplay = response.isPotentialViolation === '1' ? 'Yes' : 'No';
      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
