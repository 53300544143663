/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var NProgress = require('nprogress');

var NavUtils = require('psa-core/util/nav');

var Scaffold = require('common/layout/Scaffold');

var GetActiveApplications = require('../services/GetActiveApplications');
var GetAllApplicationStatus = require('../../application-dashboard/services/GetAllApplicationStatus');
var GetAllApplicationTypes = require('../services/GetAllApplicationTypes');

var NonDepInternalActiveApplicationsView = require('../views/NonDepInternalActiveApplicationsView');

module.exports = function() {
  NProgress.start();
  NProgress.set(0.6);

  $.when(GetActiveApplications(), GetAllApplicationStatus(), GetAllApplicationTypes()).done(function(
    applications,
    applicationStatuses,
    applicationTypes
  ) {
    NavUtils.navigate('nondep/dashboard/active-applications');
    Scaffold.content.show(
      new NonDepInternalActiveApplicationsView({
        model: new Backbone.Model({
          applications: applications,
          applicationStatuses: applicationStatuses,
          applicationTypes: applicationTypes
        })
      })
    );
    NProgress.done();
  });
};
