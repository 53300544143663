/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Ladda = require('ladda');
var Radio = require('backbone.radio');

var modelGet = require('common/util/modelGet');
var momentUtil = require('common/util/momentUtil');

var BreadcrumbView = require('common/views/BreadcrumbView');
var DepOfficerFormView = require('./DepOfficerFormView');

var SubmitEntityOfficer = require('../services/SubmitEntityOfficer');

var tmplDepOfficerEditView = require('../templates/DepOfficerEditView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepOfficerEditView,

  className: 'dep-officer-edit-view container-fluid',

  ui: {
    breadcrumb: '.breadcrumb-container',
    officerContainer: '.officer-form-container',
    submit: '.btn-submit'
  },

  regions: {
    breadcrumb: '@ui.breadcrumb',
    officerContainer: '@ui.officerContainer'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeShow: function() {
    var officer = this.model.get('officer') || {};
    var officerId = officer.officerId;
    var entityId = officer.entityId;
    var entityName = modelGet(this.model, 'entityIdentifiers.entityName');

    this.officerId = officerId;
    this.entityId = entityId;
    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#dep/dashboard',
            text: 'Home'
          },
          {
            link: '#dep/entity/' + this.entityId + '/dashboard',
            text: entityName
          },
          {
            link: '#dep/entity/' + this.entityId + '/officer/' + this.officerId + '/dashboard',
            text: officer.officerType + ' - ' + officer.firstName + ' ' + officer.lastName
          },
          {
            text: 'Edit Officer'
          }
        ]
      })
    );
    this.officerForm = new DepOfficerFormView({
      model: this.model
    });
    this.showChildView('officerContainer', this.officerForm);
  },

  onClickSubmit: function(event) {
    event.preventDefault();
    this.validate().done(
      function() {
        var laddaContext = Ladda.create(this.ui.submit.get(0));
        laddaContext.start();
        var formData = this.getFormData();

        SubmitEntityOfficer(formData)
          .done(function(officerId) {
            Radio.channel('navigate').trigger('show:int:dp:officer', formData.entityId, formData.officerId);
          })
          .fail(function() {
            laddaContext.stop();
          });
      }.bind(this)
    );
  },

  getFormData: function() {
    var formData = this.officerForm.getFormData();

    formData.officerId = this.officerId;
    formData.entityId = this.entityId;

    formData.termStartDate = momentUtil.formatStr(formData.termStartDate, momentUtil.defaultOutboundDateConfig);
    formData.termEndDate = momentUtil.formatStr(formData.termEndDate, momentUtil.defaultOutboundDateConfig);
    return formData;
  },

  validate: function() {
    return this.ui.officerContainer.parsley().whenValidate();
  }
});
