/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var bodybuilder = require('bodybuilder');
var Ladda = require('ladda');

var GridView = require('psa-marionette/views/GridView');
var PageableCollection = require('psa-backbone/models/PageableCollection');

var ButtonLinkCell = require('common/backgrid/ButtonLinkCell');
var ElasticSearchSummaryView = require('common/views/ElasticSearchSummaryView');
var toggleForm = require('common/util/toggleForm');

var SearchSecIndividuals = require('../../../securities/common/services/SearchSecIndividuals');
var CorpFinInternalSidebarNavView = require('../../common/views/CorpFinInternalSidebarNavView');
var SearchCorpFinIndividuals = require('../services/SearchCorpFinIndividuals');

require('../styles/CorpFinIndividualSearchView.css');
var tmplCorpFinIndividualSearchView = require('../templates/CorpFinIndividualSearchView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplCorpFinIndividualSearchView,

  className: 'corp-fin-individual-search-view container-fluid',

  ui: {
    sideBar: '.side-bar-container',
    indTypeSelect: 'select[name="indType"]',
    secSecondaryNameFilters: '.sec-secondary-name-filter-container',
    secIndFilters: '.sec-ind-filter-container',
    searchResultsContainer: '.search-results-container',
    search: '.search',
    clearFilters: '.clear-filters',
    formInputs: ':input[name!="indType"]',
    searchSummary: '.search-summary'
  },

  regions: {
    sideBar: '@ui.sideBar',
    searchResultsContainer: '@ui.searchResultsContainer',
    searchSummary: '@ui.searchSummary'
  },

  events: {
    'change @ui.indTypeSelect': 'onChangeIndType',
    'click @ui.search': 'onClickSearch',
    'click @ui.clearFilters': 'onClickClearFilters',
    'keydown @ui.formInputs': 'onKeydownOfInputs'
  },

  onBeforeShow: function() {
    this.showChildView('sideBar', new CorpFinInternalSidebarNavView());

    this.searchSummaryView = new ElasticSearchSummaryView({
      model: new Backbone.Model({
        searchLimit: this.model.get('searchLimit')
      })
    });
    this.showChildView('searchSummary', this.searchSummaryView);

    toggleForm(this.ui.secSecondaryNameFilters, false);
    toggleForm(this.ui.secIndFilters, false);

    this.searchResults = new GridView({
      columns: [
        {
          name: 'lastName',
          label: 'Last Name',
          cell: 'string',
          headerCell: 'custom',
          width: 23,
          editable: false
        },
        {
          name: 'firstName',
          label: 'First Name',
          cell: 'string',
          headerCell: 'custom',
          width: 23,
          editable: false
        },
        {
          name: 'middleInitial',
          label: 'Middle Initial',
          cell: 'string',
          headerCell: 'custom',
          width: 23,
          editable: false
        },
        {
          name: 'crdNumber',
          label: 'CRD Number',
          cell: 'string',
          headerCell: 'custom',
          width: 23,
          editable: false
        },
        {
          name: 'fileNumber',
          label: 'File Number',
          cell: 'string',
          headerCell: 'custom',
          width: 23,
          editable: false
        },
        {
          name: 'openButton',
          label: '',
          cell: ButtonLinkCell.extend({
            name: 'Open',
            newTab: true,
            buttonClass: 'btn-primary btn-sm',
            href: function(model) {
              if (model.get('type') === 'RECORD') {
                var categoryTypes = {
                  '1': 'registration',
                  '2': 'exemption',
                  '3': 'investment'
                };
                return (
                  '#corpfin/' +
                  categoryTypes[model.get('recordCategoryId')] +
                  '/' +
                  model.get('recordId') +
                  '/dashboard'
                );
              } else {
                var individualId = model.get('individualId');
                return '#sec/individual/' + individualId + '/dashboard';
              }
            }
          }),
          headerCell: 'custom',
          sortable: false,
          editable: false,
          width: 8
        }
      ],
      collection: new PageableCollection([], { pageSize: 25 }),
      emptyText: 'No Individuals Found'
    });

    this.showChildView('searchResultsContainer', this.searchResults);

    this.$('.backgrid.table').addClass('table-bordered');
  },

  onShow: function() {
    this.openButtonColModel = this.searchResults.viewGrid.columns.findWhere({ name: 'openButton' });
    this.crdNumberColModel = this.searchResults.viewGrid.columns.findWhere({ name: 'crdNumber' });
    this.fileNumberColModel = this.searchResults.viewGrid.columns.findWhere({ name: 'fileNumber' });
  },

  onChangeIndType: function(e) {
    e.preventDefault();

    this.searchResults.collection.fullCollection.reset();
    this.searchSummaryView.reset();

    toggleForm(this.ui.secSecondaryNameFilters, this.ui.indTypeSelect.val() === 'SECONDARY_NAME');
    toggleForm(this.ui.secIndFilters, this.ui.indTypeSelect.val() === 'INDIVIDUAL');

    if (this.ui.indTypeSelect.val() === 'SECONDARY_NAME') {
      // ensures results displayed in consistent order
      this.searchResults.viewGrid.columns.remove(this.openButtonColModel);
      this.searchResults.viewGrid.columns.remove(this.crdNumberColModel);
      this.searchResults.viewGrid.columns.add(this.fileNumberColModel);
      this.searchResults.viewGrid.columns.add(this.openButtonColModel);
    } else {
      this.searchResults.viewGrid.columns.remove(this.openButtonColModel);
      this.searchResults.viewGrid.columns.remove(this.fileNumberColModel);
      this.searchResults.viewGrid.columns.add(this.crdNumberColModel);
      this.searchResults.viewGrid.columns.add(this.openButtonColModel);
    }
  },

  onKeydownOfInputs: function(e) {
    // Searches on press of enter
    if (e.keyCode === 13) {
      this.onClickSearch(e);
    }
  },

  onClickSearch: function() {
    var self = this,
      searchData = Syphon.serialize(this, {
        inputReaders: Syphon.SearchInputReaderSet
      }),
      body,
      l,
      bodyObj;

    // Sec Individual Search
    if (searchData.indType === 'INDIVIDUAL') {
      // If no inputs were given, do not perform a search.
      if (
        !(
          searchData.firstName ||
          searchData.lastName ||
          searchData.middleInitial ||
          searchData.crdNumber ||
          searchData.otherIdNumber ||
          searchData.orgName ||
          searchData.orgCRDNumber ||
          searchData.licenseTypeId ||
          searchData.licenseStatusId
        )
      ) {
        return;
      }
      this.searchSummaryView.reset();

      l = Ladda.create(this.ui.search[0]);
      l.start();
      body = bodybuilder();

      if (searchData.lastName) {
        body.query('bool', function(b) {
          b.orQuery('match', 'lastName', {
            query: searchData.lastName,
            fuzziness: 'AUTO',
            analyzer: 'standard'
          });
          b.orQuery('wildcard', 'lastName', searchData.lastName.toLowerCase() + '*');
          return b;
        });
      }

      if (searchData.firstName) {
        body.query('bool', function(b) {
          b.orQuery('match', 'firstName', {
            query: searchData.firstName,
            fuzziness: 'AUTO',
            analyzer: 'standard'
          });
          b.orQuery('wildcard', 'firstName', searchData.firstName.toLowerCase() + '*');
          return b;
        });
      }

      if (searchData.middleInitial) {
        body.query('match', 'middleInitial', searchData.middleInitial);
      }

      if (searchData.crdNumber) {
        body.query('wildcard', 'crdNumber', searchData.crdNumber + '*');
      }

      if (searchData.otherIdNumber) {
        body.query('wildcard', 'otherIdNumber', searchData.otherIdNumber + '*');
      }

      if (searchData.orgName) {
        body.query('nested', 'path', 'organizations', function(orgBody) {
          orgBody.query('bool', function(nameBody) {
            nameBody.orQuery('match', 'organizations.name', {
              query: searchData.orgName,
              fuzziness: 'AUTO',
              analyzer: 'standard'
            });
            nameBody.orQuery('wildcard', 'organizations.name', searchData.orgName.toLowerCase() + '*');
            nameBody.orQuery('match', 'organizations.name.phonetic', searchData.orgName);
            return nameBody;
          });
          return orgBody;
        });
      }

      if (searchData.orgCRDNumber) {
        body.query('nested', 'path', 'organizations', function(orgBody) {
          orgBody.query('wildcard', 'organizations.crdNumber', searchData.orgCRDNumber + '*');
          return orgBody;
        });
      }

      if (searchData.licenseTypeId) {
        body.query('nested', 'path', 'licenses', function(licenseBody) {
          licenseBody.query('match', 'licenses.typeId', searchData.licenseTypeId);
          return licenseBody;
        });
      }

      if (searchData.licenseStatusId) {
        body.query('nested', 'path', 'licenses', function(licenseBody) {
          licenseBody.query('match', 'licenses.statusId', searchData.licenseStatusId);
          return licenseBody;
        });
      }

      body.size(this.model.get('searchLimit'));
      bodyObj = body.build();
      // Keep for testing
      // console.log(JSON.stringify(bodyObj, null, 2));
      SearchSecIndividuals(bodyObj)
        .done(function(searchRes) {
          l.stop();
          self.searchResults.collection.fullCollection.reset(searchRes.hits);
          self.searchSummaryView.display(searchRes);
        })
        .fail(function(err) {
          l.stop();
        });
    }
    // CorpFin Correpsondent Search
    else {
      // If no inputs were given, do not perform a search.
      if (!(searchData.firstName || searchData.lastName || searchData.middleInitial || searchData.fileNumber)) {
        return;
      }
      this.searchSummaryView.reset();

      l = Ladda.create(this.ui.search[0]);
      l.start();
      body = bodybuilder();

      if (searchData.lastName) {
        body.query('bool', function(b) {
          b.orQuery('match', 'lastName', {
            query: searchData.lastName,
            fuzziness: 'AUTO',
            analyzer: 'standard'
          });
          b.orQuery('wildcard', 'lastName', searchData.lastName.toLowerCase() + '*');
          return b;
        });
      }

      if (searchData.firstName) {
        body.query('bool', function(b) {
          b.orQuery('match', 'firstName', {
            query: searchData.firstName,
            fuzziness: 'AUTO',
            analyzer: 'standard'
          });
          b.orQuery('wildcard', 'firstName', searchData.firstName.toLowerCase() + '*');
          return b;
        });
      }

      if (searchData.middleInitial) {
        body.query('match', 'middleInitial', searchData.middleInitial);
      }

      if (searchData.fileNumber) {
        body.query('wildcard', 'fileNumber', searchData.fileNumber + '*');
      }

      body.query('term', 'individualType', searchData.indType);

      body.query('term', 'isDeleted', 0);

      body.size(this.model.get('searchLimit'));
      bodyObj = body.build();
      // Keep for testing
      // console.log(JSON.stringify(bodyObj, null, 2));
      SearchCorpFinIndividuals(bodyObj)
        .done(function(searchRes) {
          l.stop();
          self.searchResults.collection.fullCollection.reset(searchRes.hits);
          self.searchSummaryView.display(searchRes);
        })
        .fail(function(err) {
          l.stop();
        });
    }
  },

  onClickClearFilters: function() {
    this.ui.formInputs.val('');
  }
});
