var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "\r\n<div class=\"page-title clearfix\">\r\n  <h1 class=\"pull-left m-n p-n\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.task : depth0)) != null ? stack1.taskType : stack1)) != null ? stack1.description : stack1), depth0))
    + " for "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.examDetails : depth0)) != null ? stack1.entityName : stack1), depth0))
    + "</h1>\r\n</div>\r\n<div class=\"header-bar\"></div>\r\n<div class=\"breadcrumb-container\"></div>\r\n<div class=\"task-status\"></div>\r\n<div class=\"form-horizontal\">\r\n  <form class=\"review-exam-form\">\r\n    <div class=\"row\">\r\n      <div class=\"col-sm-12\">\r\n        <div class=\"form-group required\">\r\n          <label class=\"control-label col-sm-3 \">Exam Response</label>\r\n          <div class=\" col-sm-9 col-md-6\">\r\n            <div class=\"file-upload-response-container\"></div>\r\n          </div>\r\n        </div>\r\n      </div>\r\n    </div>\r\n  </form>\r\n  <div class=\"form-group\">\r\n    <div class=\"col-sm-9 col-lg-7 col-sm-offset-3\">\r\n      <a href=\"#ext/dep/dashboard\" class=\"btn btn-default\" role=\"button\">Cancel</a>\r\n      <button type=\"button\" class=\"btn btn-primary submit\">Submit</button>\r\n      <div class=\"task-actions\"></div>\r\n    </div>\r\n  </div>\r\n</div>";
},"useData":true});