/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(entityId, departmentId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetIndividualsByEntityAndDepartment: {
        $xmlns: 'http://dobs.pa.gov/cs/individual/bpm/1.0',
        EntityID: entityId,
        DepartmentID: departmentId
      }
    },
    blueprintArrays: [
      'GetIndividualsByEntityAndDepartmentResponse.CS_AllDepartmentEntityIndividualViewND',
      'GetIndividualsByEntityAndDepartmentResponse.CS_AllDepartmentEntityIndividualViewNonND'
    ],
    blueprint: {
      ndIndividuals: [
        '$..CS_AllDepartmentEntityIndividualViewND',
        {
          individualId: '$..ID',
          departmentId: '$..DepartmentID',
          entityId: '$..EntityID',
          individualTypeId: '$..IndividualTypeID',
          contactType: '$..ContactType',
          firstName: '$..FirstName',
          lastName: '$..LastName',
          title: '$..Title',
          phone: '$..Phone',
          email: '$..EMAIL',
          licenseTypeId: '$..LicenseTypeID',
          licenseType: '$..LicenseType',
          licenseNumber: '$..LicenseNumber',
          address: {
            addressLine1: '$..AddressLine1',
            addressLine2: '$..AddressLine2',
            addressLine3: '$..AddressLine3',
            county: '$..County',
            city: '$..City',
            state: '$..State',
            postalCode: '$..PostalCode',
            country: '$..Country'
          },
          isDeleted: '$..IsDeleted'
        }
      ],
      nonNDIndividuals: [
        '$..CS_AllDepartmentEntityIndividualViewNonND',
        {
          individualId: '$..ID',
          departmentId: '$..DepartmentID',
          entityId: '$..EntityID',
          individualTypeId: '$..IndividualTypeID',
          contactType: '$..ContactType',
          firstName: '$..FirstName',
          lastName: '$..LastName',
          title: '$..PositionHeld',
          phone: '$..PHONE',
          email: '$..Email',
          licenseTypeId: '$..LicenseTypeID',
          licenseType: '$..LicenseType',
          licenseNumber: '$..LicenseNumber',
          address: {
            addressLine1: '$..AddressLine1',
            addressLine2: '$..AddressLine2',
            addressLine3: '$..AddressLine3',
            county: '$..County',
            city: '$..City',
            state: '$..State',
            postalCode: '$..PostalCode',
            country: '$..Country'
          },
          isDeleted: '$..IsDeleted'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      response.totalHits = response.ndIndividuals ? response.ndIndividuals.length : response.nonNDIndividuals.length;
      response.individuals = response.ndIndividuals.length > 0 ? response.ndIndividuals : response.nonNDIndividuals;
      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
