/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var NProgress = require('nprogress');

var GetDocumentTypesByDepartment = require('common/services/GetDocumentTypesByDepartment');

var GetExamDetails = require('../../../exam-dashboard/services/GetExamDetails');
var GetExamDocumentsByDocumentType = require('../../../exam-dashboard/services/GetExamDocumentsByDocumentType');

var DepProvideAdditionalExamInfoView = require('./DepProvideAdditionalExamInfoView');

module.exports = {
  event: 'build:dp:provide-additional-exam-info',

  channel: 'task',

  builder: function(task) {
    var defer = $.Deferred();

    var examId = task.taskData.DP.dpExamId;
    $.when(
      GetExamDetails(examId),
      GetExamDocumentsByDocumentType(examId, 20010),
      GetExamDocumentsByDocumentType(examId, 20011),
      GetDocumentTypesByDepartment('DEPOSITORY')
    )
      .done(function(details, intDocuments, extDocuments, documentTypes) {
        NProgress.set(0.85);

        defer.resolve(
          new DepProvideAdditionalExamInfoView({
            model: new Backbone.Model({
              examDetails: details,
              task: task,
              taskId: task.taskId,
              examId: examId,
              intDocuments: intDocuments,
              extDocuments: extDocuments,
              documentTypes: documentTypes
            })
          })
        );
      })
      .fail(function(err) {
        defer.reject(err);
      });
    return defer.promise();
  }
};
