/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Radio = require('backbone.radio');
var Ladda = require('ladda');

var FileUploadCollection = require('psa-backbone/models/FileUploadCollection');
var switchToPrint = require('common/util/switchToPrint');

var toggleForm = require('common/util/toggleForm');
var modelGet = require('common/util/modelGet');
var FileUploadView = require('common/views/FileUploadView');

var SubmitLicenseSurrenderApplication = require('../../services/SubmitLicenseSurrenderApplication');

require('./LicenseSurrenderApplicationView.css');
var tmplLicenseSurrenderFormView = require('./LicenseSurrenderApplicationView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplLicenseSurrenderFormView,

  className: 'non-dep-license-surrender-form-view container-fluid',

  behaviors: {
    ReadOnlyFormBehavior: {}
  },

  ui: {
    licenseSurrenderForm: '.license-surrender-form',

    surrenderDate: '.surrender-date',
    surrenderReason: '.surrenderReason',

    fileUploadContainer: '.file-upload-container',
    fileUploadErrorContainer: '.file-upload-error-container',

    licenseCheckboxes: '.license-list :checkbox',
    principleLicenseCheckbox: 'input[name="principleLicense"]',
    branchLicenseContainer: '.branch-license-container',
    branchLicenseCheckbox: 'input[name="branchLicense"]',
    isCertified: 'input[name="isCertified"]',
    certificationName: 'input[name="certificationName"]',
    submitButton: '.submit-button'
  },

  regions: {
    fileUploadContainer: '@ui.fileUploadContainer'
  },

  events: {
    'change @ui.principleLicenseCheckbox': 'onChangePrincipleLicenseSurrender',
    'click @ui.submitButton': 'onClickSubmit'
  },

  templateHelpers: function() {
    var showAffirmation =
      !this.options.isReadOnly ||
      (this.options.mode === 'print' || this.options.mode === 'int' || this.options.mode === 'int-expand');
    return {
      viewMode: this.options.viewMode,
      isReadOnly: this.options.isReadOnly,
      showAffirmation: showAffirmation
    };
  },

  onBeforeShow: function() {
    Syphon.deserialize(this, this.model.toJSON());

    if (this.options.mode === 'resubmit') {
      this.ui.isCertified.prop('checked', false);
      this.ui.certificationName.val(null);
    }

    this.ui.surrenderDate.datepicker({
      startDate: '0d'
    });
    if (!this.model.get('childLicenses').length || this.model.get('licenseDashboard').isBranch) {
      toggleForm(this.ui.branchLicenseContainer, false);
    }
    _.each(
      this.model.get('licensesToSurrender'),
      function(license) {
        this.ui.licenseCheckboxes.filter('#' + license.licenseId).prop('checked', true);
      }.bind(this)
    );
    var attachments = _.pluck(this.model.get('attachments'), 'file');
    this.fileUpload = new FileUploadView({
      isReadOnly: this.options.isReadOnly,
      allowMultipleFiles: true,
      collection: new FileUploadCollection(attachments),
      metadata: {
        documentTypeId: 10025
      }
    });
    this.showChildView('fileUploadContainer', this.fileUpload);
    this.fileUpload.collection.on('change', this.onChangeFileUpload, this);
  },

  onChangeFileUpload: function() {
    this.ui.fileUploadErrorContainer.hide();
    this.ui.fileUploadErrorContainer.closest('.form-group').removeClass('has-error');
  },

  onChangePrincipleLicenseSurrender: function(e) {
    if (!this.model.get('licenseDashboard').isBranch) {
      if (this.ui.principleLicenseCheckbox.prop('checked')) {
        this.ui.branchLicenseCheckbox.prop('checked', true);
        this.ui.branchLicenseCheckbox.prop('disabled', true);
      } else {
        this.ui.branchLicenseCheckbox.prop('disabled', false);
        this.ui.branchLicenseCheckbox.prop('checked', false);
      }
    }
  },

  onClickSubmit: function(event) {
    event.preventDefault();
    this.validate().done(
      function() {
        var laddaContext = Ladda.create(this.ui.submitButton.get(0));
        laddaContext.start();
        SubmitLicenseSurrenderApplication(this.getFormData())
          .done(function(applicationId) {
            Radio.channel('nd:submit-application').trigger('submit');
            Radio.channel('nd:submit-application').trigger('resubmit', laddaContext);
          })
          .fail(function() {
            laddaContext.stop();
          });
      }.bind(this)
    );
  },

  validate: function() {
    return this.ui.licenseSurrenderForm.parsley().whenValidate();
  },

  removeValidationErrors: function() {
    this.ui.validationErrorContainers.hide();
  },

  getFormData: function() {
    var rawData = Syphon.serialize(this, { exclude: ['principleLicense', 'branchLicense'] });
    rawData.attachments = this.fileUpload.collection.map(function(file) {
      return {
        fileId: file.get('fileId'),
        documentTypeId: modelGet(file, 'metadata.documentTypeId')
      };
    });
    var checkedBoxes = this.ui.licenseCheckboxes.filter(':checked');
    rawData.licensesToSurrender = _.pluck(checkedBoxes, 'value');
    rawData.entityId = this.model.get('entityId');
    rawData.licenseId = this.model.get('licenseId');
    rawData.applicationId = this.model.get('applicationId');
    return rawData;
  },

  onAttach: function() {
    if (this.options.mode === 'print') {
      switchToPrint(this.$el, '.input-view');
    }
  }
});
