/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(invInqId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetInvestigationInquirySubpoenaByInvInq: {
        $xmlns: 'http://dobs.pa.gov/sc/investigationinquiry/bpm/1.0',
        InvInqID: invInqId
      }
    },
    blueprintArrays: ['GetInvestigationInquirySubpoenaByInvInqResponse.SC_InvestigationInquirySubpoena'],
    blueprint: {
      subpoenas: [
        '$..SC_InvestigationInquirySubpoena',
        {
          id: '$..ID',
          invInqId: '$..InvInqID',
          subpoenaDate: '$..SubpoenaDate',
          dueDate: '$..DueDate',
          recipient: '$..Recipient',
          isDeleted: '$..IsDeleted'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      _.each(response.subpoenas, function(subpoena) {
        subpoena.recipient = subpoena.recipient || '--';
      });

      deferred.resolve(response.subpoenas);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
