/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

module.exports = function(data) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      SubmitEntityNameChangeApplication: {
        $xmlns: 'http://dobs.pa.gov/nd/application/bpm/1.0',
        EntityID: data.entityId,
        EntityOrganizationTypeID: data.entityOrganizationTypeId,
        ApplicationID: data.applicationId,
        EffectiveChangeDate: data.effectiveChangeDate,
        EntityNewName: data.entityNewName,
        EntityName2: data.entityName2,
        EntityNameForced: data.entityNameForced,
        TIN: data.tin,
        TINTypeID: data.tinTypeId,
        PhoneNumber: data.phoneNumber,
        PhoneNumberExt: data.phoneNumberExt,
        SecondaryPhoneNumber: data.secondaryPhoneNumber,
        SecondaryPhoneNumberExt: data.secondaryPhoneNumberExt,
        FaxNumber: data.faxNumber,
        FaxNumberExt: data.faxNumberExt,
        IncorporationDate: data.incorporationDate,
        IncorporationPlace: data.incorporationPlace,
        WebAddress: data.webAddress,
        ListWebsiteBusiness: data.listWebsiteBusiness,
        DocumentExceptionLegalOpinion: data.documentExceptionLegalOpinion,
        IsForeignEntity: data.isForeignEntity,
        IsCertified: data.isCertified ? '1' : '0',
        CertificationName: data.certificationName,
        AgentOfService: (function() {
          var aosDetails = data.agentOfService || {};
          return {
            AgentOfServiceFullName: aosDetails.fullName,
            AddressLine1: aosDetails.addressLine1,
            AddressLine2: aosDetails.addressLine2,
            AddressLine3: aosDetails.addressLine3,
            City: aosDetails.city,
            State: aosDetails.state,
            PostalCode: aosDetails.postalCode,
            Country: aosDetails.country,
            County: aosDetails.county,
            PhoneNumber: aosDetails.phoneNumber
          };
        })(),
        ArticlesOfIncorporationDocumentID: data.articlesOfIncorporationDocumentId,
        ForeignRegistrationStatementDocumentID: data.foreignRegistrationStatementDocumentID,
        OperatingAgreementDocumentID: data.operatingAgreementDocumentID,
        ByLawsDocumentID: data.byLawsDocumentID,
        ApplicationDocuments: (function() {
          if (data.applicationDocuments.length === 0) {
            return {};
          }
          return {
            DocumentID: data.applicationDocuments
          };
        })(),
        DBAChanges: (function() {
          var changes = _.map(data.dbaChanges, function(dbaChange) {
            return {
              DBADeleteID: dbaChange.dbaDeleteID,
              DBA: dbaChange.dbaName,
              FictitiousDocumentID: dbaChange.fictitiousDocumentId,
              EndDate: dbaChange.endDate,
              RegistrationDate: dbaChange.registrationDate
            };
          });
          if (changes.length) {
            return {
              DBA: changes
            };
          }
          return {};
        })()
      }
    },
    blueprint: {
      newApplicationID: '$..ID'
    }
  })
    .done(function(response) {
      deferred.resolve(response.newApplicationID);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
