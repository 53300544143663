/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var FileUploadCollection = require('psa-backbone/models/FileUploadCollection');

var parsleyUtil = require('common/util/parsleyUtil');
var modelGet = require('common/util/modelGet');
var toggleForm = require('common/util/toggleForm');
var FileUploadView = require('common/views/FileUploadView');

var UIUtil = require('psa-core/util/ui');
var DepSelectFundModalView = require('./DepSelectFundModalView');
var DepSubfundActivitiesCollectionView = require('./DepSubfundActivitiesCollectionView');
var DepNewSubfundCollectionView = require('./DepNewSubfundCollectionView');
var tmplDepCollectiveInvestmentFundAmendSubFormView = require('./DepCollectiveInvestmentFundAmendSubFormView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepCollectiveInvestmentFundAmendSubFormView,

  className: 'dep-collective-investment-fund-amend-sub-form-view',

  behaviors: {
    ReadOnlyFormBehavior: {}
  },

  ui: {
    amendFundForm: '.amend-fund-form',
    selectFundButton: '.btn-fund-select',
    selectedFundName: '.selected-fund-name',
    selectedFundId: '.selected-fund-id',
    subfundsContainer: '.subfunds-container',
    newSubfundCollectionContainer: '.new-subfund-collection-container',
    addSubfund: '.add-subfund',
    addSubfundSection: '.add-subfund-section',
    trustAmendedDocumentContainer: '.trust-amended-document-container',
    datePickerFields: '.date-picker',
    trustRedlineDocumentContainer: '.trust-redline-document-container',
    supportingDocumentsContainer: '.supporting-documents-container'
  },

  regions: {
    subfundsContainer: '@ui.subfundsContainer',
    newSubfundCollectionContainer: '@ui.newSubfundCollectionContainer',
    trustAmendedDocumentContainer: '@ui.trustAmendedDocumentContainer',
    trustRedlineDocumentContainer: '@ui.trustRedlineDocumentContainer',
    datePickerFields: '@ui.datePickerFields',
    supportingDocumentsContainer: '@ui.supportingDocumentsContainer'
  },

  events: {
    'click @ui.clearEntitySearch': 'onClearEntitySearch',
    'click @ui.selectFundButton': 'onClickFundSelect',
    'click @ui.submit': 'onClickSubmit',
    'click @ui.addSubfund': 'onClickAddSubfund'
  },

  childEvents: {
    'remove:subfund': 'onChildRemoveSubfund'
  },

  templateHelpers: function() {
    return {
      isReadOnly: this.options.isReadOnly
    };
  },

  onBeforeShow: function() {
    var filingContent = this.model.get('filingContent') || {};
    var fundId = filingContent.fundId;
    var supportingDocuments = _.pluck(modelGet(this.model, 'filingContent.supportingDocuments', []), 'file');

    var trustAmendedDocId = modelGet(this.model, 'filingContent.trustAmendedDocument.fileId');
    if (trustAmendedDocId) {
      var trustAmendedDocument = modelGet(this.model, 'filingContent.trustAmendedDocument');
    }

    var trustRedlineDocId = modelGet(this.model, 'filingContent.trustRedlineDocument.fileId');
    if (trustRedlineDocId) {
      var trustRedlineDocument = modelGet(this.model, 'filingContent.trustRedlineDocument');
    }

    Syphon.deserialize(this, this.model.get('filingContent'));

    this.ui.datePickerFields.datepicker();

    this.subfundCollection = new Backbone.Collection();

    this.subFunds = new DepSubfundActivitiesCollectionView({
      collection: this.subfundCollection,
      model: this.model,
      isReadOnly: this.options.isReadOnly,
      actionType: this.options.actionType
    });
    this.showChildView('subfundsContainer', this.subFunds);

    this.newSubfunds = new DepNewSubfundCollectionView({
      collection: new Backbone.Collection(filingContent.newSubFunds),
      isReadOnly: this.options.isReadOnly
    });
    this.showChildView('newSubfundCollectionContainer', this.newSubfunds);

    this.trustAmendedDocument = new FileUploadView({
      isReadOnly: this.options.isReadOnly,
      isRequired: true,
      collection: new FileUploadCollection(trustAmendedDocument),
      metadata: {
        documentTypeId: 20001
      }
    });
    this.showChildView('trustAmendedDocumentContainer', this.trustAmendedDocument);

    this.trustRedlineDocument = new FileUploadView({
      isReadOnly: this.options.isReadOnly,
      isRequired: true,
      collection: new FileUploadCollection(trustRedlineDocument),
      metadata: {
        documentTypeId: 20001
      }
    });
    this.showChildView('trustRedlineDocumentContainer', this.trustRedlineDocument);

    this.supportingDocumentsView = new FileUploadView({
      isReadOnly: this.options.isReadOnly,
      allowMultipleFiles: true,
      collection: new FileUploadCollection(supportingDocuments),
      metadata: {
        documentTypeId: 20001
      }
    });
    this.showChildView('supportingDocumentsContainer', this.supportingDocumentsView);

    toggleForm(this.ui.addSubfundSection, !fundId);
    if (fundId) {
      var fund = _.find(filingContent.trustFunds, function(fund) {
        return (fund.id = fundId);
      });
      var subFunds = _.filter(filingContent.trustSubfunds, function(fund) {
        return fund.trustFundId === fundId;
      });
      this.ui.selectedFundName.val(fund.name);
      this.ui.selectedFundId.val(fund.id);
      this.subfundCollection.set(subFunds);
      this.subFunds.render();
      toggleForm(this.ui.addSubfundSection, true);
    } else {
      toggleForm(this.ui.addSubfundSection, false);
    }
  },

  onClickAddSubfund: function(e) {
    e.preventDefault();
    this.newSubfunds.collection.add({});
  },

  onChildRemoveSubfund: function(childView) {
    this.newSubfunds.collection.remove(childView);
  },

  onClickFundSelect: function(e) {
    e.preventDefault();
    var self = this;

    UIUtil.showModal({
      title: 'Select a Fund',
      view: new DepSelectFundModalView({
        model: this.model
      })
    }).done(function(fund) {
      if (fund) {
        var fundId = fund.id;
        self.ui.selectedFundName.val(fund.name);
        self.ui.selectedFundId.val(fundId);
        var trustSubfunds = modelGet(self.model, 'filingContent.trustSubfunds');
        var filteredTrustSubunds = _.filter(trustSubfunds, function(fund) {
          return fund.trustFundId === fundId;
        });
        self.subfundCollection.set(filteredTrustSubunds);
        self.subFunds.render();
        self.newSubfunds.collection.reset();
        toggleForm(self.ui.addSubfundSection, true);
      } else {
        self.ui.selectedFundName.val('');
        self.ui.selectedFundId.val('');
        self.subfundCollection.reset(self.subfundCollectionremoe);
        toggleForm(self.ui.addSubfundSection, false);
      }
    });
  },

  removeValidationErrors: function() {
    this.ui.amendFundForm.parsley().reset();
    this.trustAmendedDocument.removeValidationErrors();
    this.trustRedlineDocument.removeValidationErrors();
  },

  validate: function(config) {
    this.removeValidationErrors();

    if (config.type === 'save') {
      // Turn off required field validation for this form
      parsleyUtil.disableRequiredValidation(this.ui.amendFundForm);
    } else {
      var validationPromises = [
        this.ui.amendFundForm.parsley().whenValidate(),
        this.trustAmendedDocument.validate(),
        this.trustRedlineDocument.validate()
      ];

      // Turn on required field validation for this form
      parsleyUtil.enableRequiredValidation(this.ui.amendFundForm);
    }
    return $.when.apply($, validationPromises);
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    formData.trustAmendedDocId = this.trustAmendedDocument.getFirstFileId();
    formData.trustRedlineDocId = this.trustRedlineDocument.getFirstFileId();
    formData.subfundActions = this.subFunds.getFormData();
    formData.newSubfunds = this.newSubfunds.getFormData();
    formData.supportingDocuments = this.supportingDocumentsView.collection.map(function(file) {
      return {
        fileId: file.get('fileId'),
        documentTypeId: modelGet(file, 'metadata.documentTypeId')
      };
    });
    return formData;
  }
});
