/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');
var _get = require('lodash.get');
var Ladda = require('ladda');

var BreadcrumbView = require('common/views/BreadcrumbView');

var DepSubsidiaryFormView = require('./DepSubsidiaryFormView');

var SubmitSubsidiary = require('../services/SubmitSubsidiary');

var tmplDepSubsidiaryNewView = require('../templates/DepSubsidiaryNewView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepSubsidiaryNewView,

  className: 'dep-new-subsidiary-view container-fluid',

  ui: {
    breadCrumbContainer: '.breadcrumb-container',
    subsidiaryFormContainer: '.subsidiary-form-container',
    submitButton: '.btn-submit'
  },

  regions: {
    breadCrumbContainer: '@ui.breadCrumbContainer',
    subsidiaryFormContainer: '@ui.subsidiaryFormContainer'
  },

  events: {
    'click @ui.submitButton': 'onClickSubmit'
  },

  templateHelpers: function() {
    return {
      isCreditUnion: _get(this.model.get('entityIdentifiers'), 'entityTypeId') === '11'
    };
  },

  onBeforeShow: function() {
    var entityId = this.model.get('entityId');
    var entityName = _get(this.model.get('entityIdentifiers'), 'entityName');
    var isCreditUnion = _get(this.model.get('entityIdentifiers'), 'entityTypeId') === '11';

    this.showChildView(
      'breadCrumbContainer',
      new BreadcrumbView({
        breadcrumbs: [
          {
            text: 'Home',
            link: '#dep/dashboard'
          },
          {
            text: entityName,
            link: '#dep/entity/' + entityId + '/dashboard'
          },
          {
            text: isCreditUnion ? 'New CUSO' : 'New Subsidiary'
          }
        ]
      })
    );

    this.subsidiaryForm = new DepSubsidiaryFormView({
      model: this.model
    });

    this.showChildView('subsidiaryFormContainer', this.subsidiaryForm);
  },

  onClickSubmit: function(event) {
    event.preventDefault();
    var self = this;
    this.validate().done(function() {
      var laddaContext = Ladda.create(self.ui.submitButton.get(0));
      laddaContext.start();
      var formData = self.getFormData();
      formData.isDeleted = 0;
      SubmitSubsidiary(formData)
        .done(function(response) {
          Radio.channel('navigate').trigger(
            'show:int:dp:entity:subsidiary',
            formData.entityId,
            response.newSubsidiaryId
          );
        })
        .fail(function(err) {
          laddaContext.stop();
        });
    });
  },

  validate: function() {
    return this.ui.subsidiaryFormContainer.parsley().whenValidate();
  },

  getFormData: function() {
    return this.subsidiaryForm.getFormData();
  }
});
