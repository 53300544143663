/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Backbone = require('backbone');
var Marionette = require('backbone.marionette');

var BreadcrumbView = require('common/views/BreadcrumbView');
var HeaderBarView = require('common/views/HeaderBarView');

var tmplDepExtActiveFilingView = require('../templates/DepExtActiveFilingView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepExtActiveFilingView,

  className: 'dep-active-filing-view container-fluid',

  ui: {
    headerBar: '.header-bar',
    breadcrumb: '.breadcrumb-container',
    filing: '.filing-form-container'
  },

  regions: {
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb',
    filing: '@ui.filing'
  },

  initialize: function(options) {
    this.filingFormView = options.filingFormView;
  },

  onBeforeShow: function() {
    var filingDetails = this.model.get('filingDetails') || {};
    var entityId = filingDetails.entityId;
    var entityName = filingDetails.entityName;
    var filingType = filingDetails.filingType;
    var filingTypeId = filingDetails.filingTypeId;
    var isEntityFiling = !!entityId;
    // Filing type is CU Audit, Trust Audit, Trust Call Report or CIF/CTF
    var isSubmission = _.includes(['111', '205', '305', '306', '307'], filingTypeId);

    var headerBarColumns = [
      {
        name: 'filingType',
        label: isSubmission ? 'Type' : 'Filing Type',
        minWidth: '160px',
        maxWidth: '350px',
        overflow: 'truncate'
      }
    ];

    if (isEntityFiling) {
      headerBarColumns.unshift(
        {
          name: 'entityName',
          label: 'Entity Name',
          minWidth: '160px',
          maxWidth: '300px'
        },
        {
          name: 'entityType',
          label: 'Entity Type',
          minWidth: '160px'
        }
      );
    }

    this.showChildView(
      'headerBar',
      new HeaderBarView({
        columns: new Backbone.Collection(headerBarColumns),
        model: new Backbone.Model(filingDetails)
      })
    );

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#ext/dep/dashboard',
            text: 'Home'
          },
          {
            link: '#ext/dep/entity/' + entityId + '/dashboard',
            text: entityName,
            renderable: isEntityFiling
          },
          {
            text: isSubmission ? filingType : filingType + ' Filing'
          }
        ]
      })
    );

    this.showChildView('filing', this.filingFormView);
  }
});
