/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');

var SideNavSectionView = require('common/views/SideNavSectionView');
var switchToPrint = require('common/util/switchToPrint');

var Ladda = require('ladda');

var CheckCasherRenewalGeneralDetailsSectionView = require('./CheckCasherRenewalGeneralDetailsSectionView');
var CheckCasherRenewalBranchesSectionView = require('./CheckCasherRenewalBranchesSectionView');
var CheckCasherRenewalAffirmationSectionView = require('./CheckCasherRenewalAffirmationSectionView');
var CheckCasherRenewalRequirementsSectionView = require('./CheckCasherRenewalRequirementsSectionView');

var tmplCheckCasherRenewalApplicationView = require('./CheckCasherRenewalApplicationView.hbs');

var SubmitCheckCasherRenewalApplication = require('../../services/SubmitCheckCasherRenewalApplication');

module.exports = Marionette.LayoutView.extend({
  template: tmplCheckCasherRenewalApplicationView,

  className: 'check-casher-renewal-application-view m-b-md',

  behaviors: {
    ReadOnlyFormBehavior: {}
  },

  ui: {
    checkCasherRenewalApplicationContainer: '.consumer-discount-renewal-application-container'
  },

  regions: {
    checkCasherRenewalApplicationContainer: '@ui.checkCasherRenewalApplicationContainer'
  },

  onBeforeShow: function() {
    var mode = this.options.mode;
    var isForPrinting = mode === 'print';
    var isExpandInternal = mode === 'int-expand';

    this.form = new SideNavSectionView({
      collection: new Backbone.Collection([
        {
          id: 'checkCasherRenewalRequirements',
          title: 'Requirements',
          view: new CheckCasherRenewalRequirementsSectionView({
            isReadOnly: this.options.isReadOnly
          })
        },
        {
          id: 'checkCasherRenewalGeneralDetails',
          title: 'General Details',
          view: new CheckCasherRenewalGeneralDetailsSectionView({
            isReadOnly: this.options.isReadOnly,
            model: this.model
          })
        },
        {
          id: 'checkCasherRenewalBranches',
          title: 'Branch Licenses',
          view: new CheckCasherRenewalBranchesSectionView({
            isReadOnly: this.options.isReadOnly,
            model: this.model
          })
        },
        {
          id: 'checkCasherRenewalAffirmation',
          title: 'Affirmation',
          view: new CheckCasherRenewalAffirmationSectionView({
            mode: this.options.mode,
            model: this.model
          })
        }
      ]),
      useNavigationButtons: !this.options.isReadOnly,
      expanded: isForPrinting || isExpandInternal,
      contentWidth: 'medium',
      buttons: [
        {
            buttonText: 'Save',
            buttonClass: 'btn-default ladda-button',
            buttonIconClass: 'fa-save',
            labelClass: 'ladda-label',
            buttonAttrs: {
              'data-style': 'expand-right'
            },
            onClick: this.onClickSave.bind(this)
        }
      ]
    });
    
    if (
      this.options.isReadOnly &&
      !(this.options.mode === 'print' || this.options.mode === 'int' || this.options.mode === 'int-expand')
    ) {
      this.form.collection.remove(this.form.collection.get('checkCasherRenewalAffirmation'));
    }

    this.showChildView('checkCasherRenewalApplicationContainer', this.form);
  },

  onAttach: function() {
    if (this.options.mode === 'print') {
      switchToPrint(this.$el, '.input-view');
    }
  },

  onClickSave: function(e) {
    e.preventDefault();
    var self = this;
    self.form.validateAll({ type: 'save' })
    .done(function() { 
    var l = Ladda.create(e.currentTarget);
    l.start();
    var rawFormData = self.form.getFormData();
      var formData = {
        applicationId: self.model.get('applicationDetails').applicationId,
        generalDetails: rawFormData.checkCasherRenewalGeneralDetails,
        branchLicenses: rawFormData.checkCasherRenewalBranches,
        affirmation: rawFormData.checkCasherRenewalAffirmation
      };
      
      SubmitCheckCasherRenewalApplication(formData)
        .done(function() {
          l.stop();
        })
        .fail(function() {
          l.stop();
        });
      });
  }
});
