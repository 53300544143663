/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Backgrid = require('backgrid');

var PageableCollection = require('psa-backbone/models/PageableCollection');

var GridSpinnerHeaderView = require('./GridSpinnerHeaderView');
var GridSpinnerBodyView = require('./GridSpinnerBodyView');

var tmplGridSpinnerView = require('./GridSpinnerView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplGridSpinnerView,

  className: 'grid-spinner-view grid',

  columns: [],

  ui: {
    header: 'thead',
    body: 'tbody',
    paginator: '.paginator-container'
  },

  regions: {
    header: '@ui.header',
    body: '@ui.body',
    paginator: '@ui.paginator'
  },

  initialize: function(options) {
    this.columns = options.columns || this.columns;
  },

  onBeforeShow: function() {
    this.showChildView(
      'header',
      new GridSpinnerHeaderView({
        collection: new Backbone.Collection(this.columns)
      })
    );

    this.showChildView('body', new GridSpinnerBodyView(this.options));

    if (this.options.pageable) {
      this.showChildView(
        'paginator',
        new Backgrid.Extension.Paginator({
          collection: new PageableCollection()
        })
      );
    }
  }
});
