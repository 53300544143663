var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "\r\n<td colspan=\""
    + alias4(((helper = (helper = helpers.columnCount || (depth0 != null ? depth0.columnCount : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"columnCount","hash":{},"data":data}) : helper)))
    + "\">\r\n  <span>"
    + alias4(((helper = (helper = helpers.emptyText || (depth0 != null ? depth0.emptyText : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"emptyText","hash":{},"data":data}) : helper)))
    + "</span>\r\n</td>";
},"useData":true});