/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Backgrid = require('backgrid');
var Backbone = require('backbone');

var TextInputCellEditor = require('../TextInputCellEditor');

var StaticInputCell = Backbone.View.extend({
  /** @property */
  tagName: 'td',

  className: 'static-input-cell',

  /**
     @property {Backgrid.CellFormatter|Object|string} [formatter=CellFormatter]
  */
  formatter: Backgrid.CellFormatter,

  editor: TextInputCellEditor,

  /**
     Initializer.

     @param {Object} options
     @param {Backbone.Model} options.model
     @param {Backgrid.Column} options.column

     @throws {ReferenceError} If formatter is a string but a formatter class of
     said name cannot be found in the Backgrid module.
  */
  initialize: function(options) {
    this.column = options.column;
    if (!(this.column instanceof Backgrid.Column)) {
      this.column = new Backgrid.Column(this.column);
    }

    var column = this.column;
    var model = this.model;
    var $el = this.$el;

    var formatter = Backgrid.resolveNameToClass(column.get('formatter') || this.formatter, 'Formatter');
    if (!_.isFunction(formatter.fromRaw) && !_.isFunction(formatter.toRaw)) {
      formatter = new formatter();
    }
    this.formatter = formatter;

    var editor = Backgrid.resolveNameToClass(column.get('editor') || this.editor, 'CellEditor');
    this.editor = editor;

    this.listenTo(model, 'change:' + column.get('name'), function() {
      if (!$el.hasClass('editor')) {
        this.render();
      }
    });

    this.listenTo(model, 'backgrid:error', this.renderError);

    this.listenTo(column, 'change:sortable change:renderable', function(column) {
      var changed = column.changedAttributes();
      for (var key in changed) {
        if (_.has(changed, key)) {
          $el.toggleClass(key, changed[key]);
        }
      }
    });

    this.updateStateClassesMaybe();
  },

  updateStateClassesMaybe: function() {
    var model = this.model;
    var column = this.column;
    var $el = this.$el;
    $el.toggleClass('sortable', Backgrid.callByNeed(column.sortable(), column, model));
    $el.toggleClass('renderable', Backgrid.callByNeed(column.renderable(), column, model));
  },

  /**
     Render a text string in a table cell. The text is converted from the
     model's raw value for this cell's column.
  */
  render: function() {
    var model = this.model;
    var column = this.column || {};
    var columnName = column.get('name');
    var CellEditorView = this.editor;

    this.currentEditor = new CellEditorView({
      column: column,
      model: model,
      formatter: this.formatter
    });
    model.trigger('backgrid:edit', model, column, this, this.currentEditor);

    this.$el.empty();
    this.currentEditor.render();
    this.$el.append(this.currentEditor.$el);

    this.$el.addClass(columnName);
    this.updateStateClassesMaybe();
    this.delegateEvents();
    return this;
  },

  enterEditMode: function() {},
  exitEditMode: function() {}
});

module.exports = StaticInputCell;
