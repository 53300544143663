var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=helpers.helperMissing;

  return "\r\n<div class=\"page-title clearfix\">\r\n  <h1 class=\"pull-left m-n p-n\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.entityIdentifiers : depth0)) != null ? stack1.entityName : stack1), depth0))
    + "</h1>\r\n  <div class=\"page-title-buttons-container\">\r\n    <div class=\"condition-action-drop-down\"></div>\r\n  </div>\r\n</div>\r\n<div class=\"page-subtitle\">\r\n  <h2>Condition</h2>\r\n  <div class=\"page-subtitle-details\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.condition : depth0)) != null ? stack1.type : stack1), depth0))
    + "</div>\r\n</div>\r\n<div class=\"breadcrumb-container\"></div>\r\n<div class=\"form-horizontal form-static\">\r\n  <div class=\"row\">\r\n    <div class=\"col-sm-8 col-sm-offset-1\">\r\n      <div class=\"row\">\r\n        <div class=\"col-sm-6\">\r\n          <div class=\"form-group type\">\r\n            <label class=\"control-label col-sm-4\">Type</label>\r\n            <div class=\"form-control-static col-sm-8\">"
    + alias2((helpers["default"] || (depth0 && depth0["default"]) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.condition : depth0)) != null ? stack1.type : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\r\n          </div>\r\n        </div>\r\n        <div class=\"col-sm-6\">\r\n          <div class=\"form-group frequency\">\r\n            <label class=\"control-label col-sm-4\">Frequency</label>\r\n            <div class=\"form-control-static col-sm-8\">"
    + alias2((helpers["default"] || (depth0 && depth0["default"]) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.condition : depth0)) != null ? stack1.frequency : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\r\n          </div>\r\n        </div>\r\n      </div>\r\n      <div class=\"row\">\r\n        <div class=\"col-sm-6\">\r\n          <div class=\"form-group due-date\">\r\n            <label class=\"control-label col-sm-4\">Due Date</label>\r\n            <div class=\"form-control-static col-sm-8\">"
    + alias2((helpers["default"] || (depth0 && depth0["default"]) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.condition : depth0)) != null ? stack1.dueDate : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\r\n          </div>\r\n        </div>\r\n        <div class=\"col-sm-6\">\r\n          <div class=\"form-group status\">\r\n            <label class=\"control-label col-sm-4\">Status</label>\r\n            <div class=\"form-control-static col-sm-8\">"
    + alias2((helpers["default"] || (depth0 && depth0["default"]) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.condition : depth0)) != null ? stack1.status : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\r\n          </div>\r\n        </div>\r\n      </div>\r\n      <div class=\"row\">\r\n        <div class=\"col-sm-6\">\r\n          <div class=\"form-group received-date\">\r\n            <label class=\"control-label col-sm-4\">Received Date</label>\r\n            <div class=\"form-control-static col-sm-8\">"
    + alias2((helpers["default"] || (depth0 && depth0["default"]) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.condition : depth0)) != null ? stack1.receivedDate : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\r\n          </div>\r\n        </div>\r\n      </div>\r\n      <div class=\"form-group address-activity\">\r\n        <label class=\"control-label col-sm-2\">Address Activity</label>\r\n        <p class=\"form-control-static col-sm-10\">"
    + alias2((helpers["default"] || (depth0 && depth0["default"]) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.condition : depth0)) != null ? stack1.addressActivity : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</p>\r\n      </div>\r\n      <div class=\"form-group comments\">\r\n        <label class=\"control-label col-sm-2\">Comments</label>\r\n        <p class=\"form-control-static col-sm-10\">"
    + alias2((helpers["default"] || (depth0 && depth0["default"]) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.condition : depth0)) != null ? stack1.comments : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</p>\r\n      </div>\r\n    </div>\r\n  </div>\r\n</div>";
},"useData":true});