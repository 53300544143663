/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Radio = require('backbone.radio');
var Ladda = require('ladda');
var moment = require('moment');

var Session = require('psa-core/Session');

var modelGet = require('common/util/modelGet');
var HeaderBarView = require('common/views/HeaderBarView');
var BreadcrumbView = require('common/views/BreadcrumbView');

var SubmitExamExaminationClosingTask = require('../../services/SubmitExamExaminationClosingTask');

var tmplSecExaminationClosingTaskView = require('./SecExaminationClosingTaskView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplSecExaminationClosingTaskView,

  className: 'sec-examination-closing-task-view container-fluid',

  ui: {
    headerBar: '.header-bar',
    breadcrumb: '.breadcrumb-container',
    examinationClosingForm: '.examination-closing-form',
    submit: '.submit'
  },

  regions: {
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb'
  },

  behaviors: {
    TaskBehavior: {}
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeShow: function() {
    var exam = this.model.get('exam') || {};

    var headerBarColumns = [
      {
        name: 'applicantLegalName',
        label: 'Applicant Legal Name',
        minWidth: '160px',
        maxWidth: '300px'
      },
      {
        name: 'crdNumber',
        label: 'CRD Number',
        minWidth: '160px',
        maxWidth: '300px'
      },
      {
        name: 'examNumber',
        label: 'Exam Number',
        minWidth: '160px',
        maxWidth: '300px'
      },
      {
        name: 'examType',
        label: 'Exam Type',
        minWidth: '160px',
        maxWidth: '300px'
      },
      {
        name: 'primaryExaminer',
        label: 'Primary Examiner',
        minWidth: '160px',
        maxWidth: '300px'
      }
    ];

    this.showChildView(
      'headerBar',
      new HeaderBarView({
        columns: new Backbone.Collection(headerBarColumns),
        model: new Backbone.Model(exam)
      })
    );

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#sec/dashboard',
            text: 'Home'
          },
          {
            text: modelGet(this.model, 'task.taskType.description')
          }
        ]
      })
    );

    var internalComments;
    if (exam.internalComments) {
      internalComments = '\n\n' + exam.internalComments;
    } else {
      internalComments = '';
    }

    exam.internalComments = internalComments;

    Syphon.deserialize(this, exam);
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;
    var formData = this.getFormData();

    this.validate().done(function() {
      var l = Ladda.create(self.ui.submit[0]);
      l.start();
      SubmitExamExaminationClosingTask(formData)
        .done(function() {
          Radio.channel('navigate').trigger('show:int:sc:dashboard');
        })
        .fail(function() {
          l.stop();
        });
    });
  },

  validate: function() {
    return this.ui.examinationClosingForm.parsley().whenValidate();
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    formData.examId = this.model.get('examId');
    formData.taskId = this.model.get('taskId');

    var timestamp = moment().format('MM/DD/YYYY h:mm A');
    var userName = Session.user.description;

    formData.internalComments = '[' + userName + ' - ' + timestamp + '] \n' + formData.internalComments;

    return formData;
  }
});
