var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.status || (depth0 != null ? depth0.status : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"status","hash":{},"data":data}) : helper)));
},"3":function(container,depth0,helpers,partials,data) {
    return "--";
},"5":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.officeType || (depth0 != null ? depth0.officeType : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"officeType","hash":{},"data":data}) : helper)));
},"7":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.openedDate || (depth0 != null ? depth0.openedDate : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"openedDate","hash":{},"data":data}) : helper)));
},"9":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.closedDate || (depth0 != null ? depth0.closedDate : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"closedDate","hash":{},"data":data}) : helper)));
},"11":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.activity || (depth0 != null ? depth0.activity : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"activity","hash":{},"data":data}) : helper)));
},"13":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.locationType || (depth0 != null ? depth0.locationType : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"locationType","hash":{},"data":data}) : helper)));
},"15":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.county || (depth0 != null ? depth0.county : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"county","hash":{},"data":data}) : helper)));
},"17":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.relocatedTo || (depth0 != null ? depth0.relocatedTo : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"relocatedTo","hash":{},"data":data}) : helper)));
},"19":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.comments || (depth0 != null ? depth0.comments : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"comments","hash":{},"data":data}) : helper)));
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "\r\n<div class=\"location-form form-horizontal form-static m-t-lg\">\r\n  <div class=\"form-group\">\r\n    <label class=\"control-label col-sm-3 col-lg-2\">Status</label>\r\n    <div class=\"form-control-static col-sm-9 col-lg-10\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.status : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\r\n  </div>\r\n  <div class=\"form-group \">\r\n    <label class=\"control-label col-sm-3 col-lg-2\">Office Type</label>\r\n    <div class=\"form-control-static col-sm-9 col-lg-10\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.officeType : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\r\n  </div>\r\n  <div class=\"form-group\">\r\n    <label class=\"control-label col-sm-3 col-lg-2\">Date Opened</label>\r\n    <div class=\"form-control-static col-sm-9 col-lg-10\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.openedDate : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\r\n  </div>\r\n  <div class=\"form-group\">\r\n    <label class=\"control-label col-sm-3 col-lg-2\">Date Closed</label>\r\n    <div class=\"form-control-static col-sm-9 col-lg-10\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.closedDate : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\r\n  </div>\r\n  <div class=\"form-group activity-type\">\r\n    <label class=\"control-label col-sm-3 col-lg-2\">Activity</label>\r\n    <div class=\"form-control-static col-sm-9 col-lg-10\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.activity : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\r\n  </div>\r\n  <div class=\"form-group\">\r\n    <label class=\"control-label col-sm-3 col-lg-2\">Location Type</label>\r\n    <div class=\"form-control-static col-sm-9 col-lg-10\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.locationType : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\r\n  </div>\r\n  <div class=\"form-group\">\r\n    <label class=\"control-label col-sm-3 col-lg-2\">Address</label>\r\n    <div class=\"address-container col-sm-9 col-lg-10\"></div>\r\n  </div>\r\n  <div class=\"form-group\">\r\n    <label class=\"control-label col-sm-3 col-lg-2\">County</label>\r\n    <div class=\"form-control-static col-sm-9 col-lg-10\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.county : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\r\n  </div>\r\n  <div class=\"form-group relocated-to\">\r\n    <label class=\"control-label col-sm-3 col-lg-2\">Relocated To</label>\r\n    <p class=\"form-control-static col-sm-9 col-lg-10\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.relocatedTo : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "</p>\r\n  </div>\r\n  <div class=\"form-group comments\">\r\n    <label class=\"control-label col-sm-3 col-lg-2\">Comments</label>\r\n    <p class=\"form-control-static col-sm-9 col-lg-10\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.comments : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "</p>\r\n  </div>\r\n</div>";
},"useData":true});