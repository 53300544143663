/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');

var Session = require('psa-core/Session');

var userHasRole = require('common/util/userHasRole');

var startsWith = require('../../util/startsWith');

var ExternalHeaderView = require('./ExternalHeaderView');
var InternalHeaderView = require('./InternalHeaderView');
var BasicHeaderView = require('./BasicHeaderView');

var tmplHeaderView = require('../templates/HeaderView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplHeaderView,

  ui: {
    navbar: '.navbar',
    headerWrapper: '.header-wrapper',
    headerContainer: '.header-container',
    internalAppSelectHeader: '.int-app-select-header',
    externalHeader: '.ext-header',
    internalHeader: '.int-header',
    logout: '.logout'
  },

  regions: {
    wrapper: '@ui.headerWrapper',
    externalHeader: '@ui.externalHeader',
    internalHeader: '@ui.internalHeader',
    internalAppSelectHeader: '@ui.internalAppSelectHeader'
  },

  events: {
    'click @ui.logout': 'onLogout'
  },

  onBeforeShow: function() {
    var self = this;

    // Fired from the finishLogin method in app.external.js
    Radio.channel('header').on('login:external', function() {
      self.showChildView(
        'externalHeader',
        new ExternalHeaderView({
          model: new Backbone.Model({
            user: Session.user
          })
        })
      );
    });

    // Fired from the finishLogin method in app.internal.js
    Radio.channel('header').on('login:internal', function(currentRoute) {
      // Render both the app selection header and the internal header, which
      // both start out hidden, and then make sure only the correct one is
      // actually made visible.
      self.showChildView(
        'internalAppSelectHeader',
        new BasicHeaderView({
          model: new Backbone.Model({
            user: Session.user,
            brandText: 'Internal Portal'
          })
        })
      );

      var brand = self.getInternalBrandConfigByRoute(currentRoute);
      var timeTrackingLink = self.getInternalTimeTrackingLink(currentRoute);
      self.internalHeader = new InternalHeaderView({
        model: new Backbone.Model({
          user: Session.user,
          brand: brand,
          timeTrackingLink: timeTrackingLink
        })
      });
      self.showChildView('internalHeader', self.internalHeader);
    });

    // Fired whenever a user loads a page within the external application
    Radio.channel('header').on('show:external', function() {
      if (self.ui.externalHeader.hasClass('collapse')) {
        self.hideHeader();
        self.ui.externalHeader.removeClass('collapse');
      }
    });
    // Fired whenever a user loads a page within the internal application
    Radio.channel('header').on('show:internal', function(currentRoute) {
      if (self.internalHeader) {
        if (self.internalHeader.setBrand) {
          var brandConfig = self.getInternalBrandConfigByRoute(currentRoute);
          self.internalHeader.setBrand(brandConfig);
        }
        if (self.internalHeader.setTimeTrackingLink) {
          var timeTrackingLink = self.getInternalTimeTrackingLink(currentRoute);
          self.internalHeader.setTimeTrackingLink(timeTrackingLink);
        }
      }
      if (self.ui.internalHeader.hasClass('collapse')) {
        self.hideHeader();
        self.ui.internalHeader.removeClass('collapse');
      }
    });
    // Fired whenever a user loads the internal application selection page
    Radio.channel('header').on('show:int-app-select', function() {
      if (self.ui.internalAppSelectHeader.hasClass('collapse')) {
        self.hideHeader();
        self.ui.internalAppSelectHeader.removeClass('collapse');
      }
    });
    // Fired whenever a user loads the internal application selection page
    Radio.channel('header').on('hide:header', function() {
      self.hideHeader();
    });
  },

  onLogout: function() {
    Radio.channel('global').trigger('logout:finish');
  },

  hideHeader: function() {
    this.ui.headerContainer.each(function() {
      $(this).addClass('collapse');
    });
  },

  getInternalTimeTrackingLink: function(route) {
    var user = Session.user;

    var isNonDepRoute = startsWith(route, 'nondep');
    var isDepRoute = startsWith(route, 'dep');
    var isSecRoute = startsWith(route, 'sec');
    var isEaxmManagementTech = userHasRole('ND - MT Exam');

    if (isNonDepRoute && (user.timeNDHasTimesheet || user.timeNDIsReviewer || isEaxmManagementTech)) {
      if (
        (user.timeNDHasTimesheet && user.timeNDIsReviewer) ||
        (user.timeNDIsReviewer && isEaxmManagementTech) ||
        (isEaxmManagementTech && user.timeNDHasTimesheet)
      ) {
        return '#nondep/time-tracking/page-select';
      } else if (user.timeNDIsReviewer) {
        return '#nondep/time-tracking/review';
      } else if (isEaxmManagementTech) {
        return '#nondep/time-tracking/view';
      } else {
        return '#nondep/time-tracking';
      }
    } else if (isDepRoute && user.timeDPHasTimesheet) {
      return '#dep/time-tracking';
    } else if (isSecRoute && user.timeSCHasTimesheet) {
      return '#sec/time-tracking';
    }
    return null;
  },

  getInternalBrandConfigByRoute: function(route) {
    // Determine what the brand text of the header bar should be based on the
    // current route which is bassed in from the Context Loader.
    var isNonDepRoute = startsWith(route, 'nondep');
    var isDepRoute = startsWith(route, 'dep');
    var isConSerRoute = startsWith(route, 'conser');
    var isSecRoute = startsWith(route, 'sec');
    var isCorpFinRoute = startsWith(route, 'corpfin');

    if (isNonDepRoute) {
      return { text: 'Non-Depository', link: '#nondep/dashboard' };
    } else if (isDepRoute) {
      return { text: 'Depository', link: '#dep/dashboard' };
    } else if (isConSerRoute) {
      return { text: 'Consumer Services', link: '#conser/dashboard' };
    } else if (isSecRoute) {
      return { text: 'Securities', link: '#sec/dashboard' };
    } else if (isCorpFinRoute) {
      return { text: 'Corporation Finance', link: '#corpfin/dashboard' };
    }
    return null;
  }
});
