var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "        <tr class=\"individuals-cell-row\">\r\n          <td class=\"string-cell renderable name\">"
    + alias2(alias1((depth0 != null ? depth0.firstName : depth0), depth0))
    + " "
    + alias2(alias1((depth0 != null ? depth0.lastName : depth0), depth0))
    + "</td>\r\n          <td class=\"string-cell renderable entity-name\">"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.entityId : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + "</td>\r\n          <td class=\"address-cell address-display-view renderable address\">"
    + ((stack1 = container.invokePartial(require("../../../common/views/AddressDisplayView/AddressDisplayView.hbs"),(depth0 != null ? depth0.address : depth0),{"name":"../../../common/views/AddressDisplayView/AddressDisplayView","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</td>\r\n          <td class=\"search-highlight-cell renderable matches\">"
    + ((stack1 = container.invokePartial(require("../../../common/backgrid/SearchHighlightCell/SearchHighlightCell.hbs"),depth0,{"name":"../../../common/backgrid/SearchHighlightCell/SearchHighlightCell","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</td>\r\n          <td class=\"link-button-cell td-grid-cell-btn renderable open-button\">\r\n            <a href=\"#nondep/individual/"
    + alias2(alias1((depth0 != null ? depth0.individualId : depth0), depth0))
    + "/dashboard\" class=\"btn btn-primary btn-sm\" role=\"button\" target=\"_blank\">Open</a>\r\n          </td>\r\n        </tr>\r\n";
},"2":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "<a href=\"#nondep/entity/"
    + alias2(alias1((depth0 != null ? depth0.entityId : depth0), depth0))
    + "/dashboard\" target=\"_blank\">"
    + alias2(alias1((depth0 != null ? depth0.entityName : depth0), depth0))
    + "<a>";
},"4":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(container.lambda((depth0 != null ? depth0.entityName : depth0), depth0));
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "\r\n<td class=\"string-cell renderable ssn\">"
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.ssn : depth0), depth0))
    + "</td>\r\n<td class=\"individuals-cell renderable\" colspan=\"5\">\r\n  <table class=\"table table-striped table-hover\">\r\n    <tbody>\r\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.hits : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </tbody>\r\n  </table>\r\n</td>";
},"usePartial":true,"useData":true});