/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(formData) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      CalculateFilingFee: {
        $xmlns: 'http://dobs.pa.gov/cf/filing/bpm/1.0',
        FilingTypeID: formData.filingTypeId,
        Amount: formData.amount,
        OversaleAmount: formData.oversaleAmount,
        IsIndefinite: formData.isIndefinite
      }
    },
    blueprint: {
      feeAmount: '$..FeeAmount'
    }
  })
    .done(function(response) {
      cleanUpResponse(response, { cleanUpTextKeys: true });

      var feeAmount = response.feeAmount ? (+response.feeAmount).toFixed(2) : '0.00';
      feeAmount = feeAmount.replace(/\d(?=(\d{3})+\.)/g, '$&,');

      deferred.resolve(feeAmount);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
