/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var NProgress = require('nprogress');

var GetInternalFilingReview = require('../../services/GetInternalFilingReview');

var InternalFilingReviewView = require('../../views/InternalFilingReviewView');

var DepSetupReviewFilingWorkTabView = require('./DepSetupReviewFilingWorkTabView');

module.exports = {
  event: 'build:dp:setup-filing',

  channel: 'task',

  builder: function(task) {
    var defer = $.Deferred();

    var filingId = task.taskData.DP.dpFilingId;

    GetInternalFilingReview(filingId)
      .done(function(results) {
        NProgress.set(0.85);

        var filingDetails = results.filingDetails;
        var lookups = results.lookups;
        var filingFormView = results.filingFormView;

        var workTabView = new DepSetupReviewFilingWorkTabView({
          model: new Backbone.Model({
            taskId: task.taskId,
            filingId: filingId,
            task: task,
            filingDetails: filingDetails,
            initialDecisions: lookups.initialDecisions,
            filingReviewRoles: lookups.filingReviewRoles
          })
        });

        defer.resolve(
          new InternalFilingReviewView({
            filingFormView: filingFormView,
            workTabView: workTabView,
            model: new Backbone.Model({
              filingDetails: filingDetails,
              task: task
            })
          })
        );
      })
      .fail(function(err) {
        defer.reject(err);
      });
    return defer.promise();
  }
};
