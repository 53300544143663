/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Ladda = require('ladda');

var tmplNoteModalView = require('./NoteModalView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNoteModalView,

  className: 'note-modal-view',

  ui: {
    noteForm: '.note-form',
    submit: '.submit',
    editDate: '.edit-date',
    editTime: '.edit-time'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  initialize: function(options) {
    this.submitNoteService = options.submitNoteService;
    this.managerRole = options.managerRole;
  },

  onBeforeShow: function() {
    this.ui.editDate.datepicker();
    this.ui.editDate.datepicker('setDate', new Date());
    this.ui.editTime.timepicker({ defaultTime: 'current', minuteStep: 1, autoclose: true, showMeridian: true });
    var noteContent = this.model.get('content');
    if (noteContent) {
      Syphon.deserialize(this, this.model.toJSON());
    }
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;
    this.ui.noteForm
      .parsley()
      .whenValidate()
      .done(function() {
        var l = Ladda.create(self.ui.submit[0]);
        l.start();
        var formData = Syphon.serialize(self);
        formData.objectId = self.model.get('objectId');
        formData.objectTypeId = self.model.get('objectTypeId');
        formData.id = self.model.get('id');
        self
          .submitNoteService(formData)
          .done(function(newNote) {
            self.triggerMethod('modal:close', newNote);
          })
          .fail(function() {
            l.stop();
          });
      });
  }
});
