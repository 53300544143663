/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function() {
  var defer = $.Deferred();

  $.soap({
    body: {
      GetLicenseTypesByAllowNew: {
        $xmlns: 'http://dobs.pa.gov/nd/lookup/bpm/1.0'
      }
    },
    blueprintArrays: ['GetLicenseTypesByAllowNewResponse.ND_LU_LicenseType'],
    blueprint: {
      licenseTypes: [
        '$..ND_LU_LicenseType',
        {
          id: '$.ID',
          name: '$.Name',
          isBranch: '$.IsBranch',
          applicationTypeId: '$.NewApplicationTypeID',
          branchParentLicenseTypeId: '$.BranchParentLicenseTypeID'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      _.each(response.licenseTypes, function(licenseType) {
        licenseType.isBranch = licenseType.isBranch === '1';
      });
      defer.resolve(response.licenseTypes);
    })
    .fail(function(err) {
      defer.reject();
    });

  return defer.promise();
};
