/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(formData) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetExamInvInqByCategoryAndCaseNumber: {
        $xmlns: 'http://dobs.pa.gov/sc/investigationinquiry/bpm/1.0',
        CaseNumber: formData.caseNumber,
        Category: formData.category
      }
    },
    blueprintArrays: ['GetExamInvInqByCategoryAndCaseNumberResponse.SC_AllExamInvestigationInquiryView'],
    blueprint: {
      relatedCases: [
        '$..SC_AllExamInvestigationInquiryView',
        {
          objectTypeId: '$..ObjectTypeID',
          objectId: '$..ObjectID',
          caseNumber: '$..CaseNumber',
          categoryId: '$..CategoryID',
          category: '$..Category',
          statusId: '$..StatusID',
          status: '$..Status',
          openedDate: '$..OpenedDate',
          closedDate: '$..ClosedDate',
          upgradedDate: '$..UpgradedDate',
          relatedTypeId: '$..RelatedTypeID',
          relatedtype: '$..RelatedType',
          isDeleted: '$..IsDeleted'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      _.each(response.relatedCases, function(relatedCase) {
        if (relatedCase.openedDate) {
          relatedCase.openedDate = moment(relatedCase.openedDate, 'YYYY-MM-DD');
        }
        if (relatedCase.closedDate) {
          relatedCase.closedDate = moment(relatedCase.closedDate, 'YYYY-MM-DD');
        }
        if (relatedCase.upgradedDate) {
          relatedCase.upgradedDate = moment(relatedCase.upgradedDate, 'YYYY-MM-DD');
        }
        relatedCase.relatedTypeId = formData.relatedCaseTypeId;
      });

      response.totalHits = response.relatedCases.length;
      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
