/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');

var GridView = require('psa-marionette/views/GridView');

var ButtonLinkCell = require('common/backgrid/ButtonLinkCell');
var AddressDisplayView = require('common/views/AddressDisplayView');
var userHasRole = require('common/util/userHasRole');

require('../styles/NonDepEntityLocationView.css');
var tmplNonDepEntityLocationView = require('../templates/NonDepEntityLocationView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepEntityLocationView,

  className: 'nondep-entity-location-view',

  ui: {
    locationAddress: '.location-address',
    licenses: '.licenses',
    isPPOB: '.ppob-view'
  },

  regions: {
    locationAddress: '@ui.locationAddress',
    licenses: '@ui.licenses'
  },

  templateHelpers: function() {
    var isInternal = this.options.viewMode === 'int';
    var isManager = !!userHasRole('ND - Manager');

    // Only internal users who are managers can edit locations
    var canEditLocation = isInternal && isManager;

    return {
      viewMode: this.options.viewMode,
      entityId: this.options.entityId,
      canEditLocation: canEditLocation
    };
  },

  onBeforeShow: function() {
    this.showChildView(
      'licenses',
      new GridView({
        columns: [
          {
            name: 'licenseType',
            label: 'License',
            cell: 'string',
            headerCell: 'custom',
            width: 40,
            editable: false
          },
          {
            name: 'licenseNumber',
            label: 'Number',
            cell: 'string',
            headerCell: 'custom',
            width: 20,
            editable: false
          },
          {
            name: 'licenseStatus',
            label: 'Status',
            cell: 'string',
            headerCell: 'custom',
            width: 20,
            editable: false
          },
          {
            name: '',
            label: '',
            cell: ButtonLinkCell.extend({
              name: 'Open',
              buttonClass: 'btn-sm btn-primary',
              href: function(dataModel) {
                if (this.options.viewMode === 'int') {
                  return '#nondep/entity/' + dataModel.get('entityId') + '/license/' + dataModel.get('id');
                }
                if (this.options.viewMode === 'ext') {
                  return '#ext/nondep/entity/' + dataModel.get('entityId') + '/license/' + dataModel.get('id');
                }
                return '#';
              }.bind(this)
            }),
            width: 20,
            headerCell: 'custom',
            editable: false,
            sortable: false
          }
        ],
        collection: new Backbone.Collection(this.model.get('licenses')),
        emptyText: 'Location has no associated licenses.'
      })
    );

    this.showChildView(
      'locationAddress',
      new AddressDisplayView({
        model: new Backbone.Model(this.model.get('address')),
        showCounty: true
      })
    );

    if (this.model.get('isPrimaryPlaceOfBusiness')) {
      this.ui.isPPOB.removeClass('hidden');
    }
  }
});
