/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Syphon = require('backbone.syphon');
var Backbone = require('backbone');
var Marionette = require('backbone.marionette');

var UIUtil = require('psa-core/util/ui');

var TimeTrkOpenObjectSelectionModalView = require('../../common/views/TimeTrkOpenObjectSelectionModalView');
var TimeTrkHoursFormView = require('../../common/views/TimeTrkHoursFormView');
var TimeTrkSecAllObjectsSelectionModalView = require('./TimeTrkSecAllObjectsSelectionModalView');

require('../styles/TimeTrkSecEntryRowView.css');
var tmplTimeTrkSecEntryRowView = require('../templates/TimeTrkSecEntryRowView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplTimeTrkSecEntryRowView,

  className: 'time-trk-sec-entry-row-view panel panel-default',

  ui: {
    btnObjectSelection: '.btn-object-selection',
    selectedObjectName: '.selected-object-name',
    showClosedCheckbox: '.show-closed-checkbox',
    objectSelection: '.object-selection',
    btnClearObject: '.btn-clear-object',
    hoursContainer: '.hours-container',
    removeEntry: '.remove-entry'
  },

  regions: {
    hoursContainer: '@ui.hoursContainer'
  },

  triggers: {
    'click @ui.removeEntry': 'remove:entry'
  },

  events: {
    'click @ui.btnObjectSelection': 'onClickObjectSelection',
    'click @ui.btnClearObject': 'onClickClearObject'
  },

  childEvents: {
    'update:time-entries': 'onUpdateTimeEntries'
  },

  templateHelpers: function() {
    return {
      childIndex: this.options.childIndex + 1,
      categories: this.options.categories
    };
  },

  onBeforeRender: function() {
    this.model.set('timesheet', this.options.timesheet);
  },

  onBeforeShow: function() {
    Syphon.deserialize(this, this.model.toJSON());

    this.showChildView('hoursContainer', new TimeTrkHoursFormView({ options: this.options, model: this.model }));

    this.objectId = this.model.get('objectId');
    this.objectTypeId = this.model.get('objectTypeId');
  },

  onUpdateTimeEntries: function(childView) {
    this.model.set('timeEntries', childView.model.get('timeEntries'));
    this.triggerMethod('update:hour-totals'); // TimeTrkSecExaminerEntryView
  },

  onClickObjectSelection: function(e) {
    e.preventDefault();
    var self = this;

    var isShowClosedChecked = this.ui.showClosedCheckbox.prop('checked');

    var timesheet = this.model.get('timesheet') || {};
    var departmentId = timesheet.departmentId;
    var objects = timesheet.scOpenObjects;
    var assignedToUsername = timesheet.assignedToUsername;

    if (!isShowClosedChecked) {
      UIUtil.showModal({
        title: 'Select Exam, Investigation, or Inquiry',
        dialogClasses: 'modal-dialog-time-trk-object-selection-modal-view',
        view: new TimeTrkOpenObjectSelectionModalView({
          model: new Backbone.Model({
            objectId: this.objectId,
            departmentId: departmentId,
            objects: objects
          })
        })
      }).done(function(object) {
        if (object) {
          self.objectId = object.objectId;
          self.objectTypeId = object.objectTypeId;
          var objectDisplayName = _.findWhere(objects, { objectId: object.objectId }).objectDisplayName;
          self.ui.selectedObjectName.val(objectDisplayName);
        } else {
          self.objectId = '';
          self.objectTypeId = '';
          self.ui.selectedObjectName.val('');
        }
      });
    }

    if (isShowClosedChecked) {
      UIUtil.showModal({
        title: 'Select Exam, Investigation, or Inquiry',
        dialogClasses: 'modal-dialog-time-trk-sec-all-objects-selection-modal-view',
        view: new TimeTrkSecAllObjectsSelectionModalView({
          model: new Backbone.Model({
            objectId: this.objectId,
            departmentId: departmentId,
            objects: objects,
            assignedToUsername: assignedToUsername,
            orgLicenseTypes: this.options.orgLicenseTypes,
            indLicenseTypes: this.options.indLicenseTypes
          })
        })
      }).done(function(object) {
        if (object) {
          self.objectId = object.objectId;
          self.objectTypeId = object.objectTypeId;
          var objectDisplayName = object.objectDisplayName;
          self.ui.selectedObjectName.val(objectDisplayName);
        } else {
          self.objectId = '';
          self.objectTypeId = '';
          self.ui.selectedObjectName.val('');
        }
      });
    }
  },

  onClickClearObject: function(e) {
    e.preventDefault();

    this.objectTypeId = '';
    this.objectId = '';
    this.ui.selectedObjectName.val('');
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    formData.objectTypeId = this.objectTypeId;
    formData.objectId = this.objectId;
    return formData;
  }
});
