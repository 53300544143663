/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var NProgress = require('nprogress');
var NavUtils = require('psa-core/util/nav');

var Scaffold = require('common/layout/Scaffold');

var GetEntityExternalUsersByAccessLevel = require('../../common/services/GetEntityExternalUsersByAccessLevel');
var GetEntityIdentifiers = require('../../common/services/GetEntityIdentifiers');
var GetEntityExam = require('../services/GetEntityExam');

var DepExamManageOwnerView = require('../views/DepExamManageOwnerView');

module.exports = function(entityId, examId) {
  NProgress.start();
  NProgress.set(0.6);

  $.when(
    GetEntityIdentifiers(entityId),
    GetEntityExternalUsersByAccessLevel({ entityId: entityId, accessLevel: 1 }),
    GetEntityExam(examId)
  )
    .done(function(entityIdentifiers, entityUsers, exam) {
      NavUtils.navigate('dep/entity/' + entityId + '/exam/' + examId + '/manage-owner');
      Scaffold.content.show(
        new DepExamManageOwnerView({
          viewMode: 'int',
          model: new Backbone.Model({
            entityId: entityId,
            examId: examId,
            entityName: entityIdentifiers.entityName,
            entityUsers: entityUsers,
            exam: exam
          })
        })
      );
      NProgress.done();
    })
    .fail(function(err) {
      NProgress.done();
    });
};
