/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var NProgress = require('nprogress');

var NavUtils = require('psa-core/util/nav');

var Scaffold = require('common/layout/Scaffold');

var GetInvInqIdentifiers = require('../services/GetInvInqIdentifiers');
var GetDocumentTypesByDepartment = require('common/services/GetDocumentTypesByDepartment');

var SecInvInqInitiateCorrespondenceView = require('../views/SecInvInqInitiateCorrespondenceView');

module.exports = function(invInqId) {
  NProgress.start();
  NProgress.set(0.6);

  $.when(GetInvInqIdentifiers(invInqId), GetDocumentTypesByDepartment('SECURITIES'))
    .done(function(identifiers, documentTypes) {
      NavUtils.navigate('#sec/investigation-inquiry/' + invInqId + '/initiate-correspondence');
      Scaffold.content.show(
        new SecInvInqInitiateCorrespondenceView({
          model: new Backbone.Model({
            invInqId: invInqId,
            identifiers: identifiers,
            documentTypes: documentTypes
          })
        })
      );
      NProgress.done();
    })
    .fail(function() {
      NProgress.done();
    });
};
