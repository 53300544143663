var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "\r\n<div class=\"panel panel-default panel-entity-location panel-row-view\">\r\n  <div class=\"panel-body row\">\r\n    <div class=\"row\">\r\n      <div class=\"form-horizontal\">\r\n        <div class=\"col-sm-6\">\r\n          <div class=\"form-group\">\r\n            <label class=\"control-label col-sm-4 col-md-3\">Company</label>\r\n            <div class=\"form-control-static col-sm-8 col-md-9\">"
    + alias4(((helper = (helper = helpers.companyName || (depth0 != null ? depth0.companyName : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"companyName","hash":{},"data":data}) : helper)))
    + "</div>\r\n          </div>\r\n        </div>\r\n        <div class=\"col-sm-6\">\r\n          <div class=\"form-group\">\r\n            <label class=\"control-label col-sm-4 col-md-3\">Business Phone</label>\r\n            <div class=\"form-control-static col-sm-8 col-md-9\">"
    + alias4(((helper = (helper = helpers.phone || (depth0 != null ? depth0.phone : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"phone","hash":{},"data":data}) : helper)))
    + "</div>\r\n          </div>\r\n        </div>\r\n      </div>\r\n    </div>\r\n    <div class=\"row\">\r\n      <div class=\"form-horizontal\">\r\n        <div class=\"col-sm-6\">\r\n          <div class=\"form-group\">\r\n            <label class=\"control-label col-sm-4 col-md-3\">First Name</label>\r\n            <div class=\"form-control-static col-sm-8 col-md-9\">"
    + alias4((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.firstName : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\r\n          </div>\r\n        </div>\r\n        <div class=\"col-sm-6\">\r\n          <div class=\"form-group\">\r\n            <label class=\"control-label col-sm-4 col-md-3\">Fax Line</label>\r\n            <div class=\"form-control-static col-sm-8 col-md-9\">"
    + alias4((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.fax : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\r\n          </div>\r\n        </div>\r\n      </div>\r\n    </div>\r\n    <div class=\"row\">\r\n      <div class=\"form-horizontal\">\r\n        <div class=\"col-sm-6\">\r\n          <div class=\"form-group\">\r\n            <label class=\"control-label col-sm-4 col-md-3\">Last Name</label>\r\n            <div class=\"form-control-static col-sm-8 col-md-9\">"
    + alias4((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.lastName : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\r\n          </div>\r\n        </div>\r\n        <div class=\"col-sm-6\">\r\n          <div class=\"form-group\">\r\n            <label class=\"control-label col-sm-4 col-md-3\">Email Address</label>\r\n            <div class=\"form-control-static col-sm-8 col-md-9\">"
    + alias4((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.email : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\r\n          </div>\r\n        </div>\r\n      </div>\r\n    </div>\r\n    <div class=\"row\">\r\n      <div class=\"form-horizontal\">\r\n        <div class=\"col-sm-6\">\r\n          <div class=\"form-group\">\r\n            <label class=\"control-label col-sm-4 col-md-3\">Address</label>\r\n            <div class=\"address-view form-control-static col-sm-8 col-md-9\"></div>\r\n          </div>\r\n        </div>\r\n        <div class=\"col-sm-6\">\r\n          <div class=\"form-group\">\r\n            <label class=\"control-label col-sm-4 col-md-3\">Description</label>\r\n            <p class=\"form-control-static col-sm-8 col-md-9\">"
    + alias4((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.description : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</p>\r\n          </div>\r\n        </div>\r\n      </div>\r\n    </div>\r\n  </div>\r\n</div>";
},"useData":true});