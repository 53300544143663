/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');
var UIUtil = require('psa-core/util/ui');
var ConfirmationModalView = require('common/views/ConfirmationModalView');

var SideNavSectionView = require('common/views/SideNavSectionView');
var BreadcrumbView = require('common/views/BreadcrumbView');
var DropdownView = require('common/views/DropdownView');
var ContentServerLinkView = require('common/views/ContentServerLinkView');

var DepAuditDetailsSectionView = require('./DepAuditDetailsSectionView');

var SubmitTrustFundAudit = require('../services/SubmitTrustFundAudit');
var SubmitTrustSubFundAudit = require('../services/SubmitTrustSubFundAudit');

var tmplDepAuditDashboardView = require('../templates/DepAuditDashboardView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepAuditDashboardView,

  className: 'dep-audit-dashboard-view container-fluid',

  ui: {
    auditWrapper: '.audit-wrapper',
    auditActionDropdown: '.dropdown-container',
    csLinkContainer: '.cs-link-container',
    breadcrumb: '.breadcrumb-container'
  },

  regions: {
    auditWrapper: '@ui.auditWrapper',
    auditActionDropdown: '@ui.auditActionDropdown',
    csLinkContainer: '@ui.csLinkContainer',
    breadcrumb: '@ui.breadcrumb'
  },

  onBeforeShow: function() {
    var audit = this.model.get('audit') || {},
      entityId = audit.entityId,
      entityName = audit.entityName,
      fundId = audit.fundId,
      fundName = audit.fundName,
      subFundId = audit.subFundId,
      subFundName = audit.subFundName,
      auditId = audit.id,
      cSFolderId = audit.cSFolderId;

    this.showChildView(
      'auditActionDropdown',
      new DropdownView({
        rightAlign: true,
        model: new Backbone.Model({
          name: 'Audit Actions',
          options: [
            {
              text: 'Edit Audit',
              link: function() {
                if (subFundId) {
                  return (
                    '#dep/entity/' +
                    entityId +
                    '/fund/' +
                    fundId +
                    '/sub-fund/' +
                    subFundId +
                    '/audit/' +
                    auditId +
                    '/edit'
                  );
                }
                return '#dep/entity/' + entityId + '/fund/' + fundId + '/audit/' + auditId + '/edit';
              }
            },
            {
              text: 'Delete Audit',
              onClick: this.onClickDelete.bind(this)
            }
          ]
        })
      })
    );

    this.audit = new SideNavSectionView({
      collection: new Backbone.Collection([
        {
          id: 'auditDetails',
          title: 'Audit Details',
          view: new DepAuditDetailsSectionView({
            model: new Backbone.Model(audit)
          })
        },
        {
          id: 'notes',
          title: 'Notes',
          view: Radio.channel('dp').request('build:notes-view', {
            objectId: auditId,
            objectTypeId: subFundId ? 'TRUSTSUBFUND_AUDIT' : 'TRUSTFUND_AUDIT'
          })
        },
        {
          id: 'auditLog',
          title: 'Audit Log',
          view: Radio.channel('dp').request('build:audit-log-view', {
            objectId: auditId,
            objectTypeId: subFundId ? 'TRUSTSUBFUND_AUDIT' : 'TRUSTFUND_AUDIT'
          })
        }
      ])
    });
    this.showChildView('auditWrapper', this.audit);

    var breadcrumbPath = [
      {
        link: '#dep/dashboard',
        text: 'Home'
      },
      {
        link: '#dep/entity/' + entityId + '/dashboard',
        text: entityName
      },
      {
        link: '#dep/entity/' + entityId + '/fund/' + fundId + '/dashboard',
        text: fundName
      },
      {
        text: audit.auditDate
      }
    ];

    if (subFundId) {
      breadcrumbPath.splice(3, 0, {
        link: '#dep/entity/' + entityId + '/fund/' + fundId + '/sub-fund/' + subFundId + '/dashboard',
        text: subFundName
      });
    }

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: breadcrumbPath
      })
    );

    this.showChildView(
      'csLinkContainer',
      new ContentServerLinkView({
        model: new Backbone.Model({
          buttonText: 'Open CIF/CTF Audit Folder',
          contentServerId: cSFolderId ? cSFolderId : this.model.get('auditsCSFolderId')
        })
      })
    );
  },

  onClickDelete: function(e) {
    e.preventDefault();

    var audit = this.model.get('audit'),
      entityId = audit.entityId,
      fundId = audit.fundId,
      subFundId = audit.subFundId;

    var prompt = 'Are you sure you want to delete this collective investment fund audit?';

    if (subFundId) {
      prompt = 'Are you sure you want to delete this collective investment sub-fund audit?';
    }

    UIUtil.showModal({
      title: 'Confirm Deleting Collective Investment Fund Audit',
      view: new ConfirmationModalView({
        confirmText: 'Delete Collective Investment Fund Audit',
        model: new Backbone.Model({
          message: prompt,
          onConfirm: function() {
            var formData = {
              id: audit.id,
              shouldDelete: 1
            };

            if (subFundId) {
              return SubmitTrustSubFundAudit(formData);
            }
            return SubmitTrustFundAudit(formData);
          },
          onConfirmDone: function() {
            if (subFundId) {
              Radio.channel('navigate').trigger('show:int:dp:sub-fund', entityId, fundId, subFundId);
            } else {
              Radio.channel('navigate').trigger('show:int:dp:fund', entityId, fundId);
            }
          }
        })
      })
    });
  }
});
