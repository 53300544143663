/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');
var momentUtil = require('common/util/momentUtil');

module.exports = window.GetAllApplicationLookups = function(departmentName) {
  var deferred = $.Deferred();
  $.soap({
    body: {
      GetTimeTrackingLookupsByDepartmentName: {
        $xmlns: 'http://dobs.pa.gov/tt/lookup/bpm/1.0',
        DepartmentName: departmentName
      }
    },
    blueprintArrays: [
      'GetTimeTrackingLookupsByDepartmentNameResponse.Categories.TT_LU_Category',
      'GetTimeTrackingLookupsByDepartmentNameResponse.Periods.TT_LU_Period',
      'GetTimeTrackingLookupsByDepartmentNameResponse.Statuses.TT_LU_Status'
    ],
    blueprint: {
      timesheetCategories: [
        '$..TT_LU_Category',
        {
          id: '$..ID',
          category: '$..Category',
          departmentId: '$..DepartmentID',
          depEntityCategoryId: '$..DepEntityCategoryID'
        }
      ],
      timesheetPeriods: [
        '$..TT_LU_Period',
        {
          id: '$..ID',
          periodStartDate: '$..PeriodStartDate',
          periodEndDate: '$..PeriodEndDate',
          year: '$..Year'
        }
      ],
      timesheetStatuses: [
        '$..TT_LU_Status',
        {
          id: '$..ID',
          status: '$..Status'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      _.each(response.timesheetPeriods, function(period) {
        period.periodStartDate = momentUtil.formatStr(period.periodStartDate, momentUtil.defaultInboundDateConfig);

        period.periodEndDate = momentUtil.formatStr(period.periodEndDate, momentUtil.defaultInboundDateConfig);

        var startMonday = moment(period.periodStartDate, 'MM/DD/YYYY')
          .add(1, 'days')
          .format('MM/DD/YYYY');

        var endFriday = moment(period.periodStartDate, 'MM/DD/YYYY')
          .add(12, 'days')
          .format('MM/DD/YYYY');

        period.payPeriodDisplay = startMonday + ' - ' + endFriday;
      });

      response.timesheetYears = _.chain(response.timesheetPeriods)
        .pluck('year')
        .uniq()
        .value();

      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
