/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var _ = require('underscore');

require('./HeaderBarCellView.css');
var tmplHeaderBarCellView = require('./HeaderBarCellView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplHeaderBarCellView,

  className: 'header-bar-cell-view',

  ui: {
    label: '.cell-label',
    value: '.cell-value'
  },

  initialize: function(options) {
    this.column = options.model;
    this.dataModel = options.dataModel;
  },

  onRender: function() {
    if (!_.isUndefined(this.model.get('width'))) {
      this.$el.css('width', this.model.get('width'));
    }
    if (!_.isUndefined(this.model.get('maxWidth'))) {
      this.$el.css('max-width', this.model.get('maxWidth'));
    }
    if (!_.isUndefined(this.model.get('minWidth'))) {
      this.$el.css('min-width', this.model.get('minWidth'));
    }

    var overflow = (this.model.get('overflow') || '').toLowerCase();
    if (overflow === 'truncate') {
      this.ui.value.addClass('truncate');
    } else if (overflow === 'break-all') {
      this.ui.value.addClass('wrap-break');
    }
  },

  templateHelpers: function() {
    return {
      value: this.dataModel.get(this.model.get('name'))
    };
  }
});
