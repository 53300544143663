/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(entityId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetContactByEntityID: {
        $xmlns: 'http://dobs.pa.gov/dp/contact/bpm/1.0',
        EntityID: entityId
      }
    },
    blueprintArrays: [
      'GetContactByEntityIDResponse.Contacts.DP_EntityContact',
      'GetContactByEntityIDResponse.ContactTypes.DP_EntityContactType',
      'GetContactByEntityIDResponse.ContactEmailAddresses.DP_EntityContactEmailAddress',
      'GetContactByEntityIDResponse.ContactAdditionalNumbers.DP_EntityContactAdditionalNumber'
    ],
    blueprint: {
      contacts: [
        '$..DP_EntityContact',
        {
          contactId: '$.ID',
          entityId: '$.EntityID',
          firstName: '$.FirstName',
          lastName: '$.LastName',
          middleInitial: '$.MiddleInitial',
          title: '$.Title',
          addressId: '$.AddressID',
          phoneNumber: '$.PhoneNumber',
          isDeleted: '$.IsDeleted'
        }
      ],
      additionalNumbers: [
        '$..DP_EntityContactAdditionalNumber',
        {
          id: '$.ID',
          EntityContactID: '$.EntityContactID',
          additionalNumber: '$.AdditionalNumber',
          entityID: '$.EntityID'
        }
      ],
      emailAdresses: [
        '$..DP_EntityContactEmailAddress',
        {
          id: '$.ID',
          EntityContactID: '$.EntityContactID',
          email: '$.Email',
          entityID: '$.EntityID'
        }
      ],
      contactTypes: [
        '$..DP_EntityContactType',
        {
          id: '$.ID',
          EntityContactID: '$.EntityContactID',
          contactTypeID: '$.ContactTypeID',
          type: '$.Type',
          entityID: '$.EntityID'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      _.each(response.contacts, function(contacts) {
        _.each(['contactType', 'firstName', 'lastName', 'middleInitial', 'title', 'phoneNumber'], function(attr) {
          if (!contacts[attr]) {
            contacts[attr] = '--';
          }
        });

        contacts.contactTypes = _.reduce(
          response.contactTypes,
          function(memo, type) {
            if (type.EntityContactID === contacts.contactId) {
              memo.push({
                value: type.type
              });
            }
            return memo;
          },
          []
        );

        contacts.emailAdresses = _.reduce(
          response.emailAdresses,
          function(memo, type) {
            if (type.EntityContactID === contacts.contactId) {
              memo.push({
                value: type.email
              });
            }
            return memo;
          },
          []
        );

        contacts.additionalNumbers = _.reduce(
          response.additionalNumbers,
          function(memo, type) {
            if (type.EntityContactID === contacts.contactId) {
              memo.push({
                value: type.additionalNumber
              });
            }
            return memo;
          },
          []
        );
      });

      deferred.resolve(response.contacts);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
