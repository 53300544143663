/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');

module.exports = Marionette.Behavior.extend({
  onBeforeShow: function() {
    var self = this;
    var phones = this.$el.find('.phone-format');
    _.each(phones, function(phone) {
      self.$(phone).keydown(function(e) {
        var key = e.which || e.keyCode || e.charCode;
        if (key === 8) {
          self.handleDelete(phone);
        } else {
          self.handlePreviousPhoneFormat(phone);
        }
      });

      self.$(phone).keyup(function() {
        self.handleTargetDisplay(phone);
      });
    });
  },

  handlePreviousPhoneFormat: function(phone) {
    var phoneNumber = this.$(phone).val();
    this.wasFullValidPhone = /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/.test(phoneNumber);
  },

  handleTargetDisplay: function(phone) {
    var phoneNumber = this.$(phone).val();
    var digits = phoneNumber.replace(/-/g, '');
    var isValidDigits = !isNaN(digits) && !/\+.*/.test(digits);
    var digitsLen = digits.length;
    var lastCharIsDash = phoneNumber.substr(-1) === '-';
    if (_.includes([3, 6], digitsLen) && isValidDigits && !lastCharIsDash) {
      phoneNumber += '-';
      this.$(phone).val(phoneNumber);
    } else if (this.wasFullValidPhone && digitsLen > 10) {
      phoneNumber = phoneNumber.replace('-', '');
      phoneNumber = phoneNumber.replace('-', '');
      this.$(phone).val(phoneNumber);
    }
  },

  handleDelete: function(phone) {
    var phoneNumber = this.$(phone).val();
    var digits = phoneNumber.replace(/-/g, '');
    var isValidDigits = !isNaN(digits);

    if (phoneNumber.substr(-1) === '-' && isValidDigits) {
      this.$(phone).val(phoneNumber.substr(0, phoneNumber.length - 1));
    }
  }
});
