/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');

var UIUtil = require('psa-core/util/ui');
var modelGet = require('common/util/modelGet');

var ConfirmationModalView = require('common/views/ConfirmationModalView');
var DropdownView = require('common/views/DropdownView');
var BreadcrumbView = require('common/views/BreadcrumbView');

var SubmitEntityOfficer = require('../services/SubmitEntityOfficer');

var tmplDepOfficerDashboardView = require('../templates/DepOfficerDashboardView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepOfficerDashboardView,

  className: 'dep-officer-dashboard-view container-fluid',

  ui: {
    headerBar: '.header-bar-container',
    breadcrumb: '.breadcrumb-container',
    officerActionDropDown: '.officer-action-drop-down',
    officerDashboard: '.officer-dashboard-container'
  },

  regions: {
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb',
    officerActionDropDown: '@ui.officerActionDropDown',
    officerDashboard: '@ui.officerDashboard'
  },

  onBeforeShow: function() {
    var officer = this.model.get('officer') || {};
    var officerId = officer.officerId;
    var entityId = officer.entityId;
    var entityName = modelGet(this.model, 'entityIdentifiers.entityName');

    this.showChildView(
      'officerActionDropDown',
      new DropdownView({
        rightAlign: true,
        model: new Backbone.Model({
          name: 'Officer Actions',
          options: [
            {
              text: 'Edit Officer',
              link: '#dep/entity/' + entityId + '/officer/' + officerId + '/edit'
            },
            {
              text: 'Delete Officer',
              onClick: this.onClickDelete.bind(this)
            }
          ]
        })
      })
    );

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#dep/dashboard',
            text: 'Home'
          },
          {
            link: '#dep/entity/' + entityId + '/dashboard',
            text: entityName
          },
          {
            text: officer.officerType + ' - ' + officer.firstName + ' ' + officer.lastName
          }
        ]
      })
    );
  },

  onClickDelete: function() {
    var self = this;
    var message = 'Are you sure you want to delete this officer?';
    UIUtil.showModal({
      title: 'Confirm Deleting Officer',
      view: new ConfirmationModalView({
        confirmText: 'Delete Officer',
        model: new Backbone.Model({
          message: message,
          onConfirm: function() {
            return SubmitEntityOfficer({
              officerId: modelGet(self.model, 'officer.officerId'),
              isDeleted: 1
            });
          },
          onConfirmDone: function() {
            Radio.channel('navigate').trigger('show:int:dp:entity', modelGet(self.model, 'officer.entityId'));
          }
        })
      })
    });
  }
});
