/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

var getComplaintEntityType2 = function(
  complaints,
  entityTypes,
  nondepLicenseTypes,
  secLicenseTypes,
  conserLicenseTypes
) {
  complaints = _.map(complaints, function(complaint) {
    switch (complaint.departmentId) {
      case '1000':
        var licenseType =
          _.find(nondepLicenseTypes, function(licenseType) {
            return licenseType.id === complaint.entityType2Id;
          }) || {};
        complaint.entityType2 = licenseType.name || '--';
        break;
      case '2000':
        var entityType =
          _.find(entityTypes, function(entityType) {
            return entityType.id === complaint.entityType2Id;
          }) || {};
        complaint.entityType2 = entityType.type || '--';
        break;
      case '3000':
        var secLicenseType =
          _.find(secLicenseTypes, function(licenseType) {
            return licenseType.id === complaint.entityType2Id;
          }) || {};
        complaint.entityType2 = secLicenseType.type || '--';
        break;
      case '4000':
        var conserLicenseType =
          _.find(conserLicenseTypes, function(licenseType) {
            return licenseType.id === complaint.entityType2Id;
          }) || {};
        complaint.entityType2 = conserLicenseType.type || '--';
        break;
      default:
        complaint.entityType2 = '--';
    }
    return complaint;
  });
  return complaints;
};

module.exports = getComplaintEntityType2;
