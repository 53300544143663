/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var NProgress = require('nprogress');

var NavUtils = require('psa-core/util/nav');

var Scaffold = require('common/layout/Scaffold');

var DepSubsidiaryEditView = require('../views/DepSubsidiaryEditView');
var GetSubsidiary = require('../services/GetSubsidiary');
var GetEntityIdentifiers = require('../../common/services/GetEntityIdentifiers');
var GetAllSubsidiaryLookups = require('../services/GetAllSubsidiaryLookups');

module.exports = function(entityId, subsidiaryId) {
  NProgress.start();
  NProgress.set(0.4);
  $.when(GetSubsidiary(subsidiaryId), GetEntityIdentifiers(entityId))
    .done(function(subsidiary, entityIdentifiers) {
      NProgress.set(0.7);
      GetAllSubsidiaryLookups(entityIdentifiers.categoryId)
        .done(function(lookups) {
          NavUtils.navigate('#dep/entity/' + entityId + '/subsidiary/' + subsidiaryId + '/edit');
          Scaffold.content.show(
            new DepSubsidiaryEditView({
              viewMode: 'int',
              model: new Backbone.Model({
                entityId: entityId,
                subsidiaryId: subsidiaryId,
                entityIdentifiers: entityIdentifiers,
                subsidiaryTypes: lookups.subsidiaryTypes,
                subsidiaryStatus: lookups.subsidiaryStatus,
                subsidiary: subsidiary
              })
            })
          );
          NProgress.done();
        })
        .fail(function(err) {
          NProgress.done();
        });
    })
    .fail(function(err) {
      NProgress.done();
    });
};
